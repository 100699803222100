import { Injectable } from '@angular/core';
import { EnvironmentDetailsService } from '../../services/environment-details.service';


@Injectable({providedIn: 'root'})
export class ContentProvider {

    content: any;
    constructor(private _envSvc: EnvironmentDetailsService) {

    }

    public getContent(): any {
        return this.content;
    }

    load() {
        return new Promise<void>((resolve, reject) => {
            this._envSvc.getSessionEndpointsEnv().then(q => {
                this._envSvc.CURR_SESSION_ENDPOINTS = q.WCS_ENPOINT_CONST;
                this._envSvc.APIGEE_TOKEN_URLS = q.APIGEE_TOKEN_URL;
                this._envSvc.getContentEnvt().then(x => {
                    this._envSvc.CURR_CONTENT_URLS = x.CONTENT_PAGES_URL;
                    // This call Gets tokens
                    resolve();
                });
            });
        });
    }
}
