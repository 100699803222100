import { Injectable } from '@angular/core';
import { AuthHttpService } from './http-wrapper.service';
import { EnvironmentDetailsService } from './environment-details.service';
import { Observable, interval, throwError, of } from 'rxjs';
import { retryWhen, flatMap } from 'rxjs/operators';
import { COMMON_CONSTANTS, CardBrands, CardNames, CardImages } from '../common/core/common.constants';
import { HttpCacheService } from './http-cache.service';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  errorMessage: any;
  orgID: any;
  isCcInfoSaved = false;
  cardTypeIcons = [
    { name: CardNames.VISA, url: CardImages.VISA_URL, id: 1, brand: CardBrands.VISA },
    { name: CardNames.MASTERCARD, url: CardImages.MASTERCARD_URL, id: 2, brand: CardBrands.MASTERCARD },
    { name: CardNames.AMERICANEXPRESS, url: CardImages.AMERICANEXPRESS_URL, id: 3, brand: CardBrands.AMERICANEXPRESS },
    { name: CardNames.DISCOVER, url: CardImages.DISCOVER_URL, id: 4, brand: CardBrands.DISCOVER }
  ];

  cardBrandType = [
    { name: CardNames.VISA, url: CardImages.VISA_URL, id: 2, brand: CardBrands.VS },
    { name: CardNames.MASTERCARD, url: CardImages.MASTERCARD_URL, id: 4, brand: CardBrands.MSCD },
    { name: CardNames.AMERICANEXPRESS, url: CardImages.AMERICANEXPRESS_URL, id: 6, brand: CardBrands.AMEX },
    { name: CardNames.DISCOVER, url: CardImages.DISCOVER_URL, id: 5, brand: CardBrands.DISC }
  ];

  constructor(private httpClient: AuthHttpService, private _envSvc: EnvironmentDetailsService, private _cacheSvc: HttpCacheService) {
    this.orgID = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY)) ?
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY) : '';
  }


  getValidCreditCardType() {
    const headerSettings: { [name: string]: string | string[]; } = {};
    // //setting of  headers key and values
    // // headerSettings[''] = '';

  }

  fetchOrgId(orderId: any) {
    return this.httpClient.get(this._envSvc.CURR_SESSION_ENDPOINTS.FETCH_ORG_ID_API + COMMON_CONSTANTS.ORDER_ID_PARAM + orderId);

  }

  savePaymentInfo(postData: any) {
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
    ? true : false;
    const serviceUrl = this.replaceOrgIdInUrl(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_SAVE_AUTO_PAY);
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_SAVE_AUTO_PAY_PARTNER);
      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_SAVE_AUTO_PAY_PARTNER,
          postData).pipe(this.retryRequest());
      }
    } else {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_SAVE_AUTO_PAY);
      } else {
        return this.httpClient.post(serviceUrl, postData).pipe(this.retryRequest());
      }
    }
  }

  replaceOrgIdInUrl(serviceUrl: any) {
    serviceUrl.path = serviceUrl.path.replace(COMMON_CONSTANTS.ORG_ID_TEXT, this.orgID);
    return serviceUrl;
  }

  retryRequest(maxRetry: number = 2, delayMs: number = 3000) {
    return (src: Observable<any>) => src.pipe(
      retryWhen(() => {
        return interval(delayMs).pipe(
          flatMap(count => count === maxRetry ? throwError(
            {
              message: {
                errorKey: COMMON_CONSTANTS.FIVE_HUNDRED,
                errorMessage: this.errorMessage.error.genericErrorTxt,
                errorType: COMMON_CONSTANTS.SERVER_ERROR
              }, show: true
            }) : of(count))
        );
      }));
  }

}
