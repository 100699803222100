<input type="text" name="walkMeIn_userID" ng-model="walkMeIn_userID" style="display: none;" />
<app-header *ngIf="authoringContent"></app-header>
<div class="container"  *ngIf='this.isEnrollAutopayEligible || this.isManageAutopayEligible'>
  <div>
    <div class="page-title Auto-a-payment">
      <h1 *ngIf='autoPaySelectedOption == "enroll"'>{{authoringContent?.autoPaymentHeadingTxt}}</h1>
      <h1 *ngIf='(autoPaySelectedOption == "manage" || autoPaySelectedOption == "cancel")'>{{authoringContent?.manageAutoPaymentHeadingTxt}}</h1>
    </div>
    <div class="autopay-banner" *ngIf="apdJuneRelease && displayDiscountMsg && autoPaySelectedOption == 'enroll'">
        <span >{{autopayBannerMessage}}</span>
    </div>
    <div *ngIf="!apdJuneRelease && displayDiscountMsg" class="autopay-banner">
      <mat-icon class="error-icon">error_outline</mat-icon>
      <span>{{reactiveAwarenessMsg}}</span>
    </div>

    <div class="sub-heading">
      {{titleLable}}
    </div>
    <div class="mt-5">
      <div class="media">
        <span class="icons credit-card">
          <img src="/assets/tfb-payment/images/icons/payment_info_black.PNG" class="payment-info" alt="">
        </span>
        <div class="Auto-a-payment">
          <h3 class="pb-0">{{authoringContent?.headingTxt}}</h3>
          <div *ngIf="showSubHeadertxt" class="mp-error-title">
            <img src="./assets/tfb-payment/images/icon_error.svg" alt="">
            <h1>{{CommonAuthoringContent?.error?.genericErrorApTxt}}</h1>
          </div>
          <div class="radio radio-primary mb-1 marginT">
            <input type="radio" [(ngModel)]="selectedPaymentType" name="selectedPaymentType" id="bank" value="Bank"
              (change)="onChangeDirectDepositV1($event, 'ach')">
            <label for="bank">{{AchConstants?.BANK_TEXT}}</label>
            <div *ngIf="achService.isBankInfoSaved">
              <div class="col-sm-12 ml-md-4 pl-md-2">
                <span class="ml-sm-3 weight-normal saved-number-img"> {{authoringContent?.bankAccountEndingTxt}}
                  <img src="/assets/tfb-payment/images/maskNumber.PNG" alt="{{bankAccountNumber}}">{{bankAccountNumber}}
                  <a class="inline_link cursorPointer" (click)="changeSavedInfo(bankText,'ach','none')">{{authoringContent?.changeTxt}}</a>
                </span>
              </div>
            </div>
          </div>
          <div class="radio radio-primary mb-1" *ngIf="showBAUCreditDebit">
            <input type="radio" [(ngModel)]="selectedPaymentType" name="selectedPaymentType" id="card" value="Card"
              (change)="onChangeDirectDepositV1($event, 'credit')">
            <label for="card">{{authoringContent?.creditCardTxt}}</label>
              <div *ngIf="ShowCraditCardOption && !showcardDefault && !(autoPaySelectedOption == 'enroll') ">
              <div class="col-sm-12 ml-md-4 pl-md-2">
                 <img *ngIf="savedCardDetails.cardType" [src]='getCardImageURL' alt="{{getCardImageURL}}" >
                <span class="ml-sm-3 saved-number-img">
                  <img src="/assets/tfb-payment/images/maskNumber.PNG" alt="{{creditCardNumber}}"> {{creditCardNumber}}
                  <span *ngIf='autoPaySelectedOption == "manage"'>
                    <span> - {{authoringContent?.validThruText}} </span>
                    <span class="" *ngIf="cardExpirationDate !== ''">
                      {{cardExpirationDate}}
                    </span>
                  </span>
                  <span *ngIf="isEdit" > 
                      <a class="inline_link cursorPointer" (click)="changeSavedInfo(cardText, 'credit','edit')">{{authoringContent?.editTxt}} </a> 
                  </span>
                  <span *ngIf="!isEdit" class="Link-Change-disable" > 
                      {{authoringContent?.editTxt}} 
                  </span>

                  <span *ngIf="isChange"> 
                      <a class="inline_link cursorPointer" (click)="changeSavedInfo(cardText, 'credit','change')">{{authoringContent?.changeTxt}} {{authoringContent?.cardTxt}}</a>
                  </span>
                  <span *ngIf="!isChange" class="Link-Change-disable" > 
                      {{authoringContent?.changeTxt}} {{authoringContent?.cardTxt}}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="radio radio-primary mb-1" *ngIf="showSeperateCreditDebit">
            <div class="row" >
              <div class="col-sm-12">
                <input type="radio" [(ngModel)]="selectedPaymentType" name="selectedPaymentType" id="creditCard" value="creditCard"
                  (change)="onChangeDirectDepositV1($event, 'creditCard')">
                <label for="creditCard">Credit card</label>
                <span *ngIf="notifyMessage" class="optional_errortext alert-icon-text"></span>
                <span *ngIf="notifyMessage" class="alert-card-text" >
                  {{creditCardAlertMsg}}
                </span>
              </div>
            </div>
              <div *ngIf="showBasicCreditCardInfo">
              <div class="col-sm-12 ml-md-4 pl-md-2">
                 <img *ngIf="savedCardDetails.cardType" [src]='getCardImageURL' alt="{{getCardImageURL}}" >
                <span class="ml-sm-3 saved-number-img">
                  <img src="/assets/tfb-payment/images/maskNumber.PNG" alt="{{creditCardNumber}}"> {{creditCardNumber}}
                  <span *ngIf='autoPaySelectedOption == "manage"'>
                    <span> - {{authoringContent?.validThruText}} </span>
                    <span class="" *ngIf="cardExpirationDate !== ''">
                      {{cardExpirationDate}}
                    </span>
                  </span>
                  <span *ngIf="isEdit" > 
                      <a class="inline_link cursorPointer" (click)="changeSavedInfo(creditCardText, 'credit','edit')">{{authoringContent?.editTxt}} </a> 
                  </span>
                  <span *ngIf="!isEdit" class="Link-Change-disable" > 
                      {{authoringContent?.editTxt}} 
                  </span>

                  <span *ngIf="isChange"> 
                      <a class="inline_link cursorPointer" (click)="changeSavedInfo(creditCardText, 'credit','change')">{{authoringContent?.changeTxt}} {{authoringContent?.cardTxt}}</a>
                  </span>
                  <span *ngIf="!isChange" class="Link-Change-disable" > 
                      {{authoringContent?.changeTxt}} {{authoringContent?.cardTxt}}
                  </span>
                </span>
              </div>
              </div>
          </div>
          <div class="radio radio-primary mb-1" *ngIf="showSeperateCreditDebit">
            <input type="radio" [(ngModel)]="selectedPaymentType" name="selectedPaymentType" id="debitCard" value="debitCard"
              (change)="onChangeDirectDepositV1($event, 'debitCard')">
            <label for="debitCard">Debit card</label>
              <div *ngIf="showBasicDebitCardInfo">
              <div class="col-sm-12 ml-md-4 pl-md-2">
                 <img *ngIf="savedCardDetails.cardType" [src]='getCardImageURL' alt="{{getCardImageURL}}" >
                <span class="ml-sm-3 saved-number-img">
                  <img src="/assets/tfb-payment/images/maskNumber.PNG" alt="{{creditCardNumber}}"> {{creditCardNumber}}
                  <span *ngIf='autoPaySelectedOption == "manage"'>
                    <span> - {{authoringContent?.validThruText}} </span>
                    <span class="" *ngIf="cardExpirationDate !== ''">
                      {{cardExpirationDate}}
                    </span>
                  </span>
                  <span *ngIf="isEdit" > 
                      <a class="inline_link cursorPointer" (click)="changeSavedInfo(debitCardText, 'credit','edit')">{{authoringContent?.editTxt}} </a> 
                  </span>
                  <span *ngIf="!isEdit" class="Link-Change-disable" > 
                      {{authoringContent?.editTxt}} 
                  </span>

                  <span *ngIf="isChange"> 
                      <a class="inline_link cursorPointer" (click)="changeSavedInfo(debitCardText, 'credit','change')">{{authoringContent?.changeTxt}} {{authoringContent?.cardTxt}}</a>
                  </span>
                  <span *ngIf="!isChange" class="Link-Change-disable" > 
                      {{authoringContent?.changeTxt}} {{authoringContent?.cardTxt}}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="radio radio-primary mb-1" *ngIf="showMayCreditDebit">
            <div class="row" >
              <div class="col-sm-12">
                <input type="radio" [(ngModel)]="selectedPaymentType" name="selectedPaymentType" id="managecard" value="managecard"
                  (change)="onChangeDirectDepositV1($event, 'credit')">
                <label for="managecard">{{authoringContent?.creditCardTxt}}</label>
                <span *ngIf="notifyMessage" class="optional_errortext alert-icon-text"></span>
                <span *ngIf="notifyMessage" class="alert-card-text" >
                  {{creditCardAlertMsg}}
                </span>
              </div>
            </div>
            <div *ngIf="ShowCraditCardOption && !showcardDefault && !(autoPaySelectedOption == 'enroll') ">
              <div class="col-sm-12 ml-md-4 pl-md-2">
                 <img *ngIf="savedCardDetails.cardType" [src]='getCardImageURL' alt="{{getCardImageURL}}" >
                <span class="ml-sm-3 saved-number-img">
                  <img src="/assets/tfb-payment/images/maskNumber.PNG" alt="{{creditCardNumber}}"> {{creditCardNumber}}
                  <span *ngIf='autoPaySelectedOption == "manage"'>
                    <span> - {{authoringContent?.validThruText}} </span>
                    <span class="" *ngIf="cardExpirationDate !== ''">
                      {{cardExpirationDate}}
                    </span>
                  </span>
                  <span *ngIf="isEdit" > 
                      <a class="inline_link cursorPointer" (click)="changeSavedInfo('managecard', 'managecard','edit')">{{authoringContent?.editTxt}} </a> 
                  </span>
                  <span *ngIf="!isEdit" class="Link-Change-disable" > 
                      {{authoringContent?.editTxt}} 
                  </span>

                  <span *ngIf="isChange"> 
                      <a class="inline_link cursorPointer" (click)="changeSavedInfo('managecard', 'managecard','change')">{{authoringContent?.changeTxt}} {{authoringContent?.cardTxt}}</a>
                  </span>
                  <span *ngIf="!isChange" class="Link-Change-disable" > 
                      {{authoringContent?.changeTxt}} {{authoringContent?.cardTxt}}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedPaymentType === 'Bank'">
    <div class="autopayContainerBD ml-md-5 pl-md-4 col-12 col-md-11 pl-0 pr-0" *ngIf="!achService.isBankInfoSaved">
      <app-ach [flowType]="'autoPay'" (achBillPayAliasSuccess)="onAchBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'ach')"
        [banNumber]="banNumber" [orgId]="orgID"></app-ach>
    </div>
  </div>
  <div class="autopayContainerCC" *ngIf="selectedPaymentType === 'Card' || selectedPaymentType === 'managecard'">
    <div class="ml-md-5 pl-md-4 col-12 col-md-11 pl-0 pr-0" *ngIf="!creditService.isCcInfoSaved">
        <app-credit-card *ngIf="!isEdit" [isEdit]="false" [paymentDetails]="paymentProfileData" [paymentCardType]="'paymentCreditDebitCard'" [flowType]="'autoPay'" (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'credit')"
        [banNumber]="banNumber" [orgId]="orgID"></app-credit-card>

        <app-credit-card *ngIf="!isChange" [isEdit]="true" [paymentDetails]="" [cardBankInfo]="(this.mayReleaseFlag && this.autoPaySelectedOption == 'manage')?paymentProfileData:''" [manageAutopay]="(this.mayReleaseFlag && this.autoPaySelectedOption == 'manage')"  (onEnrollCredit)="(this.mayReleaseFlag && this.autoPaySelectedOption == 'manage')?onEnrollCredit($event):''" [creditMigrationDate]="(this.mayReleaseFlag && this.autoPaySelectedOption == 'manage')?creditMigrationDate:''" [paymentCardType]="'paymentCreditDebitCard'" [mayReleaseFlag]="apdJuneRelease && mayReleaseFlag" [flowType]="'autoPay'" (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'credit')" [banNumber]="banNumber" [orgId]="orgID"></app-credit-card>
    </div>
    
  </div>
  <div class="autopayContainerCC" *ngIf="((selectedPaymentType === 'Card'|| selectedPaymentType === 'managecard') && !creditService.isCcInfoSaved && showcardDefault) || (autoPaySelectedOption == 'enroll' && selectedPaymentType === 'Card')">
    <div class="ml-md-5 pl-md-4 col-12 col-md-11 pl-0 pr-0">
    <app-credit-card   [paymentDetails]="paymentProfileData" [paymentCardType]="'paymentCreditDebitCard'" [flowType]="'autoPay'" [cardBankInfo]="(this.mayReleaseFlag && this.autoPaySelectedOption == 'manage')?paymentProfileData:''" [manageAutopay]="(this.mayReleaseFlag && this.autoPaySelectedOption == 'manage')" [creditMigrationDate]="(this.mayReleaseFlag && this.autoPaySelectedOption == 'manage')?creditMigrationDate:''" [paymentCardType]="'paymentCreditDebitCard'" [mayReleaseFlag]="apdJuneRelease && mayReleaseFlag" (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'credit')"
    [banNumber]="banNumber" [orgId]="orgID"  (onEnrollCredit)="(this.mayReleaseFlag && this.autoPaySelectedOption == 'manage')?onEnrollCredit($event):''"></app-credit-card>
    </div>
  </div>   
  
  <div class="autopayContainerCC" *ngIf="selectedPaymentType === 'creditCard'">
    <div class="ml-md-5 pl-md-4 col-12 col-md-11 pl-0 pr-0" *ngIf="!creditService.isCcInfoSaved">
        <app-credit-card *ngIf="!isEdit && (!profilePayMethodeCode || profilePayMethodeCode!='DC')" [isEdit]="false" [paymentDetails]="paymentProfileData" [cardBankInfo]="paymentProfileData" [manageAutopay]="true" [paymentCardType]="'paymentCreditCard'" [creditMigrationDate]="creditMigrationDate" [flowType]="'autoPay'" (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'credit')" (onEnrollCredit)="onEnrollCredit($event)"
        [banNumber]="banNumber" [orgId]="orgID"></app-credit-card>

        <app-credit-card *ngIf="!isChange && profilePayMethodeCode!='DC'" [isEdit]="true" [paymentDetails]="" [cardBankInfo]="paymentProfileData" [manageAutopay]="true" [paymentCardType]="'paymentCreditCard'" [creditMigrationDate]="creditMigrationDate"  [flowType]="'autoPay'" (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'credit')" (onEnrollCredit)="onEnrollCredit($event)" [banNumber]="banNumber" [orgId]="orgID"></app-credit-card>
    </div>
    <div class="ml-md-5 pl-md-4 col-12 col-md-11 pl-0 pr-0">
      <app-credit-card *ngIf="profilePayMethodeCode=='DC'" [isEdit]="true" [paymentDetails]="" [cardBankInfo]="paymentProfileData" [manageAutopay]="true" [paymentCardType]="'paymentCreditCard'" [creditMigrationDate]="creditMigrationDate"  [flowType]="'autoPay'" (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'credit')" (onEnrollCredit)="onEnrollCredit($event)" [banNumber]="banNumber" [orgId]="orgID"></app-credit-card>
    </div>
    
  </div>
  <div class="autopayContainerCC" *ngIf="(selectedPaymentType === 'creditCard' && !creditService.isCcInfoSaved && showcardDefault) || (autoPaySelectedOption == 'enroll' && selectedPaymentType === 'creditCard')">
    <div class="ml-md-5 pl-md-4 col-12 col-md-11 pl-0 pr-0">
    <app-credit-card   [paymentDetails]="paymentProfileData" [cardBankInfo]="paymentProfileData" [paymentCardType]="'paymentCreditCard'" [creditMigrationDate]="creditMigrationDate" [flowType]="'autoPay'" (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'credit')" (onEnrollCredit)="onEnrollCredit($event)"
    [banNumber]="banNumber" [orgId]="orgID"></app-credit-card>
    </div>
  </div>

  <div class="autopayContainerCC" *ngIf="selectedPaymentType === 'debitCard'">
    <div class="ml-md-5 pl-md-4 col-12 col-md-11 pl-0 pr-0" *ngIf="!creditService.isCcInfoSaved">
        <app-credit-card *ngIf="!isEdit && profilePayMethodeCode!='CC'" [isEdit]="false" [paymentDetails]="paymentProfileData" [paymentCardType]="'paymentDebitCard'" [flowType]="'autoPay'" (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'credit')"
        [banNumber]="banNumber" [orgId]="orgID"></app-credit-card>

        <app-credit-card *ngIf="!isChange && profilePayMethodeCode!='CC'" [isEdit]="true" [paymentDetails]="" [paymentCardType]="'paymentDebitCard'" [flowType]="'autoPay'" (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'credit')" [banNumber]="banNumber" [orgId]="orgID"></app-credit-card>
    </div>
    <div class="ml-md-5 pl-md-4 col-12 col-md-11 pl-0 pr-0">
      <app-credit-card *ngIf="profilePayMethodeCode=='CC'" [isEdit]="true" [paymentDetails]="" [paymentCardType]="'paymentDebitCard'" [flowType]="'autoPay'" (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'credit')" [banNumber]="banNumber" [orgId]="orgID"></app-credit-card>
    </div>
    
    
  </div>
  <div class="autopayContainerCC" *ngIf="(selectedPaymentType === 'debitCard' && !creditService.isCcInfoSaved && showcardDefault) || (autoPaySelectedOption == 'enroll' && selectedPaymentType === 'debitCard')">
    <div class="ml-md-5 pl-md-4 col-12 col-md-11 pl-0 pr-0">
    <app-credit-card   [paymentDetails]="paymentProfileData" [paymentCardType]="'paymentDebitCard'" [flowType]="'autoPay'" (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)" (onSuccess)="onSuccessCredit($event, 'credit')"
    [banNumber]="banNumber" [orgId]="orgID"></app-credit-card>
    </div>
  </div>
      
  <div *ngIf='autoPaySelectedOption === "manage" || autoPaySelectedOption == "cancel"'>
    <div class="border-bottom d-block d-sm-block d-md-none d-lg-none"></div>
    <div class="row d-block d-sm-block d-md-none d-lg-none pt-4 pb-4">
      <div class="col-12 col-md-3 media">
        <a class="align-self-center" href="javascript:void(0)" (click)='openCancelModel()'>{{authoringContent?.turnOffAutoPayText}}</a>
      </div>
    </div>
    <div class="border-bottom d-block d-sm-block d-md-none d-lg-none"></div>
  </div>
  <div class="ml-md-5 pl-md-4 pt-5 pb-3 col-md-6 col-md-12 pl-0 pr-0 pr-md-3">
    <div class="pl-2 pr-0 pr-md-3 legal">
      <span [innerHTML]="authoringContent?.termAndConditionStartTxt"></span>
      <a href="javascript:void(0)" (click)='toggleAutopay()' class="legal_link">{{authoringContent?.autopayLink}}</a>
      {{authoringContent?.termAndConditionMiddleTxt}}
      {{authoringContent?.tcLink}}
      {{authoringContent?.termAndConditionEndTxt}}
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-12 col-md-6 media pl-md-5">
      <a class="align-self-center d-none d-sm-none d-md-block d-lg-block pl-md-5" *ngIf='autoPaySelectedOption == "manage"|| autoPaySelectedOption == "cancel"'
        href="javascript:void(0)" (click)='openCancelModel()'>{{authoringContent?.turnOffAutoPayText}}</a>
    </div>
    <div class="col-12 col-md-6 text-right btn-wrapperMarginAutoPay">
            <a href="javascript:void(0)" *ngIf="!isPartnerFlow" class="black_btn mrg-rt-10 col-12 col-md-4 d-none d-sm-none d-md-inline-block
        d-lg-inline-block desk-button-width desk-button-right-space" (click)='generateClickDetailsCancelButtonAutoPay()'>{{authoringContent?.cancelButtonText}}</a>
      <button tabindex="61" *ngIf="isBillingPartnerFlow" (click)="onCancel()"
              class="black_btn mrg-rt-10">{{authoringContent?.cancelButtonText}}</button>
      <button *ngIf='autoPaySelectedOption == "enroll"' class="primary_btn col-12 col-md-4 desk-button-width" 
      [disabled]="!cardTypeVal || !luhnCheck || !formValid || !enableRetry" (click)="submitEnroll()">{{authoringContent?.enrollButtonText}}</button>
      <button *ngIf='autoPaySelectedOption == "manage" || autoPaySelectedOption == "cancel"' class="primary_btn col-12 col-md-5 desk-button-width {{manageEnroll}}"
        [disabled]="!cardTypeVal || !luhnCheck || !formValid || !enableRetry" (click)="manageEnroll()">{{authoringContent?.saveChangesBtnText}}</button>
      <a href="javascript:void(0)" class="black_btn mrg-rt-10 col-12 col-md-4 d-md-none d-lg-none" (click)='generateClickDetailsCancelButtonAutoPay()'>{{authoringContent?.cancelButtonText}}
      </a>
    </div>
  </div>
  <div *ngIf="_autoPaymentSvc.successComponent">
    <app-success></app-success>
  </div>
  
  <div *ngIf="callEnrollCredit">
    <app-enroll-credit-card (onSuccess)="onSuccessenrollCredit($event)" ></app-enroll-credit-card>
  </div>
  <ng-template [ngIf]="_autoPaymentSvc.cancelComponent && paymentProfileData">
    <app-cancel></app-cancel>
  </ng-template>
  <ng-template [ngIf]="_autoPaymentSvc.showAutopay">    

    <div class="BG-modal" [ngStyle]="{'display':display}"></div>
    <div class="modal terms-and-conditions-popup" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header pb-2">
            <button type="button" class="close" aria-label="Close" (click)='toggleAutopay()'>
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">{{authoringContent?.paymentTermsTitle}} </h4>
           
          </div>
          <div class="col-12 pb-2">
              <a href="javascript:void(0)" class="print" (click)="print('print')">
                <img src="../../../assets/tfb-payment/images/icons/icon_print_magenta.png" alt="" />{{authoringContent?.printTxt}}
               </a>        
           </div>
          <div class="modal-body ">  
              <ng-template [ngIf]="errorAlertAutoPayTC.show">
                  <div class="text-error-msg tab-margin">
                    {{errorAlertAutoPayTC.message.errorMessage}}
                  </div>
                </ng-template>            
            <div class="modal-scroll  Lorem-ipsum-dolor-si" [innerHTML]="this.tcAutoPayAgreementContent" id="print" ></div>           
         </div>          
          <div class="modal-footer">
            <button type="button" class="primary_btn marRgtTerms" (click)='toggleAutopay()' >Close</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal !-->
  </ng-template>
  <ng-template [ngIf]="_autoPaymentSvc.showTC">    
    <div class="BG-modal" [ngStyle]="{'display':display}"></div>
    <div class="modal terms-and-conditions-popup" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header pb-2">
            <button type="button" class="close" aria-label="Close" (click)='toggleTnC()'>
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title"> {{authoringContent?.termsConditionTitle}} </h4>
            
          </div>
          <div class="col-12 pb-2">
              <a href="javascript:void(0)" class="print" (click)="print('print')">
                <img src="../../../assets/tfb-payment/images/icons/icon_print_magenta.png" alt="" />{{authoringContent?.printTxt}}
               </a>        
           </div>
          <div class="modal-body ">  
              <ng-template [ngIf]="errorAlertTC.show">
                  <div class="text-error-msg tab-margin">
                    {{errorAlertTC.message.errorMessage}}
                  </div>
                </ng-template>            
            <div class="modal-scroll  Lorem-ipsum-dolor-si" [innerHTML]="this.termsAndConditionTxtData" id="print" ></div>           
         </div>          
          <div class="modal-footer">
            <button type="button" class="primary_btn marRgtTerms" (click)='toggleTnC()' >Close</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal !-->

  </ng-template>
</div>
<div class="container mt-5 mb-5" *ngIf='!this.isEnrollAutopayEligible || !this.isManageAutopayEligible'>
  {{this.eligibleAutoPayMsg}}
</div>

<div class="container" *ngIf="(show)">    
  <div  class="text-error-msg error-message tab-margin mrg-vr-10">
    {{errorMessage}}</div>
</div>
<div class="container mt-5 mb-5">
  <ng-template [ngIf]="errorAlert.show">
    <div class="text-error-msg tab-margin">
      {{errorAlert.message.errorMessage}}
    </div>
  </ng-template>
</div>

<div class="container mt-5 mb-5">
  <ng-template [ngIf]="errorAlertManageEN.show">
    <div class="text-error-msg tab-margin">
      {{errorAlertManageEN.message.errorMessage}}
    </div>
  </ng-template>
</div>

<div class="container mt-5 mb-5">
  <ng-template [ngIf]="errorAlertSubmitEN.show">
    <div class="text-error-msg tab-margin">
      {{errorAlertSubmitEN.message.errorMessage}}
    </div>
  </ng-template>
</div>
<div class="container media mb-5" *ngIf="authoringContent && (isLogo)">
    <span class="spacerBlkAP"></span>
    <div class="Make-a-payment media-body col-md-8 col-sm-12 col-xs-12 pl-0">
      <a class="entrust-logo inline_link d-inline-block ml-0 mr-2" (click)="onEntrust()">
        <img src="./assets/tfb-payment/images/icons/entrust-site-seal-small.png" alt="Secured by Entrust SSL Verify" /></a>
        <div class="entrust-text d-inline-block align-middle ">
            {{content?.landingInfo?.enTrustTxt}}
            <a class="entrust-textlink" href="{{content?.landingInfo?.enTrustPrivacyUrl}}" target="_blank">{{content?.landingInfo?.enTrustTxt1}}</a>
          </div>
  </div>
</div>
<app-footer  *ngIf="!isRunningInGlobalNav"></app-footer>
<app-payment-error-popup (showSubHeadertxtChange)="showSubHeadertxt=$event" *ngIf="authoringContent"></app-payment-error-popup>