<header class="header">
  <div class="container">
    <div class="row">
      <div class="col-6 logo-section">
        <div class="logo-wrapper" *ngIf="!isPartnerFlow">
          <a  tabindex="80" href="{{authoringContent?.landingInfo?.sspUrl}}" (click)="onTmobileLogo()" >
            <img src="/assets/tfb-payment/images/TMO_Logo.png" class="payment-logo" alt="">
          </a>
        </div>
        <div class="logo-wrapper" *ngIf="isPartnerFlow">
            <img src="/assets/tfb-payment/images/TMO_Logo.png" class="payment-logo" alt="">
        </div>
        <!-- Logo wrapper end here -->
      </div>
      <div *ngIf="enableCancel && enablePartnerCancel" class="col-6 text-right" style="top: 25px;">
        <a href="javascript: void(0);" (click)="clickCancel()">{{authoringContent?.landingInfo?.cancelOrderLinkText}}</a>
      </div>
      <div *ngIf="enableCancel && enableAhubCancel" class="col-6 text-right" style="top: 25px;">
        <a  tabindex="81" href="javascript:void(0)" (click)="onHeaderCancel()">{{authoringContent?.landingInfo?.cancelOrderLinkText}}</a>
      </div>
    </div>
  </div>
</header>
