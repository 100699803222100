import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {ApiInterceptor} from './http-interceptors/api-interceptor';
import {PnpClientService} from './services/pnp-client.service';
import {configToken, PnpConfig} from './interfaces/pnp-config';

@NgModule({
  imports: [HttpClientModule],
  exports: []
})
export class PnpframeworkModule {
  static forRoot(config: PnpConfig): ModuleWithProviders<PnpframeworkModule> {
    return {
      ngModule: PnpframeworkModule,
      providers: [
        {
          provide: configToken,
          useValue: config
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiInterceptor,
          multi: true
        },
        PnpClientService,
      ]
    };
  }
}
