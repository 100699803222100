<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog" *ngIf="authoringContent" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3>{{authoringContent.cancelHeadingTxt}}</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseHandled('close')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-md-5">
          <em class="fa fa-exclamation-circle cancel-icon mr-3" aria-hidden="true"></em>
          <span *ngIf="cancelDescription" class="cancel-icon-text text-color ">{{cancelDescription}}</span>
          <span *ngIf="!cancelDescription" class="cancel-icon-text text-color cancelConfirmationText">{{authoringContent.cancelConfirmationText}}</span>
        </p>
        <p *ngIf="cancelDescription" class="text-color mt-5 mt-sm-5 mt-md-2 mt-lg-0 cancelConfirmationText">{{authoringContent.cancelConfirmationText}}</p>
        <ng-template [ngIf]="errorMessage">
          <p class="row mrg-bm-20 error_Text">{{errorauthoringContent.genericErrorApTxt}}</p>
        </ng-template>
      </div>
      <div class="modal-footer">
        <div class="col-12 col-sm-12 btn-wrapper">
          <button *ngIf="!returnURI" class="black_btn mrg-rt-10 col-12 col-sm-5 ml-sm-5" (click)="onCloseHandled('cancel')">{{authoringContent?.cancelButtonText}}</button>
          <a *ngIf="returnURI" href="javascript:void(0)" class="black_btn mrg-rt-10 col-12 col-sm-5 ml-sm-5" (click)='return()'>{{authoringContent?.cancelButtonText}}</a>
          <button class="primary_btn col-12 col-sm-5" [disabled]="errorMessage || careAuthType  || !enableRetry" (click)="submitConfirm()">{{authoringContent?.confirmButtonText}}</button>          
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf='_autoPaymentSvc.successComponent'>
  <app-success></app-success>
</div>