<div class="">
  <section class="ach-info">
    <div class="ach-container">
      <form [formGroup]="achGroup">
        <div class="row" *ngIf='achService.isBankInfoSaved'>
          <div class="col-sm-12">
            <span class="icon-bank"></span>
            <span class="weight-normal">
              {{maskedAccount}}
              <a *ngIf="!isCurrentPayment" class="inline_link"
                (click)="removeBankInfo()">{{content?.ach?.changeTxt}}</a>
            </span>
          </div>
        </div>
        <div *ngIf='!achService.isBankInfoSaved'>
          <div class="row">
            <div class="col-sm-12">
              <h3>{{content?.ach?.bankAccountInfoTxt}}</h3>
            </div>
          </div>
          <div class="row mrg-bm-20">
            <div class="col-sm-12">
              <img class="img-fluid" alt="" src="/assets/tfb-payment/images/CheckGraphic.svg" />
            </div>
          </div>
          <div class="row">
            <!-- Bank Routing Number Field -->
            <div class="col-sm-12 col-md-6">
              <div class="label_box">
                <div class="question">
                    <input  type="text"
                            class="routingNumber"
                            id="routing-number" name="routingNumber" #routingNumber
                            formControlName="routingNumber"
                            maxlength="9"
                            fieldName="routingNumber"
                            autocomplete="off"
                            [class.hide-input]="!showRoutingNumber"
                            class="form-control"
                            cvvACHInput
                            (input)="validateRoutingNumber(achGroup.get('routingNumber')?.value)"
                            tabindex="46" required
                            autofocus/>

                    <i class="fa fa-eye-slash fa-routing"
                       [ngClass]="{'fa-eye': !showRoutingNumber, 'fa-eye-slash':
                      showRoutingNumber}" (click)="toggleRoutingNumber()"></i>
                  <p class="optional_text optional_errortext position-absolute"
                    *ngIf="(!isRoutingNumberInvalid) && (!routingNumberLengthErr) && (achGroup.controls['routingNumber'].touched)">
                    {{this.content?.ach?.routingNumberError}}</p>
                  <p class="optional_text optional_errortext position-absolute" *ngIf="isRoutingNumberInvalid">
                    {{this.content?.ach?.invalidRoutingNumberError}}</p>
                  <label class="clear">{{content?.ach?.routingNumberLbl}}</label>
                </div>
              </div>
            </div>
            <!-- Bank Account Number Field -->
            <div class="col-sm-6">
              <div class="label_box">
                <div class="question" appOptionalFloatingLabel>
                  <input type="text"
                         #accountNumber
                         formControlName="accountNumber"
                         autocomplete=off
                         required minlength="6" maxlength="20"
                         (input)="validateAcntNumbr(achGroup.get('accountNumber')?.value)"
                         tabindex="32"
                         cvvACHInput
                         [class.hide-input]="!showAcountDetails"
                         [attr.disabled]="bankName == '' ? true : null"/>
                    <i class="fa fa-eye-slash fa-accountNUmber"
                       [ngClass]="{'fa-eye': !showAcountDetails, 'fa-eye-slash':
                      showAcountDetails}" (click)="toggleAcountDetails()"></i>
                    <p class="optional_text optional_errortext position-absolute"
                    *ngIf="((achGroup.controls['accountNumber'].errors?.required || achGroup.controls['accountNumber'].errors?.minlength))
                    || achGroup.controls['accountNumber'].errors?.pattern
                    && (achGroup.controls['accountNumber'].touched)">
                    {{this.content?.ach?.accountNumberError}} </p>
                  <label class="clear">{{content?.ach?.accountNumberLbl}}</label>
                </div>
              </div>
            </div>
            <!-- Bank name Field -->
            <div class="col-sm-6">
              <div class="label_box">
                <div class="question">
                  <input tabindex="33" type="text" formControlName="bankNameInput" required
                     (ngModelChange)="validateBankName()" />
                  <p class="optional_text optional_errortext position-absolute"
                    *ngIf="achGroup.controls['bankNameInput'].errors?.required && (achGroup.controls['bankNameInput'].touched)">
                    {{this.content?.ach?.bankNameError}}</p>
                  <label class="{{bankNameFloatingCss}} clear">{{content?.ach?.bankNameLbl}}</label>
                </div>
              </div>
            </div>
            <!-- Bank Account Type Field -->
            <div class="col-sm-6 mrg-bm-20">
              <div class="custom-ddl-wrapper nopadding-left">
                <div ngbDropdown #acTypeDropDown="ngbDropdown">
                  <div class="custom_floatlabel account_type_lbl" [ngClass]="{'valid-input': selectedAccntType}"
                    [attr.disabled]="bankName == '' ? true : null">{{content?.ach?.accountTypeLbl}}</div>
                  <button tabindex="34" type="button" class="btn btn-outline-primary custom-ddl-btn state-dropdown"
                    id="dropdownBasic1" ngbDropdownToggle [attr.disabled]="bankName == '' ? true : null"
                    (keydown.arrowdown)="acTypeDropDown.open()">
                    <div class="selectd-val">
                      <span>{{ selectedAccntType !== undefined ? selectedAccntType : "&nbsp;" }}</span>
                      <input type="hidden" formControlName="accountType" />
                    </div>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="acnttype-dropdown-menu">
                    <button ngbDropdownItem type="button" class="dropdown-item" *ngFor="let accnttype of accountTypes"
                      (click)="selectAcntType(accnttype)">
                      <div class="default-item">
                        <span>{{accnttype.type}}</span>
                      </div>
                    </button>
                  </div>
                  <p class="optional_text optional_errortext position-absolute" *ngIf="achGroup.controls['accountType'].errors?.required && achGroup.controls['accountType'].touched">
                    Please select a valid account type</p>
                </div>
              </div>
            </div>
            <!-- Name on account Field -->
            <div class="col-sm-6">
              <div class="label_box">
                <div class="question" appOptionalFloatingLabel>
                  <input type="text" tabindex="35" formControlName="accountName"
                    [attr.disabled]="bankName == '' ? true : null" maxlength="40" required appCharactersOnly
                    (ngModelChange)="validateAcntName()" aria-autocomplete="none" aria-haspopup="false" autocomplete=off/>

                  <p class="optional_text optional_errortext position-absolute"
                    *ngIf="achGroup.controls['accountName'].errors?.required && (achGroup.controls['accountName'].touched)">
                    {{this.content?.ach?.accountNameError}} </p>
                  <label class="clear">{{content?.ach?.accountNameLbl}}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row mrg-bm-20" *ngIf="isInstallmentRadioSelected">
            <div class="col-sm-12 media">
              <span class="icons phone">
                <img src="/assets/tfb-payment/images/phoneIcon.png" class="payment-info" alt="">
              </span>
              <div class="d-inline-block phoneBlock media-body">
              <h3 class="mt-0 pt-0 mb-2 headingPhone">{{content?.ach?.phoneNotificationTitle}}</h3>
              <p class="optional_text helpTxtPhone mb-3" >
                {{content?.ach?.phoneNotificationMessage}} </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="label_box">
                <div class="question" appOptionalFloatingLabel>
                  <input (input)="checkPhoneNumber($event.target.value)" type="text" [attr.disabled]="bankName == '' ? true : null" formControlName="bankPhone" maxlength="14" appPhoneMask required class="autoFill"/>
                  <p class="optional_text position-relative mb-0" *ngIf="achGroup.controls['bankPhone'].untouched">
                    {{content?.ach?.phoneHelpMessage}}</p>
                  <p class="optional_text optional_errortext position-relative mb-0" *ngIf="achGroup.controls['bankPhone'].errors?.required && achGroup.controls['bankPhone'].touched">
                      {{content?.ach?.phoneErrorMessage}}</p>
                      <p class="optional_text optional_errortext position-relative mb-0" *ngIf="phoneNumberError.status">
                        {{phoneNumberError.message}}
                      </p>
                      <p class="optional_text optional_successtext position-relative mb-0" *ngIf="phoneNumberSuccess.status">
                        {{phoneNumberSuccess.message}}
                      </p>
                  <label class="clear">{{content?.ach?.phone}}</label>
                </div>
              </div>
            </div>
          </div>

          <ng-template [ngIf]="errorAlert.show">
            <div class="text-error-msg tab-margin">
              {{errorAlert?.message?.errorMessage}}</div>
          </ng-template>
        </div>
      </form>
    </div>
  </section>
</div>
<app-loader></app-loader>
