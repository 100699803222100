import { Injectable } from '@angular/core';
import { AuthHttpService } from './http-wrapper.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentDetailsService } from './environment-details.service';
import { ApigeeTokenService } from './apigee-token.service';
import { HttpCacheService } from './http-cache.service';
import { COMMON_CONSTANTS } from '../common/core/common.constants';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetCreditCardTypeService {
  

  constructor(private httpClient: AuthHttpService,
     private _envSvc: EnvironmentDetailsService,
     private _apigeeSvc: ApigeeTokenService, private _cacheSvc: HttpCacheService, private _http: HttpClient) { }

     private _isTokenRefrshed = false;
     _cardTokenSubscriber: Subscription;
     


  getCardDetails(cardNumber: string ) {

    let isV4CardTypeEnabled = environment.ENABLE_V4CARDTYPE_API;
    
    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    const isoDate  = new Date().toISOString();
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Authorization': COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken,
          'content-type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
          'application-id': COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY,
          'channel-id': COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_WEB_KEY,
          'timestamp': isoDate.replace('T', ' ')
        }
      )
    };

    if (isV4CardTypeEnabled) {

      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.CURR_SESSION_ENDPOINTS.APIGEE_CARDTYPE_VALIDATION );
      } else {
        return this.httpClient.post(
          this._envSvc.CURR_SESSION_ENDPOINTS.APIGEE_CARDTYPE_VALIDATION, 
          {
            'billingAccount': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY),
            'bin': cardNumber
          }, 
          httpOptions);
      }
    } else if (!isV4CardTypeEnabled) {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.CURR_SESSION_ENDPOINTS.APIGEE_CARDTYPE_VALIDATION );
      }
    }

  }
}
