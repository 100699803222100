<!-- <app-header></app-header> -->
  <div class="container">
    <div class="idle-error-screen-wrapper">
      <div class="row">
        <div class="col-12 error-screen-title">
            <img src="../../assets/tfb-payment/images/icon_error.svg">
            <h1>Your session has expired</h1>
        </div>
        <div class="col-12 idle-error-screen-msg">
          <p>Your session has timed out due to inactivity. Please navigate back to {{partnerName}} to view your Billing and Payment details.</p>
          <!-- <a *ngIf="returnURI" href="{{returnURI}}">{{returnURI}}</a> -->
        </div>
      </div>
    </div>
  </div>
<!-- <app-footer></app-footer> -->
