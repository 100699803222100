import { Injectable, Injector } from "@angular/core";
import { HttpCacheService } from "./http-cache.service";
import {
    COMMON_CONSTANTS,
    MakePaymentConstant,
} from "../common/core/common.constants";
import { DecodeParamsService } from "./decode-params.service";
import { WCS_ENPOINT_CONST } from "../common/core/urls.mocks.constants";
import { ContentService } from "./content.service";
import { MakePaymentService } from "./make-payment.service";
import { EnvironmentDetailsService } from "./environment-details.service";
import { ApigeeTokenService } from "./apigee-token.service";
import * as _ from "lodash";
import { ActivatedRouteSnapshot, Params } from "../../../node_modules/@angular/router";
import { environment } from "../../environments/environment";
import { ErrorMessageService } from "./error-message.service";
import { BehaviorSubject } from "rxjs";
import { DataSharingService } from "./data-sharing.service";
import { OktaAuthorizationService } from "./okta.auth.service";
import { Router } from "@angular/router";
import { isNullOrUndefined } from "src/app/utilities/helper-utils";
import { v4 as uuid } from "uuid";
import { PnpClientService } from "src/pnpframework/public-api";
@Injectable({
    providedIn: "root",
})
export class SetupRouteService {
    _cacheSvc: HttpCacheService;
    _envSvc: EnvironmentDetailsService;
    _apigeeSvc: ApigeeTokenService;
    _errMsgService: ErrorMessageService;
    _oktaSvc: OktaAuthorizationService;
    _pnpClientService: PnpClientService;
    decodeParamsSvc: DecodeParamsService;
    makePaymentSvc: MakePaymentService;
    dataSharingSvc: DataSharingService;
    router: Router;
    isResolved: boolean;
    isLoaded: boolean;
    euiCode: any;
    BanNumber: string;
    OrgID: string;
    BillPayFlag: string;
    ReturnURI: string;
    authType: string;
    token: string;
    flow: string;
    CancelAutoPayFlag: string;
    ssoMessage = { error: "", show: true, errorType: "generic" };
    private checkForEUIedirect = new BehaviorSubject(false);
    isEuiRedirect = this.checkForEUIedirect.asObservable();
    isRedirect = false;
    authoringContentData: any;
    transactionID: any;
    partnerHandShake = new BehaviorSubject(false);
    partnerHandShakecall = this.partnerHandShake.asObservable();
    queryParam_AT: any;
    queryParam_DS: any;
    queryParam_TS: any;
    queryParam_EPID: any;
    
    constructor( private injector: Injector ) {
        this.flow = COMMON_CONSTANTS.ENROLL_FLOW;
        this.token = "";
        this._cacheSvc = injector.get<HttpCacheService>(HttpCacheService);
        this._envSvc = injector.get<EnvironmentDetailsService>(EnvironmentDetailsService);
        this._apigeeSvc = injector.get<ApigeeTokenService>(ApigeeTokenService);
        this._errMsgService = injector.get<ErrorMessageService>(ErrorMessageService);
        this._oktaSvc = injector.get<OktaAuthorizationService>(OktaAuthorizationService);
        this._pnpClientService = injector.get<PnpClientService>(PnpClientService);
        this.decodeParamsSvc = injector.get<DecodeParamsService>(DecodeParamsService);
        this.makePaymentSvc = injector.get<MakePaymentService>(MakePaymentService);
        this.dataSharingSvc = injector.get<DataSharingService>(DataSharingService);
        this.router = injector.get<Router>(Router);
    }

    _setupRoute(qParams, appType: string): boolean { 
        if (!(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY)) &&
            !(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY))) {

            if (COMMON_CONSTANTS.ENABLE_PID_FLOW !== undefined && COMMON_CONSTANTS.ENABLE_PID_FLOW) {
                const queryParam = this.decodeParamsSvc.decodeBase64(qParams[COMMON_CONSTANTS.PID_KEY] ?
                    qParams[COMMON_CONSTANTS.PID_KEY] : '');
                this.BanNumber = queryParam[COMMON_CONSTANTS.BAN_NUMBER_KEY] ? queryParam[COMMON_CONSTANTS.BAN_NUMBER_KEY] : '';
                this.OrgID = queryParam[COMMON_CONSTANTS.ORG_ID_KEY] ? queryParam[COMMON_CONSTANTS.ORG_ID_KEY] : '';
                this.ReturnURI = queryParam[COMMON_CONSTANTS.RETURN_URL_SSP] ? queryParam[COMMON_CONSTANTS.RETURN_URL_SSP] : '';
                this.authType = queryParam[COMMON_CONSTANTS.AUTH_TYPE] ? queryParam[COMMON_CONSTANTS.AUTH_TYPE] :
                    (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE)) ?
                        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) : COMMON_CONSTANTS.IAM_KEY);
                this.token = queryParam[COMMON_CONSTANTS.TOKEN_KEY] ? queryParam[COMMON_CONSTANTS.TOKEN_KEY] : '';
                if (appType === COMMON_CONSTANTS.AUTOPAY_KEY) {
                    this.flow = queryParam[COMMON_CONSTANTS.FLOW_KEY] ? queryParam[COMMON_CONSTANTS.FLOW_KEY] : COMMON_CONSTANTS.ENROLL_FLOW;
                }
                if (appType === COMMON_CONSTANTS.MAKEPAY_KEY) {
                    this.BillPayFlag = queryParam[COMMON_CONSTANTS.BILL_PAY_FLAG] ? queryParam[COMMON_CONSTANTS.BILL_PAY_FLAG] : '';
                }
            } else {
                this.BanNumber = qParams[COMMON_CONSTANTS.BAN_NUMBER_KEY] ? qParams[COMMON_CONSTANTS.BAN_NUMBER_KEY] : '';
                this.OrgID = qParams[COMMON_CONSTANTS.ORG_ID_KEY] ? qParams[COMMON_CONSTANTS.ORG_ID_KEY] : '';
                this.ReturnURI = qParams[COMMON_CONSTANTS.RETURN_URL_SSP] ? qParams[COMMON_CONSTANTS.RETURN_URL_SSP] : '';
                this.authType = qParams[COMMON_CONSTANTS.AUTH_TYPE] ? qParams[COMMON_CONSTANTS.AUTH_TYPE] :
                    (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE)) ?
                        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) : COMMON_CONSTANTS.IAM_KEY);
                if (appType === COMMON_CONSTANTS.AUTOPAY_KEY) {
                    this.flow = qParams[COMMON_CONSTANTS.FLOW_KEY] ? qParams[COMMON_CONSTANTS.FLOW_KEY] : COMMON_CONSTANTS.ENROLL_FLOW;

                }
                if (appType === COMMON_CONSTANTS.MAKEPAY_KEY) {
                    this.BillPayFlag = qParams[COMMON_CONSTANTS.BILL_PAY_FLAG] ? qParams[COMMON_CONSTANTS.BILL_PAY_FLAG] : '';
                }
            }
            this._cacheSvc.deleteFromSessionStoarage(COMMON_CONSTANTS.BAN_NUMBER_KEY);
            this._cacheSvc.deleteFromSessionStoarage(COMMON_CONSTANTS.ORG_ID_KEY);
            this._cacheSvc.deleteFromSessionStoarage(COMMON_CONSTANTS.RETURN_URL_SSP);
            this._cacheSvc.deleteFromSessionStoarage(COMMON_CONSTANTS.AUTH_TYPE);
            this._cacheSvc.deleteFromSessionStoarage(COMMON_CONSTANTS.TOKEN_KEY);
            if (appType === COMMON_CONSTANTS.AUTOPAY_KEY) {
                this._cacheSvc.deleteFromSessionStoarage(COMMON_CONSTANTS.FLOW_KEY);
                this._cacheSvc.deleteFromSessionStoarage(COMMON_CONSTANTS.CANCEL_AUTO_PAY_FLAG);
            }
            if (appType === COMMON_CONSTANTS.MAKEPAY_KEY) {
                this._cacheSvc.deleteFromSessionStoarage(COMMON_CONSTANTS.BILL_PAY_FLAG);
            }
            if (this.BanNumber) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.BAN_NUMBER_KEY, this.BanNumber);
            }
            if (this.OrgID) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ORG_ID_KEY, this.OrgID);
            }
            if (this.ReturnURI) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.RETURN_URL_SSP, this.ReturnURI);
            }
            if (this.authType) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.AUTH_TYPE, this.authType);
            }

            if (appType === COMMON_CONSTANTS.AUTOPAY_KEY && this.flow) {

                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY, this.flow);

            }
            if (appType === COMMON_CONSTANTS.MAKEPAY_KEY && this.BillPayFlag) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.BILL_PAY_FLAG, this.BillPayFlag);
            }

            if (this.token && this.authType === COMMON_CONSTANTS.TOKEN_KEY) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.TOKEN_KEY, this.token);
            }

            if (this.authType === COMMON_CONSTANTS.IAM_KEY) {
                const url = location.protocol + '//' + location.host + location.pathname;

                const euiURL = environment.EUI_ENDPOINT + WCS_ENPOINT_CONST.EUI_GET_CODE_REDIRECT_URL.path.replace('{{host}}', url);

                var xssFilters = require('xss-filters');
                window.location.href = xssFilters.uriInHTMLData(euiURL);

                this.checkForEUIedirect.next(true);
                return false;
            } else {
                // Call Secret Key Validation Service here using this.token
            }
        } else {
            this.euiCode = _.get(qParams, COMMON_CONSTANTS.EUI_CODE, '');
            if (this.euiCode !== undefined && this.euiCode) {
                this.checkForEUIedirect.next(false);
                this.isEuiRedirect = this.checkForEUIedirect.asObservable();
                this.partnerHandShake.next(false);
                this.BanNumber = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY) !== undefined ?
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY) : '';
                ContentService.SetCache(COMMON_CONSTANTS.BAN_NUMBER_KEY, this.BanNumber);


                this.authType = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) !== undefined ?
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) : COMMON_CONSTANTS.IAM_KEY;

                if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE) !== 'true'
                    && this.authType === COMMON_CONSTANTS.IAM_KEY) {
                    this.handleTokenData();
                } else if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE) === 'true'
                    && this.authType === COMMON_CONSTANTS.IAM_KEY) {
                    this.isLoaded = true;
                    this.ssoMessage.error = '';
                    this.ssoMessage.show = false;
                    this.ssoMessage.errorType = '';
                    this.checkForEUIedirect.next(false);
                    this.partnerHandShake.next(true);
                    this.isEuiRedirect = this.checkForEUIedirect.asObservable();
                }
            } else {
                const url = location.protocol + '//' + location.host + location.pathname;

                const euiURL = environment.EUI_ENDPOINT + WCS_ENPOINT_CONST.EUI_GET_CODE_REDIRECT_URL.path.replace('{{host}}', url);

                xssFilters = require('xss-filters');
                window.location.href = xssFilters.uriInHTMLData(euiURL);

                this.checkForEUIedirect.next(true);
                this.partnerHandShake.next(true);
                this.isEuiRedirect = this.checkForEUIedirect.asObservable();
                return false;
            }
        }
        return true;
    }

    handleTokenData(): void {
        this._pnpClientService.context$.subscribe(
            (pnpContext) => {
                const iamData: any = {
                    firstName: pnpContext[COMMON_CONSTANTS.USER_FIRST_NAME],
                    lastName: pnpContext[COMMON_CONSTANTS.USER_LAST_NAME],
                    logonId: pnpContext[COMMON_CONSTANTS.USER_EMAIL],
                    msisdn: "",
                    uuid: pnpContext[COMMON_CONSTANTS.USER_ID],
                };
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.EUI_TOKEN_DATA, JSON.stringify(iamData));
            },
            err => {
                this.dataSharingSvc.shareData.subscribe(value => {
                    this.ssoMessage.error = value;
                    this._errMsgService.sendErrorMessgae(this.ssoMessage);
                });
            });
        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE, 'true');

        this.isLoaded = true;
        this.ssoMessage.error = '';
        this.ssoMessage.show = false;
        this.ssoMessage.errorType = '';
        this.checkForEUIedirect.next(false);
        this.partnerHandShake.next(true);
        this.isEuiRedirect = this.checkForEUIedirect.asObservable();

    }


    _partnerSetupRoute(route, appType: string): boolean { 

        const epid=route.queryParamMap.get('epid');
        const ds=route.queryParamMap.get('ds');
        const async=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_ASYNC)==='true';
        const decryptPartnerTokenObservable=async?this.makePaymentSvc.decryptPartnerAnonymousToken(epid, ds):this.makePaymentSvc.decryptPartnerToken(epid, ds);
        this._cacheSvc.putInSessionStoarage('pid', route.queryParamMap.get('epid'));
        decryptPartnerTokenObservable
            .subscribe(
                data => {
                    if (data.error) {
                        this.dataSharingSvc.shareData.subscribe(value => {
                            this.ssoMessage.error = value;
                            this._errMsgService.sendErrorMessgae(this.ssoMessage);
                        });
                    } else {
                        const user_data = {};
                        user_data['firstName'] = data.firstName;
                        user_data['lastName'] = data.lastName;
                        user_data['logonId'] = data.email;
                        if (data.loanId && data.msisdn) {
                            user_data['loanId'] = data.loanId;
                            user_data['msisdn'] = data.msisdn;
                        }
                        this._cacheSvc.putInSessionStoarage('paymentTypeIndicator', data.paymentTypeIndicator);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE, 'true');
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.EUI_TOKEN_DATA, JSON.stringify(user_data));
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY,
                            route.queryParamMap.get('B2b-client'));
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.BAN_NUMBER_KEY, data.ban);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.RETURN_URL_SSP, data.redirectUrl);
                        this._cacheSvc.putInSessionStoarage(MakePaymentConstant.ORDERTYPE, data.orderType);
                        this._cacheSvc.putInSessionStoarage(MakePaymentConstant.ORDERNUMBER, data.cartId);
                        this._cacheSvc.putInSessionStoarage(MakePaymentConstant.BAN_TYPE, data.banType);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.HEADER_PROPERTIES.PID_TEXT, route.queryParamMap.get('epid'));
                        if (!(sessionStorage.getItem(COMMON_CONSTANTS.UNIQUEID_FOR_APIS))) {
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS, uuid());
                        }
                        if(async){
                            this._cacheSvc.deleteFromSessionStoarage(COMMON_CONSTANTS.RETURN_URL_SSP);
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY, data.ts);
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY, data.at);
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_AT, data.at);
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_EPID, decodeURIComponent(data.eid));
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_DS, data.ds);
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_TS, data.ts);
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.HARDGOOD_AUTH_ASYNC_ACC_TRANS_ID, data.accountTransactionId);
                        }
                        this.partnerHandShake.next(true);
                        // tslint:disable-next-line: max-line-length
                        this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                            MakePaymentConstant.FLOW_TYPE.ONETIME_PAYMENT);
                        if (data.paymentTypeIndicator === 'enrollAutoPay') {
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY,
                                'enroll');
                            this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                                MakePaymentConstant.FLOW_TYPE.ENROLL_AUTOPAY);
                        } else if (data.paymentTypeIndicator === 'manageAutoPay') {
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY,
                                'manage');
                            this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                                MakePaymentConstant.FLOW_TYPE.MANAGE_AUTOPAY);
                        } else if (data.paymentTypeIndicator === 'cancelAutoPay') {
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY,
                                'cancel');
                            this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                                MakePaymentConstant.FLOW_TYPE.CANCEL_AUTOPAY);
                        } else if (data.paymentTypeIndicator === 'simOnHand') {
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY,
                                'soh');
                            this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                                MakePaymentConstant.FLOW_TYPE.SOH_LINE_DEPOSIT);
                        }

                        this.isLoaded = true;
                        this.ssoMessage.error = '';
                        this.ssoMessage.show = false;
                        this.ssoMessage.errorType = '';
                        this.checkForEUIedirect.next(false);
                        this.isEuiRedirect = this.checkForEUIedirect.asObservable();

                    }
                },
                err => {

                    this.dataSharingSvc.shareDataPartner.subscribe(value => {
                        this.ssoMessage.error = value;
                        this.ssoMessage.errorType = "partnerError";
                        this._errMsgService.sendErrorMessgae(this.ssoMessage);
                    });
                }
            );
        return true;
    }

    _partnerSOHSetupRoute(route, appType: string): boolean {
        this._cacheSvc.putInSessionStoarage('pid', route.queryParamMap.get('epid'));
        this.makePaymentSvc.decryptPartnerAnonymousToken(route.queryParamMap.get('epid'), route.queryParamMap.get('ds'))
            .subscribe(
                data => {
                    if (data.error) {
                        this.makePaymentSvc.isDecryptCallLoaded.next("false");
                        this.dataSharingSvc.shareData.subscribe(value => {
                            this.ssoMessage.error = value;
                            this._errMsgService.sendErrorMessgae(this.ssoMessage);
                        });
                    } else {
                        const user_data = {};
                        user_data['firstName'] = data.firstName;
                        user_data['lastName'] = data.lastName;
                        user_data['logonId'] = data.email;
                        this._cacheSvc.putInSessionStoarage('paymentTypeIndicator', data.paymentTypeIndicator);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.EUI_TOKEN_DATA, JSON.stringify(user_data));
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY,
                            route.queryParamMap.get('B2b-client'));
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.BAN_NUMBER_KEY, data.ban);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.RETURN_URL_SSP, data.redirectUrl);
                        this._cacheSvc.putInSessionStoarage(MakePaymentConstant.ORDERTYPE, data.orderType);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY, data.ts);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY, data.at);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_AT, data.at);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_EPID, decodeURIComponent(data.eid));
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_DS, data.ds);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_TS, data.ts);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.AMOUNT_KEY, data.amount);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.SOH_CART_ID, data.cartId);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.SOH_ACC_TRANS_ID, data.accountTransactionId);
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.HEADER_PROPERTIES.PID_TEXT, decodeURIComponent(data.eid));
                        this.partnerHandShake.next(true);
                        // tslint:disable-next-line: max-line-length
                        this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                            MakePaymentConstant.FLOW_TYPE.ONETIME_PAYMENT);
                        if (data.paymentTypeIndicator === 'enrollAutoPay') {
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY,
                                'enroll');
                            this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                                MakePaymentConstant.FLOW_TYPE.ENROLL_AUTOPAY);
                        } else if (data.paymentTypeIndicator === 'manageAutoPay') {
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY,
                                'manage');
                            this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                                MakePaymentConstant.FLOW_TYPE.MANAGE_AUTOPAY);
                        } else if (data.paymentTypeIndicator === 'cancelAutoPay') {
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY,
                                'cancel');
                            this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                                MakePaymentConstant.FLOW_TYPE.CANCEL_AUTOPAY);
                        } else if (data.paymentTypeIndicator === 'simOnHand') {
                            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY,
                                'soh');
                            this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                                MakePaymentConstant.FLOW_TYPE.SOH_LINE_DEPOSIT);
                        }

                        this.isLoaded = true;
                        this.ssoMessage.error = '';
                        this.ssoMessage.show = false;
                        this.ssoMessage.errorType = '';
                        this.checkForEUIedirect.next(false);
                        this.isEuiRedirect = this.checkForEUIedirect.asObservable();

                        this.makePaymentSvc.isDecryptCallLoaded.next("true");
                    }
                },
                err => {

                    this.dataSharingSvc.shareData.subscribe(value => {
                        this.ssoMessage.error = value;
                        this._errMsgService.sendErrorMessgae(this.ssoMessage);
                    });
                    this.makePaymentSvc.isDecryptCallLoaded.next("false");
                }
            );
        return true;
    }
    flowTypeCheck(flowType) {
        switch (flowType) {
            case 'manageAutoPay':
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY,
                    'manage');
                break;
            case 'enrollAutoPay':
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY,
                    'enroll');
                break;
            case 'cancelAutoPay':
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY,
                    'cancel');
                break;
        }
    }

    tokenResolved() {
        return this._envSvc.getSessionEndpointsEnv().then((q) => {
            this._envSvc.CURR_SESSION_ENDPOINTS = q.WCS_ENPOINT_CONST;
            this._envSvc.APIGEE_TOKEN_URLS = q.APIGEE_TOKEN_URL;

            return this._envSvc.getContentEnvt().then((x) => {
                this._envSvc.CURR_CONTENT_URLS = x.CONTENT_PAGES_URL;
                // This call Gets tokens
                return this._apigeeSvc.getTokens();
            });
        });
    }

    saveParamsInSession(route: ActivatedRouteSnapshot, appType: string) { 

        if (route.queryParamMap) {
            if (!(sessionStorage.getItem(COMMON_CONSTANTS.TRANSACTIONID))) {
                var now = new Date();
                this.transactionID = now.valueOf();
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.TRANSACTIONID, this.transactionID);
            }
            if (!(sessionStorage.getItem(COMMON_CONSTANTS.UNIQUEID_FOR_APIS))) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS, uuid());
            }
            if (COMMON_CONSTANTS.ENABLE_PID_FLOW !== undefined && COMMON_CONSTANTS.ENABLE_PID_FLOW) {
                const queryParam = this.decodeParamsSvc.decodeBase64(route.queryParamMap.get(COMMON_CONSTANTS.PID_KEY) ?
                    route.queryParamMap.get(COMMON_CONSTANTS.PID_KEY) : '');
                this.BanNumber = queryParam[COMMON_CONSTANTS.BAN_NUMBER_KEY] ?
                    queryParam[COMMON_CONSTANTS.BAN_NUMBER_KEY] :
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY);

                this.OrgID = queryParam[COMMON_CONSTANTS.ORG_ID_KEY] ?
                    queryParam[COMMON_CONSTANTS.ORG_ID_KEY] :
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY);

                this.ReturnURI = queryParam[COMMON_CONSTANTS.RETURN_URL_SSP] ?
                    queryParam[COMMON_CONSTANTS.RETURN_URL_SSP] :
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.RETURN_URL_SSP);

                this.authType = queryParam[COMMON_CONSTANTS.AUTH_TYPE] ? queryParam[COMMON_CONSTANTS.AUTH_TYPE] :
                    (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE)) ?
                        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) : COMMON_CONSTANTS.IAM_KEY);

                if ((this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === 'true' || environment.DISABLE_ACCESS_TOKEN_CALL === true) && appType !== COMMON_CONSTANTS.SOH_LINE_DEPOSIT_KEY) {
                    this.queryParam_AT = route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_AT) ? route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_AT) :
                        (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_AT)) ?
                            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_AT) : '');

                    this.queryParam_DS = route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_DS) ? route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_DS) :
                        (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS)) ?
                            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS) : '');

                    this.queryParam_EPID = route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_EPID) ? route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_EPID) :
                        (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID)) ?
                            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID) : '');

                    this.queryParam_TS = route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_TS) ? route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_TS) :
                        (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_TS)) ?
                            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_TS) : '');
                }
                if(appType !== COMMON_CONSTANTS.SOH_LINE_DEPOSIT_KEY){
                    if (this.authType === COMMON_CONSTANTS.IAM_KEY) {
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY,
                            COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT);
                    } else {
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY,
                            COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_AHUB);
                    }
                }

                if (this.authType === COMMON_CONSTANTS.OKTA_KEY ) {
                    const globalWindow = typeof window !== 'undefined' ? window : {};
                    const win: any = globalWindow;
                    win.user_NTID = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA) ?
                        JSON.parse(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)).NTID : '';
                }

                if (appType === COMMON_CONSTANTS.MAKEPAY_KEY) {
                    this.BillPayFlag = queryParam[COMMON_CONSTANTS.BILL_PAY_FLAG] ?
                        queryParam[COMMON_CONSTANTS.BILL_PAY_FLAG] :
                        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BILL_PAY_FLAG);

                    if (queryParam[COMMON_CONSTANTS.FLOW_KEY] === COMMON_CONSTANTS.EDIT) {
                        this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.IS_EDIT_FLOW, true);
                    }
                }

                if (appType === COMMON_CONSTANTS.AUTOPAY_KEY) {
                    this.flow = queryParam[COMMON_CONSTANTS.FLOW_KEY] ?
                        queryParam[COMMON_CONSTANTS.FLOW_KEY] :
                        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.FLOW_KEY);
                }
                if (this.authType === COMMON_CONSTANTS.TOKEN_KEY) {
                    this.token = queryParam[COMMON_CONSTANTS.TOKEN_KEY] ?
                        queryParam[COMMON_CONSTANTS.TOKEN_KEY] :
                        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TOKEN_KEY);
                }
            } else {
                this.BanNumber = route.queryParamMap.get(COMMON_CONSTANTS.BAN_NUMBER_KEY) ?
                    route.queryParamMap.get(COMMON_CONSTANTS.BAN_NUMBER_KEY) :
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY);

                this.OrgID = route.queryParamMap.get(COMMON_CONSTANTS.ORG_ID_KEY) ?
                    route.queryParamMap.get(COMMON_CONSTANTS.ORG_ID_KEY) :
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY);

                this.ReturnURI = route.queryParamMap.get(COMMON_CONSTANTS.RETURN_URL_SSP) ?
                    route.queryParamMap.get(COMMON_CONSTANTS.RETURN_URL_SSP) :
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.RETURN_URL_SSP);

                this.authType = route.queryParamMap.get(COMMON_CONSTANTS.AUTH_TYPE) ? route.queryParamMap.get(COMMON_CONSTANTS.AUTH_TYPE) :
                    (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE)) ?
                        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) : COMMON_CONSTANTS.IAM_KEY);

                if ((this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === 'true' || environment.DISABLE_ACCESS_TOKEN_CALL === true) && appType !== COMMON_CONSTANTS.SOH_LINE_DEPOSIT_KEY) {
                    this.queryParam_AT = route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_AT) ? route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_AT) :
                        (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_AT)) ?
                            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_AT) : '');
                    this.queryParam_DS = route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_DS) ? route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_DS) :
                        (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS)) ?
                            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS) : '');
                    this.queryParam_EPID = route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_EPID) ? route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_EPID) :
                        (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID)) ?
                            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID) : '');
                    this.queryParam_TS = route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_TS) ? route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_TS) :
                        (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_TS)) ?
                            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_TS) : '');
                }

                if (appType === COMMON_CONSTANTS.AUTOPAY_KEY) {
                    this.flow = route.queryParamMap.get(COMMON_CONSTANTS.FLOW_KEY) ?
                        route.queryParamMap.get(COMMON_CONSTANTS.FLOW_KEY) :
                        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.FLOW_KEY);
                }

                if (appType === COMMON_CONSTANTS.MAKEPAY_KEY) {
                    this.BillPayFlag = route.queryParamMap.get(COMMON_CONSTANTS.BILL_PAY_FLAG) ?
                        route.queryParamMap.get(COMMON_CONSTANTS.BILL_PAY_FLAG) :
                        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BILL_PAY_FLAG);
                }

                if (this.authType === COMMON_CONSTANTS.TOKEN_KEY) {
                    this.token = route.queryParamMap.get(COMMON_CONSTANTS.TOKEN_KEY) ?
                        route.queryParamMap.get(COMMON_CONSTANTS.TOKEN_KEY) :
                        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TOKEN_KEY);
                }
            }
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.BAN_NUMBER_KEY, this.BanNumber);
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ORG_ID_KEY, this.OrgID);
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.RETURN_URL_SSP, this.ReturnURI);
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.AUTH_TYPE, this.authType);
            if ((this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === 'true' || environment.DISABLE_ACCESS_TOKEN_CALL === true) && appType !== COMMON_CONSTANTS.SOH_LINE_DEPOSIT_KEY) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_AT, this.queryParam_AT);
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_DS, this.queryParam_DS);
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_EPID, this.queryParam_EPID);
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_TS, this.queryParam_TS);
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY, this.queryParam_AT);
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY, this.queryParam_TS);
                //   new Date(new Date().getTime()).setSeconds(this.queryParam_TS));
            }

            if (appType === COMMON_CONSTANTS.MAKEPAY_KEY) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.BILL_PAY_FLAG, this.BillPayFlag);
            }

            if (appType === COMMON_CONSTANTS.AUTOPAY_KEY && this.flow) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.FLOW_KEY, this.flow);
            }
            if (this.authType === COMMON_CONSTANTS.TOKEN_KEY) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.TOKEN_KEY, this.token);
            }
        }
    }

    tokenResolvedOkta(qParams: Params = { code: "", state: "" }) {
        if (
            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) ===
            "okta"
        ) {
            const billpayFlag = this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.BILL_PAY_FLAG
            );
            const flow = this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.FLOW_KEY
            );

            const getTokensRequest = this._pnpClientService.isRunningInGlobalNav()
                ? this._pnpClientService.getTokens()
                : this._oktaSvc.getOktaaAccessToken();

            return getTokensRequest.then(
                (response) => {
                    sessionStorage.setItem(
                        COMMON_CONSTANTS.OKTA_ACCESS_TOKEN_KEY,
                        response["access_token"] || response["accessToken"]
                    );

                    const getUserInfo = this._pnpClientService.isRunningInGlobalNav()
                        ? this._pnpClientService.getUserInfo()
                        : this._oktaSvc.getOktaUserProfile();

                    return getUserInfo.then(
                        (userProfile) => {
                            sessionStorage.setItem(
                                "userProfile",
                                JSON.stringify(userProfile)
                            );
                            this._cacheSvc.putInSessionStoarage(
                                COMMON_CONSTANTS.EUI_TOKEN_DATA,
                                JSON.stringify(userProfile)
                            );
                            this._cacheSvc.putInSessionStoarage(
                                COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE,
                                "true"
                            );
                            return this._envSvc.getSessionEndpointsEnv().then((q) => {
                                this._envSvc.CURR_SESSION_ENDPOINTS = q.WCS_ENPOINT_CONST;
                                this._envSvc.APIGEE_TOKEN_URLS = q.APIGEE_TOKEN_URL;

                                return this._envSvc.getContentEnvt().then((x) => {
                                    this._envSvc.CURR_CONTENT_URLS = x.CONTENT_PAGES_URL;
                                    // This call Gets tokens
                                    return this._apigeeSvc.getTokens().then((y) => {
                                        this.checkForEUIedirect.next(false);
                                        this.partnerHandShake.next(true);
                                        this.isEuiRedirect = this.checkForEUIedirect.asObservable();
                                        if (billpayFlag === "true") {
                                            // tslint:disable-next-line:max-line-length
                                            this.router.navigate(["apps/tfb_payment/payment/makepayment"], {
                                                queryParams: {
                                                    code: qParams.code,
                                                    state: qParams.state,
                                                },
                                            });
                                        } else if (!isNullOrUndefined(flow)) {
                                            // tslint:disable-next-line:max-line-length
                                            this.router.navigate(["apps/tfb_payment/payment/autopayment"], {
                                                queryParams: {
                                                    code: qParams.code,
                                                    state: qParams.state,
                                                },
                                            });
                                        }
                                    });
                                });
                            });
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                },
                (err) => {
                    console.log(err);
                }
            );
        }
    }
    tokenResolvedAzure(qParams: Params = { code: "", state: "" }) {
        if (
            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) ===
            "azure"
        ) {
            const billpayFlag = this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.BILL_PAY_FLAG
            );
            const flow = this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.FLOW_KEY
            );
            
            return this._pnpClientService.getTokens().then(
                (response) => {
                    sessionStorage.setItem(
                        COMMON_CONSTANTS.AZURE_ACCESS_TOKEN_KEY,
                        response["access_token"] || response["accessToken"]
                    );

                    const getUserInfo = this._pnpClientService.getUserInfo()
                       

                    return getUserInfo.then(
                        (userProfile) => {
                            sessionStorage.setItem(
                                "userProfile",
                                JSON.stringify(userProfile)
                            );
                            this._cacheSvc.putInSessionStoarage(
                                COMMON_CONSTANTS.EUI_TOKEN_DATA,
                                JSON.stringify(userProfile)
                            );
                            this._cacheSvc.putInSessionStoarage(
                                COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE,
                                "true"
                            );
                            return this._envSvc.getSessionEndpointsEnv().then((q) => {
                                this._envSvc.CURR_SESSION_ENDPOINTS = q.WCS_ENPOINT_CONST;
                                this._envSvc.APIGEE_TOKEN_URLS = q.APIGEE_TOKEN_URL;

                                return this._envSvc.getContentEnvt().then((x) => {
                                    this._envSvc.CURR_CONTENT_URLS = x.CONTENT_PAGES_URL;
                                    // This call Gets tokens
                                    return this._apigeeSvc.getTokens().then((y) => {
                                        this.checkForEUIedirect.next(false);
                                        this.partnerHandShake.next(true);
                                        this.isEuiRedirect = this.checkForEUIedirect.asObservable();
                                        if (billpayFlag === "true") {
                                            // tslint:disable-next-line:max-line-length
                                            this.router.navigate(["apps/tfb_payment/payment/makepayment"], {
                                                queryParams: {
                                                    code: qParams.code,
                                                    state: qParams.state,
                                                },
                                            });
                                        } else if (!isNullOrUndefined(flow)) {
                                            // tslint:disable-next-line:max-line-length
                                            this.router.navigate(["apps/tfb_payment/payment/autopayment"], {
                                                queryParams: {
                                                    code: qParams.code,
                                                    state: qParams.state,
                                                },
                                            });
                                        }
                                    });
                                });
                            });
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                },
                (err) => {
                    console.log(err);
                }
            );
        }
    }

    euiLoginRedirect() {
        const url = location.protocol + "//" + location.host + location.pathname;
        const euiURL =
            environment.EUI_ENDPOINT +
            WCS_ENPOINT_CONST.EUI_GET_CODE_REDIRECT_URL.path.replace("{{host}}", url);

        var xssFilters = require("xss-filters");
        window.location.href = xssFilters.uriInHTMLData(euiURL);

        this.checkForEUIedirect.next(true);
    }

    anonymousTokenResolved() {
        return this._envSvc.getSessionEndpointsEnv().then((q) => {
            this._envSvc.CURR_SESSION_ENDPOINTS = q.WCS_ENPOINT_CONST;
            this._envSvc.APIGEE_TOKEN_URLS = q.APIGEE_TOKEN_URL;

            return this._envSvc.getContentEnvt().then((x) => {
                this._envSvc.CURR_CONTENT_URLS = x.CONTENT_PAGES_URL;
                // This call Gets tokens
                return this._apigeeSvc.getTokenAnonymous();
            });
        });
    }
}
