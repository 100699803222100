<input
  type="text"
  name="walkMeIn_userID"
  ng-model="walkMeIn_userID"
  style="display: none"
/>
<app-header></app-header>
<ng-template #payOther>
  <div
    class="thank-you-screen-wrapper mrg-lt-15 mrg-rt-15"
    *ngIf="this.thankuScreenData"
  >
    <h1 class="text-center">
      <em class="fa fa-check-circle color-light-green" aria-hidden="true"></em>
    </h1>
    <h1 class="text-center thank-you-title">
      {{ thankuScreenData?.paymentThanksTxt }}
    </h1>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <p class="text-center Visa-card-ending-in" [innerHTML]="this.paymentMsg"></p>
      </div>
      <div class="col-md-2"></div>
    </div>
    <p class="text-center The-remaining-balanc" *ngIf="this.autoPayFlag">
      {{ this.thankuScreenData.remainingBalanceTxt }}
      {{ thankuScreenData?.scheduledAutopayTxt }}.
    </p>
    <p class="text-center Your-Payment" *ngIf="this.scheduleFlag">
      {{ this.paymentIdText }} {{ this.thankuObject.paymentId }}
    </p>
    <div *ngIf="eipDevice" class="row eip-device mb-5  Visa-card-ending-in">
      <div class="col-md-4"></div>
      <div class="col-md-4" >
        <a (click) = showDetails() class="inline_text show-details">{{ showDetailTxt }}</a>
        <div *ngIf="showPaymentDetails" class="mt-4 payment-details Visa-card-ending-in">
          {{ this.thankuScreenData?.eipOrderNumberTxt }} {{thankuObject.orderNumber}}<br>
          {{ this.thankuScreenData?.eipAuthCodeTxt }} {{makePaymentResponse?.payment?.authorizationId}}<br>
          {{ this.thankuScreenData?.eipTransactionTypeTxt }} EIP STANDALONE PAYMENT<br>
          {{ this.thankuScreenData?.eipBANTxt }} {{eipDevice?.billingAccountNumber}}<br>
          {{ this.thankuScreenData?.eipLoanIdTxt }} {{eipDevice?.loanId}}<br>
          {{ this.thankuScreenData?.eipImeiTxt }} {{eipDevice?.purchasedEquipment?.imei}}<br>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div *ngIf="enableBack" class="text-center txt-sm-right">
      <button
        (click)="onBackBtnClick()"
        *ngIf="!isPartnerFlow || isBillingPartnerFlow"
        class="primary_btn"
      >
        {{ thankuScreenData?.backTxt }}
      </button>
      <button
        class="btn btn-primary"
        *ngIf="isPartnerFlow && !isPartnerFlow && !isBillingPartnerFlow"
        (click)="onClose()"
      >
        Close
      </button>
    </div>
    <br />
  </div>
</ng-template>
<!-- Order confirmation wrapper end here -->

<div *ngIf="this.payInInstallment; else payOther">
  <div class="confirmation-screen-wrapper mrg-lt-15 mrg-rt-15">
    <div class="row">
      <div class="col">
        <h1 class="text-center">
          <i
            class="fa fa-check-circle color-light-green"
            aria-hidden="true"
          ></i>
        </h1>
        <h1 class="font-tele-ult text-left text-sm-center">
          {{ thankuScreenData?.paymentArrangementComplete }}
        </h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p class="text-left text-sm-center text-gray font-arial">
          {{ thankyoumessage.payInInstallmentTxt }}
        </p>
        <p class="text-left text-sm-center font-weight-bold font-arial-bold">
          {{ thankyoumessage.firstInstallment }}<br />
          {{ thankyoumessage.secondInstallment }}
        </p>
        <p class="text-left text-sm-center text-gray font-arial">
          {{ thankyoumessage.note }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="text-center txt-sm-right">
          <button
            class="btn btn-primary"
            *ngIf="!isPartnerFlow || isBillingPartnerFlow"
            (click)="onBackBtnClick()"
          >
            {{ thankuScreenData?.backToBilling }}
          </button>
          <button
            class="btn btn-primary"
            *ngIf="isPartnerFlow && !isBillingPartnerFlow"
            (click)="onClose()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer *ngIf="!isRunningInGlobalNav"></app-footer>
