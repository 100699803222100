import { Injectable } from '@angular/core';
import { AuthHttpService } from './http-wrapper.service';
import { HttpHeaders } from '@angular/common/http';
import { EnvironmentDetailsService } from './environment-details.service';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { COMMON_CONSTANTS, ACH_CONSTANTS } from '../common/core/common.constants';
import { HttpCacheService } from './http-cache.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ACHService {
  errorMessage: any;
  orgID: any;
  isBankInfoSaved = false;
  authToken: any;
  currentDate: any = new Date();

  constructor(private _http: AuthHttpService, private _envSvc: EnvironmentDetailsService, private _cacheSvc: HttpCacheService) {
    this.orgID = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY)) ?
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY) : '';
  }
  public getACHBankName(routingNo: string) {
    this.authToken = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY)) ?
      COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY) : '';
      const isoDate  = this.currentDate.toISOString();
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Accept': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
          'Authorization': this.authToken,
          'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
          'application-id': COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY,
          'channel-id': COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_WEB_KEY,
          'timestamp': isoDate.replace('T', ' ')
        }
      )
    };
    return this._http.post(
      this._envSvc.APIGEE_TOKEN_URLS.APIGEE_GET_BANK_NAME, 
      {
        'billingAccount': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY),
        'routingNumber': routingNo
      }, 
      httpOptions);
  }

  public submitACHRequest(data: any) {
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    const serviceUrl = this.replaceOrgIdInUrl(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_SAVE_AUTO_PAY);
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this._http.get(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_SAVE_AUTO_PAY_PARTNER);
      } else {
        return this._http.post(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_SAVE_AUTO_PAY_PARTNER, data);
      }
    } else {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this._http.get(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_SAVE_AUTO_PAY);
      } else {
        return this._http.post(serviceUrl, data);
      }
    }
  }

  replaceOrgIdInUrl(serviceUrl: any) {
    serviceUrl.path = serviceUrl.path.replace(COMMON_CONSTANTS.ORG_ID_TEXT, this.orgID);
    return serviceUrl;
  }
}
