import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { Observable, throwError  } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { APIGEE_TOKEN_URL } from '../common/core/urls.constants';
import { COMMON_CONSTANTS, ERROR_CATEGORY, MakePaymentConstant } from '../common/core/common.constants';
import { HttpCacheService } from './http-cache.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UtilsService } from './utils.service';
import * as _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';


export interface ILogLoad {
    requestPayload: any;
    ban: any;
    userId: any;
}


@Injectable()
export class HttpLoggingInterceptor implements HttpInterceptor {

    private authReq: HttpRequest<any>;
    private loggerLoad: ILogLoad;
    DiffreqUrl: any;
    startTime: any;
    diffTime: any;
    endTime: any;
    cookiesVal: any;
    private LOG_LEVEL = ['TRACE', 'DEBUG', 'INFO', 'LOG', 'WARN', 'ERROR', 'OFF'];
    parsedRequest: any;
    requestDate:any;
    b2bRequestid:any;
    redFlag = false;
    requestBody: any; 
    ResponseBody: any;
    constructor(private logger: NGXLogger, private utils: UtilsService, private router: Router, private _backend: HttpXhrBackend,
        private _cacheSvc: HttpCacheService) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        this.authReq = req;

        if (req.url.indexOf(APIGEE_TOKEN_URL.SPLUNK_LOG.path) === -1) {
            this.DiffreqUrl = req.url ;
            this.startTime = new Date().getTime();
        }
        // inject this method thorugh service
        if (req.url.indexOf(APIGEE_TOKEN_URL.SPLUNK_LOG.path) !== -1 && environment.HTTP_SPLUNK_EVENT) {
            const parsedReq = JSON.parse(req.body.message);
            if (isNullOrUndefined(parsedReq.errorType)){
                const respUrl =  JSON.parse(req.body.message).url;
                if (respUrl.indexOf(this.DiffreqUrl) > -1) {
                    this.endTime = new Date().getTime();
                    this.diffTime =  (this.endTime - this.startTime) / 1000;
                    sessionStorage.setItem(COMMON_CONSTANTS.DIFFTIME_KEY, this.diffTime);
                }
            } 
            this.authReq = this._mapLoggingPayload(req);
            return next.handle(this.authReq).pipe(
                map(event => {
                    return event;
                }),
                catchError(error => {
                    return throwError(error);
            }));

        }
        return next.handle(this.authReq);
    }

    _mapLoggingPayload(req: HttpRequest<any>): HttpRequest<any> { 

        let apiName = '';
        let submitCall = '';
        let errorCategory, errorCode, errorMessage ;
        this.parsedRequest = JSON.parse(req.body.message);
        this.requestBody =  this.parsedRequest.requestBody;
        this.ResponseBody = this.parsedRequest.body;
        
        const reqMessage = _.get(req, 'body.message', '{}');
        const reqParsed = JSON.parse(reqMessage);
        apiName = _.get(reqParsed , 'api' , '');
        submitCall = _.get(reqParsed , 'submitCall' , false);
        // redflagCheck not used
        let requestheader = _.get(reqParsed , 'reqheader' , ''); 
        let responseHeader = _.get(reqParsed , 'responseHeader' , '');
        const mobileNumberDetailsUrlRegex = /b2b-experience\/v1\/entitlements\/billing-accounts\/[\w]*\/mobile-numbers-details/;
        const billingAccountOrgsUrlRegex = /b2b-experience\/v1\/b2b-billing-accounts\/orgs\/[\w]*\/billing-accounts/;

        if (mobileNumberDetailsUrlRegex.test(this.parsedRequest.url) || billingAccountOrgsUrlRegex.test(this.parsedRequest.url)) {
            this.parsedRequest.url = '*****' + this.parsedRequest.url.slice(-30);
        }

        for (let index = 0; index < this?.requestBody?.billingAccounts?.length; index++) {
            this.requestBody.billingAccounts[index] = this.requestBody.billingAccounts[index]?.toString()?.replace(/.(?=.{4,}$)/g, '*');
        }

        const euiToken =
            '{"customerFirstName":"test-Payment","customerLastName":"test-Payment","customerPhone":"1111111111","logonId":"test@test.com"}';
        const maskedUserId = '*****' + _.get(JSON.parse(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)
            || euiToken), 'logonId', '').slice(-4);

        if (!isNullOrUndefined(this.parsedRequest && this.parsedRequest.errorType)
            || isNullOrUndefined(this.parsedRequest && this.parsedRequest.errorType)) {

            if (  !isNullOrUndefined(this.parsedRequest.error) && (!isNullOrUndefined(this.parsedRequest.error.error) 
                || !isNullOrUndefined(this.parsedRequest.error.errors))) {
                if (('error' in this.parsedRequest) && ('error' in this.parsedRequest.error ) &&
                this.parsedRequest.error.error.length > 0) {
                errorCode = this.parsedRequest.error.error[0].code;
                errorMessage = this.parsedRequest.error.error[0].userMessage;
                    if (this.parsedRequest.status !== ERROR_CATEGORY.HTTP_STATUS) {
                        errorCategory = ERROR_CATEGORY.FAILURE_SYSTEM_VALIDATION;
                    } else if (this.parsedRequest.status === ERROR_CATEGORY.HTTP_STATUS) {
                        errorCategory = ERROR_CATEGORY.FAILURE_BUSINESS_VALIDATION;
                    }
                } else if (('error' in this.parsedRequest) && ('errors' in this.parsedRequest.error ) &&
                    ('error' in this.parsedRequest.error.errors ) && this.parsedRequest.error.errors.error.length > 0) {
                    errorCode = this.parsedRequest.error.errors[0].error[0].code;
                    errorMessage = this.parsedRequest.error.errors[0].error[0].userMessage;
                    if (this.parsedRequest.status !== ERROR_CATEGORY.HTTP_STATUS) {
                        errorCategory = ERROR_CATEGORY.FAILURE_SYSTEM_VALIDATION;
                    } else if (this.parsedRequest.status === ERROR_CATEGORY.HTTP_STATUS) {
                        errorCategory = ERROR_CATEGORY.FAILURE_BUSINESS_VALIDATION;
                    }
                } else if (('error' in this.parsedRequest) && ('errors' in this.parsedRequest.error ) && 
                    this.parsedRequest.error.errors.length > 0) {
                    errorCode = this.parsedRequest.error.errors[0].code;
                    errorMessage = !isNullOrUndefined(this.parsedRequest.error.errors[0].systemMessage) ?
                        this.parsedRequest.error.errors[0].systemMessage : this.parsedRequest.error.errors[0].userMessage ;
                    if (this.parsedRequest.status !== ERROR_CATEGORY.HTTP_STATUS) {
                        errorCategory = ERROR_CATEGORY.FAILURE_SYSTEM_VALIDATION;
                    } else if (this.parsedRequest.status === ERROR_CATEGORY.HTTP_STATUS) {
                        errorCategory = ERROR_CATEGORY.FAILURE_BUSINESS_VALIDATION;
                    }
                } else {
                    errorCategory = ERROR_CATEGORY.FAILURE_SYSTEM_VALIDATION;
                }
            } else if ( !isNullOrUndefined(this.parsedRequest.error) && isNullOrUndefined(this.parsedRequest.error.error)){
                errorCategory = ERROR_CATEGORY.FAILURE_SYSTEM_VALIDATION;
            }


          /*  const headerObj = {
                'Content-Type': req.headers.getAll('Content-Type')[0],
                'Host': window.document.location.host,
                'Origin': window.document.location.origin,
                'Referer': window.document.location.href,
                'User-Agent': navigator.userAgent,
                'Cookie': 'ADRUM=' + this.utils.getCookie('ADRUM'),
            };
           const responseHeaderObj={};
            /*const responseHeaderObj = this.logger['_customHttpHeaders']['headers'];
            if (_.isArray(responseHeaderObj.get('requestdate'))) {
                this.requestDate = responseHeaderObj.get('requestdate')[0]; }
            if (_.isArray(responseHeaderObj.get('b2brequestid'))) {
            this.b2bRequestid = responseHeaderObj.get('b2brequestid')[0];})


            const headerResponseObj = {
                'b2b-requestid': this.b2bRequestid,
                'date': this.requestDate,

            };*/

           try {
                this.loggerLoad = {
                    ban: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY),
                    userId: maskedUserId,
                    requestPayload: req.body
                };
            } catch (e) {
                this.loggerLoad = {
                    ban: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY),
                    requestPayload: 'Unhandled Exception -  at _mapLoggingPayload - in HttpLoggingInterceptor - ' 
                    + req.url + ' ' + req.body,
                    userId: maskedUserId
                };
            }

            this.authReq = req.clone({
                withCredentials: true,
                body: Object.assign({},
                    {
                        'event': {
                            'userId': maskedUserId,
                            'severity': this.LOG_LEVEL[req.body.level],
                            'Log_Type': 'Server-Side Log',
                            'Error_category': errorCategory,
                            'Error_Code' : errorCode,
                            'Error_Message' : errorMessage,
                            'appId': environment.APP_ID,
                            'environment': environment.ENV,
                            'url': this.parsedRequest.url,
                            'status': JSON.parse(req.body.message).status,
                            'timestamp' : Date().toLocaleString(),
                            'request': {'body': this.requestBody, 'header': requestheader},
                            'response': {'body': !isNullOrUndefined(this.parsedRequest.body) ?
                                this.ResponseBody : this.parsedRequest.error, 'header': responseHeader},
                            'response_time': sessionStorage.getItem(COMMON_CONSTANTS.DIFFTIME_KEY),
                            'transactionID': sessionStorage.getItem(COMMON_CONSTANTS.TRANSACTIONID),
                            'b2b-requestid': this.b2bRequestid,
                            'Flow_Type': this._cacheSvc.getFromSessionStorage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT),
                            'apiName': apiName,
                            'submitCall' : submitCall,
                            'redFlag' : this.redFlag
                        },
                        'index': 'tbc'
                    }),
                headers: new HttpHeaders({
                    'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY
                })
            });
        } else if (!isNullOrUndefined(this.parsedRequest.errorType) && this.parsedRequest.errorType  === COMMON_CONSTANTS.SPLUNK_CLIENTSIDE_ERROR) {
            try {
                this.loggerLoad = {
                    ban: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY),
                    userId: maskedUserId,
                    requestPayload: req.body
                };
            } catch (e) {
                this.loggerLoad = {
                    ban: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY),
                    // tslint:disable-next-line:max-line-length
                    requestPayload: 'Unhandled Exception -  at _mapLoggingPayload - in HttpLoggingInterceptor - ' + req.url + ' ' + req.body,
                    userId: maskedUserId
                };
            }
            this.authReq = req.clone({
                withCredentials: true,
                body: Object.assign({},
                {
                    'event': {
                        'userId': maskedUserId,
                        'Log_Type': this.parsedRequest.errorType,
                        'Error_category': this.parsedRequest.errorType,
                        'Error_Message' : this.parsedRequest.requestBody,
                        'appId': environment.APP_ID,
                        'environment': environment.ENV,
                        'timestamp' : Date().toLocaleString(),
                        'transactionID': sessionStorage.getItem(COMMON_CONSTANTS.TRANSACTIONID),
                        'Flow_Type': this._cacheSvc.getFromSessionStorage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT)
                    },
                    'index': 'tbc'
                })
            });
        }

        this.splunkMasking(this.authReq, JSON.parse(req.body.message).url);
        return this.authReq;
    }

    splunkMasking(message: HttpRequest<any>, apiUrl? : string) {
        let event = message.body.event;
        let maskingIgnore = COMMON_CONSTANTS.IGNORE_MASKING;
        let maskingObj = maskingIgnore.find(obj => {
            if (apiUrl.includes(obj["route"])) {
                return obj;
            }
        });
        let messageString = JSON.stringify(event, function (key, value) {
            let valuesTobeMasked = COMMON_CONSTANTS.MASKING_INFORMATION;
            let maskedValue = value;
            valuesTobeMasked?.forEach((data) => {
                let _objMaskingIgnore = maskingObj?.ignore_object.find(e => e === data.name);
                if(_objMaskingIgnore === undefined) {
                    if (data.name == key && (typeof value == 'string' || typeof value == 'number')) {
                        if (value && data.type === COMMON_CONSTANTS.MASKING_TYPE.LAST_4) {
                            maskedValue =  maskedValue?.toString()?.replace(/.(?=.{4,}$)/g, '*');
                        }
                        if (value && data.type === COMMON_CONSTANTS.MASKING_TYPE.COMPLETE) {
                            maskedValue = maskedValue?.toString()?.replace(/./g, '*');
                        }
                        if (value && data.type === COMMON_CONSTANTS.MASKING_TYPE.LAST_2) {
                            maskedValue =  maskedValue?.toString()?.replace(/.(?=.{2,}$)/g, '*');
                        }
                        if (value && data.type === COMMON_CONSTANTS.MASKING_TYPE.LAST_3) {
                            maskedValue =  maskedValue?.toString()?.replace(/.(?=.{3,}$)/g, '*');
                        }
                        if (value && data.type === COMMON_CONSTANTS.MASKING_TYPE.LAST_8) {
                            maskedValue =  maskedValue?.toString()?.replace(/.(?=.{8,}$)/g, '*');
                        }
                        if(value && data.type === COMMON_CONSTANTS.MASKING_TYPE.TOTAL){
                            maskedValue = '****';
                        }
                    }
                }
            });
            return maskedValue;
        });
        message.body.event = JSON.parse(messageString);
    }
}
