import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export interface KubeEnvironment {
    recaptchaSiteKey: string;
}

//AppConfigService.ts
@Injectable()
export class KubeEnvironmentService {
    private config: KubeEnvironment;
    loaded = false;
    constructor(private http: HttpClient) {}
    loadConfig(): Promise<void> {
        return this.http
            .get<KubeEnvironment>('/assets/tfb-payment/kube-environment/kube-environment.json')
            .toPromise()
            .then(data => {
                this.config = data;
                this.loaded = true;
            });
    }
    
    getConfig(): KubeEnvironment {
        return this.config;
    }
}