import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loader: BehaviorSubject<any> = new BehaviorSubject<any>(true);
  constructor() { 
    // This is intentional
  }

  start( type?) {
    const options = {
      isDsiplay: false,
      type: type ? type : 'mega'
    };
    this.loader.next(options);
  }

  stop(type?) {
    const options = {
      isDsiplay: true,
      type: type ? type : 'mega'
    };
    this.loader.next(options);
  }
}
