import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[cvvACHInput]',
    standalone: true
})
export class CvvAchInputDirective {
    constructor() { }

    @HostListener('input', ['$event']) onInputChange(event) {
        const initalValue = event.target.value;
        event.target.value = initalValue.replace(/[^0-9]*/g, '');
        if ( initalValue !== event.target.value) {
            event.stopPropagation();
        }
    }
}
