const idpDomain = {
    prod: {
          iam: {
            clientId: "TfBPayment",
            url: "https://account.t-mobile.com"
          },
          okta: {
            url: "https://t-mobile.okta.com",
            clientId: "",
            issuerCode: "aus1frdo4l505bJ86297"
          }
    }
}

export const domain = {
    prod: {
        ...idpDomain.prod,
        iamHome: "http://tfb.t-mobile.com",
        oktaHome: "http://care.ahub.t-mobile.com",
        userManagement: "https://usermanagement.t-mobile.com"
    }
}
  