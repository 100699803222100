import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
    providedIn: "root"
})
export class AuthHttpService {

    constructor(private _http: HttpClient) { }

    public get(url: any, options?: object): Observable<any> {
        return this._http.get(url.path, _.merge(options, {params: {metaData: url}}))
            .pipe(map((response: Response) => response));
    }

    public post(url: any, body: any, options?: object): Observable<any> {
        return this._http.post(url.path, body, _.merge(options, {params: {metaData: url}}))
            .pipe(map((response: Response) => response));
    }

    public head(url: any, options?: object): Observable<any> {
        return this._http.head(url.path, options)
            .pipe(map((response: Response) => response));
    }

    public put(url: any, body: any, options?: object): Observable<any> {
        return this._http.put(url.path, body, options)
            .pipe(map((response: Response) => response));
    }

    public patch(url: any, body: any, options?: object): Observable<any> {
        return this._http.patch(url.path, body, options)
            .pipe(map((response: Response) => response));
    }

    public delete(url: any, options?: object): Observable<any> {
        return this._http.delete(url.path, options)
            .pipe(map((response: Response) => response));
    }

    public appendToURL(path: any, data: string) {
        const newPath = _.cloneDeep(path);
        newPath.path = path.path + data;
        return newPath;
    }
    public regeneratePath(url: any, path: string) {
        const newPath = _.cloneDeep(url);
        newPath.path = path;
        return newPath;
    }

}
