import { Component, OnInit, Input, Injector } from "@angular/core";
import { Subscription } from "rxjs";
import { AutoPaymentService } from "../../services/auto-payment.service";
import { ContentService } from "../../services/content.service";
import { EnvironmentDetailsService } from "../../services/environment-details.service";
import { HttpCacheService } from "../../services/http-cache.service";
import {
  COMMON_CONSTANTS,
  MakePaymentConstant,
  ACH_CONSTANTS,
  CREDIT_CARD_CONSTANTS,
} from "../../common/core/common.constants";
import { isNullOrUndefined } from "src/app/utilities/helper-utils";
import {
  CancelAutoPayBankModal,
  CancelAutoPayCCModal,
  CCBillPayAliasObj,
  BankBillPayAliasObj,
} from "src/app/common/models/data.model";
import {
  ErrorMessageService,
  ServiceMessage,
} from "src/app/services/error-message.service";
import {
  UpdateCrOrderPaymentModel,
  LegalDocument,
} from "../../common/models/data.model";
import { MakePaymentService } from "src/app/services/make-payment.service";
import { TermAndConditionService } from "src/app/services/term-and-condition.service";
import { BillPayService } from "../../services/bill-pay.service";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
import { PDLService } from "src/app/services/pdl.service";
import { ClickEventType } from "src/app/common/core/common.interface";
import * as _ from "lodash";
import { UtilsService } from "src/app/services/utils.service";
import { SuccessComponent } from "../success/success.component";
import { NgStyle, NgIf } from "@angular/common";
import { PipedOperatorsService } from "src/app/common/pipes/customOperators/pipedOperatorsService.service";

@Component({
    selector: "app-cancel",
    templateUrl: "./cancel.component.html",
    styleUrls: ["./cancel.component.scss"],
    standalone: true,
    imports: [
        NgStyle,
        NgIf,
        SuccessComponent,
    ],
})
export class CancelComponent implements OnInit {
  @Input() authoringContent: any;

  _autoPaymentSvc: AutoPaymentService;
  _envSvc: EnvironmentDetailsService;
  _cacheSvc: HttpCacheService;
  _errMsgService: ErrorMessageService;
  _makepymntSvc: MakePaymentService;
  _tcSvc: TermAndConditionService;
  pdlService: PDLService;
  contentService: ContentService;
  utils: UtilsService;
  billPaySvc: BillPayService;
  sanitizer: DomSanitizer;
  display = COMMON_CONSTANTS.BLOCK;
  errorMessage = false;
  deleteEnrollSub: Subscription;
  cancelEnrollData: any;
  returnURI: string;
  currentDate: any = new Date();
  dueDate: any;
  autopayDate: any;
  cancelDescription: any;
  savedPayData: any;
  delBankObj: CancelAutoPayBankModal = new CancelAutoPayBankModal();
  delCcObj: CancelAutoPayCCModal = new CancelAutoPayCCModal();
  ccBillPayAliasObj: CCBillPayAliasObj = new CCBillPayAliasObj();
  achBillPayAliasObj: BankBillPayAliasObj = new BankBillPayAliasObj();
  delObj: any;
  banNumber: any;
  errorauthoringContent: any;
  updateCrOrderStatus: string;
  updateCrOrderStatusDesc: any;
  dataConsentAp: any;
  orgID: any;
  careAuthType = false;
  UpdateCrOrderObj: UpdateCrOrderPaymentModel = new UpdateCrOrderPaymentModel();
  legalDocument: LegalDocument = new LegalDocument();
  autopayTC: any;
  billPayAliasObj: any;
  consentArray: any = [];
  dataConsentCheck: any;
  autopayTCSub: Subscription;
  tcSub: Subscription;
  tcAutoPayAgreementContent: any;
  termsAndConditionTxtData: any;
  errorAlertTC: ServiceMessage = { message: null, show: false };
  errorAlertAutoPayTC: ServiceMessage = { message: null, show: false };
  autoPaySelectedOption: any;
  retry = CREDIT_CARD_CONSTANTS.ZERO;
  errorAlert: ServiceMessage = { message: null, show: false };
  saveSuccess = false;
  enableRetry: boolean = true;
  CommonAuthoringContent: any;

  constructor(private injector: Injector,  private pipedOperatorsService: PipedOperatorsService) {
    this._autoPaymentSvc = injector.get<AutoPaymentService>(AutoPaymentService);
    this._envSvc = injector.get<EnvironmentDetailsService>(
      EnvironmentDetailsService
    );
    this._cacheSvc = injector.get<HttpCacheService>(HttpCacheService);
    this._errMsgService =
      injector.get<ErrorMessageService>(ErrorMessageService);
    this._makepymntSvc = injector.get<MakePaymentService>(MakePaymentService);
    this._tcSvc = injector.get<TermAndConditionService>(
      TermAndConditionService
    );
    this.pdlService = injector.get<PDLService>(PDLService);
    this.contentService = injector.get<ContentService>(ContentService);
    this.utils = injector.get<UtilsService>(UtilsService);
    this.billPaySvc = injector.get<BillPayService>(BillPayService);
    this.sanitizer = injector.get<DomSanitizer>(DomSanitizer);
  }

  ngOnInit() {
    this.autoPaySelectedOption = !isNullOrUndefined(
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.FLOW_KEY)
    )
      ? this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.FLOW_KEY)
      : COMMON_CONSTANTS.ENROLL_FLOW;

    if (this.autoPaySelectedOption === COMMON_CONSTANTS.CANCEL_FLOW) {
      this.setAutopayTC();
      this.returnURI = this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.RETURN_URL_SSP
      );
    } else {
      this.autopayTC = this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.APSERVICE_TERMS_DATA
      );
      this.returnURI = null;
    }

    if (
        (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) ===
      COMMON_CONSTANTS.OKTA_KEY || this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) ===
            COMMON_CONSTANTS.AZURE_KEY ) 
    ) {
      this.careAuthType = true;
    }
    if (
      !isNullOrUndefined(this._cacheSvc.getFromSessionStorage("contentmain"))
    ) {
      const data = JSON.parse(
        this._cacheSvc.getFromSessionStorage("contentmain")
      );
      this.authoringContent = data.par.common_payment.autoPay;
      this.CommonAuthoringContent = data.par.common_payment;
      this.errorauthoringContent = data.par.common_payment.error;
      this.savedPayData = ContentService.GetCache(
        COMMON_CONSTANTS.SAVED_PAYMNT_OBJECT
      );
      this.banNumber = this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.BAN_NUMBER_KEY
      );
      this.orgID = this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.ORG_ID_KEY
      );
    } else {
      this.contentService
        .GetContentData()
        .subscribe((data) => {
          this.authoringContent = data.par.common_payment.autoPay;
          this.CommonAuthoringContent = data.par.common_payment;
          this.errorauthoringContent = data.par.common_payment.error;
          this.savedPayData = ContentService.GetCache(
            COMMON_CONSTANTS.SAVED_PAYMNT_OBJECT
          );
          this.banNumber = this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.BAN_NUMBER_KEY
          );
          this.orgID = this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.ORG_ID_KEY
          );
        });
    }

    this._autoPaymentSvc.getBalanceApi().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
      (data) => {
        if (!isNullOrUndefined(data)) {
          if (
            !isNullOrUndefined(data.billingAccounts[0]) &&
            !isNullOrUndefined(data.billingAccounts[0].billInfo) &&
            !isNullOrUndefined(data.billingAccounts[0].billInfo.dueDate)
          ) {
            this.dueDate = new Date(data.billingAccounts[0].billInfo.dueDate);
            this.dueDate.setDate(this.dueDate.getDate() - 2);
            if (this.currentDate < this.dueDate) {
              this.cancelDescription = this.authoringContent.cancelDescription1;
            } else if (this.currentDate >= this.dueDate) {
              this.cancelDescription = this.authoringContent.cancelDescription2;
            } else {
              this.cancelDescription = this.authoringContent.cancelDescription3;
            }
          }
        }
      },
      (err) => {
        // This is intentional
      }
    );
  }

  setAutopayTC(): any {
    this.autopayTCSub = this._tcSvc.getAutoPayAgreement().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
      (autoPayData) => {
        this.autopayTC = autoPayData;
        this._cacheSvc.putInSessionStoarage(
          COMMON_CONSTANTS.APSERVICE_TERMS_DATA,
          JSON.stringify(this.autopayTC)
        );
        const currDateTime = new Date();
        const agreementTime = currDateTime.toISOString();
        const acceptTimestamp1 = agreementTime.slice(0, -1) + "+0000";

        const autoPayAgreementConsent = {
          documentId: this.autopayTC.acceptDocumentId,
          acceptedTimeStamp: acceptTimestamp1,
        };
        this._tcSvc.setSelectedTerms(autoPayAgreementConsent);
        this.tcAutoPayAgreementContent = atob(
          this.autopayTC.attachments.embeddedData.binaryContent
        );
        this.tcAutoPayAgreementContent = this.sanitizer.bypassSecurityTrustHtml(
          this.tcAutoPayAgreementContent
        );
      },
      (err) => {
        this.errorAlertAutoPayTC = {
          message: {
            errorKey: err.status,
            errorMessage: err.error.error[0].userMessage,
            errorType: COMMON_CONSTANTS.AUTOPAY_PROPERTIES.ERR_SERVER,
          },
          show: true,
        };
      }
    );
  }

  onCloseHandled(buttonType: any) {
    if (
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.FLOW_KEY) ===
      COMMON_CONSTANTS.CANCEL_FLOW
    ) {
      this.returnURI = this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.RETURN_URL_SSP
      );
      this.return();
    } else {
      this._autoPaymentSvc.cancelComponent = false;
    }
    if (buttonType == "close") {
      // analytics click tagging
      this.generateClickDetailsForCancel(
        "click",
        "button",
        ClickEventType.NAVIGATE,
        "Close",
        "Cancel AutoPay"
      );
    } else if (buttonType == "cancel") {
      // analytics click tagging
      this.generateClickDetailsForCancel(
        "click",
        "button",
        ClickEventType.NAVIGATE,
        "Cancel",
        "Cancel AutoPay"
      );
    }
  }

  return() {
    this.utils.return(this.returnURI, false);
  }

  submitConfirm() {
    if (
      this.savedPayData &&
      this.savedPayData["paymentInstrument"] &&
      this.savedPayData["paymentInstrument"].paymentCard
    ) {
      this.delCcObj.payment.paymentMethodReference.paymentMethodCode =
        this.savedPayData.paymentMethodReference.paymentMethodCode;
      this.delCcObj.payment.autopayTermsAgreementIndicator = true;
      const currDateTime = new Date();
      const agreementTime = currDateTime.toISOString();
      this.delCcObj.payment.autopayTermsAgreementTime = agreementTime;

      this.delCcObj.payment.paymentInstrument.paymentCard.paymentCardAlias =
        this.savedPayData.paymentInstrument.paymentCard.paymentCardAlias;

      this.delCcObj.payment.autoPay.discountTxnId = 1;
      this.delCcObj.payment.autoPay.unenrollReasonCode =
        MakePaymentConstant.AP_DELUSERREQ;
      this.delCcObj.payment.businessUnit = MakePaymentConstant.businessUnit;
      this.delCcObj.payment.programCode = MakePaymentConstant.programCode;
      this.delCcObj.payment.billerCode = MakePaymentConstant.billerCode;
      this.delCcObj.payment.businessSegment =
        MakePaymentConstant.autoBusinessSegment;
      this.delCcObj.payment.productGroup = MakePaymentConstant.productGroup;
      this.delCcObj.customerReference.customerId = this.banNumber;
      this.delObj = this.delCcObj;
    } else if (
      this.savedPayData &&
      this.savedPayData["paymentInstrument"] &&
      this.savedPayData["paymentInstrument"].bankPayment
    ) {
      this.delBankObj.payment.paymentMethodReference.paymentMethodCode =
        this.savedPayData.paymentMethodReference.paymentMethodCode;
      this.delBankObj.payment.autopayTermsAgreementIndicator = true;
      const currDateTime = new Date();
      const agreementTime = currDateTime.toISOString();
      this.delBankObj.payment.autopayTermsAgreementTime = agreementTime;

      this.delBankObj.payment.paymentInstrument.bankPayment.bankAccountAlias =
        this.savedPayData.paymentInstrument.bankPayment.bankAccountAlias;

      this.delBankObj.payment.autoPay.discountTxnId = 1;
      this.delBankObj.payment.autoPay.unenrollReasonCode =
        MakePaymentConstant.AP_DELUSERREQ;
      this.delBankObj.payment.businessUnit = MakePaymentConstant.businessUnit;
      this.delBankObj.payment.programCode = MakePaymentConstant.programCode;
      this.delBankObj.payment.billerCode = MakePaymentConstant.billerCode;
      this.delBankObj.payment.businessSegment =
        MakePaymentConstant.autoBusinessSegment;
      this.delBankObj.payment.productGroup = MakePaymentConstant.productGroup;
      this.delBankObj.customerReference.customerId = this.banNumber;
      this.delObj = this.delBankObj;
    }

    this.createOrderId(this.savedPayData);

    this.billPaySvc
      .billPayAliasInfo(this.billPayAliasObj, this.orgID)
      .subscribe(
        (billPayRes: any) => {
          this._cacheSvc.putInSessionStoarage(
            COMMON_CONSTANTS.TRANS_REF_NUM,
            billPayRes.transRefNum
          );
          this._cacheSvc.putInSessionStoarage(
            COMMON_CONSTANTS.TRANSACTION_ID,
            billPayRes.transactionId
          );
          this.delObj.payment.orderNumber = billPayRes.transRefNum || "";
          this.cancelAutopay();
        },
        (error) => {
          this.errorMessage = true;
        }
      );

    // analytics click tagging
    this.generateClickDetailsForCancel(
      "click",
      "button",
      ClickEventType.ACTION,
      "Confirm",
      "Cancel AutoPay"
    );
  }

  cancelAutopay() {
    // analytics click tagging
    this.generateClickDetailsForCancel(
      "click",
      "link",
      ClickEventType.ACTION,
      "Turn Off AutoPay",
      "Cancel AutoPay"
    );

    this.dataConsentCheck = ContentService.GetCache(
      COMMON_CONSTANTS.TERMS_CONSENT
    );
    this._cacheSvc.putInSessionStoarage(
      MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
      MakePaymentConstant.FLOW_TYPE.CANCEL_AUTOPAY
    );

    if (isNullOrUndefined(this.dataConsentCheck)) {
      if (this.autopayTC) {
        this._tcSvc.recordUserConsent().subscribe(
          (data) => {
            if (data.postedDocumentDetails[0].status === "FAILURE") {
              this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
              this.updateCrOrderStatusDesc =
                this.errorauthoringContent.genericErrorApTxt;
              this.updateCROrderId();
              this.errorMessage = true;
            } else {
              this.dataConsentAp = data;
              ContentService.SetCache(
                COMMON_CONSTANTS.TERMS_CONSENT,
                this.dataConsentAp
              );
              this.deleteEnrollSub = this._autoPaymentSvc
                .cancelEnrollAutoPayment(this.delObj)
                .subscribe(
                  (cancelAutoPayData) => {
                    if (
                      environment.ENABLE_PARTNER &&
                      this._cacheSvc.getFromSessionStorage(
                        COMMON_CONSTANTS.IS_PARTNER_BOARD
                      ) === "true"
                    ) {
                      if (
                        !isNullOrUndefined(cancelAutoPayData) &&
                        !isNullOrUndefined(cancelAutoPayData.payment) &&
                        !isNullOrUndefined(
                          cancelAutoPayData.payment.messages
                        ) &&
                        !isNullOrUndefined(
                          cancelAutoPayData.payment.messages[0].redirectUrl
                        )
                      ) {
                        this._cacheSvc.putInSessionStoarage(
                          COMMON_CONSTANTS.RETURN_URL_SSP,
                          cancelAutoPayData.payment.messages[0].redirectUrl
                        );
                      }
                    }
                    if (this._errMsgService.getErrorMessage().show) {
                      this.updateCrOrderStatus =
                        MakePaymentConstant.failedStatus;
                      // Actual User Message from API to the PUT account Order API
                      this.updateCrOrderStatusDesc = cancelAutoPayData
                        ?.responseCodeList[0]?.errors?.responseErrors[0]
                        ? cancelAutoPayData?.responseCodeList[0]?.errors
                            ?.responseErrors[0]?.userMessage
                        : "";
                      this.updateCROrderId();
                      this.retry++;
                      this.errorAlert = this._errMsgService.getErrorMessage();
                      this.saveSuccess = false;
                      // tslint:disable-next-line:max-line-length
                      const negativeBanReasonCodeList = _.split(
                        this.CommonAuthoringContent.error
                          .negativeFileCheckcommonErrorCode,
                        ","
                      );
                      if (
                        !isNullOrUndefined(
                          this.errorAlert &&
                            this.errorAlert.message &&
                            this.errorAlert.message.errorKey
                        ) &&
                        negativeBanReasonCodeList.includes(
                          this.errorAlert.message.errorKey
                        ) &&
                        !isNullOrUndefined(cancelAutoPayData.payment)
                      ) {
                        this.enableRetry = false;
                      }
                      if (this.retry > CREDIT_CARD_CONSTANTS.TWO) {
                        this.enableRetry = false;
                      }
                      this.errorMessage = true;
                      return false;
                    } else if (
                      cancelAutoPayData?.reason?.reasonCode ===
                        MakePaymentConstant.APPROVED_MPI_CODE &&
                      !cancelAutoPayData["responseCodeList"]
                    ) {
                      this.cancelEnrollData = cancelAutoPayData;
                      this._autoPaymentSvc.cancelComponent = false;
                      this._autoPaymentSvc.successComponent = true;
                      this._autoPaymentSvc.buttonValue =
                        this.authoringContent.confirmButtonText;
                      this.updateCrOrderStatus = "S";
                      // tslint:disable-next-line:max-line-length
                      this.updateCrOrderStatusDesc = cancelAutoPayData[
                        "statusBody"
                      ]
                        ? cancelAutoPayData["statusBody"].reasonDescription
                        : "";
                      this.updateCROrderId();
                    } else if (
                      cancelAutoPayData["responseCodeList"].length > 0
                    ) {
                      this.updateCrOrderStatus =
                        MakePaymentConstant.failedStatus;
                      this.updateCrOrderStatusDesc = cancelAutoPayData
                        ?.responseCodeList[0]?.errors?.responseErrors[0]
                        ? cancelAutoPayData?.responseCodeList[0]?.errors
                            ?.responseErrors[0]?.userMessage
                        : "";
                      this.updateCROrderId();
                      this.saveSuccess = false;
                      this.errorMessage = true;
                      return false;
                    }
                  },
                  (err) => {
                    this.errorMessage = true;
                    this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
                    this.updateCrOrderStatusDesc =
                      this.errorauthoringContent.genericErrorApTxt;
                    this.updateCROrderId(); // actual
                  }
                );
            }
          },
          (error) => {
            this.errorMessage = ContentService.GetCache("content");
            this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
            this.updateCrOrderStatusDesc =
              this.errorauthoringContent.genericErrorApTxt;
            this.updateCROrderId(); // actual

            this.errorMessage = true;
          }
        );
      } else if (!this.autopayTC) {
        this.errorMessage = ContentService.GetCache("content");
        this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
        this.updateCrOrderStatusDesc =
          this.errorauthoringContent.genericErrorApTxt;
        this.updateCROrderId(); // actual

        this.errorMessage = true;
      }
    } else if (!isNullOrUndefined(this.dataConsentCheck)) {
      this.deleteEnrollSub = this._autoPaymentSvc
        .cancelEnrollAutoPayment(this.delObj)
        .subscribe(
          (cancelAutoPayData) => {
            if (
              environment.ENABLE_PARTNER &&
              this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.IS_PARTNER_BOARD
              ) === "true"
            ) {
              if (
                !isNullOrUndefined(cancelAutoPayData) &&
                !isNullOrUndefined(cancelAutoPayData.payment) &&
                !isNullOrUndefined(cancelAutoPayData.payment.messages) &&
                !isNullOrUndefined(
                  cancelAutoPayData.payment.messages[0].redirectUrl
                )
              ) {
                this._cacheSvc.putInSessionStoarage(
                  COMMON_CONSTANTS.RETURN_URL_SSP,
                  cancelAutoPayData.payment.messages[0].redirectUrl
                );
              }
            }
            if (this._errMsgService.getErrorMessage().show) {
              this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
              // Actual User Message from API to the PUT account Order API
              this.updateCrOrderStatusDesc = cancelAutoPayData
                ?.responseCodeList[0]?.errors?.responseErrors[0]
                ? cancelAutoPayData?.responseCodeList[0]?.errors
                    ?.responseErrors[0]?.userMessage
                : "";
              this.updateCROrderId();
              this.retry++;
              this.errorAlert = this._errMsgService.getErrorMessage();
              this.saveSuccess = false;
              const negativeBanReasonCodeList = _.split(
                this.CommonAuthoringContent.error
                  .negativeFileCheckcommonErrorCode,
                ","
              );
              if (
                !isNullOrUndefined(
                  this.errorAlert &&
                    this.errorAlert.message &&
                    this.errorAlert.message.errorKey
                ) &&
                negativeBanReasonCodeList.includes(
                  this.errorAlert.message.errorKey
                ) &&
                !isNullOrUndefined(cancelAutoPayData.payment)
              ) {
                this.enableRetry = false;
              }
              if (this.retry > CREDIT_CARD_CONSTANTS.TWO) {
                this.enableRetry = false;
              }
              this.errorMessage = true;
              return false;
            } else if (
              cancelAutoPayData?.reason?.reasonCode ===
                MakePaymentConstant.APPROVED_MPI_CODE &&
              !cancelAutoPayData["responseCodeList"]
            ) {
              this.cancelEnrollData = cancelAutoPayData;
              this._autoPaymentSvc.cancelComponent = false;
              this._autoPaymentSvc.successComponent = true;
              this._autoPaymentSvc.buttonValue =
                this.authoringContent.confirmButtonText;

              this.updateCrOrderStatus = "S";
              // tslint:disable-next-line:max-line-length
              this.updateCrOrderStatusDesc = cancelAutoPayData["statusBody"]
                ? cancelAutoPayData["statusBody"].reasonDescription
                : "";
              this.updateCROrderId();
            } else if (cancelAutoPayData["responseCodeList"].length > 0) {
              this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
              this.updateCrOrderStatusDesc = cancelAutoPayData
                ?.responseCodeList[0]?.errors?.responseErrors[0]
                ? cancelAutoPayData?.responseCodeList[0]?.errors
                    ?.responseErrors[0]?.userMessage
                : "";
              this.updateCROrderId();
              this.saveSuccess = false;
              this.errorMessage = true;
              return false;
            }
          },
          (err) => {
            this.errorMessage = true;
            this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
            this.updateCrOrderStatusDesc =
              this.errorauthoringContent.genericErrorApTxt;
            this.updateCROrderId(); // actual
          }
        );
    }
  }

  updateCROrderId() {
    this.UpdateCrOrderObj.billingAccount = this.banNumber;
    const euiTokenData = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.EUI_TOKEN_DATA
    );
    this.UpdateCrOrderObj.logonId = euiTokenData
      ? JSON.parse(euiTokenData).logonId
      : "";
    this.UpdateCrOrderObj.emailNotification.firstName = euiTokenData
      ? JSON.parse(euiTokenData)?.firstName
      : "";
    this.UpdateCrOrderObj.emailNotification.emailId =
      this.UpdateCrOrderObj.logonId;
    this.UpdateCrOrderObj.status = this.updateCrOrderStatus;
    this.UpdateCrOrderObj.transactionDesc = this.updateCrOrderStatusDesc;

    if (
      !isNullOrUndefined(this.dataConsentAp) &&
      !isNullOrUndefined(this.dataConsentAp.postedDocumentDetails)
    ) {
      for (let eachPostedDocumentDetails of this.dataConsentAp
        .postedDocumentDetails) {
        const element = {};
        element["docId"] = !isNullOrUndefined(
          eachPostedDocumentDetails.ingestDocumentId
        )
          ? eachPostedDocumentDetails.ingestDocumentId
          : "";

        element["docType"] = !isNullOrUndefined(
          eachPostedDocumentDetails.documentType
        )
          ? eachPostedDocumentDetails.documentType
          : "";
        element["acceptedDateTime"] =
          this._tcSvc.findAndConvertAcceptedTimeStampByDocumentId(
            eachPostedDocumentDetails.documentId
          );

        this.consentArray.push(element);
      }
      this.UpdateCrOrderObj.legalDocuments = this.consentArray;
    } else if (!this.dataConsentAp) {
      this.UpdateCrOrderObj.legalDocuments.push(this.legalDocument);
    }

    this._makepymntSvc.updateCROrder(this.UpdateCrOrderObj).subscribe(
      (crData) => {
        // This is intentional
      },
      (error) => {
        // This is intentional
      }
    );
  }

  createOrderId(valResponse) {
    if (
      valResponse &&
      valResponse["paymentInstrument"] &&
      valResponse["paymentInstrument"].bankPayment
    ) {
      this.achBillPayAliasObj.paymentType = ACH_CONSTANTS.PAYMENT_TYPE;
      this.achBillPayAliasObj.accountHolderName = "";
      this.achBillPayAliasObj.bankAccountAlias = !isNullOrUndefined(
        valResponse.paymentInstrument
      )
        ? valResponse.paymentInstrument.bankPayment.bankAccountAlias
        : "";

      this.achBillPayAliasObj.accountType = "";

      this.achBillPayAliasObj.routingNumber = !isNullOrUndefined(
        valResponse.paymentInstrument
      )
        ? valResponse.paymentInstrument.bankPayment.routingNumber
        : "";
      this.achBillPayAliasObj.billingAccountNumber = this.banNumber;
      this.achBillPayAliasObj.orgId = this.orgID;
      this.achBillPayAliasObj.skipLegacyValidation = "true";
      this.achBillPayAliasObj.transactionType =
        COMMON_CONSTANTS.CANCEL_TRANSACTION_TYPE;
      this.billPayAliasObj = this.achBillPayAliasObj;
    } else if (
      valResponse &&
      valResponse["paymentInstrument"] &&
      valResponse["paymentInstrument"].paymentCard
    ) {
      this.ccBillPayAliasObj.paymentType = CREDIT_CARD_CONSTANTS.PAYMENT_TYPE;
      this.ccBillPayAliasObj.cardholderName = !isNullOrUndefined(
        valResponse.paymentInstrument
      )
        ? valResponse.paymentInstrument.paymentCard.cardHolderName
        : "";
      // tslint:disable-next-line:max-line-length
      this.ccBillPayAliasObj.cardNumberAlias = !isNullOrUndefined(
        valResponse.paymentInstrument
      )
        ? valResponse.paymentInstrument.paymentCard.paymentCardAlias
        : "";
      this.ccBillPayAliasObj.expireMonth = !isNullOrUndefined(
        valResponse.paymentInstrument
      )
        ? valResponse.paymentInstrument.paymentCard.expirationMonthYear.slice(
            0,
            2
          )
        : "";
      this.ccBillPayAliasObj.expireYear = !isNullOrUndefined(
        valResponse.paymentInstrument
      )
        ? valResponse.paymentInstrument.paymentCard.expirationMonthYear.slice(
            3,
            5
          )
        : "";
      this.ccBillPayAliasObj.cardBrand = !isNullOrUndefined(
        valResponse.paymentInstrument
      )
        ? valResponse.paymentInstrument.paymentCard.typeCode
        : "";

      this.ccBillPayAliasObj.billingAccountNumber = this.banNumber;
      this.ccBillPayAliasObj.orgId = this.orgID;
      this.ccBillPayAliasObj.skipLegacyValidation = "true";
      this.ccBillPayAliasObj.transactionType =
        COMMON_CONSTANTS.CANCEL_TRANSACTION_TYPE;

      this.ccBillPayAliasObj.billingAddress.address1 = !isNullOrUndefined(
        valResponse.paymentInstrument.paymentCard.billingAddress
      )
        ? valResponse.paymentInstrument.paymentCard.billingAddress.address1
        : "";
      this.ccBillPayAliasObj.billingAddress.city = !isNullOrUndefined(
        valResponse.paymentInstrument.paymentCard.billingAddress
      )
        ? valResponse.paymentInstrument.paymentCard.billingAddress.city
        : "";
      this.ccBillPayAliasObj.billingAddress.state = !isNullOrUndefined(
        valResponse.paymentInstrument.paymentCard.billingAddress
      )
        ? valResponse.paymentInstrument.paymentCard.billingAddress.state
        : "";
      this.ccBillPayAliasObj.billingAddress.zipCode = !isNullOrUndefined(
        valResponse.paymentInstrument.paymentCard.billingAddress
      )
        ? valResponse.paymentInstrument.paymentCard.billingAddress.zipCode
        : "";
      this.billPayAliasObj = this.ccBillPayAliasObj;
    }
  }

  generateClickDetailsForCancel(
    actionInput: string,
    assetTypeInput: string,
    typeInput: ClickEventType,
    ctaNameInput: string,
    modalNameInput: string
  ) {
    let clickDetail = {
      action: actionInput,
      assetType: assetTypeInput,
      type: typeInput,
      ctaName: ctaNameInput,
      modalName: modalNameInput,
    };

    this.pdlService.generateClickTag(clickDetail);
  }
}
