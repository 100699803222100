import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { EnvironmentDetailsService } from './environment-details.service';
import { ApigeeTokenService } from './apigee-token.service';
import { LoaderService } from './loader.service';
import { HttpCacheService } from './http-cache.service';
import { COMMON_CONSTANTS } from '../common/core/common.constants';
import { DecodeParamsService } from './decode-params.service';
@Injectable()
export class SwatchResolver  {
    isResolved: boolean;
    isLoaded: boolean;
    BanNumber: string;
    OrgID: string;
    constructor(private _envSvc: EnvironmentDetailsService,
        private _apigeeSvc: ApigeeTokenService,
        private _loaderSvc: LoaderService,
        private _cacheSvc: HttpCacheService,
        private decodeParamsSvc: DecodeParamsService ) { }

    resolve(route: ActivatedRouteSnapshot): Promise<any> {
        this._loaderSvc.start();
        this.saveParamsInSession(route);
        return this._envSvc.getSessionEndpointsEnv().then(q => {
            this._envSvc.CURR_SESSION_ENDPOINTS = q.WCS_ENPOINT_CONST;
            this._envSvc.APIGEE_TOKEN_URLS = q.APIGEE_TOKEN_URL;

            return this._envSvc.getContentEnvt().then(x => {
                this._envSvc.CURR_CONTENT_URLS = x.CONTENT_PAGES_URL;
                // This call Gets tokens
                return this._apigeeSvc.getTokens().then((token) => {
                    this._loaderSvc.stop();
                    this.isResolved = true;
                });
            });
        });
    }
    private saveParamsInSession(route: ActivatedRouteSnapshot) { 
        if (route.queryParamMap) {
            if (COMMON_CONSTANTS.ENABLE_PID_FLOW !== undefined && COMMON_CONSTANTS.ENABLE_PID_FLOW) {
                const queryParam = this.decodeParamsSvc.decodeBase64(route.queryParamMap.get(COMMON_CONSTANTS.PID_KEY) ?
                    route.queryParamMap.get(COMMON_CONSTANTS.PID_KEY) : '');

                this.BanNumber = queryParam[COMMON_CONSTANTS.BAN_NUMBER_KEY] ?
                    queryParam[COMMON_CONSTANTS.BAN_NUMBER_KEY] :
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY);

                this.OrgID = queryParam[COMMON_CONSTANTS.ORG_ID_KEY] ?
                    queryParam[COMMON_CONSTANTS.ORG_ID_KEY] :
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY);
            } else {
                this.BanNumber = route.queryParamMap.get(COMMON_CONSTANTS.BAN_NUMBER_KEY) ?
                    route.queryParamMap.get(COMMON_CONSTANTS.BAN_NUMBER_KEY) :
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY);

                this.OrgID = route.queryParamMap.get(COMMON_CONSTANTS.ORG_ID_KEY) ?
                    route.queryParamMap.get(COMMON_CONSTANTS.ORG_ID_KEY) :
                    this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY);
            }
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.BAN_NUMBER_KEY, this.BanNumber);
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ORG_ID_KEY, this.OrgID);

        }
    }

}
