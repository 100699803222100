import { Injectable } from '@angular/core';
import { AuthHttpService } from './http-wrapper.service';
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { COMMON_CONSTANTS } from '../common/core/common.constants';
import { HttpCacheService } from './http-cache.service';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils'; //needed for a unit test
import { staticContent } from 'src/environments/environment.content';



@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private static CacheFactory: any[] = [];

  constructor(private _http: AuthHttpService, private _cacheSvc: HttpCacheService) { }


  public static SetCache(key, value) {
      this.CacheFactory[key] = value;
  }

  public static GetCache(key): any {
      return this.CacheFactory[key];
  }

  public static ClearCacheKey(key) {
      this.CacheFactory.splice(this.CacheFactory.indexOf([key]), 1);
  }

  public GetContentData(): Observable<any> {
    return of({par: staticContent.par});
  }
}
