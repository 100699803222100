import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ElementRef, ViewChild, Injector } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import {MatFormField, MatInput, MatLabel} from '@angular/material/input';

import {
  CCTYPE_CONSTANTS, COMMON_CONSTANTS, CreditCardConstants, CREDIT_CARD_CONSTANTS,
   monthJSON, stateJSON, GoogleAddressTypes, CardBrands, CREDIT_CARD_NUMBER_CONSTANTS,MakePaymentConstant
} from '../common/core/common.constants';
import { CCAutoPayObj, CCBillPayAliasObj, EncryptionKey, PaymentValidation, Specifications, accountDetailsRequestData } from '../common/models/data.model';
import { BillPayService } from '../services/bill-pay.service';
import { ContentService } from '../services/content.service';
import { CreditCardService } from '../services/credit-card.service';
import { EnvironmentDetailsService } from '../services/environment-details.service';
import { ErrorMessageService, ServiceMessage } from '../services/error-message.service';
import { GetCreditCardTypeService } from '../services/get-credit-card-type.service';
import { HttpCacheService } from '../services/http-cache.service';
import { LoaderService } from '../services/loader.service';
import { UtilsService } from '../services/utils.service';
import { Observable, Subscription, map, of, tap } from 'rxjs';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { MakePaymentService } from '../services/make-payment.service';
import { AutoPaymentService } from '../services/auto-payment.service';
import { staticContent } from "../../environments/environment.content";
import { Router } from '@angular/router';
import { PipedOperatorsService } from '../common/pipes/customOperators/pipedOperatorsService.service';
import { LoaderComponent } from '../loader/loader.component';
import { PhoneMaskDirective } from '../directive/phone-mask.directive';
import { NumbersOnlyDirective } from '../directive/numbers-only.directive';
import { CvvAchInputDirective } from '../directive/cvv-ach-input.directive';
import { OptionalFloatingLabelDirective } from '../directive/floating-label.directive';
import {NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { CharactersOnlyDirective } from '../directive/characters-only.directive';
import { MaskDirective } from '../directive/mask-directive';
import {CreditCardInputDirective} from '../directive/credit-card-input.directive';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownButtonItem, NgbDropdownItem, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CreditCardDirective } from '../directive/credit-card.directive';
import { CreditCardMaskDirective } from '../directive/credit-card-mask.directive';
import { NgIf, NgFor, NgClass } from '@angular/common';
import {CdkAutofill} from "@angular/cdk/text-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {MatIcon} from "@angular/material/icon";
declare const encrypt;
// import { OnlyNumbersDirective } from './only-numbers.directive';

@Component({
    selector: 'app-credit-card',
    templateUrl: './credit-card.component.html',
    styleUrls: ['./credit-card.component.scss'],
    standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, NgClass, CreditCardInputDirective, CreditCardMaskDirective, CreditCardDirective, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownButtonItem, NgbDropdownItem, MaskDirective, NgbTooltip, CharactersOnlyDirective, NgxGpAutocompleteModule, OptionalFloatingLabelDirective, NumbersOnlyDirective, PhoneMaskDirective, LoaderComponent, CdkAutofill, MatLabel, MatFormField, MatOption, MatSelect, MatIcon, CvvAchInputDirective]
})
export class CreditCardComponent implements OnInit, OnDestroy {
  _loaderSvc: LoaderService;
  _envSvc: EnvironmentDetailsService;
  _errMsgService: ErrorMessageService;
  _cacheSvc: HttpCacheService;
  _paymentSvc: MakePaymentService;
  fb: UntypedFormBuilder;
  creditService: CreditCardService;
  credittypeservice: GetCreditCardTypeService;
  contentService: ContentService;
  utils: UtilsService;
  billPaySvc: BillPayService;
  _autoPaymentSvc: AutoPaymentService;
  months = monthJSON;
  cardType1 = '';
  cvvMaxLength: number = 3; // Default CVV length
  cardNumber: string = '';
  cvv: string = '';
  expMonth: string = '';
  expYear: string = '';
  showCardDetails: boolean = false;
  showCVVDetails: boolean = false;
  // @ViewChild('cvvInput') cvvInput: MatInput;
  // @ViewChild('creditInput') creditInput: MatInput;

  years = [];
  cardTypeIcons = [
    {name: 'Visa Card', url: '../../assets/tfb-payment/images/credit-visa.png', id: 1, brand: CardBrands.VISA},
    {name: 'Master Card', url: '../../assets/tfb-payment/images/cc-master.png', id: 2, brand: CardBrands.MASTERCARD},
    {
      name: 'American Epress Card',
      url: '../../assets/tfb-payment/images/cc-amex.png',
      id: 3,
      brand: CardBrands.AMERICANEXPRESS
    },
    {name: 'Discover Card', url: '../../assets/tfb-payment/images/cc-discover.png', id: 4, brand: CardBrands.DISCOVER}
  ];

  errorAlert: ServiceMessage = {message: null, show: false};
  selectedMonth: any;
  selectedYear: string;
  selectedExpDate: string;
  creditCardNumber: string;
  public value: string;
  totalDueNewCardForm: UntypedFormGroup;
  cardBrand: any = {};
  cardCategory: any = {};
  cardType: any;
  maskedCcNumber: string;
  cardNumberStar: string;
  stateSelected: any;
  authoringContent: any;
  cvvLength: number;
  cardLength: number;
  successMessage: any;
  maskCardBool: any = true;
  saveSuccess = false;
  savePaymentRes: any;
  cardDetailObject: any;
  selectMonthTxt: string;
  selectYearTxt: string;
  entrustUrl: any;
  encrypedNumber: any;
  mobileDetails: any[] = [];
  cc = false;
  dc = false;
  isAchEncryptComplete: boolean;
  eligibleForDiscount: boolean = true;
  router: Router;
  maskingCvvVal: boolean = false;
  unMaskCvvValue: string = '';
  public stateList = stateJSON;

  @Input() billingAccNo: any;
  @Input() orderAccId: any;
  @Input() orgAccId: any;
  @Input() flowType: any;
  @Input() orgId: number;
  @Input() banNumber: any;
  @Input() isFuturePayment: boolean;
  @Input() isCurrentPayment: any;
  @Input() paymentDetails: any;
  @Input() paymentCardType: any;
  @Input() creditMigrationDate: any;
  @Input() isEdit: boolean = true;
  @Input() isInstallmentRadioSelected: boolean = false;
  @Input() cardBankInfo: any;
  @Input() manageAutopay: boolean = false;
  @Input() mayReleaseFlag: boolean = false;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Output() onEnrollCredit: EventEmitter<any> = new EventEmitter();
  @Output() ccBillPayAliasSuccess: EventEmitter<PaymentValidation> = new EventEmitter();
  isCCEncryptComplete: boolean;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onCardInfoRemove: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('creditCardNumber') creditCardNumberRef: ElementRef;
  @ViewChild('ccState') ccState: any; //
  @ViewChild('expirationMonth') expirationMonth: any; //
  @ViewChild('expirationYear') expirationYearVal: any; //
  @ViewChild('creditCardNumber') ccInput: any;
  // @ViewChild('cvv') cvvInput: any;

  isValid = true;
  iscreditcardvalid: any;
  chgreqOrderid: any;
  billpayFlag = false;
  careAuthType = false;
  editObject = {creditCardNumber: '', expMonth: '', expYear: '', nameOncard: '', ccZip: '', displaycc: ''};
  key1: EncryptionKey = new EncryptionKey();
  ccBillPayAliasData: CCBillPayAliasObj = new CCBillPayAliasObj();
  autoPayPostData: CCAutoPayObj = new CCAutoPayObj();
  ccValData: PaymentValidation = new PaymentValidation();
  ccSpecifications: Specifications = new Specifications();
  retry = CREDIT_CARD_CONSTANTS.ZERO;
  errorMessage: any;
  creditCardNameLength: any;
  cardWaringMessage: string;
  isCCNumberInvalid: boolean;
  maskedChars: string;
  firstEntry: boolean = true
  cardMaxLength: string = "16";
  cardMinLength: any;
  paymentMethodCode: any;
  iscardLengthValid = false;
  isV4CardTypeEnabled = false;
  isLuhnChecked = true;
  contentSubscription: Subscription;
  originalValue: string = "";
  creditCardNumberWithSpaces: string = ""
  publicKeySubscription: Subscription;
  cnpSalesSubscription: Subscription;
  billPaySubscription: Subscription;
  savePaymentSubscription: Subscription;
  cardTypeSubscription: Subscription;
  configSubscription: Subscription;
  disabled = '';
  phoneNumberError = {status: false, message: ''};
  phoneNumberSuccess = {status: false, message: ''};
  discountObj: any;
  bankAccountNo = false;
  bankAccountAlias: any;
  noWhitespace: string = "";
  cardNo = false;
  cardNoAlias: any;
  accountDetailsRequestData: accountDetailsRequestData = new accountDetailsRequestData();
  creditCardInfo = staticContent.components.cardMessage.creditCardInfo;
  debitCardInfo = staticContent.components.cardMessage.debitCardInfo;
  invalidCreditCard = staticContent.components.cardMessage.invalidCreditCard;
  wrongCreditCard = staticContent.components.cardMessage.wrongCreditCard;
  invalidDebitcard = staticContent.components.cardMessage.invalidDebitcard;
  wrongDebitCard = staticContent.components.cardMessage.wrongDebitCard;
  creditCardNoMsg = staticContent.components.cardMessage.creditCardNoMsg;
  debitCardNoMsg = staticContent.components.cardMessage.debitCardNoMsg;
  firstRun: boolean = true;
  firstCVV: boolean = true;
  inputType: string = "text";
  cvvInputType: string = "text"
  ccMaxLength: number = 16;
  constructor(private injector: Injector, private pipedOperatorsService: PipedOperatorsService) {
    HttpCacheService.SetCache(CREDIT_CARD_CONSTANTS.MASKING_VALUE, this.maskCardBool);
    HttpCacheService.SetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER, '');
    this.isCCEncryptComplete = false;
    this.isFuturePayment = false;
    this.maskedChars = COMMON_CONSTANTS.MASKING_CHARACTERS;
    this._loaderSvc = injector.get<LoaderService>(LoaderService);
    this._envSvc = injector.get<EnvironmentDetailsService>(EnvironmentDetailsService);
    this._errMsgService = injector.get<ErrorMessageService>(ErrorMessageService);
    this._cacheSvc = injector.get<HttpCacheService>(HttpCacheService);
    this._paymentSvc = injector.get<MakePaymentService>(MakePaymentService);
    this.fb = injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.creditService = injector.get<CreditCardService>(CreditCardService);
    this.credittypeservice = injector.get<GetCreditCardTypeService>(GetCreditCardTypeService);
    this.contentService = injector.get<ContentService>(ContentService);
    this.utils = injector.get<UtilsService>(UtilsService);
    this.billPaySvc = injector.get<BillPayService>(BillPayService);
    this._autoPaymentSvc = injector.get<AutoPaymentService>(AutoPaymentService);
    this.router = injector.get<Router>(Router);
  }


  onCardNumberChange(value: string) {
    if(value.length == 0) {
      this.totalDueNewCardForm.reset({creditCardNumber: ''});
      this.totalDueNewCardForm.reset({cvv: ''});
      this.cardBrand = {};
      this.cardCategory = {};
    }
    let formatedVal = this.totalDueNewCardForm.get('creditCardNumber')?.value.replace(/\D/g, '');
    HttpCacheService.SetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER, formatedVal);

    if (this.showCardDetails) {
      formatedVal = formatedVal.match(/.{1,4}/g)?.join(' ');
    }
    else {
      formatedVal = this.totalDueNewCardForm.get('creditCardNumber')?.value.replace(/\D/g, '');
    }
    this.totalDueNewCardForm.get('creditCardNumber')?.setValue(formatedVal);
    this.cvvMaxLength = this.cardType1 === 'AmericanExpress' ? 4 : 3;
    if(this.cardType1 === ""){
      this.cvvMaxLength = this.cvvLength;
    }
    this.totalDueNewCardForm.get('cvv')?.updateValueAndValidity(); // Force re-validation of CVV
    this.luhnCheck(value)
    this.handleCreditCardType(value)
    this.validateCardNumber();
    this.updateCardLengthValidator()
    this.totalDueNewCardForm.get('creditCardNumber')?.updateValueAndValidity(); // Force re-validation of CVV
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
  }
  getCardMaxLength() {
    if (!this.showCardDetails) {
      return this.cardType1 === 'American Express' ? 15 : 16;
    } else {
      return (this.cardType1 === 'American Express' ? 15 : 16) + 3;
    }
  }
  toggleCardDetails() {
    let creditCardNumber = this.totalDueNewCardForm.get('creditCardNumber')?.value.replace(/\D/g, '');

    if (!this.showCardDetails) {
      this.ccMaxLength = Number(this.cardMaxLength) + 3;
      creditCardNumber = creditCardNumber.match(/.{1,4}/g)?.join(' ');

    }
    else {
      this.ccMaxLength = Number(this.cardMaxLength);
      creditCardNumber = this.totalDueNewCardForm.get('creditCardNumber')?.value.replace(/\D/g, '');

    }
    this.totalDueNewCardForm.get('creditCardNumber')?.setValue(creditCardNumber);
    this.updateCardLengthValidator()
    this.showCardDetails = !this.showCardDetails;

  }
  toggleCVVDetails() {
    this.showCVVDetails = !this.showCVVDetails;
    if (this.showCVVDetails) {
    }
  }
  detectCardType(value: string) {
    if (value.startsWith('4')) {
      this.cardType1 = 'Visa';
    } else if (/^5[1-5]/.test(value)) {
      this.cardType1 = 'MasterCard';
    } else if (/^3[47]/.test(value)) {
      this.cardType1 = 'AmericanExpress';
    } else if (/^6(?:011|5)/.test(value)) {
      this.cardType1 = 'Discover';
    } else {
      this.cardType1 = 'Unknown';
    }
  }

  cvvValidator(control: any): { [key: string]: any } | null {
    this.cvvMaxLength = this.cardType1 === 'AmericanExpress' ? 4 : 3;
    if(this.cardType1 === ""){
      this.cvvMaxLength = this.cvvLength;
    }
    this.totalDueNewCardForm.get('cvv')?.updateValueAndValidity(); // Force re-validation of CVV
    this.validateCvv()
    return control.length === this.cvvMaxLength ? null : { invalidCvv: true };
  }

  ngOnInit() {
    if (this.cardBankInfo && this.cardBankInfo.paymentInstrument.bankPayment && this.cardBankInfo.paymentInstrument.bankPayment.bankAccountAlias) {
      this.manageAutopay = true;
      this.bankAccountNo = true;
      this.bankAccountAlias = this.cardBankInfo.paymentInstrument.bankPayment.bankAccountAlias;
    } else if (this.cardBankInfo && this.cardBankInfo.paymentInstrument.paymentCard && this.cardBankInfo.paymentInstrument.paymentCard.paymentCardAlias) {
      this.manageAutopay = true;
      this.cardNo = true;
      this.cardNoAlias = this.cardBankInfo.paymentInstrument.paymentCard.paymentCardAlias;
    }

    this._loaderSvc.stop(CREDIT_CARD_CONSTANTS.MICRO_TEXT);
    this.cvvLength = CREDIT_CARD_CONSTANTS.CVV_LENGTH_CONSTANT;
    HttpCacheService.SetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER, '');
    HttpCacheService.SetCache(COMMON_CONSTANTS.CREDITCARD_CVV_NUMBER, '');
    HttpCacheService.SetCache(CREDIT_CARD_CONSTANTS.CARD_TYPE, '');
    this.isV4CardTypeEnabled = environment.ENABLE_V4CARDTYPE_API;
    this.totalDueNewCard();
    this.cardDetailObject = {};

    if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('contentmain'))) {
      const data = JSON.parse(this._cacheSvc.getFromSessionStorage('contentmain'));
      this.authoringContent = data.par.common_payment;
      ContentService.SetCache(COMMON_CONSTANTS.CONTENT_CACHE_KEY, this.authoringContent);
      this.selectMonthTxt = this.authoringContent.creditCard.selectMonthLbl;
      this.selectYearTxt = this.authoringContent.creditCard.selectYearLbl;
    } else {
      this.contentSubscription = this.contentService.GetContentData()
          .subscribe(data => {
            this.authoringContent = data.par.common_payment;
            ContentService.SetCache(COMMON_CONSTANTS.CONTENT_CACHE_KEY, this.authoringContent);
            this.selectMonthTxt = this.authoringContent.creditCard.selectMonthLbl;
            this.selectYearTxt = this.authoringContent.creditCard.selectYearLbl;
          });
    }


    this.creditCardNameLength = CREDIT_CARD_CONSTANTS.CREDIT_CARD_NAME_LENGTH;
    HttpCacheService.SetCache(CCTYPE_CONSTANTS.CARD_LENGTH, this.cardLength);
    this.buildYearsArray();

    // ban and OrgID from session storage query Params
    this.banNumber = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY);
    this.orgId = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY);
    this.maskedCcNumber = this.maskedChars +
        (ContentService.GetCache(COMMON_CONSTANTS.CC_NUMBER) ?
            this.utils.getLastFourDigits(ContentService.GetCache(COMMON_CONSTANTS.CC_NUMBER)) : '');

    if (!this.creditService.isCcInfoSaved) {
      this.onSuccess.emit({
        response: false,
        cardNumber: this.totalDueNewCardForm.value.creditCardNumber
      });
    } else {
      this.onSuccess.emit({
        response: true,
        cardNumber: this.totalDueNewCardForm.value.creditCardNumber
      });
    }

    if (this.flowType === COMMON_CONSTANTS.BILL_PAY) {
      this.billpayFlag = true;
    }
    if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) === COMMON_CONSTANTS.OKTA_KEY ||
        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) === COMMON_CONSTANTS.AZURE_KEY) {
      this.careAuthType = true;
      this.totalDueNewCardForm.disable();
    }
    if (!isNullOrUndefined(this.paymentDetails) &&
        !isNullOrUndefined(this.paymentDetails.paymentInstrument.paymentCard)) {
      if (!isNullOrUndefined(this.paymentDetails.paymentInstrument.paymentCard.paymentCardAlias)) {
        const last4digit = (this.paymentDetails.paymentInstrument.paymentCard.paymentCardAlias).substring(12);
        this.editObject.displaycc = '**** **** **** ' + last4digit;
        this.editObject.creditCardNumber = this.paymentDetails.paymentInstrument.paymentCard.paymentCardAlias;
        this.totalDueNewCardForm.patchValue({
          creditCardNumber: this.editObject.displaycc
        });
        this.totalDueNewCardForm.patchValue({
          actualcreditCardNumber: this.paymentDetails.paymentInstrument.paymentCard.paymentCardAlias
        });
      }
      if (!isNullOrUndefined(this.paymentDetails.paymentInstrument.paymentCard.expirationMonthYear)) {
        this.editObject.expMonth = ((this.paymentDetails.paymentInstrument.paymentCard.expirationMonthYear).split('/'))[0];
        this.editObject.expYear = ((this.paymentDetails.paymentInstrument.paymentCard.expirationMonthYear).split('/'))[1];
        this.totalDueNewCardForm.patchValue({
          expirationMonth: ((this.paymentDetails.paymentInstrument.paymentCard.expirationMonthYear).split('/'))[0]
        });

      }
      if (!isNullOrUndefined(this.paymentDetails.paymentInstrument.paymentCard.cardHolderName)) {
        this.editObject.nameOncard = this.paymentDetails.paymentInstrument.paymentCard.cardHolderName;
        this.totalDueNewCardForm.patchValue({
          nameOnCreditCard: this.paymentDetails.paymentInstrument.paymentCard.cardHolderName
        });
      }
      if (!isNullOrUndefined(this.paymentDetails.paymentInstrument.paymentCard.cardHolderAddress) &&
          !isNullOrUndefined(this.paymentDetails.paymentInstrument.paymentCard.cardHolderAddress.addressCore) &&
          !isNullOrUndefined(this.paymentDetails.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode)) {
        this.editObject.ccZip = this.paymentDetails.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode;
        this.totalDueNewCardForm.patchValue({
          ccZip: this.paymentDetails.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode
        });
      }
      this.selectedYear = '20' + this.editObject.expYear;
      this.selectedMonth = {};
      this.selectedMonth.value = this.editObject.expMonth;
      this.crditCardNumberSet(this.editObject.creditCardNumber);
      this.handleCreditCardType(this.editObject.creditCardNumber);
      if (this.paymentDetails.paymentInstrument.paymentCard.typeCode === 'AMERICANEXPRESS') {
        this.cvvLength = CREDIT_CARD_CONSTANTS.CVV_AMEXLENGTH_CONSTANT;
        HttpCacheService.SetCache(CREDIT_CARD_CONSTANTS.CARD_TYPE, CardBrands.AMERICANEXPRESS);
      }

      this.totalDueNewCardForm.patchValue({
        expirationYear: this.selectedYear
      });
      this.totalDueNewCardForm.patchValue({
        cardTypeVal: true
      });
      this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);

      this.totalDueNewCardForm.controls['creditCardNumber'].disable();
      this.totalDueNewCardForm.controls['nameOnCreditCard'].disable();
    }
    if (!this.isEdit) {
      this.disabled = 'disabled';
    }

    this._paymentSvc.makePaymentToCC.subscribe((data) => {
      if (data) {
        this.selectedMonth = {
          'name': this.authoringContent.creditCard.selectMonthLbl,
          'value': this.authoringContent.creditCard.selectMonthLbl
        };
        this.selectedYear = this.authoringContent.creditCard.selectYearLbl;
        this.stateSelected = {
          'id': this.authoringContent.creditCard.state,
          'name': this.authoringContent.creditCard.state
        };
        this.totalDueNewCardForm.patchValue({ccState: ''});
        this.totalDueNewCardForm.patchValue({expirationYear: ''});
        this.totalDueNewCardForm.reset({expirationMonth: ''});
        this.totalDueNewCardForm.reset({creditCardNumber: ''});
        this.totalDueNewCardForm.reset();
      }
    });
    this._paymentSvc.processPaymentErrorCounter.subscribe(data => {
      if (data > 0 && data <= 2) {
        this.creditCardNumberRef.nativeElement.focus();
      }
    });
    this.totalDueNewCardForm.markAllAsTouched();
  }


  public buildYearsArray() {
    let currentYear = new Date().getFullYear();
    this.years.push(currentYear);
    for (let i = 1; i < 15; i++) {
      this.years.push(currentYear + 1);
      currentYear++;
    }
  }


  public totalDueNewCard() {
    if (this.isV4CardTypeEnabled === true) {
      this.totalDueNewCardForm = new UntypedFormGroup({
        // tslint:disable-next-line
        nameOnCreditCard: new FormControl('', [Validators.required, Validators.minLength(CREDIT_CARD_CONSTANTS.ONE), Validators.maxLength(CREDIT_CARD_CONSTANTS.CREDIT_CARD_NAME_LENGTH),
          Validators.pattern(COMMON_CONSTANTS.CHARACTER_REGEX), Validators.nullValidator,], this.specialCharacterNormalization),
        expirationMonth: new FormControl('', [Validators.required]),
        expirationYear: new FormControl('', [Validators.required]),
        creditCardNumber: new FormControl('', [Validators.required, Validators.minLength(Number(this.cardMinLength) + 3),
          Validators.maxLength(Number(this.cardMaxLength) + 3),
          Validators.pattern('^[0-9* ]*$')]),
        actualcreditCardNumber: new FormControl(),
        cvv: new FormControl('', [Validators.required, Validators.minLength(this.cvvLength),
          Validators.pattern(COMMON_CONSTANTS.CVV_REGEX)]),
        ccAddress1: new FormControl('', [Validators.required]),
        ccAddress2: new FormControl(''),
        ccCity: new FormControl('', [Validators.required, Validators.pattern(COMMON_CONSTANTS.CHARACTER_REGEX)], this.specialCharacterNormalization),
        ccState: new FormControl(''),
        ccZip: new FormControl('', [Validators.required, Validators.minLength(CREDIT_CARD_CONSTANTS.FIVE)
          , Validators.maxLength(CREDIT_CARD_CONSTANTS.FIVE),
          Validators.pattern(COMMON_CONSTANTS.NUMBER_REGEX)]),
        ccPhone: new FormControl('', this.isInstallmentRadioSelected ? [Validators.required] : null),
        LuhnCheck: new FormControl(''),
        cardTypeVal: new FormControl(''),
        isSameCardTypeSelectedEntered: new FormControl(''),
        paymentMethodCode: new FormControl(''),
        cardTypeCode: new FormControl(''),
      }, this.validateExpirationDate);

    } else {

      this.cardLength = CREDIT_CARD_CONSTANTS.CARD_LENGTH_CONSTANT;
      HttpCacheService.SetCache(CCTYPE_CONSTANTS.CARD_LENGTH, this.cardLength);

      this.totalDueNewCardForm = new UntypedFormGroup({
        // tslint:disable-next-line
        nameOnCreditCard: new FormControl('', [Validators.required, Validators.minLength(CREDIT_CARD_CONSTANTS.ONE), Validators.maxLength(CREDIT_CARD_CONSTANTS.CREDIT_CARD_NAME_LENGTH),
          Validators.pattern(COMMON_CONSTANTS.CHARACTER_REGEX)], this.specialCharacterNormalization),
        expirationMonth: new FormControl('', [Validators.required]),
        expirationYear: new FormControl('', [Validators.required]),
        creditCardNumber: new FormControl('', [Validators.required, Validators.minLength(Number(this.cardLength) + 3),
          Validators.pattern('^[0-9* ]*$')]),
        actualcreditCardNumber: new FormControl(),
        cvv: new FormControl('', [Validators.required, Validators.minLength(this.cvvLength),
          Validators.pattern(COMMON_CONSTANTS.CVV_REGEX)]),
        ccAddress1: new FormControl('', [Validators.required]),
        ccAddress2: new FormControl(''),
        ccCity: new FormControl('', [Validators.required, Validators.pattern(COMMON_CONSTANTS.CHARACTER_REGEX)], this.specialCharacterNormalization),
        ccState: new FormControl(''),
        ccZip: new FormControl('', [Validators.required, Validators.minLength(CREDIT_CARD_CONSTANTS.FIVE)
          , Validators.maxLength(CREDIT_CARD_CONSTANTS.FIVE),
          Validators.pattern(COMMON_CONSTANTS.NUMBER_REGEX)]),
        ccPhone: new FormControl('', [Validators.required, Validators.minLength(14)]),
        LuhnCheck: new FormControl(''),
        cardTypeVal: new FormControl(''),
        paymentMethodCode: new FormControl(''),
        cardTypeCode: new FormControl(''),
      }, this.validateExpirationDate);
    }
  }

  validateExpirationDate(form: UntypedFormGroup) {

    if (form.controls[CREDIT_CARD_CONSTANTS.EXPIRATION_MONTH].value && form.controls[CREDIT_CARD_CONSTANTS.EXPIRATION_YEAR].value) {
      const year = form.controls[CREDIT_CARD_CONSTANTS.EXPIRATION_YEAR];
      const month = form.controls[CREDIT_CARD_CONSTANTS.EXPIRATION_MONTH];
      const date = new Date();
      const newD = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
      // tslint:disable-next-line:radix
      const sDate = new Date(`${year.value}-${parseInt(month.value)}-1`);
      if (sDate.getTime() < newD.getTime()) {
        return {
          'validateExpirationDate': true
        };
      }
    }
    return null;
  }

  specialCharacterNormalization(formData: FormControl) {
    if ((formData.value as string).includes('ñ')) {
      formData.patchValue((formData.value as string).replace(new RegExp("ñ", "g"), "n"));
    }
    if ((formData.value as string).includes('Ñ')) {
      formData.patchValue((formData.value as string).replace(new RegExp("Ñ", "g"), "N"));
    }
    return of(null);
  }

  changeMonth(val) {
    if (!val.name) {
      val = {
        name: this.months[val - 0].name,
        value: val
      }
    }
    this.selectedMonth = val;
    this.totalDueNewCardForm.controls['expirationMonth'].setValue(val.value);
    this.totalDueNewCardForm.patchValue({
      expirationMonth: val.value
    });
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
  }
  changeYear(val: any) {
    this.selectedYear = val;
    this.totalDueNewCardForm.patchValue({
      expirationYear: val
    });
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
  }

  public getHostName() {
    const url = window.location.href;
    const arr = url.split('/');
    return arr[0] + '//' + arr[2]; // host
  }


  handleCreditCardType(rawFormValue) {
    const creditfirstnum = this.getCardHTTPCacheValue().replace(/ /g, '');
    this.isCCNumberInvalid = false;

    if (creditfirstnum.length < CREDIT_CARD_CONSTANTS.CREDIT_CARD_LENGTH_BRAND_CHECKING) {
      this.cardBrand = '';
      this.isCCNumberInvalid = false;
      this.cc = false;
      this.dc = false;
    }

    if (creditfirstnum && creditfirstnum.length === CREDIT_CARD_CONSTANTS.CREDIT_CARD_LENGTH_BRAND_CHECKING) {
      this.creditCardNameLength = CREDIT_CARD_CONSTANTS.CREDIT_CARD_NAME_LENGTH;
      this.getCardType(creditfirstnum);
    } else if (creditfirstnum.length >= CREDIT_CARD_CONSTANTS.CREDIT_CARD_LENGTH_BRAND_CHECKING && (typeof this.cardBrand || typeof this.cardCategory) != 'string') {
      this.getCardType(creditfirstnum.substring(0, 6));
    }

    this.isCardValid(rawFormValue);
    if (creditfirstnum &&
        (creditfirstnum.length === CREDIT_CARD_CONSTANTS.CARD_LENGTH_CONSTANT || (this.cardBrand === this.authoringContent.creditCard.americanCreditTypeText && creditfirstnum.length === CREDIT_CARD_NUMBER_CONSTANTS.AMERICAN_CREDIT_CARD_NUMBER_LENGTH)) &&
        this.paymentCardType == 'paymentCreditCard' && this.creditMigrationDate != '' && this.creditMigrationDate != undefined && this.manageAutopay == false && !this.cc && this.iscreditcardvalid) {
      this.billPaySvc.fetchPublicKey().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
          (data: any) => {
            this.isAchEncryptComplete = false;
            this.key1.kty = data.payment.encryption.jwkKey.kty;
            this.key1.e = data.payment.encryption.jwkKey.e;
            this.key1.n = data.payment.encryption.jwkKey.n;
            const account = this;
            encrypt(
                data.payment.encryption.jwkKey,
                HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER),
                (encryptedData) => {
                  encryptedData = JSON.stringify(encryptedData, undefined, 2);
                  account.encrypedNumber = encryptedData.substring(
                      1,
                      encryptedData.length - 1
                  );
                  if (
                      !isNullOrUndefined(this.encrypedNumber) &&
                      !this.isAchEncryptComplete
                  ) {
                    this.createAutopayDiscount();
                  }
                }
            )
          })
    } else if (
        creditfirstnum &&
        (creditfirstnum.length === CREDIT_CARD_CONSTANTS.CARD_LENGTH_CONSTANT || (this.cardBrand === this.authoringContent.creditCard.americanCreditTypeText && creditfirstnum.length === CREDIT_CARD_NUMBER_CONSTANTS.AMERICAN_CREDIT_CARD_NUMBER_LENGTH)) &&
        (this.paymentCardType == "paymentCreditCard" ||
            (this.paymentCardType == "paymentCreditDebitCard" &&
                this.mayReleaseFlag &&
                this.cardCategory === "CREDIT")) &&
        this.creditMigrationDate != "" &&
        this.creditMigrationDate != undefined &&
        this.isLuhnChecked == false &&
        this.manageAutopay == true &&
        this.cardNo == true &&
        !this.cc &&
        this.iscreditcardvalid
    ) {
      this.billPaySvc.fetchPublicKey().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
          (data: any) => {
            this.isAchEncryptComplete = false;
            this.key1.kty = data.payment.encryption.jwkKey.kty;
            this.key1.e = data.payment.encryption.jwkKey.e;
            this.key1.n = data.payment.encryption.jwkKey.n;
            const account = this;
            encrypt(
                data.payment.encryption.jwkKey,
                HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER),
                (encryptedData) => {
                  encryptedData = JSON.stringify(encryptedData, undefined, 2);
                  account.encrypedNumber = encryptedData.substring(
                      1,
                      encryptedData.length - 1
                  );
                  if (
                      !isNullOrUndefined(this.encrypedNumber) &&
                      !this.isAchEncryptComplete
                  ) {
                    this.updateAutopayDiscountWithCard();
                  }
                }
            )
          })
    } else if (creditfirstnum &&
        (creditfirstnum.length === CREDIT_CARD_CONSTANTS.CARD_LENGTH_CONSTANT || (this.cardBrand === this.authoringContent.creditCard.americanCreditTypeText && creditfirstnum.length === CREDIT_CARD_NUMBER_CONSTANTS.AMERICAN_CREDIT_CARD_NUMBER_LENGTH)) &&
        (this.paymentCardType == 'paymentCreditCard' || (this.paymentCardType == "paymentCreditDebitCard" && this.mayReleaseFlag && this.cardCategory === "CREDIT")) && this.creditMigrationDate != '' && this.creditMigrationDate != undefined && this.isLuhnChecked == false && this.manageAutopay == true && !this.cc && this.iscreditcardvalid) {
      this.billPaySvc.fetchPublicKey().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
          (data: any) => {
            this.isAchEncryptComplete = false;
            this.key1.kty = data.payment.encryption.jwkKey.kty;
            this.key1.e = data.payment.encryption.jwkKey.e;
            this.key1.n = data.payment.encryption.jwkKey.n;
            const account = this;
            encrypt(
                data.payment.encryption.jwkKey,
                HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER),
                (encryptedData) => {
                  encryptedData = JSON.stringify(encryptedData, undefined, 2);
                  account.encrypedNumber = encryptedData.substring(
                      1,
                      encryptedData.length - 1
                  );
                  if (
                      !isNullOrUndefined(this.encrypedNumber) &&
                      !this.isAchEncryptComplete
                  ) {
                    this.updateAutopayDiscountWithBank();
                  }
                }
            )
          })
    }

  }

  createAutopayDiscount() {
    this.isAchEncryptComplete = true;
    this.discountObj = {};
    this.discountObj.salesChannelId = "WEB";
    this.discountObj.programCode = "U1POSTLEGACY";
    this.discountObj.businessUnit = "TMOBILE";
    this.discountObj.businessSegment = "POSTPAID";
    this.discountObj.productGroup = "SOFTGOODS";
    this.discountObj.orderTypes = "MANAGEPAYMENT";
    this.discountObj.billerCode = "SAMSON";
    this.discountObj.accountInfo = {};
    this.discountObj.accountInfo.billingAccount = this.banNumber;
    this.discountObj.autoPayInfo = {};
    this.discountObj.autoPayInfo.autoPayFlow = "CREATE";
    this.discountObj.autoPayInfo.skipPaymentMethodEligibility = false;
    this.discountObj.autoPayInfo.skipSamsonCallForMigrationDate = true;
    this.discountObj.autoPayInfo.autoPayDiscountMigrationDate = this.creditMigrationDate;
    this.discountObj.autoPayInfo.currentAutoPayInfo = null;
    this.discountObj.autoPayInfo.newAutoPayInfo = {};
    this.discountObj.autoPayInfo.newAutoPayInfo.paymentCard = {};
    this.discountObj.autoPayInfo.newAutoPayInfo.paymentCard.cardNumber = this.encrypedNumber;
    this.discountObj.autoPayInfo.newAutoPayInfo.bankPayment = null;

    this._autoPaymentSvc
        .autopayDiscountEligibility(this.discountObj)
        .pipe(this.pipedOperatorsService.subReattempt())
        .subscribe(
            autoPayData => {
              const currDateTime = new Date();
              let now_utc = Date.UTC(
                  currDateTime.getUTCFullYear(),
                  currDateTime.getUTCMonth(),
                  currDateTime.getUTCDate(),
                  currDateTime.getUTCHours(),
                  currDateTime.getUTCMinutes(),
                  currDateTime.getUTCSeconds()
              );
              if (autoPayData && autoPayData.autoPayDiscountMigrationDate && new Date(now_utc) >= new Date(autoPayData.autoPayDiscountMigrationDate.toLocaleString()) && autoPayData.paymentMethodDiscountEligibilityIndicator && autoPayData.paymentMethodDiscountEligibilityIndicator == "N") {
                this._loaderSvc.stop(CREDIT_CARD_CONSTANTS.MICRO_TEXT);
                this.eligibleForDiscount = false;
                this.onEnrollCredit.emit({
                  eligibleForDiscount: this.paymentCardType == 'paymentCreditCard' ? this.eligibleForDiscount : true
                });
              }
            }, err => {
              this.documentAutopayDiscountFailure(err, "create")
            });
  }

  updateAutopayDiscountWithBank() {
    this.isAchEncryptComplete = true;
    this.discountObj = {};
    this.discountObj.salesChannelId = "WEB";
    this.discountObj.programCode = "U1POSTLEGACY";
    this.discountObj.businessUnit = "TMOBILE";
    this.discountObj.businessSegment = "POSTPAID";
    this.discountObj.productGroup = "SOFTGOODS";
    this.discountObj.orderTypes = "MANAGEPAYMENT";
    this.discountObj.billerCode = "SAMSON";
    this.discountObj.accountInfo = {};
    this.discountObj.accountInfo.billingAccount = this.banNumber;
    this.discountObj.autoPayInfo = {};
    this.discountObj.autoPayInfo.autoPayFlow = "UPDATE";
    this.discountObj.autoPayInfo.skipPaymentMethodEligibility = false;
    this.discountObj.autoPayInfo.skipSamsonCallForMigrationDate = true;
    this.discountObj.autoPayInfo.autoPayDiscountMigrationDate = this.creditMigrationDate;
    this.discountObj.autoPayInfo.currentAutoPayInfo = {};
    this.discountObj.autoPayInfo.currentAutoPayInfo.bankAccountAlias = this.bankAccountAlias;
    this.discountObj.autoPayInfo.newAutoPayInfo = {};
    this.discountObj.autoPayInfo.newAutoPayInfo.paymentCard = {};
    this.discountObj.autoPayInfo.newAutoPayInfo.paymentCard.cardNumber = this.encrypedNumber;
    this.discountObj.autoPayInfo.newAutoPayInfo.bankPayment = null;

    this._autoPaymentSvc
        .autopayDiscountEligibility(this.discountObj)
        .pipe(this.pipedOperatorsService.subReattempt())
        .subscribe(
            autoPayData => {
              const currDateTime = new Date();
              let now_utc = Date.UTC(
                  currDateTime.getUTCFullYear(),
                  currDateTime.getUTCMonth(),
                  currDateTime.getUTCDate(),
                  currDateTime.getUTCHours(),
                  currDateTime.getUTCMinutes(),
                  currDateTime.getUTCSeconds()
              );
              if (autoPayData && autoPayData.autoPayDiscountMigrationDate && new Date(now_utc) >= new Date(autoPayData.autoPayDiscountMigrationDate.toLocaleString()) && autoPayData.paymentMethodDiscountEligibilityIndicator && autoPayData.paymentMethodDiscountEligibilityIndicator == "N") {
                this._loaderSvc.stop(CREDIT_CARD_CONSTANTS.MICRO_TEXT);
                this.eligibleForDiscount = false;
                this.onEnrollCredit.emit({
                  eligibleForDiscount: (this.paymentCardType == 'paymentCreditCard' || (this.paymentCardType === 'paymentCreditDebitCard' && this.mayReleaseFlag)) ? this.eligibleForDiscount : true
                });

              }
            }, err => {
              this.documentAutopayDiscountFailure(err, "updateWithBank")
            });
  }

  updateAutopayDiscountWithCard() {
    this.isAchEncryptComplete = true;
    this.discountObj = {};
    this.discountObj.salesChannelId = "WEB";
    this.discountObj.programCode = "U1POSTLEGACY";
    this.discountObj.businessUnit = "TMOBILE";
    this.discountObj.businessSegment = "POSTPAID";
    this.discountObj.productGroup = "SOFTGOODS";
    this.discountObj.orderTypes = "MANAGEPAYMENT";
    this.discountObj.billerCode = "SAMSON";
    this.discountObj.accountInfo = {};
    this.discountObj.accountInfo.billingAccount = this.banNumber;
    this.discountObj.autoPayInfo = {};
    this.discountObj.autoPayInfo.autoPayFlow = "UPDATE";
    this.discountObj.autoPayInfo.skipPaymentMethodEligibility = false;
    this.discountObj.autoPayInfo.skipSamsonCallForMigrationDate = true;
    this.discountObj.autoPayInfo.autoPayDiscountMigrationDate = this.creditMigrationDate;
    this.discountObj.autoPayInfo.currentAutoPayInfo = {};
    this.discountObj.autoPayInfo.currentAutoPayInfo.paymentCardAlias = this.cardNoAlias;
    this.discountObj.autoPayInfo.newAutoPayInfo = {};
    this.discountObj.autoPayInfo.newAutoPayInfo.paymentCard = {};
    this.discountObj.autoPayInfo.newAutoPayInfo.paymentCard.cardNumber = this.encrypedNumber;
    this.discountObj.autoPayInfo.newAutoPayInfo.bankPayment = null;

    this._autoPaymentSvc
        .autopayDiscountEligibility(this.discountObj)
        .pipe(this.pipedOperatorsService.subReattempt())
        .subscribe(
            autoPayData => {
              const currDateTime = new Date();
              let now_utc = Date.UTC(
                  currDateTime.getUTCFullYear(),
                  currDateTime.getUTCMonth(),
                  currDateTime.getUTCDate(),
                  currDateTime.getUTCHours(),
                  currDateTime.getUTCMinutes(),
                  currDateTime.getUTCSeconds()
              );
              if (autoPayData && autoPayData.autoPayDiscountMigrationDate && new Date(now_utc) >= new Date(autoPayData.autoPayDiscountMigrationDate.toLocaleString()) && autoPayData.paymentMethodDiscountEligibilityIndicator && autoPayData.paymentMethodDiscountEligibilityIndicator == "N") {
                this._loaderSvc.stop(CREDIT_CARD_CONSTANTS.MICRO_TEXT);
                this.eligibleForDiscount = false;
                this.onEnrollCredit.emit({
                  eligibleForDiscount: (this.paymentCardType == 'paymentCreditCard' || (this.paymentCardType === 'paymentCreditDebitCard' && this.mayReleaseFlag)) ? this.eligibleForDiscount : true
                });
              }
            }, err => {
              this.documentAutopayDiscountFailure(err, "updateWithCard")
            });
  }

  documentAutopayDiscountFailure(error?: any, type?: any) {
    this.errorMessage = ContentService.GetCache(
        COMMON_CONSTANTS.CONTENT_CACHE_KEY
    );
    if (type == 'create') {
      this.errorAlert = {
        message: {
          errorKey: error.status,
          errorMessage: this.errorMessage.error.retryCardBankErrorTxt1,
          errorType: CREDIT_CARD_CONSTANTS.ERR_SERVER
        },
        show: true
      };
      this.router.navigate([MakePaymentConstant.processError]);
    } else if (type == 'updateWithBank') {
      this.errorAlert = {
        message: {
          errorKey: error.status,
          errorMessage: this.errorMessage.error.retryCardBankErrorTxt1,
          errorType: CREDIT_CARD_CONSTANTS.ERR_SERVER
        },
        show: true
      };
      this.router.navigate([MakePaymentConstant.processError]);
    } else if (type == 'updateWithCard') {
      this.errorAlert = {
        message: {
          errorKey: error.status,
          errorMessage: this.errorMessage.error.retryCardBankErrorTxt1,
          errorType: CREDIT_CARD_CONSTANTS.ERR_SERVER
        },
        show: true
      };
      this.router.navigate([MakePaymentConstant.processError]);
    }
  }

  public getStateName(stateId) {
    let stateName = '';
    stateJSON.forEach(function (item) {
      const id = item.id;
      const name = item.name;
      if (stateId === id) {
        stateName = name;
      }
    });
    return stateName;
  }

  getFormattedName(name: string, type: string) {
    const nameArr = name.toString().split(' ');
    if (type === CREDIT_CARD_CONSTANTS.FIRST) {
      if (nameArr.length > CREDIT_CARD_CONSTANTS.TWO) {
        return (nameArr[0] + ' ' + nameArr[1]);
      } else {
        return nameArr[0];
      }
    } else {
      if (nameArr.length > CREDIT_CARD_CONSTANTS.ONE) {
        return nameArr[nameArr.length - 1];
      } else {
        return '';
      }
    }
  }

  changeState(state: any) {
    this.stateSelected = state;
    this.totalDueNewCardForm.controls['ccState'].setValue(state.id);
    this.totalDueNewCardForm.patchValue({ccState: state.id});
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
  }

  getCardCategory() {
    const creditfirstnum = this.getCardHTTPCacheValue().replace(/ /g, '');

    if (creditfirstnum && creditfirstnum.length === CREDIT_CARD_CONSTANTS.CREDIT_CARD_LENGTH_BRAND_CHECKING) {
      this.creditCardNameLength = CREDIT_CARD_CONSTANTS.CREDIT_CARD_NAME_LENGTH;
      this.credittypeservice.getCardDetails(creditfirstnum).subscribe(cardType => {
        if (creditfirstnum === this.getCardHTTPCacheValue().substr(0, 6)) {
          this.cardCategory = _.get(cardType, CCTYPE_CONSTANTS.CARD_CATEGORY);
        }
        if (this.paymentCardType == 'paymentDebitCard' && this.cardCategory && this.cardCategory != 'DEBIT') {
          this.dc = true;
        } else {
          this.dc = false;
        }

        if (this.paymentCardType == 'paymentCreditCard' && this.cardCategory && this.cardCategory != 'CREDIT') {

          this.cc = true;
        } else {
          this.cc = false;
        }
      })
    }
  }

  updateCardLengthValidator() {
    if (this.cardMaxLength === '19') {
      this.totalDueNewCardForm.controls[COMMON_CONSTANTS.CREDITCARD_NUMBER].setValidators([Validators.required,
        Validators.minLength(Number(this.cardMinLength) + 4),
        Validators.maxLength(Number(this.cardMaxLength) + 4), Validators.pattern('^[0-9* ]*$')]);

    } else if(/\s/g.test(this.totalDueNewCardForm.controls['creditCardNumber'].value)){
      this.totalDueNewCardForm.controls[COMMON_CONSTANTS.CREDITCARD_NUMBER].setValidators([Validators.required,
        Validators.minLength(Number(this.cardMinLength) + 3),
        Validators.maxLength(Number(this.cardMaxLength) + 3), Validators.pattern('^[0-9* ]*$')]);
    }
    else if(!(/\s/g.test(this.totalDueNewCardForm.controls['creditCardNumber'].value))){
      this.totalDueNewCardForm.controls[COMMON_CONSTANTS.CREDITCARD_NUMBER].setValidators([Validators.required,
        Validators.minLength(Number(this.cardMinLength)),
        Validators.maxLength(Number(this.cardMaxLength)), Validators.pattern('^[0-9* ]*$')]);
    }
    else {
      this.totalDueNewCardForm.controls[COMMON_CONSTANTS.CREDITCARD_NUMBER].setValidators([Validators.required,
        Validators.minLength(Number(this.cardMinLength) + 3),
        Validators.maxLength(Number(this.cardMaxLength) + 3), Validators.pattern('^[0-9* ]*$')]);
    }

    this.totalDueNewCardForm.controls[COMMON_CONSTANTS.CREDITCARD_NUMBER].updateValueAndValidity();
  }

  getCardType(cardNumber: string) {
    if (this.isV4CardTypeEnabled) {
      this.cardTypeSubscription = this.credittypeservice.getCardDetails(cardNumber).subscribe(cardType => {
        if (cardNumber === this.getCardHTTPCacheValue().substr(0, 6)) {
          this.cardBrand = _.get(cardType, CCTYPE_CONSTANTS.CARD_TYPE);
          this.cardCategory = _.get(cardType, CCTYPE_CONSTANTS.CARD_CATEGORY);
        }
        if (this.paymentCardType == 'paymentDebitCard' && this.cardCategory && this.cardCategory != 'DEBIT') {
          this.dc = true;
        } else {
          this.dc = false;
        }

        if (this.paymentCardType == 'paymentCreditCard' && this.cardCategory && this.cardCategory != 'CREDIT') {

          this.cc = true;
        } else {
          this.cc = false;
        }
        if (this.cardBrand) {

          this.totalDueNewCardForm.patchValue({
            cardTypeVal: true
          });
          this.totalDueNewCardForm.patchValue({
            paymentMethodCode: cardType.paymentMethodCode
          });
          this.totalDueNewCardForm.patchValue({
            cardTypeCode: cardType.cardType
          });
          this.totalDueNewCardForm.patchValue({
            isSameCardTypeSelectedEntered: ((this.paymentCardType == 'paymentCreditCard' && this.cardCategory && this.cardCategory === 'CREDIT') ||
                (this.paymentCardType == 'paymentDebitCard' && this.cardCategory && this.cardCategory === 'DEBIT'))
          });
          this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
          for (let i = 0; i < 4; i++) {

            const iconBrand = this.creditService.cardTypeIcons[i].brand;
            if (iconBrand === this.cardBrand) {

              HttpCacheService.SetCache(CREDIT_CARD_CONSTANTS.CARD_TYPE, this.cardBrand);
              this.isCCNumberInvalid = false;
              this.cardDetailObject.cardType = this.cardBrand;
              this.cvvLength = _.get(cardType, CCTYPE_CONSTANTS.SECURITY_CODE_LENGTH);
              this.cardMaxLength = cardType.cardLength.maxLength;
              this.cardMinLength = cardType.cardLength.minLength;
              this.paymentMethodCode = cardType.paymentMethodCode;
              HttpCacheService.SetCache(CREDIT_CARD_CONSTANTS.PAYMETHOD_CODE, this.paymentMethodCode);
              this.updateCardLengthValidator();
              this.totalDueNewCardForm.controls[CREDIT_CARD_CONSTANTS.CVV]
                  .setValidators([Validators.required, Validators.minLength(this.cvvLength),
                    Validators.pattern(COMMON_CONSTANTS.CVV_REGEX)]);
              this.totalDueNewCardForm.controls[CREDIT_CARD_CONSTANTS.CVV].updateValueAndValidity();
              HttpCacheService.SetCache(CCTYPE_CONSTANTS.CARD_LENGTH, this.cardMaxLength);
              this.settingNameonCardLength(this.cardBrand);
              this.settingCvvNumberLength(this.cvvLength);
              this.isCCNumberInvalid = false;
              break;
            } else {
              this.isCCNumberInvalid = true;
            }

          }
        } else {
          // removed once content service resume
          // this.cardWaringMessage = CREDIT_CARD_CONSTANTS.INVALID_CARD;
          this.isCCNumberInvalid = true;

        }

      });
    } else {
      this.cardLength = CREDIT_CARD_CONSTANTS.CARD_LENGTH_CONSTANT;
      HttpCacheService.SetCache(CCTYPE_CONSTANTS.CARD_LENGTH, this.cardLength);


      this.cardTypeSubscription = this.credittypeservice.getCardDetails(cardNumber).subscribe(cardType => {
        if (cardNumber === this.getCardHTTPCacheValue().substr(0, 6)) {
          this.cardBrand = _.get(cardType, CCTYPE_CONSTANTS.CARD_TYPE);
          this.cardCategory = _.get(cardType, CCTYPE_CONSTANTS.CARD_CATEGORY);
        }

        if (this.paymentCardType == 'paymentDebitCard' && this.cardCategory && this.cardCategory != 'DEBIT') {
          this.dc = true;
        } else {
          this.dc = false;
        }

        if (this.paymentCardType == 'paymentCreditCard' && this.cardCategory && this.cardCategory != 'CREDIT') {
          this.cc = true;
        } else {
          this.cc = false;
        }

        if (this.cardBrand) {
          this.totalDueNewCardForm.patchValue({
            cardTypeVal: true
          });
          this.totalDueNewCardForm.patchValue({
            paymentMethodCode: cardType.paymentMethodCode
          });
          this.totalDueNewCardForm.patchValue({
            cardTypeCode: cardType.cardType
          });
          this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);

          HttpCacheService.SetCache(CREDIT_CARD_CONSTANTS.CARD_TYPE, this.cardBrand);
          this.isCCNumberInvalid = false;
          this.cardDetailObject.cardType = this.cardBrand;
          this.cvvLength = _.get(cardType, CCTYPE_CONSTANTS.SECURITY_CODE_LENGTH);
          this.cardMaxLength = cardType.cardLength.maxLength;
          this.cardMinLength = cardType.cardLength.minLength;
          this.paymentMethodCode = cardType.paymentMethodCode;
          HttpCacheService.SetCache(CREDIT_CARD_CONSTANTS.PAYMETHOD_CODE, this.paymentMethodCode);
          this.settingCardLength(this.cardBrand);
          this.totalDueNewCardForm.controls[COMMON_CONSTANTS.CREDITCARD_NUMBER].setValidators([Validators.required,
            Validators.minLength(Number(cardType.cardLength.minLength) + 3),
            Validators.maxLength(Number(cardType.cardLength.maxLength) + 3), Validators.pattern('^[0-9* ]*$')]);
          this.totalDueNewCardForm.controls[COMMON_CONSTANTS.CREDITCARD_NUMBER].updateValueAndValidity();
          this.totalDueNewCardForm.controls[CREDIT_CARD_CONSTANTS.CVV]
              .setValidators([Validators.required, Validators.minLength(this.cvvLength),
                Validators.pattern(COMMON_CONSTANTS.CVV_REGEX)]);
          this.totalDueNewCardForm.controls[CREDIT_CARD_CONSTANTS.CVV].updateValueAndValidity();
          HttpCacheService.SetCache(CCTYPE_CONSTANTS.CARD_LENGTH, this.cardLength);
          this.settingNameonCardLength(this.cardBrand);
          this.settingCvvNumberLength(this.cvvLength);
        } else {
          // removed once content service resume
          // this.cardWaringMessage = CREDIT_CARD_CONSTANTS.INVALID_CARD;
          this.isCCNumberInvalid = true;
        }

      });
    }

  }

  settingNameonCardLength(cardBrand) {
    switch (cardBrand) {
      case this.authoringContent.creditCard.visaCreditTypeText:
        this.creditCardNameLength = CREDIT_CARD_CONSTANTS.VISA_CREDIT_CARD_LENGTH;
        this.trimCardHolderName(this.totalDueNewCardForm.value.nameOnCreditCard, this.creditCardNameLength);
        break;
      case this.authoringContent.creditCard.americanCreditTypeText:
      case this.authoringContent.creditCard.discoverCreditTypeText:
        this.creditCardNameLength = CREDIT_CARD_CONSTANTS.AMERICAN_CREDIT_CARD_LENGTH;
        this.trimCardHolderName(this.totalDueNewCardForm.value.nameOnCreditCard, this.creditCardNameLength);
        break;
      case this.authoringContent.creditCard.masterCreditTypeText:
      default:
        this.creditCardNameLength = CREDIT_CARD_CONSTANTS.CREDIT_CARD_NAME_LENGTH;
        this.trimCardHolderName(this.totalDueNewCardForm.value.nameOnCreditCard, this.creditCardNameLength);
        break;
    }
  }

  settingCardLength(cardBrand) {
    switch (cardBrand) {
      case this.authoringContent.creditCard.masterCreditTypeText:
        this.cardLength = CREDIT_CARD_NUMBER_CONSTANTS.MASTER_CARD_NUMBER_LENGTH;
        break;
      case this.authoringContent.creditCard.americanCreditTypeText:
        this.cardLength = CREDIT_CARD_NUMBER_CONSTANTS.AMERICAN_CREDIT_CARD_NUMBER_LENGTH;
        break;
      case this.authoringContent.creditCard.discoverCreditTypeText:
        this.cardLength = CREDIT_CARD_NUMBER_CONSTANTS.DISCOVER_CARD_NUMBER_LENGTH;
        break;
      default:
        this.cardLength = CREDIT_CARD_NUMBER_CONSTANTS.VISA_CREDIT_CARD_NUMBER_LENGTH;
        break;
    }
  }

  trimCardHolderName(cardHolderName, lenght): any {
    if (cardHolderName) {
      const name = cardHolderName.substr(0, lenght);
      this.totalDueNewCardForm.patchValue({
        nameOnCreditCard: name
      });
    }
  }

  settingCvvNumberLength(CvvLength) {
    if (this.totalDueNewCardForm.get("cvv").value === "") {
      this.totalDueNewCardForm.patchValue({
        cvv: ''
      });
    }
    HttpCacheService.SetCache(COMMON_CONSTANTS.CREDITCARD_CVV_NUMBER, '');

  }

  validateCardNumber() {
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
  }

  validateCvv() {
    HttpCacheService.SetCache(COMMON_CONSTANTS.CREDITCARD_CVV_NUMBER, this.totalDueNewCardForm.get('cvv').value);
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);

  }

  validateCardName() {
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
  }

  validateAddress() {
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
  }

  validateZip() {
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
  }

  validateCity() {
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
  }

  validateForm() {
    this.totalDueNewCardForm.valid
  }

  // Handle Google Address Selection
  // creditCardNumberSpaced: string: "";
  public handleGoogleAddressChange(place) {
    let googleStreet: string;
    let googleCity: string;
    let googleState: string;
    let googleZip: string;
    let streetSelected: boolean;
    if (place.address_components !== undefined && place.address_components.length > CREDIT_CARD_CONSTANTS.ZERO) {
      for (let googleAddressType of place.address_components) {
        switch (googleAddressType.types[0]) {
          case GoogleAddressTypes.LOCALITY:
            googleCity = googleAddressType.short_name;
            break;
          case GoogleAddressTypes.ADMINSTRATIVE_AREA_LEVEL1:
            googleState = googleAddressType.short_name;
            break;
          case GoogleAddressTypes.POSTEL_CODE:
            googleZip = googleAddressType.short_name;
            break;
          case GoogleAddressTypes.STREET_NUM:
            googleStreet = googleAddressType.short_name;
            streetSelected = true;
            break;
          case GoogleAddressTypes.ROUTE:
            if (streetSelected) {
              googleStreet = googleStreet + ' ' + googleAddressType.short_name;
            } else {
              googleStreet = googleAddressType.short_name;
            }
            break;
        }
      }
      this.totalDueNewCardForm.patchValue({
        ccAddress1: googleStreet,
        ccAddress2: '',
        ccCity: googleCity,
        ccState: googleState,
        ccZip: googleZip
      });
      this.changeState({'name': this.getStateName(googleState), 'id': googleState});
    }
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
  }

  toggleCVVInput() {
    this.cvvInputType = this.cvvInputType === 'text' ? 'password' : 'text';
  }

  crditCardNumberSet(value) {

    this.luhnCheck(value)
    this.handleCreditCardType(value)
    this.validateCardNumber();
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
  }


  getCardHTTPCacheValue() {
    return HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER)
  }

  getCardLength() {
    return HttpCacheService.GetCache(CCTYPE_CONSTANTS.CARD_LENGTH)
  }

  public removeCcInfo() {
    this.onCardInfoRemove.emit(true);
    this.creditService.isCcInfoSaved = false;
    ContentService.ClearCacheKey(COMMON_CONSTANTS.CC_NUMBER);
    this.selectedMonth = undefined;
    this.selectedYear = undefined;
    this.stateSelected = undefined;
    this.cardBrand = '';
    this.totalDueNewCard();
    this.cardDetailObject = {};
    HttpCacheService.SetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER, '');

    this.cvvLength = CREDIT_CARD_CONSTANTS.CVV_LENGTH_CONSTANT;
    this.buildYearsArray();
    this.onSuccess.emit({
      response: false,
      cardNumber: this.totalDueNewCardForm.value.creditCardNumber
    });
  }

  getCardImage() {
    if (!ContentService.GetCache(CREDIT_CARD_CONSTANTS.IMAGE_URL)) {
      for (let index = COMMON_CONSTANTS.ZERO; index < this.creditService.cardTypeIcons.length; index++) {
        if (this.creditService.cardTypeIcons[index].brand === this.cardBrand) {
          ContentService.SetCache(CREDIT_CARD_CONSTANTS.IMAGE_URL, this.creditService.cardTypeIcons[index].url);
          return this.creditService.cardTypeIcons[index].url;
        }
      }
    } else {
      return ContentService.GetCache(CREDIT_CARD_CONSTANTS.IMAGE_URL);
    }
  }

  onEntrust() {

    this.entrustUrl = this.authoringContent.enTrustLogoUrl.replace('[host]', location.protocol + '//' + location.host);

    window.open(this.entrustUrl, '', 'width=537,height=457,left=19%,top=50,sceenX=19%,screenY=50');

  }

  onEntrustPrivacy() {

    window.location.href = this.authoringContent.enTrustPrivacyUrl;

  }

  ngOnDestroy() {
    if (this.contentSubscription) {
      this.contentSubscription.unsubscribe();
    }
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }

    if (this.publicKeySubscription) {
      this.publicKeySubscription.unsubscribe();
    }

    if (this.cnpSalesSubscription) {
      this.cnpSalesSubscription.unsubscribe();
    }

    if (this.cardTypeSubscription) {
      this.cardTypeSubscription.unsubscribe();
    }

    if (this.savePaymentSubscription) {
      this.savePaymentSubscription.unsubscribe();
    }
  }

  settingCardBrandMapping(cardBrand) {
    switch (cardBrand) {
      case CardBrands.VISA:
        return CreditCardConstants.VISA_CARD;
      case CardBrands.MASTERCARD:
        return CreditCardConstants.MASTER_CARD;
      case CardBrands.AMERICANEXPRESS:
        return CreditCardConstants.AMERICAN_EXPRESS_CARD;
      default:
        return CreditCardConstants.DISCOVER_CARD;
    }
  }

  luhnCheck(value) {
    if (this.isEdit) {
      const cardValue = value.replace(/\s+/g, '');
      let creditCardValue: string;
      if (cardValue[0] === '*') {
        if (!this.isEdit) {
          creditCardValue = this.editObject.creditCardNumber;
        } else {
          creditCardValue = HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER);
        }
        this.totalDueNewCardForm.patchValue({
          actualcreditCardNumber: creditCardValue
        });
        this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
      } else {
        creditCardValue = value;
        this.totalDueNewCardForm.patchValue({
          actualcreditCardNumber: cardValue
        });
        this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
      }

      let nCheck = 1;
      if (creditCardValue !== undefined) {
        // append leading zeros to card number
        while (creditCardValue.length < 16) {
          creditCardValue = '0' + creditCardValue;
        }
        if (/[^0-9 \-]+/.test(creditCardValue)) {
          return false;
        }
        let nDigit = 0,
            bEven = false;
        nCheck = 0;
        creditCardValue = '' + creditCardValue;
        creditCardValue = creditCardValue.replace(/\D/g, '');
        for (let n = creditCardValue.length - 1; n >= 0; n--) {
          const cDigit = creditCardValue.charAt(n);
          nDigit = parseInt(cDigit, 10);
          if (bEven) {
            if ((nDigit *= 2) > 9) {
              nDigit -= 9;
            }
          }
          nCheck += nDigit;
          bEven = !bEven;
        }
      }
      this.isLuhnChecked = ((nCheck % 10) === 0);
      //
      if (!(/\s/g.test(this.totalDueNewCardForm.value.creditCardNumber)) && !this.maskCardBool) { //Autofill case
        this.totalDueNewCardForm.patchValue({
          creditCardNumber: this.totalDueNewCardForm.value.creditCardNumber.match(/.{1,4}/g).join(' ')
        });
      }
      const testStr = creditCardValue.substr(-creditCardValue.length, 4);
      if (testStr === '0000') {
        this.isLuhnChecked = false;
      }

    } else {
      this.isLuhnChecked = true;
    }

    this.totalDueNewCardForm.patchValue({
      LuhnCheck: this.isLuhnChecked
    });
    this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);

  }

  isCardValid(cardNo) {
    const cardValue = cardNo.replace(/\s+/g, '');
    let creditCardValue: string;
    if (cardValue[0] === '*') {
      if (!this.isEdit) {
        creditCardValue = this.editObject.creditCardNumber;
      } else {
        creditCardValue = HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER);
      }
    } else {
      creditCardValue = cardNo;
    }

    let nCheck = 1;
    if (creditCardValue !== undefined) {
      // append leading zeros to card number
      while (creditCardValue.length < 16) {
        creditCardValue = '0' + creditCardValue;
      }
      if (/[^0-9 \-]+/.test(creditCardValue)) {
        return false;
      }
      let nDigit = 0,
          bEven = false;
      nCheck = 0;
      creditCardValue = '' + creditCardValue;
      creditCardValue = creditCardValue.replace(/\D/g, '');
      for (let n = creditCardValue.length - 1; n >= 0; n--) {
        const cDigit = creditCardValue.charAt(n);
        nDigit = parseInt(cDigit, 10);
        if (bEven) {
          if ((nDigit *= 2) > 9) {
            nDigit -= 9;
          }
        }
        nCheck += nDigit;
        bEven = !bEven;
      }
    }
    this.iscreditcardvalid = ((nCheck % 10) === 0);
    if (this.iscreditcardvalid) {
      this.totalDueNewCardForm.controls['creditCardNumber'].markAsTouched
    }
    const testStr = creditCardValue.substr(-creditCardValue.length, 4);
    if (testStr === '0000') {
      this.iscreditcardvalid = false;
    }
  }

  public checkPhoneNumber(phoneNumber: string) {
    phoneNumber = phoneNumber.replace(/\D/g, '');
    if (phoneNumber.length == 10) {
      this.accountDetailsRequestData = new accountDetailsRequestData();
      this.accountDetailsRequestData.billingAccount = this.banNumber.toString();
      this.accountDetailsRequestData.msisdn = phoneNumber;
      this._autoPaymentSvc.getAccountDetails(this.accountDetailsRequestData)
        .pipe(this.pipedOperatorsService.subReattempt())
          .subscribe({
            next: () => {
              // 200 response, which means phoneNumber is associated to ban
              this._cacheSvc.putInSessionStoarage('phoneNumber', phoneNumber);
              this.phoneNumberError.status = false;
              this.phoneNumberError.message = '';
              this.phoneNumberSuccess.status = true;
              this.phoneNumberSuccess.message = 'Account associated number';
            },
            error: () => {
              // 400 code if phone number is not part of ban
              this.phoneNumberError.status = true;
              this.phoneNumberError.message = 'Number not associated with account';
              this._cacheSvc.deleteFromSessionStoarage('phoneNumber');
              this.phoneNumberSuccess.status = false;
              this.phoneNumberSuccess.message = '';
            },
            complete: () => {
              this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
            }
          });
    } else {
      this._cacheSvc.deleteFromSessionStoarage('phoneNumber');
      this._resetPhoneNumberStatus(phoneNumber.length);
      this._paymentSvc.cardDataTopayment(this.totalDueNewCardForm);
    }
  }

  private _resetPhoneNumberStatus(phoneNumberLength: number) {
    this.phoneNumberError.message = '';
    this.phoneNumberSuccess.status = false;
    this.phoneNumberSuccess.message = '';
    this.phoneNumberError.status = phoneNumberLength == 0 ? false : true;
  }
}
