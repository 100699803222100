<div *ngIf="enableEip && (authoringContent || showPartnerError)">
  <div class="header-eip">
    <app-header></app-header>
  </div>

  <section>
    <div>
      <div *ngIf="show" class="error-message">
        <div class="text-error-msg tab-margin mrg-vr-10">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </section>

  <div class="row" *ngIf="authoringContent">
    <div
      [ngClass]="{ 'visible-low mouse-disabled': errorType == 'generic' }"
      class="container"
    >
      <div class="Make-a-payment col-sm-12 page-title" tabindex="0" #title>
        <h1>{{ authoringContent?.landingInfo?.paymentHeadingTxt }}</h1>
        <div class="sub-heading">
          {{ titleLabel }}
        </div>
      </div>

      <div class="col-sm-12 mt-4 eip-device" tabindex="1">
        <section class="msisdn">
          {{ deviceDetails?.mobileNumber | phone: "US" }}
        </section>
        <div class="device-row row">
          <div class="col-4">
            <div class="image-holder">
              <img src="/assets/tfb-payment/images/phone.png" alt="" />
            </div>
            <div class="text-holder">
              <div class="details">
                {{ deviceDetails?.purchasedEquipment?.description }}
              </div>
              <div class="details plan">
                {{ authoringContent?.eipAdditionalPayment?.plan
                }}{{ deviceDetails?.agreementNumber }}
              </div>
              <div class="details">
                {{ authoringContent?.eipAdditionalPayment?.imei
                }}{{ deviceDetails?.purchasedEquipment?.imei }}
              </div>
              <div class="details-sub mt-2">
                {{ authoringContent?.eipAdditionalPayment?.financed }}
                {{ deviceDetails?.effectiveStartDate | date: "MMMM dd, yyyy" }}
              </div>
              <div class="details-sub">
                {{ authoringContent?.eipAdditionalPayment?.originalPrice }}
                {{
                  deviceDetails?.purchasedEquipment?.salePrice
                    | currency: "USD":"symbol"
                }}
              </div>
              <div class="details-sub">
                {{ authoringContent?.eipAdditionalPayment?.downPayment }}
                {{
                  deviceDetails?.totalDownPaymentAmount || "0"
                    | currency: "USD":"symbol"
                }}
              </div>
            </div>
          </div>

          <div class="col-2">
            <div class="payment-left">
              {{ deviceDetails?.remainingNumberOfInstallments }}
            </div>
          </div>

          <div class="col-2">
            <div class="monthly-payment">
              {{
                deviceDetails?.recurringInstallmentAmount
                  | currency: "USD":"symbol"
              }}
            </div>
            <div class="final-payment">
              {{ authoringContent?.eipAdditionalPayment?.finalPayment }}
              <div>
                {{
                  deviceDetails?.lastInstallmentAmount
                    | currency: "USD":"symbol"
                }}
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="progress-text">
              {{
                deviceDetails?.totalAmountFinanced - deviceDetails?.balance
                  | currency: "USD":"symbol"
              }}
              {{ authoringContent?.eipAdditionalPayment?.slash }}
              {{
                deviceDetails?.totalAmountFinanced | currency: "USD":"symbol"
              }}
            </div>
            <div
              class="outer-progress"
              [title]="getPercentage(deviceDetails) + '%'"
            >
              <div
                class="inner-progress"
                [ngStyle]="{ width: getPercentage(deviceDetails) + '%' }"
              ></div>
            </div>
            <div class="pay-text">
              {{ deviceDetails?.balance | currency: "USD":"symbol" }}
              {{ authoringContent?.eipAdditionalPayment?.remaining }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="media">
          <span class="icons dollor">
            <!-- <em class="fas fa-dollar-sign" aria-hidden="true"></em> -->
            <img
              src="/assets/tfb-payment/images/icons/fa-usd-icon.svg"
              class="payment-info"
            />
          </span>
          <div class="Make-a-payment media-body" style="width: 206px">
            <h3 tabindex="2">
              {{ authoringContent?.eipAdditionalPayment?.paymentAmount }}
            </h3>
            <div>
              <div>
                <div class="row">
                  <div class="radio radio-primary radioBottomMar col-4 pl-2">
                    <input
                      type="radio"
                      name="radio_default"
                      id="radio_extra"
                      [checked]="isAdditionalAmount"
                      (change)="onPaymentAmountChange('additional')"
                    />
                    <label for="radio_extra"
                      >{{
                        authoringContent?.eipAdditionalPayment
                          ?.extraDevicePaymentTxt
                      }}
                    </label>
                  </div>
                  <div class="label_box max-width-160 m-l-33 mb-3 col-3">
                    <div class="question">
                      <span class="dollor-icon" *ngIf="isSymbol">{{
                        authoringContent?.eipAdditionalPayment?.dollar
                      }}</span>
                      <input
                        [disabled]="careAuthType || !isAdditionalAmount"
                        type="text"
                        tabindex="6"
                        #changeAmount
                        appNumbersOnly
                        required
                        maxlength="8"
                        class="pt-0"
                        (keyup.enter)="collapseOther(changeAmount)"
                        (blur)="collapseOther(changeAmount)"
                        [(ngModel)]="additionalAmount"
                      />
                      <label>{{
                        authoringContent?.eipAdditionalPayment?.enterAmountTxt
                      }}</label>
                      <p
                        class="optional_text optional_errortext"
                        *ngIf="amountError"
                      >
                        {{ amountError }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="radio radio-primary radioBottomMar col-4 pl-2">
                    <input
                      type="radio"
                      name="radio_default"
                      id="radio_full"
                      (change)="onPaymentAmountChange('balance')"
                    />
                    <label for="radio_full"
                      >{{
                        authoringContent?.eipAdditionalPayment?.payInFullTxt
                      }}
                    </label>
                  </div>
                  <div class="col-3">
                    {{ balanceAmount | currency: "USD":"symbol" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="media">
          <span class="icons calendar-check">
            <img
              src="/assets/tfb-payment/images/icons/icon-calendar1.png"
              class="payment-info"
              alt="Payment Date"
            />
          </span>
          <div class="Make-a-payment media-body">
            <h3 tabindex="11" class="paymentDateHeading">
              {{ authoringContent?.eipAdditionalPayment?.paymentDate }}
            </h3>
            <div>
              <div>
                <div>
                  <p>
                    <span class="lable-color"
                      >{{ authoringContent?.eipAdditionalPayment?.today }} ({{
                        paymentDate | date: "longDate"
                      }})</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="media payInfoMargin">
          <span class="icons credit-card">
            <img
              src="/assets/tfb-payment/images/icons/payment_info_black.PNG"
              class="payment-info"
              alt=""
            />
          </span>
          <div class="Make-a-payment media-body" style="width: 252px">
            <h3 class="pb-0">
              {{ authoringContent?.eipAdditionalPayment?.paymentInformation }}
            </h3>
            <div *ngIf="showSubHeadertxt" class="mp-error-title">
              <img src="/assets/tfb-payment/images/icon_error.svg" alt="" />
              <h1>{{ authoringContent?.error?.genericErrorApTxt }}</h1>
            </div>

            <div [attr.disabled]="careAuthType == true ? true : null">
              <app-credit-card
                *ngIf="checkIfGoogleLoaded()"
                [isInstallmentRadioSelected]="false"
                [isFuturePayment]="false"
                (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)"
                (onSuccess)="onSuccessCredit($event)"
                [flowType]="'billPay'"
                [banNumber]="banNumber"
                [orgId]="orgID"
                (onCardInfoRemove)="deleteCardInfo($event)"
                [paymentCardType]="'paymentCreditDebitCard'"
              ></app-credit-card>
            </div>
          </div>
        </div>
      </div>

      <div class="media mb-md-0">
        <span class="spacerBlk"></span>
        <div
          class="
            Make-a-payment
            media-body
            col-md-7 col-sm-10 col-xs-9
            pl-0
            legal
          "
        >
          {{ authoringContent?.termsAndCondition?.eipTermsSubmitTxt }}
          <a
            class="legal_link"
            target="_blank"
            href="https://www.t-mobile.com/responsibility/legal/terms-and-conditions"
          >
            {{ authoringContent?.termsAndCondition?.termAndConditionTxt }}</a
          >. <br />
          {{ authoringContent?.termsAndCondition?.eipTermsPayBalanceTxt }}
          <br />
          {{ authoringContent?.termsAndCondition?.eipTermsDueTxt }}
        </div>
      </div>

      <app-recaptcha-v2 *ngIf="!disableRecaptcha()" (onPass)="setToken($event)">
      </app-recaptcha-v2>

      <div class="">
        <div class="button-row row mrg-bm-20">
          <div
            class="
              col-lg-6
              offset-lg-6
              col-md-12
              btn-wrapper
              text-md-center text-lg-right
              btn-wrapperMargin
              pr-4
            "
          >
            <button
              tabindex="61"
              class="black_btn mrg-rt-10"
              (click)="onCancel()"
            >
              {{ authoringContent?.landingInfo?.cancelOrderButtonText }}
            </button>
            <button
              #submitButton
              tabindex="62"
              class="primary_btn"
              data-action="submit"
              [disabled]="!recaptchaToken"
              (click)="onSubmit()"
            >
              {{ authoringContent?.landingInfo?.submitOrderButtonText }}
            </button>
          </div>
        </div>

        <div class="media mb-5">
          <span class="spacerBlk"></span>
          <div
            class="Make-a-payment media-body col-md-8 col-sm-10 col-xs-9 pl-0"
          >
            <a
              class="entrust-logo inline_link d-inline-block ml-0 mr-2"
              (click)="onEntrust()"
              tabindex="0"
            >
              <img
                src="./assets/tfb-payment/images/icons/entrust-site-seal-small.png"
                alt="Secured by Entrust SSL Verify"
              />
            </a>
            <div class="entrust-text d-inline-block align-middle">
              {{ authoringContent?.landingInfo?.enTrustTxt }}
              <a
                class="entrust-textlink"
                href="{{ authoringContent?.landingInfo?.enTrustPrivacyUrl }}"
                tabindex="0"
                target="_blank"
                >{{ authoringContent?.landingInfo?.enTrustTxt1 }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-payment-error-popup
    (showSubHeadertxtChange)="showSubHeadertxt = $event"
  ></app-payment-error-popup>
  <app-footer
    *ngIf="!isRunningInGlobalNav && (authoringContent || showPartnerError)"
  ></app-footer>
</div>
