import { Component, OnInit, Injector } from "@angular/core";
import { EnvironmentDetailsService } from "../../services/environment-details.service";
import { ContentService } from "../../services/content.service";
import { HttpCacheService } from "../../services/http-cache.service";
import {
  IMAGES_URL,
  COMMON_CONSTANTS,
  CREDIT_CARD_CONSTANTS,
  ACH_CONSTANTS,
  MakePaymentConstant,
  PDL_CONSTANTS,
  EipPaymentConstants,
} from "../../common/core/common.constants";
import { DatePipe, CurrencyPipe, NgIf } from "@angular/common";
import { isNullOrUndefined } from "src/app/utilities/helper-utils";
import { PDLService } from "../../services/pdl.service";
import { environment } from "../../../environments/environment";
import { UtilsService } from "../../services/utils.service";
import { ClickEventType } from "src/app/common/core/common.interface";
import { PnpClientService } from "src/pnpframework/public-api";
import { FooterComponent } from "../../footer/footer.component";
import { HeaderComponent } from "../../header/header.component";
@Component({
    selector: "app-thanku-screen",
    templateUrl: "./thanku-screen.component.html",
    styleUrls: ["./thanku-screen.component.scss"],
    standalone: true,
    imports: [
        HeaderComponent,
        NgIf,
        FooterComponent,
    ],
})
export class ThankuScreenComponent implements OnInit {
  _envSvc: EnvironmentDetailsService;
  _cacheSvc: HttpCacheService;
  contentService: ContentService;
  datePipe: DatePipe;
  currencyPipe: CurrencyPipe;
  utils: UtilsService;
  pdlService: PDLService;
  pnpClientService: PnpClientService;
  thankuScreenData: any;
  thankuObject: any;
  paymentRefreshIcon: any;
  refreshIcon: any = IMAGES_URL.REFRESH_ICON;
  content: any;
  contentData: any;
  makePaymentResponse: any;
  updatedBalanceObject: any;
  chargeMsgText: any;
  paymentIdText: any;
  autoPayFlag: boolean;
  returnURI: string;
  paymentMsg: any;
  scheduleFlag: boolean;
  payInInstallment = false;
  paymentInstallmentInfo: any;
  isPartnerFlow: boolean = false;
  isBillingPartnerFlow: boolean = false;
  thankyoumessage = {
    payInInstallmentTxt: "",
    firstInstallment: "",
    secondInstallment: "",
    note: "",
  };
  walkMeIn_userID: any;
  enableBack = true;
  eipDevice: any;
  showDetailTxt = "Show Details";
  showPaymentDetails: boolean = false;
  partnerRedirectUrl: string;

  get isRunningInGlobalNav(): boolean {
    return this.pnpClientService.isRunningInGlobalNav();
  }

  constructor( private injector: Injector ) {
    this._envSvc = injector.get<EnvironmentDetailsService>(EnvironmentDetailsService);
    this._cacheSvc = injector.get<HttpCacheService>(HttpCacheService);
    this.contentService = injector.get<ContentService>(ContentService);
    this.datePipe = injector.get<DatePipe>(DatePipe);
    this.currencyPipe = injector.get<CurrencyPipe>(CurrencyPipe);
    this.utils = injector.get<UtilsService>(UtilsService);
    this.pdlService = injector.get<PDLService>(PDLService);
    this.pnpClientService = injector.get<PnpClientService>(PnpClientService);
  }

  async ngOnInit() { 
    if (
      !isNullOrUndefined(
        this._cacheSvc.getFromSessionStorage("paymentTypeIndicator")
      ) &&
      this._cacheSvc.getFromSessionStorage("paymentTypeIndicator") ===
        "oneTimeLineActivationDeposit"
    ) {
      this.enableBack = false;
    }
    if (
      this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW
      ) === "true"
    ) {
      this.isBillingPartnerFlow = true;
    }
    if (
      this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.IS_PARTNER_BOARD
      ) === "true"
    ) {
      this.isPartnerFlow = true;
    }
    this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.THANKU_PAGE, true);
    this.autoPayFlag = false;
    this.scheduleFlag = false;
    this.thankuObject = {};
    this.updatedBalanceObject = ContentService.GetCache("updatedBalance");
    const paymentResponse = ContentService.GetCache("makePaymentResponse");
    this.makePaymentResponse = paymentResponse.paymentResponse;
    this.payInInstallment = paymentResponse.payInInstallment;
    this.paymentInstallmentInfo = paymentResponse.paymentInstallmentInfo;
    const cardDetailObject = ContentService.GetCache("cardDetailObject");
    this.contentData = ContentService.GetCache("content");

    if (this.contentData) {
      this.thankuScreenData = this.contentData.thankYou;
    }
    this.returnURI = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.RETURN_URL_SSP
    );
    if (paymentResponse.fromWhichPayment === "payment") {
      this.chargeMsgText = this.thankuScreenData.visaCardTxt2;
      this.paymentIdText = this.thankuScreenData.yourPaymentTxt;
      if (this.makePaymentResponse) {
        if (
          !isNullOrUndefined(
            this._cacheSvc.getFromSessionStorage("paymentTypeIndicator")
          ) &&
          this._cacheSvc.getFromSessionStorage("paymentTypeIndicator") ===
            "oneTimeLineActivationDeposit"
        ) {
          this.thankuObject.amount =
            this.makePaymentResponse.payment.authorizedAmount;
        } else {
          this.thankuObject.amount =
            this.makePaymentResponse.payment.chargeAmount;
        }
        this.thankuObject.emailID = this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.EUI_TOKEN_DATA
        )
          ? JSON.parse(
              this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.EUI_TOKEN_DATA
              )
            ).logonId
          : "";

        this.thankuObject.dateObject = new Date();
        this.thankuObject.paymentId =
          this.makePaymentResponse.payment.paymentId;
        this.thankuObject.accountNo = this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.BAN_NUMBER_KEY
        );
      }
      if (this.updatedBalanceObject) {
        const billDuedate = new Date(
          this.updatedBalanceObject.billInfo.dueDate
        );

        this.thankuObject.remainingAmount =
          this.updatedBalanceObject.balanceinfo.arBalance -
          this.thankuObject.amount;

        if (
          this.updatedBalanceObject.statusInfo &&
          this.updatedBalanceObject.statusInfo.autoPayStatus !== "undefined" &&
          (this.updatedBalanceObject.statusInfo[
            MakePaymentConstant.autoPayStatus
          ].toLowerCase() === MakePaymentConstant.optIn ||
            this.updatedBalanceObject.statusInfo[
              MakePaymentConstant.autoPayStatus
            ].toLowerCase() === true) &&
          this.thankuObject.remainingAmount > 0
        ) {
          this.autoPayFlag = true;
        }
        // tslint:disable-next-line:max-line-length
        this.thankuObject.remainingAmountDate = new Date(
          billDuedate.setDate(billDuedate.getDate() - 2)
        );
      }

      if (paymentResponse.paymentInfo === "Bank") {
        this.bankAccountDetail();
        this.paymentMsg = await this.getPaymentMsg(paymentResponse.paymentInfo);
      } else if (paymentResponse.paymentInfo === "Card") {
        this.thankuObject.alternateCardValueTxt =
          CREDIT_CARD_CONSTANTS.ALTERNATE_CARD; // '(Alternate Card) ';
        this.thankuObject.cardOrAccountText =
          cardDetailObject.cardType + " card ";
        // tslint:disable-next-line:max-line-length
        this.thankuObject.cardOrAccountNo = cardDetailObject.cardNumber.substr(
          cardDetailObject.cardNumber.length - 4,
          cardDetailObject.cardNumber.length
        );
        this.paymentMsg = await this.getPaymentMsg(paymentResponse.paymentInfo);        
      }
    }
    else if(paymentResponse.fromWhichPayment === EipPaymentConstants.ORDER_TYPE) {
      this.thankuObject.orderNumber = this._cacheSvc.getFromSessionStorage(EipPaymentConstants.ORDER_NUMBER);
      this.chargeMsgText = this.thankuScreenData.visaCardTxt2;
      this.paymentIdText = this.thankuScreenData.yourPaymentTxt;
      this.eipDevice = JSON.parse(this._cacheSvc.getFromSessionStorage(EipPaymentConstants.EIP_DEVICE));
      const loanId = this.eipDevice ? this.eipDevice?.loanId: '';
      if (this.makePaymentResponse) {
          this.thankuObject.amount =
            this.makePaymentResponse.payment.chargeAmount;
        this.thankuObject.emailID = this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.EUI_TOKEN_DATA
        )
          ? JSON.parse(
              this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.EUI_TOKEN_DATA
              )
            ).logonId
          : "";

        this.thankuObject.dateObject = new Date();
        this.thankuObject.paymentId =
          this.makePaymentResponse.payment.paymentId;
        this.thankuObject.accountNo = this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.BAN_NUMBER_KEY
        );
      }
        this.thankuObject.alternateCardValueTxt =
          CREDIT_CARD_CONSTANTS.ALTERNATE_CARD; // '(Alternate Card) ';
        this.thankuObject.cardOrAccountText =
          cardDetailObject.cardType + " card ";
        // tslint:disable-next-line:max-line-length
        this.thankuObject.cardOrAccountNo = cardDetailObject.cardNumber.substr(
          cardDetailObject.cardNumber.length - 4,
          cardDetailObject.cardNumber.length
        );
        this.paymentMsg = await this.getEipPaymentMsg(loanId);

    } else if (paymentResponse.fromWhichPayment === "schedulePayment") {
      this.scheduleFlag = true;
      this.chargeMsgText = this.thankuScreenData.schPmtText;
      // tslint:disable-next-line:max-line-length
      this.paymentIdText = ContentService.GetCache(
        COMMON_CONSTANTS.CHANGE_REQ_ID
      )
        ? ContentService.GetCache(COMMON_CONSTANTS.CHANGE_REQ_ID)
        : this.thankuScreenData.schPmtIDText;

      if (this.makePaymentResponse) {
        this.thankuObject.amount = paymentResponse.paymentAmount;
        this.thankuObject.emailID = this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.EUI_TOKEN_DATA
        )
          ? JSON.parse(
              this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.EUI_TOKEN_DATA
              )
            ).logonId
          : "";
        this.thankuObject.paymentId =
          this.makePaymentResponse.payment.paymentId;

        this.thankuObject.accountNo = this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.BAN_NUMBER_KEY
        );
      }
      if (this.updatedBalanceObject) {
        const billDuedate = new Date(
          this.updatedBalanceObject.billInfo.dueDate
        );
        this.thankuObject.remainingAmount =
          this.updatedBalanceObject.balanceinfo.arBalance -
          this.thankuObject.amount;

        if (
          this.updatedBalanceObject.statusInfo &&
          this.updatedBalanceObject.statusInfo.autoPayStatus !== "undefined" &&
          (this.updatedBalanceObject.statusInfo[
            MakePaymentConstant.autoPayStatus
          ].toLowerCase() === MakePaymentConstant.optIn ||
            this.updatedBalanceObject.statusInfo[
              MakePaymentConstant.autoPayStatus
            ].toLowerCase() === true) &&
          this.thankuObject.remainingAmount > 0
        ) {
          this.autoPayFlag = true;
        }
        if (!this.payInInstallment) {
          const schDate = paymentResponse.schPmtDueDate;
          const year = schDate.substring(0, 4);
          const month = schDate.substring(4, 6);
          const day = schDate.substring(6, 8);
          const scheduledDate = new Date(year, month - 1, day);
          this.thankuObject.dateObject = this.datePipe.transform(
            scheduledDate,
            "MMMM d, y"
          );
          // tslint:disable-next-line:max-line-length
          this.thankuObject.remainingAmountDate = new Date(
            billDuedate.setDate(billDuedate.getDate() - 2)
          );
        } else {
          const installmentFirst = this.paymentInstallmentInfo[0].amount;
          const installmentFirstDate = this.datePipe.transform(
            this.paymentInstallmentInfo[0].date,
            "MMMM d"
          );
          const installmentSecond = this.paymentInstallmentInfo[1].amount;
          const InstallmentSecondDate = this.datePipe.transform(
            this.paymentInstallmentInfo[1].date,
            "MMMM d"
          );
          this.thankyoumessage.firstInstallment =
            this.currencyPipe.transform(
              installmentFirst,
              MakePaymentConstant.usd
            ) +
            " (" +
            installmentFirstDate +
            ")";
          this.thankyoumessage.secondInstallment =
            this.currencyPipe.transform(
              installmentSecond,
              MakePaymentConstant.usd
            ) +
            " (" +
            InstallmentSecondDate +
            ")";
          if (paymentResponse.paymentInfo === "Bank") {
            const accountNumber =
              this.makePaymentResponse.payment.paymentInstrument.bankPayment
                .bankAccountAlias;
            const last4digit = accountNumber.substr(
              accountNumber.length - 4,
              accountNumber.length
            );
            this.thankyoumessage.payInInstallmentTxt =
              this.thankuScreenData.payInInstallmenttxt + last4digit + ":";
            this.thankyoumessage.note = this.thankuScreenData.note;
          } else if (paymentResponse.paymentInfo === "Card") {
            const accountNumber =
              this.makePaymentResponse.payment.paymentInstrument.paymentCard
                .paymentCardAlias;
            const last4digit = accountNumber.substr(
              accountNumber.length - 4,
              accountNumber.length
            );
            this.thankyoumessage.payInInstallmentTxt =
              this.thankuScreenData.payInInstallmenttxtCC.replace(
                "[CARDTYPE]",
                HttpCacheService.GetCache(CREDIT_CARD_CONSTANTS.CARD_TYPE)
              ) +
              last4digit +
              ":";
            this.thankyoumessage.note = this.thankuScreenData.note;
          }
        }
      }

      if (paymentResponse.paymentInfo === "Bank") {
        this.bankAccountDetail();
        this.paymentMsg = await this.getScheduledPaymentMsg(paymentResponse.paymentInfo);
          
        // tslint:disable-next-line:max-line-length
        this.thankuObject.paymentId = this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.TRANS_REF_NUM
        )
          ? this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TRANS_REF_NUM)
          : "";
      } else if (paymentResponse.paymentInfo === "Card") {
        this.thankuObject.alternateCardValueTxt =
          CREDIT_CARD_CONSTANTS.ALTERNATE_CARD; // '(Alternate Card) ';
        this.thankuObject.cardOrAccountText =
          this.makePaymentResponse.payment.paymentInstrument.paymentCard
            .typeCode + " card ";
        // tslint:disable-next-line:max-line-length
        this.thankuObject.cardOrAccountNo = cardDetailObject.cardNumber.substr(
          this.makePaymentResponse.payment.paymentInstrument.paymentCard
            .paymentCardAlias.length - 4,
          this.makePaymentResponse.payment.paymentInstrument.paymentCard
            .paymentCardAlias.length
        );

        this.paymentMsg = await this.getScheduledPaymentMsg(paymentResponse.paymentInfo);

        // tslint:disable-next-line:max-line-length
        this.thankuObject.paymentId = this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.TRANS_REF_NUM
        )
          ? this._cacheSvc.getFromSessionStorage(
              COMMON_CONSTANTS.TRANS_REF_NUM
            )
          : "";
      }
    }
    
    this.contentData = ContentService.GetCache("content");

    if (this.contentData) {
      this.thankuScreenData = this.contentData.thankYou;
      this.content = this.contentData.footer;
    } else {
      if (
        !isNullOrUndefined(this._cacheSvc.getFromSessionStorage("contentmain"))
      ) {
        const data = JSON.parse(
          this._cacheSvc.getFromSessionStorage("contentmain")
        );
        this.thankuScreenData = data.par.common_payment.thankYou;
        this.content = data.par.common_payment.footer;
        ContentService.SetCache("content", data.par.common_payment);
      } else {
        if (this._envSvc.CURR_CONTENT_URLS) {
          this.getContent();
        } else {
          this._envSvc.getContentEnvt().then((x) => {
            this._envSvc.CURR_CONTENT_URLS = x.CONTENT_PAGES_URL;
            if (this._envSvc.CURR_CONTENT_URLS) {
              this.getContent();
            }
          });
        }
      }
    }
    // Page load tagging & for IAM and partner flow
    const idpType = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE)
    const isPartner = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
    if (environment.ENABLE_TAGGING && (idpType == COMMON_CONSTANTS.IAM_KEY 
    || isPartner == COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE)) {
      if (paymentResponse.fromWhichPayment === MakePaymentConstant.paymentTxt || paymentResponse.fromWhichPayment === MakePaymentConstant.schedulePayment) {
        this.generatePageViewDetailsForThankyouScreen(idpType, isPartner);
      }
    }
    this.walkMeIn_userID = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.EUI_TOKEN_DATA
    )
      ? JSON.parse(
          this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)
        ).logonId
      : MakePaymentConstant.cardSchemailId;
  }

  async getPartnerRedirectUrl(): Promise<string> {
    return await this.utils.getPaymentToBillingRedirectUrl().toPromise().then(data => {
      if (!isNullOrUndefined(data) && !isNullOrUndefined(data.redirectUrl)) {
        return data.redirectUrl;
      }
      return this.returnURI;
    }).catch(() => {
      return this.returnURI;
    })
  }
  async getPaymentMsg(paymentType: String): Promise<string> {
      // tslint:disable-next-line:max-line-length
      if (this.isPartnerFlow) {
        this.partnerRedirectUrl = await this.getPartnerRedirectUrl();
      }
      return this.thankuScreenData.onePaymentTxt.replace(
        "[PAYMENTID]",
        this.thankuObject.paymentId
      ) +
      this.currencyPipe.transform(this.thankuObject.amount, "USD") +
      this.thankuScreenData.onePaymentTxt1.replace(
        "[BAN]",
        this.thankuObject.accountNo
      ) +
      this.thankuScreenData.onePaymentTxt2.replace(
        "[PAYMENTDATE]",
        this.datePipe.transform(this.thankuObject.dateObject, "MMMM d, y")
      ) + (paymentType == "Bank" ?
        // tslint:disable-next-line:max-line-length
        (this.thankuScreenData.onePaymentTxt4.replace(
          "[BANKACNT]",
          this.thankuObject.cardOrAccountNo))
        : (this.thankuScreenData.onePaymentTxt3.replace(
           "[CARDTYPE]",
           HttpCacheService.GetCache(CREDIT_CARD_CONSTANTS.CARD_TYPE)) + this.thankuObject.cardOrAccountNo + ". ")
      ) 
      + (!this.isPartnerFlow ? 
        (this.thankuScreenData.visaCardTxt4 +
          this.thankuObject.emailID +
          this.thankuScreenData.onePaymentTxt5) :
        (this.thankuScreenData.onePaymentTxt6 + 
          this.thankuScreenData.partnerHighSecurity + 
          (this.isBillingPartnerFlow ? this.thankuScreenData.partnerHighSecurityBilling.replace("[REDIRECT_URL]", this.partnerRedirectUrl) : "")))
  }

  async getEipPaymentMsg(loanId: String): Promise<String> {
    if (this.isPartnerFlow) {
      this.partnerRedirectUrl = await this.getPartnerRedirectUrl();
    }
    // tslint:disable-next-line:max-line-length
    return this.thankuScreenData.onePaymentTxt.replace(
      "[PAYMENTID]",
      this.thankuObject.paymentId
    ) +
    this.currencyPipe.transform(this.thankuObject.amount, "USD") +
    this.thankuScreenData.eipSuccessTxt.replace("[LOANID]",loanId) +
    this.thankuScreenData.onePaymentTxt2.replace(
      "[PAYMENTDATE]",
      this.datePipe.transform(this.thankuObject.dateObject, "MMMM d, y")
    ) +
    // tslint:disable-next-line:max-line-length
    this.thankuScreenData.onePaymentTxt3.replace(
      "[CARDTYPE]",
      HttpCacheService.GetCache(CREDIT_CARD_CONSTANTS.CARD_TYPE)
    ) +
    this.thankuObject.cardOrAccountNo + ". "
    + (!this.isPartnerFlow ? 
      (this.thankuScreenData.visaCardTxt4 +
        this.thankuObject.emailID +
        this.thankuScreenData.onePaymentTxt5) :
      (this.thankuScreenData.onePaymentTxt6 + 
        this.thankuScreenData.partnerHighSecurity + 
        (this.isBillingPartnerFlow ? this.thankuScreenData.partnerHighSecurityBilling.replace("[REDIRECT_URL]", this.partnerRedirectUrl) : "")))
  }

  async getScheduledPaymentMsg(paymentType: String): Promise<string> {
    if (this.isPartnerFlow) {
      this.partnerRedirectUrl = await this.getPartnerRedirectUrl();
    }
    return this.thankuScreenData.schPaymentTxt1.replace(
        "[PAYMENTAMOUNT]",
        this.currencyPipe.transform(this.thankuObject.amount, "USD")
      ) +
      // tslint:disable-next-line:max-line-length
      this.thankuScreenData.schPaymentTxt2.replace(
        "[BAN]",
        this.thankuObject.accountNo
      ) +
      this.thankuScreenData.schPaymentTxt3.replace(
        "[PAYMENTDATE]",
        this.datePipe.transform(this.thankuObject.dateObject, "MMMM d, y")
      ) +
      (paymentType === "Bank" ? (
        // tslint:disable-next-line:max-line-length
      this.thankuScreenData.onePaymentTxt4.replace(
        "[BANKACNT]",
        this.thankuObject.cardOrAccountNo
      )) : (
        // tslint:disable-next-line:max-line-length
        this.thankuScreenData.schPaymentTxt4.replace(
          "[CARDTYPE]",
          this.makePaymentResponse.payment.paymentInstrument.paymentCard
            .typeCode
        ) +
        // tslint:disable-next-line:max-line-length
        this.thankuScreenData.schPaymentTxt5.replace(
          "[CARDNUMBER]",
          this.thankuObject.cardOrAccountNo
        )
      )) + (!this.isPartnerFlow ? 
        (this.thankuScreenData.visaCardTxt4 +
          this.thankuObject.emailID +
          this.thankuScreenData.onePaymentTxt5) :
        (this.thankuScreenData.onePaymentTxt6 + 
          this.thankuScreenData.partnerHighSecurity + 
          (this.isBillingPartnerFlow ? this.thankuScreenData.partnerHighSecurityBilling.replace("[REDIRECT_URL]", this.partnerRedirectUrl) : "")))
  }

  generatePageViewDetailsForThankyouScreen(idpTypeArg: any, isPartnerArg: any) {
    let pageDetail = {
      primaryCategory: PDL_CONSTANTS.PRIMARY_CATEGORY,
      subCategory: PDL_CONSTANTS.PLP_SUB_CATEGORY,
      pageType: PDL_CONSTANTS.PLP_PAGE_TYPE_CONFIRMATION,
      pageName: PDL_CONSTANTS.PLP_PAGE_NAME_CONFIRMATION,
      previousPageURL: this._cacheSvc.getFromSessionStorage(PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL),
      idpType: idpTypeArg,
      isPartner: isPartnerArg
    };
    if (this.payInInstallment) {
      pageDetail.pageType = PDL_CONSTANTS.PLP_PAGE_TYPE_CONFIRMATION_PA;
      pageDetail.pageName = PDL_CONSTANTS.PLP_PAGE_NAME_CONFIRMATION_PA;
    }
    if (this.autoPayFlag) {
      pageDetail.pageType = PDL_CONSTANTS.PLP_PAGE_TYPE_CONFIRMATION_AUTOPAY;
      pageDetail.pageName = PDL_CONSTANTS.PLP_PAGE_NAME_CONFIRMATION_AUTOPAY;
    }
    this.pdlService.generatePageViewTag(pageDetail);
  }

  getContent() {
    this.contentService
      .GetContentData()
      .subscribe((data) => {
        this.thankuScreenData = data.par.common_payment.thankYou;
        this.content = data.par.common_payment.footer;
        ContentService.SetCache("content", data.par.common_payment);
      });
  }

  bankAccountDetail() {
    this.thankuObject.cardOrAccountText = ACH_CONSTANTS.BANK_ACCOUNT; // 'Bank account # ';
    this.thankuObject.alternateCardValueTxt = "";
    const accountNumber = ContentService.GetCache("accountNumber");
    this.thankuObject.cardOrAccountNo = accountNumber.substr(
      accountNumber.length - 4,
      accountNumber.length
    );
  }

  onBackBtnClick() {
    // analytics click tagging
    this.generateClickDetailsForThankyouScreen(
      "click",
      "button",
      ClickEventType.NAVIGATE,
      "Back"
    );
    if (
      this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW
      ) == "true"
    ) {
      this.utils.redirectToPartnerBillingApp();
    } else {
      this.utils.return(this.returnURI, true);
    }
  }

  onClose() {
    window.open("", "_parent", "");
    window.close();
  }

  generateClickDetailsForThankyouScreen(
    actionInput: string,
    assetTypeInput: string,
    typeInput: ClickEventType,
    ctaNameInput: string
  ) {
    let clickDetail = {
      action: actionInput,
      assetType: assetTypeInput,
      type: typeInput,
      ctaName: ctaNameInput,
    };

    this.pdlService.generateClickTag(clickDetail);
  }

  showDetails(){
    this.showPaymentDetails = !this.showPaymentDetails;
    if(this.showPaymentDetails){
      this.showDetailTxt = this.thankuScreenData?.eipHideDetailsTxt;
    } else {
      this.showDetailTxt = this.thankuScreenData?.eipShowDetailsTxt;
    }
  }
}
