import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { LoaderService } from "./loader.service";
import { SetupRouteService } from "./setup-route.service";
import { MakePaymentConstant } from "../common/core/common.constants";
import { HttpCacheService } from "./http-cache.service";

@Injectable()
export class PaymentResolver  {
  constructor(
    private _loaderSvc: LoaderService,
    private setUpRouteSvc: SetupRouteService,
    private _cacheSvc: HttpCacheService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (route) {
      this._cacheSvc.putInSessionStoarage(
        MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
        MakePaymentConstant.FLOW_TYPE.ONETIME_PAYMENT
      );
      sessionStorage.setItem("oktaAuthCode", route.queryParams.code);
      return this.setUpRouteSvc.tokenResolvedOkta(route.queryParams);
    }
  }
}
