<div class="">
    <section class="credit-card-Info">
      <div class="credit-container">
        <form [formGroup]="totalDueNewCardForm">

          <div >
            <div class="row mrg-bm-20">
              <div class="col-sm-12">
                <h3 *ngIf="paymentCardType == 'paymentCreditDebitCard'" class="mt-1 pt-4">{{authoringContent?.creditCard?.creditCardInfoTxt}}</h3>
                <h3 *ngIf="paymentCardType == 'paymentCreditCard'" class="mt-1 pt-4">{{creditCardInfo}}</h3>
                <h3 *ngIf="paymentCardType == 'paymentDebitCard'" class="mt-1 pt-4">{{debitCardInfo}}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 cc-num-section">
                <div class="label_box">
                  <div class="question possition-r">
                    <ul class="card-icons">
                      <li *ngFor="let icon of creditService.cardTypeIcons">
                        <img [src]="icon.url" [ngClass]="{'highlight-card' : icon.brand === cardBrand}" alt="{{icon.url}}" class="ccThumb">
                      </li>
                    </ul>

                      <input
                    (input)="onCardNumberChange(totalDueNewCardForm.get('creditCardNumber')?.value)"
                    type="text"
                    id="creditCardNumber"
                    #creditCardNumber
                    [maxlength]="ccMaxLength"
                    formControlName="creditCardNumber"
                    autocomplete="cc-number"
                    creditCardInput
                    [readonly]="!isEdit"
                    [class.hide-input]="!showCardDetails"
                    required
                    />
                      <i *ngIf='isEdit'  class="fa fa-eye-slash" [ngClass]="{'fa-eye': !showCardDetails, 'fa-eye-slash': showCardDetails}"
                         (click)="toggleCardDetails()"></i>
                      <i *ngIf='!isEdit'  class="fa fa-eye-slash" [ngClass]="{'fa-eye': !showCardDetails, 'fa-eye-slash': showCardDetails}"></i>
                      <p class="optional_text optional_errortext position-absolute" *ngIf="(totalDueNewCardForm.controls['creditCardNumber'].errors?.required && totalDueNewCardForm.controls['creditCardNumber'].touched && flowType!='autoPay')||(!isLuhnChecked && flowType!='autoPay')">
                      Please enter valid credit/debit card</p>
                    <p class="optional_text optional_errortext position-absolute" *ngIf="!isV4CardTypeEnabled && (totalDueNewCardForm.controls['creditCardNumber'].errors?.pattern || totalDueNewCardForm.controls['creditCardNumber'].errors?.minlength) && (!isCCNumberInvalid)  && (totalDueNewCardForm.controls['creditCardNumber'].touched) || !isLuhnChecked && (paymentCardType == 'paymentCreditDebitCard')">
                      Please enter valid credit/debit card</p>

                    <p class="optional_text optional_errortext position-absolute" *ngIf="isV4CardTypeEnabled && (totalDueNewCardForm.controls['creditCardNumber'].touched ||  isCCNumberInvalid ) && ( totalDueNewCardForm.controls['creditCardNumber'].errors?.pattern  || totalDueNewCardForm.controls['creditCardNumber'].errors?.maxlength    ||  !isLuhnChecked ||   isCCNumberInvalid) && (paymentCardType == 'paymentCreditDebitCard')">
                      Please enter valid credit/debit card</p>

                    <p class="optional_text optional_errortext position-absolute" *ngIf="totalDueNewCardForm.controls['creditCardNumber'].errors?.required && (!isCCNumberInvalid) && (totalDueNewCardForm.controls['creditCardNumber'].touched) && (paymentCardType == 'paymentCreditDebitCard')">
                      Please enter valid credit/debit card</p>

                    <p class="optional1 optional_text optional_errortext position-absolute" *ngIf="!isV4CardTypeEnabled && (totalDueNewCardForm.controls['creditCardNumber'].errors?.pattern || totalDueNewCardForm.controls['creditCardNumber'].errors?.minlength) && (!isCCNumberInvalid)  || !isLuhnChecked && (totalDueNewCardForm.controls['creditCardNumber'].touched) && (paymentCardType == 'paymentCreditCard') && !cc">
                      Please enter valid credit card</p>

                    <p class="optional2 optional_text optional_errortext position-absolute" *ngIf="isV4CardTypeEnabled && (totalDueNewCardForm.controls['creditCardNumber'].touched ||  isCCNumberInvalid ) && ( totalDueNewCardForm.controls['creditCardNumber'].errors?.pattern  || totalDueNewCardForm.controls['creditCardNumber'].errors?.maxlength ||  !isLuhnChecked ||   isCCNumberInvalid) && (paymentCardType == 'paymentCreditCard') && !cc">
                      Please enter valid credit card</p>

                    <p class="optional_text optional_errortext position-absolute" *ngIf="(paymentCardType == 'paymentCreditCard') && cc">
                      {{wrongCreditCard}}</p>

                    <p class="optional3 optional_text optional_errortext position-absolute" *ngIf="totalDueNewCardForm.controls['creditCardNumber'].errors?.required && (!isCCNumberInvalid) && (totalDueNewCardForm.controls['creditCardNumber'].touched) && (paymentCardType == 'paymentCreditCard') && !cc">
                      Please enter valid credit card</p>

                    <p class="optional_text optional_errortext position-absolute" *ngIf="!isV4CardTypeEnabled && (totalDueNewCardForm.controls['creditCardNumber'].errors?.pattern || totalDueNewCardForm.controls['creditCardNumber'].errors?.minlength) && (!isCCNumberInvalid)  && (totalDueNewCardForm.controls['creditCardNumber'].touched) || !isLuhnChecked && (paymentCardType == 'paymentDebitCard') && !dc">
                      Please enter valid debit card</p>

                    <p class="optional_text optional_errortext position-absolute" *ngIf="isV4CardTypeEnabled && (totalDueNewCardForm.controls['creditCardNumber'].touched ||  isCCNumberInvalid ) && ( totalDueNewCardForm.controls['creditCardNumber'].errors?.pattern  || totalDueNewCardForm.controls['creditCardNumber'].errors?.maxlength    ||  !isLuhnChecked ||   isCCNumberInvalid) && (paymentCardType == 'paymentDebitCard') && !dc">
                      Please enter valid debit card</p>

                    <p class="optional_text optional_errortext position-absolute" *ngIf="(paymentCardType == 'paymentDebitCard') && dc">
                      {{wrongDebitCard}}</p>

                    <p class="optional_text optional_errortext position-absolute" *ngIf="totalDueNewCardForm.controls['creditCardNumber'].errors?.required && (!isCCNumberInvalid) && (totalDueNewCardForm.controls['creditCardNumber'].touched) && (paymentCardType == 'paymentDebitCard') && !dc">
                      Please enter valid debit card</p>

                    <label *ngIf="(isEdit) && flowType == 'billPay'" >{{authoringContent?.creditCard?.creditCardNumberText}}</label>
                    <label *ngIf="(isEdit) && paymentCardType == 'paymentCreditCard'" >{{creditCardNoMsg}}</label>
                    <label *ngIf="(isEdit) && paymentCardType == 'paymentDebitCard'" >{{debitCardNoMsg}}</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-5 exp-date-section">
                <div class="row">
                  <div class="custom_floatlabel custom_monthfloatlabel" [ngClass]="{'valid-input': selectedMonth}">{{authoringContent?.creditCard?.selectMonthLbl}}</div>
                  <div class="col-sm-6 col-6">

                    <div class="custom-ddl-wrapper nopadding-left">
<div ngbDropdown #expDateDropDown="ngbDropdown">
                        <button tabindex="42" [disabled]="careAuthType" type="button" class="btn btn-outline-primary custom-ddl-btn state-dropdown"
                                id="dropdownBasic2" ngbDropdownToggle   (keydown.arrowdown)="expDateDropDown.open()">
                          <div class="selectd-val">
                              <span> {{ selectedMonth !== undefined ? selectedMonth.value : "&nbsp;" }} </span>
                              <input #month type="hidden" autocomplete="cc-exp-month" formControlName="expirationMonth" required tabindex="42 "  (click)="changeMonth(month.value)"/>
                          </div>
                        </button>
                        <div ngbDropdownMenu #expirationMonth aria-labelledby="dropdownBasic2" class="acnttype-dropdown-menu">
                          <button tabindex="43" ngbDropdownItem [disabled]="careAuthType" type="button" class="dropdown-item" *ngFor="let month of months" (click)="changeMonth(month)">
                            <div class="default-item">
                              <span>{{month.value}}
                              </span>
                            </div>
                          </button>

                        </div>
                      </div>
                      <p class="optional_text optional_errortext" *ngIf="totalDueNewCardForm.controls['expirationMonth'].errors?.required && totalDueNewCardForm.controls['expirationMonth'].touched">
                        Please select exp month</p>
                    </div>
                  </div>

                  <div class="col-sm-6 col-6">
                    <div class="custom-ddl-wrapper nopadding-left">
                      <div ngbDropdown #expYearDropDown="ngbDropdown">
                          <div class="custom_floatlabel exp_year_lbl" [ngClass]="{'valid-input': selectedYear}">{{authoringContent?.creditCard?.selectYearLbl}}</div>
                        <button tabindex="44" [disabled]="careAuthType" type="button" class="btn btn-outline-primary custom-ddl-btn state-dropdown" id="dropdownBasic3" ngbDropdownToggle (keydown.arrowdown)="expYearDropDown.open()">
                            <div class="selectd-val">
                                <span> {{ selectedYear !== undefined ? selectedYear : "&nbsp;" }} </span>
                            </div>
                        </button>
                        <div ngbDropdownMenu #expirationYear  aria-labelledby="dropdownBasic3" class="acnttype-dropdown-menu">
                          <button tabindex="45" ngbDropdownItem [disabled]="careAuthType" type="button" class="dropdown-item" *ngFor="let year of years" (click)="changeYear(year)">
                            <div class="default-item">
                              <span>{{year}}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <p class="optional_text optional_errortext" *ngIf="totalDueNewCardForm.controls['expirationYear'].errors?.required && totalDueNewCardForm.controls['expirationYear'].touched">
                      Please select exp year</p>
                  </div>
                  <div class="col-12" *ngIf="totalDueNewCardForm.errors?.validateExpirationDate">
                    <p class="optional_text optional_errortext expDate position-absolute">{{authoringContent?.creditCard?.expirationError}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="label_box">
                  <div class="question">
                      <input  [type]="cvvInputType"
                              class="cvv_input"
                              id="cvv" name="cvv" #cvv
                              formControlName="cvv"
                              fieldName="creditCardCvvNumber"
                              autocomplete="cc-csc"
                              cvvACHInput
                              [class.hide-input]="!showCVVDetails"
                              class="form-control"
                              [attr.maxlength]="cvvMaxLength"
                              (input)="cvvValidator(totalDueNewCardForm.get('cvv')?.value)"
                              tabindex="46" required/>
                      <i class="fa fa-eye-slash fa-cvv"
                         [ngClass]="{'fa-eye': !showCVVDetails, 'fa-eye-slash':
                      showCVVDetails}" (click)="toggleCVVDetails()"></i>

                      <div *ngIf="((totalDueNewCardForm.controls['cvv'].errors?.required || totalDueNewCardForm.controls['cvv'].errors?.minlength) &&   totalDueNewCardForm.controls['cvv'].touched)" class="position-relative cvv-error">
                          <p class="optional_text optional_errortext mb-2">
                        {{authoringContent?.creditCard?.blankCVVNumberError}}</p>
                      </div>
                      <div *ngIf="!((totalDueNewCardForm.controls['cvv'].errors?.required || totalDueNewCardForm.controls['cvv'].errors?.minlength) &&   totalDueNewCardForm.controls['cvv'].touched) && totalDueNewCardForm.controls['cvv'].errors?.pattern" class="position-relative cvv-error">
                          <p class="optional_text optional_errortext mb-2">
                              {{authoringContent?.creditCard?.invalidCVVNumberError}}</p>
                      </div>
                      <label for="cvv" >{{authoringContent?.creditCard?.securityCodeLabel}}</label>
                  </div>
                  <span class ="optional-helper-text position-relative" placement="right" container="body" ngbTooltip="{{authoringContent?.creditCard?.cvvHelpTooltipTxt}}">{{authoringContent?.creditCard?.cvvHelpLabelTxt}}</span>
                </div>
              </div>
              <div class="col-sm-6 offset-sm-3">
                <div class="label_box">
                  <div class="question">
                    <input type="text" id="nameOnCreditCard" autocomplete="cc-name" formControlName="nameOnCreditCard"  value="{{editObject?.nameOncard}}" required
                    [maxlength]="creditCardNameLength" (ngModelChange)="validateCardName()" tabindex="47"/>
                    <p class="optional_text optional_errortext position-absolute" *ngIf="totalDueNewCardForm.controls['nameOnCreditCard'].errors?.required && (totalDueNewCardForm.controls['nameOnCreditCard'].touched)">{{authoringContent?.creditCard?.invalidCardHolderNameError}}</p>
                    <p class="optional_text optional_errortext position-absolute" *ngIf="totalDueNewCardForm.controls['nameOnCreditCard'].errors?.pattern && (totalDueNewCardForm.controls['nameOnCreditCard'].touched)">{{authoringContent?.creditCard?.invalidCardHolderNameError}}</p>
                    <label for="nameOnCreditCard" *ngIf=(isEdit)>{{authoringContent?.creditCard?.ccName}}</label>
                  </div>
                </div>
              </div>
            </div>

              <!-- Credit Billing address -->
            <div *ngIf="isEdit">
            <div class="row mrg-bm-20">
              <div class="col-sm-12">
                <h3 class="mt-0 pt-0">{{authoringContent?.creditCard?.billingAddTxt}}</h3>
              </div>
            </div>
            <div class="row-billing-address">
              <div class="col-sm-6">
                <div class="label_box">
                  <div class="question">
                      <input type="text"  autocomplete="off" placeholder="" id="addressNumbers" formControlName="ccAddress1"   ngx-gp-autocomplete [options]="{types: [],componentRestrictions: { country: 'US' }}"
                      #placesRef="ngx-places"  (onAddressChange)="handleGoogleAddressChange($event)" required (ngModelChange)="validateAddress()" tabindex="48"/>
                    <p class="optional_text optional_errortext position-absolute" *ngIf="totalDueNewCardForm.controls['ccAddress1'].errors?.required && totalDueNewCardForm.controls['ccAddress1'].touched">
                      {{authoringContent?.creditCard?.blankAddressLine1Error}}</p>
                      <label for="addressNumbers">{{authoringContent?.creditCard?.addressLine1Lbl}}</label>

                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="label_box">
                  <div class="question" appOptionalFloatingLabel>
                      <input type="text"   id="po-box-apt" formControlName="ccAddress2" tabindex="49"   [ngClass]="totalDueNewCardForm.controls['ccAddress2'].value =='' && totalDueNewCardForm.controls['ccAddress2'].touched ? 'inputAddress2' : '' "  readonly="" onfocus="this.removeAttribute('readonly');"/>
                    <p class="optional_text optional_errortext position-absolute" *ngIf="totalDueNewCardForm.controls['ccAddress2'].errors?.required && totalDueNewCardForm.controls['ccAddress2'].touched">
                      {{authoringContent?.creditCard?.blankAddressLine2Error}}</p>
                      <label for="po-box-apt">{{authoringContent?.creditCard?.addressLine2Lbl}}</label>

                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="label_box">
                  <div class="question">
                      <input type="text"   autocomplete="address-level2" id="city" name="data_input_field_3" formControlName="ccCity"   required tabindex="50" (ngModelChange)="validateCity()" class="autoFill"/>
                      <p class="optional_text optional_errortext position-absolute" *ngIf="totalDueNewCardForm.controls['ccCity'].errors?.required && (totalDueNewCardForm.controls['ccCity'].touched)">
                      {{authoringContent?.creditCard?.blankCityErrorMessage}}</p>
                    <p class="optional_text optional_errortext" *ngIf="totalDueNewCardForm.controls['ccCity'].errors?.pattern && (totalDueNewCardForm.controls['ccCity'].touched)">
                      {{authoringContent?.creditCard?.cityPatternErrorMessage}}</p>
                      <label for="city">{{authoringContent?.creditCard?.city}}</label>

                  </div>
                </div>
              </div>
              <div class="col-sm-3 col-6 div-state mrg-bm-20">
                <div class="custom-ddl-wrapper nopadding-left">
                    <div ngbDropdown #stateDropDown ="ngbDropdown">
                    <div class="custom_floatlabel state_lbl" [ngClass]="{'valid-input': stateSelected}">{{authoringContent?.creditCard?.state}}</div>
                        <button tabindex="52" [disabled]="careAuthType" type="button" class="btn btn-outline-primary custom-ddl-btn state-dropdown" id="dropdownBasic1" ngbDropdownToggle (keydown.arrowdown)="stateDropDown.open()">
                            <div>
                                <span>{{ stateSelected !== undefined ? stateSelected.name : "&nbsp;" }}</span>
                        <input type="hidden" formControlName="ccState" required tabindex="0" />
                            </div>
                        </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="state-dropdown-menu">
                      <button tabindex="53" ngbDropdownItem [disabled]="careAuthType" type="button" class="dropdown-item" *ngFor="let state of stateList" (click)="changeState(state)">
                                <div class="default-item">

                                    <span>{{state.name}}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                  <p class="optional_text optional_errortext" *ngIf="totalDueNewCardForm.controls['ccState'].errors?.required && totalDueNewCardForm.controls['ccState'].touched">
                    Please select state</p>
                </div>
              </div>
              <div class="col-sm-3 col-6">
                <div class="label_box">
                  <div class="question">
                      <input type="text"  autocomplete="postal-code"  id="zipcode" tabindex="54" maxlength="5" formControlName="ccZip"  value="{{editObject?.ccZip}}" appNumbersOnly required (ngModelChange)="validateZip()" class="autoFill"/>
                    <p class="optional_text optional_errortext position-absolute" *ngIf="totalDueNewCardForm.controls['ccZip'].errors?.required && (totalDueNewCardForm.controls['ccZip'].touched)">
                      {{authoringContent?.creditCard?.blankZipError}}</p>
                    <p class="optional_text optional_errortext position-absolute" *ngIf="(totalDueNewCardForm.controls['ccZip'].errors?.minlength || totalDueNewCardForm.controls['ccZip'].errors?.pattern)
                    && (totalDueNewCardForm.controls['ccZip'].touched)">
                      {{authoringContent?.creditCard?.invalidZipError}}</p>
                      <label for="zipcode">{{authoringContent?.creditCard?.zip}}</label>

                  </div>
                </div>
              </div>
            </div>
            <div class="row mrg-bm-20" *ngIf=isInstallmentRadioSelected>
              <div class="col-sm-12 media">
                <span class="icons phone">
                  <img src="/assets/tfb-payment/images/phoneIcon.png" class="payment-info" alt="">
                </span>
                <div class="d-inline-block phoneBlock media-body">
                  <h3 class="mt-0 pt-0 mb-2 headingPhone">{{authoringContent?.creditCard?.phoneNotificationTitle}}</h3>
                  <p class="optional_text helpTxtPhone mb-3" >
                    {{authoringContent?.creditCard?.phoneNotificationMessage}} </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="label_box">
                  <div class="question" appOptionalFloatingLabel>
                    <input (input)="checkPhoneNumber($event.target.value)" type="text" formControlName="ccPhone" appPhoneMask required class="autoFill"/>
                    <p class="optional_text position-relative mb-0" *ngIf="totalDueNewCardForm.controls['ccPhone'].untouched">
                      {{authoringContent?.creditCard?.phoneHelpMessage}}</p>
                      <p class="optional_text optional_errortext position-relative mb-0"
                      *ngIf="totalDueNewCardForm.controls['ccPhone'].errors?.required && totalDueNewCardForm.controls['ccPhone'].touched">
                        {{authoringContent?.creditCard?.phoneErrorMessage}}</p>
                      <p class="optional_text optional_errortext position-relative mb-0" *ngIf="phoneNumberError.status">
                        {{phoneNumberError.message}}
                      </p>
                      <p class="optional_text optional_successtext position-relative mb-0" *ngIf="phoneNumberSuccess.status">
                        {{phoneNumberSuccess.message}}
                      </p>
                    <label class="clear">{{authoringContent?.creditCard?.phone}}</label>
                  </div>
                </div>
              </div>
            </div>
            </div>

          </div>
        </form>
      </div>
    </section>
  </div>
  <div class="container mt-5 mb-5">
    <ng-template [ngIf]="errorAlert.show">
      <div class="text-error-msg tab-margin">
        {{errorAlert.message.errorMessage}}
      </div>
    </ng-template>
  </div>
  <app-loader></app-loader>

