<div class="main-loader-wrapper" *ngIf="!isLoaded">
  <div class="mega_spinner_box" *ngIf="loaderType === 'mega'">
    <svg class="megaspinner" width="66px" height="66px" stroke-width="5" viewBox="0 0 66 66">
      <circle cx="33" cy="33" r="25"/>
    </svg>
  </div>
  <div class="modal_spinner_box" *ngIf="loaderType === 'modal'">
    <svg class="modalspinner" width="48px" height="48px" stroke-width="5" viewBox="0 0 66 66">
      <circle cx="33" cy="33" r="25"/>
    </svg>
  </div>
  <div class="micro_spinner_box" *ngIf="loaderType === 'mirco'">
    <svg class="microspinner" width="40px" height="40px" stroke-width="5" viewBox="0 0 66 66">
      <circle cx="33" cy="33" r="25"/>
    </svg>
  </div>
</div>


<!-- Loader End -->