import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { KubeEnvironmentService } from '../kube-environment/kube-environment';
import { NgClass, NgIf } from '@angular/common';
declare var grecaptcha: any;

@Component({
    selector: 'app-recaptcha-v2',
    templateUrl: './recaptcha-v2.component.html',
    styleUrls: ['./recaptcha-v2.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf]
})
export class RecaptchaV2Component implements OnInit, OnDestroy {

  @Output()
  onPass: EventEmitter<string> = new EventEmitter<string>();
  hasToken = false;

    constructor(private kubeEnvService: KubeEnvironmentService) {
    }

  ngOnInit() {
    this.renderRecaptcha();
  }

  ngOnDestroy() {
    this.onPass.emit('');
  }

  private renderRecaptcha() {
    console.log('renderRecaptcha was called')
    grecaptcha.render('recaptcha', {
      sitekey: this.kubeEnvService.getConfig().recaptchaSiteKey,
      callback: (response) => {
        this.onPass.emit(response);
        this.hasToken = !!response;
      },
      'expired-callback': () => {
        this.onPass.emit('');
      }
    });
  }
}
