<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div *ngIf="authoringContent" class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3>
          <em class="fa fa-check-circle text-success success-m-r-10" aria-hidden="true"></em>
          {{authoringContent.successHeadingTxt}}
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseHandled()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mb-4">
        <div *ngIf="_autoPaymentSvc.buttonValue === authoringContent.enrollButtonText">
          <p class="Checking-Account-end" [innerHTML]="successDescription">
          </p>
          <P class="AutoPay-will-begin-o" [innerHTML]="successDateDetails">
          </P>
        </div>
        <div *ngIf="_autoPaymentSvc.buttonValue === authoringContent.saveChangesBtnText">
          <p class="Checking-Account-end" [innerHTML]="successDescriptionForManage">
          </p>
          <P class="AutoPay-will-begin-o" [innerHTML]="successDateDetailsForManage">
          </P>
        </div>
        <div *ngIf="_autoPaymentSvc.buttonValue === authoringContent.confirmButtonText">
          <p>{{authoringContent?.cancelAutoPayBody}}</p>
        </div>
      </div>
    </div>
  </div>
</div>