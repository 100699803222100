import { NgxLoggerLevel } from 'ngx-logger';
import { staticContent } from './environment.content';
import { pnpConfig } from './environment.defaults';
import { domain } from "./environment.prod.domain";
export const environment = {
  domain: domain.prod,
  production: true,
  dev: false,
  mocked_all: false,
  mocked_content: false,
  mocked_endpoints: false,
  APIGEE_KEY: '',
  SSO_ON: true,
  APPD_KEY: 'AD-AAB-AAM-AHZ',
  GOOGLE_API_KEY: '',
  LOGGING_ENABLED: false,
  oktaRedirectUrl: 'https://payment.tfb.t-mobile.com/implicit/callback',
  oktaClientId: '',
  oktaHost: 'https://t-mobile.okta.com',
  OKTA_ISSUER_CODE: 'aus1frdo4l505bJ86297',
  // SPLUNK
  SPLUNK_LOGGING: NgxLoggerLevel.LOG,
  HTTP_SPLUNK_EVENT: false,
  EUI_ENDPOINT: 'https://account.t-mobile.com/',
  APP_ID: 'digital-payment',
  ENV: 'prod',
  hideBANFriendlyName: false,
  ENABLE_V4CARDTYPE_API: true,
  CCPADormancyFlag: true,
  TEAAPI_Flag: false,
  HIDE_PAYMENT_ARRANGEMENT: false,
  ENABLE_PARTNER: true,
  ENABLE_HARDGOODS: true,
  DISABLE_ACCESS_TOKEN_CALL: true,
  ENABLE_WALKMEIN: true,
  ENABLE_PA_V2API: true,
  ENABLE_TAGGING: true,
  ENABLE_EIP_STANDALONE_PAYMENT: true,
  ENABLE_EIP_STANDALONE_PAYMENT_PARTNER: true,
  ENABLE_AUTOPAY_VERBIAGE: true,
  idle: {
    enabled: true,
    idleTimeSeconds: 28 * 60, // Executes when inactive for 29 min
    timeOutSeconds: 2 * 60 // Executes a 2 minute countdown prior to Timeout
  },
  IAM_LOGOUT: "/oauth2/v1/signout",
  OKTA_LOGOUT: "/oauth2/ausu6su3moLk5PYj90h7/v1/logout",
  PNP_CONFIG: pnpConfig,
  APD_JUNE_RELEASE:true,
  MAY_RELEASE_FLAG:false,
  StaticContent: {
    ...staticContent
  }
};

