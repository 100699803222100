<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name">
<div class="modal-dialog modal-md">
<div class="modal-content">
 <div class="modal-header">
   <h4 id="dialog-child-name" class="modal-title pull-left">
    <img class="errorIcon" src="../../../assets/tfb-payment/images/icon_error.svg" alt="error">Your Session is about to time out</h4>
   <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()"> 
     <span aria-hidden="true">&times;</span>
   </button>-->
 </div>
 <div class="modal-body">
    <div class="warningMessage">{{idleState}} </div>
   <div class="row mrgbtm">
     <div class="col-md-7">         
     </div>
     <div class="col-md-4">
         <button type="button" (click)="stay()" class="btn btn-success">Keep me logged in</button>
     </div>
   </div>
 </div>
</div>
</div>
</div>