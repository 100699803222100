
<!-- Below condition commented for US416936 -->
<div class="legal" *ngIf='termsAndConditionTxtData'>
  <span [innerHTML]="this.termsAndConditionTxtData.makePaymentTermsTxt"></span>
  <a class="legal_link" href="javascript:void(0)" (click)='openApTermsModal()' >{{this.termsAndConditionTxtData.makePaymentTermsTxt1}}</a>
  {{this.termsAndConditionTxtData.makePaymentTermsTxt4}}
  {{this.termsAndConditionTxtData.makePaymentTermsTxt2}}
  {{this.termsAndConditionTxtData.makePaymentTermsTxt3}}
    <!-- Modal section -->
   <div class="BG-modal" [ngStyle]="{'display':display}"></div>
   <div class="modal terms-and-conditions-popup" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}">
     <div class="modal-dialog" role="document">
       <div class="modal-content">
         <div class="modal-header pb-2">
           <button type="button" class="close" aria-label="Close" (click)="onCloseHandled()">
             <span aria-hidden="true">&times;</span></button>
           <h4 class="modal-title"> {{this.termAndConditionTitle}}</h4>
           
         </div>
         <div class="col-12 pb-2">
           <a href="javascript:void(0)" class="print" (click)="print('print')">
             <img src="../../../assets/tfb-payment/images/icons/icon_print_magenta.png" alt="" />{{contentData?.autoPay?.printTxt}}
            </a>        
        </div>
         <div class="modal-body ">              
           <div class="modal-scroll  Lorem-ipsum-dolor-si" [innerHTML]="this.tcContent.serviceTermsTxt" id="print" ></div>           
        </div>          
         <div class="modal-footer">
           <button type="button" class="primary_btn marRgtTerms" (click)="onCloseHandled()" >Close</button>
         </div>
       </div><!-- /.modal-content -->
     </div><!-- /.modal-dialog -->
   </div><!-- /.modal !-->
</div>
