import { Injectable } from "@angular/core";
import { iif, of, pipe, throwError } from "rxjs";
import { concatMap, delay, retryWhen } from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class PipedOperatorsService {
    subReattempt(attempts?, delay_ms?) {
        attempts = attempts ?? 3;
        delay_ms = delay_ms ?? 1000;
        return pipe(
            retryWhen<any>(errors => errors.pipe(
                concatMap((e, i) => 
                    iif(() => 
                        ((e.status < 500) || (i >= attempts - 1)),
                        throwError(e),
                        of(e).pipe(delay(delay_ms))
                    )
                )
            ))
        )
    }
}