/**
 * This provides all of the pnp functionality for microapps.  The service will automatically complete the connection to the
 * host/parent hub application when it is injected as a dependency.
 * The context and navigation data can be subscribed to directly.  The credentials are loaded transparently
 * and are automatically included with any http requests that the application makes to the backend.
 */

import { Inject, Injectable, NgZone, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { boundMethod } from "autobind-decorator";
import { OktaAuth, Token, UserClaims } from "@okta/okta-auth-js";
import { isFunction } from "lodash";

import { PnpContext } from "../interfaces/pnp-context";
import { PnpMessage } from "../interfaces/pnp-message";
import { PnpMessageBus } from "../interfaces/pnp-message-bus";
import { PnpNav } from "../interfaces/pnp-nav";
import { configToken, PnpConfig } from "../interfaces/pnp-config";
import { PnpMicroAppConfig } from "../interfaces/pnp-microapp-config";

declare var require: any;
const PNP_CONFIG: PnpMicroAppConfig = require("../../../assets/tfb-payment/pnp-configuration.json");

@Injectable({
  providedIn: "root",
})
export class PnpClientService implements OnDestroy {
  private contextSubject = new BehaviorSubject<PnpContext>({});
  private resolveSubject = new Subject<boolean>();
  private messageBus: PnpMessageBus;
  private oktaClient: OktaAuth;
  private isPartner = (window as any).IS_PARTNER;
  private idpType = "iam";
  private apigeeStorage;
  private iamStorage;
  private azureStorage;
  USER_DATA_STORAGE_KEY: string = "userData";
  private tokenRefreshFn: () => Promise<{
    accessToken: string;
    idToken: string;
  }>;

  context$ = this.contextSubject.asObservable();
  resolve$ = this.resolveSubject.asObservable();

  constructor(
    private zone: NgZone,
    @Inject(configToken) private config: PnpConfig
  ) {
    const isSessionStorage = PNP_CONFIG.APIGEE_CONFIG.SESSION_STORAGE;
    this.apigeeStorage = isSessionStorage ? sessionStorage : localStorage;

    this.iamStorage = PNP_CONFIG.IAM_CONFIG.SESSION_STORAGE
      ? sessionStorage
      : localStorage;

    this.azureStorage = PNP_CONFIG.AZURE_CONFIG.SESSION_STORAGE
        ? sessionStorage
        : localStorage;
    
    const oktaConfig = PNP_CONFIG.OKTA_CONFIG;
    this.oktaClient = new OktaAuth({
      issuer: oktaConfig.ISSUER,
      clientId: oktaConfig.CLIENT_ID,
      redirectUri: `${window.location.origin}/${oktaConfig.REDIRECT_URI}`,
      tokenManager: {
        storageKey: oktaConfig.STORAGE_CODE,
      },
    });

    this.context$.subscribe(
      (context) =>
        (this.idpType =
          context && context.IDPType ? context.IDPType : this.idpType)
    );
  }

  ngOnDestroy(): void {
    if (this.messageBus) {
      this.messageBus.removeListener("message", this.onMessage);
    }
  }

  public async getAccessToken(): Promise<string | Token> {
    if (this.idpType === "azure") {
      const tokenKey = PNP_CONFIG.AZURE_CONFIG.TOKEN_STORAGE_KEY;
      return JSON.parse(this.azureStorage.getItem(tokenKey)).accessToken
    }
    
    if (this.tokenRefreshFn && isFunction(this.tokenRefreshFn)) {
      const res = await this.tokenRefreshFn();
      return res ? res.accessToken : "";
    }

    if (this.isPartner) {
      const accessTokenKey = PNP_CONFIG.APIGEE_CONFIG.ACCESS_TOKEN_STORAGE_KEY;
      return this.apigeeStorage.getItem(accessTokenKey);
    }

    if (this.idpType === "iam") {
      const tokenKey = PNP_CONFIG.IAM_CONFIG.TOKEN_STORAGE_KEY;
      return JSON.parse(this.iamStorage.getItem(tokenKey) || "{}").access_token;
    }
    
    // default to OKTA
    this.oktaClient.authStateManager.updateAuthState();
    const accessToken = await this.oktaClient.tokenManager.get("accessToken");
    return accessToken ? accessToken : "";
  }

  public async getIdToken(): Promise<Token | string | any[]> {
    if (this.idpType === "azure") {
      const tokenKey = PNP_CONFIG.AZURE_CONFIG.TOKEN_STORAGE_KEY;
      return JSON.parse(this.azureStorage.getItem(PNP_CONFIG.AZURE_CONFIG.TOKEN_STORAGE_KEY)).idToken
    }
    if (this.tokenRefreshFn && isFunction(this.tokenRefreshFn)) {
      const res = await this.tokenRefreshFn();
      return res ? res.idToken : "";
    }
    if (this.isPartner) {
      const idTokenKey = PNP_CONFIG.APIGEE_CONFIG.ID_TOKEN_STORAGE_KEY;
      return this.apigeeStorage.getItem(idTokenKey);
    }

    if (this.idpType === "iam") {
      const tokenKey = PNP_CONFIG.IAM_CONFIG.TOKEN_STORAGE_KEY;
      return JSON.parse(this.iamStorage.getItem(tokenKey) || "{}").id_tokens[0][
        "id_token.none"
      ];
    }
    
    this.oktaClient.authStateManager.updateAuthState();
    const idToken = await this.oktaClient.tokenManager.get("idToken");
    return idToken ? idToken : "";
  }

  async getTokens(): Promise<any> {
    if (this.idpType === "azure") {
      const tokenKey = PNP_CONFIG.AZURE_CONFIG.TOKEN_STORAGE_KEY;
      return {
        access_token : JSON.parse(this.azureStorage.getItem(PNP_CONFIG.AZURE_CONFIG.TOKEN_STORAGE_KEY)).accessToken,
        id_token: JSON.parse(this.azureStorage.getItem(PNP_CONFIG.AZURE_CONFIG.TOKEN_STORAGE_KEY)).idToken,
      }
    }
    if (this.tokenRefreshFn && isFunction(this.tokenRefreshFn)) {
      await this.tokenRefreshFn();
    }
    
    if (this.isPartner) {
      const idTokenKey = PNP_CONFIG.APIGEE_CONFIG.ID_TOKEN_STORAGE_KEY;
      const accessTokenKey = PNP_CONFIG.APIGEE_CONFIG.ACCESS_TOKEN_STORAGE_KEY;
      return {
        access_token: this.apigeeStorage.getItem(accessTokenKey),
        id_token: this.apigeeStorage.getItem(idTokenKey),
      };
    }

    if (this.idpType === "iam") {
      const tokenKey = PNP_CONFIG.IAM_CONFIG.TOKEN_STORAGE_KEY;
      return JSON.parse(this.iamStorage.getItem(tokenKey) || "{}");
    }
    
    this.oktaClient.authStateManager.updateAuthState();
    return await this.oktaClient.tokenManager.getTokens();
  }

  /**
   * Connect to the message bus created by the single-spa root application.
   */
  connect(messageBus: PnpMessageBus): void {
    if (!messageBus) {
      return;
    }
    this.messageBus = messageBus;
    this.messageBus.on("message", this.onMessage);
    this.requestHandshake();
  }

  /**
   * Callback to handle messages received from the parent/host app.
   * @param message Message Bus event
   */
  @boundMethod
  onMessage(message: PnpMessage): void {
    this.zone.run(async () => {
      switch (message.type) {
        case "UPDATE_CLIENT_CONTEXT":
          this.contextSubject.next({
            ...this.contextSubject.value,
            ...(message.payload as PnpContext),
          });
          break;
        case "UPDATE_TOKEN_FETCH_FN":
          this.tokenRefreshFn = message.payload;
          break;
        case "RESOLVE":
          alert("we are reloading app");
          this.resolveSubject.next(true);
          this.resolveSubject.complete();
          break;
      }
    });
  }

  /**
   * Sends a message of the conforming contract to the parent/host app.
   * @param message Object containing both the type and value of the message
   */
  sendMessage(message: PnpMessage): void {
    if (this.messageBus) {
      this.messageBus.emit("message", message);
    }
  }

  /**
   * Sends a message to set the navigation lists shown on the host/parent app for the microapp.
   * @param appId The id of the microapp, typically the calling microapp, but does allow updating navs for other apps.
   * @param navs List of all navs to be shown
   */
  setNavList(appId: string, navs: PnpNav[]): void {
    this.sendMessage({
      type: "SET_NAV_LIST",
      payload: { appId, navs },
    });
  }

  /**
   * Sends a message to host to set the header title.
   * @param title The title
   */
  setTitle(title: string): void {
    this.sendMessage({ type: "TITLE", payload: title });
  }

  /**
   * Update the context and synchronize it with the parent/host app.
   * @param context The values to merge into the context.
   */
  updateContext(context: PnpContext): void {
    this.sendMessage({
      type: "UPDATE_HOST_CONTEXT",
      payload: context,
    });
    this.contextSubject.next({
      ...this.contextSubject.value,
      ...context,
    });
  }

  isRunningInGlobalNav(): boolean {
    return Boolean((window as any).IS_GLOBAL_NAV);
  }

  getUserInfo(): Promise<UserClaims> {
    if (this.idpType === "azure"){
      return this.getAzureUser()
    }
    if (this.idpType === "iam") {
      const tokenKey = PNP_CONFIG.IAM_CONFIG.TOKEN_STORAGE_KEY;
      return JSON.parse(this.iamStorage.getItem(tokenKey) || "{}").userInfo;
    }

    if (this.oktaClient && this.oktaClient.isAuthenticated()) {
      return this.oktaClient.getUser();
    }
    return {} as any;
  }
  public getAzureUser(): Promise<any> {
    let userData = JSON.parse(localStorage.getItem(this.USER_DATA_STORAGE_KEY));
    return Promise.resolve({
      email: "",
      firstName: userData.idTokenClaims.given_name,
      lastName: userData.idTokenClaims.family_name,
      // orgId,
      userId: userData.idTokenClaims.NTID,
      // isGroupOrgUser,
      uuid: userData.idTokenClaims.NTID,
      NTID: userData.idTokenClaims.NTID
    });
  }

  requestReload(): void {
    this.sendMessage({
      type: "RELOAD",
      payload: { appId: this.config.microAppId },
    });
  }

  requestHandshake(): void {
    this.sendMessage({
      type: "HANDSHAKE",
      payload: { appId: this.config.microAppId },
    });
  }
}
