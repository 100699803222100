import { enableProdMode, NgZone, APP_INITIALIZER, ErrorHandler, importProvidersFrom, ɵprovideZonelessChangeDetection } from '@angular/core';
import { COMMON_CONSTANTS } from '../src/app/common/core/common.constants';
import { Router } from '@angular/router';
import { getSingleSpaExtraProviders, singleSpaAngular } from 'single-spa-angular';
import { SingleSpaProps, singleSpaPropsSubject } from './single-spa/single-spa-props';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { APIGEE_TOKEN_URL } from './app/common/core/urls.constants';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MatIconModule } from '@angular/material/icon';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { PnpframeworkModule } from 'src/pnpframework/public-api';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppRoutingModule } from './app/app-routing.module';
import { GlobalErrorHandler } from './app/services/errorHandler';
import { HttpLoggingInterceptor } from './app/services/http-logging.interceptor';
import { HttpCacheInterceptor } from './app/services/http-cache.interceptor';
import { AuthHttpInterceptor } from './app/services/auth-http.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { KubeEnvironmentService } from './app/common/kube-environment/kube-environment';
import { CurrencyPipe, DecimalPipe, DatePipe, APP_BASE_HREF, CommonModule } from '@angular/common';
import { SimonhandResolver } from './app/services/simonhand.resolver';
import { AutopayAuthResolver } from './app/services/autopay.resolver';
import { RedirectService } from './app/services/redirect.service';
import { ApigeeTokenService } from './app/services/apigee-token.service';
import { AppConfig } from './app/services/app.config';
import { SetupRouteService } from './app/services/setup-route.service';
import { EnvironmentDetailsService } from './app/services/environment-details.service';
import { CreditCardService } from './app/services/credit-card.service';
import { LoaderService } from './app/services/loader.service';
import { HttpCacheService } from './app/services/http-cache.service';
import { AuthHttpService } from './app/services/http-wrapper.service';
import { AuthGuard } from './app/services/auth.gaurd';
import { SplunkUtilityService } from './app/utilities/splunk-utility.service';
import { PaymentAuthResolver } from './app/services/paymentauth.resolver';
import { PaymentResolver } from './app/services/payment.resolver';
import { ContentProvider } from './app/common/core/content.provider';
import { SwatchResolver } from './app/services/swatch.resolver';
import { UtilsService } from './app/services/utils.service';
import { ContentService } from './app/services/content.service';
import { Loader } from '@googlemaps/js-api-loader';
import { GlobalErrorHandlerService } from './app/global/global-error-handler.service';

if (environment.production) {
  enableProdMode();
}

// const googleScriptSrc = 'https://maps.googleapis.com/maps/api/js?key=' + environment.GOOGLE_API_KEY + '&libraries=places&language=en';
// const googleScript = document.createElement('script');
// googleScript.src = googleScriptSrc;
// googleScript.type = 'text/javascript';
// googleScript.charset = 'utf-8';
// document.head.appendChild(googleScript);

const script = document.createElement('script');
// script.type = 'text/javascript';
script.charset = 'utf-8';
script.async = true;
  script.innerHTML = ` window['adrum-start-time'] = new Date().getTime();
  (function(config){
      config.appKey = '` + environment.APPD_KEY + `';
      config.adrumExtUrlHttp = 'http://cdn.appdynamics.com';
      config.adrumExtUrlHttps = 'https://cdn.appdynamics.com';
      config.beaconUrlHttp = 'http://col.eum-appdynamics.com';
      config.beaconUrlHttps = 'https://col.eum-appdynamics.com';
      config.geoResolverUrlHttp = 'https://appd-geo.geo.t-mobile.com/geo/';
      config.geoResolverUrlHttps = 'https://appd-geo.geo.t-mobile.com/geo/';
      config.xd = {enable : true};
      config.spa = {"spa2": true};
  })(window['adrum-config'] || (window['adrum-config'] = {}));

  loadScrptAsync('` + COMMON_CONSTANTS.ADRUM_JS_PATH + `');

  function loadScrptAsync(url) {
    try {
        var d = document;
        var spt = d.createElement('script');
        spt.src = url;
        spt.async = !0;
        d.getElementsByTagName('script')[0].parentNode.appendChild(spt);
    } catch (ex) {
        console.log('loadScrptAsync' + ex);
    }
}
  `;
   document.head.appendChild(script);

//    const script1 = document.createElement('script');
// // script.type = 'text/javascript';
// script1.async = true;
// script1.innerHTML = `var scriptString = '';
// if ('https:' === document.location.protocol) {
//   scriptString = ("<script src='https://cdn.appdynamics.com/adrum/adrum-latest.js' type='text/javascript' charset='UTF-8'></script>");
// } else {
//   scriptString = ("<script src='http://cdn.appdynamics.com/adrum/adrum-latest.js' type='text/javascript' charset='UTF-8'></script>");
// }`;
// document.getElementsByTagName('script')[0].parentNode.appendChild(script1);

const lifecycles = singleSpaAngular({
  bootstrapFunction: (singleSpaProps: SingleSpaProps) => {
    singleSpaPropsSubject.next(singleSpaProps);
    return bootstrapApplication(AppComponent, {
    providers: [
        getSingleSpaExtraProviders(),
        provideAnimations(),
        provideHttpClient(),
        importProvidersFrom(AppRoutingModule, CommonModule, BrowserModule, MatDatepickerModule, MatFormFieldModule, MatNativeDateModule, 
            MatInputModule, FormsModule, ReactiveFormsModule, NgbModule, NgxGpAutocompleteModule, PnpframeworkModule.forRoot(environment.PNP_CONFIG), 
            NgIdleKeepaliveModule.forRoot(), ModalModule, MatIconModule, LoggerModule.forRoot({
                serverLoggingUrl: APIGEE_TOKEN_URL.SPLUNK_LOG.path, // To Log in Server Api Calls
                level: environment.production ? NgxLoggerLevel.LOG : environment.dev ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.INFO, // ternary operators nested need to be independent statement
                serverLogLevel: environment.SPLUNK_LOGGING,
                disableConsoleLogging: true,
            }), 
            FontAwesomeModule,
            BrowserAnimationsModule),
        SwatchResolver,
        ContentProvider,
        PaymentResolver,
        PaymentAuthResolver,
        SplunkUtilityService,
        AuthGuard,
        AuthHttpService,
        HttpCacheService,
        LoaderService,
        CreditCardService,
        EnvironmentDetailsService,
        SetupRouteService,
        AppConfig,
        ApigeeTokenService,
        RedirectService,
        AutopayAuthResolver,
        SimonhandResolver,
        CurrencyPipe,
        DecimalPipe,
        DatePipe,
        KubeEnvironmentService,
        UtilsService,
        ContentService,
        {
            provide: Loader,
            useValue: new Loader({
              apiKey: 'YOUR_API_KEY',
              libraries: ['places']
            })
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initKubeEnvironment,
            deps: [KubeEnvironmentService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: contentProviderFactory,
            deps: [ContentProvider],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpCacheInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpLoggingInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: APP_BASE_HREF,
            useValue: "/",
        },
        { 
            provide: ErrorHandler, 
            useClass: GlobalErrorHandlerService 
        }
    ]
});
  },
  template: '<app-root />',
  Router,
  NgZone,
  domElementGetter: (() => {
    const el = document.getElementById('microapp-content');
    if (el) {
      return () => el;
    }
    return undefined;
  })()
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
export function contentProviderFactory(provider: ContentProvider) {
    return () => provider.load();
}

export function initKubeEnvironment(kubeEnvService: KubeEnvironmentService) {
    return () => kubeEnvService.loadConfig();
}
