import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { EnvironmentDetailsService } from 'src/app/services/environment-details.service';
import { HttpCacheService } from 'src/app/services/http-cache.service';
import { COMMON_CONSTANTS, PDL_CONSTANTS } from 'src/app/common/core/common.constants';
import { PDLService } from 'src/app/services/pdl.service';
import { environment } from '../../environments/environment';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { LoaderService } from 'src/app/services/loader.service';
@Component({
    selector: 'app-idle-error-screen',
    templateUrl: './idle-error-screen.component.html',
    styleUrls: ['./idle-error-screen.component.scss'],
    standalone: true
})
export class IdleErrorScreenComponent implements OnInit {
  returnURI: string;
  partnerName: string;
  authoringContent: any;
  enableBack = true;
  constructor(private contentService: ContentService,
    private _envSvc: EnvironmentDetailsService,
    private _cacheSvc: HttpCacheService,
    private pdlService: PDLService,
    private _loaderSvc: LoaderService) { }

  ngOnInit() {
    if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('paymentTypeIndicator'))
          && (this._cacheSvc.getFromSessionStorage('paymentTypeIndicator') === 'oneTimeLineActivationDeposit')) {
          this.enableBack = false;
          this._loaderSvc.stop('mega');
        }
    this.contentService.GetContentData().subscribe(data => {
      this.authoringContent = data.par.common_payment;
    });
    this.returnURI =  this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.RETURN_URL_SSP);

    if(!isNullOrUndefined(this.returnURI) && this.returnURI.toLowerCase().includes("inseego")) {
      this.partnerName = "Inseego"
    } else if(!isNullOrUndefined(this.returnURI) && this.returnURI.toLowerCase().includes("venicom")) {
      this.partnerName = "Venicom"
    } else {
      this.partnerName = "T-Mobile"
    }

    // Page load tagging & for IAM and partner flow
    const idpType = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE)
    const isPartner = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
    if (environment.ENABLE_TAGGING && (idpType != COMMON_CONSTANTS.OKTA_KEY || idpType == COMMON_CONSTANTS.IAM_KEY 
    || isPartner == COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE)) {
      this.generatePageViewDetails(idpType, isPartner);
    }
  }

  generatePageViewDetails(idpTypeArg: any, isPartnerArg: any): void {
    let pageDetail = {
      primaryCategory: PDL_CONSTANTS.PRIMARY_CATEGORY,
      subCategory: PDL_CONSTANTS.PLP_SUB_CATEGORY,
      pageType: PDL_CONSTANTS.PLP_ERROR_PAGE_TYPE,
      pageName: PDL_CONSTANTS.PLP_ERROR_PAGE_NAME,
      previousPageURL: this._cacheSvc.getFromSessionStorage(PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL),
      idpType: idpTypeArg,
      isPartner: isPartnerArg
    };
    this.pdlService.generatePageViewTag(pageDetail);
  }
}
