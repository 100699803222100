import { Component, OnInit } from "@angular/core";
import { ContentService } from "src/app/services/content.service";
import { EnvironmentDetailsService } from "src/app/services/environment-details.service";
import { HttpCacheService } from "src/app/services/http-cache.service";
import {
  COMMON_CONSTANTS,
  PDL_CONSTANTS,
} from "src/app/common/core/common.constants";
import { PDLService } from "src/app/services/pdl.service";
import { environment } from "../../../environments/environment";
import { isNullOrUndefined } from "src/app/utilities/helper-utils";
import { LoaderService } from "src/app/services/loader.service";
import { UtilsService } from "../../services/utils.service";
import { ClickEventType } from "src/app/common/core/common.interface";
import { PnpClientService } from "src/pnpframework/public-api";
import { ActivatedRoute } from "@angular/router";
import { FooterComponent } from "../../footer/footer.component";
import { NgIf } from "@angular/common";
import { HeaderComponent } from "../../header/header.component";
@Component({
    selector: "app-error-screen",
    templateUrl: "./error-screen.component.html",
    styleUrls: ["./error-screen.component.scss"],
    standalone: true,
    imports: [
        HeaderComponent,
        NgIf,
        FooterComponent,
    ],
})
export class ErrorScreenComponent implements OnInit {
  returnURI: string;
  authoringContent: any;
  enableBack = true;
  isPartnerFlow: boolean = false;
  isBillingPartnerFlow: boolean = false;
  pendingRequest: boolean = false;

  get isRunningInGlobalNav(): boolean {
    return this.pnpClientService.isRunningInGlobalNav();
  }

  constructor(
    private contentService: ContentService,
    private _envSvc: EnvironmentDetailsService,
    private _cacheSvc: HttpCacheService,
    private pdlService: PDLService,
    private utils: UtilsService,
    private _loaderSvc: LoaderService,
    private pnpClientService: PnpClientService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    if (
      !isNullOrUndefined(
        this._cacheSvc.getFromSessionStorage("paymentTypeIndicator")
      ) &&
      this._cacheSvc.getFromSessionStorage("paymentTypeIndicator") ===
        "oneTimeLineActivationDeposit"
    ) {
      this.enableBack = false;
      this._loaderSvc.stop("mega");
    }
    if (
      this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW
      ) === "true"
    ) {
      this.isBillingPartnerFlow = true;
    }
    if (
      this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.IS_PARTNER_BOARD
      ) === "true"
    ) {
      this.isPartnerFlow = true;
    }
    this.contentService
      .GetContentData()
      .subscribe((data) => {
        this.authoringContent = data.par.common_payment;
      });
    this.returnURI = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.RETURN_URL_SSP
    );

    // Page load tagging & for IAM and partner flow
    const idpType = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE)
    const isPartner = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
    if (environment.ENABLE_TAGGING && (idpType == COMMON_CONSTANTS.IAM_KEY 
    || isPartner == COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE)) {
      this.generatePageViewDetails(idpType, isPartner);
    }
    this.checkPendingRequest();
  }

  generatePageViewDetails(idpTypeArg: any, isPartnerArg: any): void {
    let pageDetail = {
      primaryCategory: PDL_CONSTANTS.PRIMARY_CATEGORY,
      subCategory: PDL_CONSTANTS.PLP_SUB_CATEGORY,
      pageType: PDL_CONSTANTS.PLP_ERROR_PAGE_TYPE,
      pageName: PDL_CONSTANTS.PLP_ERROR_PAGE_NAME,
      previousPageURL: this._cacheSvc.getFromSessionStorage(PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL),
      idpType: idpTypeArg,
      isPartner: isPartnerArg
    };
    this.pdlService.generatePageViewTag(pageDetail);
  }

  onBackToShop(): void {
    // analytics click tagging
    this.generateClickDetailsForErrorScreen("click", "button", ClickEventType.NAVIGATE, "Back to Billing", "")
    //this._cacheSvc.clearSessionStorage();
    this.utils.return(this.returnURI, true);
  }
  onCancel() {
    // analytics click tagging
    this.generateClickDetailsForErrorScreen(
      "click",
      "button",
      ClickEventType.NAVIGATE,
      "Cancel",
      "Error Modal"
    );
    if (
      this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW
      ) == "true"
    ) {
      this.utils.redirectToPartnerBillingApp();
    }
  }
  onClose() {
    // analytics click tagging
    this.generateClickDetailsForErrorScreen(
      "click",
      "button",
      ClickEventType.NAVIGATE,
      "Close",
      "Error Modal"
    );
    self.close();
  }

  generateClickDetailsForErrorScreen(
    actionInput: string,
    assetTypeInput: string,
    typeInput: ClickEventType,
    ctaNameInput: string,
    modalNameInput: string
  ) {
    let clickDetail = {
      action: actionInput,
      assetType: assetTypeInput,
      type: typeInput,
      ctaName: ctaNameInput,
      modalName: modalNameInput,
    };

    this.pdlService.generateClickTag(clickDetail);
  }

  checkPendingRequest() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.status && params.status === "pending") {
        this.pendingRequest = true;
      }
    });
  }
}
