import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { COMMON_CONSTANTS } from '../common/core/common.constants';
@Injectable()
export class AuthGuard  {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | Observable<boolean> {

        if (sessionStorage.getItem(COMMON_CONSTANTS.ACCESS_TOKEN_KEY)) {
            // logged in so return true
            return true;
        } else {
            // not logged in so redirect to login page
            // this.router.navigate(['/login']);
        }
    }
}
