// Auto Pay data models
export class CCAutoPayObj {
    paymentType: any;
    cardBrand: any;
    cardholderName: any;
    cardNumber: any;
    expireMonth: any;
    expireYear: any;
    cvv: any;
    billingAccountNumber: any;
    transactionType: any;
    orgId: any;
    billingAddress: BillingAddressObj;
    constructor() {
        this.billingAddress = new BillingAddressObj();
    }
}

export class BankAutoPayObj {
    bankAccountHolderName: any;
    paymentType: any;
    bankAccountNumber: any;
    bankAccountType: any;
    bankName: any;
    bankRoutingNumber: any;
    billingAccountNumber: any;
    transactionType: any;
    orgId: any;
}

// Common Models b/w cc and ACh bill pay calls

export class CustomerReference {
    customerId: any;
}


export class CustomerAddress {
    addressCore: AddressCore;
    constructor() {
        this.addressCore = new AddressCore();
    }
}
export class AddressCore {
    purposes: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    countryCode: string;
}

export class EncryptionKey {
    kty: string;
    e: string;
    n: string;
}

export class PublicKeyObj {
    billingAccount: any;
    payment: PublicPayment;
    constructor() {
        this.payment = new PublicPayment();
    }
}
export class PublicPayment {
    encryption: Encryption;
    constructor() {
        this.encryption = new Encryption();
    }
}

export class Encryption {
    encryptionFormat: any;
}

export class BillingAddressObj {
    address1: any;
    city: any;
    state: any;
    zipCode: any;
}

// Bill Pay Credit Card models
export class PaymentValidation {
    payment: CCPayment;
    customerReference: CustomerReference;
    constructor() {
        this.payment = new CCPayment();
        this.customerReference = new CustomerReference();
    }
}

export class CCPayment {
    specifications: Array<Specifications> = [];
    paymentMethodReference: PaymentMethodReference;
    termsAgreementIndicator: boolean;
    isTokenRequired?: boolean;
    paymentInstrument: CCPaymentInstrument;
    customerType?: string;
    productGroup: string;
    businessUnit: string;
    programCode: string;
    billerCode: string;
    businessSegment: string;
    orderTypes: string;
    storedPaymentIndicator: boolean;
    constructor() {
        this.paymentMethodReference = new PaymentMethodReference();
        this.paymentInstrument = new CCPaymentInstrument();
    }
}

export class Specifications {
    name: any;
    value: any;
}
export class PaymentMethodReference {
    paymentMethodCode: string;
    storePaymentMethodIndicator: boolean;
}

export class CCPaymentInstrument {
    paymentCard?: PaymentCard;
    constructor() {
        this.paymentCard = new PaymentCard();
    }
}

export class PaymentCard {
    cardNumber?: any;
    cardHolderName: string;
    cardHolderFirstName: string;
    cardHolderLastName: string;
    cvv: string;
    typeCode: string;
    expirationMonthYear: string;
    paymentCardAlias?: string;
    cardHolderAddress: CustomerAddress;
    constructor() {
        this.cardHolderAddress = new CustomerAddress();
    }
}

export class CCBillPayAliasObj {

    paymentType: any;
    cardholderName: any;
    cardNumberAlias: any;
    expireMonth: any;
    expireYear: any;
    cardBrand: any;
    billingAccountNumber: any;
    orgId: any;
    skipLegacyValidation?: any;
    transactionType: any;
    billingAddress: BillingAddressObj;
    constructor() {
        this.billingAddress = new BillingAddressObj();
    }
}

// Bank Bill Pay data models
export class BankBillPayAliasObj {

    paymentType: any;
    accountHolderName: any;
    bankAccountAlias: any;
    accountType: any;
    // bankName: any;
    routingNumber: any;
    billingAccountNumber: any;
    skipLegacyValidation?: any;
    transactionType: any;
    orgId: any;
    // billingAddress: BillingAddressObj;
    // constructor() {
    //     this.billingAddress = new BillingAddressObj();
    // }
}

export class CreateTransactionRequest{
    billingAccount: string;
    transactionType: string;
    paymentType: string;
    cardInfo: CardInfo;
    bankInfo: BankInfo;
    plannedPayments: Array<PlannedPayment>;
}

export class CardInfo{
    cardHolderName: string;
    creditCardType: string;
    cardNumberAlias: string;
    expireMonth: string;
    expireYear: string;
    cvv: string;
    addressInfo: AddressInfo;
    constructor() {
        this.addressInfo = new AddressInfo();
    }
}

export class AddressInfo{
    addressLine1: string;
    addressLine2: string;
    city: string;
    zipCode: string;
    state: string
}

export class BankInfo{
	bankAccountHolderName: string;
	bankAccountType: string;
	bankAccountAlias: string;
	bankRoutingNumber:string
}

export class PlannedPayment{
    plannedPaymentId: string;
    plannedPaymentAmount: string;
    plannedPaymentDate: string
}

export class BankPaymentValidation {
    payment: BankBillPayment;
    customerReference: CustomerReference;
    constructor() {
        this.payment = new BankBillPayment();
        this.customerReference = new CustomerReference();
    }
}


export class BankBillPayment {
    clientId: string;
    paymentMethodReference: PaymentMethodReference;
    termsAgreementIndicator: boolean;
    termsAgreementTime: string;
    isTokenRequired?: boolean;
    paymentInstrument: BankPaymentInstrument;
    customerType: string;
    productGroup: string;
    channel: string;
    businessUnit: string;
    programCode: string;
    billerCode: string;
    businessSegment: string;
    orderTypes: string;
    storedPaymentIndicator: boolean;
    constructor() {
        this.paymentMethodReference = new PaymentMethodReference();
        this.paymentInstrument = new BankPaymentInstrument();
    }
}

export class BankPaymentInstrument {
    bankPayment?: BankPayment;
    constructor() {
        this.bankPayment = new BankPayment();
    }
}

export class BankPayment {
    accountNumber?: any;
    routingNumber: any;
    bankAccountAlias?: any;
    accountType?: any;
    accountHolderNames?: any;

}

// Make a Payment Bill balance models
export class AccountInfo {
    public autoPayStatus: any;
    public delinquencyStatus: any;
    public billMediaStatus: any;
}

export class BalanceInfo {
    public billedDueAmount: any;
    public unBilledDueAmount: any;
    public totalDueAmount: any;
    public billedPastDueAmount: any;
    public unBilledPastDueAmount: any;
    public totalPastDueAmount: any;
    public billedTotalAmount: any;
    public unBilledTotalAmount: any;
    public arBalance: any;
}
export class BillInfo {
    public cycleStartDate: any;
    public cycleEndDate: any;
    public totalAmountDue: any;
    public dueDate: any;
    public type: any;
}

export class BillingAccountInfo {
    public billingAccount: string; 
    public statusInfo: AccountInfo;
    public balanceinfo: BalanceInfo;
    public billInfo: BillInfo;
    constructor() {
        this.statusInfo = new AccountInfo();
        this.balanceinfo = new BalanceInfo();
        this.billInfo = new BillInfo();
    }
}

// Make a Payment process Payment Models
// For Bank
export class BankPaymentSubmit {
    orderNumber: number | string;
    businessUnit: string;
    termsAgreementTime: string;
    programCode: string;
    clientId: string;
    operationType: string;
    termsAgreementIndicator: boolean;
    orderTypes: string;
    creationTime: string | number | Date;
    businessSegment: string;
    customerPresentIndicator: boolean;
    paymentMethodReference: PaymentMethodReference;
    chargeAmount: number;
    paymentInstrument: BankPaymentInstrument;
    productGroup: string;
    billerCode: string;
    constructor() {
        this.paymentMethodReference = new PaymentMethodReference();
        this.paymentInstrument = new BankPaymentInstrument();

    }
}

export class BankMakeAPaymentModal {
    customerReference: CustomerReference;
    payment: BankPaymentSubmit;
    constructor() {
        this.customerReference = new CustomerReference();
        this.payment = new BankPaymentSubmit();
    }
}

// for Card
export class CCPaymentSubmit {
    orderNumber: number | string;
    businessUnit: string;
    termsAgreementTime: string;
    programCode: string;
    operationType: string;
    termsAgreementIndicator: boolean;
    orderTypes: string;
    creationTime: string | number | Date;
    businessSegment: string;
    customerPresentIndicator: boolean;
    paymentMethodReference: PaymentMethodReference;
    chargeAmount: number;
    paymentInstrument: CCPaymentInstrument;
    productGroup: string;
    billerCode: string;
    constructor() {
        this.paymentMethodReference = new PaymentMethodReference();
        this.paymentInstrument = new CCPaymentInstrument();

    }
}

export class CCMakeAPaymentModal {
    customerReference: CustomerReference;
    payment: CCPaymentSubmit;
    constructor() {
        this.customerReference = new CustomerReference();
        this.payment = new CCPaymentSubmit();
    }
}

export class CCEipPaymentModal {
    billingAccount: string;
    salesInfo: SalesInfo;
    postingDetails: PostingDetails;
    payment: EipPaymentSubmit;
    recaptchaResponse: string;
    constructor() {
        this.salesInfo = new SalesInfo();
        this.postingDetails = new PostingDetails();
        this.payment = new EipPaymentSubmit();
    }
}

export class EipPaymentSubmit {
    creationTime: string | number | Date;
    termsAgreementTime: string | number | Date;
    termsAgreementIndicator: boolean;
    orderNumber: string;
    chargeAmount: number;
    paymentInstrument: EipPayInstrumentObject;
    constructor(){
        this.paymentInstrument = new EipPayInstrumentObject();
    }
}

export class EipPayInstrumentObject {
    paymentCard: EipPaymentCard;
    constructor() {
        this.paymentCard = new EipPaymentCard();
    }
}

export class EipPaymentCard {
    cardHolderFirstName: string;
    cardHolderLastName: string;
    cardHolderName: string;
    cardNumber: string;
    paymentCardAlias: string;
    cvv: string;
    expirationMonthYear: string | number | Date;
    typeCode: string;
    cardHolderAddress: CustomerAddressEip;
    constructor() {
        this.cardHolderAddress = new CustomerAddressEip();
    }
}

export class CustomerAddressEip {
    addressCore: AddressCoreEip;
    constructor() {
        this.addressCore = new AddressCoreEip();
    }
}
export class AddressCoreEip {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
}

export class PostingDetails {
    postingIdentifier: string;
    postingAmount: number;
    postingSubIdentifier: string;
    mobileNumber: string;
}
export class SalesInfo {
    createdBy: string;
}

export class DeviceLoanDetailsRequestBody{
    billingAccountNumber: string;
    equipmentFinanceId: Array<string>;
}

export class EipDeviceDetails {
    loanId: string;
    orderId: string;
    billingAccountNumber: string;
    mobileNumber: string;
    agreementNumber: string;
    effectiveStartDate: string | Date;
    totalDownPaymentAmount: number | string;
    remainingNumberOfInstallments:number | string;
    lastInstallmentAmount: number | string;
    recurringInstallmentAmount: number | string;
    totalAmountFinanced: number | any;
    balance: number | any;
    purchasedEquipment: PurchasedEquipment;
    constructor () {
        this.purchasedEquipment = new PurchasedEquipment();
    }
}

export class PurchasedEquipment {
    description: string;
    imei: string;
    salePrice: number | string;
}
export class TermsAndConditionModal {
    ban: any;
    documentReturnType: any;
    transactionId: any;
    transactionType: any;
    customerDetails: CustomerDetails;
    serviceAgreementChargeDetails: ServiceAgreementChargeDetails;
    serviceAgreementDetails = Array<ServiceAgreementDetail>();
    constructor() {
        this.customerDetails = new CustomerDetails();
        this.serviceAgreementChargeDetails = new ServiceAgreementChargeDetails();
    }
}

export class CustomerDetails {
    fullName: any;
    templateLanguage?: any;
    // contactInformationData: ContactInformationData;
    contactInformation: Array<ContactInformation> = [];
    //  constructor() {
    //      this.contactInformationData = new ContactInformationData();
    //  }
}

export class ContactInformation {
    phoneNumber: any;
    email: any;
}

export class ServiceAgreementDetail {
    msisdn: any;
    sim: any;
    imei: any;
    activationFee: any;
    depositAmount: any;
    serviceDetails: Array<ServiceADetail> = [];
    ratePlanDetails: Array<RatePlanDetails> = [];

}

export class ServiceADetail {
    serviceName: any;
    changeEffectiveDate: any;
    serviceChargeType: any;
    mrc: any;
}

export class RatePlanDetails {
    planName: any;
    planStatus: any;
    changeEffectiveDate: any;
    mrc: any;
    amountDue: any;

}

export class ServiceAgreementChargeDetails {
    regulatoryProgramFeeAmount: any;
    totalActivationFee: any;
    previousTotalMonthlyCharges: any;
    firstBillTotalMonthlyCharges: any;
    regularTotalMonthlyCharges: any;
    totalMonthlyChargesDiff: any;
    totalAmountDue: any;
    taxTreatment: any;
    digitsIndicator: any;

}

export class PatchCallObject {
    transactionId: any;
    transactionType: any;
    documentDetails: any;
}

export class GeneralTermsModal {
    ban: any;
    transactionId: any;
    customerDetails: CustomerDetails;
    constructor() {
        this.customerDetails = new CustomerDetails();

    }
    documentReturnType: any;
}

// schedule payment models
// Bank
export class BankScheduleModal {
    phoneNumber: string;
    payment: BankScheduleSubmit;
    customerReference: CustomerReference;
    constructor() {
        this.payment = new BankScheduleSubmit();
        this.customerReference = new CustomerReference();
    }
}

export class BankScheduleSubmit {
    paymentMethodReference: SchPaymentMethodReference;
    paymentInstrument: BankPaymentInstrument;
    scheduledPayment: ScheduledPayment;
    scheduledpayTermsAgreementIndicator: boolean;
    scheduledpayTermsAgreementTime: any;
    productGroup: string;
    businessUnit: string;
    programCode: string;
    billerCode: string;
    businessSegment: string;
    orderTypes: string;
    constructor() {
        this.paymentMethodReference = new SchPaymentMethodReference();
        this.paymentInstrument = new BankPaymentInstrument();
        this.scheduledPayment = new ScheduledPayment();
        //this.paymentArrangements[0] = new PaymentArrangement();
    }
}


export class SalesContext {
    emailAddress: any;
}

export class SalesChannelReference {
    salesChannelId: any;
}


// for Card
export class CCScheduleModal {
    phoneNumber: string;
    salesContext: SalesContext;
    salesChannelReference: SalesChannelReference;
    payment: CCScheduleSubmit;
    customerReference: CustomerReference;
    constructor() {
        this.salesContext = new SalesContext();
        this.salesChannelReference = new SalesChannelReference();
        this.payment = new CCScheduleSubmit();
        this.customerReference = new CustomerReference();
    }
}

export class CCScheduleSubmit {
    paymentMethodReference: SchPaymentMethodReference;
    scheduledpayTermsAgreementIndicator: boolean;
    scheduledpayTermsAgreementTime: any;
    paymentInstrument: CCPaymentInstrument;
    scheduledPayment: ScheduledPayment;
    businessUnit: string;
    programCode: string;
    billerCode: string;
    businessSegment: string;
    orderTypes: string;
    productGroup: string;
    constructor() {
        this.paymentMethodReference = new SchPaymentMethodReference();
        this.paymentInstrument = new CCPaymentInstrument();
        this.scheduledPayment = new ScheduledPayment();
    }
}
export class PaymentArrangement {
    paymentArrangementCategory: string;
    // paymentArrangementId: string;
    paymentArrangementType: string;
    requestPaymentArrangement: any;
    plannedPayments: any;
   // actionCode: string;

}
export class SchPaymentMethodReference {
    paymentMethodCode: any;
}

export class ScheduledPayment {
    schPmtRunDate: any;
    schPmtDueDate: any;
    schPmtDueAmt: any;

}

export class UpdateCrOrderPaymentModel {
    billingAccount: any;
    logonId: any;
    transRefNum: any;
    emailNotification: EmailNotification;
    scheduledPayment: ScheduledPaymentInfo;
    status: any;
    transactionDesc: any;
    chargeAmount?: any;
    legalDocuments: Array<LegalDocument> = [];
    bankAccountAlias?: any;
    cardNumberAlias?: any;
    constructor() {
        this.emailNotification = new EmailNotification();
        this.scheduledPayment = new ScheduledPaymentInfo();
    }
}

export class EmailNotification{
    firstName: string;
    emailId: string;
}

export class ScheduledPaymentInfo{
    scheduledPaymentId: string;
    scheduledPaymentDate: string;
}

export class UpdateCrOrderSchPaymentModel {

    changeReqOrderId: any;
    changeReqOrderItmId: any;
    cardNumber?: any;
    accountNumber?: any;
    ban: any;
    clientId: any;
    email: any;
    status: any;
    desc: any;
    orgId: any;
    scheduledPaymentId: any;
    scheduledPmtDueDate: any;
    chargeAmount: any;
    legalDocuments: Array<LegalDocument> = [];
    bankAccountAlias?: any;
    cardNumberAlias?: any;
}

export class LegalDocument {
    docId: any;
    docType: any;
    acceptedDateTime: any;
}

export class RequestPayLoadAutoPay {
    logonId: any;
    email: any;
    changeRequestOrderId: any;
    payType?: any;
    documentDetails: Array<DocumentDetailAutoPay> = [];
}

export class DocumentDetailAutoPay {

    documentId: any;
    documentType: any;
    acceptedTimeStamp: any;

}

export class AutoPaymentProfile {
    payment: PaymentObj;
    customerReference: CustomerReference;
    constructor() {
        this.payment = new PaymentObj();
        this.customerReference = new CustomerReference();
    }
}

export class PaymentObj {
    businessUnit: any;
    programCode: any;
    billerCode: any;
    businessSegment: any;
}

export class CancelAutoPayCCModal {
    payment: CancelPaymentCardObj;
    customerReference: CustomerReference;
    constructor() {
        this.payment = new CancelPaymentCardObj();
        this.customerReference = new CustomerReference();
    }
}

export class CancelAutoPayBankModal {
    payment: CancelPaymentBankObj;
    customerReference: CustomerReference;
    constructor() {
        this.payment = new CancelPaymentBankObj();
        this.customerReference = new CustomerReference();
    }
}

export class CancelPaymentCardObj {
    paymentMethodReference: SchPaymentMethodReference;
    autopayTermsAgreementIndicator: any;
    autopayTermsAgreementTime: any;
    businessUnit: any;
    programCode: any;
    billerCode: any;
    businessSegment: any;
    productGroup: string;
    orderNumber: number | string;
    paymentInstrument: PayInstrumentCardObj;
    autoPay: DelAutoPayObj;
    constructor() {
        this.paymentMethodReference = new SchPaymentMethodReference();
        this.paymentInstrument = new PayInstrumentCardObj();
        this.autoPay = new DelAutoPayObj();
    }
}

export class CancelPaymentBankObj {
    paymentMethodReference: SchPaymentMethodReference;
    autopayTermsAgreementIndicator: any;
    autopayTermsAgreementTime: any;
    businessUnit: any;
    programCode: any;
    billerCode: any;
    businessSegment: any;
    paymentInstrument: PayInstrumentBankObj;
    autoPay: DelAutoPayObj;
    orderNumber: number | string;
    productGroup: string;
    constructor() {
        this.paymentMethodReference = new SchPaymentMethodReference();
        this.paymentInstrument = new PayInstrumentBankObj();
        this.autoPay = new DelAutoPayObj();
    }
}


export class PayInstrumentCardObj {
    paymentCard: PaymentCardObj;
    constructor() {
        this.paymentCard = new PaymentCardObj();
    }
}
export class DelAutoPayObj {
    discountTxnId: any;
    unenrollReasonCode: any;
}

export class PaymentCardObj {
    paymentCardAlias: any;
}

export class PayInstrumentBankObj {
    bankPayment: PaymentBankObj;
    constructor() {
        this.bankPayment = new PaymentBankObj();
    }
}

export class PaymentBankObj {
    bankAccountAlias: any;
}

export class BankEnrlMnjAutopayModal {
    payment: BankEnrlMnjApObj;
    customerReference: CustomerReference;
    constructor() {
        this.payment = new BankEnrlMnjApObj();
        this.customerReference = new CustomerReference();
    }
}
export class BankEnrlMnjApObj {
    specifications?: Array<Specifications> = [];
    paymentMethodReference: SchPaymentMethodReference;
    autopayTermsAgreementIndicator: any;
    autopayTermsAgreementTime: any;
    paymentInstrument: BankPaymentInstrument;
    autoPay: AutoPayObj;
    businessUnit: string;
    programCode: string;
    billerCode: string;
    businessSegment: string;
    orderTypes: string;
    productGroup: string;
    orderNumber: string;
    constructor() {
        this.paymentMethodReference = new SchPaymentMethodReference();
        this.paymentInstrument = new BankPaymentInstrument();
        this.autoPay = new AutoPayObj();
    }
}

export class AutoPayObj {
    autoPayAcceptance: any;
    assistingDept: any;
}


export class CardEnrlMnjAutopayModal {
    payment: CardEnrlMnjApObj;
    customerReference: CustomerReference;
    constructor() {
        this.payment = new CardEnrlMnjApObj();
        this.customerReference = new CustomerReference();
    }
}
export class CardEnrlMnjApObj {
    specifications?: Array<Specifications> = [];
    paymentMethodReference: SchPaymentMethodReference;
    autopayTermsAgreementIndicator: any;
    autopayTermsAgreementTime: any;
    paymentInstrument: CCPaymentInstrument;
    autoPay: AutoPayObj;
    businessUnit: string;
    programCode: string;
    billerCode: string;
    businessSegment: string;
    orderTypes: string;
    productGroup: string;
    orderNumber: string;
    constructor() {
        this.paymentMethodReference = new SchPaymentMethodReference();
        this.paymentInstrument = new CCPaymentInstrument();
        this.autoPay = new AutoPayObj();
    }
}

export class CCPaymentArrangementModel {
    phoneNumber: string;
    salesContext: SalesContext;
    salesChannelReference: SalesChannelReference;
    payment: CCPaymentArrangementSubmit;
    customerReference: CustomerReference;
    constructor() {
        this.salesContext = new SalesContext();
        this.salesChannelReference = new SalesChannelReference();
        this.payment = new CCPaymentArrangementSubmit();
        this.customerReference = new CustomerReference();
    }
}

export class BankPaymentArrangementModel {
    phoneNumber: string;
    payment: BankPaymentArrangementSubmit;
    customerReference: CustomerReference;
    constructor() {
        this.payment = new BankPaymentArrangementSubmit();
        this.customerReference = new CustomerReference();
    }
}


export class BankPaymentArrangementSubmit {
    paymentMethodReference: SchPaymentMethodReference;
    paymentInstrument: BankPaymentInstrument;
    scheduledpayTermsAgreementIndicator: boolean;
    scheduledpayTermsAgreementTime: any;
    productGroup: string;
    paymentArrangementAction: string;
    businessUnit: string;
    programCode: string;
    billerCode: string;
    businessSegment: string;
    orderTypes: string;
    paymentArrangements: Array<PaymentArrangement> = [];
    paTermsAgreementIndicator: boolean;
    paTermsAgreementTime: any;
    constructor() {
        this.paymentMethodReference = new SchPaymentMethodReference();
        this.paymentInstrument = new BankPaymentInstrument();
    }
}


export class CCPaymentArrangementSubmit {
    paymentMethodReference: SchPaymentMethodReference;
    scheduledpayTermsAgreementIndicator: boolean;
    scheduledpayTermsAgreementTime: any;
    paymentInstrument: CCPaymentInstrument;
    businessUnit: string;
    programCode: string;
    billerCode: string;
    businessSegment: string;
    orderTypes: string;
    productGroup: string;
    paymentArrangementAction: string;
    paymentArrangements: Array<PaymentArrangement> = [];
    paTermsAgreementIndicator: boolean;
    paTermsAgreementTime: any;
    constructor() {
        this.paymentMethodReference = new SchPaymentMethodReference();
        this.paymentInstrument = new CCPaymentInstrument();
    }
}


export class HardGoodsPaymntModel {
    applicationId: string;
    payment: CCHardGoodsPaymntSubmit;
    customerReference: CustomerReference;
    salesChannelReference: SalesChannelReference;
    constructor() {
        this.payment = new CCHardGoodsPaymntSubmit();
        this.customerReference = new CustomerReference();
        this.salesChannelReference = new SalesChannelReference();
    }
}

export class CCHardGoodsPaymntSubmit {
    paymentMethodReference: SchPaymentMethodReference;
    scheduledpayTermsAgreementIndicator: boolean;
    scheduledpayTermsAgreementTime: any;
    paymentInstrument: CCPaymentInstrumentHardGoods;
    accountTransactionId: string;
    businessUnit: string;
    programCode: string;
    billerCode: string;
    businessSegment: string;
    orderTypes: string;
    orderNumber: string;
    productGroup: string;
    fraudCheckFlag: boolean;
    creationTime: string | number | Date;
    constructor() {
        this.paymentMethodReference = new SchPaymentMethodReference();
        this.paymentInstrument = new CCPaymentInstrumentHardGoods();
    }
}

export class AutopayHardGoods {
    assistingDept: string;
    autoPayAcceptance: string;
}

export class CCPaymentInstrumentHardGoods {
    paymentCard?: PaymentCardHardGoods;
    constructor() {
        this.paymentCard = new PaymentCardHardGoods();
    }
}

export class PaymentCardHardGoods {
    cardNumber?: any;
    cardHolderName: string;
    cardHolderFirstName: string;
    cardHolderLastName: string;
    cvv: string;
    typeCode: string;
    expirationMonthYear: string;
    cardHolderAddress: CustomerAddress;
    constructor() {
        this.cardHolderAddress = new CustomerAddress();
    }

}
export enum MakePaymentSubmissionFlow {
    DEFAULT,
    INSTALLMENT_NONEDIT,
    INSTALLMENT_EDIT,
    HARDGOODS
}
export class SimOnHand {
    ban: number | string;
    orgId: string;
    firstName: string;
    lastName: string;
    email: string;
    paymentTypeIndicator: string;
    role: string[];
    amount: number;
    cartId: number;
    accountTransactionId: string;
    eid: string;
    ds: string;
    ts: number;
    at: string;
    id: string;
    returnURI: string;
}

// for Card in Sim on hand
export class PaymentSubmitSimOnHand {
    orderNumber: number | string;
    accountTransactionId: number | string;
    businessUnit: string;
    termsAgreementTime: string;
    programCode: string;
    operationType: string;
    termsAgreementIndicator: boolean;
    orderTypes: string;
    creationTime: string | number | Date;
    businessSegment: string;
    customerPresentIndicator: boolean;
    paymentMethodReference: PaymentMethodReference;
    chargeAmount: number;
    paymentInstrument: CCPaymentInstrument;
    productGroup: string;
    billerCode: string;
    constructor() {
        this.paymentMethodReference = new PaymentMethodReference();
        this.paymentInstrument = new CCPaymentInstrument();

    }
}

export class SimOnHandPaymentModal {
    customerReference: CustomerReference;
    payment: PaymentSubmitSimOnHand;
    constructor() {
        this.customerReference = new CustomerReference();
        this.payment = new PaymentSubmitSimOnHand();
    }
}


export class UserLineServiceRequest {
    ban: number | string;
    orgId: string;
    msisdns: string[];
    page: string;
    pageSize: number;
}
export class accountDetailsRequestData {
    billingAccount : string;
    msisdn : string;
}