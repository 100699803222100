import { endpointUrl } from 'src/single-spa/asset-url';

export const COMMON_CONSTANTS = {
  UNDEFINED_KEY: 'undefined',
  UNIDENTIFIED_KEY: 'Unidentified',
  CONTENT_CACHE_KEY: 'content',
  CHARACTER_REGEX: '^[a-zA-ZñÑ .-]*$',
  NUMBER_REGEX: '^[0-9]*$',
  CVV_REGEX: '^(?!000$)(?!0000$)\\d{3,4}$',
  VARCHAR_REGEX: '^[a-zA-Z0-9 .-]*$',
  BAN_NUMBER_KEY: 'ban',
  BAN_FIRST_NAME_KEY: 'firstName',
  BAN_LAST_NAME_KEY: 'lastName ',
  ORG_ID_KEY: 'OrgID',
  ORG_ID_GN_KEY: 'orgId',
  ORG_ID_TEXT: '{orgId}',
  ORDER_ID_PARAM: '?orderId=',
  TRANSACTION_TYPE: 'BAN_AUTOPAY_ENROLL',
  UPDATE_TRANSACTION_TYPE: 'BAN_AUTOPAY_UPDATE_ENROLL',
  CANCEL_TRANSACTION_TYPE: 'BAN_AUTOPAY_UNENROLL',
  BILL_PAY_FLAG: 'billPayflag',
  RETURN_URL_SSP: 'returnURI',
  CANCEL_AUTO_PAY_FLAG: 'CancelAutoPayFlag',
  EUI_CODE: 'code',
  IS_HANDSHAKE_COMPLETE: 'isHandshakeComplete',
  EUI_TOKEN_DATA: 'euiTokenData',
  SOH_ACC_TRANS_ID: 'sohOrderId',
  SOH_CART_ID: 'sohCartId',
  CHANGE_REQ_ID: 'chgreqOrderid',
  TRANS_REF_NUM:'transRefNum',
  TRANSACTION_ID: 'transactionId',
  ALL_DENIAL_TYPE: 'ALL',
  ACTIVITY_ID: 'act123',
  TEST_CLIENT: 'test',
  APP_TEXT: 'APP1',
  CARE_TEXT: 'CARE',
  DNS_WINDOW: 'DNSWindow',
  POST: 'post',
  TFB: 'TFB',
  NO_SELL_WEB: 'NoSell_Web',
  ACCOUNT_HUB: 'AHUB',
  DNS: '/dns',
  BRAND_TEXT: 'Brand',
  SITE_TEXT: 'Site',
  ORIGINURL_TEXT: 'Origin_URL',
  TEXT: 'hidden',
  DNS_STYLE: 'width=800,height=800,resizeable,scrollbars',
  DIGIT_TWELEVE: '12',
  BILLING_ACCOUNT: '{billing-account}',
  PAYMENTMETHODCODE: 'paymentMethodCode',
  PRODUCTGROUP: 'productGroup',
  BAN_PLACEHOLDER: '{{ban}}',
  TRANSACTION_PLACEHOLDER: '{transaction-id}',
  DIFFTIME_KEY: 'diffTime-key',
  TRANSACTIONID: 'transactionID',
  UNIQUEID_FOR_APIS: 'UNIQUEID_FOR_APIS',
  B2B_REQUESTID: 'b2b-requestid',
  B2B_REQUEST_ID: 'b2b-request-id',
  REQUEST_DATE: 'requestDate',
  CURRENT_PST_DATE: 'currentDate',
  DIGIT_ONE: '1',
  DIGIT_HUNDRED: '100',
  ELIGIBILITY_V1: 'elibilityCriteria',
  ELIGIBILITY_V2: 'eligibilityCriteria',
  WORKFLOW_ID_LINE_DEPOSIT:'LINE-ACTIVATION-DEPOSIT',
  AMOUNT_KEY: 'amount',
  // REQUEST_BODY:"body",
  // REQUEST_HEADER:"header",
  // RESPONSE_BODY:"body",
  // RESPONSE_HEADER:"header",
  BOOLEAN_PROPERTIES: {
    TRUE: 'true',
    FALSE: 'false'
  },
  GOOGLE_ADDRESS_PROPERTIES: {
    LOCALITY: 'locality',
    ADMINISTRATIVE_AREA_LEVEL_1: 'administrative_area_level_1',
    POSTAL_CODE: 'postal_code',
    STREET_NUMBER: 'street_number',
    ROUTE: 'route'
  },
  HEADER_PROPERTIES: {
    ACTIVITY_ID_KEY: 'activity-id',
    AUTHORIZATION_KEY: 'Authorization',
    X_AUTH_ORIGINATOR_KEY: 'x-auth-originator',
    EID: 'eid',
    B2B_USER_ID: 'b2b-user-id',
    B2B_USERID: 'b2b-userId',
    BASIC_KEY: 'Basic ',
    ACCEPT_KEY: 'Accept',
    APPLICATION_JSON_KEY: 'application/json',
    BEARER_KEY: 'Bearer ',
    SENDER_ID_KEY: 'TFB',
    CHANNEL_ID_KEY: 'WEB',
    APPLICATION_ID_KEY: 'DASH',
    SALES_VALIDATION_CHANNEL_ID_KEY: 'TSL',
    B2B_CLIENT_KEY: 'B2b-client',
    B2B_CLIENT_VALUE: 'TEST',
    B2B_ORG_KEY: 'B2b-org',
    CONTENT_TYPE_KEY: 'Content-Type',
    AUTOPAY_B2B_CLIENT: 'TFB-PAYMENT-APP',
    B2B_USERID_KEY: 'B2b-userId',
    B2B_CCID_KEY: 'B2b-ccid',
    ENTITLEMENT_ORG: '2-6VGGJB',
    B2B_CLIENT: 'TFB-PAYMENT-APP',
    B2B_CLIENT_AHUB: 'TFB-PAYMENT-APP',
    B2B_WEB_KEY: 'B2B_WEB',
    AP_CHANNEL_ID: 'WEB',
    TFB_APPLICATION_ID_KEY: 'TFB',
    PARTNER_APPLICATION_ID_KEY: 'TFB-EXT',
    NO_CACHE: 'no-cache',
    OKTAAPPLICATION_JSON_KEY: 'application/x-www-form-urlencoded',
    PID_TEXT: 'queryParam_PID'
  },
  AUTOPAY_PROPERTIES: {
    ENROLL_B2B_USERID_VALUE: 'U-17f4d036-68d4-4f94-81c3-7fb35d9f60b8',
    ENROLL_B2B_CCID_VALUE: '2-6VGGJB',
    ENROLL_ACTIVITY_ID_VALUE: 'act123',
    ERR_40051: 'ATWRK_AUTOPAY_ERR_40051',
    ERR_SERVER: 'Server',
    STATUS_TEXT: 'status',
    STATUS_SUCCESS: 'SUCCESS',
    BAN: '[BAN]',
    BANNAME: '[BANNAME]'
  },
  PUBLICKEY_PROPERTIES: {
    APPLICATION_ID: '45454',
    ENCRYPTION_FORMAT: 'JWK'
  },
  IS_EDIT_FLOW: 'isEditFlow',
  EDIT: 'edit',
  PAYMENT_TOKEN_EXPIRY_KEY: 'payment-token-expiry',
  PAYMENT_TOKEN_KEY: 'payment-token',
  ACCESS_TOKEN_EXPIRY_KEY: 'access-token-expiry',
  ACCESS_TOKEN_KEY: 'access-token',
  ACCESS_TOKEN_ID: "id_token",
  ORDER_TOKEN_KEY: 'order-token',
  AUTHORIZATION_CODE: 'authorization_code',
  OKTA_ACCESS_TOKEN_KEY: 'oktaAccessToken',
  AZURE_ACCESS_TOKEN_KEY: 'azureAccessToken',
  OKTA_AUTH_CODE_KEY: 'oktaAuthCode',
  OKTA_USER_PROFILE: 'okta_userProfile',
  AZURE_USER_PROFILE: 'azure_userProfile',
  PID_KEY: 'pid',
  // PID Flow Flag
  ENABLE_PID_FLOW: true,
  AUTH_TYPE: 'IDPType',
  DEFAUTH_TYPE: 'authType',
  IAM_KEY: 'iam',
  AZURE_KEY: 'azure',
  OKTA_KEY: 'okta',
  SOH_KEY: 'soh',
  TOKEN_KEY: 'token',
  SERVICE_TERMS_DATA: 'serviceTermsData',
  APSERVICE_TERMS_DATA: 'autoPayTermsData',
  TERMS_CONSENT: 'terms-consent',
  ESAY_PAY_DATA: 'easyPayData',
  FLOW_KEY: 'Flow',
  ENROLL_FLOW: 'enroll',
  SOH_FLOW: 'soh',
  BANK_TXT: 'Bank',
  CARD_TXT: 'Card',
  TYPE_ACH: 'ach',
  TYPE_CREDIT_CARD: 'creditCard',
  TYPE_DEBIT_CARD: 'debitCard',
  TYPE_CREDITDEBIT_MAYRELEASE_CARD: 'managecard',
  TYPE_CREDIT: 'credit',
  TYPE_DEBIT: 'debit',
  MANAGE_FLOW: 'manage',
  CANCEL_FLOW: 'cancel',
  PAYMENT_TYPE: 'paymentType',
  STATUS_FAILURE: 'FAILURE',
  BILL_PAY: 'billPay',
  AUTOPAY_KEY: 'autoPay',
  MAKEPAY_KEY: 'makepay',
  SOH_LINE_DEPOSIT_KEY: 'sohLineDeposit',
  PAYMENT_TYPE_DETAILS: 'paymentTypeDetails',
  PAYMENT_TYPE_TXT: 'PaymentType',
  CHECKING_ACCOUNT: 'Checking Account',
  ACCOUNT_NUMBER: 'accountNumber',
  ACCOUNT_NUMBER_VAL: 'accountNumberValue',
  ROUTING_NUMBER:'routingNumber',
  CREDITCARD_NUMBER: 'creditCardNumber',
  CREDITCARD_CVV_NUMBER: 'creditCardCvvNumber',
  SELECTED_ACCOUNT_TYPE: 'selectedAccntType',
  ACH_BILLPAYALIAS_DATA: 'achBillPayAliasData',
  BANKNAME_TXT: 'bankName',
  CARD_DETAIL_OBJECT: 'cardDetailObject',
  SAVED_PAYMNT_OBJECT: 'savdPaymntObject',
  CREDIT_CARD: 'Credit Card',
  EMAIL_ID: 'emailId',
  USER_FIRST_NAME: 'firstName',
  USER_LAST_NAME: 'lastName',
  USER_EMAIL: 'email',
  USER_ID: 'userId',
  BALANCE_TOKEN: 'balance-token',
  CC_NUMBER: 'ccNumber',
  AUTO_DATE: 'autoDate',
  TWO: 2,
  NINE: 9,
  ZERO: 0,
  ONE: 1,
  TWENTYFOUR: 24,
  HUNDRED: 100,
  TWENTY_FIVE_THOUSAND: 25000,
  FIVE_HUNDRED: '500',
  FORMATED_ZERO: '0.00',
  SERVER_ERROR: 'ServerError',
  PAYMENT_AMOUNT: 'paymentAmount',
  PAYMENT_DATE: 'paymentDate',
  NONE: 'none',
  BLOCK: 'block',
  AUTO: 'auto',
  MASKING_CHARACTERS: '****',
  ADRUM_JS_PATH: '//cdn.appdynamics.com/adrum/adrum-4.5.2.0.js',
  HANDSHAKE_ERROR_MSG: 'System is down, Please check back later',
  THANKU_PAGE: 'thankuPage',
  IS_AUTOPAYiNSTRUMENT_FLAG: 'replaceAutoPayInstrument',
  INSTYPE_REGULAR: 'REGULAR',
  IS_PARTNER_BOARD: 'isPartnerOnboard',
  IS_BILLING_PARTNER_FLOW: 'isBillingPartnerFlow',
  QUERY_PARAM_AT: 'at',
  QUERY_PARAM_EPID: 'epid',
  QUERY_PARAM_DS: 'ds',
  QUERY_PARAM_TS: 'ts',
  QUERY_PARAM_ASYNC: 'async',
  HARDGOOD_AUTH_ASYNC_ACC_TRANS_ID: 'hga_async_acc_trans_id',
  PARTNER_HARDGOODS: 'hardGoodAuth',
  TOTAL_LINE_IN_BAN: 5000,
  SPLUNK_CLIENTSIDE_ERROR: 'clientSide Javascript Error',
  PST_TIME_ZONE : 'America/Los_Angeles',
  PAYMENT_METHOD_CODE_CREDIT : 'CC',
  PAYMENT_METHOD_CODE_DEBIT : 'DC',
  SPLUNK_TRACKER_TYPES : {
    PA_ATTEMPTED : 'PA_ATTEMPTED',
    OTHER_AMOUNT_ATTEMPTED : 'OTHER_AMOUNT_ATTEMPTED',
    TOTAL_DUE_ATTEMPTED: 'TOTAL_DUE_ATTEMPTED',
    PAYMENT_AMOUNT_ATTEMPTED: 'PAYMENT_AMOUNT_ATTEMPTED'
  },
  IGNORE_MASKING :[],
  MASKING_INFORMATION : [
    {name:'x-authorization', type:'total'},
    {name:'Authorization', type:'total'},
    {name:'authorization', type:'total'},
    {name:'x-auth-originator', type:'total'},
    {name:'recaptcha-auth', type:'last4'},
    {name:'b2b-user-id', type:'last4'},
    {name:'channel-id', type:'last2'},
    {name:'application-id', type:'last2'},
    {name:'eid', type:'last4'},
    {name:'ds', type:'last4'},
    {name:'accountHolderLastName', type:'last2'},
    {name:'accountHolderFirstName', type:'last2'},
    {name:'accountNumber', type:'last4'},
    {name:'bankAccountAlias', type:'last4'},
    {name:'routingNumber', type:'last4'},
    {name:'cardHolderLastName', type:'last2'},
    {name:'cardHolderFirstName', type:'last2'},
    {name:'cardNumber', type:'last4'},
    {name:'cvv', type:'total'},
    {name:'cardHolderName', type:'last2'},
    {name:'paymentCardAlias', type:'last4'},
    {name:'addressLine1', type:'last4'},
    {name:'addressLine2', type:'last4'},
    {name:'zipCode', type:'last3'},
    {name:'accountHolderNames', type:'last2'},
    {name:'cvvResponseCode', type:'last4'},
    {name:'recaptchaResponse', type:'total'},
    {name:'customerId', type:'last4'},
    {name:'salesChannelId', type:'last4'},
    {name:'emailAddress', type:'last4'},
    {name:'phoneNumber', type:'last4'},
    {name:'expirationMonthYear', type:'total'},
    {name:'expireYear', type:'total'},
    {name:'expireMonth', type:'total'},
    {name:'ntid', type:'last4'},
    {name:'accountHolderName', type:'last4'},
    {name:'cardNumberAlias', type:'last4'},
    {name:'logonId', type:'last4'},
    {name:'cardholderName', type:'last2'},
    {name:'billingAccountNumber', type:'last3'},
    {name:'accountNumber', type:'last3'},
    {name:'ban', type:'last3'},
    {name:'email', type:'last4'},
    {name:'firstName', type:'last2'},
    {name:'lastName', type:'last2'},
    {name:'billingAccount', type:'last4'},
    {name:'financialAccountNumber', type:'last4'},
    {name:'address1', type:'last4'},
    {name:'city', type:'last4'},
    {name:'fullName', type:'last4'},
    {name:'contractBan', type:'last4'},
    {name:'financialAccountNumber', type:'last4'},
    {name:'emailId', type:'last4'},
    {name:'bankAccountHolderName', type:'last2'},
    {name:'bankRoutingNumber', type:'last4'},
    {name:'redirectUrl', type:'last8'},
    {name:'bankAccountNumber', type:'last4'},
    {name:'creditCardNo', type:'last4'},
    {name:'creditCardExpirationDate', type:'last4'},
    {name:'tokenizedCreditCardNo', type:'last4'},
    {name:'tokenizedCreditCardExpirationDate', type:'last4'},
    {name:'n', type:'last4'},
    {name:'binaryContent', type:'last8'},
    {name:'billingAccounts', type:'last4'},
  ],
  MASKING_TYPE:{
    LAST_4:"last4",
    COMPLETE:"comp",
    TOTAL:'total',
    LAST_2:'last2',
    LAST_3:'last3',
    LAST_8:'last8',
  }
};

export const CCTYPE_CONSTANTS: any = {
  CONSUMER_KEY: 'consumerKEY',
  CONSUMER_SECRET: 'consumerSecret',
  MASTER_CARD_URL: 'masterCardURL',
  AUTH_TOKEN_URL: 'authTokenURL',
  ACCESS_TOKEN: 'access_token',
  CARD_TYPE: 'cardType',
  CARD_CATEGORY: 'issuerCardCategory',
  CARD_LENGTH: 'cardLength',
  SECURITY_CODE_LENGTH: 'securityCodeLength',
};

export const API_PARAMS_CONST: any = {
  CONTENT_HEADER_TYPE: 'application/x-www-form-urlencoded;charset=UTF-8',
  B2B_ORG_ID: sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY),
  BAN_NUMBER: sessionStorage.getItem(COMMON_CONSTANTS.BAN_NUMBER_KEY),
  B2B_CLIENT: 'TFB-PAYMENT-APP',
  EUI_CLIENT_ID: 'paymentapp'
};


export const CACHE_IGNORE_LIST: any = [
  { route: '/v1/oauth2/accesstoken?grant_type=client_credentials' },
  { route: '/oauth2/' }
];

export const CLONE_URL_IGNORE_LIST: any = [
  { route: '/v1/oauth2/accesstoken?grant_type=client_credentials' },
  { route: 'payments/v1/cardtypevalidation' },
  { route: '.json' },
  { route: '/billing-experience/v1/bills/calculate-updated-balance' },
  { route: '/payments/ecommerce/v3/processpayments' },
  { route: '/b2b-experience/document-service/v1/documents/service-agreement' },
  { route: '/b2b-experience/document-service/v1/documents' },
  { route: '/cardtypeapi/v1/oauth2/accesstoken?grant_type=client_credentials' },
  { route: '/cardtypevalidation' },
  { route: '/balanceapi/v1/oauth2/accesstoken?grant_type=client_credentials' },
  { route: '/wcs/resources/store/11151/user/login' },
  { route: '/paymentsapi/v1/oauth2/accesstoken?grant_type=client_credentials' },
  { route: '/schedule/b2b-experience/payment-service/v1/billing-accounts' },
  { route: '/b2b-experience/v1/billing-accounts/' },
  { route: '/b2b-experience/payment-service/v1/billing-accounts' },
  { route: '/b2b-experience/payment-service/v1/billing-accounts/' },
  { route: '/b2b-experience/v1/documents/service-agreement' },
  { route: '/b2b-experience/v1/documents' },
  { route: '/b2b-experience/v1/entitlements/groups/5/mobile-numbers-detail' },
  { route: '/b2b-experience/v1/entitlements/' },
  { route: '/tmobile/cq/loggingapi' },
  { route: '/oauth2/' },
  {route:  '/b2b-experience/payment/auth/v4/handshake/payment' },
  {route:  '/b2b-experience/payment/auth/v4/handshake/anonymous' },
  {route:  '/b2b-experience/bpm/auth/v4/handshake/payment' }

];

export const SPECIAL_HEADER_HANDLE_LIST: any = [
  { route: '/b2b/payment/v4/billing-accounts/card-type-validation' },
  { route: '/b2b/payment/v4/billing-accounts/aba-routing-info' },
  { route: '/b2b/payment/v4/billing-accounts/public-key' },
  { route: '/b2b/payment/v4/billing-accounts/autopay-enroll-eligibility' },
  { route: '/b2b/payment/v4/billing-accounts/scheduled-payment-info' },
  { route: '/b2b/payment/v4/billing-accounts/balance' },
  { route: '/b2b/payment/v4/billing-accounts/schedule-payment-eligibility' },
  { route: '/b2b/payment/v4/billing-accounts/payment-arrangement/eligibility' },
  { route: '/b2b/payment/v4/billing-accounts/payment-arrangement/search' },
  { route: '/b2b/payment/v4/billing-accounts/documents'},
  { route: '/b2b/payment/v4/billing-accounts/documents/easy-pay-agreement'},
  { route: '/b2b/payment/v4/transactions' },
  { route: '/b2b/payment/v4/billing-accounts/autopay-info' },
  { route: '/b2b/payment/v4/billing-accounts/account-details' },
  { route: '/b2b/payment/v4/billing-accounts/eip-payment'},
  { route: '/b2b/billing/v4/billing-accounts/finance-agreements' },
  { route: '/tfb/v4/entitlements/management/user-activities' },
  { route: '/tfb/v4/entitlements/management/users/.*/roles' },
  { route: '/tfb/v4/entitlements/core/billing-accounts/mobile-numbers-details' }
];

export const LOADER_IGNORE_LIST: any = [
  { route: '/v1/oauth2/accesstoken?grant_type=client_credentials' },
  { route: 'payments/v1/cardtypevalidation' },
  { route: '/cardtypevalidation' }
];

export const IMAGES_URL: any = {
  IPHONE: './assets/tfb-payment/images/iphone.png',
  ICON: './assets/tfb-payment/images/icons/icon-review-item.png',
  ICON1: './assets/tfb-payment/images/icons-items.png',
  EDIT_ICON: './assets/tfb-payment/images/icons/icon-edit.png',
  SUCCESS_ICON: './assets/tfb-payment/images/icons/icon-success-sign.png',
  REFRESH_ICON: './assets/tfb-payment/images/icons/refreshDollarIcon.PNG'
};


export const TOKEN_DEFAULTS: any = {
  RETRY_INTERVAL: 120000,
  SET_TIME: 2000000,
};



export const ACHCC_URL_IGNORE_LIST: any = [
  { route: '/b2b-experience/v1/orgs/7000000000000020152/account-orders' },
  { route: '/b2b-experience/v1/routingNumbers' },
  { route: '/b2b-experience/v1/payment-v3-config' },
  { route: '/b2b-experience/v1/card-type-api-config' },
  { route: 'payments/v1/cardtypevalidation' },
  { route: '/b2b-experience/v1/payment-v3-config' },
  { route: '/payments/ecommerce/v3/processpayments' },
  { route: '/b2b-experience/document-service/v1/documents' },
  { route: '/b2b-experience/v1/orgs/7000000000000020152/account-orders' }

];

export const LOGGING_URL_IGNORE_LIST: any = [
  { route: '/tmobile/cq/loggingapi' },
  { route: '/splunk-logs/services/collector/event' }
];

export const REDFLAG_URL: any = [
  endpointUrl('/b2b-experience/v1/content?service=/bin/authorvalue/content/tmocontentApp/common-payment.json'),
  endpointUrl('/v1/oauth2/accesstoken'),
  endpointUrl('/b2b-experience/v1/handshake-sso')
];


export const monthJSON: any = [
  { name: 'Jan', value: '01' },
  { name: 'Feb', value: '02' },
  { name: 'Mar', value: '03' },
  { name: 'Apr', value: '04' },
  { name: 'May', value: '05' },
  { name: 'June', value: '06' },
  { name: 'July', value: '07' },
  { name: 'August', value: '08' },
  { name: 'September', value: '09' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
];

export const yearsJSON: any = [
  { name: 2018, value: '2018' },
  { name: 2019, value: '2019' },
  { name: 2020, value: '2020' },
  { name: 2021, value: '2021' },
  { name: 2022, value: '2022' },
  { name: 2023, value: '2023' },
  { name: 2024, value: '2024' },
  { name: 2025, value: '2025' },
  { name: 2026, value: '2026' },
  { name: 2027, value: '2027' },
  { name: 2028, value: '2028' },
];

export const TERMS_CONDITION_CONSTANTS: any = {
  DOCUMENT_TYPE_TEXT: 'HTML',
  TRANSACTION_TYPE_TEXT: 'ADD_A_LINE',
  FULL_NAME_TEXT: 'Richard Headely',
  EMAIL_TEXT: 'dsdsdsd@asasas',
  PLAN_NAME_TEXT: 'SC North America DOP 21GB',
  TREATMENT_TEXT: 'TI',
  UPDATED_BALANCE_KEY: 'updatedBalance',
  B2B_CLIENT_VALUE: 'test',
  SELECTED_TERMS_KEY: 'selectedTerms'
};

export const CREDIT_CARD_CONSTANTS: any = {
  ZERO: 0,
  CVV_LENGTH_CONSTANT: 3,
  CVV_AMEXLENGTH_CONSTANT: 4,
  CARD_LENGTH_CONSTANT: 16,
  CREDIT_CARD_NAME_LENGTH: 30,
  VISA_CREDIT_CARD_LENGTH: 30,
  AMERICAN_CREDIT_CARD_LENGTH: 30,
  CUSTOMER_ID_CONSTANT: 752821271,
  CREDIT_CARD_LENGTH_BRAND_CHECKING: 6,
  TWO: 2,
  ONE: 1,
  FIVE: 5,
  INVALID_CARD: 'Invalid Card',
  // temp keys replaced with actual keys
  CARD_BLOCK_KEY: 'DEBIT_CARD,CREDIT_CARD',  // STATUS_FAILURE: 'FAILURE'
  CARD_BLOCKED: 'CARD_BLOCKED',
  BAN_BLOCKED: 'BAN_BLOCKED',
  ALTERNATE_CARD: '(Alternate Card) ',
  MASKING_VALUE: 'maskingValue',
  PAYMETHOD_CODE: 'payMethodCode',
  ERR_SERVER: 'Server',
  MICRO_TEXT: 'micro',
  IMAGE_URL: 'imageUrl',
  EXPIRATION_MONTH: 'expirationMonth',
  EXPIRATION_YEAR: 'expirationYear',
  FIRST: 'first',
  LAST: 'last',
  CVV: 'cvv',
  TRUE: 'TRUE',
  CC_BILLPAYALIAS_DATA: 'ccBillPayAliasData',
  CARD_TYPE: 'cardBrand',
  PAYMENT_TYPE: 'CC'
};

export const CREDIT_CARD_NUMBER_CONSTANTS: any = {
  AMERICAN_CREDIT_CARD_NUMBER_LENGTH: 15,
  VISA_CREDIT_CARD_NUMBER_LENGTH: 16,
  MASTER_CARD_NUMBER_LENGTH: 16,
  DISCOVER_CARD_NUMBER_LENGTH: 16,
};

export const ACH_CONSTANTS: any = {
  TRANSACTION_TYPE: 'BILL_PAYMENT',
  SCH_TRANSACTION_TYPE: 'SCHEDULED_PAYMENT',
  PAYMENT_TYPE: 'ACH',
  INVALID_ROUTING: 'Invalid Routing Number',
  changeReqType: 'BAN_AUTOPAY_ENROLL',
  pay_payMethodId: 'CHECK',
  CUSTOMER_TYPE: 'SAMSON',
  PRODUCT_GROUP: 'SOFTGOODS',
  CHANNEL: 'TSL',
  CHANNEL_B2B: 'B2B_WEB',
  BUSINESS_UNIT: 'TMOBILE',
  PROGRAM_CODE: 'U1POSTLEGACY',
  BILLER_CODE: 'SAMSON',
  BUSINESS_SEGMENT: 'POSTPAID',
  MNGORDER_TYPE: 'MANAGEPAYMENT',
  ORDER_TYPE: 'ADDALINE',
  PURPOSE: 'BillTo',
  COUNTRY_CODE: 'US',
  CLIENT_ID: 'B2B_WEB',
  BANK_BLOCK_KEY: 'CHECK',
  BANK_BLOCKED: 'BANK_BLOCKED',
  BANK_ACCOUNT: 'Bank account # ',
  SAVINGS_ACCOUNT: 'Savings Account',
  CHECKING_KEY: 'CHECKING',
  SAVING_KEY: 'SAVINGS',
  ERR_1001: 'MessageKey-1001',
  ERR_SERVER: 'Server',
  MICRO_TEXT: 'micro',
  BANK_TEXT: 'Bank account'
};

export const ERROR_CATEGORY: any = {
  FAILURE_SYSTEM_VALIDATION: 'FAILURE_SYSTEM_VALIDATION',
  FAILURE_BUSINESS_VALIDATION: 'FAILURE_BUSINESS_VALIDATION',
  HTTP_STATUS: 200
};

export const stateJSON: any = [
  {
    'name': 'Alabama',
    'id': 'AL'
  },
  {
    'name': 'Alaska',
    'id': 'AK'
  },
  {
    'name': 'Arizona',
    'id': 'AZ'
  },
  {
    'name': 'Arkansas',
    'id': 'AR'
  },
  {
    'name': 'California',
    'id': 'CA'
  },
  {
    'name': 'Colorado',
    'id': 'CO'
  },
  {
    'name': 'Connecticut',
    'id': 'CT'
  },
  {
    'name': 'Delaware',
    'id': 'DE'
  },
  {
    'name': 'District Of Columbia',
    'id': 'DC'
  },
  {
    'name': 'Florida',
    'id': 'FL'
  },
  {
    'name': 'Georgia',
    'id': 'GA'
  },
  {
    'name': 'Hawaii',
    'id': 'HI'
  },
  {
    'name': 'Idaho',
    'id': 'ID'
  },
  {
    'name': 'Illinois',
    'id': 'IL'
  },
  {
    'name': 'Indiana',
    'id': 'IN'
  },
  {
    'name': 'Iowa',
    'id': 'IA'
  },
  {
    'name': 'Kansas',
    'id': 'KS'
  },
  {
    'name': 'Kentucky',
    'id': 'KY'
  },
  {
    'name': 'Louisiana',
    'id': 'LA'
  },
  {
    'name': 'Maine',
    'id': 'ME'
  },
  {
    'name': 'Marshall Islands',
    'id': 'MH'
  },
  {
    'name': 'Maryland',
    'id': 'MD'
  },
  {
    'name': 'Massachusetts',
    'id': 'MA'
  },
  {
    'name': 'Michigan',
    'id': 'MI'
  },
  {
    'name': 'Minnesota',
    'id': 'MN'
  },
  {
    'name': 'Mississippi',
    'id': 'MS'
  },
  {
    'name': 'Missouri',
    'id': 'MO'
  },
  {
    'name': 'Montana',
    'id': 'MT'
  },
  {
    'name': 'Nebraska',
    'id': 'NE'
  },
  {
    'name': 'Nevada',
    'id': 'NV'
  },
  {
    'name': 'New Hampshire',
    'id': 'NH'
  },
  {
    'name': 'New Jersey',
    'id': 'NJ'
  },
  {
    'name': 'New Mexico',
    'id': 'NM'
  },
  {
    'name': 'New York',
    'id': 'NY'
  },
  {
    'name': 'North Carolina',
    'id': 'NC'
  },
  {
    'name': 'North Dakota',
    'id': 'ND'
  },
  {
    'name': 'Ohio',
    'id': 'OH'
  },
  {
    'name': 'Oklahoma',
    'id': 'OK'
  },
  {
    'name': 'Oregon',
    'id': 'OR'
  },
  {
    'name': 'Pennsylvania',
    'id': 'PA'
  },
  {
    'name': 'Rhode Island',
    'id': 'RI'
  },
  {
    'name': 'South Carolina',
    'id': 'SC'
  },
  {
    'name': 'South Dakota',
    'id': 'SD'
  },
  {
    'name': 'Tennessee',
    'id': 'TN'
  },
  {
    'name': 'Texas',
    'id': 'TX'
  },
  {
    'name': 'Utah',
    'id': 'UT'
  },
  {
    'name': 'Vermont',
    'id': 'VT'
  },
  {
    'name': 'Virginia',
    'id': 'VA'
  },
  {
    'name': 'Washington',
    'id': 'WA'
  },
  {
    'name': 'West Virginia',
    'id': 'WV'
  },
  {
    'name': 'Wisconsin',
    'id': 'WI'
  },
  {
    'name': 'Wyoming',
    'id': 'WY'
  }
];

export const MakePaymentConstant = {
  businessUnit: 'TMOBILE',
  programCode: 'U1POSTLEGACY',
  clientId: 'SL_ECP',
  operationType: 'SALES',
  operationTypeSOH: 'AUTH',
  achOrderType: 'POSTPAYMENT_ONE_TIME_PAYMENT',
  sohOrderType: 'SOFT_GOODS_DEPOSIT',
  businessSegment: 'POSTPAID',
  paymentMethodBankCode: 'CHECK',
  paymentMethodCardCode: 'CC',
  purposes: 'BillTo',
  countryCode: 'US',
  productGroup: 'SOFTGOODS',
  productGroupInseego : 'HARDGOODS',
  applicationId_Hardgoods: 'TFB-EXT',
  applicationId_Hardgoods_IOT: 'TFB-EXT-IOT',
  paymentArrangementAction: '',
  paymentArrangementActionEdit: 'UPDATE PAYMENT INSTRUMENT',
  billerCode: 'SAMSON',
  ccOrderType: 'ADDALINE',
  schAchOrderType: 'MANAGEPAYMENT',
  ORDERTYPE: 'orderType',
  ORDERNUMBER: 'orderNumber',
  BAN_TYPE: 'banType',
  cardSchemailId: '',
  cardSchsalesChannelId: 'B2B_WEB',
  salesChannelId: 'WEB',
  autoBusinessSegment: 'B2B',
  AP_ACCEPTANCE: 'Yes - Internal',
  AP_ASSISTINGDEPT: 'Offline Activations',
  AP_DELUSERREQ: 'UsrReq',
  amountPlaceholder: '[AMOUNT]',
  usd: 'USD',
  dueDate: '[DUEDATE]',
  smallBalance: 1.99,
  TEN: 10,
  TWELEVE: 12,
  TWENTY: 20,
  MINUS_ONE: -1,
  UPDATED_BALANCE_KEY: 'updatedBalance',
  optIn: 'opt-in',
  optOut: 'opt-out',
  dueDateField: 'dueDate',
  autopay: 'autoPay',
  autoPayStatus: 'autoPayStatus',
  ban: '[BAN]',
  banName: '[BANNAME]',
  serviceAgreementKey: 'serviceAgreement',
  autoPayDocDetailsKey: 'AutoPay_docDetails',
  bank: 'Bank',
  card: 'Card',
  totalDue: 'totalDue',
  paymentAmount: 'paymentamount',
  Installment: 'installment',
  other: 'other',
  today: 'today',
  todayWithBracket: '(Today)',
  zeroConstant: '+0000',
  twoDollar: '$2.00',
  dateFormat: 'MMM d, y',
  dateFormatYYYY: 'yyyyMMdd',
  dateFormatMMMM: 'MMMM d, y',
  dateFormatpaymentArrangement:'yyyy-MM-dd',
  txtTrue: 'true',
  txtFalse: 'false',
  paymentTxt: 'payment',
  makepaymentResponse: 'makePaymentResponse',
  thankuUrl: 'apps/tfb_payment/thankuScreen',
  processError: 'apps/tfb_payment/errorScreen',
  idleError: 'apps/tfb_payment/idleErrorScreen',
  responseCodeList: 'responseCodeList',
  schedulePayment: 'schedulePayment',
  autoPayAgreement: 'AutoPayAgreement',
  failedStatus: 'F',
  successStatus: 'S',
  paymentArrangementTxt: 'paymentArrangement',
  APPROVED_MPI_CODE: '2491',
  INSEEGO_NEGATIVE_FILE_ERROR : '2492',
  APPROVED_STATUS: 'A',
  DICLINED_STATUS: 'D',
  FLOW_TYPE: {
    FLOW_TYPE_TEXT: 'flowType',
    ONETIME_PAYMENT: 'ONETIME_PAYMENT',
    SCHEDULE_PAYMENT: 'SCHEDULE_PAYMENT',
    ENROLL_AUTOPAY: 'ENROLL_AUTOPAY',
    MANAGE_AUTOPAY: 'MANAGE_AUTOPAY',
    CANCEL_AUTOPAY: 'CANCEL_AUTOPAY',
    SOH_LINE_DEPOSIT: 'LINE_ACTIVATION_DEPOSIT'
  },
  MODAL_CNTNT: 'modal-content',
  ZOOM_IN: 'zoomOut',
  ZOOM_OUT: 'zoomIn',
  errorPageUrl: '/error',
  PAYMENT_ARRANGEMENT_READONLY:'PAYMENT_ARRANGEMENT_READONLY',
  PA_CREATE_PAYMENT_ARRANGEMENT: 'CREATE_PAYMENT_ARRANGEMENT',
  MAKE_PAYMENTS: 'MAKE_PAYMENTS',
  AUTOPAY_ENROLLMENT: 'AUTOPAY_ENROLLMENT',
  PA_EDIT_PAYMENT_ARRANGEMENT: 'EDIT_PAYMENT_ARRANGEMENT',
  INSTALLMENT_STATUS_OPENED: 'OPENED'
};

export enum CardBrands {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMERICANEXPRESS = 'AMERICANEXPRESS',
  DISCOVER = 'DISCOVER',
  VS = 'VS',
  MSCD = 'MSCD',
  AMEX = 'AMEX',
  DISC = 'DISC'
}

export enum CardImages {
  VISA_URL = '../../assets/tfb-payment/images/cc-visa.png',
  MASTERCARD_URL = '../../assets/tfb-payment/images/cc-master.png',
  AMERICANEXPRESS_URL = '../../assets/tfb-payment/images/cc-amex.png',
  DISCOVER_URL = '../../assets/tfb-payment/images/cc-discover.png'
}

export enum CardNames {
  VISA = 'Visa Card',
  MASTERCARD = 'Master Card',
  AMERICANEXPRESS = 'American Epress Card',
  DISCOVER = 'Discover Card'
}


export enum GoogleAddressTypes {
  LOCALITY = 'locality',
  ADMINSTRATIVE_AREA_LEVEL1 = 'administrative_area_level_1',
  POSTEL_CODE = 'postal_code',
  STREET_NUM = 'street_number',
  ROUTE = 'route'
}

export const CreditCardConstants = {
  PAYMENT_TYPE: 'CC',
  changeReqType: 'BAN_AUTOPAY_ENROLL',
  CUSTOMER_TYPE: 'SAMSON',
  PRODUCT_GROUP: 'HARDGOODS',
  BUSINESS_UNIT: 'TMOBILE',
  PROGRAM_CODE: 'U1POSTLEGACY',
  BILLER_CODE: 'SAMSON',
  BUSINESS_SEGMENT: 'POSTPAID',
  VISA_CARD: 'VISA',
  MASTER_CARD: 'MSCD',
  AMERICAN_EXPRESS_CARD: 'AMEX',
  DISCOVER_CARD: 'DISC',
  IS_AUTH_REQUIRED: 'isAuthorizationRequired',
  IS_AUTH_FALSE: 'false'
};

export enum RESPONSIVE_STATE {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
  TABLET = 'Tablet'
}

export enum FLOW_TYPE {
  SCHEDULE_PAYMENT = 1,
  CREATE_PA = 2,
  EDIT_PA = 3,
  HARD_GOODS_AUTH = 4,
}

export const PDL_CONSTANTS = {
    PRIMARY_CATEGORY: 'Billing',
    PLP_SUB_CATEGORY: 'Payment',
    PLP_PAGE_NAME: 'Make A Payment',
    PLP_ERROR_PAGE_NAME: 'Payment Error',
    PLP_ERROR_PAGE_TYPE: 'error',
    PLP_PAGE_NAME_EDIT: 'Edit Payment Arrangement',
    PLP_PAGE_TYPE: 'payment',
    PLP_PAGE_TYPE_EDIT_PA: 'EditPA',
    PLP_PAGE_NAME_CONFIRMATION: 'Make A Payment Confirmation',
    PLP_PAGE_NAME_CONFIRMATION_PA: 'Payment Arrangement Confirmation',
    PLP_PAGE_TYPE_CONFIRMATION: 'Paymentconfirm',
    PLP_PAGE_TYPE_CONFIRMATION_PA: 'PAConfirm',
    PLP_PAGE_NAME_AUTOPAY: 'Enroll In Autopay',
    PLP_PAGE_TYPE_AUTOPAY: 'Enroll',
    PLP_PAGE_NAME_AUTOPAY_MANAGE: 'Edit AutoPay',
    PLP_PAGE_TYPE_AUTOPAY_MANAGE: 'EditAutoPay',
    PLP_PAGE_TYPE_CONFIRMATION_AUTOPAY: 'AutoPayConfirm',
    PLP_PAGE_NAME_CONFIRMATION_AUTOPAY: 'AutoPay Confirmation',
    PLP_PAGE_NAME_SOH: 'Sim on Hand',
    PLP_PAGE_PREVIOUS_URL: 'previousPageUrl',
};

export const EipPaymentConstants = {
  EIP_DEVICE: "eipDevice",
  CONTENT: "contentmain",
  TRUE: "true",
  ZERO: "0",
  PARTNER_ERROR: "partnerError",
  BALANCE: "balance",
  ADDITIONAL: "additional",
  TRANSACTION_TYPE: "EIP_STANDALONE_PAYMENT",
  DEALER_CODE: "0000002",
  POSTING_SUB_TYPE: "loanId",
  POSTING_TYPE: "agreementNumber",
  PAYMENT_TRANSACTION_SUB_TYPE: "PAYMENT_UNSCH",
  PAYMENT_TYPE: "P",
  PAYMENT_TRANSACTION_TYPE: "PAYMENT",
  ERROR_LIST_CODE: "8183,8184",
  ORDER_TYPE: "eipStandalonePayment",
  PAYMENT_OBJECT: "card",
  HANDSHAKE_COMPLETE: "isHandshakeComplete",
  ORDER_NUMBER: "transRefNum",
  EIP_PAYMENT: "EIP_PAYMENT",
  ORIGIN_APPLICATION_ID: "APM0103622-TFB-Payment-App"
};
