import { Injectable } from '@angular/core';
import { EnvironmentDetailsService } from './environment-details.service';
import { ContentService } from './content.service';
import { TERMS_CONDITION_CONSTANTS, COMMON_CONSTANTS, MakePaymentConstant } from '../common/core/common.constants';
import { AuthHttpService } from './http-wrapper.service';
import {
  TermsAndConditionModal, ContactInformation, ServiceAgreementDetail,
  ServiceADetail, RatePlanDetails, PatchCallObject, GeneralTermsModal
} from '../common/models/data.model';
import { HttpCacheService } from './http-cache.service';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class TermAndConditionService {
  body: any;
  selectedOption = [];
  orderId: any;
  accessToken: any;
  handShakeData: any;
  termsAndConditionModal: TermsAndConditionModal = new TermsAndConditionModal();
  contactInformationData: ContactInformation = new ContactInformation();
  serviceAgreementDetail: ServiceAgreementDetail = new ServiceAgreementDetail();
  serviceADetail: ServiceADetail = new ServiceADetail();
  ratePlanDetails: RatePlanDetails = new RatePlanDetails();
  generalTermsModal: GeneralTermsModal = new GeneralTermsModal();
  patchCallObject: PatchCallObject = new PatchCallObject();
  transactionType: any;

  constructor(private _http: AuthHttpService, private _envSvc: EnvironmentDetailsService, private _cacheSvc: HttpCacheService) {

  }
  setHeaders() {
    this.accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;

    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.ACTIVITY_ID_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS) || uuid();
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.X_AUTH_ORIGINATOR_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.X_AUTH_ORIGINATOR_KEY);
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.ACCEPT_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY);
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + this.accessToken;
    headerSettings['channel-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.AP_CHANNEL_ID;
    headerSettings['application-id'] = "TFB";

    if (isPartnerOnboard) {
      headerSettings['eid'] = this._cacheSvc.getFromSessionStorage('eid');
      headerSettings['ds'] = this._cacheSvc.getFromSessionStorage('ds');
    } else {
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_USER_ID] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_USER_ID);
    }


    return new HttpHeaders(headerSettings);
  }

  public getServiceAgreement() { 
    this.handShakeData = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA) ?
      JSON.parse(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)) : '';

    this.termsAndConditionModal.ban = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY);
    this.termsAndConditionModal.documentReturnType = TERMS_CONDITION_CONSTANTS.DOCUMENT_TYPE_TEXT;
    this.termsAndConditionModal.transactionId = '';
    this.termsAndConditionModal.transactionType = TERMS_CONDITION_CONSTANTS.TRANSACTION_TYPE_TEXT;


    this.termsAndConditionModal.customerDetails.fullName = this.handShakeData ?
      this.handShakeData.firstName + ' ' + this.handShakeData.lastName : '' + '';
    this.contactInformationData.phoneNumber = (this.handShakeData == null ? '' : this.handShakeData.msisdn);

    this.contactInformationData.email = this.handShakeData ? this.handShakeData.logonId : '';
    this.termsAndConditionModal.customerDetails.contactInformation.push(this.contactInformationData);
    this.serviceAgreementDetail.msisdn = '';
    this.serviceAgreementDetail.sim = '';
    this.serviceAgreementDetail.imei = '';
    this.serviceAgreementDetail.activationFee = '';
    this.serviceAgreementDetail.depositAmount = '';
    this.serviceADetail.serviceName = '';
    this.serviceADetail.changeEffectiveDate = '';
    this.serviceADetail.mrc = '';
    this.serviceADetail.serviceChargeType = '';
    this.serviceAgreementDetail.serviceDetails.push(this.serviceADetail);
    this.ratePlanDetails.planName = TERMS_CONDITION_CONSTANTS.PLAN_NAME_TEXT;
    this.ratePlanDetails.planStatus = '';
    this.ratePlanDetails.changeEffectiveDate = '';
    this.ratePlanDetails.mrc = '';
    this.ratePlanDetails.amountDue = '';
    this.serviceAgreementDetail.ratePlanDetails.push(this.ratePlanDetails);
    this.termsAndConditionModal.serviceAgreementDetails.push(this.serviceAgreementDetail);
    this.termsAndConditionModal.serviceAgreementChargeDetails.regulatoryProgramFeeAmount = '';
    this.termsAndConditionModal.serviceAgreementChargeDetails.totalActivationFee = '';
    this.termsAndConditionModal.serviceAgreementChargeDetails.previousTotalMonthlyCharges = '';
    this.termsAndConditionModal.serviceAgreementChargeDetails.firstBillTotalMonthlyCharges = '';
    this.termsAndConditionModal.serviceAgreementChargeDetails.regularTotalMonthlyCharges = '';
    this.termsAndConditionModal.serviceAgreementChargeDetails.totalMonthlyChargesDiff = '';
    this.termsAndConditionModal.serviceAgreementChargeDetails.totalAmountDue = '';
    this.termsAndConditionModal.serviceAgreementChargeDetails.taxTreatment = TERMS_CONDITION_CONSTANTS.TREATMENT_TEXT;
    this.termsAndConditionModal.serviceAgreementChargeDetails.digitsIndicator = '';
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this._http.get(this._envSvc.CURR_SESSION_ENDPOINTS.GET_SERVICE_TEXT_URL_PARTNER);
      } else {
        return this._http.post(this._envSvc.CURR_SESSION_ENDPOINTS.GET_SERVICE_TEXT_URL_PARTNER,
          this.termsAndConditionModal, this.setHeaders());
      }
    } else {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this._http.get(this._envSvc.CURR_SESSION_ENDPOINTS.GET_SERVICE_TEXT_URL);
      } else {
        return this._http.post(this._envSvc.CURR_SESSION_ENDPOINTS.GET_SERVICE_TEXT_URL, this.termsAndConditionModal,
          this.setHeaders());
      }
    }
  }

  public setSelectedTerms(option: any) {
    this.selectedOption.push(option);
    ContentService.SetCache(TERMS_CONDITION_CONSTANTS.SELECTED_TERMS_KEY, this.selectedOption);
  }

  public findAndConvertAcceptedTimeStampByDocumentId(documentId){
    const result=this.selectedOption.filter(option=>option.documentId===documentId);
    return result.length>0?result[0].acceptedTimeStamp.replace(MakePaymentConstant.zeroConstant, 'Z'):'';
  }

  public recordUserConsent() {
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    this.accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);

    this.body ={
      billingAccount: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY),
      transRefNum: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TRANS_REF_NUM),
      documentDetails: this.selectedOption
    }

    
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this._http.get(this._envSvc.CURR_SESSION_ENDPOINTS.GET_SERVICE_CONSENT_URL_PARTNER);
      } else {
        return this._http.patch(this._envSvc.CURR_SESSION_ENDPOINTS.GET_SERVICE_CONSENT_URL_PARTNER, this.body,
          this.setHeaders());
      }
    } else {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this._http.get(this._envSvc.CURR_SESSION_ENDPOINTS.GET_SERVICE_CONSENT_URL);
      } else {
        return this._http.patch(this._envSvc.CURR_SESSION_ENDPOINTS.GET_SERVICE_CONSENT_URL, this.body,
          this.setHeaders());
      }
    }
  }

  getAutoPayAgreement() { 
    this.handShakeData = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA) ?
      JSON.parse(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)) : '';

    this.body = {
      billingAccount: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY),
      customerDetails: {
        fullName: this.handShakeData ?
          this.handShakeData.firstName + ' ' + this.handShakeData.lastName : '' + '',
        firstName: this.handShakeData ?
          this.handShakeData.firstName : '',
        lastName: this.handShakeData ?
          this.handShakeData.lastName : ''
      },
      tagInfo: {
        firstName: this.handShakeData ?
          this.handShakeData.firstName : '',
        lastName: this.handShakeData ?
          this.handShakeData.lastName : '',
        contractBan: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY),
        dateTime: new Date().toISOString()
      },
      documentReturnType: 'HTML',
      templateLanguage: 'en-US'
    };
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this._http.get(this._envSvc.CURR_SESSION_ENDPOINTS.GET_AUTO_PAY_SERVICE_URL_PARTNER);
      } else {
        // tslint:disable-next-line:max-line-length
        return this._http.post(this._envSvc.CURR_SESSION_ENDPOINTS.GET_AUTO_PAY_SERVICE_URL_PARTNER, this.body, this.setHeaders());
      }
    } else {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this._http.get(this._envSvc.CURR_SESSION_ENDPOINTS.GET_AUTO_PAY_SERVICE_URL);
      } else {
        // tslint:disable-next-line:max-line-length
        return this._http.post(this._envSvc.CURR_SESSION_ENDPOINTS.GET_AUTO_PAY_SERVICE_URL, this.body, this.setHeaders());
      }
    }
  }
}
