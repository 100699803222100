import { Injectable } from '@angular/core';
import { AuthHttpService } from './http-wrapper.service';
import { EnvironmentDetailsService } from './environment-details.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { HttpCacheService } from './http-cache.service';
import { APIGEE_TOKEN_URL } from '../common/core/urls.constants';
import { catchError, tap } from 'rxjs/operators';
import { COMMON_CONSTANTS, FLOW_TYPE } from '../common/core/common.constants';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SchedulePaymentService {
  authToken: any;

  constructor(private httpClient: AuthHttpService, private _http: HttpClient, private _envSvc: EnvironmentDetailsService,
    private _cacheSvc: HttpCacheService) {
    this.authToken = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY)) ?
      COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY) : '';
  }

  getHeader(orgid) {
    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;

    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings['B2b-Org'] = orgid;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
      COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken;
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
        headerSettings['eid'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID);
        headerSettings[COMMON_CONSTANTS.QUERY_PARAM_DS] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS);
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
    }

    return new HttpHeaders(headerSettings)
  }

  getSchEligibilty(basebancode: any, orgid: any) {
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    if (!isPartnerOnboard && environment.DISABLE_ACCESS_TOKEN_CALL === false) {
      const getSchEligURL1 = APIGEE_TOKEN_URL.GET_SCH_ELIGIBILITY.path.replace('{{baseban}}', basebancode);
      const getSchEligURL2 = '?org=' + orgid;
      let getSchEligURL = getSchEligURL1 + getSchEligURL2;
      if (environment.mocked_all || environment.mocked_endpoints) {
        getSchEligURL = this._envSvc.APIGEE_TOKEN_URLS.GET_SCH_ELIGIBILITY.path;
      }
      const getSchEligConst = {
        path: getSchEligURL,
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_SCH_ELIGIBILITY'
      };
      return this.httpClient.get(getSchEligConst, this.getHeader(orgid))
        .pipe(tap(() => {
          // console.log(`scheduled payment Eligibility`)
        }),
          catchError(this.handleError)
        );
    } else {
      const body = { billingAccount: atob(basebancode) };
      return this.httpClient.post(APIGEE_TOKEN_URL.GET_SCH_ELIGIBILITY_PARTNER, body, this.getHeader(orgid))
        .pipe(tap(() => {
          // console.log(`scheduled payment Eligibility`)
        }),
          catchError(this.handleError)
        );
    }
  }

  getSchDates(basebancode: any, orgid: any) {
    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    // tslint:disable-next-line:max-line-length
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
      if (!isPartnerOnboard && environment.DISABLE_ACCESS_TOKEN_CALL === false ) {
        const httpOptions = {
          headers: new HttpHeaders(
            {
              'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
              // 'B2b-client': API_PARAMS_CONST.B2B_CLIENT,
              'B2b-client': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY),
              'B2b-Org': orgid,
              'Authorization': COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken
            }
          )
        };
        const getSchDatesURL1 = APIGEE_TOKEN_URL.GET_SCH_DATES.path.replace('{{baseban}}', basebancode);
        const getSchDatesURL2 = '?org=' + orgid;
        let getSchDatesURL = getSchDatesURL1 + getSchDatesURL2;
       
        if (environment.mocked_all || environment.mocked_endpoints) {
          getSchDatesURL = this._envSvc.APIGEE_TOKEN_URLS.GET_SCH_DATES.path;
        }
    
        const getSchDateConst = {
          path: getSchDatesURL,
          canCache: false,
          isMocked: false,
          b2bClient: 'tfb-payment',
          apiName: 'GET_SCH_DATES'
        };
        return this.httpClient.get(getSchDateConst, httpOptions)
          .pipe(tap(() => {
            // console.log(`scheduled Dates Response`)
          }),
            catchError(this.handleError)
          );
      } else {
        let getSchDatesURL = APIGEE_TOKEN_URL.GET_SCH_DATES_PARTNER.path;
        if (environment.mocked_all || environment.mocked_endpoints) {
          getSchDatesURL = this._envSvc.APIGEE_TOKEN_URLS.GET_SCH_DATES.path;
        }
        const getSchDateConst = {
          path: getSchDatesURL,
          canCache: false,
          isMocked: false,
          b2bClient: 'tfb-payment',
          apiName: 'GET_SCH_DATES'
        };
       const requestPayload = {
         'billingAccount': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY)
        };
      return this.httpClient.post(getSchDateConst, requestPayload ,this.getHeader(orgid))
                .pipe(tap(() => {
                  // console.log(`scheduled Dates Response`)
                }),
                  catchError(this.handleError)
                );
            }
  }

  managePayment(requestPayload: any, recaptchaToken: string, flowType: number) {
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;

    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
      this.authToken;

    headerSettings['userId'] = COMMON_CONSTANTS.DIGIT_TWELEVE;
    headerSettings['senderid'] = COMMON_CONSTANTS.DIGIT_TWELEVE;
    headerSettings['channel-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.AP_CHANNEL_ID;
    if (isPartnerOnboard) {
        headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.PARTNER_APPLICATION_ID_KEY;
    } else {
        headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY;
    }

    headerSettings['activity-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    headerSettings['interaction-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    headerSettings['workflow-id'] = COMMON_CONSTANTS.WORKFLOW_ID_LINE_DEPOSIT;
    headerSettings['session-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID)){
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_USER_ID]=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID);
    }
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      headerSettings['eid'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID);
      headerSettings[COMMON_CONSTANTS.QUERY_PARAM_DS] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS);
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
    }
    /// To confirm if we need this check or not - FBEPTD-78312
    // const async=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_ASYNC)==='true';
    // if(async){
      headerSettings['recaptcha-auth'] = recaptchaToken;
    // }
    const httpOptions = {
      headers: new HttpHeaders(headerSettings)
    };

    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.MNG_PAYMENT_PARTNER);
    } else {
      switch (flowType) {
        case FLOW_TYPE.CREATE_PA:
          return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.CREATE_PA_PAYMENT_PARTNER, requestPayload, httpOptions);
        case FLOW_TYPE.EDIT_PA:
          return this.httpClient.put(this._envSvc.APIGEE_TOKEN_URLS.EDIT_PA_PAYMENT_PARTNER, requestPayload, httpOptions);
        case FLOW_TYPE.HARD_GOODS_AUTH:
          return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.HARD_GOODS_AUTH_PAYMENT_PARTNER, requestPayload, httpOptions);
        case FLOW_TYPE.SCHEDULE_PAYMENT:
          return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.SCHEDULE_PAYMENT_PARTNER, requestPayload, httpOptions);
        default:
          break;
      }
    }
  }

  cancelSchedulePayment() {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Accept': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
          'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
          // 'B2b-Client': API_PARAMS_CONST.B2B_CLIENT,
          'B2b-client': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY),
          'B2b-Org': sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY),
          'Authorization': this.authToken,
        }
      )
    };
    const banNumber = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY);
    let cancelSchedulePaymentUrl = this._envSvc.APIGEE_TOKEN_URLS.CANCEL_SCHEDULE_PAYMENT.path;
    if (environment.mocked_all || environment.mocked_endpoints) {
      cancelSchedulePaymentUrl = this._envSvc.APIGEE_TOKEN_URLS.CANCEL_SCHEDULE_PAYMENT.path;
    }
    cancelSchedulePaymentUrl = cancelSchedulePaymentUrl.replace('{billing-account}', btoa(banNumber));

    const cancelSchedulePaymentConst = {
      path: cancelSchedulePaymentUrl,
      canCache: false,
      isMocked: false,
      b2bClient: 'tfb-payment'
    };

    return this.httpClient.delete(cancelSchedulePaymentConst, httpOptions)
      .pipe(tap(() => {
        // console.log(`cancel Schedule Payment Response`)
      }),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response) { return throwError(error); }
}
