import { ErrorHandler, Injectable} from '@angular/core';
 
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
 
    constructor() { 
    }
  
    handleError(error) {
        //handle errors based on codes
        if (error.code == 909) {
            //This is an error that occurs since the Angular 17 upgrade. It doesn't affect functionality, 
            //but it can crash the console, so we're intercepting it and suppressing it here
        } else {
            //we re-throw all other errors
            console.error(error)
        }
   }
 
}