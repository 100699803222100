import { Injectable } from '@angular/core';
import { AuthHttpService } from './http-wrapper.service';
import { EnvironmentDetailsService } from './environment-details.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { COMMON_CONSTANTS, CREDIT_CARD_CONSTANTS, ACH_CONSTANTS } from '../common/core/common.constants';
import { Subscription } from 'rxjs';
import { HttpCacheService } from './http-cache.service';
import { BankInfo, CardInfo, CreateTransactionRequest, PublicKeyObj } from '../common/models/data.model';
import { environment } from '../../environments/environment';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';

@Injectable({
    providedIn: 'root'
})
export class BillPayService {
    errorMessage: any;
    publicKeyObj: PublicKeyObj = new PublicKeyObj();

    constructor(private authHttpSvc: AuthHttpService,
        private _envSvc: EnvironmentDetailsService, private _cacheSvc: HttpCacheService,
        private httpClient: HttpClient) {
    }

    private _isTokenRefrshed = false;
    _paymentTokenSubscriber: Subscription;

    fetchPublicKey() {
        const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
        const isoDate  = new Date().toISOString();
        const httpOptions = {
            headers: new HttpHeaders(
                {
                    'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
                    'application-id': COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY,
                    'channel-id': COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_WEB_KEY,
                    'Authorization': COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken,
                    'sender-id': COMMON_CONSTANTS.HEADER_PROPERTIES.SENDER_ID_KEY,
                    'Accept': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
                    'timestamp': isoDate.replace('T', ' ')
                }
            )
        };

        this.publicKeyObj.payment.encryption.encryptionFormat = COMMON_CONSTANTS.PUBLICKEY_PROPERTIES.ENCRYPTION_FORMAT;
        this.publicKeyObj.billingAccount = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY);

        if (environment.mocked_all || environment.mocked_endpoints) {
            return this.authHttpSvc.get(this._envSvc.APIGEE_TOKEN_URLS.PUBLIC_KEY);
        } else {
            return this.authHttpSvc.post(
                this._envSvc.APIGEE_TOKEN_URLS.PUBLIC_KEY, 
                this.publicKeyObj, 
                httpOptions
            );
        }

    }

    billPayAliasInfo(billPayAlData: any, orgId: any) {
        console.log("billPayAlData====",billPayAlData,orgId)
        const createTransactionRequest=this._generateCreateTransactionRequest(billPayAlData);
        const url = Object.assign({}, this._envSvc.APIGEE_TOKEN_URLS.BILLPAY_ALIAS);
        url.path = url.path + orgId + url.queryparam;
        const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
        const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
            ? true : false;
        if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
            if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('paymentTypeIndicator'))
                && (this._cacheSvc.getFromSessionStorage('paymentTypeIndicator') === 'oneTimeLineActivationDeposit')) {
                const headerSettings: { [name: string]: string | string[]; } = {};
                headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
                // comment out for nodejs
                headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
                    COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken;
                headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.PARTNER_APPLICATION_ID_KEY;
                headerSettings['channel-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.AP_CHANNEL_ID;

                headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
                headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
                headerSettings['activity-id'] = this._cacheSvc.getFromSessionStorage('transactionID');
                headerSettings['interaction-id'] = this._cacheSvc.getFromSessionStorage('transactionID');
                headerSettings['workflow-id'] = COMMON_CONSTANTS.WORKFLOW_ID_LINE_DEPOSIT;
                headerSettings['session-id'] = this._cacheSvc.getFromSessionStorage('transactionID');
                headerSettings["service-transaction-id"] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);


                const httpOptions = {
                    headers: new HttpHeaders(headerSettings)
                };

                if (environment.mocked_all || environment.mocked_endpoints) {
                    return this.authHttpSvc.get(this._envSvc.APIGEE_TOKEN_URLS.BILLPAY_ALIAS_PARTNER);
                } else {
                    return this.authHttpSvc.post(this._envSvc.APIGEE_TOKEN_URLS.BILLPAY_ALIAS_PARTNER, createTransactionRequest, httpOptions);
                }
            } else {
                if (environment.mocked_all || environment.mocked_endpoints) {
                    return this.authHttpSvc.get(this._envSvc.APIGEE_TOKEN_URLS.BILLPAY_ALIAS_PARTNER);
                } else {
                    return this.authHttpSvc.post(this._envSvc.APIGEE_TOKEN_URLS.BILLPAY_ALIAS_PARTNER, createTransactionRequest);
                }
            }
        } else {
            if (environment.mocked_all || environment.mocked_endpoints) {
                return this.authHttpSvc.get(this._envSvc.APIGEE_TOKEN_URLS.BILLPAY_ALIAS);
            } else {
                return this.authHttpSvc.post(url, billPayAlData);
            }
        }
    }
    private _generateCreateTransactionRequest(billPayAlData){
        const request:CreateTransactionRequest=new CreateTransactionRequest();
        request.billingAccount=billPayAlData?.billingAccountNumber;
        request.transactionType=billPayAlData?.transactionType;
        request.paymentType=billPayAlData?.paymentType;
        if(request.paymentType===CREDIT_CARD_CONSTANTS.PAYMENT_TYPE){
            request.cardInfo=new CardInfo();
            request.cardInfo.cardHolderName=billPayAlData?.cardholderName;
            request.cardInfo.creditCardType=billPayAlData?.cardBrand;
            request.cardInfo.cardNumberAlias=billPayAlData?.cardNumberAlias;
            request.cardInfo.expireMonth=billPayAlData?.expireMonth;
            request.cardInfo.expireYear=billPayAlData?.expireYear;
            request.cardInfo.addressInfo.addressLine1=billPayAlData?.billingAddress?.address1;
            request.cardInfo.addressInfo.city=billPayAlData?.billingAddress?.city;
            request.cardInfo.addressInfo.state=billPayAlData?.billingAddress?.state;
            request.cardInfo.addressInfo.zipCode=billPayAlData?.billingAddress?.zipCode;
        }else if(request.paymentType===ACH_CONSTANTS.PAYMENT_TYPE){
            request.bankInfo=new BankInfo();
            request.bankInfo.bankAccountHolderName=billPayAlData?.accountHolderName;
            request.bankInfo.bankAccountType=billPayAlData?.accountType;
            request.bankInfo.bankAccountAlias=billPayAlData?.bankAccountAlias;
            request.bankInfo.bankRoutingNumber=HttpCacheService.GetCache(COMMON_CONSTANTS.ROUTING_NUMBER);
        }
        request.plannedPayments=billPayAlData?.plannedPayments;
        return request;
    }
}
