import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { IPageViewTag, IClickTag } from '../common/core/common.interface';
import { RESPONSIVE_STATE } from '../common/core/common.constants';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PDLService {
  constructor(@Inject(PLATFORM_ID) private platform: any) { }
  responsiveState: any;
 generatePageViewTag(pageViewDetails: IPageViewTag) {
    setTimeout(() => {
      if (this.checkIsBrowser) {
        try {
          (window as any).digitalData.page.category.primaryCategory = pageViewDetails.primaryCategory;
          (window as any).digitalData.page.category.subCategory = pageViewDetails.subCategory;
          (window as any).digitalData.page.category.pageType = pageViewDetails.pageType;
          (window as any).digitalData.page.pageInfo.pageName = pageViewDetails.pageName;
          (window as any).digitalData.page.pageInfo.responsiveState = this.getResponsiveState();
          (window as any).digitalData.page.pageInfo.pageURLFull = window.location.host+window.location.pathname;
          (window as any).digitalData.page.pageInfo.previousPageURL = pageViewDetails.previousPageURL;
          this.responsiveState = (window as any).digitalData.page.pageInfo.responsiveState;
          if (pageViewDetails.isPartner == "true") {
            (window as any).digitalData.page.pageInfo.variant = "Partner";
          } else if (pageViewDetails.idpType == "iam"){
            (window as any).digitalData.page.pageInfo.variant = "IAM";
          }

          const event: any = {
            eventInfo: {
              eventAction: 'load',
              eventType: 'page',
              timestamp: Date()
            }
          };
          this.addElementToObject(event.eventInfo, 'idpType', pageViewDetails.idpType);
          this.addElementToObject(event.eventInfo, 'isPartner', pageViewDetails.isPartner);
          (window as any).digitalData.events.push(event);
          (window as any).triggerEvent();
          } catch(e) {

          }
        }
      }, 5000);
  }

  generateClickTag(clickTagDetails: IClickTag) {
    console.log('In generateClickTag')
    console.log('checkIsBrowser Value:' + this.checkIsBrowser)
    if (this.checkIsBrowser) {
      const event = {
        eventInfo: {
          eventType: clickTagDetails.type,
          eventAction: clickTagDetails.action
        },
        eventDescription: {
          assetType: clickTagDetails.assetType
        }
      };

      this.addElementToObject(event.eventDescription, 'formName', clickTagDetails.pageName);
      this.addElementToObject(event.eventDescription, 'modalName', clickTagDetails.modalName);
      this.addElementToObject(event.eventDescription, 'sortName', clickTagDetails.sortName);
      this.addElementToObject(event.eventDescription, 'filterName', clickTagDetails.filterName);
      this.addElementToObject(event.eventDescription, 'ctaName', clickTagDetails.ctaName);
      this.addElementToObject(event.eventDescription, 'targetUrl', clickTagDetails.targetUrl);

      (window as any).digitalData.events.push(event);
      (window as any).triggerEvent();
    }
  }

  private getResponsiveState(): string {
    if (window.matchMedia('(max-width: 600px)').matches) {
      return RESPONSIVE_STATE.MOBILE;
    } else if (
      window.matchMedia('(min-width: 600px) and (max-width: 1024px)').matches
    ) {
      return RESPONSIVE_STATE.TABLET;
    } else {
      return RESPONSIVE_STATE.DESKTOP;
    }
  }

  private addElementToObject(object: any, key: string, value: string) {
    if (value) {
      object[key] = value;
    }

  }

  private checkIsBrowser()
  {
    if(isPlatformBrowser(this.platform)){
      return true;
    }
    else {
      return false;
    }
  }
}
