/**
 * Injects Authorization token and baseApiUrl into HttpClient api calls.
 */
import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { PnpClientService } from '../services/pnp-client.service';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { configToken, PnpConfig } from '../interfaces/pnp-config';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private pnpClient: PnpClientService,
    @Inject(configToken) private _pnpConfig: PnpConfig
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let _req = req.clone();
    if (this.pnpClient.isRunningInGlobalNav() && _req.url.startsWith('/')) {
      _req = _req.clone({
        url: `${this._pnpConfig.xhrPrefix}${_req.url}`
      });
    }
    if (_req.headers && _req.headers.get('skip')) {
      _req = _req.clone({
        headers: _req.headers.delete('skip')
      });
    }
    if (!this._pnpConfig.shouldInjectToken) {
      return next.handle(_req);
    }
    return from(this.pnpClient.getAccessToken())
      .pipe(switchMap(token => {
        return next.handle(_req.clone({
          setHeaders: { Authorization: `Bearer ${token}` }
        }));
      }));
  }
}
