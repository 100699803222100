import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { SetupRouteService } from './setup-route.service';
import { LoaderService } from './loader.service';
import { OktaAuthorizationService } from './okta.auth.service';
import { HttpCacheService } from './http-cache.service';
import { COMMON_CONSTANTS, MakePaymentConstant, PDL_CONSTANTS } from '../common/core/common.constants';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';

@Injectable({
    providedIn: 'root'
})
export class SimonhandResolver  {
    isResolved: boolean;
    isLoaded: boolean;
    BanNumber: string;
    OrgID: string;
    isPartnerEnabled: any;
    constructor(private _loaderSvc: LoaderService, private setUpRouteSvc: SetupRouteService,
        private _oktaAuthSvc: OktaAuthorizationService, private _cacheSvc: HttpCacheService) { }
    resolve(route: ActivatedRouteSnapshot) {
        this.isPartnerEnabled = environment.ENABLE_PARTNER;
        route.queryParamMap.get('isPartner') === 'true' && environment.ENABLE_PARTNER === true ?
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.IS_PARTNER_BOARD, true)
            : this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.IS_PARTNER_BOARD, false);
        if (route && this.isPartnerEnabled && route.queryParamMap.get('isPartner') === 'true') {
            if (!isNullOrUndefined(route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_EPID))) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_EPID,
                    route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_EPID));
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ORG_ID_KEY, route.queryParamMap.get('B2b-org'));
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY,
                     route.queryParamMap.get('B2b-client'));
                this._cacheSvc.putInSessionStoarage(PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL,
            document.referrer.indexOf('?') !== -1 ? document.referrer.substr(0, document.referrer.indexOf('?')) : document.referrer);
            }
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_AT, route.queryParamMap.get('at'));
            if (_.isNull(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA))) {
                this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                    MakePaymentConstant.FLOW_TYPE.SOH_LINE_DEPOSIT);

                this.setUpRouteSvc.saveParamsInSession(route, COMMON_CONSTANTS.SOH_LINE_DEPOSIT_KEY);
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE, 'true');
                this.setUpRouteSvc.partnerHandShake.next(true);
                return this.setUpRouteSvc.anonymousTokenResolved()
                    .then((token) => {
                        this.setUpRouteSvc._partnerSOHSetupRoute(route, COMMON_CONSTANTS.SOH_LINE_DEPOSIT_KEY);
                    });
            } else {
                return;
            }
        }
    }
}
