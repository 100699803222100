<footer class="application-footer">
  <div class="container">

    <div class="row">
      <div class="col-md-12 col-xs-12 footer-logo-wrapper">
        <img src="/assets/tfb-payment/images/TMO-Business.svg" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <span class="footer-small-text" target="_blank"> {{this.content?.tmobileBussText}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-4 mb-3">
        <a tabindex="65" class="inline_link mb-3" href="{{this.content?.termsAndConditionUrl}}"
          target="_blank">{{this.content?.termsConditionTxt}}</a>
        <a tabindex="66" class="inline_link mb-3" href="{{this.content?.termsOfUseUrl}}"
          target="_blank">{{this.content?.termsOfUseTxt}}</a>
        <a tabindex="67" class="inline_link mb-3" href="{{this.content?.privacyPolicyUrl}}" target="_blank">
          {{this.content?.privacyPolicyTxt}}</a>
        <a tabindex="68" class="inline_link mb-3" href="{{this.content?.returnPolicyUrl}}" target="_blank">
          {{this.content?.returnPolicyTxt}}</a>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 mb-3">
        <a tabindex="69" class="inline_link mb-3" href="{{this.content?.openInternetUrl}}"
          target="_blank">{{this.content?.openInternetTxt}}</a>
        <a tabindex="70" class="inline_link mb-3" href="{{this.content?.consumerUrl}}"
          target="_blank">{{this.content?.consumerInfoTxt}}</a>
        <a tabindex="71" class="inline_link mb-3" href="{{this.content?.publicSafetyUrl}}" target="_blank">
          {{this.content?.publicSafetyTxt}}</a>
        <a tabindex="72" class="inline_link mb-3" href="{{this.content?.accessibilityUrl}}" target="_blank">
          {{this.content?.accessibilityTxt}}</a>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4">
        <a tabindex="73" class="inline_link mb-3" href="javascript:void(0)" *ngIf="isCCPAFlagEnable" (click)="onDNSNavigate();">
          {{this.content?.doNotSellMyPersonalInfoTxt}}</a>
        <a tabindex="74" class="inline_link mb-3" href="{{this.content?.privacyCenterUrl}}" *ngIf="isCCPAFlagEnable" target="_blank">        
          {{this.content?.privacyCenterTxt}}</a>
        <a tabindex="75" class="inline_link mb-3" href="{{this.content?.coverageUrl}}"
          target="_blank">{{this.content?.coverageTxt}}</a>
        <a tabindex="76" class="inline_link mb-3" href="{{supportPageURL}}" target="_blank">
          {{this.content?.supportTxt}}</a>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <ul class="social-links">
          <li>
            <a tabindex="77" href="{{this.content?.linkedInUrl}}" target="_blank" class="Linkedin">
              <img class="social-image" src="/assets/tfb-payment/images/linkedInImage.png" alt="">
            </a>
          </li>
          <li>
            <a  tabindex="78" href="{{this.content?.facebookUrl}}" target="_blank">
              <img class="social-image" src="/assets/tfb-payment/images/facebookImage.png" alt="">
            </a>
          </li>
          <li>
            <a tabindex="79" href="{{this.content?.twitterUrl}}" target="_blank">
              <img class="social-image" src="/assets/tfb-payment/images/TwitterImage.png" alt="">
            </a>
          </li>
        </ul>
      </div>
      <div class="row">
        <span class="footer-small-text" target="_blank"> {{this.content?.tmobileIncTxt}}</span>
      </div>
    </div>
  </div>
</footer>