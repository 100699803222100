import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { PlatformLocation } from "@angular/common";
import { HttpCacheService } from "./services/http-cache.service";
import { COMMON_CONSTANTS } from "./common/core/common.constants";
import * as _ from "lodash";
import { environment } from "src/environments/environment";
import { singleSpaPropsSubject } from "src/single-spa/single-spa-props";
import { PnpClientService } from "src/pnpframework/public-api";
import { Subscription } from "rxjs";
import { UtilsService } from "./services/utils.service";
import { IdleComponent } from "./common/idle/idle.component";
import { RouterOutlet } from "@angular/router";
import { LoaderComponent } from "./loader/loader.component";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    host: { class: "tfb-payment-container" },
    standalone: true,
    imports: [
        LoaderComponent,
        RouterOutlet,
        IdleComponent,
    ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "app";
  paymentForm: UntypedFormGroup;
  billingAccount: any;
  orderId: any;
  orgId: any;
  returnURI: string;
  private _singleSpaSubscription: Subscription;
  private _contextSubscription: Subscription;

  constructor(
    public location: PlatformLocation,
    private _cacheSvc: HttpCacheService,
    private utils: UtilsService,
    private pnpClientService: PnpClientService
  ) {
    if (this.pnpClientService.isRunningInGlobalNav()) {
      return;
    }

    location.onPopState(() => {
      this.returnURI = this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.RETURN_URL_SSP
      );
      if (
        !_.isNull(
          this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.THANKU_PAGE)
        ) &&
        !_.isEmpty(this.returnURI) &&
        (
          this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.IS_PARTNER_BOARD
          ) !== "true"
        )
      ) {
        this.utils.return(this.returnURI, true);
      }
    });
  }

  initTfbPaymentApp() {
    if (!this.pnpClientService.isRunningInGlobalNav()) {
      return;
    }
    this.loadScript(
      "https://maps.googleapis.com/maps/api/js?client=gme-tmobileusa3&channel=tfb&v=3.34&libraries=places&language=en&region=us"
    );
    this.loadScript("https://unpkg.com/window-crypto");
    this.loadScript("assets/tfb-payment/js/encryptUsingRSAOAEP.js");
    this.loadScript("assets/tfb-payment/js/tfb.js");
    this.loadScript("https://www.google.com/recaptcha/api.js?render=explicit");
    this.loadScript("https://unpkg.com/core-js-bundle/minified.js");
    this._singleSpaSubscription = singleSpaPropsSubject.subscribe(
      ({ messageBus }) => {
        this.pnpClientService.connect(messageBus);
        this.pnpClientService.setTitle("Payment App");
      }
    );
    this._contextSubscription = this.pnpClientService.context$.subscribe(
      (pnpContext) => {
        this._cacheSvc.putInSessionStoarage(
          COMMON_CONSTANTS.AUTH_TYPE,
          pnpContext[COMMON_CONSTANTS.AUTH_TYPE] || "iam"
        );
      }
    );
  }

  loadScript(scriptSrc) {
    let node = document.createElement("script"); // creates the script tag
    node.src = scriptSrc; // sets the source (insert url in between quotes)
    node.type = "text/javascript"; // set the script type
    node.async = true; // makes script run asynchronously
    // node.charset = 'utf-8';
    // append to head of document
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  loadScriptMethod(scriptSrc) {
    let node = document.createElement("script"); // creates the script tag
    var inlineScript = document.createTextNode(scriptSrc);
    node.appendChild(inlineScript);
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  ngOnInit() {
    this.initTfbPaymentApp();
    if (environment.production) {
      if (environment.ENABLE_TAGGING) {
        this.loadScript(
          "https://tags.tiqcdn.com/utag/tmobile/nextgen/prod/utag.js"
        );
      }
      if (environment.ENABLE_WALKMEIN) {
        this.loadScriptMethod(
          '(function() {var walkme = document.createElement("script"); walkme.type = "text/javascript"; walkme.async = true; walkme.src = "https://cdn.walkme.com/users/626f6ee16cfe420f8e87a0858d5f7107/test/walkme_626f6ee16cfe420f8e87a0858d5f7107_https.js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig =      {smartLoad:true}   ; })();'
        );
      }
    } else {
      if (environment.ENABLE_TAGGING) {
        this.loadScript(
          "https://tags.tiqcdn.com/utag/tmobile/nextgen/qa/utag.js"
        );
      }
      if (environment.ENABLE_WALKMEIN) {
        this.loadScriptMethod(
          '(function() {var walkme = document.createElement("script"); walkme.type = "text/javascript"; walkme.async = true; walkme.src = "https://cdn.walkme.com/users/626f6ee16cfe420f8e87a0858d5f7107/test/walkme_626f6ee16cfe420f8e87a0858d5f7107_https.js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig =      {smartLoad:true}   ; })();'
        );
      }
    }
  }

  ngOnDestroy(): void {
    if (this.pnpClientService.isRunningInGlobalNav()) {
      this._singleSpaSubscription.unsubscribe();
      this._contextSubscription.unsubscribe();
    }
  }

  // setBillingAccount(value: any){
  //   this.billingAccount = value;
  // }

  // setOrderAccount(value: any){
  //   this.orderId = value;
  // }

  // setOrgAccount(value: any){
  //   this.orgId = value;
  // }
}
