<!-- <input type="text" name="walkMeIn_userID" ng-model="walkMeIn_userID" style="display: none;" /> -->
<div class="container" *ngIf="showLinkExpired">
  <div class="error-screen-wrapper">
    <div class="row">
      <div class="col-12 error-screen-title">
        <img src="../../../assets/tfb-payment/images/icon_error.svg" alt="We are unable to process your transaction.">
        <h1>We are unable to process your transaction.</h1>
      </div>
      <div class="col-12 error-screen-msg">
        <p>Unable to proceed with this request. Please call our Business Team of Experts at 1-800-375-1126 for assistance.</p>
      </div>
      </div>
  </div>
</div>
<div *ngIf="authoringContent">
  <app-header></app-header>
  <div class="container">
    <div class="make-payment-wrapper">
      <div class="row">
        <div class="Make-a-payment col-sm-12 page-title">
          <h1>{{authoringContent?.landingInfo?.paymentHeadingTxt}}</h1>
          <div class="sub-heading">{{titleLabel}}</div>
          <div class="payment-desc">
            <p class="mt-2 d-flex">
            </p>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="media">
            <span class="icons dollor">
              <!-- <em class="fas fa-dollar-sign" aria-hidden="true"></em> -->
              <img
                src="/assets/tfb-payment/images/icons/fa-usd-icon.svg"
                class="payment-info"
              />
            </span>
            <div class="Make-a-payment media-body" style="width: 206px;">
              <h3 class="make-a-payment-heading">{{authoringContent?.paymentAmount?.headingTxt}}</h3>
              <p>
                <span class="paymentAmount">{{paymentAmount | currency: '$'}}</span>
              </p>
            </div>
          </div>
          <div class="media">
            <span class="icons calendar-check">
              <img src="/assets/tfb-payment/images/icons/icon-calendar1.png" class="payment-info">
            </span>
            <div class="Make-a-payment media-body">
              <h3 class="paymentDateHeading">{{ authoringContent?.paymentDate?.headingTxt }}</h3>
              <div>
                <p>
                  <span class="lable-color date-today">
                    {{authoringContent?.paymentDate?.todayTxt}}
                    ({{ todayDate | date: 'longDate' }})
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="media payInfoMargin">
            <span class="icons credit-card">
              <img id="cc-img" class="payment-info" src="/assets/tfb-payment/images/icons/payment_info_black.PNG" alt="">
            </span>
            <div class="Make-a-payment media-body" style="width: 252px">
              <h3 id="payment-info-header" class="pb-0">{{authoringContent?.paymentInfo.headingTxt}}</h3>
              <div class="radio-btn-box">
                <app-credit-card *ngIf="isCcOptionSelected"></app-credit-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="media md-md-0">
        <span class="spacerBlk"></span>
        <div class="Make-a-payment media-body col-md-7 col-sm-10 col-xs-9 pl-0">
          <app-terms-and-conditions></app-terms-and-conditions>
        </div>
      </div>
      <app-recaptcha-v2 *ngIf="!disableRecaptcha()" (onPass)="setToken($event)">
      </app-recaptcha-v2>
      <div class="button-row row mrg-bm-20">
        <div class="col-lg-6 offset-lg-6 col-md-12 btn-wrapper text-md-center text-lg-right btn-wrapperMargin">
          <button class="primary_btn" id="submit-button" [disabled]="!recaptchaToken"
                  (click)="onSubmit()">{{authoringContent?.landingInfo.submitOrderButtonText}}</button>
        </div>
      </div>
    </div>
    <div class="media mb-5">
      <span class="spacerBlk"></span>
      <div class="Make-a-payment media-body col-md-8 col-sm-10 col-xs-9 pl-0">
        <a class="entrust-logo inline_link d-inline-block ml-0 mr-2" (click)="onEntrust()" tabindex="0">
          <img src="./assets/tfb-payment/images/icons/entrust-site-seal-small.png" alt="Secured by Entrust SSL Verify" />
        </a>
        <div class="entrust-text d-inline-block align-middle ">
          {{authoringContent?.landingInfo?.enTrustTxt}}
          <a class="entrust-textlink" href="{{authoringContent?.landingInfo?.enTrustPrivacyUrl}}" tabindex="0"
             target="_blank">{{authoringContent?.landingInfo?.enTrustTxt1}}</a>
        </div>
      </div>
    </div>
  </div>
  <app-payment-error-popup (showSubHeadertxtChange)="showSubHeadertxt=$event"></app-payment-error-popup>
  <app-footer *ngIf="!isRunningInGlobalNav && authoringContent"></app-footer>
</div>
