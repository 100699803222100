import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AutoPaymentService } from '../../services/auto-payment.service';
import { ContentService } from '../../services/content.service';
import { EnvironmentDetailsService } from '../../services/environment-details.service';
import { HttpCacheService } from '../../services/http-cache.service';
import { COMMON_CONSTANTS, monthJSON } from '../../common/core/common.constants';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { UtilsService } from '../../services/utils.service';
import { ClickEventType } from 'src/app/common/core/common.interface';
import { PDLService } from 'src/app/services/pdl.service';
import { NgStyle, NgIf } from '@angular/common';
import { PipedOperatorsService } from 'src/app/common/pipes/customOperators/pipedOperatorsService.service';

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss'],
    standalone: true,
    imports: [NgStyle, NgIf]
})
export class SuccessComponent implements OnInit {

  authoringContent: any;
  returnURI: string;
  emailId: any;
  balPaytoken: any;
  currentDate: any = new Date();
  dueDate: any;
  display = COMMON_CONSTANTS.BLOCK;
  successDescription: any = '';
  successDateDetails: any = '';
  enrollDate: any;
  successDescriptionForManage: any = '';
  successDateDetailsForManage: any = '';
  autoPayType: any = '';
  isPartnerFlow: boolean = false;
  isBillingPartnerFlow: boolean = false;

  constructor(public _autoPaymentSvc: AutoPaymentService, private pdlService: PDLService, private contentService: ContentService,
    private _envSvc: EnvironmentDetailsService, private _cacheSvc: HttpCacheService, private utils: UtilsService,private _changeDetect:ChangeDetectorRef,
    private pipedOperatorsService: PipedOperatorsService) { }

  ngOnInit() {
    this.returnURI = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.RETURN_URL_SSP);
    if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('contentmain'))) {
      const data = JSON.parse(this._cacheSvc.getFromSessionStorage('contentmain'));
      this.authoringContent = data.par.common_payment.autoPay;
    } else {
      this.contentService.GetContentData().subscribe(data => {
        this.authoringContent = data.par.common_payment.autoPay;
      });
    }
   if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === 'true'){
       this.isPartnerFlow = true;
    }
   if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW) === 'true'){
        this.isBillingPartnerFlow = true;
      }
    // To get the balance due date
    // this.balPaytoken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BALANCE_TOKEN);
    // this.balPaytoken = !isNullOrUndefined(this.balPaytoken) ? this.balPaytoken : '';
    this._autoPaymentSvc.getBalanceApi().pipe(this.pipedOperatorsService.subReattempt()).subscribe(data => {
      this.dueDate = new Date(data.billingAccounts[0].billInfo.dueDate);
      this.enrollDate = new Date(data.billingAccounts[0].billInfo.dueDate);
      this.updatedDateDescription();
    }, err => {
      console.log(err);
    });
    this.updatedSuccessDescription();
  }

  public updatedSuccessDescription() {
    this.autoPayType = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.FLOW_KEY)) ?
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.FLOW_KEY) : COMMON_CONSTANTS.ENROLL_FLOW;
    this.emailId = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA) ?
      JSON.parse(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)).logonId : '';
    if (this.autoPayType === COMMON_CONSTANTS.ENROLL_FLOW) {
      if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.PAYMENT_TYPE) === COMMON_CONSTANTS.BANK_TXT) {
        if (this.isPartnerFlow) {
          this.successDescription = this.authoringContent.successDescriptionBankPartner;
        } else {
          this.successDescription = this.authoringContent.successDescriptionBank;
        }
      }
      if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.PAYMENT_TYPE) === COMMON_CONSTANTS.CARD_TXT) {
        if (this.isPartnerFlow) {
          this.successDescription = this.authoringContent.successDescriptionCardPartner;
        } else {
          this.successDescription = this.authoringContent.successDescriptionCard;
        }
      }
      if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.PAYMENT_TYPE) === COMMON_CONSTANTS.TYPE_CREDIT_CARD) {
        if (this.isPartnerFlow) {
          this.successDescription = "This account has successfully been set up for AutoPay using the credit card provided.";  
        } else {
          this.successDescription = "This account has successfully been set up for AutoPay using the credit card provided. A confirmation email has been sent to [EMAILID].";
        }
      }
      if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.PAYMENT_TYPE) === COMMON_CONSTANTS.TYPE_DEBIT_CARD) {
        if (this.isPartnerFlow) {
          this.successDescription = "This account has successfully been set up for AutoPay using the debit card provided.";
        } else {
          this.successDescription = "This account has successfully been set up for AutoPay using the debit card provided. A confirmation email has been sent to [EMAILID].";
        }
      }
      if (!this.isPartnerFlow) {
        this.successDescription =
          this.successDescription.replace('[EMAILID]', '<b>' + this.emailId + '</b>') ;
      }
      this._cacheSvc.deleteFromSessionStoarage(COMMON_CONSTANTS.PAYMENT_TYPE);
      return this.successDescription;
    }

    if (this.autoPayType === COMMON_CONSTANTS.MANAGE_FLOW) {
      if (this.isPartnerFlow) {
        this.successDescriptionForManage = this.authoringContent.partnerSuccessDescriptionForManage
      } else {
        this.successDescriptionForManage = this.authoringContent.successDescriptionForManage;
        this.successDescriptionForManage =
          this.successDescriptionForManage.replace('[EMAILID]', '<b>' + this.emailId + '</b>') ;
      }
      this._cacheSvc.deleteFromSessionStoarage(COMMON_CONSTANTS.PAYMENT_TYPE);
      return this.successDescriptionForManage;
    }

  }

  public updatedDateDescription() {
    if (this.autoPayType === COMMON_CONSTANTS.ENROLL_FLOW) {
      this.enrollDate.setDate(this.enrollDate.getDate() - 2);
      if ((this.currentDate < this.enrollDate)) {
        this.successDateDetails = this.authoringContent.successDateDetails1.replace(COMMON_CONSTANTS.AUTO_DATE, '<b>' +
          monthJSON[(this.dueDate.getMonth())].name + ' ' + (this.dueDate.getDate()) + ', ' + this.dueDate.getFullYear() + '</b>');
      } else if ((this.currentDate >= this.enrollDate) && this.currentDate < this.dueDate) {
        this.successDateDetails = this.authoringContent.successDateDetails2;
      } else {
        this.successDateDetails = this.authoringContent.successDateDetails3;
      }
      this._changeDetect.detectChanges();
      return this.successDateDetails;
    }
    if (this.autoPayType === COMMON_CONSTANTS.MANAGE_FLOW) {
      this.enrollDate.setDate(this.enrollDate.getDate() - 2);
      if ((this.currentDate < this.enrollDate)) {
        this.successDateDetailsForManage = this.authoringContent.successDateDetailsForManage;
      } else {
        this.successDateDetailsForManage = this.authoringContent.successDateDetailsForManage1;
      }
      this._changeDetect.detectChanges();
      return this.successDateDetailsForManage;
    }
  }

  onCloseHandled() {
    // analytics click tagging
    this.generateClickDetailsForSuccess("click", "button", ClickEventType.NAVIGATE, "Close", "Success Modal")
    if( this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW) == 'true'){
      this.utils.redirectToPartnerBillingApp();
      } else {
          this.utils.return(this.returnURI, true);
      }
  }

  generateClickDetailsForSuccess(actionInput: string, assetTypeInput: string, typeInput: ClickEventType, ctaNameInput: string, modalNameInput: string) {
    let clickDetail = {
      action: actionInput,
      assetType: assetTypeInput,
      type: typeInput,
      ctaName: ctaNameInput,
      modalName: modalNameInput
    };
    
    this.pdlService.generateClickTag(clickDetail);
  }

}
