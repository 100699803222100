import { ErrorHandler, Injectable} from '@angular/core';
import { SplunkUtilityService } from '../utilities/splunk-utility.service';
import { NgxLoggerLevel } from 'ngx-logger';
import { COMMON_CONSTANTS } from '../common/core/common.constants';


@Injectable()

export class GlobalErrorHandler implements ErrorHandler {
  constructor(private splunkSvc: SplunkUtilityService) { }
  handleError(error) {
    const errorType = COMMON_CONSTANTS.SPLUNK_CLIENTSIDE_ERROR;
    const req =  {url: '/splunk-logs/services/collector/event'};
    const errorMsg = error.stack  ? error.stack : error;
    this.splunkSvc.logInSplunk(NgxLoggerLevel.ERROR, req, errorMsg, errorType);
    throw error;
  }
}
