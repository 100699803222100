import { NgModule } from "@angular/core";
import { APP_BASE_HREF } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { MakePaymentComponent } from "./makePayment/make-payment/make-payment.component";
import { CreditCardComponent } from "./credit-card/credit-card.component";
import { AchComponent } from "./ach/ach.component";
import { PaymentResolver } from "./services/payment.resolver";
import { PaymentAuthResolver } from "./services/paymentauth.resolver";
import { ThankuScreenComponent } from "./makePayment/thanku-screen/thanku-screen.component";
import { SwatchResolver } from "./services/swatch.resolver";
import { AutoPaymentComponent } from "./autoPayment/auto-payment/auto-payment.component";
import { AutopayAuthResolver } from "./services/autopay.resolver";
import { CancelComponent } from "./autoPayment/cancel/cancel.component";
import { ErrorScreenComponent } from "./makePayment/error-screen/error-screen.component";
import { SuccessComponent } from "./autoPayment/success/success.component";
import { OktaCallbackComponent } from "./okta-callback/okta-callback.component";
import { SimOnHandComponent } from "./sim-on-hand/sim-on-hand.component";
import { SimonhandResolver } from "./services/simonhand.resolver";
import { IdleErrorScreenComponent } from "./idle-error-screen/idle-error-screen.component";
import { RootComponent } from "./root/root.component";
import { EmptyRouteComponent } from "./empty-route/empty-route.component";
import { EipStandalonePaymentComponent } from "./eip-standalone-payment/eip-standalone-payment.component";

export const appRoutes: Routes = [
  {
    path: "apps/tfb_payment",
    component: RootComponent,
    pathMatch: "full",
  },
  {
    path: "apps/tfb_payment/payment/makepayment",
    component: MakePaymentComponent,
    resolve: { message: PaymentAuthResolver },
  },
  {
    path: "apps/tfb_payment/payment/makeeippayment",
    component: EipStandalonePaymentComponent,
    resolve: { message: PaymentAuthResolver },
  },
  {
    path: "apps/tfb_payment/payment/creditcard",
    component: CreditCardComponent,
    resolve: { message: SwatchResolver },
  },
  {
    path: "apps/tfb_payment/payment/ach",
    component: AchComponent,
    resolve: { message: SwatchResolver },
  },
  {
    path: "apps/tfb_payment/thankuScreen",
    component: ThankuScreenComponent,
  },
  {
    path: "apps/tfb_payment/errorScreen",
    component: ErrorScreenComponent,
  },
  {
    path: "apps/tfb_payment/idleErrorScreen",
    component: IdleErrorScreenComponent,
  },
  {
    path: "apps/tfb_payment/payment/autopayment",
    component: AutoPaymentComponent,
    resolve: { message: AutopayAuthResolver },
  },
  {
    path: window["IS_GLOBAL_NAV"]
      ? "apps/tfb_payment/implicit/callback"
      : "implicit/callback",
    component: OktaCallbackComponent,
    resolve: { message: PaymentResolver },
  },
  {
    path: "apps/tfb_payment/payment/cancelpayment",
    component: CancelComponent,
  },
  {
    path: "apps/tfb_payment/payment/successpayment",
    component: SuccessComponent,
  },
  {
    path: "apps/tfb_payment/payment/lineactivationdeposit",
    component: SimOnHandComponent,
    resolve: { SimonhandResolver },
  },
  {
    path: "**",
    component: EmptyRouteComponent
  },
  {
    path: "apps/tfb_payment/creditcard",
    redirectTo: "apps/tfb_payment/payment/creditcard",
  },
  {
    path: "apps/tfb_payment/ach",
    redirectTo: "apps/tfb_payment/payment/ach",
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(appRoutes)],
  providers: [{ provide: APP_BASE_HREF, useValue: "/" }],
})
export class AppRoutingModule {}
