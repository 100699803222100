import { Component } from "@angular/core";

@Component({
    selector: "tfb-payment-root-component",
    templateUrl: "./root.component.html",
    styleUrls: ["./root.component.scss"],
    standalone: true
})
export class RootComponent {
}
