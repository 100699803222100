import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { COMMON_CONSTANTS } from '../common/core/common.constants';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  constructor() { 
    // This is intentional
  }

  private sharedData = new BehaviorSubject<any>(COMMON_CONSTANTS.HANDSHAKE_ERROR_MSG);
  shareData = this.sharedData.asObservable();

  private sharedDataPartner = new BehaviorSubject<any>("You don't have permission to access on this server.");
  shareDataPartner = this.sharedDataPartner.asObservable();

  sendData(data: any) {
    this.sharedData.next(data);
  }

}
