import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HttpCacheService {


    private static CacheFactory: any[] = [];

    public static SetCache(key, value) {
        this.CacheFactory[key] = value;
    }

    public static GetCache(key): any {
        return this.CacheFactory[key];
    }

    public static ClearCacheKey(key) {
        this.CacheFactory.splice(this.CacheFactory.indexOf([key]), 1);
    }

    getFromLocalStorage(key): any {
        return localStorage.getItem(key);
    }

    putInLocalStoarage(key, value) {
        localStorage.setItem(key, value);
    }

    getFromSessionStorage(key): any {
        return sessionStorage.getItem(key);
    }

    putInSessionStoarage(key, value) {
        sessionStorage.setItem(key, value);
    }
    deleteFromLocalStoarage(key) {
        localStorage.removeItem(key);
    }
    deleteFromSessionStoarage(key) {
        sessionStorage.removeItem(key);
    }
    clearLocalStorage() {
        localStorage.clear();
    }
    clearSessionStorage() {
        sessionStorage.clear();
    }
}
