import { Injectable } from '@angular/core';
import { AuthHttpService } from './http-wrapper.service';
import { EnvironmentDetailsService } from './environment-details.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpCacheService } from './http-cache.service';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { COMMON_CONSTANTS, CreditCardConstants } from '../common/core/common.constants';
import { environment } from '../../environments/environment';
import {
  RequestPayLoadAutoPay, DocumentDetailAutoPay, AutoPaymentProfile,
  CancelAutoPayCCModal
} from '../common/models/data.model';


@Injectable({
  providedIn: 'root'
})
export class AutoPaymentService {
  // requestPayload: any;
  requestPayload: RequestPayLoadAutoPay = new RequestPayLoadAutoPay();
  requestPayProfile: AutoPaymentProfile = new AutoPaymentProfile();
  requestCancelEnroll: CancelAutoPayCCModal = new CancelAutoPayCCModal();
  documentDetail: DocumentDetailAutoPay = new DocumentDetailAutoPay();
  handShakeData: any;
  banNumber: any;
  authToken: any;
  orgId: any;
  logonID: any;
  changeRquestID: any;
  buttonValue: any;
  emailID: any;

  cancelComponent = false;
  successComponent = false;
  autoPayComponent = true;
  showTC = false;
  showAutopay = false;

  constructor(private httpClient: AuthHttpService, private _envSvc: EnvironmentDetailsService,
    private _cacheSvc: HttpCacheService) {
    this.handShakeData = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)) ?
      JSON.parse(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)) : '';
    this.banNumber = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY)) ?
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY) : '';
    this.banNumber = btoa(this.banNumber);
    this.authToken = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY)) ?
      COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY) : '';
    this.orgId = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY)) ?
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY) : '';
    this.logonID = !isNullOrUndefined(this.handShakeData.firstName && this.handShakeData.lastName) ?
      this.handShakeData.firstName + ' ' + this.handShakeData.lastName : '' + '';
    this.emailID = !isNullOrUndefined(this.handShakeData.logonId) ? this.handShakeData.logonId : '';
  }

  setHeaders() {
    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);

    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.ACCEPT_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
      COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken;

    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
          ? true : false;
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
        headerSettings['eid'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID);
        headerSettings[COMMON_CONSTANTS.QUERY_PARAM_DS] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS);
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
    }

    return {headers: new HttpHeaders(headerSettings)};
  }
  
  updatedHeaders(httpMethod: string) {
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    const headerSettings: { [name: string]: string | string[]; } = {};
    this.commonHeaders(isPartnerOnboard, headerSettings, httpMethod);
    
     return {headers: new HttpHeaders(headerSettings)};
  }
  commonHeaders(isPartnerOnboard : boolean, headerSettings: any, httpMethod: string) {
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
      this.authToken;
    headerSettings['userid'] = COMMON_CONSTANTS.DIGIT_TWELEVE;
    headerSettings['activityid'] = COMMON_CONSTANTS.DIGIT_TWELEVE;
    headerSettings['channelid'] = COMMON_CONSTANTS.HEADER_PROPERTIES.AP_CHANNEL_ID;
    if (isPartnerOnboard) {
        headerSettings['applicationid'] = COMMON_CONSTANTS.HEADER_PROPERTIES.PARTNER_APPLICATION_ID_KEY;
    } else {
        headerSettings['applicationid'] = COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY;
    }
    headerSettings['method'] = httpMethod;
  }
  /// START - Sent below headers in all scenarios - FBEPTD-78312
  updatedv4Headers(httpMethod: string) {
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    const headerSettings: { [name: string]: string | string[]; } = {};
    this.commonv4Headers(isPartnerOnboard, headerSettings, httpMethod);
     return {headers: new HttpHeaders(headerSettings)};
  }
  commonv4Headers(isPartnerOnboard : boolean, headerSettings: any, httpMethod: string) {
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
      this.authToken;
    headerSettings['userid'] = COMMON_CONSTANTS.DIGIT_TWELEVE;
    headerSettings['user-id'] = COMMON_CONSTANTS.DIGIT_TWELEVE;
    headerSettings['channel-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.AP_CHANNEL_ID;
    if (isPartnerOnboard) {
        headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.PARTNER_APPLICATION_ID_KEY;
    } else {
        headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY;
    }
    headerSettings['method'] = httpMethod;

    headerSettings['activity-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    headerSettings['interaction-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    headerSettings['workflow-id'] = COMMON_CONSTANTS.WORKFLOW_ID_LINE_DEPOSIT;
    headerSettings['session-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID)){
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_USER_ID]=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID);
    }
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
        headerSettings['eid'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID);
        headerSettings[COMMON_CONSTANTS.QUERY_PARAM_DS] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS);
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
    }
  }
  /// END - Sent below headers in all scenarios - FBEPTD-78312

  updatedv5Headers(httpMethod: string) {
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    const headerSettings: { [name: string]: string | string[]; } = {};
    this.commonv5Headers(isPartnerOnboard, headerSettings, httpMethod);
     return {headers: new HttpHeaders(headerSettings)};
  }
  commonv5Headers(isPartnerOnboard : boolean, headerSettings: any, httpMethod: string) {
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
      this.authToken;
    headerSettings['userid'] = COMMON_CONSTANTS.DIGIT_TWELEVE;
    headerSettings['user-id'] = COMMON_CONSTANTS.DIGIT_TWELEVE;
    headerSettings['channel-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.AP_CHANNEL_ID;
    if (isPartnerOnboard) {
        headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.PARTNER_APPLICATION_ID_KEY;
    } else {
        headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY;
    }
    headerSettings['method'] = httpMethod;

    headerSettings['activity-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    headerSettings['interaction-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    // headerSettings['workflow-id'] = COMMON_CONSTANTS.WORKFLOW_ID_LINE_DEPOSIT;
    headerSettings['session-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    headerSettings['sender-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY;
    if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID)){
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_USER_ID]=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID);
    }
  }

  enrollAutoPayment(requestPayload: any) {
    let enrollAutoPayUrl;
    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(enrollAutoPayUrl);
    } else {
      if (requestPayload.isEditmode) {
        const httpOptions = this.updatedHeaders("PUT");
        enrollAutoPayUrl = Object.assign({}, this._envSvc.CURR_SESSION_ENDPOINTS.MANAGE_AUTO_PAY_SERVICE_URL_PARTNER);
        return this.httpClient.put(enrollAutoPayUrl, requestPayload, httpOptions);
      } else {
        const httpOptions = this.updatedv4Headers("POST");
        enrollAutoPayUrl = Object.assign({}, this._envSvc.CURR_SESSION_ENDPOINTS.ENROLL_AUTO_PAY_SERVICE_URL_PARTNER);
        return this.httpClient.post(enrollAutoPayUrl, requestPayload, httpOptions);
      }
    }
  }

  getAccountDetails(requestPayload : any){
    const httpOptions = this.updatedv4Headers("POST");
    let autoPayUrl = Object.assign({}, this._envSvc.APIGEE_TOKEN_URLS.ACCOUNT_DETAILS);
    return this.httpClient.post(autoPayUrl, requestPayload,httpOptions); 
  }

  manageEnrollAutoPayment(requestPayload: any) {
    const httpOptions = this.updatedv4Headers("PUT");
    let manageAutoPayUrl;
    manageAutoPayUrl = Object.assign({}, this._envSvc.CURR_SESSION_ENDPOINTS.MANAGE_AUTO_PAY_SERVICE_URL_PARTNER);
    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(manageAutoPayUrl);
    } else {
      return this.httpClient.put(manageAutoPayUrl, requestPayload, httpOptions);
    }

  }

  cancelEnrollAutoPayment(requestPayload: any) {
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    const headerSettings: { [name: string]: string | string[]; } = {};
      this.commonv4Headers(isPartnerOnboard, headerSettings, "POST");
      const httpHeaders = {
      headers: new HttpHeaders(headerSettings),
      
    };

    let deleteAutoPayUrl;
    deleteAutoPayUrl = Object.assign({}, this._envSvc.CURR_SESSION_ENDPOINTS.DELETE_AUTO_PAY_SERVICE_URL_PARTNER);
    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(deleteAutoPayUrl);
    } else {
      return this.httpClient.post(deleteAutoPayUrl,requestPayload, httpHeaders);
    }

  }

  getAutopayEligibility() {
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    this.banNumber = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY)) ?
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY) : '';
    this.banNumber = btoa(this.banNumber);
    const httpOptions = this.setHeaders();
    const payType = 'auto-pay';

    if (!isPartnerOnboard && environment.DISABLE_ACCESS_TOKEN_CALL === false) {
      const autoPayEligibilityUrl = this.replaceBanInUrl(this._envSvc.CURR_SESSION_ENDPOINTS.GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL.path);
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.CURR_SESSION_ENDPOINTS.GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL);
      } else {
        this._envSvc.CURR_SESSION_ENDPOINTS.GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL.path =
          autoPayEligibilityUrl + '?pay-type=' + payType + '&org=' + this.orgId;
        return this.httpClient.get(this._envSvc.CURR_SESSION_ENDPOINTS.GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL, httpOptions);
      }
    } else {
      const body = { billingAccount: atob(this.banNumber)};
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.CURR_SESSION_ENDPOINTS.GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL_PARTNER);
      } else {
        return this.httpClient.post(this._envSvc.CURR_SESSION_ENDPOINTS.GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL_PARTNER, body, httpOptions);
      }
    }
  }

  getAutoPaymentProfile() {
    
    const httpOptions = this.updatedv4Headers("POST");
    this.requestPayProfile.payment.businessUnit = CreditCardConstants.BUSINESS_UNIT;
    this.requestPayProfile.payment.programCode = CreditCardConstants.PROGRAM_CODE;
    this.requestPayProfile.payment.billerCode = CreditCardConstants.BILLER_CODE;
    this.requestPayProfile.payment.businessSegment = CreditCardConstants.BUSINESS_SEGMENT;
    this.requestPayProfile.customerReference.customerId =
      !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY)) ?
        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY) : '';

    const autoPaymentUrl = Object.assign({}, this._envSvc.CURR_SESSION_ENDPOINTS.AUTO_PAY_PAYMENT_PROFILE_SERVICE_URL);

    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(autoPaymentUrl);
    } else {
      return this.httpClient.post(autoPaymentUrl, this.requestPayProfile, httpOptions);
    }
  }

  replaceBanInUrl(path: any) {
    return path.replace(COMMON_CONSTANTS.BILLING_ACCOUNT, this.banNumber);
  }

  // US480034 - New Balance API Change
  // getUpdatedBalance() {
  //   const httpOptions = {
  //     headers: new HttpHeaders(
  //       {
  //         'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
  //         'Authorization': this.authToken,
  //         'Accept': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
  //         'activityid': COMMON_CONSTANTS.ACTIVITY_ID,

  //       }
  //     )
  //   };

  // US480034 - New Balance API Change
  getBalanceApi() {
    const httpOptions = this.setHeaders();

    const requestPayload = {
      'billingAccounts': [
        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY)
      ]
    };
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_BALANCE_PARTNER);
      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_BALANCE_PARTNER, requestPayload, httpOptions);
      }
    } else {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_BALANCE);
      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_BALANCE, requestPayload, httpOptions);
      }
    }
  }

  autopayDiscountEligibility(requestPayload: any) {
    // console.log("requestPayload=========",requestPayload)
    let autopayDiscountUrl;
    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(autopayDiscountUrl);
    } else {
      
      const httpOptions = this.updatedv5Headers("POST");
      autopayDiscountUrl = Object.assign({}, this._envSvc.CURR_SESSION_ENDPOINTS.GET_AUTO_PAY_DISCOUNT_ELIGIBILITY_SERVICE_URL_PARTNER);
      return this.httpClient.post(autopayDiscountUrl, requestPayload, httpOptions);
     
    }
  }
}
