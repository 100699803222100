import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { SetupRouteService } from './setup-route.service';
import { OktaAuthorizationService } from './okta.auth.service';
import { HttpCacheService } from './http-cache.service';
import { COMMON_CONSTANTS, MakePaymentConstant, PDL_CONSTANTS } from '../common/core/common.constants';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { PnpClientService } from 'src/pnpframework/public-api';

@Injectable()
export class PaymentAuthResolver  {
    isResolved: boolean;
    isLoaded: boolean;
    BanNumber: string;
    OrgID: string;
    isPartnerEnabled: any;
    transactionID: any;
    constructor(private setUpRouteSvc: SetupRouteService,
        private _oktaAuthSvc: OktaAuthorizationService, private _cacheSvc: HttpCacheService,
        private _pnpClientService: PnpClientService
        ) { }
    async resolve(route: ActivatedRouteSnapshot) { 
        this.isPartnerEnabled = environment.ENABLE_PARTNER;
        route.queryParamMap.get('isPartner') === 'true' && environment.ENABLE_PARTNER === true ?
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.IS_PARTNER_BOARD, true) : this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.IS_PARTNER_BOARD, false);
        if (route && this.isPartnerEnabled && route.queryParamMap.get('isPartner') === 'true') {
            const async=route.queryParamMap.get(COMMON_CONSTANTS.QUERY_PARAM_ASYNC)==='true';
            if(!async){
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY, route.queryParamMap.get('at'));
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_AT, route.queryParamMap.get('at'));
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY, route.queryParamMap.get('ts'));
            }
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_ASYNC, async);
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ORG_ID_KEY, route.queryParamMap.get('B2b-org'));
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_EPID, route.queryParamMap.get('epid'));
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_DS, route.queryParamMap.get('ds'));
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_TS, route.queryParamMap.get('ts'));
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY, route.queryParamMap.get('B2b-client'));
            this._cacheSvc.putInSessionStoarage(PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL, document.referrer.indexOf('?') != -1 ? document.referrer.substr(0, document.referrer.indexOf('?')): document.referrer);
            if (!(sessionStorage.getItem(COMMON_CONSTANTS.TRANSACTIONID))) {
                const now = new Date();
                this.transactionID = now.valueOf();
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.TRANSACTIONID, this.transactionID);
            }
            route.queryParamMap.get('isBillingPartner') === 'true' ? this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW, true) : this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW, false);
            const tokenResolved= async?this.setUpRouteSvc.anonymousTokenResolved():this.setUpRouteSvc.tokenResolved();
            return tokenResolved.then((token) => {
                    this.setUpRouteSvc._partnerSetupRoute(route, COMMON_CONSTANTS.MAKEPAY_KEY);
                });

            // tslint:disable-next-line: max-line-length
        } else if (route && isNullOrUndefined(route.queryParamMap.get('isPartner'))) {
            if (!isNullOrUndefined(route.queryParamMap.get(COMMON_CONSTANTS.PID_KEY))) {
                this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.PID_KEY,
                    route.queryParamMap.get(COMMON_CONSTANTS.PID_KEY));
                    this._cacheSvc.putInSessionStoarage(PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL, document.referrer.indexOf('?') != -1 ? document.referrer.substr(0, document.referrer.indexOf('?')): document.referrer);
            }
                this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                    MakePaymentConstant.FLOW_TYPE.ONETIME_PAYMENT);
                if (environment.SSO_ON) {
                    this.setUpRouteSvc.saveParamsInSession(route, COMMON_CONSTANTS.MAKEPAY_KEY);
                    if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) === COMMON_CONSTANTS.OKTA_KEY) {
                        if (this._pnpClientService.isRunningInGlobalNav()) {
                            return this.setUpRouteSvc.tokenResolvedOkta();
                        }
                        if (!(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.OKTA_ACCESS_TOKEN_KEY))) {
                            this._oktaAuthSvc.loginWithOkta();
                        }
                    } 
                    else if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) === COMMON_CONSTANTS.AZURE_KEY){
                        this.setUpRouteSvc.tokenResolvedAzure();
                    }
                    else if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) === COMMON_CONSTANTS.IAM_KEY) {
                        if (this._pnpClientService.isRunningInGlobalNav()) {
                            return this.setUpRouteSvc.tokenResolved().then(() => {
                                return this._pnpClientService.getTokens().then((token) => {
                                    this.setUpRouteSvc.handleTokenData();
                                  });      
                            });
                        }
                        if (!_.isNull(route) && !_.isNull(route.queryParamMap.get(COMMON_CONSTANTS.PID_KEY))) {
                            this.setUpRouteSvc.euiLoginRedirect();
                        } else {
                            return this.setUpRouteSvc.tokenResolved()
                                .then((token) => {
                                    this.setUpRouteSvc._setupRoute(route.queryParams, COMMON_CONSTANTS.MAKEPAY_KEY);
                                });
                        }
                    }
                } else {
                    this.setUpRouteSvc.saveParamsInSession(route, COMMON_CONSTANTS.MAKEPAY_KEY);
                    this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE, 'true');
                    const EUIdata = {"firstName":"XYZ","lastName":"ABC","logonId":"XYZ@test.com","msisdn":"","status":"SUCCESS","userType":"test","uuid":"test uuid"};
                    this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.EUI_TOKEN_DATA, JSON.stringify(EUIdata));
                    this.setUpRouteSvc.partnerHandShake.next(true);
                    if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) === COMMON_CONSTANTS.OKTA_KEY) {
                        if (this._pnpClientService.isRunningInGlobalNav()) {
                            return this.setUpRouteSvc.tokenResolvedOkta();
                        }

                        if (!(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.OKTA_ACCESS_TOKEN_KEY))) {
                            this._oktaAuthSvc.loginWithOkta();
                        }
                    } else if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) === COMMON_CONSTANTS.IAM_KEY) {
                        return this.setUpRouteSvc.tokenResolved();
                    }
                }
        }
    }
}
