import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild, Injector, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { SplunkUtilityService } from 'src/app/utilities/splunk-utility.service';
import { Router } from '@angular/router';
import {
  COMMON_CONSTANTS,
  MakePaymentConstant,
  ACH_CONSTANTS,
  CREDIT_CARD_CONSTANTS,
  PDL_CONSTANTS
} from '../../common/core/common.constants';
// tslint:disable-next-line:max-line-length
import {
  BankPaymentValidation,
  PaymentValidation,
  CardEnrlMnjAutopayModal,
  BankEnrlMnjAutopayModal,
  UpdateCrOrderPaymentModel,
  LegalDocument,
  CCBillPayAliasObj,
  BankBillPayAliasObj,
  Specifications,
  accountDetailsRequestData
} from '../../common/models/data.model';
import { AutoPaymentService } from '../../services/auto-payment.service';
import { ContentService } from '../../services/content.service';
import { EnvironmentDetailsService } from '../../services/environment-details.service';
import { HttpCacheService } from '../../services/http-cache.service';
import { TermAndConditionService } from '../../services/term-and-condition.service';
import {
  ErrorMessageService,
  ServiceMessage
} from '../../services/error-message.service';
import { UtilsService } from '../../services/utils.service';
import { CreditCardService } from '../../services/credit-card.service';
import { ACHService } from '../../services/ach.service';
import { SetupRouteService } from 'src/app/services/setup-route.service';
import { environment } from '../../../environments/environment';
import { MakePaymentService } from 'src/app/services/make-payment.service';
import { BillPayService } from '../../services/bill-pay.service';
import { PaymentErrorPopupComponent } from '../../common/payment-error-popup/payment-error-popup.component';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import { PDLService } from '../../services/pdl.service';
import { ClickEventType } from 'src/app/common/core/common.interface';
import { PnpClientService } from 'src/pnpframework/public-api';
import { staticContent } from "../../../environments/environment.content";
import { LoaderService } from 'src/app/services/loader.service';
import { PipedOperatorsService } from 'src/app/common/pipes/customOperators/pipedOperatorsService.service';
import { FooterComponent } from '../../footer/footer.component';
import { CancelComponent } from '../cancel/cancel.component';
import { EnrollCreditCardComponent } from '../enroll-credit-card/enroll-credit-card.component';
import { SuccessComponent } from '../success/success.component';
import { CreditCardComponent } from '../../credit-card/credit-card.component';
import { AchComponent } from '../../ach/ach.component';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { HeaderComponent } from '../../header/header.component';
import { NgIf, NgStyle } from '@angular/common';

declare const encrypt;

@Component({
    selector: 'app-auto-payment',
    templateUrl: './auto-payment.component.html',
    styleUrls: ['./auto-payment.component.scss'],
    standalone: true,
    imports: [NgIf, HeaderComponent, MatIcon, FormsModule, AchComponent, CreditCardComponent, SuccessComponent, EnrollCreditCardComponent, CancelComponent, NgStyle, FooterComponent, PaymentErrorPopupComponent]
})
export class AutoPaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  
  contentService : ContentService;
  sanitizer : DomSanitizer;
  utils : UtilsService;
  creditService : CreditCardService;
  achService : ACHService;
  billPaySvc: BillPayService;
  router: Router;
  pdlService: PDLService;
  pnpClientService: PnpClientService;
  splunkSvc: SplunkUtilityService;
  _envSvc: EnvironmentDetailsService;
  _tcSvc: TermAndConditionService;
  _autoPaymentSvc: AutoPaymentService;
  _cacheSvc: HttpCacheService;
  _errMsgService: ErrorMessageService;
  _routeService: SetupRouteService;
  _makepymntSvc: MakePaymentService;
  _getBanDetails$: Subscription;
  _loaderSvc:LoaderService;
  authoringContent: any;
  AchConstants: any;
  autoPaySelectedOption: any;
  paymementInfo = '';
  achSavedData: BankPaymentValidation = new BankPaymentValidation();
  saveSuccess = false;
  saveSuccessACHType = '';
  saveSuccessCreditType = '';
  bankAccountNumber = '';
  creditCardNumber = '';
  ccSavedData: PaymentValidation = new PaymentValidation();
  selectedPaymentType: any = '';
  action: string;
  showAutopay = false;
  autopayTC: any;
  autopayTCSub: Subscription;
  tcSub: Subscription;
  enrollEligibilitySub: Subscription;
  autoPayPaymentProfileSub: Subscription;
  manageEnrollSub: Subscription;
  submitEnrollSub: Subscription;
  showTC = false;
  termsAndConditionTxtData: any;
  public tcAutoPayAgreementContent: any;
  enrollData: any;
  paymentProfileData: any;
  returnURI: string;
  display = COMMON_CONSTANTS.BLOCK;
  titleLable: any;
  autoPayEligibilityData: any;
  isManageAutopayEligible = false;
  isEnrollAutopayEligible = false;
  eligibleAutoPayMsg: any;
  errorAlert: ServiceMessage = { message: null, show: false };
  errorMessage: any;
  errorAlertTC: ServiceMessage = { message: null, show: false };
  errorAlertAutoPayTC: ServiceMessage = { message: null, show: false };
  errorAlertSubmitEN: ServiceMessage = { message: null, show: false };
  errorAlertManageEN: ServiceMessage = { message: null, show: false };
  bankText: any = COMMON_CONSTANTS.BANK_TXT;
  cardText: any = COMMON_CONSTANTS.CARD_TXT;
  creditCardText: any = COMMON_CONSTANTS.TYPE_CREDIT_CARD
  debitCardText: any = COMMON_CONSTANTS.TYPE_DEBIT_CARD
  manageCardText:any = COMMON_CONSTANTS.TYPE_CREDITDEBIT_MAYRELEASE_CARD;
  isCcInfoSavedSuccess = false;
  isBankInfoSavedSuccess = false;
  getCardImageURL: string;
  isShowCardDetail = false;
  isShowBankDetail = false;
  savedCardDetails: any = { cardNumber: '', expirationDate: '', cardType: '' };
  savedBankDetails: any = {
    accountNumber: '',
    accountType: '',
    routingNumber: ''
  };
  banDetails: any;
  cardExpirationDate: any;
  show: any;
  errorType: string;
  isRedirect: boolean;
  enrlObj: any;
  discountObj: any;
  mngObj: any;
  consentArray: any = [];
  enrlBankObj: BankEnrlMnjAutopayModal = new BankEnrlMnjAutopayModal();
  enrlCcObj: CardEnrlMnjAutopayModal = new CardEnrlMnjAutopayModal();
  mngBankObj: BankEnrlMnjAutopayModal = new BankEnrlMnjAutopayModal();
  mngCcObj: CardEnrlMnjAutopayModal = new CardEnrlMnjAutopayModal();
  ccBillPayAliasObj: CCBillPayAliasObj = new CCBillPayAliasObj();
  achBillPayAliasObj: BankBillPayAliasObj = new BankBillPayAliasObj();
  replaceSpecifications: Specifications = new Specifications();
  accountDetailsRequestData : accountDetailsRequestData = new accountDetailsRequestData();
  achBillPayAliasData: any;
  ccBillPayAliasData: any;
  UpdateCrOrderObj: UpdateCrOrderPaymentModel = new UpdateCrOrderPaymentModel();
  legalDocument: LegalDocument = new LegalDocument();
  _changeDetect:ChangeDetectorRef;
  updateCrOrderStatus: string;
  updateCrOrderStatusDesc: any;
  dataConsent: any;
  autopayConsent: string;
  dataConsentAp: any;
  dataConsentSvc: any;
  entrustUrl: any;
  content: any;
  banNumber: any;
  orgID: any;
  dataConsentCheck: any;
  isAccessTokenStored: boolean;
  billPayAliasObj: any;
  careAuthType = false;
  isCCEncryptComplete: boolean;
  encrypedNumber: any;
  retry = CREDIT_CARD_CONSTANTS.ZERO;
  isValid = true;
  formValid: boolean;
  totalDueNewCardForm: any;
  achGroup: any;
  enableRetry: boolean = true;
  currentPayAcntCardNumber: any;
  profileAliasNumber: any = '';
  currentBnkRoutingNumber: any = '';
  currentPayAcntCardName: any = '';
  ShowCraditCardOption: boolean;
  showcardDefault: boolean;
  ShowbankOption: boolean;
  isEdit: boolean = true;
  isChange: boolean = true;
  isLogo: boolean = false;
  luhnCheck: boolean = true;
  cardTypeVal: boolean = true;
  profilecardTypeCode: any;
  submitManagethruEnroll: boolean = false;
  CommonAuthoringContent: any;
  profilecardName: any;
  accountType: any;
  profilePayMethodeCode: any;
  errorCounter = 0;
  showSubHeadertxt: boolean;
  @ViewChild(PaymentErrorPopupComponent, { static: true }) PaymentErrorModel: PaymentErrorPopupComponent;
  profileZipCode: any;
  paymentMethodCode: any;
  cardTypeCode: any;
  walkMeIn_userID: any;
  isPartnerFlow: boolean = false;
  isBillingPartnerFlow: boolean = false;
  apdJuneRelease : boolean = environment.APD_JUNE_RELEASE;
  mayReleaseFlag : boolean = environment.MAY_RELEASE_FLAG;
  notifyMessage:boolean = false;
  creditMigrationDate :any;
  accountDetails: any;
  displayDiscountMsg: boolean  = false;
  cardBankInfo:any;
  creditEligibleDiscount:boolean=true;
  callEnrollCredit:boolean=false;
  reactiveAwarenessMsg = staticContent.components.autoPayment.reactiveAwarenessMsg;
  autopayBannerMessage = staticContent.components.autoPayment.autopayBannerMessage;
  creditCardAlertMsg = staticContent.components.autoPayment.creditCardAlertMsg;
  showBAUCreditDebit: boolean = false;
  showSeperateCreditDebit: boolean = false;
  showMayCreditDebit: boolean = false;
  encryptedCvv:any;
  autoDisplayMsgToggle : boolean = true;
  showBasicCreditCardInfo:boolean = false;
  showBasicDebitCardInfo:boolean = false;
  makeAutopayPermission:boolean = false;

  banFriendlyName: any;
  get isRunningInGlobalNav(): boolean {
   return this.pnpClientService.isRunningInGlobalNav(); 
  }

  constructor( private injector : Injector, private pipedOperatorsService: PipedOperatorsService) { 
    this._envSvc = injector.get<EnvironmentDetailsService>(EnvironmentDetailsService);
    this._tcSvc = injector.get<TermAndConditionService>(TermAndConditionService);
    this._autoPaymentSvc = injector.get<AutoPaymentService>(AutoPaymentService);
    this._cacheSvc = injector.get<HttpCacheService>(HttpCacheService);
    this._errMsgService = injector.get<ErrorMessageService>(ErrorMessageService);
    this._routeService = injector.get<SetupRouteService>(SetupRouteService);
    this._makepymntSvc = injector.get<MakePaymentService>(MakePaymentService);
    this.contentService = injector.get<ContentService>(ContentService);
    this.sanitizer = injector.get<DomSanitizer>(DomSanitizer);
    this.utils = injector.get<UtilsService>(UtilsService);
    this.creditService = injector.get<CreditCardService>(CreditCardService);
    this.achService = injector.get<ACHService>(ACHService);
    this.billPaySvc = injector.get<BillPayService>(BillPayService);
    this.router = injector.get<Router>(Router);
    this.pdlService = injector.get<PDLService>(PDLService);
    this.pnpClientService = injector.get<PnpClientService>(PnpClientService);
    this.splunkSvc = injector.get<SplunkUtilityService>(SplunkUtilityService);
    this._loaderSvc = injector.get<LoaderService>(LoaderService);
    this._changeDetect = injector.get<ChangeDetectorRef>(ChangeDetectorRef);
  }

  ngOnInit() { 
    this._errMsgService.serviceData.subscribe((genricMessage: any) => {
      if (genricMessage) {
        this.errorMessage = genricMessage.error;
        this.show = genricMessage.show;
        this.errorType = genricMessage.errorType;
      }
    });

    this._routeService.isEuiRedirect.pipe(first()).subscribe(isRedirect => {
      this.isRedirect = isRedirect;
      this.isAccessTokenStored = Boolean(
        !isNullOrUndefined(
          this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.ACCESS_TOKEN_KEY
          )
        )
      );

      // add partner condition
      if (environment.ENABLE_PARTNER && this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === 'true') {
        this._routeService.partnerHandShake.subscribe((data) => {
          if (data) {
            this.isPartnerFlow = true;
           if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW) === 'true'){
              this.isBillingPartnerFlow = true;
            }
            this.checkPermissions();
          }
        });
      } else {
        if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('isHandshakeComplete'))
          && this._cacheSvc.getFromSessionStorage('isHandshakeComplete') === 'true') {
          this.checkPermissions();
        } else {
          this._routeService.partnerHandShake.subscribe((data) => {
            if (data) {
              this.checkPermissions();
            }
          });
        }
      }
    });

    this._makepymntSvc.achDataTomakepayment.subscribe(achDataForm => {
      if (!isNullOrUndefined(achDataForm)) {
        this.achGroup = achDataForm.value;
        this.formValid = achDataForm.valid;
      }
    });

    this._makepymntSvc.cardDataTomakepayment.subscribe(ccDataForm => {
      this.isLogo = true;
      if (!isNullOrUndefined(ccDataForm)) {
        this.totalDueNewCardForm = ccDataForm.value;
        this.luhnCheck = this.totalDueNewCardForm.LuhnCheck;
        this.cardTypeVal = this.totalDueNewCardForm.cardTypeVal;
        this.paymentMethodCode = this.totalDueNewCardForm.paymentMethodCode;
        this.cardTypeCode = this.totalDueNewCardForm.cardTypeCode;
        if ((!this.isEdit && this.profilecardTypeCode !== 'AMERICANEXPRESS' && !isNullOrUndefined(ccDataForm.value.cvv) && ((ccDataForm.value.cvv).length === 3)) 
         || (!this.isEdit && this.profilecardTypeCode === 'AMERICANEXPRESS' && !isNullOrUndefined(ccDataForm.value.cvv) && ((ccDataForm.value.cvv).length === 4))) {
          this.formValid = ccDataForm.errors == null ? true:false;
          this.luhnCheck = true;
          this.cardTypeVal = true;
        } else {
          this.formValid = (this.mayReleaseFlag && this.autoPaySelectedOption === COMMON_CONSTANTS.MANAGE_FLOW) ? 
                          ccDataForm.valid : 
                          ccDataForm.valid && this.totalDueNewCardForm.isSameCardTypeSelectedEntered;
        }
      }
    });
    this.walkMeIn_userID = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA) ?
      JSON.parse(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)).logonId : MakePaymentConstant.cardSchemailId;

  }
  checkPermissions() {
    if(!this.isPartnerFlow && !this.utils.isCareAuth()) {
      const permissionsObject = JSON.parse(this._cacheSvc.getFromSessionStorage("pnp-persisted-context") || "{}");
      if(!isNullOrUndefined(permissionsObject) && !isNullOrUndefined(permissionsObject.activities)){
        if (permissionsObject.activities.BAN?.AUTOPAY_ENROLLMENT?.isActiveFlag == 1) {
          this.makeAutopayPermission = true;
          this.initializeObjects();
        }
        if(!this.makeAutopayPermission){
          this.router.navigate([MakePaymentConstant.processError]);
        }
      }
    }
    else if(this.utils.isCareAuth()  || this.isPartnerFlow){
      this.makeAutopayPermission = true;
      this.initializeObjects();
    }
  }
  initializeObjects(){
    if (
        (!this.isRedirect &&
            this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE
            ) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE &&
            this.isAccessTokenStored === true) ||
        (environment.SSO_ON === false && this.isAccessTokenStored === true && this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE
        ) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE) && this.makeAutopayPermission
    ) {
      this.contentService
          .GetContentData()
          .subscribe(data => {
                this._cacheSvc.putInSessionStoarage('contentmain', JSON.stringify(data));
                this.autoPaymentInitialLoad(data);

              },
              error => {
                const mock = true;
                this.contentService.GetContentData().subscribe(data => {
                  this.autoPaymentInitialLoad(data);
                  this._cacheSvc.putInSessionStoarage('contentmain', JSON.stringify(data));
                });
              });
    }
  }
  autoPaymentInitialLoad(data) { 
    this.AchConstants = ACH_CONSTANTS;
    this.authoringContent = data.par.common_payment.autoPay;
    this.CommonAuthoringContent = data.par.common_payment;

    this.content = data.par.common_payment;
    this.banNumber = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.BAN_NUMBER_KEY
    );
    this.orgID = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.ORG_ID_KEY
    );
    this.accountDetailsRequestData.billingAccount = this.banNumber;
      this._autoPaymentSvc.getAccountDetails(this.accountDetailsRequestData)
      .pipe(this.pipedOperatorsService.subReattempt())
      .subscribe(data => {
        this.accountDetails = data;
        if (this.accountDetails?.accountType === "I" && this.accountDetails?.accountSubType === "S"
        || this.accountDetails?.accountType === "B" && this.accountDetails?.accountSubType === "L"){
          this.displayDiscountMsg = true;
        }
        else{
          this.displayDiscountMsg = false;
        }
        if(this.accountDetails?.banFriendlyName){
          this.banFriendlyName = this.accountDetails?.banFriendlyName;
          this.setAccountTitle(this.accountDetails.banNumber)

        }
      });
    
    const baseban = btoa(this.banNumber);
    this.careAuthType = this.utils.isCareAuth()
    if (environment.hideBANFriendlyName || this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) == 'true') {
      this.titleLable = `${this.authoringContent.autopayTitlelblTxt1.replace(
        COMMON_CONSTANTS.AUTOPAY_PROPERTIES.BAN,
        this.banNumber
      )}`;
    } else {
      if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) !== 'true') {
        this.setAccountTitle(baseban);
      }
    }

    if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) !== 'true') {
      this.autoPaySelectedOption = !isNullOrUndefined(
        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.FLOW_KEY)
      )
        ? this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.FLOW_KEY)
        : COMMON_CONSTANTS.ENROLL_FLOW;
      if (
        this.autoPaySelectedOption === COMMON_CONSTANTS.MANAGE_FLOW ||
        this.autoPaySelectedOption === COMMON_CONSTANTS.ENROLL_FLOW
      ) {
        this.setAutopayTC();
      }
      this.checkAutopayEligibility();
      if (
        this.autoPaySelectedOption === COMMON_CONSTANTS.MANAGE_FLOW ||
        this.autoPaySelectedOption === COMMON_CONSTANTS.CANCEL_FLOW
      ) {
        this.autoPayPaymentProfile();
        if (this.autoPaySelectedOption === COMMON_CONSTANTS.CANCEL_FLOW) {
          this.openCancelModel();
        }
      }
      this.flowTypeCheck(this.autoPaySelectedOption);
      this.setShowRadioButton();
      this.returnURI = this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.RETURN_URL_SSP
      );
    } else {
      this.checkAutopayEligibility();
    }
    // Page load tagging & for IAM and partner flow
    const idpType = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE)
    const isPartner = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
    if (environment.ENABLE_TAGGING && (idpType == COMMON_CONSTANTS.IAM_KEY || isPartner == COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE)) {
      if (this.autoPaySelectedOption === COMMON_CONSTANTS.ENROLL_FLOW ||
        this.autoPaySelectedOption === COMMON_CONSTANTS.MANAGE_FLOW ||
        this.autoPaySelectedOption === COMMON_CONSTANTS.CANCEL_FLOW) {
        this.generatePageViewDetailsForAutoPay(idpType, isPartner);
      }
    }
  }

  generatePageViewDetailsForAutoPay(idpTypeArg: any, isPartnerArg: any) {
    let pageDetail = {
      primaryCategory: PDL_CONSTANTS.PRIMARY_CATEGORY,
      subCategory: PDL_CONSTANTS.PLP_SUB_CATEGORY,
      pageType: PDL_CONSTANTS.PLP_PAGE_TYPE_AUTOPAY,
      pageName: PDL_CONSTANTS.PLP_PAGE_NAME_AUTOPAY,
      previousPageURL: this._cacheSvc.getFromSessionStorage(PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL),
      idpType: idpTypeArg,
      isPartner: isPartnerArg
    };
    if (this.autoPaySelectedOption === COMMON_CONSTANTS.MANAGE_FLOW) {
      pageDetail.pageType = PDL_CONSTANTS.PLP_PAGE_TYPE_AUTOPAY_MANAGE;
      pageDetail.pageName = PDL_CONSTANTS.PLP_PAGE_NAME_AUTOPAY_MANAGE;
    }
    this.pdlService.generatePageViewTag(pageDetail);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isLogo = true;
    }, 5000);
  }
  flowTypeCheck(flowType) {
    switch (flowType) {
      case COMMON_CONSTANTS.MANAGE_FLOW:
        this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
          MakePaymentConstant.FLOW_TYPE.MANAGE_AUTOPAY);
        break;
      case COMMON_CONSTANTS.ENROLL_FLOW:
        this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
          MakePaymentConstant.FLOW_TYPE.ENROLL_AUTOPAY);
        break;
      case COMMON_CONSTANTS.CANCEL_FLOW:
        this._cacheSvc.putInSessionStoarage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
          MakePaymentConstant.FLOW_TYPE.CANCEL_AUTOPAY);
        break;
    }
  }  

  onChangeDirectDepositV1(evt: any, type) {
    this.formValid = false;
    if (type === COMMON_CONSTANTS.TYPE_ACH) {
      this.ShowCraditCardOption = false;
      this.isEdit = true;
      this.isChange = true;
      this.cardTypeVal = true;
      this.luhnCheck = true;
    } else {
      if(type.indexOf(this.profilePayMethodeCode) > -1){
        this.ShowCraditCardOption = false;
      }else{
        this.ShowCraditCardOption = true;
      }
    }
    this.errorAlert.show = false;
    this.selectedPaymentType = evt.target.value;
    if (
      type === COMMON_CONSTANTS.TYPE_ACH &&
      this.creditCardNumber !== '' &&
      !this.creditService.isCcInfoSaved
    ) {
      this.creditService.isCcInfoSaved = true;
    }
    if (
      type === COMMON_CONSTANTS.TYPE_CREDIT &&
      this.bankAccountNumber !== '' &&
      !this.achService.isBankInfoSaved
    ) {
      this.achService.isBankInfoSaved = true;
    }
    if (
      type === COMMON_CONSTANTS.TYPE_CREDIT_CARD &&
      this.bankAccountNumber !== '' &&
      !this.achService.isBankInfoSaved
    ) {
      this.achService.isBankInfoSaved = true;      
    }
    if (
      type === COMMON_CONSTANTS.TYPE_DEBIT_CARD &&
      this.bankAccountNumber !== '' &&
      !this.achService.isBankInfoSaved
    ) {
      this.achService.isBankInfoSaved = true;      
    }
    if (this.selectedPaymentType === this.cardText) {
      if (this.bankAccountNumber !== '') {
        this.achService.isBankInfoSaved = false;
      }
      if (this.creditCardNumber !== '') {
        this.creditService.isCcInfoSaved = true;
      }
    }

    if (this.selectedPaymentType === this.bankText) {
      if (this.bankAccountNumber !== '') {
        this.achService.isBankInfoSaved = true;
      }
      if (this.creditCardNumber !== '') {
        this.creditService.isCcInfoSaved = false;
      }
    }

    if (
      (this.selectedPaymentType === this.cardText &&
        this.savedCardDetails.cardNumber !== this.creditCardNumber) ||
      (this.selectedPaymentType === this.bankText &&
        this.savedBankDetails.accountNumber !== this.bankAccountNumber)
    ) {
      this.saveSuccess = true;
    } else {
      this.saveSuccess = false;
    }

    if (this.savedCardDetails.cardNumber !== '') {
      this.isShowCardDetail = true;
      this.isShowBankDetail = false;
    }
    if (this.savedBankDetails.accountNumber !== '') {
      this.isShowCardDetail = false;
      this.isShowBankDetail = true;
    }
    if((type === COMMON_CONSTANTS.TYPE_CREDIT_CARD && this.profilePayMethodeCode==undefined)) {
      this.createAutopayDiscountELigibility();
    }else{
      this.notifyMessage = false;
    }
    if((type === COMMON_CONSTANTS.TYPE_CREDIT_CARD && this.profilePayMethodeCode && this.profilePayMethodeCode!=undefined) || 
      (type === COMMON_CONSTANTS.TYPE_CREDIT  && this.profilePayMethodeCode && this.profilePayMethodeCode!=undefined && this.mayReleaseFlag && this.autoPaySelectedOption == 'manage') || 
      (type === COMMON_CONSTANTS.TYPE_CREDIT  && this.mayReleaseFlag && this.autoPaySelectedOption == 'manage')){
      this.updateAutopayDiscountELigibility();
    }else{
      this.notifyMessage = false;
    }
    this.callEnrollCredit = false;
    this.creditEligibleDiscount=true;
    this.showCardBasicInfo();
  }

  
  queryAutopayDiscountELigibility(){
    this.banNumber = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.BAN_NUMBER_KEY
    );
    this.discountObj = {};
    this.discountObj.salesChannelId = "WEB";
    this.discountObj.programCode = "U1POSTLEGACY";
    this.discountObj.businessUnit = "TMOBILE";
    this.discountObj.businessSegment = "POSTPAID";
    this.discountObj.productGroup = "SOFTGOODS";
    this.discountObj.orderTypes = "MANAGEPAYMENT";
    this.discountObj.billerCode = "SAMSON";
    this.discountObj.accountInfo = {};
    this.discountObj.accountInfo.billingAccount = this.banNumber;
    this.discountObj.autoPayInfo = {};
    this.discountObj.autoPayInfo.autoPayFlow = "QUERY";
    this.discountObj.autoPayInfo.skipSamsonCallForMigrationDate = true;
    this.discountObj.autoPayInfo.autoPayDiscountMigrationDate = this.creditMigrationDate;
    this.discountObj.autoPayInfo.currentAutoPayInfo = {};
    this.discountObj.autoPayInfo.currentAutoPayInfo.paymentCardAlias =  this.paymentProfileData?.paymentInstrument?.paymentCard
    .paymentCardAlias;
    this.discountObj.autoPayInfo.newAutoPayInfo = null;

    this._autoPaymentSvc
    .autopayDiscountEligibility(this.discountObj)
    .pipe(this.pipedOperatorsService.subReattempt())
    .subscribe(
      autoPayData => {
        const currDateTime = new Date();
        let now_utc = Date.UTC(
          currDateTime.getUTCFullYear(),
          currDateTime.getUTCMonth(),
          currDateTime.getUTCDate(),
          currDateTime.getUTCHours(),
          currDateTime.getUTCMinutes(),
          currDateTime.getUTCSeconds()
        );        
        if(autoPayData && autoPayData.autoPayDiscountMigrationDate && new Date(now_utc) >= new Date(autoPayData.autoPayDiscountMigrationDate.toLocaleString()) && autoPayData.paymentMethodDiscountEligibilityIndicator && autoPayData.paymentMethodDiscountEligibilityIndicator=="N"){
          this.notifyMessage = true;
          this.creditMigrationDate = autoPayData.autoPayDiscountMigrationDate;          
          this.creditEligibleDiscount = false;         
        }else{
          this.notifyMessage = false;
        }
      }, err => {
        this.documentAutopayDiscountFailure(err,"query")
      })
  }

  createAutopayDiscountELigibility(){
    this.banNumber = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.BAN_NUMBER_KEY
    );
    this.discountObj = {};
    this.discountObj.salesChannelId = "WEB";
    this.discountObj.programCode = "U1POSTLEGACY";
    this.discountObj.businessUnit = "TMOBILE";
    this.discountObj.businessSegment = "POSTPAID";
    this.discountObj.productGroup = "SOFTGOODS";
    this.discountObj.orderTypes = "MANAGEPAYMENT";
    this.discountObj.billerCode = "SAMSON";
    this.discountObj.accountInfo = {};
    this.discountObj.accountInfo.billingAccount = this.banNumber;
    this.discountObj.autoPayInfo = {};
    this.discountObj.autoPayInfo.autoPayFlow = "CREATE";
    this.discountObj.autoPayInfo.skipPaymentMethodEligibility = true;
    this.discountObj.autoPayInfo.skipSamsonCallForMigrationDate = false;
    this.discountObj.autoPayInfo.currentAutoPayInfo = null;
    this.discountObj.autoPayInfo.newAutoPayInfo = null;

    this._autoPaymentSvc
    .autopayDiscountEligibility(this.discountObj)
    .pipe(this.pipedOperatorsService.subReattempt())
    .subscribe(
      autoPayData => {
        const currDateTime = new Date();
        let now_utc = Date.UTC(
          currDateTime.getUTCFullYear(),
          currDateTime.getUTCMonth(),
          currDateTime.getUTCDate(),
          currDateTime.getUTCHours(),
          currDateTime.getUTCMinutes(),
          currDateTime.getUTCSeconds()
        );        
        if(autoPayData && autoPayData.autoPayDiscountMigrationDate!='null' && 
          new Date(now_utc) >= new Date(autoPayData.autoPayDiscountMigrationDate?.toLocaleString())) {
            this.notifyMessage = true;
            this.creditMigrationDate = autoPayData.autoPayDiscountMigrationDate;
        }else{
          this.notifyMessage = false;
        }
      }, err => {
        this.documentAutopayDiscountFailure(err,"create")
      })
  }

  updateAutopayDiscountELigibility(){
    this.banNumber = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.BAN_NUMBER_KEY
    );
    this.discountObj = {};
    this.discountObj.salesChannelId = "WEB";
    this.discountObj.programCode = "U1POSTLEGACY";
    this.discountObj.businessUnit = "TMOBILE";
    this.discountObj.businessSegment = "POSTPAID";
    this.discountObj.productGroup = "SOFTGOODS";
    this.discountObj.orderTypes = "MANAGEPAYMENT";
    this.discountObj.billerCode = "SAMSON";
    this.discountObj.accountInfo = {};
    this.discountObj.accountInfo.billingAccount = this.banNumber;
    this.discountObj.autoPayInfo = {};
    this.discountObj.autoPayInfo.autoPayFlow = "UPDATE";
    this.discountObj.autoPayInfo.skipPaymentMethodEligibility = true;
    this.discountObj.autoPayInfo.skipSamsonCallForMigrationDate = false;
    this.discountObj.autoPayInfo.currentAutoPayInfo = null;
    this.discountObj.autoPayInfo.newAutoPayInfo = null;

    this._autoPaymentSvc
    .autopayDiscountEligibility(this.discountObj)
    .pipe(this.pipedOperatorsService.subReattempt())
    .subscribe(
      autoPayData => {
        const currDateTime = new Date();
        let now_utc = Date.UTC(
          currDateTime.getUTCFullYear(),
          currDateTime.getUTCMonth(),
          currDateTime.getUTCDate(),
          currDateTime.getUTCHours(),
          currDateTime.getUTCMinutes(),
          currDateTime.getUTCSeconds()
        );       
        if(autoPayData && autoPayData.autoPayDiscountMigrationDate!='null' && 
          new Date(now_utc) >= new Date(autoPayData.autoPayDiscountMigrationDate.toLocaleString())) {
            this.notifyMessage = true;
            this.creditMigrationDate = autoPayData.autoPayDiscountMigrationDate;
        }else{
          this.notifyMessage = false;
        }
      }, err => {
        this.documentAutopayDiscountFailure(err,"update")
      })
  }

  documentAutopayDiscountFailure(error?: any, type?:any) {
    if(type=='create'){
      this.errorMessage = ContentService.GetCache(
        COMMON_CONSTANTS.CONTENT_CACHE_KEY
      );
      this.errorAlert = {
        message: {
          errorKey: error.status,
          errorMessage: this.errorMessage.error.retryCardBankErrorTxt1,
          errorType: CREDIT_CARD_CONSTANTS.ERR_SERVER
        },
        show: true
      };
      this.router.navigate([MakePaymentConstant.processError]);
    }else{
      this.errorMessage = ContentService.GetCache(
        COMMON_CONSTANTS.CONTENT_CACHE_KEY
      );
      this.errorAlert = {
        message: {
          errorKey: error.status,
          errorMessage: this.errorMessage.error.retryCardBankErrorTxt1,
          errorType: CREDIT_CARD_CONSTANTS.ERR_SERVER
        },
        show: true
      };
      this.router.navigate([MakePaymentConstant.processError]);
    }
  }

  onAchBillPaySuccess(achData: BankPaymentValidation) {
    this.achSavedData = achData;
  }

  onSuccessCredit(evt, type) {
    this.saveSuccess = evt.response;
    if (
      evt.response &&
      type === COMMON_CONSTANTS.TYPE_ACH &&
      !isNullOrUndefined(evt.accountNumber)
    ) {
      this.bankAccountNumber = this.utils.getLastFourDigits(evt.accountNumber);
      this.saveSuccessACHType = type;
    }
    if (
      evt.response &&
      type === COMMON_CONSTANTS.TYPE_CREDIT &&
      ContentService.GetCache(COMMON_CONSTANTS.CC_NUMBER) !== undefined
    ) {
      this.creditCardNumber = this.utils.getLastFourDigits(
        ContentService.GetCache(COMMON_CONSTANTS.CC_NUMBER)
      );
      this.saveSuccessCreditType = type;

      this.cardExpirationDate = ContentService.GetCache(
        COMMON_CONSTANTS.CARD_DETAIL_OBJECT
      ).expirationDate;
    }
    if(evt.eligibleForDiscount && evt.eligibleForDiscount==false){
      this.creditEligibleDiscount = evt.eligibleForDiscount;
    }
  }

  onEnrollCredit(evt) {    
    if(evt && evt.eligibleForDiscount==false){  
      this.creditEligibleDiscount = evt.eligibleForDiscount;
    }
  }

  onSuccessenrollCredit(evt){
    if(evt.enrollCredit=='confirm'){
      this.creditEligibleDiscount=true;
      if(!this.isEdit){
        this.manageEnroll();
      }else{
        this.submitEnroll();
      }
     
    }else{
      this.callEnrollCredit = false;
      this.creditEligibleDiscount=false;
    }

  }
  onCcBillPaySuccess(ccData: PaymentValidation) {
    this.ccSavedData = ccData;
  }

  private _enrollAutoPay() {
    this.createOrderId();
    this.billPaySvc
      .billPayAliasInfo(this.billPayAliasObj, this.orgID)
      .subscribe(
        (billPayRes: any) => {
          this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.TRANS_REF_NUM, billPayRes.transRefNum);
          this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.TRANSACTION_ID, billPayRes.transactionId);
          this.dataConsentCheck = ContentService.GetCache(COMMON_CONSTANTS.TERMS_CONSENT);
          if (isNullOrUndefined(this.dataConsentCheck)) {
            if (this.autopayTC ) {
              this._tcSvc.recordUserConsent().subscribe(
                consentData => {
                  // tslint:disable-next-line:max-line-length
                  if (consentData.postedDocumentDetails[0].status === 'FAILURE') {
                    // Document consent fails in success scenario- Disable enroll button and show the genric message
                    this.documentConsentFailure();
                  } else {
                    this.dataConsentAp = consentData;
                    ContentService.SetCache(COMMON_CONSTANTS.TERMS_CONSENT, this.dataConsentAp);
                    this.enrollMPI(this.encrypedNumber);
                  }
                },
                error => {
                  // Document consent fails - Disable enroll button and show the genric message
                  this.documentConsentFailure(error);
                }
              );
            } else if (!this.autopayTC) {
              // if general or easy-pay agreement retreival fails - Disable enroll button and show the genric message
              this.documentConsentFailure();
            }
          } else if (!isNullOrUndefined(this.dataConsentCheck)) {
            this.enrollMPI(this.encrypedNumber);
          }
        },
        error => {
          this.LogErrorMessage(error);
        }
      );
  }

  submitEnroll() {
    // analytics click tagging
    if(this.creditEligibleDiscount==false) {
      this.callEnrollCredit = true;
    } else {
      this.generateClickDetailsForAutoPay("click", "button", ClickEventType.NAVIGATE, "Submit", "")
      this._cacheSvc.putInSessionStoarage(PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL, window.location.host + window.location.pathname);
      this.billPaySvc.fetchPublicKey().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
        (data: any) => {
        this.isCCEncryptComplete = false;
        let cardAccountNumber;
        let cvv;
        if (this.selectedPaymentType === this.bankText) {
          cardAccountNumber = HttpCacheService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER_VAL);
        } else {
          cardAccountNumber = this.totalDueNewCardForm.actualcreditCardNumber;
          cvv = this.totalDueNewCardForm.cvv;

        }
        const card = this;
        encrypt(data?.payment?.encryption?.jwkKey, cardAccountNumber, (encryptedData) => {
          encryptedData = JSON.stringify(encryptedData, undefined, 2);
          card.encrypedNumber = encryptedData.substring(1, encryptedData.length - 1);
          if (!isNullOrUndefined(this.encrypedNumber)) {
            if (cvv) {
              encrypt(data?.payment?.encryption?.jwkKey, cvv, (encryptedData) => {
                encryptedData = JSON.stringify(encryptedData, undefined, 2);
                card.encryptedCvv = encryptedData.substring(1, encryptedData.length - 1);                     
                if (!isNullOrUndefined(this.encrypedNumber)) {
                  this._enrollAutoPay();
                } else {
                  this.LogErrorMessage();
                }
              },
              (errorData) => {
                this.LogErrorMessage();
              });
            } else {
              if (!isNullOrUndefined(this.encrypedNumber)) {
                this._enrollAutoPay();
              } else {
                this.LogErrorMessage();
              }
            }
          } else {
            this.LogErrorMessage();
          }
        },
        (errorData) => {
          this.LogErrorMessage();
        });
      },
      err => {
        this.LogErrorMessage(err);
      })
    }
  }    



  enrollMPI(encrypedNumber: any) { 
    if (this.selectedPaymentType === this.bankText) {
      this.enrlBankObj.payment.paymentMethodReference.paymentMethodCode =
        MakePaymentConstant.paymentMethodBankCode;
      this.enrlBankObj.payment.autopayTermsAgreementIndicator = true;
      const currDateTime = new Date();
      const agreementTime = currDateTime.toISOString();
      this.enrlBankObj.payment.autopayTermsAgreementTime = agreementTime;
      this.achBillPayAliasData = ContentService.GetCache('achBillPayAliasData');
      // tslint:disable-next-line:max-line-length
      this.enrlBankObj.payment.paymentInstrument.bankPayment.bankAccountAlias =
        '';
      this.enrlBankObj.payment.paymentInstrument.bankPayment.accountNumber = encrypedNumber;
      this.enrlBankObj.payment.paymentInstrument.bankPayment.routingNumber = HttpCacheService.GetCache(COMMON_CONSTANTS.ROUTING_NUMBER);
      this.enrlBankObj.payment.paymentInstrument.bankPayment.accountHolderNames = [
        this.achGroup?.accountName
      ];
      if (this.achGroup && this.achGroup?.accountType) {
        if (this.achGroup?.accountType === ACH_CONSTANTS.CHECKING_KEY) {
          this.accountType = this.achGroup?.accountType.substr(0, this.achGroup?.accountType.length - 3);
        } else if (this.achGroup.accountType === ACH_CONSTANTS.SAVING_KEY) {
          this.accountType = this.achGroup?.accountType.substr(0, this.achGroup?.accountType.length - 4) + 'E';
        }
      }
      this.enrlBankObj.payment.paymentInstrument.bankPayment.accountType = this.accountType;
      this.enrlBankObj.payment.autoPay.autoPayAcceptance =
        MakePaymentConstant.AP_ACCEPTANCE;
      this.enrlBankObj.payment.autoPay.assistingDept =
        MakePaymentConstant.AP_ASSISTINGDEPT;
      this.enrlBankObj.payment.businessUnit = MakePaymentConstant.businessUnit;
      this.enrlBankObj.payment.programCode = MakePaymentConstant.programCode;
      this.enrlBankObj.payment.billerCode = MakePaymentConstant.billerCode;
      this.enrlBankObj.payment.businessSegment =
        MakePaymentConstant.autoBusinessSegment;
      this.enrlBankObj.payment.orderTypes = MakePaymentConstant.schAchOrderType;
      this.enrlBankObj.payment.productGroup = MakePaymentConstant.productGroup;
      this.enrlBankObj.customerReference.customerId = this.banNumber;
      if (this.autoPaySelectedOption === COMMON_CONSTANTS.MANAGE_FLOW) {
        this.enrlBankObj.payment.specifications.length = 0;

        this.replaceSpecifications.name =
          COMMON_CONSTANTS.IS_AUTOPAYiNSTRUMENT_FLAG;
        this.replaceSpecifications.value =
          COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE;
        this.enrlBankObj.payment.specifications.push(
          this.replaceSpecifications
        );
      }
      this.enrlObj = this.enrlBankObj;
    } else if (this.selectedPaymentType === this.cardText || this.selectedPaymentType === this.creditCardText || this.selectedPaymentType === this.debitCardText || this.selectedPaymentType === this.manageCardText) {
      this.enrlCcObj.payment.paymentMethodReference.paymentMethodCode =
        this.paymentMethodCode;
      this.enrlCcObj.payment.autopayTermsAgreementIndicator = true;
      const currDateTime = new Date();
      const agreementTime = currDateTime.toISOString();
      this.enrlCcObj.payment.autopayTermsAgreementTime = agreementTime;
      this.ccBillPayAliasData = ContentService.GetCache('ccBillPayAliasData');
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cardHolderName = this.totalDueNewCardForm?.nameOnCreditCard;
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cardHolderFirstName = this.getFormattedName(
        this.totalDueNewCardForm?.nameOnCreditCard,
        CREDIT_CARD_CONSTANTS.FIRST
      );
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cardHolderLastName = this.getFormattedName(
        this.totalDueNewCardForm?.nameOnCreditCard,
        CREDIT_CARD_CONSTANTS.LAST
      );
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cvv = this.encryptedCvv;
      this.enrlCcObj.payment.paymentInstrument.paymentCard.typeCode = this.cardTypeCode;
      this.enrlCcObj.payment.paymentInstrument.paymentCard.expirationMonthYear =
        this.totalDueNewCardForm.expirationMonth +
        this.totalDueNewCardForm.expirationYear.toString().substr(2, 2);
      this.enrlCcObj.payment.paymentInstrument.paymentCard.paymentCardAlias =
        '';
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cardNumber = encrypedNumber;
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 = this.totalDueNewCardForm?.ccAddress1;
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2 = this.totalDueNewCardForm?.ccAddress2;
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city = this.totalDueNewCardForm?.ccCity;
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state = this.totalDueNewCardForm?.ccState;
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.purposes =
        MakePaymentConstant.purposes;
      // tslint:disable-next-line:max-line-length
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.countryCode =
        MakePaymentConstant?.countryCode;
      this.enrlCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode = this.totalDueNewCardForm?.ccZip;
      this.enrlCcObj.payment.autoPay.autoPayAcceptance =
        MakePaymentConstant.AP_ACCEPTANCE;
      this.enrlCcObj.payment.autoPay.assistingDept =
        MakePaymentConstant.AP_ASSISTINGDEPT;
      this.enrlCcObj.payment.businessUnit = MakePaymentConstant.businessUnit;
      this.enrlCcObj.payment.programCode = MakePaymentConstant.programCode;
      this.enrlCcObj.payment.billerCode = MakePaymentConstant.billerCode;
      this.enrlCcObj.payment.businessSegment =
        MakePaymentConstant.autoBusinessSegment;
      this.enrlCcObj.payment.orderTypes = MakePaymentConstant.schAchOrderType;
      this.enrlCcObj.payment.productGroup = MakePaymentConstant.productGroup;
      this.enrlCcObj.customerReference.customerId = this.banNumber;
      if (this.autoPaySelectedOption === COMMON_CONSTANTS.MANAGE_FLOW) {
        this.enrlCcObj.payment.specifications.length = 0;

        this.replaceSpecifications.name =
          COMMON_CONSTANTS.IS_AUTOPAYiNSTRUMENT_FLAG;
        this.replaceSpecifications.value =
          COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE;
        this.enrlCcObj.payment.specifications.push(this.replaceSpecifications);
      }
      this.enrlObj = this.enrlCcObj;
      if (!this.isEdit) {
        this.enrlObj.isEditmode = true;
      }
    }
    this.enrlObj.payment.orderNumber = this._cacheSvc?.getFromSessionStorage(COMMON_CONSTANTS?.TRANS_REF_NUM) || "";
    this.submitEnrollSub = this._autoPaymentSvc
      .enrollAutoPayment(this.enrlObj)
      .subscribe(
        autoPayData => {
          if (environment.ENABLE_PARTNER &&
            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === 'true') {
            if (!isNullOrUndefined(autoPayData) && !isNullOrUndefined(autoPayData?.payment) &&
              !isNullOrUndefined(autoPayData?.payment?.messages) && !isNullOrUndefined(autoPayData?.payment?.messages[0]?.redirectUrl)) {
              this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.RETURN_URL_SSP, autoPayData?.payment?.messages[0]?.redirectUrl);
            }
          }

          let errorCode = '';
          if (!isNullOrUndefined(autoPayData && autoPayData.responseCodeList)) {
            errorCode = autoPayData?.responseCodeList[0]?.errors?.responseErrors[0]?.code;
          }
          const errorCodeList = _.split("8184,8183,8330", ',');
          if (this.retry > 0 && !isNullOrUndefined(
            autoPayData && autoPayData['reason'] && autoPayData.reason.reasonCode
          ) &&
            autoPayData.reason.reasonCode ===
            MakePaymentConstant.APPROVED_MPI_CODE &&
            !autoPayData['responseCodeList'] && this._errMsgService.getErrorMessage().show) {
            this._errMsgService.unSettingErrorMsg();
          }
          if (this._errMsgService.getErrorMessage().show) {
            this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
            // Actual User Message from API to the PUT account Order API
            this.updateCrOrderStatusDesc =
              autoPayData?.responseCodeList[0]?.errors?.responseErrors[0] ? autoPayData?.responseCodeList[0]?.errors?.responseErrors[0]?.userMessage : '';
            this.updateCROrderId(autoPayData);
            this.retry++;
            this.saveSuccess = false;
            const negativeBanReasonCodeList = _.split(
              this.CommonAuthoringContent.error.negativeFileCheckcommonErrorCode, ',');
            if (
              !isNullOrUndefined(
                this.errorAlert &&
                this.errorAlert.message &&
                this.errorAlert.message.errorKey
              ) &&
              negativeBanReasonCodeList.includes(
                this.errorAlert.message.errorKey
              )
            ) {
              this.enableRetry = false;
              this._makepymntSvc.makePaymenttoAch(true);
              this._makepymntSvc.makePaymenttoCC(true);
              this.router.navigate([MakePaymentConstant.processError]);
            }
            else {
              if (this.retry > CREDIT_CARD_CONSTANTS.TWO) {
                this.enableRetry = false;
                this.router.navigate([MakePaymentConstant.processError]);
              } else {
                if (errorCodeList.includes(errorCode)) {
                  this._makepymntSvc.processPaymentErrorCounter.next(this.retry);                  
                  this.PaymentErrorModel?.openModal();
                }
                else {
                  this.enableRetry = false;
                  this.router.navigate([MakePaymentConstant.processError]);
                }

              }
            }
            return false;
          } else if (
            !isNullOrUndefined(
              autoPayData &&
              autoPayData['reason'] &&
              autoPayData.reason.reasonCode
            ) &&
            autoPayData.reason.reasonCode ===
            MakePaymentConstant.APPROVED_MPI_CODE &&
            !autoPayData['responseCodeList']
          ) {
            // Success scenario for MPI Auto Pay API

            this.updateCrOrderStatus = 'S';
            this.updateCrOrderStatusDesc = autoPayData['statusBody']
              ? autoPayData['statusBody'].reasonDescription
              : '';
            this.updateCROrderId(autoPayData);
            this.successEnroll(autoPayData);
          }
        },
        err => {
          // For handled errors of MPI,  show generic error message with disable button.
          // check document if we have to enable the retry for 400 errors
          this.documentConsentFailure(err);
        }
      );
  }

  manageEnroll() { 
    // analytics click tagging
    if(this.creditEligibleDiscount==false){
      this.callEnrollCredit = true
    }else{
      this.generateClickDetailsForAutoPay("click", "button", ClickEventType.NAVIGATE, "Save Changes", "")

      this._cacheSvc.putInSessionStoarage(PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL, window.location.host + window.location.pathname);
      if (this.isEdit) {
        this.submitManagethruEnroll = true;
        this.submitEnroll();
      } else if (!this.isEdit) {
        this.createOrderId(this.isEdit);
        this.billPaySvc
          .billPayAliasInfo(this.billPayAliasObj, this.orgID)
          .subscribe(
            (billPayRes: any) => {
              this._cacheSvc.putInSessionStoarage(
                COMMON_CONSTANTS.TRANS_REF_NUM,
                billPayRes.transRefNum
              );
              this._cacheSvc.putInSessionStoarage(
                COMMON_CONSTANTS.TRANSACTION_ID,
                billPayRes.transactionId
              );
              this.dataConsentCheck = ContentService.GetCache(
                COMMON_CONSTANTS.TERMS_CONSENT
              );
  
              if (isNullOrUndefined(this.dataConsentCheck)) {
                if (this.autopayTC ) {
                  this.manageEnrollHelper();
                } else if (
                  !this.autopayTC
                ) {
                  // if general or easy-pay agreement retreival fails - Disable enroll button and show the genric message
  
                  this.documentConsentFailure();
                }
              } else if (!isNullOrUndefined(this.dataConsentCheck)) {
                this.manageMPI();
              }
            },
            error => {
              this.LogErrorMessage(error);
            }
          );
      }
    }
    
  }

  
  manageEnrollHelper() {
    this._tcSvc.recordUserConsent().subscribe(
      consentData => {
        if (
          consentData.postedDocumentDetails[0].status ===
          'FAILURE'
        ) {
          // Document consent fails - Disable enroll button and show the genric message

          this.documentConsentFailure();

        } else {
          this.dataConsentAp = consentData;
          ContentService.SetCache(
            COMMON_CONSTANTS.TERMS_CONSENT,
            this.dataConsentAp
          );
          this.manageMPI();
        }
      },
      error => {
        // Document consent fails - Disable enroll button and show the genric message
        this.documentConsentFailure(error);
      }
    );
  }

  manageMPI() { 
    if (this.selectedPaymentType === this.bankText) {
      this.mngBankObj.payment.paymentMethodReference.paymentMethodCode =
        MakePaymentConstant.paymentMethodBankCode;
      this.mngBankObj.payment.autopayTermsAgreementIndicator = true;
      const currDateTime = new Date();
      const agreementTime = currDateTime.toISOString();
      this.mngBankObj.payment.autopayTermsAgreementTime = agreementTime;
      this.achBillPayAliasData = ContentService.GetCache('achBillPayAliasData');
      this.mngBankObj.payment.paymentInstrument.bankPayment.bankAccountAlias = this.profileAliasNumber;
      this.mngBankObj.payment.paymentInstrument.bankPayment.accountNumber = '';

      this.mngBankObj.payment.paymentInstrument.bankPayment.routingNumber = HttpCacheService.GetCache(COMMON_CONSTANTS.ROUTING_NUMBER);
      this.mngBankObj.payment.paymentInstrument.bankPayment.accountHolderNames = [
        this.achGroup?.accountName
      ];
      if (this.achGroup && this.achGroup?.accountType) {
        if (this.achGroup.accountType === ACH_CONSTANTS.CHECKING_KEY) {
          this.accountType = this.achGroup.accountType.substr(0, this.achGroup?.accountType.length - 3);
        } else if (this.achGroup.accountType === ACH_CONSTANTS.SAVING_KEY) {
          this.accountType = this.achGroup.accountType.substr(0, this.achGroup?.accountType.length - 4) + 'E';
        }
      }
      this.mngBankObj.payment.paymentInstrument.bankPayment.accountType = this.accountType;
      this.mngBankObj.payment.autoPay.autoPayAcceptance =
        MakePaymentConstant.AP_ACCEPTANCE;
      this.mngBankObj.payment.autoPay.assistingDept =
        MakePaymentConstant.AP_ASSISTINGDEPT;
      this.mngBankObj.payment.businessUnit = MakePaymentConstant.businessUnit;
      this.mngBankObj.payment.programCode = MakePaymentConstant.programCode;
      this.mngBankObj.payment.billerCode = MakePaymentConstant.billerCode;
      this.mngBankObj.payment.businessSegment =
        MakePaymentConstant.autoBusinessSegment;
      this.mngBankObj.payment.orderTypes = MakePaymentConstant.schAchOrderType;
      this.mngBankObj.payment.productGroup = MakePaymentConstant.productGroup;
      this.mngBankObj.customerReference.customerId = this.banNumber;

      this.mngObj = this.mngBankObj;
      this._manageMPIHelper();
    } else if (this.selectedPaymentType === this.cardText || this.selectedPaymentType === this.creditCardText || this.selectedPaymentType === this.debitCardText || this.selectedPaymentType === this.manageCardText) {
      this.billPaySvc.fetchPublicKey().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
        (data: any) => {
          let cvv;
          cvv = this.totalDueNewCardForm.cvv;

          const card = this;
          if (cvv) {
            encrypt(data?.payment?.encryption?.jwkKey, cvv, 
              (encryptedData) => {
                encryptedData = JSON.stringify(encryptedData, undefined, 2);
                card.encryptedCvv = encryptedData.substring(1, encryptedData.length - 1);                     
                if (!isNullOrUndefined(this.encryptedCvv)) {
                  this.mngCcObj.payment.paymentMethodReference.paymentMethodCode = this.profilePayMethodeCode;
                  this.mngCcObj.payment.autopayTermsAgreementIndicator = true;
                  const currDateTime = new Date();
                  const agreementTime = currDateTime.toISOString();
                  this.mngCcObj.payment.autopayTermsAgreementTime = agreementTime;
                  this.ccBillPayAliasData = ContentService.GetCache('ccBillPayAliasData');
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cardHolderName = this.profilecardName;
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cardHolderFirstName = this.getFormattedName(
                    this.profilecardName,
                    CREDIT_CARD_CONSTANTS.FIRST
                  );
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cardHolderLastName = this.getFormattedName(
                    this.profilecardName,
                    CREDIT_CARD_CONSTANTS.LAST
                  );
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cvv = this.encryptedCvv;
                  // tslint:disable-next-line:max-line-length
                  this.mngCcObj.payment.paymentInstrument.paymentCard.typeCode = this.profilecardTypeCode;
                  this.mngCcObj.payment.paymentInstrument.paymentCard.expirationMonthYear =
                    this.totalDueNewCardForm?.expirationMonth +
                    this.totalDueNewCardForm?.expirationYear.toString().substr(2, 2);
            
                  this.mngCcObj.payment.paymentInstrument.paymentCard.paymentCardAlias = this.profileAliasNumber;
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cardNumber = '';
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 = this.totalDueNewCardForm?.ccAddress1;
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2 = this.totalDueNewCardForm?.ccAddress2;
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city = this.totalDueNewCardForm?.ccCity;
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state = this.totalDueNewCardForm?.ccState;
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.purposes =
                    MakePaymentConstant.purposes;
                  // tslint:disable-next-line:max-line-length
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.countryCode =
                    MakePaymentConstant.countryCode;
                  this.mngCcObj.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode = this.profileZipCode;
                  this.mngCcObj.payment.autoPay.autoPayAcceptance =
                    MakePaymentConstant.AP_ACCEPTANCE;
                  this.mngCcObj.payment.autoPay.assistingDept =
                    MakePaymentConstant.AP_ASSISTINGDEPT;
                  this.mngCcObj.payment.businessUnit = MakePaymentConstant.businessUnit;
                  this.mngCcObj.payment.programCode = MakePaymentConstant.programCode;
                  this.mngCcObj.payment.billerCode = MakePaymentConstant.billerCode;
                  this.mngCcObj.payment.businessSegment =
                    MakePaymentConstant.autoBusinessSegment;
                  this.mngCcObj.payment.orderTypes = MakePaymentConstant.schAchOrderType;
                  this.mngCcObj.payment.productGroup = MakePaymentConstant.productGroup;
                  this.mngCcObj.customerReference.customerId = this.banNumber;
            
                  this.mngObj = this.mngCcObj;
                  this._manageMPIHelper();
                }
              },
              (errorData) => {
                //
              }
            );
          }
        });
    }
    
  }

  _manageMPIHelper(){
    this.mngObj.payment.orderNumber = this._cacheSvc?.getFromSessionStorage(COMMON_CONSTANTS?.TRANS_REF_NUM) || "";
    this.manageEnrollSub = this._autoPaymentSvc
      .manageEnrollAutoPayment(this.mngObj)
      .subscribe(
        autoPayData => {
          if (environment.ENABLE_PARTNER &&
            this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === 'true') {
            if (!isNullOrUndefined(autoPayData) && !isNullOrUndefined(autoPayData?.payment) &&
              !isNullOrUndefined(autoPayData?.payment?.messages) && !isNullOrUndefined(autoPayData.payment.messages[0].redirectUrl)) {
              this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.RETURN_URL_SSP, autoPayData?.payment?.messages[0].redirectUrl);
            }
          }
          let errorCode = '';
          if (!isNullOrUndefined(autoPayData && autoPayData.responseCodeList)) {
            errorCode = autoPayData?.responseCodeList[0]?.errors?.responseErrors[0]?.code;
          }
          const errorCodeList = _.split("8184,8183,8330", ',');

          if (this.retry > 0 && !isNullOrUndefined(
            autoPayData && autoPayData['reason'] && autoPayData.reason.reasonCode
          ) &&
            autoPayData.reason.reasonCode ===
            MakePaymentConstant.APPROVED_MPI_CODE &&
            !autoPayData['responseCodeList'] && this._errMsgService.getErrorMessage().show) {
            this._errMsgService.unSettingErrorMsg();
          }
          if (this._errMsgService.getErrorMessage().show) {
            this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
            // Actual User Message from API to the PUT account Order API
            this.updateCrOrderStatusDesc =
              autoPayData?.responseCodeList[0]?.errors?.responseErrors[0] ? autoPayData?.responseCodeList[0]?.errors?.responseErrors[0]?.userMessage : '';
            this.updateCROrderId(autoPayData);

            this.retry++;
            this.saveSuccess = false;
            const negativeBanReasonCodeList = _.split(
              this.CommonAuthoringContent.error.negativeFileCheckcommonErrorCode,
              ','
            );

            if (
              !isNullOrUndefined(
                this.errorAlert &&
                this.errorAlert.message &&
                this.errorAlert.message.errorKey
              ) &&
              negativeBanReasonCodeList.includes(
                this.errorAlert.message.errorKey
              )
            ) {
              this.enableRetry = false;
              this._makepymntSvc.makePaymenttoAch(true);
              this._makepymntSvc.makePaymenttoCC(true);
              this.router.navigate([MakePaymentConstant.processError]);
            }
            else {
              if (this.retry > CREDIT_CARD_CONSTANTS.TWO) {
                this.enableRetry = false;
                this.router.navigate([MakePaymentConstant.processError]);
              } else {
                if (errorCodeList.includes(errorCode)) {
                  this._makepymntSvc.processPaymentErrorCounter.next(this.retry);                 
                  this.PaymentErrorModel?.openModal();
                }
                else {
                  this.enableRetry = false;
                  this.router.navigate([MakePaymentConstant.processError]);
                }
              }
            }
            return false;
          } else if (
            !isNullOrUndefined(
              autoPayData && autoPayData['reason'] && autoPayData.reason.reasonCode
            ) &&
            autoPayData.reason.reasonCode ===
            MakePaymentConstant.APPROVED_MPI_CODE &&
            !autoPayData['responseCodeList']
          ) {
            // Success scenario for MPI Auto Pay API

            this.updateCrOrderStatus = 'S';
            this.updateCrOrderStatusDesc = autoPayData['statusBody']
              ? autoPayData['statusBody'].reasonDescription
              : '';
            this.updateCROrderId(autoPayData);

            this.enrollData = autoPayData;
            this._cacheSvc.putInSessionStoarage(
              COMMON_CONSTANTS.PAYMENT_TYPE,
              this.selectedPaymentType
            );
            this._loaderSvc.stop();
            this._autoPaymentSvc.successComponent = true;            
            this._autoPaymentSvc.buttonValue = this.authoringContent.saveChangesBtnText;
            this.saveSuccess = false;
            if (this.selectedPaymentType === COMMON_CONSTANTS.CARD_TXT || this.selectedPaymentType === COMMON_CONSTANTS.TYPE_CREDIT_CARD || this.selectedPaymentType === COMMON_CONSTANTS.TYPE_DEBIT_CARD) {
              this.isCcInfoSavedSuccess = true;
              this.savedCardDetails.cardNumber = this.utils.getLastFourDigits(
                ContentService.GetCache(COMMON_CONSTANTS.CARD_DETAIL_OBJECT)
                  ?.cardNumber
              );
              this.savedCardDetails.expirationDate = ContentService.GetCache(
                COMMON_CONSTANTS.CARD_DETAIL_OBJECT
              )?.expirationDate;
              this.savedCardDetails.cardType = ContentService.GetCache(
                COMMON_CONSTANTS.CARD_DETAIL_OBJECT
              )?.cardType;
              this.getCardImage(this.savedCardDetails.cardType);
            }
            if (this.selectedPaymentType === COMMON_CONSTANTS.BANK_TXT) {
              this.isBankInfoSavedSuccess = true;
              this.isShowBankDetail = true;
              // tslint:disable-next-line:max-line-length
              this.savedBankDetails.accountNumber = this.utils.getLastFourDigits(
                ContentService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER)
              );
            }
            this._changeDetect.detectChanges();
          }
        },
        err => {
          this.documentConsentFailure(err);
        }
      );
  }
  toggleAutopay() {
    this._autoPaymentSvc.showAutopay = !this._autoPaymentSvc.showAutopay;
  }

  toggleTnC() {
    this._autoPaymentSvc.showTC = !this._autoPaymentSvc.showTC;
  }

  setAutopayTC(): any {
    this.autopayTCSub = this._tcSvc.getAutoPayAgreement().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
      autoPayData => {
        this.autopayTC = autoPayData;
        this._cacheSvc.putInSessionStoarage(
          COMMON_CONSTANTS.APSERVICE_TERMS_DATA,
          JSON.stringify(this.autopayTC)
        );
        
        const currDateTime = new Date();
        const agreementTime = currDateTime.toISOString();
        const acceptTimestamp1 = agreementTime.slice(0, -1) + '+0000';

        const autoPayAgreementConsent = {
          documentId: this.autopayTC?.acceptDocumentId,
          acceptedTimeStamp: acceptTimestamp1
        };
        this._tcSvc.setSelectedTerms(autoPayAgreementConsent);
        this.tcAutoPayAgreementContent = atob(
          this.autopayTC?.attachments?.embeddedData?.binaryContent
        );
        this.tcAutoPayAgreementContent = this.sanitizer.bypassSecurityTrustHtml(
          this.tcAutoPayAgreementContent
        );
      },
      err => {
        if ('error' in err.error) {
          this.errorAlertAutoPayTC = {
            message: {
              errorKey: err.status,
              errorMessage: err.error.error[0].userMessage,
              errorType: COMMON_CONSTANTS.AUTOPAY_PROPERTIES.ERR_SERVER
            },
            show: true
          };
        }
      }
    );
  }

  setAccountTitle(baseban): any {
     this.titleLable = `${this.authoringContent.autopayTitlelblTxt1.replace(
                COMMON_CONSTANTS.AUTOPAY_PROPERTIES.BAN,
                this.banNumber
            )}`;

              if (this.banFriendlyName) {
                this.titleLable += `${this.authoringContent.autopaytitlelblTxt2.replace(
                    COMMON_CONSTANTS.AUTOPAY_PROPERTIES.BANNAME,
                    this.banFriendlyName
                )}`;
              }

            }

  public openCancelModel() {
    this.flowTypeCheck(COMMON_CONSTANTS.CANCEL_FLOW);
    this._autoPaymentSvc.cancelComponent = true;
    // analytics click tagging
    this.generateClickDetailsForAutoPay("click", "link", ClickEventType.ACTION, "Turn Off AutoPay", "Autopay cancel modal")
  }
  onCancel() {
    // analytics click tagging
    this.generateClickDetailsForAutoPay("click", "button", ClickEventType.NAVIGATE, "Cancel", "")
    if( this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW) == 'true'){
      this.utils.redirectToPartnerBillingApp();
    }
  }
  checkAutopayEligibility() {
    this.enrollEligibilitySub = this._autoPaymentSvc
      .getAutopayEligibility()
      .pipe(this.pipedOperatorsService.subReattempt())
      .subscribe(
        autopayEligibleData => { 
          if (!isNullOrUndefined(autopayEligibleData)) {
            this.autoPayEligibilityData = autopayEligibleData;
              this.isEnrollAutopayEligible = !isNullOrUndefined(
                this.autoPayEligibilityData.isBanEligibleForAutoPay
              )
                ? this.autoPayEligibilityData.isBanEligibleForAutoPay
                : false;
              this.isManageAutopayEligible = !isNullOrUndefined(
                this.autoPayEligibilityData.isBanEnrolledForAutoPay
              )
                ? this.autoPayEligibilityData.isBanEnrolledForAutoPay
                : false;
          }
          if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === 'true') {
            this.autoPaySelectedOption = !isNullOrUndefined(
              this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.FLOW_KEY)
            )
              ? this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.FLOW_KEY)
              : COMMON_CONSTANTS.ENROLL_FLOW;
            if (
              this.autoPaySelectedOption === COMMON_CONSTANTS.MANAGE_FLOW ||
              this.autoPaySelectedOption === COMMON_CONSTANTS.ENROLL_FLOW
            ) {
              this.setAutopayTC();
            }
            if (
              this.autoPaySelectedOption === COMMON_CONSTANTS.MANAGE_FLOW ||
              this.autoPaySelectedOption === COMMON_CONSTANTS.CANCEL_FLOW
            ) {
              this.autoPayPaymentProfile();
            }
            this.flowTypeCheck(this.autoPaySelectedOption);
            this.returnURI = this._cacheSvc.getFromSessionStorage(
              COMMON_CONSTANTS.RETURN_URL_SSP
            );
            this.setShowRadioButton();
          }
        },
        err => {
          const showErr = ContentService.GetCache('content');
          this.errorAlert = {
            message: {
              errorKey: err.status,
              errorMessage: !isNullOrUndefined(
                this.errorMessage &&
                this.errorMessage.error &&
                this.errorMessage.error.userMessage
              )
                ? this.errorMessage.error.userMessage
                : showErr.error.handshakeFailureErrorTxt,
              errorType: COMMON_CONSTANTS.AUTOPAY_PROPERTIES.ERR_SERVER
            },
            show: true
          };
        }
      );
  }

  autoPayPaymentProfile() {
    this.autoPayPaymentProfileSub = this._autoPaymentSvc
      .getAutoPaymentProfile()
      .pipe(this.pipedOperatorsService.subReattempt())
      .subscribe(
        data => {
          if (!isNullOrUndefined(data) && data['status'].statusCode === 'SUCCESS' && data['payment']) {
            ContentService.SetCache(COMMON_CONSTANTS.SAVED_PAYMNT_OBJECT, data?.payment);
            this.paymentProfileData = data?.payment;
            if (this.autoPaySelectedOption === COMMON_CONSTANTS.CANCEL_FLOW) {
              this.openCancelModel();
            }
            const accountNo =
              !isNullOrUndefined(
                this.paymentProfileData?.paymentInstrument?.bankPayment
              ) &&
                !isNullOrUndefined(
                  this.paymentProfileData?.paymentInstrument?.bankPayment
                    .bankAccountAlias
                )
                ? this.paymentProfileData?.paymentInstrument?.bankPayment
                  .bankAccountAlias
                : '';
            const cardNo =
              !isNullOrUndefined(
                this.paymentProfileData?.paymentInstrument?.paymentCard
              ) &&
                !isNullOrUndefined(
                  this.paymentProfileData?.paymentInstrument?.paymentCard
                    .paymentCardAlias
                )
                ? this.paymentProfileData?.paymentInstrument?.paymentCard
                  .paymentCardAlias
                : '';
            if (accountNo !== '') {
              this.ShowbankOption = true;
              this.showcardDefault = true;
              this.profileAliasNumber = accountNo;
              this.achService.isBankInfoSaved = true;

              this.bankAccountNumber = this.utils.getLastFourDigits(accountNo);
              this.selectedPaymentType = COMMON_CONSTANTS.BANK_TXT;
              this.isShowBankDetail = true;
              this.savedBankDetails.accountNumber = this.utils.getLastFourDigits(
                accountNo
              );
              this.currentBnkRoutingNumber =
                !isNullOrUndefined(
                  this.paymentProfileData?.paymentInstrument?.bankPayment
                ) &&
                  !isNullOrUndefined(
                    this.paymentProfileData?.paymentInstrument?.bankPayment
                      .routingNumber
                  )
                  ? this.paymentProfileData?.paymentInstrument?.bankPayment
                    .routingNumber
                  : '';
              this.currentPayAcntCardNumber = this.bankAccountNumber;
              this.profilePayMethodeCode = "ach";
            } else if (cardNo !== '') {
              this.ShowCraditCardOption = true;
              this.showcardDefault = false;             
              this.profileAliasNumber = cardNo;
              if (!isNullOrUndefined(this.paymentProfileData) &&
                !isNullOrUndefined(this.paymentProfileData.paymentInstrument) &&
                !isNullOrUndefined(this.paymentProfileData?.paymentInstrument?.paymentCard)) {

                if (!isNullOrUndefined(this.paymentProfileData?.paymentInstrument?.paymentCard.typeCode)) {
                  this.profilecardTypeCode = this.paymentProfileData?.paymentInstrument?.paymentCard
                    .typeCode;
                }
                if (!isNullOrUndefined(this.paymentProfileData?.paymentInstrument?.paymentCard?.cardHolderName)) {
                  this.profilecardName = this.paymentProfileData?.paymentInstrument?.paymentCard?.cardHolderName;
                }
                //Jun release radio button selection
                if(this.apdJuneRelease && !this.mayReleaseFlag)
                {
                  let cardType = this.paymentProfileData?.paymentMethodReference?.issuerCardCategory;
                  if (!isNullOrUndefined(cardType)) {
                    this.profilePayMethodeCode =
                      cardType.toLowerCase() === COMMON_CONSTANTS.TYPE_CREDIT
                        ? COMMON_CONSTANTS.PAYMENT_METHOD_CODE_CREDIT
                        : cardType.toLowerCase() === COMMON_CONSTANTS.TYPE_DEBIT
                        ? COMMON_CONSTANTS.PAYMENT_METHOD_CODE_DEBIT
                        : "";
                  }
                } 
                //May release radio button selection
                if (!isNullOrUndefined(this.paymentProfileData.paymentMethodReference.paymentMethodCode) && (this.mayReleaseFlag && this.apdJuneRelease)) {
                  this.profilePayMethodeCode = this.paymentProfileData.paymentMethodReference.paymentMethodCode;
                }
                if (!isNullOrUndefined(this.paymentProfileData?.paymentInstrument?.paymentCard?.cardHolderAddress) &&
                  !isNullOrUndefined(this.paymentProfileData?.paymentInstrument?.paymentCard?.cardHolderAddress.AddressCore) &&
                  !isNullOrUndefined(this.paymentProfileData?.paymentInstrument?.paymentCard?.cardHolderAddress.AddressCore.zip)) {
                  this.profileZipCode = this.paymentProfileData?.paymentInstrument?.paymentCard?.cardHolderAddress.AddressCore.zip;
                }
              }
              this.creditService.isCcInfoSaved = true;
              this.creditCardNumber = this.utils.getLastFourDigits(cardNo);
 
              if(this.apdJuneRelease){
                if(this.mayReleaseFlag && (this.profilePayMethodeCode == COMMON_CONSTANTS.PAYMENT_METHOD_CODE_CREDIT || this.profilePayMethodeCode == COMMON_CONSTANTS.PAYMENT_METHOD_CODE_DEBIT)) {
                  this.selectedPaymentType = COMMON_CONSTANTS.TYPE_CREDITDEBIT_MAYRELEASE_CARD;
                  this.updateAutopayDiscountELigibility();
                }
                else if(this.profilePayMethodeCode == COMMON_CONSTANTS.PAYMENT_METHOD_CODE_CREDIT){
                  this.selectedPaymentType = this.creditCardText;
                  this.updateAutopayDiscountELigibility();
                }else if(this.profilePayMethodeCode == COMMON_CONSTANTS.PAYMENT_METHOD_CODE_DEBIT){
                  this.selectedPaymentType = this.debitCardText;
                }else{
                  this.selectedPaymentType = COMMON_CONSTANTS.CARD_TXT;
                }
              }else{
                this.selectedPaymentType = COMMON_CONSTANTS.CARD_TXT;
              }
              
              this.isShowCardDetail = true;
              this.savedCardDetails.cardNumber = this.utils.getLastFourDigits(
                cardNo
              );
              this.currentPayAcntCardNumber = this.creditCardNumber;

              this.savedCardDetails.expirationDate =
                this.paymentProfileData?.paymentInstrument?.paymentCard?.expirationMonthYear.slice(
                  0,
                  2
                ) +
                '/' +
                this.paymentProfileData?.paymentInstrument?.paymentCard?.expirationMonthYear.slice(
                  2,
                  4
                );
              this.paymentProfileData.paymentInstrument.paymentCard.expirationMonthYear = this.savedCardDetails.expirationDate;
              this.cardExpirationDate = this.savedCardDetails.expirationDate;
              this.savedCardDetails.cardType = this.paymentProfileData.paymentInstrument.paymentCard?.typeCode;
              this.currentPayAcntCardName = this.paymentProfileData.paymentInstrument.paymentCard?.cardHolderName;
              if (
                this.paymentProfileData?.paymentInstrument?.paymentCard
                  .typeCode !== ''
              ) {
                this.getCardImage(
                  this.paymentProfileData?.paymentInstrument?.paymentCard.typeCode
                );
              }
              this.showCardBasicInfo();
            }
          }
        },
        err => {
          this.errorAlert = {
            message: {
              errorKey: err.status,
              errorMessage: err.error[0].userMessage,
              errorType: COMMON_CONSTANTS.AUTOPAY_PROPERTIES.ERR_SERVER
            },
            show: true
          };
        }
      );
  }

  showCardBasicInfo() {
    if(this.ShowCraditCardOption && !this.showcardDefault && !(this.autoPaySelectedOption == COMMON_CONSTANTS.ENROLL_FLOW)) {
      this.showBasicCreditCardInfo = false;
      this.showBasicDebitCardInfo = false;
      if(this.profilePayMethodeCode == COMMON_CONSTANTS.PAYMENT_METHOD_CODE_CREDIT && this.selectedPaymentType == COMMON_CONSTANTS.TYPE_CREDIT_CARD)
        this.showBasicCreditCardInfo = true;
      else if(this.profilePayMethodeCode == COMMON_CONSTANTS.PAYMENT_METHOD_CODE_DEBIT && this.selectedPaymentType == COMMON_CONSTANTS.TYPE_DEBIT_CARD)
        this.showBasicDebitCardInfo = true;
    }
  }

  ngOnDestroy() {
    if(this.autopayTCSub) {
      this.autopayTCSub.unsubscribe();
    }
    if(this.tcSub) {
      this.tcSub.unsubscribe();
    }
    if(this.enrollEligibilitySub) {
      this.enrollEligibilitySub.unsubscribe();
    }
    if (this.autoPayPaymentProfileSub) {
      this.autoPayPaymentProfileSub.unsubscribe();
    }
    if (this.manageEnrollSub) {
      this.manageEnrollSub.unsubscribe();
    }
    if (this.submitEnrollSub) {
      this.submitEnrollSub.unsubscribe();
    }
  }

  changeSavedInfo(paymenttype, type, action) {
    this.creditEligibleDiscount = true;  
    switch (action) {
      case 'edit':
        // analytics click tagging
        this.generateClickDetailsForAutoPay("click", "link", ClickEventType.ACTION, "Edit", "");        
        this.isEdit = false;
        this.isChange = true;
        this.showcardDefault = false;
        this.cardTypeVal = true;
        break;
      case 'change':
        // analytics click tagging
        this.generateClickDetailsForAutoPay("click", "link", ClickEventType.ACTION, "Change card", "")
        this.isEdit = true;
        this.isChange = false;
        this.showcardDefault = false;
        this.cardTypeVal = true;
        this.formValid = false;
        break;
      default:
        this.isEdit = true;
        this.isChange = true;
        this.cardTypeVal = true;
    }
    this.selectedPaymentType = paymenttype;
    if (this.selectedPaymentType === COMMON_CONSTANTS.BANK_TXT) {
      if (!isNullOrUndefined(this.bankAccountNumber)) {
        ContentService.ClearCacheKey(COMMON_CONSTANTS.ACCOUNT_NUMBER);
        this.achService.isBankInfoSaved = false;
      }
      if (this.creditCardNumber !== '') {
        this.creditService.isCcInfoSaved = true;
      }
      this.saveSuccessACHType = type;
    } else if (this.selectedPaymentType === COMMON_CONSTANTS.CARD_TXT || this.selectedPaymentType === this.manageCardText) {
      if (!isNullOrUndefined(this.creditCardNumber)) {
        ContentService.ClearCacheKey(COMMON_CONSTANTS.CC_NUMBER);
        this.creditService.isCcInfoSaved = false;
      }
      if (this.bankAccountNumber !== '') {
        this.achService.isBankInfoSaved = true;
      }
      this.saveSuccessCreditType = type;
    }else if(this.selectedPaymentType === COMMON_CONSTANTS.TYPE_CREDIT_CARD){
      if (!isNullOrUndefined(this.creditCardNumber)) {
        ContentService.ClearCacheKey(COMMON_CONSTANTS.CC_NUMBER);
        this.creditService.isCcInfoSaved = false;
      }
      if (this.bankAccountNumber !== '') {
        this.achService.isBankInfoSaved = true;
      }
      this.saveSuccessCreditType = type;
    }else if(this.selectedPaymentType === COMMON_CONSTANTS.TYPE_DEBIT_CARD){
      if (!isNullOrUndefined(this.creditCardNumber)) {
        ContentService.ClearCacheKey(COMMON_CONSTANTS.CC_NUMBER);
        this.creditService.isCcInfoSaved = false;
      }
      if (this.bankAccountNumber !== '') {
        this.achService.isBankInfoSaved = true;
      }
      this.saveSuccessCreditType = type;
    }
    if((this.selectedPaymentType === COMMON_CONSTANTS.TYPE_CREDIT_CARD ||this.selectedPaymentType === this.manageCardText)
        && !this.isEdit
        && (this.creditMigrationDate)) {
      this.queryAutopayDiscountELigibility();
    }
  }

  getCardImage(cardBrand) {
    for (
      let index = COMMON_CONSTANTS.ZERO;
      index < this.creditService.cardTypeIcons.length;
      index++
    ) {
      if (
        this.creditService.cardTypeIcons[index].brand === cardBrand ||
        this.creditService.cardBrandType[index].brand === cardBrand
      ) {
        this.getCardImageURL = this.creditService.cardTypeIcons[index].url;
      }
    }
  }

  updateCROrderId(valResponse?: any) {
    if (this.selectedPaymentType === this.bankText && !isNullOrUndefined(valResponse && valResponse?.payment?.paymentInstrument)) {
      this.UpdateCrOrderObj.bankAccountAlias = !isNullOrUndefined(valResponse?.payment?.paymentInstrument?.bankPayment) ?
        valResponse?.payment?.paymentInstrument?.bankPayment?.bankAccountAlias : '';
    } else if ((this.selectedPaymentType === this.cardText || this.selectedPaymentType === this.creditCardText || this.selectedPaymentType === this.debitCardText || this.selectedPaymentType === this.manageCardText) && !isNullOrUndefined(valResponse && valResponse?.payment?.paymentInstrument)) {
      // tslint:disable-next-line:max-line-length
      this.UpdateCrOrderObj.cardNumberAlias = !isNullOrUndefined(valResponse?.payment?.paymentInstrument?.paymentCard) ?
        valResponse?.payment?.paymentInstrument?.paymentCard?.paymentCardAlias : '';
    }
    this.UpdateCrOrderObj.billingAccount = this.banNumber;
    const euiTokenData=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA);
    this.UpdateCrOrderObj.logonId = euiTokenData ? JSON.parse(euiTokenData).logonId : '';
    this.UpdateCrOrderObj.emailNotification.firstName=euiTokenData ? JSON.parse(euiTokenData)?.firstName : '';
    this.UpdateCrOrderObj.emailNotification.emailId=this.UpdateCrOrderObj.logonId;
    // tslint:disable-next-line:max-line-length
    this.UpdateCrOrderObj.status = this.updateCrOrderStatus;
    this.UpdateCrOrderObj.transactionDesc = this.updateCrOrderStatusDesc;
    if (
      !isNullOrUndefined(this.dataConsentAp) &&
      !isNullOrUndefined(this.dataConsentAp.postedDocumentDetails)
    ) {
        for (let eachPostedDocumentDetails of this.dataConsentAp.postedDocumentDetails) {
          const element = {};
          element['docId'] = !isNullOrUndefined(
            eachPostedDocumentDetails.ingestDocumentId
          )
            ? eachPostedDocumentDetails.ingestDocumentId
            : '';

        element['docType'] = !isNullOrUndefined(
          eachPostedDocumentDetails.documentType
        )
          ? eachPostedDocumentDetails.documentType
          : '';
        element['acceptedDateTime'] = this._tcSvc.findAndConvertAcceptedTimeStampByDocumentId(eachPostedDocumentDetails.documentId);
        this.consentArray.push(element);
      }
      this.UpdateCrOrderObj.legalDocuments = this.consentArray;
    } else if (!this.dataConsentAp) {
      this.UpdateCrOrderObj.legalDocuments.push(this.legalDocument);
    }

    this._makepymntSvc.updateCROrder(this.UpdateCrOrderObj).subscribe(
      crData => {
        // This is intentional
      },
      error => {
        // This is intentional
      }
    );
  }

  onEntrust() {
    this.entrustUrl = this.content.landingInfo.enTrustLogoUrl.replace(
      '[host]',
      location.host
    );
    window.open(
      this.entrustUrl,
      '',
      'width=537,height=457,left=19%,top=50,sceenX=19%,screenY=50'
    );
  }

  onEntrustPrivacy() {
    window.location.href = this.content.landingInfo.enTrustPrivacyUrl;
  }

  LogErrorMessage(err?: any) {
    this.saveSuccess = false;
    this.router.navigate([MakePaymentConstant.processError]);
  }



  createOrderId(isEdit?: any) {
    if (this.selectedPaymentType === this.bankText) {
      this.achBillPayAliasObj.paymentType = ACH_CONSTANTS.PAYMENT_TYPE;
      this.achBillPayAliasObj.accountHolderName = this.achGroup.accountName;

      this.achBillPayAliasObj.accountType = this.achGroup.accountType;

      this.achBillPayAliasObj.routingNumber = HttpCacheService.GetCache(COMMON_CONSTANTS.ROUTING_NUMBER);
      this.achBillPayAliasObj.billingAccountNumber = this.banNumber;
      this.achBillPayAliasObj.orgId = this.orgID;
      this.achBillPayAliasObj.skipLegacyValidation = 'true';
      if (this.autoPaySelectedOption === COMMON_CONSTANTS.ENROLL_FLOW) {
        this.achBillPayAliasObj.transactionType = COMMON_CONSTANTS.TRANSACTION_TYPE;
      } else if (this.autoPaySelectedOption === COMMON_CONSTANTS.MANAGE_FLOW) {
        this.achBillPayAliasObj.transactionType = COMMON_CONSTANTS.UPDATE_TRANSACTION_TYPE;
      }
      this.billPayAliasObj = this.achBillPayAliasObj;
    } else if (this.selectedPaymentType === this.cardText || this.selectedPaymentType === this.creditCardText || this.selectedPaymentType === this.debitCardText || this.selectedPaymentType === this.manageCardText) {
      if (!isNullOrUndefined(isEdit) && !isEdit) {
        this.ccBillPayAliasObj.cardholderName = this.profilecardName;
        this.ccBillPayAliasObj.cardBrand = this.profilecardTypeCode;
      } else if (isNullOrUndefined(isEdit)) {
        this.ccBillPayAliasObj.cardholderName = this.totalDueNewCardForm.nameOnCreditCard ? this.totalDueNewCardForm.nameOnCreditCard
          : '';
        this.ccBillPayAliasObj.cardBrand = this.cardTypeCode;
      }

      this.ccBillPayAliasObj.paymentType = CREDIT_CARD_CONSTANTS.PAYMENT_TYPE;
      // tslint:disable-next-line:max-line-length
      // this.ccBillPayAliasObj.cardNumberAlias = !isNullOrUndefined(valResponse.paymentInstrument) ?
      //  valResponse.paymentInstrument.paymentCard.paymentCardAlias : '';
      this.ccBillPayAliasObj.expireMonth = this.totalDueNewCardForm.expirationMonth;
      this.ccBillPayAliasObj.expireYear = this.totalDueNewCardForm.expirationYear;
      this.ccBillPayAliasObj.billingAccountNumber = this.banNumber;
      this.ccBillPayAliasObj.orgId = this.orgID;
      this.ccBillPayAliasObj.skipLegacyValidation = 'true';
      if (this.autoPaySelectedOption === COMMON_CONSTANTS.ENROLL_FLOW) {
        this.ccBillPayAliasObj.transactionType = COMMON_CONSTANTS.TRANSACTION_TYPE;
      } else if (this.autoPaySelectedOption === COMMON_CONSTANTS.MANAGE_FLOW) {
        this.ccBillPayAliasObj.transactionType = COMMON_CONSTANTS.UPDATE_TRANSACTION_TYPE;
      }
      this.ccBillPayAliasObj.billingAddress.address1 = this.totalDueNewCardForm.ccAddress1;
      this.ccBillPayAliasObj.billingAddress.city = this.totalDueNewCardForm.ccCity;
      this.ccBillPayAliasObj.billingAddress.state = this.totalDueNewCardForm.ccState;
      this.ccBillPayAliasObj.billingAddress.zipCode = this.totalDueNewCardForm.ccZip;
      this.billPayAliasObj = this.ccBillPayAliasObj;
    }
  }

  getFormattedName(name: string, type: string) {
    if (!isNullOrUndefined(name)) {
      const nameArr = name.toString().split(' ');
      if (type === CREDIT_CARD_CONSTANTS.FIRST) {
        if (nameArr.length > CREDIT_CARD_CONSTANTS.TWO) {
          return nameArr[0] + ' ' + nameArr[1];
        } else {
          return nameArr[0];
        }
      } else {
        if (nameArr.length > CREDIT_CARD_CONSTANTS.ONE) {
          return nameArr[nameArr.length - 1];
        } else {
          return '';
        }
      }
    }
  }

  successEnroll(valResponse) {
    this.enrollData = valResponse;

    this._cacheSvc.putInSessionStoarage(
      COMMON_CONSTANTS.PAYMENT_TYPE,
      this.selectedPaymentType
    ); 
    
    // if Mange AutoPay is done through Enroll POST method, then pass saveChanges button text
    if (!this.submitManagethruEnroll && this.autoPaySelectedOption == COMMON_CONSTANTS.ENROLL_FLOW) {
      this._autoPaymentSvc.buttonValue = this.authoringContent.enrollButtonText;
    } else {
      this._autoPaymentSvc.buttonValue = this.authoringContent.saveChangesBtnText;
    }
    this.saveSuccess = false;
    if (this.selectedPaymentType === COMMON_CONSTANTS.CARD_TXT || this.selectedPaymentType === COMMON_CONSTANTS.TYPE_CREDIT_CARD || this.selectedPaymentType === COMMON_CONSTANTS.TYPE_DEBIT_CARD || this.selectedPaymentType === this.manageCardText) {
      this.isCcInfoSavedSuccess = true;
    }
    if (this.selectedPaymentType === COMMON_CONSTANTS.BANK_TXT) {
      this.isBankInfoSavedSuccess = true;
    }    
    this._loaderSvc.stop();
    this._autoPaymentSvc.successComponent = true; 
    this._changeDetect.detectChanges();
  }

  checkUpdateAutoPayAPI(cardAccountNumber) {
    // the Below check for to use POSt MPI API or PUT Mpi APi for Updating the Auto PAy
    // This condition is to check current profile Last 4 digist of alias number with user entered last 4 didgits
    // If it is true, call PUT MPI otherwise call POST MPI API
    const newCardAccountNumberlast4 = this.utils.getLastFourDigits(
      cardAccountNumber
    );
    if (this.selectedPaymentType === this.bankText) {
      if (
        newCardAccountNumberlast4 === this.currentPayAcntCardNumber &&
        this.currentBnkRoutingNumber === HttpCacheService.GetCache(COMMON_CONSTANTS.ROUTING_NUMBER)
      ) {
        this.manageMPI();
      } else {
        this.enrollMPI(this.encrypedNumber);
      }
    } else if (this.selectedPaymentType === this.cardText || this.selectedPaymentType === this.creditCardText || this.selectedPaymentType === this.debitCardText) {
      const newExpirationMonthYear =
        this.totalDueNewCardForm.expirationMonth +
        '/' +
        this.totalDueNewCardForm.expirationYear.toString().substr(2, 2);
      if (
        newCardAccountNumberlast4 === this.currentPayAcntCardNumber &&
        this.savedCardDetails.cardType ===
        HttpCacheService.GetCache(CREDIT_CARD_CONSTANTS.CARD_TYPE) &&
        this.savedCardDetails.expirationDate === newExpirationMonthYear &&
        this.currentPayAcntCardName ===
        this.totalDueNewCardForm.nameOnCreditCard
      ) {
        this.manageMPI();
      } else {
        this.enrollMPI(this.encrypedNumber);
      }
    }
  }

  documentConsentFailure(error?: any) {
    this.errorMessage = ContentService.GetCache('content');
    this.enableRetry = false;
    this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
    // add some more conditions of error for 200 consent
    if (!isNullOrUndefined(error) && error.status === 404) {
      this.updateCrOrderStatusDesc = this.errorMessage.error.genericErrorApTxt; // actual error message from Consent  API
      this.updateCROrderId();
      this.LogErrorMessage();
    } else {
      if (!isNullOrUndefined(error) && ('error' in error) && ('error' in error.error) && error.error.error.length > 0) {
        // tslint:disable-next-line:max-line-length
        this.updateCrOrderStatusDesc = error.error.error[0].userMessage ? error.error.error[0].userMessage : this.errorMessage.error.genericErrorApTxt; // actual error message from Consent  API
        this.updateCROrderId();
        this.LogErrorMessage(error);
      } else if (isNullOrUndefined(error)) {
        this.updateCrOrderStatusDesc = this.errorMessage.error.genericErrorApTxt; // actual error message from Consent  API
        this.updateCROrderId();
        this.LogErrorMessage();
      } else if (!isNullOrUndefined(error) && ('error' in error) && ('errors' in error.error) && error.error.errors.length > 0) {
        this.updateCrOrderStatusDesc = error.error.errors[0].userMessage
          ? error.error.errors[0].userMessage
          : this.errorMessage.error.genericErrorApTxt;
        this.updateCROrderId();
        this.LogErrorMessage(error);
      } else if (!isNullOrUndefined(error) && ('error' in error) && ('errors' in error.error)
        && ('error' in error.error.errors) && error.error.errors.error.length > 0) {
        this.updateCrOrderStatusDesc = error.error.errors.error[0].userMessage
          ? error.error.errors.error[0].userMessage
          : this.errorMessage.error.genericErrorApTxt;
        this.updateCROrderId();
        this.LogErrorMessage(error);
      } else {
        this.updateCrOrderStatusDesc = this.errorMessage.error.genericErrorApTxt; // actual error message from Consent  API
        this.updateCROrderId();
        this.LogErrorMessage(error);
      }
    }
  }

  retryRequest(err?: any) {
    this.retry++;
    this.errorMessage = ContentService.GetCache(
      COMMON_CONSTANTS.CONTENT_CACHE_KEY
    );
    if (this.retry > CREDIT_CARD_CONSTANTS.TWO) {
      this.errorAlert = {
        message: {
          errorKey: err.status,
          errorMessage: this.errorMessage.error.retryCardBankErrorTxt1,
          errorType: CREDIT_CARD_CONSTANTS.ERR_SERVER
        },
        show: true
      };
      this.isValid = false;
    } else if (this.retry <= CREDIT_CARD_CONSTANTS.TWO) {
      this.errorAlert = {
        message: {
          errorKey: err.status,
          errorMessage: this.errorMessage.error.retryCardErrorTxt,
          errorType: CREDIT_CARD_CONSTANTS.ERR_SERVER
        },
        show: true
      };
    }
  }


  printToCart() {
    window.print();
  }

  print(id) {
    this.utils.print(id);
  }

  generateClickDetailsForAutoPay(actionInput: string, assetTypeInput: string, typeInput: ClickEventType, ctaNameInput: string, modalNameInput: string) {
    let clickDetail = {
      action: actionInput,
      assetType: assetTypeInput,
      type: typeInput,
      ctaName: ctaNameInput,
      modalName: modalNameInput
    };
    
    this.pdlService.generateClickTag(clickDetail);
  }

  public generateClickDetailsCancelButtonAutoPay() {
    // analytics click tagging helper method to use in html
    this.generateClickDetailsForAutoPay("click", "button", ClickEventType.NAVIGATE, "Cancel", "");
    this.return();
  }

  public return(){
    this.utils.return(this.returnURI, false);
  }

  public setShowRadioButton() {
    this.showBAUCreditDebit = this.apdJuneRelease ? false : true;
    this.showSeperateCreditDebit = (this.apdJuneRelease &&
      ((this.autoPaySelectedOption =="enroll" && this.mayReleaseFlag) || (!this.mayReleaseFlag))) ? true : false;
    this.showMayCreditDebit = (this.apdJuneRelease && this.mayReleaseFlag && this.autoPaySelectedOption == 'manage') ? true : false;
  }
}
