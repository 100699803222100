import { Component, ViewChild, OnDestroy, OnInit, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContentService } from '../services/content.service';
import { EnvironmentDetailsService } from '../services/environment-details.service';
import { environment } from '../../environments/environment';
import { HttpCacheService } from '../services/http-cache.service';
import { LoaderService } from '../services/loader.service';
import { TermAndConditionService } from '../services/term-and-condition.service';
import { DomSanitizer } from '@angular/platform-browser';
import {
  COMMON_CONSTANTS, MakePaymentConstant, ACH_CONSTANTS, CREDIT_CARD_CONSTANTS,
  CreditCardConstants, PDL_CONSTANTS
} from '../common/core/common.constants';
import {
  BankMakeAPaymentModal, PaymentValidation, CCBillPayAliasObj, UpdateCrOrderPaymentModel, 
  LegalDocument, EncryptionKey, Specifications, HardGoodsPaymntModel, SimOnHandPaymentModal
} from '../common/models/data.model';
import { ErrorMessageService, ServiceMessage } from '../services/error-message.service';
import { BillPayService } from '../services/bill-pay.service';
import { MakePaymentService } from '../services/make-payment.service';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { UtilsService } from '../services/utils.service';
import { DatePipe, NgIf, CurrencyPipe } from '@angular/common';
import { Router } from '@angular/router';
import { PaymentErrorPopupComponent } from '../common/payment-error-popup/payment-error-popup.component';
import * as _ from 'lodash';
import { PnpClientService } from 'src/pnpframework/public-api';
import { PDLService } from 'src/app/services/pdl.service';
import { PipedOperatorsService } from '../common/pipes/customOperators/pipedOperatorsService.service';
import { FooterComponent } from '../footer/footer.component';
import { RecaptchaV2Component } from '../common/recaptcha-v2/recaptcha-v2.component';
import { TermsAndConditionsComponent } from '../makePayment/terms-and-conditions/terms-and-conditions.component';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { HeaderComponent } from '../header/header.component';
declare const encrypt;

@Component({
    selector: 'app-sim-on-hand',
    templateUrl: './sim-on-hand.component.html',
    styleUrls: [
        './sim-on-hand.component.scss',
        './../makePayment/make-payment/make-payment.component.scss'
    ],
    standalone: true,
    imports: [NgIf, HeaderComponent, CreditCardComponent, TermsAndConditionsComponent, RecaptchaV2Component, PaymentErrorPopupComponent, FooterComponent, CurrencyPipe, DatePipe]
})

export class SimOnHandComponent implements OnInit, OnDestroy {
  _loaderSvc: LoaderService;
  _paymentSvc: MakePaymentService;
  _cacheSvc: HttpCacheService;
  _errMsgService: ErrorMessageService;
  environmentService: EnvironmentDetailsService;
  contentService: ContentService;
  cacheService: HttpCacheService;
  termsAndConditionsSvc: TermAndConditionService;
  sanitizer: DomSanitizer;
  billPaySvc: BillPayService;
  utils: UtilsService;
  datePipe: DatePipe;
  router: Router;
  pdlService: PDLService;
  pnpClientService: PnpClientService;
@ViewChild(PaymentErrorPopupComponent) PaymentErrorModel: PaymentErrorPopupComponent;
  showSubHeadertxt = "";
  entrustUrl: any;
  authoringContent: any;
  paymentAmount: number = 0;
  orgID: any;
  saveSuccess = false;
  todayDate = new Date();
  isCcOptionSelected = true;
  errorAlert: ServiceMessage = { message: null, show: false };
  public tcContent: any;
  autopayTCSub: any;
  autopayTC: any;
  titleLabel: any;
  enableRetry = true;
  luhnCheck: boolean = true;
  cardTypeVal: boolean = true;
  formValid: boolean;
  public tcAutoPayAgreementContent: any;
  errorAlertAutoPayTC: { message: { errorKey: any; errorMessage: any; errorType: string; }; show: boolean; };
  errorAlertTC: { message: { errorKey: any; errorMessage: any; errorType: string; }; show: boolean; };
  returnURI: string;
  recaptchaToken: string;
  ccSavedData: PaymentValidation = new PaymentValidation();
  hgCCValidation: HardGoodsPaymntModel = new HardGoodsPaymntModel();
  ccBillPayAliasData: CCBillPayAliasObj = new CCBillPayAliasObj();
  paymentMethodCode: any;
  thankuDataObject: any;
  dataConsentCheck: any;
  dataConsent: any;
  totalDueNewCardForm: any;
  cardDetailObject: any;
  publicKeySubscription: Subscription;
  subscription: Subscription;
  isCCEncryptComplete: boolean;
  ccSpecifications: Specifications = new Specifications();
  cardBrand: any = {};
  //cnpSalesSubscription: Subscription;
  billPaySubscription:Subscription;
  maskedCcNumber: string;
  billPayAliasData: any;
  banNumber: any;
  errorMessage: any;
  encrypedNumber: any;
  sPValidation: {};
  cardTypeCode: any;
  key1: EncryptionKey = new EncryptionKey();
  maskedChars: string;
  transactionId:any;
  paymementInfo = '';
  maskedAccount = '';
  paymentDate: Date;
  mPBankValidation: BankMakeAPaymentModal = new BankMakeAPaymentModal();
  mPCCValidation: SimOnHandPaymentModal = new SimOnHandPaymentModal();
  mPUpdateCrOrder: UpdateCrOrderPaymentModel = new UpdateCrOrderPaymentModel();
  mPValidation: {};
  retry = CREDIT_CARD_CONSTANTS.ZERO;
  updateCrOrderStatus: any;
  updateCrOrderStatusDesc: any;
  consentArray: any = [];
  legalDocument: LegalDocument = new LegalDocument();
  updateCrOrderObj: {};
  showLinkExpired = false;
  encryptedCvv:any;

  get isRunningInGlobalNav(): boolean {
    return this.pnpClientService.isRunningInGlobalNav();
  }

  constructor( private injector: Injector, private pipedOperatorsService: PipedOperatorsService) {
    this._loaderSvc = injector.get<LoaderService>(LoaderService);
    this._paymentSvc = injector.get<MakePaymentService>(MakePaymentService);
    this._cacheSvc = injector.get<HttpCacheService>(HttpCacheService);
    this._errMsgService = injector.get<ErrorMessageService>(ErrorMessageService);
    this.environmentService = injector.get<EnvironmentDetailsService>(EnvironmentDetailsService);
    this.contentService = injector.get<ContentService>(ContentService);
    this.cacheService = injector.get<HttpCacheService>(HttpCacheService);
    this.termsAndConditionsSvc = injector.get<TermAndConditionService>(TermAndConditionService);
    this.sanitizer = injector.get<DomSanitizer>(DomSanitizer);
    this.billPaySvc = injector.get<BillPayService>(BillPayService);
    this.utils = injector.get<UtilsService>(UtilsService);
    this.datePipe = injector.get<DatePipe>(DatePipe);
    this.router = injector.get<Router>(Router);
    this.pdlService = injector.get<PDLService>(PDLService);
    this.pnpClientService = injector.get<PnpClientService>(PnpClientService);
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.cacheService.getFromSessionStorage('contentmain'))) {
      const data = JSON.parse(this.cacheService.getFromSessionStorage('contentmain'));
      this.authoringContent = data.par.common_payment;
      this.setLabelsValues();
    } else {
      this._paymentSvc.isDecryptCallLoaded.subscribe((data) => {
        if (data==="true") {
          this.showLinkExpired = false;
          this.initalize();
        }
        if(data==="false"){
           this.showLinkExpired = true;
             this._loaderSvc.stop();
           }
      });
    }

    this._paymentSvc.cardDataTomakepayment.subscribe(ccDataForm => {
      if (!isNullOrUndefined(ccDataForm)) {
        this.totalDueNewCardForm = ccDataForm;
        this.formValid = ccDataForm.valid;
        this.luhnCheck = this.totalDueNewCardForm.value.LuhnCheck;
        this.paymentMethodCode = this.totalDueNewCardForm.value.paymentMethodCode;
        this.cardTypeCode = this.totalDueNewCardForm.value.cardTypeCode;
        this.cardTypeVal = this.totalDueNewCardForm.value.cardTypeVal;
      }
    });

    // Page load tagging for SOH & partner
    const idpType = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE)
    const isPartner = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
    if (environment.ENABLE_TAGGING && (idpType == COMMON_CONSTANTS.IAM_KEY 
    || isPartner == COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE)) {
      this.generatePageViewDetails(idpType, isPartner);
    }

  }

  generatePageViewDetails(idpTypeArg: any, isPartnerArg: any): void {
    let pageDetail = {
      primaryCategory: PDL_CONSTANTS.PRIMARY_CATEGORY,
      subCategory: PDL_CONSTANTS.PLP_SUB_CATEGORY,
      pageType: PDL_CONSTANTS.PLP_PAGE_TYPE,
      pageName: PDL_CONSTANTS.PLP_PAGE_NAME_SOH,
      previousPageURL: this._cacheSvc.getFromSessionStorage(PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL),
      idpType: idpTypeArg,
      isPartner: isPartnerArg
    };
    this.pdlService.generatePageViewTag(pageDetail);
  }

  ngOnDestroy(): void {
    // This is intentional
  }

  private initalize(): void {
    this.contentService.GetContentData()
    .subscribe(data => {
      this.authoringContent = data.par.common_payment;
      this.cacheService.putInSessionStoarage('contentmain', JSON.stringify(data));
      this.setLabelsValues();
    },
      error => {
        const mock = true;
        this.contentService.GetContentData()
        .subscribe(data => {
          this.authoringContent = data.par.common_payment;
          this.cacheService.putInSessionStoarage('contentmain', JSON.stringify(data));
          this.setLabelsValues();
          console.log(error);
        });
      }
    );


  }

  onCcBillPaySuccess(ccData: PaymentValidation) {
    this.ccSavedData = ccData;
  }
  setLabelsValues() {
    this.thankuDataObject = {};
    this.cardDetailObject = {};
    this.tcContent = {};
    this.paymentAmount = this.cacheService.getFromSessionStorage(COMMON_CONSTANTS.AMOUNT_KEY);
    this.banNumber = this.cacheService.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY);
    this.titleLabel = `${this.authoringContent.paymentAmount.titlelblTxt1.replace(MakePaymentConstant.ban, this.cacheService.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY))}`;
    this.getAutopayTC();
  }

  getAutopayTC(): any {
    this.autopayTCSub = this.termsAndConditionsSvc.getAutoPayAgreement().pipe(this.pipedOperatorsService.subReattempt()).subscribe(autoPayData => {
      this.autopayTC = autoPayData;
      this.cacheService.putInSessionStoarage(COMMON_CONSTANTS.APSERVICE_TERMS_DATA, JSON.stringify(this.autopayTC));
      const currDateTime = new Date();
      const agreementTime = currDateTime.toISOString();
      const acceptTimestamp1 = agreementTime.slice(0, -1) + MakePaymentConstant.zeroConstant;
      const autoPayAgreementConsent = {
        'documentId': this.autopayTC.acceptDocumentId,
        'documentType': this.autopayTC.documentType,
        'acceptedTimeStamp': acceptTimestamp1
      };
      this.termsAndConditionsSvc.setSelectedTerms(autoPayAgreementConsent);
      ContentService.SetCache(MakePaymentConstant.autoPayDocDetailsKey, autoPayAgreementConsent);
      this.tcAutoPayAgreementContent = atob(this.autopayTC.attachments.embeddedData.binaryContent);
      this.tcAutoPayAgreementContent = this.sanitizer.bypassSecurityTrustHtml(this.tcAutoPayAgreementContent);
      ContentService.SetCache(MakePaymentConstant.autoPayAgreement, this.tcAutoPayAgreementContent);
    }, err => {
      this.errorAlertAutoPayTC = {
        message: {
          errorKey: err.status,
          errorMessage: 'aa',
          errorType: COMMON_CONSTANTS.AUTOPAY_PROPERTIES.ERR_SERVER
        }, show: true
      };
    });
  }

  onSubmit() {
    if (!this.recaptchaToken) {
      return;
    }

    if (this.totalDueNewCardForm.value.expirationMonth !== '' && this.totalDueNewCardForm.value.expirationYear !== '') {
      this.cardDetailObject.expirationDate = this.totalDueNewCardForm.value.expirationMonth + '/'
        + this.totalDueNewCardForm.value.expirationYear.toString().substr(2, 2);
    }
    this.publicKeySubscription = this.billPaySvc.fetchPublicKey().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
      (data: any) => {
        this._loaderSvc.stop(CREDIT_CARD_CONSTANTS.MICRO_TEXT);
        this.isCCEncryptComplete = false;
        this.key1.kty = data.payment.encryption.jwkKey.kty;
        this.key1.e = data.payment.encryption.jwkKey.e;
        this.key1.n = data.payment.encryption.jwkKey.n;
        const creditCardNumber = this.totalDueNewCardForm.value.actualcreditCardNumber;
        const cvv = HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_CVV_NUMBER);
        const card = this;
        encrypt(data?.payment?.encryption?.jwkKey, cvv, (encryptedData) => {
            encryptedData = JSON.stringify(encryptedData, undefined, 2);
            card.encryptedCvv = encryptedData.substring(1, encryptedData.length - 1);
            if (!isNullOrUndefined(this.encryptedCvv)) {
              encrypt(data.payment.encryption.jwkKey, creditCardNumber, (encryptedData) => {
                encryptedData = JSON.stringify(encryptedData, undefined, 2);
                card.encrypedNumber = encryptedData.substring(1, encryptedData.length - 1);
                if (!isNullOrUndefined(this.encrypedNumber) && !this.isCCEncryptComplete) {
                  this.ccSavedData.payment.paymentMethodReference.paymentMethodCode = this.paymentMethodCode;
                  this.ccSavedData.payment.paymentMethodReference.storePaymentMethodIndicator = false;
                  this.ccSavedData.payment.termsAgreementIndicator = true;
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cardNumber = this.encrypedNumber;
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderName = this.totalDueNewCardForm.value.nameOnCreditCard;
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderFirstName =
                    this.getFormattedName(this.totalDueNewCardForm.value.nameOnCreditCard, CREDIT_CARD_CONSTANTS.FIRST);
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderLastName =
                    this.getFormattedName(this.totalDueNewCardForm.value.nameOnCreditCard, CREDIT_CARD_CONSTANTS.LAST);
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cvv = this.encryptedCvv;
                  this.ccSavedData.payment.paymentInstrument.paymentCard.typeCode = this.cardTypeCode;
                  this.ccSavedData.payment.paymentInstrument.paymentCard.expirationMonthYear =
                    // tslint:disable-next-line:max-line-length
                    this.totalDueNewCardForm.value.expirationMonth + this.totalDueNewCardForm.value.expirationYear.toString().substr(2, 2);
                  this.ccSavedData.payment.paymentInstrument.paymentCard.paymentCardAlias = '';
                  // tslint:disable-next-line:max-line-length
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.purposes = MakePaymentConstant.purposes;
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 =
                    this.totalDueNewCardForm.value.ccAddress1;
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2 =
                    this.totalDueNewCardForm.value.ccAddress2;
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city =
                    this.totalDueNewCardForm.value.ccCity;
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state =
                    this.totalDueNewCardForm.value.ccState;
                  // tslint:disable-next-line:max-line-length
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode = this.totalDueNewCardForm.value.ccZip;
                  // tslint:disable-next-line:max-line-length
                  this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.countryCode = MakePaymentConstant.countryCode;
                  // tslint:disable-next-line:max-line-length
                  this.ccSavedData.payment.customerType = CreditCardConstants.CUSTOMER_TYPE;
                  this.ccSavedData.payment.productGroup = MakePaymentConstant.productGroup;
                  this.ccSavedData.payment.businessUnit = MakePaymentConstant.businessUnit;
                  this.ccSavedData.payment.programCode = MakePaymentConstant.programCode;
                  this.ccSavedData.payment.billerCode = MakePaymentConstant.billerCode;
                  this.ccSavedData.payment.businessSegment = MakePaymentConstant.autoBusinessSegment;
                  this.ccSavedData.payment.orderTypes = ACH_CONSTANTS.MNGORDER_TYPE;
                  this.ccSavedData.payment.storedPaymentIndicator = false;
                  this.ccSavedData.customerReference.customerId = this.banNumber;
                  // tslint:disable-next-line:max-line-length
                  this.ccBillPayAliasData.paymentType = CREDIT_CARD_CONSTANTS.PAYMENT_TYPE;
                  this.ccBillPayAliasData.cardholderName = this.totalDueNewCardForm.value.nameOnCreditCard;
                  // tslint:disable-next-line:max-line-length
                  this.ccBillPayAliasData.cardNumberAlias = '';
                  this.ccBillPayAliasData.expireMonth = this.totalDueNewCardForm.value.expirationMonth;
                  this.ccBillPayAliasData.expireYear = this.totalDueNewCardForm.value.expirationYear;
                  this.ccBillPayAliasData.cardBrand = this.cardTypeCode;
                  this.ccBillPayAliasData.billingAccountNumber = this.banNumber;
                  this.ccBillPayAliasData.orgId = this.orgID;
                  this.ccBillPayAliasData.transactionType = ACH_CONSTANTS.TRANSACTION_TYPE;
                  this.ccBillPayAliasData.billingAddress.address1 = this.totalDueNewCardForm.value.ccAddress1;
                  this.ccBillPayAliasData.billingAddress.city = this.totalDueNewCardForm.value.ccCity;
                  this.ccBillPayAliasData.billingAddress.state = this.totalDueNewCardForm.value.ccState;
                  this.ccBillPayAliasData.billingAddress.zipCode = this.totalDueNewCardForm.value.ccZip;
                  this.billPayAliasData = this.ccBillPayAliasData;
      
                  this.submitSimOnHandPayment(this.encrypedNumber);
      
                }
              }, (errorData) => {
                this.errorMessage = ContentService.GetCache('content');
                this.saveSuccess = true;
                this.errorAlert = {
                  message: {
                    errorKey: errorData.status ? errorData.status : '',
                    errorMessage: this.errorMessage.error.genericErrorMpTxt,
                    errorType: 'Server'
                  },
                  show: true
                };
              });
            }
          }
        );
      }, err => {
        this.errorMessage = ContentService.GetCache('content');
        this.saveSuccess = true;
        this.errorAlert = {
          message: {
            errorKey: err ? err.status : '',
            errorMessage: this.errorMessage.error.genericErrorMpTxt,
            errorType: 'Server'
          },
          show: true
        };
      });

  }

  submitSimOnHandPayment(encrypedNumber: any) {

    this.hgCCValidation.payment.paymentMethodReference.paymentMethodCode = this.paymentMethodCode;

    this.hgCCValidation.payment.businessUnit = MakePaymentConstant.businessUnit;
    this.hgCCValidation.payment.programCode = MakePaymentConstant.programCode;
    this.hgCCValidation.payment.billerCode = MakePaymentConstant.billerCode;
    this.hgCCValidation.payment.businessSegment = MakePaymentConstant.autoBusinessSegment;
    this.hgCCValidation.payment.orderTypes = this.cacheService.getFromSessionStorage(MakePaymentConstant.ORDERTYPE);
    this.hgCCValidation.payment.productGroup = MakePaymentConstant.productGroup;
    this.thankuDataObject.paymentAmount = this.paymentAmount;
    this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderName =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderName;
    this.hgCCValidation.payment.paymentInstrument.paymentCard.cvv = this.ccSavedData.payment.paymentInstrument.paymentCard.cvv;
    this.hgCCValidation.payment.paymentInstrument.paymentCard.typeCode = this.cardTypeCode;
    this.hgCCValidation.payment.paymentInstrument.paymentCard.expirationMonthYear =
      this.ccSavedData.payment.paymentInstrument.paymentCard.expirationMonthYear;
    this.hgCCValidation.payment.paymentInstrument.paymentCard.cardNumber = encrypedNumber;
    this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1;
    this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city;
    this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state;
    // tslint:disable-next-line:max-line-length
    this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.countryCode = MakePaymentConstant.countryCode;
    this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode;
    this.hgCCValidation.payment.billerCode = MakePaymentConstant.billerCode;
    this.hgCCValidation.customerReference.customerId = this.banNumber;
    this.sPValidation = this.hgCCValidation;

    (<any>this.sPValidation).recaptchaResponse = this.recaptchaToken;

    this.dataConsentCheck = ContentService.GetCache(COMMON_CONSTANTS.TERMS_CONSENT);
    if (isNullOrUndefined(this.dataConsentCheck)) {
      if (this.autopayTC ) {
        this.termsAndConditionsSvc.recordUserConsent()
          .subscribe(data => {
            if (data.postedDocumentDetails[0].status !== COMMON_CONSTANTS.STATUS_FAILURE ) {
              this.dataConsent = data;
              ContentService.SetCache(COMMON_CONSTANTS.TERMS_CONSENT, this.dataConsent);
              this.simOnHandPayment(this.encrypedNumber);
            } else {
              this.simOnHandPayment(this.encrypedNumber);
            }
          },
            (error) => {
              this.simOnHandPayment(this.encrypedNumber);
            }
          );
      } else {
        this.simOnHandPayment(this.encrypedNumber);
      }
    } else {
      this.simOnHandPayment(this.encrypedNumber);
    }
  }

  simOnHandPayment(encrypedNumber) {
    // submit payment logic
    //console.log("inside payment");
    this.paymentDate = new Date();
        this.billPaySubscription = this.billPaySvc.billPayAliasInfo(this.billPayAliasData, this.orgID)
          .subscribe((billPayRes: any) => { // POST call for account-orders
            this.transactionId = billPayRes.transactionId;
            this._cacheSvc.putInSessionStoarage(
              COMMON_CONSTANTS.TRANS_REF_NUM,
              billPayRes.transRefNum
            );
            this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.TRANSACTION_ID, this.transactionId);
            const card = HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER).replace(/ /g, '');
            this.maskedCcNumber = this.maskedChars +
              this.utils.getLastFourDigits(HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER));
            ContentService.SetCache(COMMON_CONSTANTS.CC_NUMBER, card);
            this.cardDetailObject.cardNumber = card;
            ContentService.SetCache(COMMON_CONSTANTS.CARD_DETAIL_OBJECT, this.cardDetailObject);

            if (this.datePipe.transform(this.paymentDate, MakePaymentConstant.dateFormat) ===
              this.datePipe.transform(this.todayDate, MakePaymentConstant.dateFormat) ) {
              this.submitPayment(encrypedNumber);
            }
          }, err => {
            console.log("inside error");
          });
  }


  submitPayment(encrypedNumber: any) {

        this.mPCCValidation.payment.paymentMethodReference.paymentMethodCode = this.paymentMethodCode;
        this.mPCCValidation.payment.paymentMethodReference.storePaymentMethodIndicator = false;
        this.mPCCValidation.customerReference.customerId = this.ccSavedData.customerReference.customerId;
        this.mPCCValidation.payment.orderNumber = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.SOH_CART_ID);
        this.mPCCValidation.payment.accountTransactionId = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.SOH_ACC_TRANS_ID);
        this.mPCCValidation.payment.businessUnit = MakePaymentConstant.businessUnit;
        const currDateTime = new Date();
        const agreementTime = currDateTime.toISOString();
        this.mPCCValidation.payment.termsAgreementTime = agreementTime;
        this.mPCCValidation.payment.programCode = MakePaymentConstant.programCode;
        this.mPCCValidation.payment.operationType = MakePaymentConstant.operationTypeSOH;
        this.mPCCValidation.payment.termsAgreementIndicator = true;
        this.mPCCValidation.payment.orderTypes = MakePaymentConstant.sohOrderType;
        this.mPCCValidation.payment.creationTime = agreementTime;
        this.mPCCValidation.payment.businessSegment = MakePaymentConstant.autoBusinessSegment;
        this.mPCCValidation.payment.customerPresentIndicator = false;
        this.mPCCValidation.payment.paymentMethodReference.paymentMethodCode = MakePaymentConstant.paymentMethodCardCode;
        // check for the payment Amount has comma
        this.mPCCValidation.payment.chargeAmount = Number(this.paymentAmount.toString().replace(',', ''));
        this.mPCCValidation.payment.paymentMethodReference.paymentMethodCode = HttpCacheService.GetCache(CREDIT_CARD_CONSTANTS.PAYMETHOD_CODE);
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cvv = this.ccSavedData.payment.paymentInstrument.paymentCard.cvv;
        // tslint:disable-next-line:max-line-length
        this.mPCCValidation.payment.paymentInstrument.paymentCard.typeCode = this.cardTypeCode;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.expirationMonthYear =
          this.ccSavedData.payment.paymentInstrument.paymentCard.expirationMonthYear;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.paymentCardAlias = '';
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardNumber = encrypedNumber;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderName;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderFirstName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderFirstName;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderLastName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderLastName;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2 =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.purposes = MakePaymentConstant.purposes;
        // tslint:disable-next-line:max-line-length
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.countryCode = MakePaymentConstant.countryCode;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode;
        this.mPCCValidation.payment.productGroup = MakePaymentConstant.productGroup;
        this.mPCCValidation.payment.billerCode = MakePaymentConstant.billerCode;
        this.mPValidation = this.mPCCValidation;
      (<any>this.mPValidation).recaptchaResponse = this.recaptchaToken;

      this.dataConsentCheck = ContentService.GetCache(COMMON_CONSTANTS.TERMS_CONSENT);
      if (isNullOrUndefined(this.dataConsentCheck)) {
        if (this.autopayTC ) {
          this.termsAndConditionsSvc.recordUserConsent()
            .subscribe(data => {
              if (data.postedDocumentDetails[0].status === COMMON_CONSTANTS.STATUS_FAILURE ||
                data.postedDocumentDetails[1].status === COMMON_CONSTANTS.STATUS_FAILURE) {
                this.processSimOnHandsPayment();
              } else {
                this.dataConsent = data;
                ContentService.SetCache(COMMON_CONSTANTS.TERMS_CONSENT, this.dataConsent);
                this.processSimOnHandsPayment();
              }
            },
              (error) => {
                this.processSimOnHandsPayment();
              }
            );
        } else {
          this.processSimOnHandsPayment();
        }
      } else {
        this.processSimOnHandsPayment();
      }  }


  processSimOnHandsPayment() {

    this._paymentSvc.SimOnHandsPayment(this.recaptchaToken, this.mPValidation).subscribe((response) => { // POST call of processpayments
      if (this.retry > 0 && !isNullOrUndefined(response['status']) && !isNullOrUndefined(response['status'].statusCode)
        && response['status'].statusCode === MakePaymentConstant.APPROVED_STATUS && (response['reason'].reasonCode ===
          '8033') && this._errMsgService.getErrorMessage().show) {
        this._errMsgService.unSettingErrorMsg();
      }

      if (this._errMsgService.getErrorMessage().show) {
        this.retry++;
        this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
        this.updateCrOrderStatusDesc = response['payment'] ?
          response['payment'].messages[0].uiAction : this.errorMessage.error.genericErrorMpTxt;
        this.updateCROrderId(response);
        this.updateCROrder();
        this.saveSuccess = false;
        const negativeBanReasonCodeList = _.split(this.authoringContent.error.processNegativeBanReasonCodeTxt, ',');
        if (!isNullOrUndefined(this.errorAlert && this.errorAlert.message && this.errorAlert.message.errorKey)
          && negativeBanReasonCodeList.includes(this.errorAlert.message.errorKey)) {
          this.enableRetry = false;
          this._paymentSvc.makePaymenttoAch(true);
          this._paymentSvc.makePaymenttoCC(true);
          this.router.navigate([MakePaymentConstant.processError]);
        } else {
          if (this.retry > CREDIT_CARD_CONSTANTS.TWO) {
            this.enableRetry = false;
            this.router.navigate([MakePaymentConstant.processError]);
          } else {
            if (negativeBanReasonCodeList.includes(response['reason'].reasonCode)) {
              this.enableRetry = false;
              this.router.navigate([MakePaymentConstant.processError]);
            } else {
              const errorListCode = "8183,8184,8330";
              if (errorListCode.includes(response['reason'].reasonCode)) {
                this.enableRetry = true;
                this._paymentSvc.processPaymentErrorCounter.next(this.retry);
                this.PaymentErrorModel.openModal();
              } else {
                this.enableRetry = false;
                this.router.navigate([MakePaymentConstant.processError]);
              }
            }

          }
        }
        return false;
      } else if (!isNullOrUndefined(response['status']) && !isNullOrUndefined(response['status'].statusCode)
        && response['status'].statusCode === MakePaymentConstant.APPROVED_STATUS && (response['reason'].reasonCode ===
          '8033')) {
        this.thankuDataObject.paymentResponse = response;
        this.thankuDataObject.paymentInfo = COMMON_CONSTANTS.CARD_TXT;
        this.thankuDataObject.fromWhichPayment = MakePaymentConstant.paymentTxt;
        ContentService.SetCache(MakePaymentConstant.makepaymentResponse, this.thankuDataObject);
        this.updateCROrderId();
        this.updateCROrder();
        this.router.navigate([MakePaymentConstant.thankuUrl]);
      }
    },
      err => {
        this.errorMessage = ContentService.GetCache(COMMON_CONSTANTS.CONTENT_CACHE_KEY);
        this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
        if (!isNullOrUndefined(err.error.errors)) {
          this.updateCrOrderStatusDesc = err.error.errors[0].systemMessage
            ? err.error.errors[0].systemMessage : err.error.errors[0].userMessage;
        } else {
          this.updateCrOrderStatusDesc = err.error ? err.error : this.errorMessage.error.genericErrorMpTxt;
        }
        this.updateCROrderId();
        this.updateCROrder();
        this.saveSuccess = false;
        this.router.navigate([MakePaymentConstant.processError]);

      }
    );
  }



  updateCROrderId(response?: any) { 
    if (this.datePipe.transform(this.paymentDate, MakePaymentConstant.dateFormat) ===
      this.datePipe.transform(this.todayDate, MakePaymentConstant.dateFormat)) {
      // UPdate CR Order Object starts
      if (!isNullOrUndefined(this.thankuDataObject && this.thankuDataObject.paymentResponse
        && this.thankuDataObject.paymentResponse.payment.paymentInstrument)) {
        // tslint:disable-next-line:max-line-length
        this.mPUpdateCrOrder.cardNumberAlias = !isNullOrUndefined(this.thankuDataObject.paymentResponse.payment.paymentInstrument) ?
          this.thankuDataObject.paymentResponse.payment.paymentInstrument.paymentCard.paymentCardAlias : '';
      } else if (!isNullOrUndefined(response && response.payment &&
        response.payment.paymentInstrument)) {
        this.mPUpdateCrOrder.cardNumberAlias = !isNullOrUndefined(response.payment.paymentInstrument) ?
          response.payment.paymentInstrument.paymentCard.cardNumberAlias : '';
      }

      this.mPUpdateCrOrder.billingAccount = this.banNumber;
      const euiTokenData=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA);
      this.mPUpdateCrOrder.logonId = euiTokenData ? JSON.parse(euiTokenData).logonId : '';
      this.mPUpdateCrOrder.emailNotification.firstName=euiTokenData ? JSON.parse(euiTokenData)?.firstName : '';
      this.mPUpdateCrOrder.emailNotification.emailId=this.mPUpdateCrOrder.logonId;
      this.mPUpdateCrOrder.status = this.thankuDataObject.paymentResponse ? 'S' : this.updateCrOrderStatus;
      this.mPUpdateCrOrder.transactionDesc =
      // tslint:disable-next-line:max-line-length
      this.thankuDataObject.paymentResponse ? this.thankuDataObject.paymentResponse.paymentTransactionResponse.statusMessage : this.updateCrOrderStatusDesc;
      if (!isNullOrUndefined(this.dataConsent) && !isNullOrUndefined(this.dataConsent.postedDocumentDetails)) {
        for (let eachPostedDocumentDetails of this.dataConsent.postedDocumentDetails) {
          const element = {};
          element['docId'] = !isNullOrUndefined(eachPostedDocumentDetails.ingestDocumentId) ?
            eachPostedDocumentDetails.ingestDocumentId : '';
          element['docType'] = !isNullOrUndefined(eachPostedDocumentDetails.ingestDocumentId) ?
            eachPostedDocumentDetails.documentType : '';
          element['acceptedDateTime'] = this.termsAndConditionsSvc.findAndConvertAcceptedTimeStampByDocumentId(eachPostedDocumentDetails.documentId);
          this.consentArray.push(element);
        }
        this.mPUpdateCrOrder.legalDocuments = this.consentArray;

      } else if (!this.dataConsent) {
        this.mPUpdateCrOrder.legalDocuments.push(this.legalDocument);
      }

      this.updateCrOrderObj = this.mPUpdateCrOrder;
      if(!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.SOH_CART_ID)))
      {
        this.updateCrOrderObj['orderNumber'] =this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.SOH_CART_ID);

      }
      // UPdate CR Order Object Ends
    }
  }

  updateCROrder() {
      this._paymentSvc.updateCROrder(this.updateCrOrderObj).subscribe(crData => {
        // PUT call for account-orders
      },
        (error) => {
          // This is intentional
        }
      );
      }
  getFormattedName(name: string, type: string) {
    const nameArr = name.toString().split(' ');
    if (type === CREDIT_CARD_CONSTANTS.FIRST) {
      if (nameArr.length > CREDIT_CARD_CONSTANTS.TWO) {
        return (nameArr[0] + ' ' + nameArr[1]);
      } else {
        return nameArr[0];
      }
    } else {
      if (nameArr.length > CREDIT_CARD_CONSTANTS.ONE) {
        return nameArr[nameArr.length - 1];
      } else {
        return '';
      }
    }
  }

  setToken(recaptchaToken: string): void {
    this.recaptchaToken = recaptchaToken;
  }
  disableRecaptcha(): boolean {
    let isDisabled: boolean;
    isDisabled = !this.cardTypeVal || !this.luhnCheck || !this.enableRetry || !this.formValid;
    return isDisabled;
  }
  onEntrust() {
    this.entrustUrl =  this.authoringContent.landingInfo.enTrustLogoUrl.replace('[host]', location.host);
    window.open(this.entrustUrl, '', 'width=537,height=457,left=19%,top=50,sceenX=19%,screenY=50');
  }
}