import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[appOptionalFloatingLabel]',
    standalone: true
})

export class OptionalFloatingLabelDirective implements AfterViewInit {
    element;
    classValid: string;
    classInvalid: string;

    constructor(private elRef: ElementRef) {
        this.classValid = 'hr-valid';
        this.classInvalid = 'hr-invalid';
        this.element = this.elRef.nativeElement;
    }

    // Check input field value is blank or not
    public checkBlankField(value, selector) {
        if (value.length === 0) {
            selector.parentNode.classList.remove(this.classValid);
            selector.parentNode.classList.add(this.classInvalid );
        } else {
            selector.parentNode.classList.remove(this.classInvalid );
            selector.parentNode.classList.add(this.classValid);
        }
    }

    ngAfterViewInit() {
        const inputField = this.element.children[0];

        // Check input value on load
        let inputVal = inputField.value;
        this.checkBlankField(inputVal, inputField);

        // Check input value on keyup
        inputField.addEventListener('keyup', event =>  {
            inputVal = inputField.value;
            this.checkBlankField(inputVal, event.target);
        });
    }
}
