import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MakePaymentConstant, COMMON_CONSTANTS } from 'src/app/common/core/common.constants';
import { MakePaymentService } from 'src/app/services/make-payment.service';
import { MakePaymentComponent } from 'src/app/makePayment/make-payment/make-payment.component';
import { ContentService } from 'src/app/services/content.service';
import { EnvironmentDetailsService } from 'src/app/services/environment-details.service';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { HttpCacheService } from 'src/app/services/http-cache.service';
import { LoaderComponent } from '../../loader/loader.component';
import { NgStyle, NgClass, NgIf } from '@angular/common';
@Component({
    selector: 'app-payment-error-popup',
    templateUrl: './payment-error-popup.component.html',
    styleUrls: ['./payment-error-popup.component.scss'],
    standalone: true,
    imports: [NgStyle, NgClass, NgIf, LoaderComponent]
})
export class PaymentErrorPopupComponent implements OnInit {

  isLoader: any;
  @Input() isHardGoodsEnableMsg: Boolean;
  @Input()
  showSubHeadertxt;

  @Output()
  showSubHeadertxtChange = new EventEmitter<boolean>();
  body: any = document.getElementsByTagName('body')[0];
  display: any = 'none';
  authoringContent: any;
  errorCount = 2;
  constructor(private _paymentSvc: MakePaymentService, private contentService: ContentService,
     private _envSvc: EnvironmentDetailsService,private _cacheSvc: HttpCacheService,) { }

  ngOnInit() {
      if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('contentmain'))) {
        const data = JSON.parse(this._cacheSvc.getFromSessionStorage('contentmain'));
        this.authoringContent = data.par.common_payment;  
        this.isLoader = false;     
      } else {
      this.contentService.GetContentData().subscribe(data => {
        this.authoringContent = data.par.common_payment;
        this.isLoader = false;
      });
    }
  }

  openModal() {
    this.display = COMMON_CONSTANTS.BLOCK;
    const shop: any = document.getElementsByClassName(MakePaymentConstant.MODAL_CNTNT)[0];
    shop.classList.remove(MakePaymentConstant.ZOOM_OUT);
    shop.classList.add(MakePaymentConstant.ZOOM_IN);
}
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async closeModal() {
    const zoomIn: any = document.getElementsByClassName(MakePaymentConstant.MODAL_CNTNT)[0];
    zoomIn.classList.remove(MakePaymentConstant.ZOOM_OUT);
    zoomIn.classList.add(MakePaymentConstant.ZOOM_IN);
    await this.sleep(500);
    this.body.style.overflow = COMMON_CONSTANTS.AUTO;
    this.display = COMMON_CONSTANTS.NONE;
    this.showSubHeadertxtChange.emit(true);
    this._paymentSvc.makePaymenttoAch(true);
    this._paymentSvc.makePaymenttoCC(true);
    this._paymentSvc.processPaymentErrorCounter.next(this.errorCount);
  }
}
