import { Injectable } from '@angular/core';
import { COMMON_CONSTANTS } from '../common/core/common.constants';

@Injectable({
  providedIn: 'root'
})
export class DecodeParamsService {

  constructor() { 
    // This is intentional
  }
  decodeBase64(input: string) {
    if (input !== '') {
      const decodedStr = atob(decodeURIComponent(input));
      // tslint:disable-next-line:prefer-const
      let decodedparams = {};
      const tempObj =  decodedStr.split('&');
      for (let eachTempObj of tempObj) {
        const val = eachTempObj.split('=');
        switch (val[0]) {
          case COMMON_CONSTANTS.BAN_NUMBER_KEY:
                      decodedparams[COMMON_CONSTANTS.BAN_NUMBER_KEY] = val[1];
                      break;
          case COMMON_CONSTANTS.ORG_ID_KEY:
                      decodedparams[COMMON_CONSTANTS.ORG_ID_KEY] = val[1];
                      break;
          case 'returnURI':
                      let returnURI = eachTempObj.indexOf('?') > -1 ? 
                                        eachTempObj.slice(eachTempObj.indexOf('=') + 1) : 
                                        val[1];
                      decodedparams['returnURI'] = decodeURIComponent(returnURI);
                      break;
          case COMMON_CONSTANTS.BILL_PAY_FLAG:
                      decodedparams[COMMON_CONSTANTS.BILL_PAY_FLAG] = val[1];
                      break;
          case COMMON_CONSTANTS.FLOW_KEY:
                      decodedparams[COMMON_CONSTANTS.FLOW_KEY] = val[1];
                      break;
          case COMMON_CONSTANTS.AUTH_TYPE:
                      decodedparams[COMMON_CONSTANTS.AUTH_TYPE] = val[1];
                      break;
          case COMMON_CONSTANTS.TOKEN_KEY:
                      decodedparams[COMMON_CONSTANTS.TOKEN_KEY] = val[1];
                      break;
          case COMMON_CONSTANTS.DEFAUTH_TYPE:
                      decodedparams[COMMON_CONSTANTS.AUTH_TYPE] = val[1];
                      break;
        }
      }
      return decodedparams;
    } else {
        return input;
    }
  }
}
