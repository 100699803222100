import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { BsModalRef, ModalModule } from "ngx-bootstrap/modal";
import { ModalDirective } from "ngx-bootstrap/modal";
import { HttpCacheService } from "../../services/http-cache.service";
import * as _ from "lodash";
import { Router } from "@angular/router";
import {
  COMMON_CONSTANTS,
  MakePaymentConstant,
} from "../core/common.constants";
import { PnpClientService } from "../../../pnpframework/lib/services/pnp-client.service";

@Component({
    selector: "app-idle",
    templateUrl: "./idle.component.html",
    styleUrls: ["./idle.component.scss"],
    standalone: true,
    imports: [ModalModule],
})
export class IdleComponent implements OnInit {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  public modalRef: BsModalRef;
  @ViewChild("childModal", { static: true }) childModal: ModalDirective;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private _cacheSvc: HttpCacheService,
    private router: Router,
    private pnpClientService: PnpClientService
  ) {}

  ngOnInit() {
    if (
      environment.idle.enabled === false ||
      this.pnpClientService.isRunningInGlobalNav()
    ) {
      return;
    }

    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(environment.idle.idleTimeSeconds);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(environment.idle.timeOutSeconds);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.idle.clearInterrupts();
      this.childModal.show();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState =
        "Due to inactivity, you will be automatically logged out from your current session in: \n " +
        countdown +
        " seconds!";
      console.log(this.idleState);
    });

    this.idle.onIdleEnd.subscribe(() => {
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.logout();
    });

    // sets the ping interval to 15 seconds
    // this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    this.reset();
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  logout() {
    if (
      this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.IS_PARTNER_BOARD
      ) === "true"
    ) {
      this.router.navigate([MakePaymentConstant.idleError]);
      this.childModal.hide();
      return;
    } else {
      const queryParams = {};
      this.generateLogoutURL(queryParams);
      const queryParamsRaw = Object.keys(queryParams)
        .map((k) => `${k}=${queryParams[k]}`)
        .join("&");

      let redirectUrl = environment.domain.iam.url + environment.IAM_LOGOUT;
      if (this._cacheSvc.getFromSessionStorage("IDPType") === "okta") {
        redirectUrl = environment.domain.iam.url + environment.OKTA_LOGOUT;
      }

      window.location.href =
        redirectUrl + ((queryParamsRaw.length && "?" + queryParamsRaw) || "");
    }

    this.childModal.hide();
  }

  generateLogoutURL(queryParams) {
    console.info("[INFO] Before logout");

    if (this._cacheSvc.getFromSessionStorage("IDPType") === "iam") {
      queryParams["redirect_uri"] =
        `${environment.domain.iam.url}/oauth2/v1/auth` +
        encodeURIComponent(
          `?redirect_uri=${environment.domain.iamHome}` +
            "&scope=TMO_ID_profile role extended_lines openid" +
            "&client_id=TFBBilling" +
            "&access_type=ONLINE" +
            "&response_type=code"
        );
    } else {
      queryParams["id_token_hint"] =
        this._cacheSvc.getFromSessionStorage("IDPType");
      queryParams["post_logout_redirect_uri"] = environment.domain.oktaHome;
      queryParams["state"] = "okta";
    }

    localStorage.clear();
    sessionStorage.clear();

    // delete all cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }
}
