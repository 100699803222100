// WCS URLS
export const WCS_ENPOINT_CONST: any = {

    GET_SERVICE_TEXT_URL: {
        path: '/b2b-experience/v1/documents/service-agreement',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_SERVICE_TEXT_URL',
        REDFLAG: false
    },
    GET_SERVICE_TEXT_URL_PARTNER: {
        path: '/b2b-experience/partner/v2/documents/service-agreement',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_SERVICE_TEXT_URL_PARTNER',
        REDFLAG: false
    },
    GET_GENERAL_TERMS_URL: {
        path: '/b2b-experience/payment/v2/documents/terms-conditions',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_GENERAL_TERMS_URL',
        REDFLAG: false
    },
    GET_SERVICE_CONSENT_URL: {
        path: '/b2b-experience/v1/documents',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_SERVICE_CONSENT_URL',
        REDFLAG: false
    },
    GET_SERVICE_CONSENT_URL_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/documents',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_SERVICE_CONSENT_URL_PARTNER',
        REDFLAG: false
    },

    EUI_GET_CODE_REDIRECT_URL: {
        path: 'oauth2/v1/auth?client_id=TfBPayment&scope=TMO_ID_profile openid role extended_lines'
            + '&redirect_uri={{host}}&access_type=online&response_type=code',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'EUI_GET_CODE_REDIRECT_URL',
        REDFLAG: false
    },
    DECRYPT_PARTNER_TOKEN: {
        path: '/b2b-experience/payment/auth/v4/handshake/payment',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: { pid: '?pid=' },
        apiName: 'DECRYPT_PARTNER_TOKEN',
        REDFLAG: true
    },
    DECRYPT_PARTNER_SOH_TOKEN: {
        path: '/b2b-experience/payment/auth/v4/handshake/anonymous',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'DECRYPT_PARTNER_SOH_TOKEN',
        REDFLAG: true
    },
    PARTNER_REDIRECT_TO_BILLING: {
        path: '/b2b-experience/bpm/auth/v4/handshake/billing',
        canCache: false,
        isMocked: false,
        apiName: 'PARTNER_REDIRECT_TO_BILLING',
        REDFLAG: true
    },
    GET_AUTO_PAY_SERVICE_URL: {
        path: '/b2b-experience/v1/documents/easypay-agreement',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_AUTO_PAY_SERVICE_URL',
        REDFLAG: false
    },
    GET_AUTO_PAY_SERVICE_URL_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/documents/easy-pay-agreement',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_AUTO_PAY_SERVICE_URL_PARTNER',
        REDFLAG: false
    },
    ENROLL_AUTO_PAY_SERVICE_URL_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/enroll-autopay',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'ENROLL_AUTO_PAY_SERVICE_URL_PARTNER',
        finalCall: true,
        REDFLAG: true
    },

    MANAGE_AUTO_PAY_SERVICE_URL_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/manage-autopay',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'MANAGE_AUTO_PAY_SERVICE_URL_PARTNER',
        finalCall: true,
        REDFLAG: true
    },
    DELETE_AUTO_PAY_SERVICE_URL_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/unenroll-autopay',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'DELETE_AUTO_PAY_SERVICE_URL_PARTNER',
        finalCall: true,
        REDFLAG: true
    },
    GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL: {
        path: '/b2b-experience/v1/billing-accounts/{billing-account}/enroll-eligibility',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL',
        REDFLAG: false
    },
    GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/autopay-enroll-eligibility',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL_PARTNER',
        REDFLAG: false
    },
    AUTO_PAY_PAYMENT_PROFILE_SERVICE_URL: {
        path: '/b2b/payment/v4/billing-accounts/autopay-info',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'AUTO_PAY_PAYMENT_PROFILE_SERVICE_URL',
        REDFLAG: false
    },
    APIGEE_CARDTYPE_VALIDATION: {
        path: '/b2b/payment/v4/billing-accounts/card-type-validation',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'APIGEE_CARDTYPE_VALIDATION',
        REDFLAG: false
    },
    OKTA_ISSUER: {
        path: '/oauth2/{{okta_issuercode}}/v1/authorize?client_id={{okta_clientid}}&scope=openid'
            + '&redirect_uri={{okta_redirecturi}}&response_type=code&state=state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb602',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'OKTA_ISSUER',
        REDFLAG: false
    },
    OKTA_TOKEN: {
        path: '/oauth2/{{okta_issuercode}}/v1/token',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'OKTA_TOKEN',
        REDFLAG: false
    },
    OKTA_USERINFO: {
        path: '/oauth2/{{okta_issuercode}}/v1/userinfo',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'OKTA_USERINFO',
        REDFLAG: false
    },
    GET_AUTO_PAY_DISCOUNT_ELIGIBILITY_SERVICE_URL_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/autopay-discount-eligibility',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_AUTO_PAY_DISCOUNT_ELIGIBILITY_SERVICE_URL_PARTNER',
        REDFLAG: false
    },
};


// APIGEE_URLS
export const APIGEE_TOKEN_URL: any = {
    APIGEE_REFRESH_TOKEN: {
        path: '/oauth2/v1/tokens',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'TAG_REFRESH_TOKEN',
        REDFLAG: true
    },
    SECURE_TOKEN_REFRESH: {
        path: '/b2b-experience/payment/auth/v4/handshake/payment/refresh',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: '?noCache=',
        apiName: 'SECURE_TOKEN_REFRESH',
        REDFLAG: true
    },
    APIGEE_PUBLIC_KEY: {
        path: '/b2b/payment/v4/billing-accounts/public-key',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'APIGEE_PUBLIC_KEY',
        REDFLAG: false
    },
    PUBLIC_KEY: {
        path: '/b2b/payment/v4/billing-accounts/public-key',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'PUBLIC_KEY',
        REDFLAG: false
    },
    APIGEE_CARDTYPE_VALIDATION: {
        path: '/b2b/payment/v4/billing-accounts/card-type-validation',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'APIGEE_CARDTYPE_VALIDATION',
        REDFLAG: false
    },
    APIGEE_SAVE_AUTO_PAY: {
        path: '/b2b-experience/v1/orgs/{orgId}/account-orders',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'APIGEE_SAVE_AUTO_PAY',
        REDFLAG: false
    },
    APIGEE_SAVE_AUTO_PAY_PARTNER: {
        path: '/b2b-experience/v2/billing-accounts/order',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'APIGEE_SAVE_AUTO_PAY_PARTNER',
        REDFLAG: false
    },
    APIGEE_GET_BANK_NAME: {
        path: '/b2b/payment/v4/billing-accounts/aba-routing-info',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: '?programCode=',
        apiName: 'APIGEE_GET_BANK_NAME',
        REDFLAG: false
    },
    MAKE_PAYMENT: {
        path: '/payments/ecommerce/v3/processpayments',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'MAKE_PAYMENT',
        finalCall: true,
        REDFLAG: true
    },
    MAKE_PAYMENT_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/bill-payment',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'MAKE_PAYMENT_PARTNER',
        finalCall: true,
        REDFLAG: false
    },
    BILLPAY_ALIAS: {
       path: '/b2b-experience/v1/orgs/',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: '/account-orders',
        apiName: 'BILLPAY_ALIAS',
        REDFLAG: false
    },
    BILLPAY_ALIAS_PARTNER: {
        path: '/b2b/payment/v4/transactions',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'BILLPAY_ALIAS_PARTNER',
        REDFLAG: false
    },
    CALCULATE_UPDATED_BALANCE: {
        path: '/billing-experience/v1/bills/calculate-updated-balance', // updated with actual API url
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'CALCULATE_UPDATED_BALANCE',
        REDFLAG: false
    },
    APIGEE_BALANCE: {
        path: '/b2b-experience/v1/billing-accounts/balance',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'APIGEE_BALANCE',
        REDFLAG: false
    },
    APIGEE_BALANCE_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/balance',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'APIGEE_BALANCE_PARTNER',
        REDFLAG: false
    },

    APIGEE_ENTITLEMENT: {
        path: '/tfb/v4/entitlements/core/billing-accounts/mobile-numbers-details',
        canCache: false,
        isMocked: false, 
        b2bClient: 'tfb-payment',
        apiName: 'APIGEE_ENTITLEMENT',
        REDFLAG: false
    },

    APIGEE_ENTITLEMENT_TEA: {
        path: '/tfb/v4/b2b-billing-accounts/orgs/{{orgId}}/billing-accounts/{{ban}}',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: { pageNumber: '?page-number=', pageSize: '&page-size=' },
        apiName: 'APIGEE_ENTITLEMENT_TEA',
        REDFLAG: false
    },

    GET_SCH_ELIGIBILITY: {
        path: '/b2b-experience/v1/billing-accounts/{{baseban}}/schedule-payment-eligibility',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: '?org=',
        apiName: 'GET_SCH_ELIGIBILITY',
        REDFLAG: false
    },
    GET_SCH_ELIGIBILITY_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/schedule-payment-eligibility',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'GET_SCH_ELIGIBILITY_PARTNER',
        REDFLAG: false
    },
    GET_SCH_DATES: {
        path: '/b2b-experience/v1/billing-accounts/{{baseban}}/scheduled-payment-info',
        canCache: false,
        isMocked: false,
        b2bClient: 'test',
        queryparam: '?org=',
        apiName: 'GET_SCH_DATES',
        REDFLAG: false
    },
    GET_SCH_DATES_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/scheduled-payment-info',
        canCache: false,
        isMocked: false,
        b2bClient: 'test',
        queryparam: '?org=',
        apiName: 'GET_SCH_DATES',
        REDFLAG: false
    },
    CREATE_PA_PAYMENT_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/payment-arrangement',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'CREATE_PA_PAYMENT_PARTNER',
        finalCall: true,
        REDFLAG: false
    },
    EDIT_PA_PAYMENT_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/payment-arrangement',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'EDIT_PA_PAYMENT_PARTNER',
        finalCall: true,
        REDFLAG: false
    },
    SCHEDULE_PAYMENT_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/schedule-payment',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'EDIT_PA_PAYMENT_PARTNER',
        finalCall: true,
        REDFLAG: false
    },
    HARD_GOODS_AUTH_PAYMENT_PARTNER: {
        path: '/b2b/payment/v4/billing-accounts/hard-good-authorization',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'HARD_GOODS_AUTH_PAYMENT_PARTNER',
        finalCall: true,
        REDFLAG: false
    },
    CANCEL_SCHEDULE_PAYMENT: {
        path: '/b2b-experience/v1/billing-accounts/{billing-account}/scheduled-payment',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'CANCEL_SCHEDULE_PAYMENT',
        REDFLAG: true
    },
    UPDATE_CRORDERID: {
        path: '/b2b-experience/v1/orgs/{orgId}/account-orders',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'UPDATE_CRORDERID',
        REDFLAG: false
    },
    UPDATE_CRORDERID_PARTNER: {
        path: '/b2b/payment/v4/transactions/{transaction-id}',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'UPDATE_CRORDERID_PARTNER'
    },
    LOGGING_API: {
        path: '/tmobile/cq/loggingapi',
        componetName: '',
        methodName: '',
        requestObj: '',
        responseObj: '',
        serviceName: '',
        loggingFileApp: 'paymentAppTrace',
        endPoint: '',
        otherMSG: '',
        apiName: 'LOGGING_API',
        REDFLAG: false
    },
    SPLUNK_LOG: {
        path: '/splunk-logs/services/collector/event',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'SPLUNK_LOG',
        REDFLAG: false
    },
    OKTA_ISSUER: {
        path: '/oauth2/{{okta_issuercode}}/v1/authorize?client_id={{okta_clientid}}&scope=openid'
            + '&redirect_uri={{okta_redirecturi}}&response_type=code&state=state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb602',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'OKTA_ISSUER',
        REDFLAG: false
    },
    OKTA_TOKEN: {
        path: '/oauth2/{{okta_issuercode}}/v1/token',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'OKTA_TOKEN',
        REDFLAG: false
    },
    OKTA_USERINFO: {
        path: '/oauth2/{{okta_issuercode}}/v1/userinfo',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'OKTA_USERINFO',
        REDFLAG: false
    },
    INSTALLMENT_ELIGIBILITY: {
        path : '/billing/v1/payment-profile/payment-arrangements/eligibility',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        REDFLAG: false
    },
    PAYMENT_ARRANGEMENTS_QUERY: {
        path: '/billing/v1/payment-profile/payment-arrangements/search',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        REDFLAG: false
    },
       INSTALLMENT_ELIGIBILITY_V2: {
            path : '/b2b/payment/v4/billing-accounts/payment-arrangement/eligibility',
            canCache: false,
            isMocked: false,
            b2bClient: 'tfb-payment'
        },
        PAYMENT_ARRANGEMENTS_QUERY_V2: {
            path: '/b2b/payment/v4/billing-accounts/payment-arrangement/search',
            canCache: false,
            isMocked: false,
            b2bClient: 'tfb-payment'
        },
    PAYMENT_ARRANGEMENTS_FETCH_ACTIVITY_ID_IAM: {
        path: '/tfb/v4/entitlements/management/users/{{uuid}}/roles',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: { org: '?org='},
        REDFLAG: false
    },
    PAYMENT_ARRANGEMENTS_FETCH_ROLES_ID_OKTA: {
        path: '/tfb/v4/entitlements/management/ad-groups/roles-groups',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        REDFLAG: false
    },
    PAYMENT_ARRANGEMENTS_FETCH_USER_ROLE_ACTIVITIES: {
        path: '/tfb/v4/entitlements/management/user-activities',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        REDFLAG: false
    },
    PAYMENT_ARRANGEMENTS_FETCH_ACTIVITIESID_BYROLES_OKTA: {
        path: '/tfb/v4/entitlements/management/users/{{userId}}/roles/{{roleId}}/activities',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        REDFLAG: false
    },
    SIMONHANDS_PAYMENT: {
        path:"/b2b/payment/v4/billing-accounts/line-activation-deposit",
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'SIMONHANDS_PAYMENT',
        finalCall: true,
        REDFLAG: true
     },
     ACCOUNT_DETAILS: {
        path: '/b2b/payment/v4/billing-accounts/account-details',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'ACCOUNT_DETAILS',
        REDFLAG: false
    },
    EIP_STANDALONE_PAYMENT: {
        path: '/b2b/payment/v4/billing-accounts/eip-payment',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'EIP_STANDALONE_PAYMENT',
        finalCall: true,
        REDFLAG: false
    },
    FINANCE_AGREEMENTS: {
        path: '/b2b/billing/v4/billing-accounts/finance-agreements',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'FINANCE_AGREEMENTS',
        finalCall: true,
        REDFLAG: false
    },
};

export const CONTENT_PAGES_URL: any = {
    CONTENT_ASOP_CHECKOUT_PAGE: {
        path: '/b2b-experience/v1/content?service=/bin/authorvalue/content/tmocontentApp/common-payment.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'CONTENT_PAYMENT_PAGE',
        REDFLAG: true
    },
    MOCKCONTENT_ASOP_CHECKOUT_PAGE: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/commonpayment.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        REDFLAG: false
    }
};
