import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EnvironmentDetailsService } from '../../services/environment-details.service';
import { ContentService } from '../../services/content.service';
import { TermAndConditionService } from '../../services/term-and-condition.service';
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';

import { DomSanitizer } from '@angular/platform-browser';
import { COMMON_CONSTANTS } from '../../common/core/common.constants';
import {UtilsService} from '../../services/utils.service';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { HttpCacheService } from '../../services/http-cache.service';
import { NgIf, NgStyle } from '@angular/common';


@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle]
})
export class TermsAndConditionsComponent implements OnInit {

  display = COMMON_CONSTANTS.NONE;
  contentData: any;
  termsAndConditionTxtData: any;
  subscription: Subscription;
  public tcContent: any;
  @Input() paymentAmount;
  termAndConditionTitle: string;

  constructor(private _envSvc: EnvironmentDetailsService,
    public utilsService: UtilsService, private contentService: ContentService,
    private termsAndConditionsSvc: TermAndConditionService, private sanitizer: DomSanitizer,
    private _cacheSvc: HttpCacheService) { }

  ngOnInit() {

    this.tcContent = {};
    this.contentData = ContentService.GetCache(COMMON_CONSTANTS.CONTENT_CACHE_KEY);

    if (this.contentData) {
      this.termsAndConditionTxtData = this.contentData.termsAndCondition;
    } else {
      if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('contentmain'))) {
        const data = JSON.parse(this._cacheSvc.getFromSessionStorage('contentmain'));
        this.termsAndConditionTxtData = data.par.common_payment.termsAndCondition;
        ContentService.SetCache(COMMON_CONSTANTS.CONTENT_CACHE_KEY, data.par.common_payment);
      } else {
        if (this._envSvc.CURR_CONTENT_URLS) {
          this.getContentData();
        } else {
          this._envSvc.getContentEnvt().then(x => {
            this._envSvc.CURR_CONTENT_URLS = x.CONTENT_PAGES_URL;
            if (this._envSvc.CURR_CONTENT_URLS) {
              this.getContentData();
            }
          });
        }
      }
    }
  }

  getContentData()
  {
    this.contentService
    .GetContentData()
    .subscribe(data => {
      this.termsAndConditionTxtData = data.par.common_payment.termsAndCondition;
      ContentService.SetCache(COMMON_CONSTANTS.CONTENT_CACHE_KEY, data.par.common_payment);
    });

  }

  openApTermsModal() {

    this.getModelData(this.termsAndConditionTxtData.paymentTermsHead
      , 'AutoPayAgreement');
  }

  getModelData( title:string, service:string)
  {
    this.display = COMMON_CONSTANTS.BLOCK;
    this.termAndConditionTitle = title;
    this.tcContent.serviceTermsTxt = ContentService.GetCache(service);
  }


  onCloseHandled() {
    this.display = COMMON_CONSTANTS.NONE;
  }

  print(id) {
    this.utilsService.print(id);
  }
}
