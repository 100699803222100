import { Injectable } from '@angular/core';
import { COMMON_CONSTANTS } from 'src/app/common/core/common.constants';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EnvironmentDetailsService } from './environment-details.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class OktaAuthorizationService {

    constructor(
        private http: HttpClient,
        private _envSvc: EnvironmentDetailsService,
        private router: ActivatedRoute) {
    }

    loginWithOkta() {
        return this._envSvc.getSessionEndpointsEnv().then(q => {
            this._envSvc.CURR_SESSION_ENDPOINTS = q.WCS_ENPOINT_CONST;
            this._envSvc.APIGEE_TOKEN_URLS = q.APIGEE_TOKEN_URL;

            return this._envSvc.getContentEnvt().then(x => {
                let oktaAuthUrlPath =
                    this._envSvc.APIGEE_TOKEN_URLS.OKTA_ISSUER.path.replace('{{okta_redirecturi}}', environment.oktaRedirectUrl);
                oktaAuthUrlPath =
                    oktaAuthUrlPath.replace('{{okta_issuercode}}', environment.OKTA_ISSUER_CODE);
                oktaAuthUrlPath = oktaAuthUrlPath.replace('{{okta_clientid}}', environment.oktaClientId);

                const oktaAuthUrl = environment.oktaHost + oktaAuthUrlPath;
                window.location.href = oktaAuthUrl;
                return true;
            });
        });
    }

    getOktaReturnCode() {
        return this.router.queryParams;
    }

    public getOktaaAccessToken(): any {
        if (typeof (Storage) !== COMMON_CONSTANTS.UNDEFINED_KEY) {
            const grant_type = COMMON_CONSTANTS.AUTHORIZATION_CODE;
            const redirect = environment.oktaRedirectUrl;
            const body = `grant_type=${grant_type}&code=${sessionStorage.getItem('oktaAuthCode')}&redirect_uri=${redirect}`;

            const httpOptions = {
                headers: new HttpHeaders(
                    {
                        'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.OKTAAPPLICATION_JSON_KEY,
                        'cache-control': COMMON_CONSTANTS.HEADER_PROPERTIES.NO_CACHE,
                        // 'authorization': COMMON_CONSTANTS.HEADER_PROPERTIES.BASIC_KEY + environment.OKTA_TMOB_KEY,
                        'accept': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY
                    }
                )
            };

            // tslint:disable-next-line:max-line-length
            let oktaTokenUrl = this._envSvc.APIGEE_TOKEN_URLS.OKTA_TOKEN.path.replace('{{okta_issuercode}}', environment.OKTA_ISSUER_CODE);
            if (environment.mocked_all || environment.mocked_endpoints) {
                oktaTokenUrl = this._envSvc.APIGEE_TOKEN_URLS.OKTA_TOKEN.path;
                return this.http.get(oktaTokenUrl).toPromise();
            } else {
                return this.http.post(oktaTokenUrl, body, httpOptions).toPromise();
            }
        }
        return Promise.resolve();
    }


    getOktaUserProfile(): any {
        if (typeof (Storage) !== COMMON_CONSTANTS.UNDEFINED_KEY) {

            const httpOptions = {
                headers: new HttpHeaders(
                    {
                        'cache-control': COMMON_CONSTANTS.HEADER_PROPERTIES.NO_CACHE,
                        // tslint:disable-next-line:max-line-length
                        'Authorization': COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + sessionStorage.getItem(COMMON_CONSTANTS.OKTA_ACCESS_TOKEN_KEY)
                    }
                )
            };

            // tslint:disable-next-line:max-line-length
            let oktaUserUrl = this._envSvc.APIGEE_TOKEN_URLS.OKTA_USERINFO.path.replace('{{okta_issuercode}}', environment.OKTA_ISSUER_CODE);
            if (environment.mocked_all || environment.mocked_endpoints) {
                oktaUserUrl = this._envSvc.APIGEE_TOKEN_URLS.OKTA_USERINFO.path;
                return this.http.get(oktaUserUrl).toPromise();
            } else {
                return this.http.post(oktaUserUrl, null, httpOptions).toPromise();
            }
        }
        return Promise.resolve();
    }
}
