<input
  type="text"
  name="walkMeIn_userID"
  ng-model="walkMeIn_userID"
  style="display: none"
/>

<div *ngIf="authoringContent || showPartnerError">
  <app-header></app-header>
  <!-- start of div for banner -->
  <section>
    <div class="container">
      <div *ngIf="show" class="error-message">
        <div class="text-error-msg tab-margin mrg-vr-10">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </section>

  <div *ngIf="authoringContent">
    <!-- add condition to show only in payment arrangement case :  && showInstallOption  -->
    <div
      id="bannerDiv"
      *ngIf="
        showBanner &&
        showInstallOption &&
        !isEditFlow &&
        !isHardGoodsEnable &&
        !isPartnerFlow
      "
    >
      <div class="rectangle row">
        <div class="col-10 Check-it-out-Youll d-flex">
          <span>
            <img
              class="Color-override"
              alt="info"
              src="/assets/tfb-payment/images/icons/info.png"
            />
          </span>
          <span>{{ authoringContent?.paymentAmount?.bannerTxt }}</span>
        </div>
        <div class="col-2 align-txt">
          <a href="javascript:void(0);" (click)="closeDiv('bannerDiv')">
            <img
              class="closeDiv"
              alt="close"
              src="/assets/tfb-payment/images/icons/close.png"
            />
          </a>
        </div>
      </div>
    </div>
    <!-- div for banner-->
    <div
      class="container"
      [ngClass]="{ 'visible-low mouse-disabled': errorType == 'generic' }"
      *ngIf="!isEditFlow; else editFlow"
    >
      <div class="make-payment-wrapper">
        <div class="row" *ngIf="!isHardGoodsEnable">
          <div class="Make-a-payment col-sm-12 page-title">
            <h1>{{ authoringContent?.landingInfo?.paymentHeadingTxt }}</h1>
            <div class="sub-heading">
              {{ titleLable }}
              <div class="payment-desc" *ngIf="showInstallOption">
                <p class="mt-2 d-flex">
                  <span class="p-0"
                    ><img
                      src="/assets/tfb-payment/images/information.png"
                      alt=" "
                  /></span>
                  <span class="font-tele-hal pt-1">{{
                    authoringContent?.paymentAmount?.alertPAnotification
                  }}</span>
                </p>
              </div>
              <div class="payment-desc">
                <p class="mt-2">{{ updatedBalence.text }}</p>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="media">
              <span class="icons dollor">
                <!-- <em class="fas fa-dollar-sign" aria-hidden="true"></em> -->
                <img
                  src="/assets/tfb-payment/images/icons/fa-usd-icon.svg"
                  class="payment-info"
                />
              </span>
              <div class="Make-a-payment media-body" style="width: 206px">
                <h3 tabindex="1">
                  {{ authoringContent?.paymentAmount?.headingTxt }}
                </h3>
                <p *ngIf="!isAmount">
                  <span class="paymentAmount">{{
                    paymentAmount ? (paymentAmount | currency: "$") : "$0.00"
                  }}</span>
                  <span
                    class="tooltip-block"
                    container="body"
                    placement="right"
                    ngbTooltip="{{
                      authoringContent?.paymentAmount
                        ?.paymentAmountInfoTooltipTxt
                    }}"
                    ><img
                      src="/assets/tfb-payment/images/tooltip-icon.png"
                      alt=""
                  /></span>
                  <a
                    tabindex="2"
                    class="inline_link"
                    (keydown.enter)="changeAmount()"
                    (click)="changeAmount()"
                    >{{ authoringContent?.paymentAmount?.changeTxt }}</a
                  >
                </p>
                <div *ngIf="isAmount">
                  <div>
                    <div
                      class="radio radio-primary radioBottomMar"
                      *ngIf="totalDue"
                    >
                      <input
                        type="radio"
                        name="radio_total"
                        id="radio_total_due"
                        [checked]="isTotalSelected && !hasTotalDueMsg"
                        [disabled]="hasTotalDueMsg"
                        (change)="onPayementAmountChange('totalDue')"
                      />
                      <label for="radio_total_due"
                        >{{ totalDueRadio }}
                        <span
                          class="tooltip-block"
                          placement="right"
                          container="body"
                          ngbTooltip="{{
                            authoringContent?.paymentAmount
                              ?.paymentAmountInfoTooltipTxt
                          }}"
                          ><img
                            src="/assets/tfb-payment/images/tooltip-grey-icon.gif"
                            alt="iconTooltip"
                            class="iconTooltip"
                        /></span>
                        <span *ngIf="totalDueText">
                          {{ totalDueText }}
                        </span>
                      </label>
                      <br/>
                      <span *ngIf = "hasTotalDueMsg" class="amountMsg">
                        {{totalDueMsg}}
                      </span>
                    </div>
                    <!-- Default Radio -->
                    <div
                      class="radio radio-primary radioBottomMar"
                      *ngIf="isDefaultBalance"
                    >
                      <input
                        type="radio"
                        name="radio_default"
                        id="radio_due_amount"
                        [checked]="isDefaultSelected && !hasTotalDueMsg"
                        [disabled]="hasTotalDueMsg"
                        (change)="
                          onPayementAmountChange(
                            'paymentamount',
                            payAmntDefRadio
                          )
                        "
                      />
                      <label for="radio_due_amount"
                        >{{ payAmntDefRadio }}
                        <span
                          class="tooltip-block"
                          placement="right"
                          container="body"
                          ngbTooltip="{{
                            authoringContent?.paymentAmount
                              ?.paymentAmountInfoTooltipTxt
                          }}"
                          ><img
                            src="/assets/tfb-payment/images/tooltip-grey-icon.gif"
                            alt="iconTooltip"
                            class="iconTooltip"
                        /></span>
                        <span *ngIf="dueNowText">
                          <span
                            class="
                              optional_errortext optional_errortext_20
                              mrg-rt-5
                            "
                          ></span>
                          <span class="due-alert"> {{ dueNowText }}</span>
                        </span>
                      </label>
                      <br/>
                      <span *ngIf = "hasTotalDueMsg" class="amountMsg">
                        {{totalDueMsg}}
                      </span>
                    </div>
                    <div
                      class="radio radio-primary radioBottomMar"
                      *ngIf="isSmallBalance"
                    >
                      <input
                        type="radio"
                        name="radio_smallBalance"
                        id="radio_small_balance"
                        disabled
                      />
                      <label for="radio_small_balance"
                        >{{ payAmntDefRadio }}
                      </label>
                    </div>
                    <div *ngIf="payAmntDefRadiomsg && !isSmallBalance">
                      <p *ngIf="isInformation" class="mrg-lt-5 def-msg">
                        <img
                          src="/assets/tfb-payment/images/information.png"
                          class="information font-size-20 mrg-rt-10"
                          alt=""
                        />
                        {{ payAmntDefRadiomsg }}
                      </p>
                      <p *ngIf="!isInformation" class="immidiateMsg">
                        {{ payAmntDefRadiomsg }}
                      </p>
                    </div>
                    <div *ngIf="payAmntSvcRadiomsg">
                      <p class="prev-msg">{{ payAmntSvcRadiomsg }}</p>
                    </div>

                    <!-- Other Amount Option -->
                    <div
                      class="radio radio-primary radioBottomMar"
                      *ngIf="isOtherAmount"
                    >
                      <input
                        tabindex="5"
                        type="radio"
                        name="radio_other"
                        id="radio_amount_other"
                        [checked]="isOtherRadioSelected"
                        [disabled]="isSmallBalance"
                        (change)="onPayementAmountChange('other')"
                      />
                      <label for="radio_amount_other">{{
                        authoringContent?.paymentAmount?.otherTxt
                      }}</label>
                    </div>

                    <!-- Choose an Amount Field -->
                    <div *ngIf="isOtherSelected" class="clearfix">
                      <div class="label_box max-width-160 m-l-33 mb-3">
                        <div class="question">
                          <span class="dollor-icon" *ngIf="isSymbol">$</span>
                          <input
                            type="text"
                            tabindex="6"
                            [disabled]="careAuthType"
                            [value]="
                              isSmallBalance
                                ? ('2.00' | currency: '$')
                                : otherTxtValue
                                ? otherTxtValue
                                : ''
                            "
                            #changeAmount
                            appNumbersOnly
                            required
                            (keyup.enter)="collapseOther(changeAmount)"
                            (click)="toggleSymbol(changeAmount)"
                            (blur)="changeAmountValue(changeAmount)"
                            maxlength="8"
                          />
                          <label>{{
                            authoringContent?.paymentAmount
                              ?.chooseAmountPlaceHolderTxt
                          }}</label>
                          <p
                            class="optional_text optional_errortext"
                            *ngIf="amountError"
                          >
                            {{ amountError }}
                          </p>
                        </div>
                      </div>
                      <div *ngIf="isSmallBalance" class="label_box clearfix">
                        <img
                          src="/assets/tfb-payment/images/information.png"
                          class="information mrg-rt-10"
                          alt=""
                        />
                        <span [innerHTML]="payAmntDefRadiomsg"></span>
                      </div>
                    </div>

                    <div
                      *ngIf="showInstallOption"
                      class="radio radio-primary radioBottomMar"
                    >
                      <input
                        type="radio"
                        name="radio_installment"
                        id="radio_amount_installment"
                        [disabled]="isSmallBalance"
                        (change)="onPayementAmountChange('installment')"
                        [checked]="isInstallmentRadioSelected"
                      />
                      <label for="radio_amount_installment"
                        >{{ authoringContent?.paymentAmount?.installmentText }}
                      </label>
                      <br /><span class="installmentNote font-tele-hal">
                        {{ 
                          installmentNoteText
                        }}</span
                      >
                      <span
                        class="tooltip-install-txt"
                        container="body"
                        placement="top"
                        ngbTooltip="{{
                          installmentTooltipTxt
                        }}"
                        ><img
                          class="tooltip-install"
                          src="/assets/tfb-payment/images/informationBlk.png"
                          alt=" "
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="media">
              <span class="icons calendar-check">
                <img
                  src="/assets/tfb-payment/images/icons/icon-calendar1.png"
                  class="payment-info"
                  alt="Payment Date"
                />
              </span>
              <div class="Make-a-payment media-body">
                <h3 tabindex="11" class="paymentDateHeading">
                  {{ authoringContent?.paymentDate?.headingTxt }}
                </h3>
                <div>
                  <div *ngIf="!isInstallmentSelected">
                    <div *ngIf="!isDate">
                      <p>
                        <span
                          class="lable-color"
                          *ngIf="!isOtherPaymentDateSelected"
                          >{{ authoringContent?.paymentDate?.todayTxt }} ({{
                            todayDate | date: "longDate"
                          }})</span
                        >
                        <span
                          class="lable-color"
                          *ngIf="isOtherPaymentDateSelected"
                        >
                          {{ paymentDate | date: "longDate" }}</span
                        >
                        <a
                          tabindex="12"
                          class="inline_link"
                          (keydown.enter)="changeDate()"
                          (click)="changeDate()"
                          >{{ authoringContent?.paymentDate?.changeTxt }}</a
                        >
                      </p>
                    </div>
                    <div class="mrg-tp-10" *ngIf="isDate">
                      <div class="radio radio-primary radioBottomMarPd">
                        <input
                          type="radio"
                          tabindex="13"
                          name="radio_p"
                          id="radio_p"
                          value="today"
                          checked
                          (change)="onPayementDateChange($event)"
                        />
                        <label for="radio_p">
                          {{ authoringContent?.paymentDate?.todayTxt }} ({{
                            todayDate | date: "longDate"
                          }})</label
                        >
                      </div>
                      <p
                        class="mrg-lt-5 warning-msg"
                        *ngIf="payDateDefRadioMsg && !isEligible"
                      >
                        <img
                          src="/assets/tfb-payment/images/information.png"
                          class="information font-size-20 mrg-rt-10"
                          alt=" "
                        />{{ payDateDefRadioMsg }}
                      </p>
                      <div *ngIf="isEligible">
                        <div class="radio radio-primary">
                          <input
                            type="radio"
                            tabindex="13"
                            name="radio_p"
                            id="radio_d"
                            value="other"
                            (keydown.enter)="onPayementDateChange($event)"
                            (change)="onPayementDateChange($event)"
                          />
                          <label
                            (keydown.enter)="onPayementDateChange($event)"
                            for="radio_d"
                            >{{
                              authoringContent?.paymentAmount?.otherTxt
                            }}</label
                          >
                        </div>
                        <div
                          class="label_box max-width-160 mrg-tp-20 mrg-lt-30"
                          *ngIf="isOtherPaymentDate"
                        >
                          <div class="datepic_box">
                            <div class="question">
                              <mat-form-field appearance="fill">
                                <input
                                  matInput
                                  [min]="startDate"
                                  [max]="endDate"
                                  [matDatepicker]="schPayment"
                                  [value]="bsValue"
                                  (dateChange)="addEvent('change', $event)"
                                  disabled
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="schPayment"
                                ></mat-datepicker-toggle>
                                <mat-datepicker
                                  #schPayment
                                  panelClass="schdatepicker"
                                  [calendarHeaderComponent]="
                                    customPaymentHeader
                                  "
                                  disabled="false"
                                > <mat-datepicker-actions>
                                  <div
                                    class="datepicker-footer"
                                    #datepickerFooter
                                  >
                                    <div class="box"></div>
                                    <span class="footer-elements"
                                      >Selected Date</span
                                    >
                                    <div class="empty_box"></div>
                                    <span class="footer-elements"
                                      >Due Date</span
                                    >
                                  </div>
                                  <button
                                    class="apply_btn btn_align"
                                    matDatepickerApply
                                    (click)="onApply()"
                                  >
                                    Apply
                                  </button>
                                </mat-datepicker-actions></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isInstallmentSelected">
                    <span class="paymentDateNote font-arial">
                      {{ authoringContent?.paymentAmount?.paymentDateNote }}
                      {{ firstInstFieldMsgPastDue }}
                    </span>
                    <div class="row">
                      <div
                        class="
                          col-12 col-sm-4 col-lg-2
                          font-arial-bold
                          pdlableRow1
                          pdLable
                        "
                      >
                        {{
                          authoringContent?.paymentAmount?.firstInstallmentTxt
                        }}
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 pdlableRow1">
                        <div class="label_box">
                          <div class="question">
                            <span
                              class="dollor-icon"
                              [ngClass]="{
                                errorInputTxt: errorFirstInstallment
                              }"
                              >$</span
                            >
                            <input
                              appNumbersOnly
                              type="text"
                              #firstInstallmentAmt
                              maxlength="8"
                              (keyup.enter)="collapseOther(firstInstallmentAmt)"
                              (click)="toggleSymbol(firstInstallmentAmt)"
                              [(ngModel)]="firstInstallmentAmount"
                              (blur)="
                                changeInstallmentValue(firstInstallmentAmt)
                              "
                              required
                              tabindex="0"
                              [ngClass]="{
                                errorInputTxt: errorFirstInstallment
                              }"
                              onCopy="return false"
                              onDrag="return false"
                              onDrop="return false"
                              onPaste="return false"
                            />
                          </div>
                          <div class="font-arial leastAmt text-gray">
                            <p
                              *ngIf="
                                !errorFirstInstallment;
                                else firstInstError
                              "
                            >
                              {{ firstInstFieldMsgPercent }}
                            </p>
                            <ng-template #firstInstError>
                              <p class="optional_text optional_errortext">
                                {{ firstInstFieldMsgPercent }}
                              </p>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          col-12 col-sm-4
                          offset-sm-4
                          col-lg-1
                          offset-lg-0
                          font-arial-bold
                          pdlableRow1
                          pdLable
                        "
                      >
                        on
                      </div>
                      <div
                        class="
                          col-12 col-sm-6
                          offset-sm-4
                          col-lg-3
                          offset-lg-0
                          pdlableRow1
                        "
                      >
                        <div class="label_box">
                          <div class="question">
                            <mat-form-field appearance="fill">
                              <input
                                matInput
                                [min]="firstInstallmentDate"
                                [max]="installmentEndDate"
                                [value]="displayFirstinstallmentDate"
                                [matDatepicker]="firstInstallment"
                                (dateChange)="installmentDateChanges($event)"
                                disabled
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="firstInstallment"
                              ></mat-datepicker-toggle>
                              <mat-datepicker
                                #firstInstallment                            
                                [calendarHeaderComponent]="customPaymentHeader"
                                (opened)="onInstallmentOpen(firstInstallment)"
                                [dateClass]="dateClass"
                                disabled="false"
                              >
                              <mat-datepicker-actions>
                                <button class="apply_btn" mat-raised-button matDatepickerApply>Apply</button>
                              </mat-datepicker-actions></mat-datepicker>
                            </mat-form-field>
                            <div style="display: none">
                              <div
                                class="datepicker-installment-footer"
                                #datepickerInstallmentFooter
                              >
                                <div class="box"></div>
                                <span class="footer-elements"
                                  >First Installment</span
                                >
                                <br style="clear: both;">
                                <div class="empty_box"></div>
                                <span class="footer-elements">Second Installment</span>
                                <br style="clear: both;">
                                <div class="faded_box"></div>
                                <span class="footer-elements">Available Dates</span>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div
                        class="
                          col-12 col-sm-4 col-lg-2
                          font-arial-bold
                          pdlableRow2
                          pdLable
                        "
                      >
                        {{
                          authoringContent?.paymentAmount?.secondInstallmentTxt
                        }}
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 pdlableRow2">
                        <div class="label_box">
                          <div class="question">
                            <span
                              class="dollor-icon"
                              [ngClass]="{
                                errorInputTxt: errorSecondInstallment,
                                errorInputTxt1: showMessageSecondInstallmet
                              }"
                              >$</span
                            >
                            <input
                              appNumbersOnly
                              type="text"
                              [(ngModel)]="secondInstallmentAmount"
                              #secondInstallmentAmt
                              maxlength="8"
                              required
                              (keyup.enter)="
                                collapseOther(secondInstallmentAmt)
                              "
                              (click)="toggleSymbol(secondInstallmentAmt)"
                              (blur)="
                                changeInstallmentValueSecond(
                                  secondInstallmentAmt
                                )
                              "
                              tabindex="0"
                              [ngClass]="{
                                errorInputTxt: errorSecondInstallment,
                                errorInputTxt1: showMessageSecondInstallmet
                              }"
                              onCopy="return false"
                              onDrag="return false"
                              onDrop="return false"
                              onPaste="return false"
                            />
                          </div>
                          <div class="font-arial leastAmt text-gray">
                            <p
                              class="optional_text optional_errortext"
                              *ngIf="errorSecondInstallment"
                            >
                              {{ secondPaymentFieldMsg }}
                            </p>
                            <p
                              class="optional_text optional_errortext"
                              *ngIf="showMessageSecondInstallmet"
                            >
                              {{ messageSecondInstallment }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          col-12 col-sm-4
                          offset-sm-4
                          col-lg-1
                          offset-lg-0
                          font-arial-bold
                          pdlableRow2
                          pdLable
                        "
                      >
                        on
                      </div>

                      <div
                        class="
                          col-12 col-sm-6
                          offset-sm-4
                          col-lg-3
                          offset-lg-0
                          pdlableRow2
                        "
                      >
                        <div class="label_box">
                          <div class="question">
                            <mat-form-field appearance="fill">
                              <input
                                matInput
                                [min]="secondInsMinDate"
                                [max]="secondInsMaxDate"
                                [value]="displaySecondinstallmentDate"
                                [matDatepicker]="secoundInstallment"
                                (dateChange)="
                                  secondInstallmentSelectionDate($event)
                                "
                                disabled
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="secoundInstallment"
                              ></mat-datepicker-toggle>
                              <mat-datepicker
                                #secoundInstallment
                                [calendarHeaderComponent]="customPaymentHeader"
                                (opened)="onInstallmentOpen(secoundInstallment)"
                                [dateClass]="dateClass"
                                disabled="false"
                              >
                              <mat-datepicker-actions>
                                <button class="apply_btn" mat-raised-button matDatepickerApply>Apply</button>
                              </mat-datepicker-actions></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="media payInfoMargin">
              <span class="icons credit-card">
                <img
                  src="/assets/tfb-payment/images/icons/payment_info_black.PNG"
                  class="payment-info"
                  alt=""
                />
              </span>
              <div class="Make-a-payment media-body" style="width: 252px">
                <h3 class="pb-0">
                  {{ authoringContent?.paymentInfo?.headingTxt }}
                </h3>
                <div *ngIf="showSubHeadertxt" class="mp-error-title">
                  <img src="/assets/tfb-payment/images/icon_error.svg" alt="" />
                  <h1>{{ authoringContent?.error?.genericErrorApTxt }}</h1>
                </div>
                <div class="radio-btn-box">
                  <div class="radio radio-primary">
                    <input
                      type="radio"
                      #paymentRadia
                      tabindex="30"
                      name="radio_1"
                      id="radio_1"
                      value="Bank"
                      (keydown.enter)="onChangeDirectDeposit($event)"
                      (change)="onChangeDirectDeposit($event)"
                    />
                    <label for="radio_1">{{AchConstants?.BANK_TEXT}}</label>
                    <div *ngIf="isAchSaved && paymementInfo === 'Bank'">
                      <app-ach
                        [isInstallmentRadioSelected]="
                          isInstallmentRadioSelected
                        "
                        [isFuturePayment]="isOtherPaymentDateSelected"
                        [flowType]="'billPay'"
                        #achComponents
                        (onBankInfoRemove)="deleteBankInfo($event)"
                        (achBillPayAliasSuccess)="onAchBillPaySuccess($event)"
                        (onSuccess)="onSuccessCredit($event, 'ach')"
                        [banNumber]="banNumber"
                        [orgId]="orgID"
                      ></app-ach>
                    </div>
                  </div>
                  <div class="radio radio-primary">
                    <input
                      tabindex="30"
                      type="radio"
                      name="radio_1"
                      id="radio_12"
                      value="Card"
                      (keydown.enter)="onChangeDirectDeposit($event)"
                      (change)="onChangeDirectDeposit($event)"
                    />
                    <label for="radio_12">{{
                      authoringContent?.paymentInfo?.creditCardTxt
                    }}</label>
                  </div>
                  <div *ngIf="paymementInfo === 'Bank'">
                    <div *ngIf="!isAchSaved">
                      <app-ach
                        [isInstallmentRadioSelected]="
                          isInstallmentRadioSelected
                        "
                        [isFuturePayment]="isOtherPaymentDateSelected"
                        [flowType]="'billPay'"
                        #achComponents
                        (onBankInfoRemove)="deleteBankInfo($event)"
                        (achBillPayAliasSuccess)="onAchBillPaySuccess($event)"
                        (onSuccess)="onSuccessCredit($event, 'ach')"
                        [banNumber]="banNumber"
                        [orgId]="orgID"
                      ></app-ach>
                    </div>
                  </div>
                  <div
                    *ngIf="paymementInfo === 'Card'"
                    [attr.disabled]="careAuthType == true ? true : null"
                  >
                    <app-credit-card
                      [isInstallmentRadioSelected]="isInstallmentRadioSelected"
                      [isFuturePayment]="isOtherPaymentDateSelected"
                      (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)"
                      (onSuccess)="onSuccessCredit($event, 'credit')"
                      [flowType]="'billPay'"
                      [banNumber]="banNumber"
                      [orgId]="orgID"
                      (onCardInfoRemove)="deleteCardInfo($event)"
                    ></app-credit-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container hardGoodsComponent" *ngIf="isHardGoodsEnable">
          <div class="make-payment-wrapper">
            <div class="row">
              <div class="media payInfoMargin">
                <span class="icons credit-card">
                  <img
                    src="/assets/tfb-payment/images/icons/payment_info_black.PNG"
                    class="payment-info"
                    alt=""
                  />
                </span>
                <div class="Make-a-payment media-body">
                  <h3 class="pb-0">
                    {{ authoringContent?.paymentInfo?.headingTxt }}
                  </h3>
                  <div *ngIf="showSubHeadertxt" class="mp-error-title">
                    <img
                      src="/assets/tfb-payment/images/icon_error.svg"
                      alt=""
                    />
                    <h1>{{ authoringContent?.error?.HardGoodsErrorTxt }}</h1>
                  </div>
                  <div class="radio-btn-box">
                    <div>
                      <app-credit-card
                        [isInstallmentRadioSelected]="
                          isInstallmentRadioSelected
                        "
                        [isFuturePayment]="isOtherPaymentDateSelected"
                        (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)"
                        (onSuccess)="onSuccessCredit($event, 'credit')"
                        [flowType]="'billPay'"
                        [banNumber]="banNumber"
                        [orgId]="orgID"
                        (onCardInfoRemove)="deleteCardInfo($event)"
                      ></app-credit-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="media mb-md-0">
          <span class="spacerBlk"></span>
          <div
            class="Make-a-payment media-body col-md-7 col-sm-10 col-xs-9 pl-0"
          >
            <app-terms-and-conditions></app-terms-and-conditions>
          </div>
        </div>
        <app-recaptcha-v2
          *ngIf="!disableRecaptcha()"
          (onPass)="setToken($event)"
        >
        </app-recaptcha-v2>

        <div class="">
          <div
            class="button-row row mrg-bm-20"
            *ngIf="!isInstallmentSelected; else installmentPaymentFlow"
          >
            <div
              class="
                col-lg-6
                offset-lg-6
                col-md-12
                btn-wrapper
                text-md-center text-lg-right
                btn-wrapperMargin
              "
            >
              <button
                tabindex="61"
                *ngIf="isBillingPartnerFlow || !isPartnerFlow"
                (click)="onCancel()"
                class="black_btn mrg-rt-10"
              >
                {{ authoringContent?.landingInfo?.cancelOrderButtonText }}
              </button>
              <button
                #submitButton
                *ngIf="!isHardGoodsEnable"
                tabindex="62"
                class="primary_btn"
                data-action="submit"
                [disabled]="!recaptchaToken"
                (click)="onSubmit()"
              >
                {{ authoringContent?.landingInfo?.submitOrderButtonText }}
              </button>

              <button
                #submitButton
                *ngIf="isHardGoodsEnable"
                tabindex="62"
                class="primary_btn"
                [disabled]="!recaptchaToken"
                (click)="onSubmit()"
              >
                {{ authoringContent?.landingInfo?.ContinueOrderButtonText }}
              </button>
            </div>
          </div>

          <ng-template #installmentPaymentFlow>
            <div class="button-row row mrg-bm-20">
              <div
                class="
                  col-lg-6
                  offset-lg-6
                  col-md-12
                  btn-wrapper
                  text-md-center text-lg-right
                  btn-wrapperMargin
                "
              >
                <button
                  tabindex="61"
                  *ngIf="isBillingPartnerFlow || !isPartnerFlow"
                  (click)="onCancel()"
                  class="black_btn mrg-rt-10"
                >
                  {{ authoringContent?.landingInfo?.cancelOrderButtonText }}
                </button>
                <button
                  #submitButton
                  tabindex="62"
                  class="primary_btn"
                  [disabled]="!recaptchaToken"
                  (click)="onSubmit()"
                >
                  {{ authoringContent?.landingInfo?.submitOrderButtonText }}
                </button>
              </div>
            </div>
          </ng-template>
        </div>
        <ng-template [ngIf]="errorAlert.show">
          <div class="text-error-msg tab-margin">
            {{ errorAlert.message.errorMessage }}
          </div>
        </ng-template>
        <div class="media mb-5">
          <span class="spacerBlk"></span>
          <div
            class="Make-a-payment media-body col-md-8 col-sm-10 col-xs-9 pl-0"
          >
            <a
              class="entrust-logo inline_link d-inline-block ml-0 mr-2"
              (click)="onEntrust()"
              tabindex="0"
            >
              <img
                src="./assets/tfb-payment/images/icons/entrust-site-seal-small.png"
                alt="Secured by Entrust SSL Verify"
              />
            </a>
            <div class="entrust-text d-inline-block align-middle">
              {{ authoringContent?.landingInfo?.enTrustTxt }}
              <a
                class="entrust-textlink"
                href="{{ authoringContent?.landingInfo?.enTrustPrivacyUrl }}"
                tabindex="0"
                target="_blank"
                >{{ authoringContent?.landingInfo?.enTrustTxt1 }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #editFlow>
      <div
        class="container"
        [ngClass]="{ 'visible-low mouse-disabled': errorType == 'generic' }"
      >
        <div class="make-payment-wrapper">
          <div class="row">
            <div class="Make-a-payment col-sm-12 page-title">
              <h1>
                {{ authoringContent?.paymentAmount?.titleEditArrangement }}
              </h1>
              <div class="sub-heading">
                {{ this.titleLable }}
                <div
                  class="payment-desc"
                  *ngIf="sameDateCheck; else sameDateCheckNotTrueNote"
                >
                  <p>
                    <span class="info-image"
                      ><img
                        src="/assets/tfb-payment/images/information.png"
                        alt=" "
                    /></span>
                    <span class="font-tele-hal">{{
                      authoringContent?.paymentAmount?.sameDateCheckNote
                    }}</span>
                  </p>
                </div>
                <ng-template #sameDateCheckNotTrueNote>
                  <div class="payment-desc">
                    <p>
                      <span class="info-image"
                        ><img
                          src="/assets/tfb-payment/images/information.png"
                          alt=" "
                      /></span>
                      <span class="font-tele-hal">
                        {{
                          authoringContent?.paymentAmount?.notSameDateCheckNote
                        }}</span
                      >
                    </p>
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="media">
                <span class="icons dollor">
                  <!-- <i class="fas fa-dollar-sign" aria-hidden="true"></i> -->
                  <img
                    src="/assets/tfb-payment/images/icons/fa-usd-icon.svg"
                    class="payment-info"
                  />
                </span>
                <div class="Make-a-payment media-body" style="width: 206px">
                  <h3>{{ authoringContent?.paymentAmount?.headingTxt }}</h3>
                  <div class="font-arial-bold">
                    {{ authoringContent?.paymentAmount?.installmentText }}
                    <p>
                      <span class="legal">
                        {{ 
                          installmentNoteText
                        }}</span
                      >
                      <span
                        class="tooltip-install-txt"
                        container="body"
                        placement="top"
                        ngbTooltip="{{
                          installmentTooltipTxt
                        }}"
                        ><img
                          class="tooltip-install"
                          src="/assets/tfb-payment/images/informationBlk.png"
                          alt=" "
                      /></span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="media" *ngIf="paymentInstallment?.length > 0">
                <span class="icons calendar-check">
                  <img
                    src="/assets/tfb-payment/images/icons/icon-calendar1.png"
                    class="payment-info"
                    alt=" "
                  />
                </span>
                <div class="Make-a-payment media-body">
                  <h3 tabindex="11" class="paymentDateHeading">
                    {{ authoringContent?.paymentDate?.headingTxt }}
                  </h3>
                  <p class="mb-4 text-gray-b2b">
                    {{ authoringContent?.paymentAmount?.paymentDateNote }}
                    {{ firstInstFieldMsgPastDue }}
                  </p>
                  <div
                    class="wrapper mb-3"
                    *ngFor="let item of paymentInstallment; let i = index"
                  >
                    <div class="row d-flex align-items-center">
                      <div class="col-3">
                        <p class="font-weight-bold font-arial" *ngIf="i == 0">
                          {{
                            authoringContent?.paymentAmount?.firstInstallmentTxt
                          }}
                        </p>
                        <p class="font-weight-bold font-arial" *ngIf="i == 1">
                          {{
                            authoringContent?.paymentAmount
                              ?.secondInstallmentTxt
                          }}
                        </p>
                      </div>
                      <div class="col-3">
                        <p class="font-italic text-gray-b2b font-arial">
                          {{ item.installmentAmount | currency: "$" }}
                        </p>
                      </div>
                      <div class="col-2">
                        <p class="font-weight-bold font-arial">on</p>
                      </div>
                      <div class="col-3">
                        <p class="font-italic text-gray-b2b font-arial">
                          {{ item.installmentDate | date: "longDate" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="media payInfoMargin">
                <span class="icons credit-card">
                  <img
                    src="/assets/tfb-payment/images/icons/payment_info_black.PNG"
                    class="payment-info"
                    alt=" "
                  />
                </span>
                <div class="Make-a-payment media-body" style="width: 252px">
                  <h3 class="pb-0">
                    {{ authoringContent?.paymentInfo?.headingTxt }}
                  </h3>
                  <div class="radio-btn-box">
                    <div class="radio radio-primary">
                      <div *ngIf="!sameDateCheck">
                        <input
                          type="radio"
                          name="radio_1"
                          id="radio_1"
                          value="Bank"
                          (change)="onChangeDirectDeposit($event)"
                          [(ngModel)]="paymentMode"
                          [disabled]="sameDateCheck"
                        />
                        <label for="radio_1">{{AchConstants?.BANK_TEXT}}</label>
                      </div>
                    </div>
                    <div class="radio radio-primary" *ngIf="!sameDateCheck">
                      <input
                        type="radio"
                        name="radio_1"
                        id="radio_12"
                        value="Card"
                        (change)="onChangeDirectDeposit($event)"
                        [(ngModel)]="paymentMode"
                        [disabled]="sameDateCheck"
                      />
                      <label for="radio_12">{{
                        authoringContent?.paymentInfo?.creditCardTxt
                      }}</label>
                    </div>
                    <div *ngIf="paymementInfo === 'Bank'">
                      <app-ach
                        [isInstallmentRadioSelected]="
                          isInstallmentRadioSelected
                        "
                        [isCurrentPayment]="sameDateCheck"
                        [isFuturePayment]="isOtherPaymentDateSelected"
                        [flowType]="'billPay'"
                        #achComponents
                        (onBankInfoRemove)="deleteBankInfo($event)"
                        (achBillPayAliasSuccess)="onAchBillPaySuccess($event)"
                        (onSuccess)="onSuccessCredit($event, 'ach')"
                        [banNumber]="banNumber"
                        [orgId]="orgID"
                      >
                      </app-ach>
                    </div>
                    <div
                      *ngIf="paymementInfo === 'Card'"
                      [attr.disabled]="careAuthType == true ? true : null"
                    >
                      <div
                        class="col-sm-12"
                        *ngIf="!creditDetails?.allowChange"
                      >
                        <div [ngSwitch]="creditDetails?.type" class="col-sm-4">
                          <span class="weight-normal" *ngSwitchCase="'VISA'">
                            <img
                              src="/assets/tfb-payment/images/cc-visa.png"
                              class="mr-4"
                              alt=" "
                            />
                            <img
                              src="/assets/tfb-payment/images/maskNumber.PNG"
                              class="maskNum"
                              alt=" "
                            />
                            {{ creditDetails?.lastfourDigit }}</span
                          >
                          <span
                            class="weight-normal"
                            *ngSwitchCase="'MASTERCARD'"
                          >
                            <img
                              src="/assets/tfb-payment/images/cc-master.png"
                              class="mr-4"
                              alt=" "
                            />
                            <img
                              src="/assets/tfb-payment/images/maskNumber.PNG"
                              class="maskNum"
                              alt=" "
                            />
                            {{ creditDetails?.lastfourDigit }}</span
                          >
                          <span
                            class="weight-normal"
                            *ngSwitchCase="'MASTER_CARD'"
                          >
                            <img
                              src="/assets/tfb-payment/images/cc-master.png"
                              class="mr-4"
                              alt=" "
                            />
                            <img
                              src="/assets/tfb-payment/images/maskNumber.PNG"
                              class="maskNum"
                              alt=" "
                            />
                            {{ creditDetails?.lastfourDigit }}</span
                          >
                          <span
                            class="weight-normal"
                            *ngSwitchCase="'DISCOVER'"
                          >
                            <img
                              src="/assets/tfb-payment/images/cc-discover.png"
                              class="mr-4"
                              alt=" "
                            />
                            <img
                              src="/assets/tfb-payment/images/maskNumber.PNG"
                              class="maskNum"
                              alt=" "
                            />
                            {{ creditDetails?.lastfourDigit }}</span
                          >
                          <span
                            class="weight-normal"
                            *ngSwitchCase="'AMERICANEXPRESS'"
                          >
                            <img
                              src="/assets/tfb-payment/images/cc-amex.png"
                              class="mr-4"
                              alt=" "
                            />
                            <img
                              src="/assets/tfb-payment/images/maskNumber.PNG"
                              class="maskNum"
                              alt=" "
                            />
                            {{ creditDetails?.lastfourDigit }}</span
                          >
                          <span
                            class="weight-normal"
                            *ngSwitchCase="'AMERICAN_EXPRESS'"
                          >
                            <img
                              src="/assets/tfb-payment/images/cc-amex.png"
                              class="mr-4"
                              alt=" "
                            />
                            <img
                              src="/assets/tfb-payment/images/maskNumber.PNG"
                              class="maskNum"
                              alt=" "
                            />
                            {{ creditDetails?.lastfourDigit }}</span
                          >
                          <a
                            *ngIf="!sameDateCheck && creditDetails?.type"
                            (click)="creditAllowChange()"
                            class="font-tele-nor change_link"
                            >{{ authoringContent?.paymentAmount?.changeTxt }}</a
                          >
                        </div>
                      </div>

                      <app-credit-card
                        [isInstallmentRadioSelected]="
                          isInstallmentRadioSelected
                        "
                        *ngIf="creditDetails?.allowChange"
                        [isCurrentPayment]="sameDateCheck"
                        [isFuturePayment]="isOtherPaymentDateSelected"
                        (ccBillPayAliasSuccess)="onCcBillPaySuccess($event)"
                        (onSuccess)="onSuccessCredit($event, 'credit')"
                        [flowType]="'billPay'"
                        [banNumber]="banNumber"
                        [orgId]="orgID"
                        (onCardInfoRemove)="deleteCardInfo($event)"
                      ></app-credit-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Payment Amount -->
          <div class="media mb-md-0">
            <span class="spacerBlk"></span>
            <div
              class="Make-a-payment media-body col-md-7 col-sm-10 col-xs-9 pl-0"
            >
              <app-terms-and-conditions></app-terms-and-conditions>
            </div>
          </div>

          <app-recaptcha-v2
            *ngIf="!disableRecaptcha()"
            (onPass)="setToken($event)"
          >
          </app-recaptcha-v2>
            

          <div class="">
            <div class="button-row row mrg-bm-20">
              <div
                class="
                  col-lg-6
                  offset-lg-6
                  col-md-12
                  btn-wrapper
                  text-md-center text-lg-right
                  btn-wrapperMargin
                "
              >
                <button (click)="onCancel()" class="black_btn mrg-rt-10">
                  {{ authoringContent?.landingInfo?.cancelOrderButtonText }}
                </button>
                <button
                  #submitButton
                  class="primary_btn"
                  [disabled]="!recaptchaToken"
                  (click)="onSubmit()"
                >
                  {{ authoringContent?.landingInfo?.submitOrderButtonText }}
                </button>
              </div>
            </div>
          </div>

          <!-- </ng-template> -->

          <ng-template [ngIf]="errorAlert.show">
            <div class="text-error-msg tab-margin">
              {{ errorAlert.message.errorMessage }}
            </div>
          </ng-template>
          <div class="media mb-5">
            <span class="spacerBlk"></span>
            <div
              class="Make-a-payment media-body col-md-8 col-sm-10 col-xs-9 pl-0"
            >
              <a
                class="entrust-logo inline_link d-inline-block ml-0 mr-2"
                (click)="onEntrust()"
                tabindex="0"
              >
                <img
                  src="./assets/tfb-payment/images/icons/entrust-site-seal-small.png"
                  alt="Secured by Entrust SSL Verify"
                />
              </a>
              <div class="entrust-text d-inline-block align-middle">
                {{ authoringContent?.landingInfo?.enTrustTxt }}
                <a
                  class="entrust-textlink"
                  href="{{ authoringContent?.landingInfo?.enTrustPrivacyUrl }}"
                  tabindex="0"
                  target="_blank"
                  >{{ authoringContent?.landingInfo?.enTrustTxt1 }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <app-payment-error-popup
    (showSubHeadertxtChange)="showSubHeadertxt = $event"
    [isHardGoodsEnableMsg]="isHardGoodsEnable"
  ></app-payment-error-popup>
  <app-footer
    *ngIf="!isRunningInGlobalNav && (authoringContent || showPartnerError)"
  ></app-footer>
</div>
