import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  ChangeDetectionStrategy,
  Inject,
  ViewEncapsulation,
  Injector
} from "@angular/core";
import { EnvironmentDetailsService } from "../../services/environment-details.service";
import { ContentService } from "../../services/content.service";
import { MakePaymentService } from "../../services/make-payment.service";
import { FormControl, UntypedFormGroup, FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import {
  ErrorMessageService,
  ServiceMessage,
} from "../../services/error-message.service";
import { HttpCacheService } from "../../services/http-cache.service";
import { DatePipe, CurrencyPipe, DecimalPipe, NgIf, NgClass, NgFor, NgSwitch, NgSwitchCase } from "@angular/common";
import {
  BillingAccountInfo,
  BankMakeAPaymentModal,
  PaymentValidation,
  BankPaymentValidation,
  BankBillPayAliasObj,
  CCBillPayAliasObj,
  CCMakeAPaymentModal,
  CCScheduleModal,
  BankScheduleModal,
  UpdateCrOrderPaymentModel,
  LegalDocument,
  EncryptionKey,
  Specifications,
  BankPaymentArrangementModel,
  CCPaymentArrangementModel,
  HardGoodsPaymntModel,
  MakePaymentSubmissionFlow,
} from "../../common/models/data.model";
import {
  COMMON_CONSTANTS,
  MakePaymentConstant,
  ACH_CONSTANTS,
  CREDIT_CARD_CONSTANTS,
  CreditCardConstants,
  CardBrands,
  PDL_CONSTANTS,
  FLOW_TYPE,
} from "../../common/core/common.constants";
import { BillPayService } from "../../services/bill-pay.service";
import { TermAndConditionService } from "../../services/term-and-condition.service";
import { Subscription, forkJoin, of, Subject } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { SchedulePaymentService } from "../../services/schedule-payment.service";
import { catchError, first, takeUntil } from "rxjs/operators";
import { UtilsService } from "../../services/utils.service";
import { LoaderService } from "../../services/loader.service";
import { SetupRouteService } from "../../services/setup-route.service";
import { environment } from "../../../environments/environment";
import { isNullOrUndefined } from "src/app/utilities/helper-utils";
import * as _ from "lodash";
import { SplunkUtilityService } from "src/app/utilities/splunk-utility.service";
import { NgxLoggerLevel } from "ngx-logger";
import { PaymentErrorPopupComponent } from "../../common/payment-error-popup/payment-error-popup.component";
import { PDLService } from "../../services/pdl.service";
import { ClickEventType } from "src/app/common/core/common.interface";
import { PnpClientService } from "src/pnpframework/public-api";
import { RedirectService } from "../../services/redirect.service";
import { MatCalendar, MatDatepicker, MatDatepickerInputEvent, MatDatepickerInput, MatDatepickerToggle, MatDatepickerActions, MatDatepickerApply } from '@angular/material/datepicker';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from "@angular/material/core";
import {AutoPaymentService} from "../../services/auto-payment.service";
import { PipedOperatorsService } from "src/app/common/pipes/customOperators/pipedOperatorsService.service";
import { FooterComponent } from "../../footer/footer.component";
import { RecaptchaV2Component } from "../../common/recaptcha-v2/recaptcha-v2.component";
import { TermsAndConditionsComponent } from "../terms-and-conditions/terms-and-conditions.component";
import { CreditCardComponent } from "../../credit-card/credit-card.component";
import { AchComponent } from "../../ach/ach.component";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatSuffix } from "@angular/material/form-field";
import { NumbersOnlyDirective } from "../../directive/numbers-only.directive";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { HeaderComponent } from "../../header/header.component";

declare const encrypt;

@Component({
    selector: "app-make-payment",
    templateUrl: "./make-payment.component.html",
    styleUrls: ["./make-payment.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        HeaderComponent,
        NgClass,
        NgbTooltip,
        NumbersOnlyDirective,
        MatFormField,
        MatInput,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepicker,
        MatDatepickerActions,
        MatDatepickerApply,
        FormsModule,
        AchComponent,
        CreditCardComponent,
        TermsAndConditionsComponent,
        RecaptchaV2Component,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        PaymentErrorPopupComponent,
        FooterComponent,
        CurrencyPipe,
        DatePipe,
    ],
})
export class MakePaymentComponent implements OnInit {
  _envSvc: EnvironmentDetailsService;
  _errMsgService: ErrorMessageService;
  _paymentSvc: MakePaymentService;
  _cacheSvc: HttpCacheService;
  _schPaySvc: SchedulePaymentService;
  _autoPaymentSvc: AutoPaymentService;
  _loaderSvc: LoaderService;
  _routeService: SetupRouteService;
  contentService: ContentService;
  router: Router;
  datePipe: DatePipe;
  billPaySvc: BillPayService;
  termsAndConditionsSvc: TermAndConditionService;
  sanitizer: DomSanitizer;
  currencyPipe: CurrencyPipe;
  decimalPipe: DecimalPipe;
  utils: UtilsService;
  private _getBanDetails$: Subscription;
  splunkSvc: SplunkUtilityService;
  pdlService: PDLService;
  redirectService: RedirectService;
  pnpClientService: PnpClientService;
  customPaymentHeader = CustomPaymentHeader;
  @ViewChild('datepickerFooter', {static: false}) datepickerFooter: ElementRef;
  @ViewChild('datepickerInstallmentFooter', {static: false}) datepickerInstallmentFooter: ElementRef;
  @ViewChild('schPayment', {static: false}) datepicker: MatDatepicker<any>;
  @ViewChild("ngxdp") ngxdp;
  @ViewChild("dp") datePicker;
  @ViewChild(PaymentErrorPopupComponent)
  PaymentErrorModel: PaymentErrorPopupComponent;
  isDefaultBalance = false;
  bsValue = new Date();
  selectedDate: any;
  isPreviousBalance = false;
  isOtherAmount = false;
  isSmallBalance = false;
  isLatePayment = false;
  isAddCreditCard = false;
  isAddBank = false;
  paymementInfo = "";
  todayDate = new Date();
  isDate = false;
  isAmount = false;
  isOtherSelected = false;
  isOtherRadioSelected = false;
  isOtherPaymentDateSelected = false;
  isOtherPaymentDate = false;
  otherTxtValue: any;
  totalDue = false;
  amountError: any;
  authoringContent: any;
  dateModel;
  paymentForm: UntypedFormGroup;
  data: any;
  flowType: string;
  titleLable: any;
  paymentDate: Date;
  errorAlert: ServiceMessage = { message: null, show: false };
  billingAccount: any;
  billInfo: {};
  url: any;
  basicToken: any;
  updatedBalence = {
    text: "",
    icon: false,
  };
  billingAccounts: BillingAccountInfo[];
  payAmntDefRadio: any;
  totalDueRadio: any;
  payAmntDefRadiomsg: any;
  payDateDefRadioMsg: any;
  isInformation = true;
  prevBalRadio: any;
  prevBalRadioMsg: any;
  payAmntSvcRadiomsg: any;
  paymentAmount: any;
  pastDueAmount: any;
  isDefaultSelected = true;
  isTotalSelected = false;
  defAmount: any;
  thankuDataObject: any;
  orgID: any;
  banNumber: any;
  saveSuccess = false;
  isAchSaved = false;
  maskedAccount = "";
  mPBankValidation: BankMakeAPaymentModal = new BankMakeAPaymentModal();
  mPCCValidation: CCMakeAPaymentModal = new CCMakeAPaymentModal();
  ccSavedData: PaymentValidation = new PaymentValidation();
  achSavedData: BankPaymentValidation = new BankPaymentValidation();
  achBillPayAliasData: BankBillPayAliasObj = new BankBillPayAliasObj();
  ccBillPayAliasData: CCBillPayAliasObj = new CCBillPayAliasObj();
  sPBankValidation: BankScheduleModal = new BankScheduleModal();
  sPCCValidation: CCScheduleModal = new CCScheduleModal();
  paBankValidation: BankPaymentArrangementModel = new BankPaymentArrangementModel();
  paCCValidation: CCPaymentArrangementModel = new CCPaymentArrangementModel();
  hgCCValidation: HardGoodsPaymntModel = new HardGoodsPaymntModel();
  mPUpdateCrOrder: UpdateCrOrderPaymentModel = new UpdateCrOrderPaymentModel();
  sPUpdateCrOrder: UpdateCrOrderPaymentModel = new UpdateCrOrderPaymentModel();
  legalDocument: LegalDocument = new LegalDocument();
  mPValidation: {};
  sPValidation: {};
  updateCrOrderObj: {};
  errorMessage: any;
  accountType: any;
  paymentDue: boolean;
  dueNowText: any;
  totalDueText: any;
  scheduledPayment: any;
  scheduleEligibilty: any;
  returnURI: string;
  subscription: Subscription;
  isEligible: boolean;
  public tcContent: any;
  bsConfig = {
    showWeekNumbers: false,
  };
  startDate: Date;
  endDate: Date;
  startDateChk: Date;
  endDateChk: Date;
  dueDate: Date;
  autoPayDate: Date;
  banDetails: any;
  isOther: boolean;
  hasScheduledPayment: boolean;
  hasTotalDueMsg = false;
  makePaymentPermission = false;
  totalDueMsg:any;
  hasArBalance = false;
  dueNowPrevText: any;
  isSymbol = false;
  dataConsent: any;
  updateCrOrderStatus: any;
  updateCrOrderStatusDesc: any;
  entrustUrl: any;
  listsAll = [
    { text: "Selected Date", id: 1, className: "selected-date" },
    { text: "Due Date", id: 1, className: "selected-date" },
  ];
  listsAllEnstalment = [
    { text: "First installment", id: 1, className: "displayList-element" },
    { text: "Second installment", id: 1, className: "displayList-element" },
    { text: "Available dates", id: 1, className: "displayList-element" },
  ];
  show: any;
  errorType: string;
  isRedirect: boolean;
  autopayTCSub: any;
  autopayTC: any;
  public tcAutoPayAgreementContent: any;
  errorAlertAutoPayTC: {
    message: { errorKey: any; errorMessage: any; errorType: string };
    show: boolean;
  };
  errorAlertTC: {
    message: { errorKey: any; errorMessage: any; errorType: string };
    show: boolean;
  };
  isAccessTokenStored: boolean;
  careAuthType: any;
  azureAuthType: any;
  consentArray: any = [];
  formValid: boolean;
  isAchEncryptComplete: boolean;
  key1: EncryptionKey = new EncryptionKey();
  achGroup: any;
  encrypedNumber: any;
  encryptedCvv:any;
  retry = CREDIT_CARD_CONSTANTS.ZERO;
  maskedChars: string;
  dataConsentCheck: any;
  transactionId: any;
  totalDueNewCardForm: any;
  cardDetailObject: any;
  publicKeySubscription: Subscription;
  isCCEncryptComplete: boolean;
  ccSpecifications: Specifications = new Specifications();
  cardBrand: any = {};
  cnpSalesSubscription: Subscription;
  billPaySubscription: Subscription;
  maskedCcNumber: string;
  billPayAliasData: any;
  enableRetry = true;
  isInstallmentRadioSelected: boolean;
  isInstallmentSelected = false;
  isEditFlow = false;
  showInstallOption = false;
  paymentInstallment: any;
  sameDateCheck = false;
  firstInstallmentflag = false;
  secondInstalment = false;
  enableSubmitByInstallment = true;
  firstInstallmentDate: any;
  secondInstallmentDate: any;
  installmentEndDate: any;
  firstInstalmentDateSelected: any;
  installmentDates: any = [];
  secondInstallmentDateModal: any;
  firstInstallmentDateModal: any;
  displayFirstinstallmentDate: any;
  displaySecondinstallmentDate: any;
  maxDaysBeforeFirstInstallment: number;
  maxDaysBetweenInstallments: number;
  secondInsMinDate: any;
  secondInsMaxDate: any;
  installDate: any;
  messageSecondInstallment: string;
  // dateCustomClasses: DatepickerDateCustomClasses[];
  paymentMode = "";
  firstInstallmentAmount: any;
  errorFirstInstallment: boolean = false;
  elegiblefirstInstallmentAmount: any;
  secondInstallmentAmount: any;
  errorSecondInstallment: boolean = false;
  isSecondCalendarDblClick: boolean = false;
  sameDateCheckNote: string;
  // start temp data as API is not available
  installmentInfo = [
    {
      date: undefined,
      amount: MakePaymentConstant.TWENTY,
    },
    {
      date: undefined,
      amount: MakePaymentConstant.TEN,
    },
  ];
  allowModeEdit: any;
  // end temp data as API is not available
  creditDetails: any = { allowChange: false, showData: true };
  paymentEligibilityResponse: any;
  paymentArrangementsResponse: any;
  firstInstFieldMsgPercent: any;
  firstInstFieldMsgPastDue: any;
  secondPaymentFieldMsg: any;
  showMessageSecondInstallmet = false;
  isMiAmountCalculated: boolean;
  luhnCheck: boolean = true;
  errorCounter = 0;
  display: any = "none";
  showSubHeadertxt: boolean;
  @ViewChild("paymentRadia") nameField: ElementRef;
  contentMockUrl: any;
  paymentArrangementDisable: any;
  installmentNoteText: string = "Turns off AutoPay and removes per-line discount.";
  installmentTooltipTxt: string = "You may re-enroll in AutoPay after your final payment is processed. The per-line discount will apply to the next billing cycle.";
  paymentMethodCode: any;
  cardTypeCode: any;
  cardTypeVal: boolean = true;
  isHardGoodsEnable: boolean = false;
  isBillingPartnerFlow: boolean = false;
  isIOTBanType: boolean = false;
  isPaymentArrangementReadOnly: boolean;
  choosePayDateFirstInstTxt: any;
  choosePayDateSecondInstTxt: any;
  MPIphoneNumber: any;
  MPIphoneNumberCheck: boolean = true;
  paymentArrangementId: any;
  showBanner: boolean = true;
  isPartnerFlow: boolean = false;
  walkMeIn_userID: any;
  recaptchaToken: string;
  submissionType = MakePaymentSubmissionFlow.DEFAULT;
  showPartnerError = false;
  AchConstants: any;
  get isRunningInGlobalNav(): boolean {
    return this.pnpClientService.isRunningInGlobalNav();
  }

dateRange = new UntypedFormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  constructor( private injector: Injector, private pipedOperatorsService: PipedOperatorsService) {
    this.isAchEncryptComplete = false;
    this.maskedChars = COMMON_CONSTANTS.MASKING_CHARACTERS;
    this.isCCEncryptComplete = false;
    this._envSvc = injector.get<EnvironmentDetailsService>(EnvironmentDetailsService);
    this._errMsgService = injector.get<ErrorMessageService>(ErrorMessageService);
    this._paymentSvc = injector.get<MakePaymentService>(MakePaymentService);
    this._cacheSvc = injector.get<HttpCacheService>(HttpCacheService);
    this._schPaySvc = injector.get<SchedulePaymentService>(SchedulePaymentService);
    this._loaderSvc = injector.get<LoaderService>(LoaderService);
    this._routeService = injector.get<SetupRouteService>(SetupRouteService);
    this.contentService = injector.get<ContentService>(ContentService);
    this.router = injector.get<Router>(Router);
    this.datePipe = injector.get<DatePipe>(DatePipe);
    this._autoPaymentSvc = injector.get<AutoPaymentService>(AutoPaymentService);
    this.billPaySvc = injector.get<BillPayService>(BillPayService);
    this.termsAndConditionsSvc = injector.get<TermAndConditionService>(TermAndConditionService);
    this.sanitizer = injector.get<DomSanitizer>(DomSanitizer);
    this.currencyPipe = injector.get<CurrencyPipe>(CurrencyPipe);
    this.decimalPipe = injector.get<DecimalPipe>(DecimalPipe);
    this.utils = injector.get<UtilsService>(UtilsService);
    this.splunkSvc = injector.get<SplunkUtilityService>(SplunkUtilityService);
    this.pdlService = injector.get<PDLService>(PDLService);
    this.redirectService = injector.get<RedirectService>(RedirectService);
    this.pnpClientService = injector.get<PnpClientService>(PnpClientService);
  }

  ngOnInit() { 
    this.changeAmount();
    if(environment.ENABLE_AUTOPAY_VERBIAGE)
    {
      this.installmentNoteText = "AutoPay will pause while you have an active payment arrangement and will resume once the payment arrangement is complete.";
      this.installmentTooltipTxt = "Your AutoPay will resume once you successfully complete or cancel your payment arrangement. If your payment arrangement completes within 2 days of your due date, you will need to make a one-time payment and AutoPay will resume the following month. If your account is or becomes past due, you will not receive the AutoPay discount. If your payment arrangement fails, you will be un-enrolled from AutoPay.";
    }
    // dormancy flag for payment Arrangement
    this.paymentArrangementDisable = environment.HIDE_PAYMENT_ARRANGEMENT;
    if (
      !this.paymentArrangementDisable &&
      Boolean(
        !isNullOrUndefined(
          this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_EDIT_FLOW)
        )
      )
    ) {
      this.isEditFlow = true;
      this.isInstallmentRadioSelected = true;
    } else {
      this.isEditFlow = false;
    }

    this.isMiAmountCalculated = false;
    this._errMsgService.serviceData.subscribe((genricMessage: any) => {
      if (genricMessage) {
        if (genricMessage.errorType === "partnerError") {
          this.showPartnerError = true;
          this._loaderSvc.stop("mega");
        }
        this.errorMessage = genricMessage.error;
        this.show = genricMessage.show;
        this.errorType = genricMessage.errorType;
      }
    });
    this._paymentSvc.notifyDoubleClick.subscribe((data) => {
      this.onApplySelectngx(data);
    });
    this._routeService.isEuiRedirect.pipe(first()).subscribe((isRedirect) => {
      this.isRedirect = isRedirect;
      this.isAccessTokenStored = Boolean(
        !isNullOrUndefined(
          this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.ACCESS_TOKEN_KEY
          )
        )
      );
      this._cacheSvc.putInSessionStoarage(
        MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
        MakePaymentConstant.FLOW_TYPE.ONETIME_PAYMENT
      );
      // tslint:disable-next-line:max-line-length
      if (
        environment.ENABLE_PARTNER &&
        this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.IS_PARTNER_BOARD
        ) === "true"
      ) {
        this._routeService.partnerHandShake.subscribe((data) => {
          if (data) {
            this.isPartnerFlow = true;
            if (
              environment.ENABLE_HARDGOODS &&
              this._cacheSvc.getFromSessionStorage("paymentTypeIndicator") ===
              COMMON_CONSTANTS.PARTNER_HARDGOODS &&
              this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.IS_PARTNER_BOARD
              ) === "true"
            ) {
              
              this.isHardGoodsEnable = true;
            }
            if ( this.isHardGoodsEnable && !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(MakePaymentConstant.BAN_TYPE)) 
            && this._cacheSvc.getFromSessionStorage(MakePaymentConstant.BAN_TYPE) === "IOT" ) {
              this.isIOTBanType = true;
             }
            if (
              this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW
              ) === "true"
            ) {
              this.isBillingPartnerFlow = true;
            }
            this.initializeObjects();
          }
        });
      } else {
        if (
          !isNullOrUndefined(
            this._cacheSvc.getFromSessionStorage("isHandshakeComplete")
          ) &&
          this._cacheSvc.getFromSessionStorage("isHandshakeComplete") === "true"
        ) {
          this.initializeObjects();
        } else {
          this._routeService.partnerHandShake.subscribe((data) => {
            if (data) {
              this.initializeObjects();
            }
          });
        }
      }
    });
    this._loaderSvc.stop("mega");
    this._paymentSvc.achDataTomakepayment.subscribe((achDataForm) => {
      if (!isNullOrUndefined(achDataForm)) {
        this.achGroup = achDataForm;
        this.formValid = achDataForm.valid;
      }

      if (this.isInstallmentRadioSelected) {
        if (
          !isNullOrUndefined(
            this._cacheSvc.getFromSessionStorage("phoneNumber")
          )
        ) {
          this.MPIphoneNumberCheck = true;
        } else {
          this.MPIphoneNumberCheck = false;
        }
      } else {
        this.MPIphoneNumberCheck = true;
      }
    });

    this._paymentSvc.cardDataTomakepayment.subscribe((ccDataForm) => {
      if (!isNullOrUndefined(ccDataForm)) {
        this.totalDueNewCardForm = ccDataForm;
        this.formValid = ccDataForm.valid;
        this.luhnCheck = this.totalDueNewCardForm.value.LuhnCheck;
        this.paymentMethodCode =
          this.totalDueNewCardForm.value.paymentMethodCode;
        this.cardTypeCode = this.totalDueNewCardForm.value.cardTypeCode;
        this.cardTypeVal = this.totalDueNewCardForm.value.cardTypeVal;
      }
      if (this.isInstallmentRadioSelected) {
        if (
          !isNullOrUndefined(
            this._cacheSvc.getFromSessionStorage("phoneNumber")
          )
        ) {
          this.MPIphoneNumberCheck = true;
        } else {
          this.MPIphoneNumberCheck = false;
        }
      } else {
        this.MPIphoneNumberCheck = true;
      }
    });

    this.maskedAccount =
      this.maskedChars +
      (ContentService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER)
        ? this.utils.getLastFourDigits(
          ContentService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER)
        )
        : "");
    this.cardDetailObject = {};
    // Page load tagging & for IAM and partner flow
    const idpType = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE)
    const isPartner = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
    if (environment.ENABLE_TAGGING && (idpType == COMMON_CONSTANTS.IAM_KEY || isPartner == COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE)) {
      this.generatePageViewDetails(idpType, isPartner);
    }
    this.walkMeIn_userID = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.EUI_TOKEN_DATA
    )
      ? JSON.parse(
        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)
      ).logonId
      : MakePaymentConstant.cardSchemailId;
  }

  generatePageViewDetails(idpTypeArg: any, isPartnerArg: any) {
    let pageDetail = {
      primaryCategory: PDL_CONSTANTS.PRIMARY_CATEGORY,
      subCategory: PDL_CONSTANTS.PLP_SUB_CATEGORY,
      pageType: PDL_CONSTANTS.PLP_PAGE_TYPE,
      pageName: PDL_CONSTANTS.PLP_PAGE_NAME,
      previousPageURL: this._cacheSvc.getFromSessionStorage(PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL),
      idpType: idpTypeArg,
      isPartner: isPartnerArg
    };
    if (this.isEditFlow) {
      pageDetail.pageName = PDL_CONSTANTS.PLP_PAGE_NAME_EDIT;
      pageDetail.pageType = PDL_CONSTANTS.PLP_PAGE_TYPE_EDIT_PA;
    }
    this.pdlService.generatePageViewTag(pageDetail);
  }

  creditAllowChange() {
    // analytics click tagging
    this.generateClickDetailsForMakePayment(
      "click",
      "link",
      ClickEventType.ACTION,
      "Change",
      ""
    );
    this.creditDetails["allowChange"] = true;
    this.creditDetails["showData"] = false;
  }

  checkPaymentEligibilty() {
    return this.paymentArrangementDisable
  }
  executePaymentEligibilty_Search() { 

    let eligibilityCriteria = environment.ENABLE_PA_V2API
      ? COMMON_CONSTANTS.ELIGIBILITY_V2
      : COMMON_CONSTANTS.ELIGIBILITY_V1;
    if (!this.paymentArrangementDisable && !this.utils.isCareAuth()) {
      this._paymentSvc.paymentEligibilityCheck(this.banNumber).pipe(this.pipedOperatorsService.subReattempt()).subscribe(
        (response) => {
          if (!isNullOrUndefined(response)) {
            if (
              !isNullOrUndefined(
                response["paymentArrangementEligibility"][0][
                eligibilityCriteria
                ]
              ) &&
              response["paymentArrangementEligibility"][0][
              "eligibilityStatus"
              ] === "SUCCESS" &&
              !isNullOrUndefined(
                response["paymentArrangementEligibility"][0][
                  eligibilityCriteria
                ].maxInstallmentsAllowed
              ) &&
              Number(
                response["paymentArrangementEligibility"][0][
                  eligibilityCriteria
                ].maxInstallmentsAllowed
              ) >= COMMON_CONSTANTS.TWO
            ) {
              this.showInstallOption = true;
              
              this.paymentEligibilityResponse =
                response["paymentArrangementEligibility"][0][
                eligibilityCriteria
                ];
              this.maxDaysBeforeFirstInstallment =
                this.paymentEligibilityResponse.maxDaysBeforeFirstInstallment;
              this.maxDaysBetweenInstallments =
                this.paymentEligibilityResponse.maxDaysBetweenInstallments;
                this._cacheSvc.putInSessionStoarage(
                  "maxDaysBeforeFirstInstallment",
                  this.paymentEligibilityResponse.maxDaysBeforeFirstInstallment
                );
                this._cacheSvc.putInSessionStoarage(
                  "maxDaysBetweenInstallments",
                  this.paymentEligibilityResponse.maxDaysBetweenInstallments
                );
              if (
                !isNullOrUndefined(
                  this.authoringContent && this.authoringContent.paymentAmount
                )
              ) {
                this.firstInstFieldMsgPercent =
                  this.authoringContent.paymentAmount.firstPaymentFieldMsgPercent.replace(
                    "[minPercent]",
                    this.paymentEligibilityResponse
                      .minPercentageForFirstInstallment
                  );
                this.choosePayDateFirstInstTxt =
                  this.authoringContent.paymentAmount.choosePayDateFirstInstTxt.replace(
                    "[maxDaysBeforeFirstInstallment]",
                    this.paymentEligibilityResponse
                      .maxDaysBeforeFirstInstallment
                  );
                this.choosePayDateSecondInstTxt =
                  this.authoringContent.paymentAmount.choosePayDateSecondInstTxt.replace(
                    //  ('[maxDaysBetweenInstallment]', this.paymentEligibilityResponse.maxDaysBeforeFirstInstallment);
                    "[maxDaysBetweenInstallment]",
                    this.paymentEligibilityResponse.maxDaysBetweenInstallments
                  );
                if (!this.isMiAmountCalculated) {
                  this.calculatingMinAmmount();
                }
              }
            }
          }
        },
        (error) => {
          // This is intentional
        }
      );

      this._paymentSvc.paymentArrangementsQuery(this.banNumber).pipe(this.pipedOperatorsService.subReattempt()).subscribe(
        (response) => {
          if (
            !isNullOrUndefined(
              response &&
              response.paymentArrangement &&
              response.paymentArrangement.installmentList
            )
          ) {
            response.paymentArrangement.installmentList.forEach(
              (InstallmentList) => {
                if (
                  InstallmentList.installmentStatus ===
                  MakePaymentConstant.INSTALLMENT_STATUS_OPENED
                ) {
                  this.isEditFlow = true;
                  this.isInstallmentRadioSelected = true;
                  this._cacheSvc.putInSessionStoarage(
                    COMMON_CONSTANTS.IS_EDIT_FLOW,
                    true
                  );
                  this.isInstallmentRadioSelected = true;
                }
              }
            );
          }
          if (this.isEditFlow) {
            this.paymentInstallment = _.filter(
              response.paymentArrangement.installmentList,
              { installmentType: COMMON_CONSTANTS.INSTYPE_REGULAR }
            );

            const dateComparison = _.forEach(
              this.paymentInstallment,
              (key, value) => {
                  this.installDate = this.dateComparsion(key.installmentDate);
                  if (
                    this.installDate > MakePaymentConstant.MINUS_ONE &&
                    this.installDate < COMMON_CONSTANTS.TWENTYFOUR
                  ) {
                    this.sameDateCheck = true;
                  }
                  ContentService.SetCache("sameDateCheck", this.sameDateCheck);
                  this.isInstallmentSelected = true;
                  this.paymentAmount =
                    Number(this.installmentInfo[COMMON_CONSTANTS.ZERO].amount) +
                    Number(this.installmentInfo[COMMON_CONSTANTS.ONE].amount);
              }
            );


            if (
              !isNullOrUndefined(
                this.paymentInstallment[COMMON_CONSTANTS.ZERO]
              ) &&
              !isNullOrUndefined(
                this.paymentInstallment[COMMON_CONSTANTS.ZERO].installmentAmount
              )
            ) {
              this.installmentInfo[COMMON_CONSTANTS.ZERO].amount =
                this.paymentInstallment[
                  COMMON_CONSTANTS.ZERO
                ].installmentAmount;
            }
            if (
              !isNullOrUndefined(
                this.paymentInstallment[COMMON_CONSTANTS.ONE]
              ) &&
              !isNullOrUndefined(
                this.paymentInstallment[COMMON_CONSTANTS.ONE].installmentAmount
              )
            ) {
              this.installmentInfo[COMMON_CONSTANTS.ONE].amount =
                this.paymentInstallment[COMMON_CONSTANTS.ONE].installmentAmount;
            }
            if (
              !isNullOrUndefined(
                this.paymentInstallment[COMMON_CONSTANTS.ZERO]
              ) &&
              !isNullOrUndefined(
                this.paymentInstallment[COMMON_CONSTANTS.ZERO].installmentDate
              )
            ) {
              this.installmentInfo[COMMON_CONSTANTS.ZERO].date =
                this.paymentInstallment[COMMON_CONSTANTS.ZERO].installmentDate;
            }
            if (
              !isNullOrUndefined(
                this.paymentInstallment[COMMON_CONSTANTS.ONE]
              ) &&
              !isNullOrUndefined(
                this.paymentInstallment[COMMON_CONSTANTS.ONE].installmentDate
              )
            ) {
              this.installmentInfo[COMMON_CONSTANTS.ONE].date =
                this.paymentInstallment[COMMON_CONSTANTS.ONE].installmentDate;
            }

            this.saveSuccess = false;
            this.paymentArrangementId =
              response.paymentArrangement.paymentArrangementId;
            if (
              !_.isEmpty(
                response.paymentArrangement.installmentList[0]
                  .securePaymentDetails.securePaymentMethodDetails
                  .bankAccountNumber
              )
            ) {
              this.paymentMode = COMMON_CONSTANTS.BANK_TXT;
              this.paymementInfo = COMMON_CONSTANTS.BANK_TXT;
              this.creditDetails["allowChange"] = true;
              this.allowModeEdit = COMMON_CONSTANTS.BANK_TXT;
              const bankACNumber =
                response.paymentArrangement.installmentList[0]
                  .securePaymentDetails.securePaymentMethodDetails
                  .bankAccountNumber;
              ContentService.SetCache(
                COMMON_CONSTANTS.ACCOUNT_NUMBER,
                bankACNumber.substring(bankACNumber.length - 4)
              );
            }
            if (
              !_.isEmpty(
                response.paymentArrangement.installmentList[0]
                  .securePaymentDetails.securePaymentMethodDetails
                  .creditDebitCardNumber
              )
            ) {
              const CCNumber =
                response.paymentArrangement.installmentList[0]
                  .securePaymentDetails.securePaymentMethodDetails
                  .creditDebitCardNumber;
              this.paymentMode = COMMON_CONSTANTS.CARD_TXT;
              this.paymementInfo = COMMON_CONSTANTS.CARD_TXT;
              this.allowModeEdit = COMMON_CONSTANTS.CARD_TXT;
              this.creditDetails["type"] =
                response.paymentArrangement.installmentList[0].securePaymentDetails.securePaymentMethodDetails.debitType;
              
              this.creditDetails["lastfourDigit"] = CCNumber.substring(
                CCNumber.length - 4
              );
            }
          }
          if (
            this.isEditFlow &&
            !isNullOrUndefined(
              response && response["paymentArrangement"]["pastDueAmount"]
            )
          ) {
            this.paymentArrangementsResponse = Number(
              response["paymentArrangement"]["pastDueAmount"]
            );
            if (
              !isNullOrUndefined(
                this.authoringContent && this.authoringContent.paymentAmount
              )
            ) {
              this.firstInstFieldMsgPastDue =
                this.authoringContent.paymentAmount.firstPaymentFieldMsgPastDue.replace(
                  "[pastDue]",
                  this.currencyPipe.transform(
                    this.paymentArrangementsResponse,
                    MakePaymentConstant.usd
                  )
                );
              if (!this.isMiAmountCalculated) {
                this.calculatingMinAmmount();
              }
            }
          }
          // installment date changes
          this._paymentSvc.installmentDateFromClander.subscribe((dateModel) => {
            if (!isNullOrUndefined(dateModel && dateModel.dates)) {
              if (
                !isNullOrUndefined(dateModel.isSecondCalendarDblClick) &&
                dateModel.isSecondCalendarDblClick
              ) {
                this.isSecondCalendarDblClick =
                  dateModel.isSecondCalendarDblClick;
              }
              this.installmentDateChanges(dateModel.dates);
            }
          });
                },
        (error) => {
          // This is intentional
        }
      );

      if (!this.isEditFlow && !isNullOrUndefined(this.paymentAmount)) {
        this.paymentArrangementsResponse = this.paymentAmount;
        if (
          !isNullOrUndefined(
            this.authoringContent && this.authoringContent.paymentAmount
          )
        ) {
          this.firstInstFieldMsgPastDue =
            this.authoringContent.paymentAmount.firstPaymentFieldMsgPastDue.replace(
              "[pastDue]",
              this.currencyPipe.transform(
                this.paymentArrangementsResponse,
                MakePaymentConstant.usd
              )
            );
          if (!this.isMiAmountCalculated) {
            this.calculatingMinAmmount();
          }
        }
      }
      this.enableSubmitButton();
    }
  }

  track_PAviaSPLUNK(type) {
    const errorType = COMMON_CONSTANTS.SPLUNK_CLIENTSIDE_ERROR;
    const req = { url: type, body: type };
    this.splunkSvc.logInSplunk(NgxLoggerLevel.LOG, req, type, errorType);
  }

  initializeObjects() {
    if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY) == "null"){
      this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ORG_ID_KEY,this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_GN_KEY));
    }
    this.orgID = this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.ORG_ID_KEY
    );
    this.banNumber = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.BAN_NUMBER_KEY
    );
    if(!this.isPartnerFlow) {
      if (this.utils.isCareAuth()){
        this.makePaymentPermission = true;
        this.paymentArrangementDisable = false;
        this.isPaymentArrangementReadOnly = false;
        this.executePaymentEligibilty_Search();
      }
      else {
        this.paymentArrangementDisable = true;
        const permissionsObject = JSON.parse(this._cacheSvc.getFromSessionStorage("pnp-persisted-context") || "{}");
        if(!isNullOrUndefined(permissionsObject) && !isNullOrUndefined(permissionsObject.activities)){
          if (permissionsObject.activities.BAN?.MAKE_PAYMENTS?.isActiveFlag == 1) {
            this.makePaymentPermission = true;
            this.paymentArrangementDisable = false;
            this.isPaymentArrangementReadOnly = false;
            this.executePaymentEligibilty_Search();
          }
          if(!this.makePaymentPermission){
            this.router.navigate([MakePaymentConstant.processError]);
            return;
          }
        }
      }
    }
    if (
      (!this.isRedirect &&
        this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE
        ) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE &&
        this.isAccessTokenStored === true) ||
      (environment.SSO_ON === false &&
        this.isAccessTokenStored === true &&
        this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE
        ) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE) && this.makePaymentPermission
    ) {
      this.paymentDate = new Date();
      this.isOther = false;
      this.thankuDataObject = {};
      this.tcContent = {};
      this.banNumber = this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.BAN_NUMBER_KEY
      );
      this.careAuthType = this.utils.isCareAuth()

      const baseban = btoa(this.banNumber);

      // tslint:disable-next-line:max-line-length
      this.contentService
        .GetContentData()
        .subscribe((data) => {
            this.makePaymentIntialLoad(baseban, data);
            this._cacheSvc.putInSessionStoarage(
              "contentmain",
              JSON.stringify(data)
            );
          },
          (error) => {
            const mock = true;
            this.contentService
              .GetContentData()
              .subscribe((data) => {
                this.makePaymentIntialLoad(baseban, data);
                this._cacheSvc.putInSessionStoarage(
                  "contentmain",
                  JSON.stringify(data)
                );
              });
          }
        );
    }

    this.setPaymentSubmissionType();
  }

  dateComparsion(comparsionDate) {
    const dt1 = new Date();
    const dt2 = new Date(comparsionDate);
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.round(diff); //roundOff
  }

  makePaymentIntialLoad(baseban, data) {
    this.AchConstants = ACH_CONSTANTS;
    this.authoringContent = data.par.common_payment;
    document.title = this.authoringContent.landingInfo.PageTitle;
    ContentService.SetCache(
      COMMON_CONSTANTS.CONTENT_CACHE_KEY,
      this.authoringContent
    );
    // tslint:disable-next-line:max-line-length
    this.returnURI = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.RETURN_URL_SSP
    );
    if (!this.isHardGoodsEnable) {
      const checkPaymentService = forkJoin(
        this._paymentSvc.getBalanceApi().pipe(catchError(() => of(null))),
        this._schPaySvc
          .getSchDates(baseban, this.orgID)
          .pipe(catchError(() => of(null)))
      );

      checkPaymentService.pipe(this.pipedOperatorsService.subReattempt()).subscribe(
        (responses) => {
          const balanceData = responses[0];            
          const schData = responses[1];
          this.hasScheduledPayment = Boolean(schData === null);
          if (balanceData) {

            this._cacheSvc.putInSessionStoarage(
              COMMON_CONSTANTS.CURRENT_PST_DATE,
              (new Date()).toLocaleDateString("en-US",{timeZone:"America/Los_Angeles"})
            );
            this.billingAccounts = <
              Array<BillingAccountInfo>
              >balanceData.billingAccounts;
            if (
              this.billingAccounts &&
              this.billingAccounts.length >= 1
            ) {
              ContentService.SetCache(
                MakePaymentConstant.UPDATED_BALANCE_KEY,
                this.billingAccounts[0]
              );
              this.billingAccount = this.billingAccounts[0];
              const dueDate =
                this.billingAccount.billInfo[
                MakePaymentConstant.dueDateField
                ];
              let autoPay: any;
              if (
                this.billingAccount.statusInfo[
                  MakePaymentConstant.autoPayStatus
                ].toLowerCase() === MakePaymentConstant.optIn ||
                this.billingAccount.statusInfo[
                  MakePaymentConstant.autoPayStatus
                ].toLowerCase() === true
              ) {
                autoPay = true;
              } else {
                autoPay = false;
              }
              const dueD = new Date(`${dueDate}`);
              this.dueDate = new Date(`${dueDate}`);
              if (autoPay) {
                const isAutoPay = JSON.parse(autoPay);
                this.autoPayDate = isAutoPay
                  ? new Date(dueD.setDate(dueD.getDate() - 2))
                  : null;
              }
              // US474841: condition check for arBalance
              this.paymentAmount =
                this.billingAccount.balanceinfo.arBalance.replace(
                  ",",
                  ""
                );
              this.billingAccount.balanceinfo.arBalance =
                this.paymentAmount;
              if(parseFloat(this.billingAccount.balanceinfo.arBalance) > COMMON_CONSTANTS.TWENTY_FIVE_THOUSAND){
                this.hasTotalDueMsg = true;
                this.isOtherRadioSelected = true;
                this.isOtherSelected = true;
                this.totalDueMsg = this.authoringContent.paymentAmount.totalDueMessage;
              }
              if (parseInt(this.paymentAmount, 10) === 0) {
                this.paymentDue = false;
              } else {
                this.paymentDue = true;
              }
              if (
                this._cacheSvc.getFromSessionStorage(
                  COMMON_CONSTANTS.IS_PARTNER_BOARD
                ) === "false"
              ) {
                environment.HIDE_PAYMENT_ARRANGEMENT
                  ? ""
                  : this.checkPaymentEligibilty();
              }
            }
          }
          if (schData) {
            this.scheduledPayment = schData;
          }
          if (this.billingAccount) {
            this.getUpdatedBalence(
              this.billingAccount,
              this.scheduledPayment
            );
          }
          this.paymentAmount = +this.paymentAmount;
          this.defAmount = this.paymentAmount;
                  },
        (err) => { console.log(err) }
      );

      // tslint:disable-next-line:no-shadowed-variable
      this._schPaySvc.getSchEligibilty(baseban, this.orgID).pipe(this.pipedOperatorsService.subReattempt()).subscribe( // already declared in scope
        (data) => {
          if (data && data.eligibility === "Y") {
            // tslint:disable-next-line:radix
            this.startDateChk = new Date(data["rangeStartDate"]);
            // tslint:disable-next-line:radix
            this.endDateChk = new Date(data["rangeEndDate"]);
            this.bsValue = this.startDateChk;
            this.startDate = new Date();
            this.endDate = this.endDateChk;
            // Temp Code fix for sch eligibility reverse response Ends
            this.isEligible = true;
          }else{
            this.isEligible = false;
            this.payDateDefRadioMsg = this.authoringContent.paymentDate.notEligibleTxt;
          }
        },
        (err) => {
          this.isEligible = false;
          this.payDateDefRadioMsg =
            this.authoringContent.paymentDate.notEligibleTxt;
        }
      );
    }
    this.getAutopayTC();
    if (
      environment.hideBANFriendlyName ||
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) ==
      "true"
    ) {
      this.titleLable = `${this.authoringContent.paymentAmount.titlelblTxt1.replace(
        MakePaymentConstant.ban,
        this.banNumber
      )}`;
    } else {
      if (
        this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.IS_PARTNER_BOARD
        ) !== "true"
      ) {
        this.setTitle();
      }
    }
  }

  setTitle() {
    let accountDetailsRequestData = {
      billingAccount: this.banNumber,
    };
    this._getBanDetails$ = this._autoPaymentSvc
        .getAccountDetails(accountDetailsRequestData)
        .pipe(this.pipedOperatorsService.subReattempt())
        .subscribe(
            (data) => {
              this.titleLable = `${this.authoringContent.paymentAmount.titlelblTxt1.replace(
                  MakePaymentConstant.ban,
                  this.banNumber
              )}`;
              if (data) {
                this.banDetails = data;
                if (this.banDetails.banFriendlyName) {
                  this.titleLable += `${this.authoringContent.paymentAmount.titlelblTxt2.replace(
                      MakePaymentConstant.banName,
                      this.banDetails.banFriendlyName
                  )}`;
                }
              }
            },
            (error) => {
              this.titleLable = `${this.authoringContent.paymentAmount.titlelblTxt1.replace(
                  MakePaymentConstant.ban,
                  this.banNumber
              )}`;
            }
        );
  }

  getAutopayTC(): any {
    this.autopayTCSub = this.termsAndConditionsSvc
      .getAutoPayAgreement()
      .pipe(this.pipedOperatorsService.subReattempt())
      .subscribe(
        (autoPayData) => {
          this.autopayTC = autoPayData;
          this._cacheSvc.putInSessionStoarage(
            COMMON_CONSTANTS.APSERVICE_TERMS_DATA,
            JSON.stringify(this.autopayTC)
          );
          const currDateTime = new Date();
          const agreementTime = currDateTime.toISOString();
          const acceptTimestamp1 =
            agreementTime.slice(0, -1) + MakePaymentConstant.zeroConstant;
          const autoPayAgreementConsent = {
            documentId: this.autopayTC.acceptDocumentId,
            acceptedTimeStamp: acceptTimestamp1,
          };
          this.termsAndConditionsSvc.setSelectedTerms(autoPayAgreementConsent);
          ContentService.SetCache(
            MakePaymentConstant.autoPayDocDetailsKey,
            autoPayAgreementConsent
          );
          this.tcAutoPayAgreementContent = atob(
            this.autopayTC.attachments.embeddedData.binaryContent
          );
          this.tcAutoPayAgreementContent =
            this.sanitizer.bypassSecurityTrustHtml(
              this.tcAutoPayAgreementContent
            );
          ContentService.SetCache(
            MakePaymentConstant.autoPayAgreement,
            this.tcAutoPayAgreementContent
          );
        },
        (err) => {
          this.errorAlertAutoPayTC = {
            message: {
              errorKey: err.status,
              errorMessage: "aa",
              errorType: COMMON_CONSTANTS.AUTOPAY_PROPERTIES.ERR_SERVER,
            },
            show: true,
          };
        }
      );
  }

  addCreditCard() {
    this.isAddCreditCard = !this.isAddCreditCard;
    if (this.isAddCreditCard) {
      this.flowType = COMMON_CONSTANTS.BILL_PAY;
    }
    this.enableSubmitButton();
  }

  addBank() {
    this.isAddBank = !this.isAddBank;
    if (this.isAddBank) {
      this.flowType = COMMON_CONSTANTS.BILL_PAY;
    }
    this.enableSubmitButton();
  }

  onChangeDirectDeposit(evt: any) {

    this.formValid = false;
    this.errorAlert = { message: null, show: false };
    this.paymementInfo = evt.target.value;
    this.thankuDataObject.paymentInfo = this.paymementInfo;
    if (this.allowModeEdit === "Bank") {
      this.creditDetails.allowChange = true;
    }

    if (this.allowModeEdit === "Card") {
      this.creditDetails.allowChange = false;
    }

    if (this.paymementInfo === MakePaymentConstant.bank) {
      this.isAddCreditCard = false;
      this.luhnCheck = true;
      this.cardTypeVal = true;
    }

  }

  onPayementAmountChange(evt: any, value?: any) { 
    this.isOtherSelected = false;
    if (evt === "installment" || this.totalDue) {
      if (evt === MakePaymentConstant.totalDue) {
        this.track_PAviaSPLUNK(
          COMMON_CONSTANTS.SPLUNK_TRACKER_TYPES.TOTAL_DUE_ATTEMPTED
        );
        this.isOtherRadioSelected = false;
        this.isDefaultSelected = false;
        this.isTotalSelected = true;
        this.isInstallmentRadioSelected = false;
        this.isInstallmentSelected = false;
        this.paymentAmount = this.defAmount;
      } else if (evt === MakePaymentConstant.paymentAmount) {
        this.track_PAviaSPLUNK(
          COMMON_CONSTANTS.SPLUNK_TRACKER_TYPES.PAYMENT_AMOUNT_ATTEMPTED
        );
        this.isOtherRadioSelected = false;
        this.isDefaultSelected = true;
        this.isInstallmentSelected = false;
        this.isInstallmentRadioSelected = false;
        this.isTotalSelected = false;
        this.paymentAmount = value.replace("$", "");
      } else if (evt === MakePaymentConstant.Installment) {
        this.track_PAviaSPLUNK(
          COMMON_CONSTANTS.SPLUNK_TRACKER_TYPES.PA_ATTEMPTED
        );
        this.isDefaultSelected = false;
        this.isTotalSelected = false;
        this.isOtherRadioSelected = false;
        this.isInstallmentRadioSelected = true;
        this.isInstallmentSelected = true;
        this.isOtherSelected = false;
        if (this.isSymbol) {
          this.isSymbol = !this.isSymbol;
        }

        this.firstInstallmentflag = false;
        this.secondInstalment = false;
        // tslint:disable-next-line:radix
        const tempDate = this._cacheSvc.getFromSessionStorage("currentDate");

        const setFirstInstallment = new Date(tempDate);
        this.firstInstallmentDate = new Date(tempDate);
        this.firstInstallmentDate = new Date(
          setFirstInstallment.setDate(this.firstInstallmentDate.getDate() + 1)
        );
        this.firstInstalmentDateSelected = new Date(tempDate);
        const setSecondInstalmentDate = new Date(tempDate);
        // tslint:disable-next-line:radix
        this.secondInstallmentDate = new Date(
          setSecondInstalmentDate.setDate(setSecondInstalmentDate.getDate() + 1)
        );
        this.secondInstallmentDate = new Date(
          this.secondInstallmentDate.setDate(
            setSecondInstalmentDate.getDate() +
            Number(this.maxDaysBetweenInstallments)
          )
        );

        this.installmentEndDate = new Date(tempDate);
        this.installmentEndDate = new Date(
          this.installmentEndDate.setDate(this.installmentEndDate.getDate())
        );
        this.installmentEndDate = new Date(
          this.installmentEndDate.setDate(
            this.installmentEndDate.getDate() +
            Number(this.maxDaysBeforeFirstInstallment)
          )
        );
        this.listsAllEnstalment = [
          {
            text: "First installment",
            id: 1,
            className: "displayList-element",
          },
          {
            text: "Second installment",
            id: 1,
            className: "displayList-element",
          },
          { text: "Available dates", id: 1, className: "displayList-element" },
        ];

        this.installmentDates.push(this.firstInstallmentDate);
        this.installmentDates.push(this.secondInstallmentDate);
        this.secondInstallmentDateModal = this.secondInstallmentDate;
        this.firstInstallmentDateModal = this.firstInstallmentDate;
        this.secondInsMinDate = new Date(
          setSecondInstalmentDate.setDate(setSecondInstalmentDate.getDate() + 1)
        );
        this.secondInsMaxDate = new Date(
          setSecondInstalmentDate.setDate(
            setSecondInstalmentDate.getDate() +
            Number(this.maxDaysBetweenInstallments) -
            1
          )
        );
        this.displayFirstinstallmentDate = this.validateTodayDate(
          this.firstInstallmentDateModal
        );
        this.displaySecondinstallmentDate = this.validateTodayDate(
          this.secondInstallmentDateModal
        );
        this.installmentInfo[COMMON_CONSTANTS.ZERO].date =
          this.validateTodayDate(this.firstInstallmentDateModal);
        this.installmentInfo[COMMON_CONSTANTS.ONE].date =
          this.validateTodayDate(this.secondInstallmentDateModal);
        if (!this.firstInstallmentAmount) {
          this.calculatingMinAmmount();
        }
        this.installmentInfo[COMMON_CONSTANTS.ZERO].amount =
          this.firstInstallmentAmount;
        this.installmentInfo[COMMON_CONSTANTS.ONE].amount =
          this.secondInstallmentAmount;
      } else {
        this.isOtherRadioSelected = true;
        this.isInstallmentRadioSelected = false;
        this.isInstallmentSelected = false;
        this.isOtherSelected = true;
        this.isDefaultSelected = false;
        this.isTotalSelected = false;
        if (this.isSymbol) {
          this.isSymbol = !this.isSymbol;
        }
      }
    } else {
      if (evt === MakePaymentConstant.paymentAmount) {
        this.track_PAviaSPLUNK(
          COMMON_CONSTANTS.SPLUNK_TRACKER_TYPES.PAYMENT_AMOUNT_ATTEMPTED
        );
        this.isDefaultSelected = true;
        this.isOtherRadioSelected = false;
        this.isInstallmentRadioSelected = false;
        this.isInstallmentSelected = false;
        this.paymentAmount = this.defAmount;
        this.otherTxtValue = "";
      } else if (evt === MakePaymentConstant.other) {
        this.track_PAviaSPLUNK(
          COMMON_CONSTANTS.SPLUNK_TRACKER_TYPES.OTHER_AMOUNT_ATTEMPTED
        );
        this.paymentAmount = 0;
        this.isDefaultSelected = false;
        this.isOtherRadioSelected = true;
        this.isInstallmentRadioSelected = false;
        this.isInstallmentSelected = false;
        this.isOtherSelected = true;
        if (this.isSymbol) {
          this.isSymbol = !this.isSymbol;
        }
      } else {
        this.track_PAviaSPLUNK(
          COMMON_CONSTANTS.SPLUNK_TRACKER_TYPES.PA_ATTEMPTED
        );
        this.isDefaultSelected = false;
        this.isOtherRadioSelected = false;
        this.isInstallmentRadioSelected = true;
        this.isInstallmentSelected = true;
        this.isOtherSelected = false;
        if (this.isSymbol) {
          this.isSymbol = !this.isSymbol;
        }
        this.firstInstallmentflag = false;
        this.secondInstalment = false;
        const tempDate = this._cacheSvc.getFromSessionStorage("currentDate");

        const setFirstInstallment = new Date(tempDate);
        this.firstInstallmentDate = new Date(tempDate);
        this.firstInstallmentDate = new Date(
          setFirstInstallment.setDate(this.firstInstallmentDate.getDate() + 1)
        );
        this.firstInstalmentDateSelected = new Date(tempDate);
        const setSecondInstalmentDate = new Date(tempDate);
        // tslint:disable-next-line:radix
        this.secondInstallmentDate = new Date(
          setSecondInstalmentDate.setDate(setSecondInstalmentDate.getDate() + 1)
        );
        this.secondInstallmentDate = new Date(
          this.secondInstallmentDate.setDate(
            setSecondInstalmentDate.getDate() +
            Number(this.maxDaysBetweenInstallments)
          )
        );

        this.installmentEndDate = new Date(tempDate);
        this.installmentEndDate = new Date(
          this.installmentEndDate.setDate(this.installmentEndDate.getDate())
        );
        this.installmentEndDate = new Date(
          this.installmentEndDate.setDate(
            this.installmentEndDate.getDate() +
            Number(this.maxDaysBeforeFirstInstallment)
          )
        );
        this.listsAllEnstalment = [
          {
            text: "First installment",
            id: 1,
            className: "displayList-element",
          },
          {
            text: "Second installment",
            id: 1,
            className: "displayList-element",
          },
          { text: "Available dates", id: 1, className: "displayList-element" },
        ];

        this.installmentDates.push(this.firstInstallmentDate);
        this.installmentDates.push(this.secondInstallmentDate);
        this.secondInstallmentDateModal = this.secondInstallmentDate;
        this.firstInstallmentDateModal = this.firstInstallmentDate;
        this.secondInsMinDate = new Date(
          setSecondInstalmentDate.setDate(setSecondInstalmentDate.getDate() + 1)
        );
        this.secondInsMaxDate = new Date(
          setSecondInstalmentDate.setDate(
            setSecondInstalmentDate.getDate() +
            Number(this.maxDaysBetweenInstallments) -
            1
          )
        );
        this.displayFirstinstallmentDate = this.validateTodayDate(
          this.firstInstallmentDateModal
        );
        this.displaySecondinstallmentDate = this.validateTodayDate(
          this.secondInstallmentDateModal
        );
        this.installmentInfo[COMMON_CONSTANTS.ZERO].date =
          this.validateTodayDate(this.firstInstallmentDateModal);
        this.installmentInfo[COMMON_CONSTANTS.ONE].date =
          this.validateTodayDate(this.secondInstallmentDateModal);
        this.installmentInfo[COMMON_CONSTANTS.ZERO].amount =
          this.firstInstallmentAmount;
        this.installmentInfo[COMMON_CONSTANTS.ONE].amount =
          this.secondInstallmentAmount;
      }
    }
    this.enableSubmitButton();
  }

  validateTodayDate(givenDate) {
    let dateFormat = givenDate;
    if (
      this.datePipe.transform(givenDate, 'MM/dd/yyyy') ===
      this.datePipe.transform(this.todayDate, 'MM/dd/yyyy')
    ) {
      dateFormat += " " + MakePaymentConstant.todayWithBracket;
    }
    return dateFormat
  }

  onPayementDateChange(evt: any) {
    // analytics click tagging
    this.generateClickDetailsForMakePayment(
      "click",
      "button",
      ClickEventType.ACTION,
      "Change",
      ""
    );
    this.isOtherPaymentDate = false;
    if (evt.target.value === MakePaymentConstant.today) {
      this.isDate = false;
      this.paymentDate = new Date();
      this.isOtherPaymentDate = false;
      this.isOtherPaymentDateSelected = false;
    } else {
      this.isOtherPaymentDate = true;
    }
  }

  onCcBillPaySuccess(ccData: PaymentValidation) {
    this.ccSavedData = ccData;
  }

  onAchBillPaySuccess(achData: BankPaymentValidation) {
    this.achSavedData = achData;
    this.enableSubmitButton();
  }

  deleteBankInfo(val: boolean) {
    this.errorAlert = { message: null, show: false };
    this.isAchSaved = val;
  }

  deleteCardInfo(val: boolean) {
    this.errorAlert = { message: null, show: false };
  }

  changeAmount() {
    this.isAmount = true;
    this.isDate = false;
    this.isOtherSelected = false;
    this.amountError = "";
    this.paymentAmount = this.defAmount;
    if (this.isOtherRadioSelected) {
      this.isOtherSelected = true;
    }
  }

  collapseOther(amt?) {
    if (amt.value !== "" && this.isOtherRadioSelected === true) {
      this.isOtherSelected = false;
      this.paymentAmount = amt.value;
      this.isAmount = false;
      if (
        amt.value >= COMMON_CONSTANTS.TWO &&
        amt.value <= COMMON_CONSTANTS.TWENTY_FIVE_THOUSAND
      ) {
        this.isOtherSelected = false;
        this.paymentAmount = amt.value;
        this.isAmount = false;
      } else if (amt.value < COMMON_CONSTANTS.TWO) {
        this.amountError =
          this.authoringContent.paymentAmount.chooseAmountErrorTxt;
      } else if (amt.value > COMMON_CONSTANTS.TWENTY_FIVE_THOUSAND) {
        this.amountError = this.authoringContent.paymentAmount.amountErrorTxt;
      }
    }
  }

  changeAmountValue(amt) {
    if (
      amt.value >= COMMON_CONSTANTS.TWO &&
      amt.value <= COMMON_CONSTANTS.TWENTY_FIVE_THOUSAND
    ) {
      this.paymentAmount = amt.value;
      this.otherTxtValue = amt.value;
      this.isAmount = true;
      this.paymentDue = true;
      this.isOtherSelected = true;
      this.isOtherRadioSelected = true;
      this.isDefaultSelected = false;
      this.amountError = "";
      this.isSymbol = true;
    } else if (amt.value === "") {
      this.isAmount = true;
      this.isDefaultSelected = false;
      this.isOtherRadioSelected = true;
      this.isOtherSelected = true;
      this.isSymbol = false;
      this.paymentAmount = 0;
    } else if (amt.value < COMMON_CONSTANTS.TWO) {
      this.isSymbol = true;
      this.amountError =
        this.authoringContent.paymentAmount.chooseAmountErrorTxt;
    } else if (amt.value > COMMON_CONSTANTS.TWENTY_FIVE_THOUSAND) {
      this.isSymbol = true;
      this.amountError = this.authoringContent.paymentAmount.amountErrorTxt;
    }
  }

  toggleSymbol(amt?: any) {
    if (
      (amt.value === MakePaymentConstant.twoDollar || amt.value === "") &&
      this.isSmallBalance
    ) {
      this.isSymbol = false;
    } else {
      this.isSymbol = !this.isSymbol;
    }
  }

  changeDate() {
    // analytics click tagging
    this.generateClickDetailsForMakePayment(
      "click",
      "button",
      ClickEventType.ACTION,
      "Change",
      ""
    );
    this.isDate = true;
    this.isAmount = false;
    this.isOtherSelected = false;
  }

  dateSelect(evt) {
    this.paymentDate = evt ? evt : this.paymentDate;
    // tslint:disable-next-line:max-line-length
    if (
      this.datePipe.transform(
        this.paymentDate,
        MakePaymentConstant.dateFormat
      ) !==
      this.datePipe.transform(this.todayDate, MakePaymentConstant.dateFormat)
    ) {
      this.isOtherPaymentDateSelected = true;
    } else {
      this.isOtherPaymentDateSelected = false;
    }
    this.isDate = false;
    this.isOtherPaymentDate = false;
  }

  submitPayment(encrypedNumber: any) { 
    // analytics click tagging
    console.log('SP: Submit Payment method flow')
    try{
      this.generateClickDetailsForMakePayment(
        "click",
        "button",
        ClickEventType.ACTION,
        "Submit",
        ""
      );
    }
    catch(error){
      console.log('SP: generateClickDetailsForMakePayment: catch block');
    }
    if (this.isInstallmentRadioSelected || this.isEditFlow) {
      console.log('SP: Call Schedule pay');
      this.submitSchedulePayment(encrypedNumber);
    } else {
      if (this.paymementInfo === MakePaymentConstant.bank) {
        console.log('SP: Bank Payment flow');
        this.mPBankValidation.customerReference.customerId =
          this.achSavedData.customerReference.customerId;
        this.mPBankValidation.payment.orderNumber =
          this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TRANS_REF_NUM);
        this.mPBankValidation.payment.businessUnit =
          MakePaymentConstant.businessUnit;
        const currDateTime = new Date();
        const agreementTime = currDateTime.toISOString();
        this.mPBankValidation.payment.termsAgreementTime = agreementTime;
        this.mPBankValidation.payment.programCode =
          MakePaymentConstant.programCode;
        this.mPBankValidation.payment.clientId = MakePaymentConstant.clientId;
        this.mPBankValidation.payment.operationType =
          MakePaymentConstant.operationType;
        this.mPBankValidation.payment.termsAgreementIndicator = false;
        this.mPBankValidation.payment.orderTypes =
          MakePaymentConstant.achOrderType;
        this.mPBankValidation.payment.creationTime = agreementTime;
        this.mPBankValidation.payment.businessSegment =
          MakePaymentConstant.businessSegment;
        this.mPBankValidation.payment.customerPresentIndicator = false;
        this.mPBankValidation.payment.paymentMethodReference.paymentMethodCode =
          MakePaymentConstant.paymentMethodBankCode;
        this.mPBankValidation.payment.paymentMethodReference.storePaymentMethodIndicator =
          false;
        // check for the payment Amount has comma
        this.mPBankValidation.payment.chargeAmount = Number(
          this.paymentAmount.toString().replace(",", "")
        );
        this.mPBankValidation.payment.paymentInstrument.bankPayment.accountNumber =
          encrypedNumber;
        this.mPBankValidation.payment.paymentInstrument.bankPayment.bankAccountAlias =
          "";
        if (this.achBillPayAliasData && this.achBillPayAliasData.accountType) {
          if (
            this.achBillPayAliasData.accountType === ACH_CONSTANTS.CHECKING_KEY
          ) {
            this.accountType = this.achBillPayAliasData.accountType.substr(
              0,
              this.achBillPayAliasData.accountType.length - 3
            );
          } else if (
            this.achBillPayAliasData.accountType === ACH_CONSTANTS.SAVING_KEY
          ) {
            this.accountType =
              this.achBillPayAliasData.accountType.substr(
                0,
                this.achBillPayAliasData.accountType.length - 4
              ) + "E";
          }
        }
        this.mPBankValidation.payment.paymentInstrument.bankPayment.accountType =
          this.accountType;
          // added code to get routing number from session
        this.mPBankValidation.payment.paymentInstrument.bankPayment.routingNumber =
        HttpCacheService.GetCache(COMMON_CONSTANTS.ROUTING_NUMBER);
        this.mPBankValidation.payment.paymentInstrument.bankPayment.accountHolderNames =
          [this.achBillPayAliasData.accountHolderName];
        this.mPBankValidation.payment.productGroup =
          MakePaymentConstant.productGroup;
        this.mPBankValidation.payment.billerCode =
          MakePaymentConstant.billerCode;
        /* code added for payment arrangement object
        if (!this.paymentArrangementDisable) {
          const installmentList = [
            {
              plannedPaymentAmount: Number((this.installmentInfo[COMMON_CONSTANTS.ZERO].amount).toString().replace(',', '')),
              plannedPaymentDate:
              this.datePipe.transform(  this.installmentInfo[COMMON_CONSTANTS.ONE].date , MakePaymentConstant.dateFormatYYYY)
            },
            {
              plannedPaymentAmount: Number((this.installmentInfo[COMMON_CONSTANTS.ONE].amount).toString().replace(',', '')),
              plannedPaymentDate:
              this.datePipe.transform(  this.installmentInfo[COMMON_CONSTANTS.ONE].date , MakePaymentConstant.dateFormatYYYY) 
            }
          ];
          this.sPBankValidation.payment.paymentArrangements.plannedPayments = installmentList;
        }
        // end of code added for payment Arrangement
        */
        this.mPValidation = this.mPBankValidation;
        console.log('SP: Bank Payment flow Assign variables');
      } else {
        console.log('SP: Card Payment flow');
        this.mPCCValidation.payment.paymentMethodReference.paymentMethodCode =
          this.paymentMethodCode;
        this.mPCCValidation.customerReference.customerId =
          this.ccSavedData.customerReference.customerId;
        this.mPCCValidation.payment.orderNumber =
          this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.TRANS_REF_NUM
          );
        this.mPCCValidation.payment.businessUnit =
          MakePaymentConstant.businessUnit;
        const currDateTime = new Date();
        const agreementTime = currDateTime.toISOString();
        this.mPCCValidation.payment.termsAgreementTime = agreementTime;
        this.mPCCValidation.payment.programCode =
          MakePaymentConstant.programCode;
        this.mPCCValidation.payment.operationType =
          MakePaymentConstant.operationType;
        this.mPCCValidation.payment.termsAgreementIndicator = true;
        this.mPCCValidation.payment.orderTypes =
          MakePaymentConstant.achOrderType;
        this.mPCCValidation.payment.creationTime = agreementTime;
        this.mPCCValidation.payment.businessSegment =
          MakePaymentConstant.businessSegment;
        this.mPCCValidation.payment.customerPresentIndicator = false;
        this.mPCCValidation.payment.paymentMethodReference.paymentMethodCode =
          MakePaymentConstant.paymentMethodCardCode;
        // check for the payment Amount has comma
        this.mPCCValidation.payment.chargeAmount = Number(
          this.paymentAmount.toString().replace(",", "")
        );
        this.mPCCValidation.payment.paymentMethodReference.paymentMethodCode =
          HttpCacheService.GetCache(CREDIT_CARD_CONSTANTS.PAYMETHOD_CODE);
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cvv =
          this.encryptedCvv;
        // tslint:disable-next-line:max-line-length
        this.mPCCValidation.payment.paymentInstrument.paymentCard.typeCode =
          this.cardTypeCode;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.expirationMonthYear =
          this.ccSavedData.payment.paymentInstrument.paymentCard.expirationMonthYear;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.paymentCardAlias =
          "";
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardNumber =
          encrypedNumber;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderName;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderFirstName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderFirstName;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderLastName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderLastName;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2 =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.purposes =
          MakePaymentConstant.purposes;
        // tslint:disable-next-line:max-line-length
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.countryCode =
          MakePaymentConstant.countryCode;
        this.mPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode;
        this.mPCCValidation.payment.productGroup =
          MakePaymentConstant.productGroup;
        this.mPCCValidation.payment.billerCode = MakePaymentConstant.billerCode;
        /* code added for payment arrangement object
        if (!this.paymentArrangementDisable) {
          const installmentList = [
            {
              plannedPaymentAmount: Number((this.installmentInfo[COMMON_CONSTANTS.ZERO].amount).toString().replace(',', '')),
              plannedPaymentDate:
              this.datePipe.transform(  this.installmentInfo[COMMON_CONSTANTS.ONE].date , MakePaymentConstant.dateFormatYYYY)
            },
            {
              plannedPaymentAmount: Number((this.installmentInfo[COMMON_CONSTANTS.ONE].amount).toString().replace(',', '')),
              plannedPaymentDate:
              this.datePipe.transform(  this.installmentInfo[COMMON_CONSTANTS.ONE].date , MakePaymentConstant.dateFormatYYYY) 
            }
          ];
          this.sPCCValidation.payment.paymentArrangements.plannedPayments = installmentList;
        }
        // end of code added for payment arrangement object
        */
        this.mPValidation = this.mPCCValidation;
        console.log('SP: Card Payment flow Assign variables');
      }

      (<any>this.mPValidation).recaptchaResponse = this.recaptchaToken;
      console.log('SP: Assign recaptcha response');

      this.dataConsentCheck = ContentService.GetCache(
        COMMON_CONSTANTS.TERMS_CONSENT
      );
      if (isNullOrUndefined(this.dataConsentCheck)) {
        console.log('SP: Data Consent');
        if (this.autopayTC ) {
          console.log('SP: Auotpay TC');
          this.termsAndConditionsSvc.recordUserConsent().subscribe(
            (data) => {
              console.log('SP: T&C Consent');
              if (
                data.postedDocumentDetails[0].status ===
                COMMON_CONSTANTS.STATUS_FAILURE
              ) {
                console.log('SP: Consent Fail call Process Payment');
                this.processPayment();
              } else {
                this.dataConsent = data;
                ContentService.SetCache(
                  COMMON_CONSTANTS.TERMS_CONSENT,
                  this.dataConsent
                );
                console.log('SP: Consent Success call Process Payment');
                this.processPayment();
              }
            },
            (error) => {
              console.log('SP: Error loop Call Process Payment');
              this.processPayment();
            }
          );
        } else {
          console.log('SP: Not Auotpay TC: Call Process Payment');
          this.processPayment();
        }
      } else {
        console.log('SP: Not Data Consent: Call Process Payment');
        this.processPayment();
      }
    }
  }

  submitSchedulePayment(encrypedNumber: any) { 
    // analytics click tagging
    this.generateClickDetailsForMakePayment(
      "click",
      "button",
      ClickEventType.ACTION,
      "Submit",
      ""
    );
    if (this.paymementInfo === MakePaymentConstant.bank) {
      // check for payment Arrangement flow in schedule payment flow else schedule payment
      if (this.isInstallmentRadioSelected || this.isEditFlow) {
        this.paBankValidation.payment.paymentMethodReference.paymentMethodCode =
          MakePaymentConstant.paymentMethodBankCode;
        this.paBankValidation.payment.businessUnit =
          MakePaymentConstant.businessUnit;
        this.paBankValidation.payment.programCode =
          MakePaymentConstant.programCode;
        this.paBankValidation.payment.billerCode =
          MakePaymentConstant.billerCode;
        this.paBankValidation.payment.businessSegment =
          MakePaymentConstant.businessSegment;
        this.paBankValidation.payment.orderTypes =
          MakePaymentConstant.schAchOrderType;
        this.paBankValidation.payment.productGroup =
          MakePaymentConstant.productGroup;
        if (this.isEditFlow) {
          this.paBankValidation.payment.paymentArrangementAction =
            MakePaymentConstant.paymentArrangementActionEdit;
        } else {
          this.paBankValidation.payment.paymentArrangementAction =
            MakePaymentConstant.paymentArrangementAction;
        }
        this.paBankValidation.payment.billerCode =
          MakePaymentConstant.billerCode;
        this.paBankValidation.customerReference.customerId =
          this.achSavedData.customerReference.customerId;
        this.sPValidation = this.paBankValidation;
        this.paBankValidation.payment.paymentInstrument.bankPayment.accountNumber =
          encrypedNumber;
        this.paBankValidation.payment.paymentInstrument.bankPayment.bankAccountAlias =
          "";
        if (this.achBillPayAliasData && this.achBillPayAliasData.accountType) {
          if (
            this.achBillPayAliasData.accountType === ACH_CONSTANTS.CHECKING_KEY
          ) {
            this.accountType = this.achBillPayAliasData.accountType.substr(
              0,
              this.achBillPayAliasData.accountType.length - 3
            );
          } else if (
            this.achBillPayAliasData.accountType === ACH_CONSTANTS.SAVING_KEY
          ) {
            this.accountType =
              this.achBillPayAliasData.accountType.substr(
                0,
                this.achBillPayAliasData.accountType.length - 4
              ) + "E";
          }
        }
        this.paBankValidation.payment.paymentInstrument.bankPayment.accountType =
          this.accountType;
        this.paBankValidation.payment.paymentInstrument.bankPayment.routingNumber =
        HttpCacheService.GetCache(COMMON_CONSTANTS.ROUTING_NUMBER);
        this.paBankValidation.payment.paymentInstrument.bankPayment.accountHolderNames =
          [this.achBillPayAliasData.accountHolderName];
        const runDate = new Date();
        this.thankuDataObject.paymentAmount = this.paymentAmount;
        this.paBankValidation.payment.productGroup =
          MakePaymentConstant.productGroup;
        this.paBankValidation.payment.billerCode =
          MakePaymentConstant.billerCode;
        this.paBankValidation.customerReference.customerId = this.banNumber;
        // code added for payment arrangement object

        if (!this.paymentArrangementDisable) {
          var installmentList = [];
          //If 1st installment is past date only 2nd installment will be passed
          if (
            this.isEditFlow &&
            this.installmentInfo[COMMON_CONSTANTS.ZERO].date <=
            this.validateTodayDate(new Date())
          ) {
            installmentList = [
              {
                plannedPaymentId: "2",
                plannedPaymentAmount: Number(
                  this.installmentInfo[COMMON_CONSTANTS.ONE].amount
                    .toString()
                    .replace(",", "")
                ),
                plannedPaymentDate: this.datePipe.transform(
                  this.installmentInfo[COMMON_CONSTANTS.ONE].date,
                  MakePaymentConstant.dateFormatpaymentArrangement
                ),
              },
            ];
          } else {
            installmentList = [
              {
                plannedPaymentId: "1",
                plannedPaymentAmount: Number(
                  this.installmentInfo[COMMON_CONSTANTS.ZERO].amount
                    .toString()
                    .replace(",", "")
                ),
                plannedPaymentDate: this.datePipe.transform(
                  this.installmentInfo[COMMON_CONSTANTS.ZERO].date,
                  MakePaymentConstant.dateFormatpaymentArrangement
                ),
              },
              {
                plannedPaymentId: "2",
                plannedPaymentAmount: Number(
                  this.installmentInfo[COMMON_CONSTANTS.ONE].amount
                    .toString()
                    .replace(",", "")
                ),
                plannedPaymentDate: this.datePipe.transform(
                  this.installmentInfo[COMMON_CONSTANTS.ONE].date,
                  MakePaymentConstant.dateFormatpaymentArrangement
                ),
              },
            ];
          }

          let paymentArrangementCategory = "";
          if (
            !isNullOrUndefined(this.paymentEligibilityResponse) &&
            !isNullOrUndefined(
              this.paymentEligibilityResponse.paymentArrangementCategoryId
            )
          ) {
            paymentArrangementCategory =
              this.paymentEligibilityResponse.paymentArrangementCategoryId;
          }

          const tempPaymentArrangement = [
            {
              // actionCode: actionCode,
              paymentArrangementCategory: paymentArrangementCategory,
              requestPaymentArrangement: "SECURE",
              paymentArrangementType: "REGULAR",
              plannedPayments: installmentList,
              paymentArrangementId: this.paymentArrangementId,
            },
          ];

          if (
            !isNullOrUndefined(
              this._cacheSvc.getFromSessionStorage("phoneNumber")
            )
          ) {
            this.paBankValidation.phoneNumber =
              this._cacheSvc.getFromSessionStorage("phoneNumber");
          }
          this.paBankValidation.payment.paymentArrangements =
            tempPaymentArrangement;
          this.paBankValidation.payment.paTermsAgreementIndicator = true;
          this.paBankValidation.payment.paTermsAgreementTime =
            this.datePipe.transform(
              runDate,
              MakePaymentConstant.dateFormatYYYY
            );
        }
        // end of code added for payment Arrangement
        this.sPValidation = this.paBankValidation;
      } else {
        this.sPBankValidation.payment.paymentMethodReference.paymentMethodCode =
          MakePaymentConstant.paymentMethodBankCode;
        this.sPBankValidation.payment.businessUnit =
          MakePaymentConstant.businessUnit;
        this.sPBankValidation.payment.programCode =
          MakePaymentConstant.programCode;
        this.sPBankValidation.payment.billerCode =
          MakePaymentConstant.billerCode;
        this.sPBankValidation.payment.businessSegment =
          MakePaymentConstant.businessSegment;
        this.sPBankValidation.payment.orderTypes =
          MakePaymentConstant.schAchOrderType;
        this.sPBankValidation.payment.productGroup =
          MakePaymentConstant.productGroup;
        this.sPBankValidation.payment.productGroup =
          MakePaymentConstant.productGroup;
        this.sPBankValidation.payment.billerCode =
          MakePaymentConstant.billerCode;
        this.sPBankValidation.customerReference.customerId =
          this.achSavedData.customerReference.customerId;
        this.sPValidation = this.sPBankValidation;
        this.sPBankValidation.payment.paymentInstrument.bankPayment.accountNumber =
          encrypedNumber;
        this.sPBankValidation.payment.paymentInstrument.bankPayment.bankAccountAlias =
          "";
        if (this.achBillPayAliasData && this.achBillPayAliasData.accountType) {
          if (
            this.achBillPayAliasData.accountType === ACH_CONSTANTS.CHECKING_KEY
          ) {
            this.accountType = this.achBillPayAliasData.accountType.substr(
              0,
              this.achBillPayAliasData.accountType.length - 3
            );
          } else if (
            this.achBillPayAliasData.accountType === ACH_CONSTANTS.SAVING_KEY
          ) {
            this.accountType =
              this.achBillPayAliasData.accountType.substr(
                0,
                this.achBillPayAliasData.accountType.length - 4
              ) + "E";
          }
        }
        this.sPBankValidation.payment.paymentInstrument.bankPayment.accountType =
          this.accountType;
        this.sPBankValidation.payment.paymentInstrument.bankPayment.routingNumber =
        HttpCacheService.GetCache(COMMON_CONSTANTS.ROUTING_NUMBER);
        this.sPBankValidation.payment.paymentInstrument.bankPayment.accountHolderNames =
          [this.achBillPayAliasData.accountHolderName];
        const runDate = new Date();

        this.sPBankValidation.payment.scheduledPayment.schPmtRunDate =
          this.datePipe.transform(runDate, MakePaymentConstant.dateFormatYYYY); // actual
        this.sPBankValidation.payment.scheduledPayment.schPmtDueDate =
          this.datePipe.transform(
            this.paymentDate,
            MakePaymentConstant.dateFormatYYYY
          ); // actual
        this.thankuDataObject.schPmtDueDate =
          this.sPBankValidation.payment.scheduledPayment.schPmtDueDate;
        // check for the payment Amount has comma
        this.sPBankValidation.payment.scheduledPayment.schPmtDueAmt = Number(
          this.paymentAmount.toString().replace(",", "")
        );
        this.sPBankValidation.payment.scheduledpayTermsAgreementIndicator =
          true;
        const currDateTime = new Date();
        const agreementTime = currDateTime.toISOString();
        this.sPBankValidation.payment.scheduledpayTermsAgreementTime =
          agreementTime;

        this.thankuDataObject.paymentAmount = this.paymentAmount;
        this.sPBankValidation.payment.productGroup =
          MakePaymentConstant.productGroup;
        this.sPBankValidation.payment.billerCode =
          MakePaymentConstant.billerCode;
        this.sPBankValidation.customerReference.customerId = this.banNumber;
        this.sPValidation = this.sPBankValidation;
      }
    } else {
      if (this.isHardGoodsEnable) {
        const async=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_ASYNC)==='true';
        if(async){
          this.hgCCValidation.payment.accountTransactionId=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HARDGOOD_AUTH_ASYNC_ACC_TRANS_ID);
        }
        this.hgCCValidation.payment.paymentMethodReference.paymentMethodCode =
          this.paymentMethodCode;
        this.hgCCValidation.payment.businessUnit =
          MakePaymentConstant.businessUnit;
        this.hgCCValidation.payment.programCode =
          MakePaymentConstant.programCode;
        this.hgCCValidation.payment.billerCode = MakePaymentConstant.billerCode;
        this.hgCCValidation.payment.businessSegment =
          MakePaymentConstant.businessSegment;
        this.hgCCValidation.payment.orderTypes =
          this._cacheSvc.getFromSessionStorage(MakePaymentConstant.ORDERTYPE);
        this.hgCCValidation.payment.orderNumber = this._cacheSvc.getFromSessionStorage(MakePaymentConstant.ORDERNUMBER);
        this.hgCCValidation.applicationId =
          MakePaymentConstant.applicationId_Hardgoods;
        this.hgCCValidation.payment.productGroup =
          MakePaymentConstant.productGroupInseego;
        this.hgCCValidation.salesChannelReference.salesChannelId = MakePaymentConstant.salesChannelId;
        this.thankuDataObject.paymentAmount = this.paymentAmount;
        const currDateTime = new Date();
        const agreementTime = currDateTime.toISOString();
        this.hgCCValidation.payment.creationTime = agreementTime;
        this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderName;
        this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderFirstName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderFirstName;
        this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderLastName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderLastName;
        this.hgCCValidation.payment.paymentInstrument.paymentCard.cvv =
        this.encryptedCvv;
        if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(MakePaymentConstant.BAN_TYPE))
         && this._cacheSvc.getFromSessionStorage(MakePaymentConstant.BAN_TYPE) === "IOT" ) {
        this.hgCCValidation.payment.fraudCheckFlag = true;
        this.hgCCValidation.applicationId = MakePaymentConstant.applicationId_Hardgoods_IOT;
          } else {
        this.hgCCValidation.applicationId = MakePaymentConstant.applicationId_Hardgoods;
        this.hgCCValidation.payment.fraudCheckFlag = false;
          }
        this.hgCCValidation.payment.paymentInstrument.paymentCard.typeCode =
          this.cardTypeCode;
        this.hgCCValidation.payment.paymentInstrument.paymentCard.expirationMonthYear =
          this.ccSavedData.payment.paymentInstrument.paymentCard.expirationMonthYear;
        this.hgCCValidation.payment.paymentInstrument.paymentCard.cardNumber =
          encrypedNumber;
        this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1;
        this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city;
        this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state;
        // tslint:disable-next-line:max-line-length
        this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.countryCode =
          MakePaymentConstant.countryCode;
        this.hgCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode;
        this.hgCCValidation.payment.billerCode = MakePaymentConstant.billerCode;
        this.hgCCValidation.customerReference.customerId = this.banNumber;
        //this.hgCCValidation.payment.autoPay.assistingDept = 'Offline Activations';
        //this.hgCCValidation.payment.autoPay.autoPayAcceptance = 'Yes - Verbal Auth';
        // console.log("dee", this.hgCCValidation);
        // end of code added for payment arrangement object for inseego
        this.sPValidation = this.hgCCValidation;
      } else if (this.isInstallmentRadioSelected || this.isEditFlow) {
        this.paCCValidation.salesContext.emailAddress =
          this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)
            ? JSON.parse(
              this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.EUI_TOKEN_DATA
              )
            ).logonId
            : MakePaymentConstant.cardSchemailId;
        this.paCCValidation.salesChannelReference.salesChannelId =
          MakePaymentConstant.cardSchsalesChannelId;
        this.paCCValidation.payment.paymentMethodReference.paymentMethodCode =
          this.paymentMethodCode;
        this.paCCValidation.payment.businessUnit =
          MakePaymentConstant.businessUnit;
        this.paCCValidation.payment.programCode =
          MakePaymentConstant.programCode;
        this.paCCValidation.payment.billerCode = MakePaymentConstant.billerCode;
        this.paCCValidation.payment.businessSegment =
          MakePaymentConstant.businessSegment;
        this.paCCValidation.payment.orderTypes =
          MakePaymentConstant.schAchOrderType;
        this.paCCValidation.payment.productGroup =
          MakePaymentConstant.productGroup;
        if (this.isEditFlow) {
          this.paCCValidation.payment.paymentArrangementAction =
            MakePaymentConstant.paymentArrangementActionEdit;
        } else {
          this.paCCValidation.payment.paymentArrangementAction =
            MakePaymentConstant.paymentArrangementAction;
        }
        const runDate = new Date();
        this.thankuDataObject.paymentAmount = this.paymentAmount;
        this.paCCValidation.payment.paymentInstrument.paymentCard.cardHolderName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderName;
        this.paCCValidation.payment.paymentInstrument.paymentCard.cardHolderFirstName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderFirstName;
        this.paCCValidation.payment.paymentInstrument.paymentCard.cardHolderLastName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderLastName;
        this.paCCValidation.payment.paymentInstrument.paymentCard.cvv =
          this.encryptedCvv;
        // tslint:disable-next-line:max-line-length
        // this.sPCCValidation.payment.paymentInstrument.paymentCard.typeCode = HttpCacheService.GetCache(CREDIT_CARD_CONSTANTS.CARD_TYPE);
        this.paCCValidation.payment.paymentInstrument.paymentCard.typeCode =
          this.cardTypeCode;
        this.paCCValidation.payment.paymentInstrument.paymentCard.expirationMonthYear =
          this.ccSavedData.payment.paymentInstrument.paymentCard.expirationMonthYear;
        this.paCCValidation.payment.paymentInstrument.paymentCard.paymentCardAlias =
          "";
        this.paCCValidation.payment.paymentInstrument.paymentCard.cardNumber =
          encrypedNumber;
        this.paCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1;
        this.paCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2 =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2;
        this.paCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city;
        this.paCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state;
        this.paCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.purposes =
          MakePaymentConstant.purposes;
        // tslint:disable-next-line:max-line-length
        this.paCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.countryCode =
          MakePaymentConstant.countryCode;
        this.paCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode;
        this.paCCValidation.payment.productGroup =
          MakePaymentConstant.productGroup;
        this.paCCValidation.payment.billerCode = MakePaymentConstant.billerCode;
        this.paCCValidation.customerReference.customerId = this.banNumber;

        // code added for payment arrangement object

        if (!this.paymentArrangementDisable) {
          var newInstallmentList = [];
          if (
            this.isEditFlow &&
            this.installmentInfo[COMMON_CONSTANTS.ZERO].date <=
            this.validateTodayDate(new Date())
          ) {
            newInstallmentList = [
              {
                plannedPaymentId: "2",
                plannedPaymentAmount: Number(
                  this.installmentInfo[COMMON_CONSTANTS.ONE].amount
                    .toString()
                    .replace(",", "")
                ),
                plannedPaymentDate: this.datePipe.transform(
                  this.installmentInfo[COMMON_CONSTANTS.ONE].date,
                  MakePaymentConstant.dateFormatpaymentArrangement
                ),
              },
            ];
          } else {
            newInstallmentList = [
              {
                plannedPaymentId: "1",
                plannedPaymentAmount: Number(
                  this.installmentInfo[COMMON_CONSTANTS.ZERO].amount
                    .toString()
                    .replace(",", "")
                ),
                plannedPaymentDate: this.datePipe.transform(
                  this.installmentInfo[COMMON_CONSTANTS.ZERO].date,
                  MakePaymentConstant.dateFormatpaymentArrangement
                ),
              },
              {
                plannedPaymentId: "2",
                plannedPaymentAmount: Number(
                  this.installmentInfo[COMMON_CONSTANTS.ONE].amount
                    .toString()
                    .replace(",", "")
                ),
                plannedPaymentDate: this.datePipe.transform(
                  this.installmentInfo[COMMON_CONSTANTS.ONE].date,
                  MakePaymentConstant.dateFormatpaymentArrangement
                ),
              },
            ];
          }
          let paymentArrangementCategory = "";
          if (
            !isNullOrUndefined(this.paymentEligibilityResponse) &&
            !isNullOrUndefined(
              this.paymentEligibilityResponse.paymentArrangementCategoryId
            )
          ) {
            paymentArrangementCategory =
              this.paymentEligibilityResponse.paymentArrangementCategoryId;
          }

          const tempPaymentArrangement = [
            {
              // actionCode : actionCode,
              paymentArrangementCategory: paymentArrangementCategory,
              requestPaymentArrangement: "SECURE",
              paymentArrangementType: "REGULAR",
              plannedPayments: newInstallmentList,
              paymentArrangementId: this.paymentArrangementId,
            },
          ];
          if (
            !isNullOrUndefined(
              this._cacheSvc.getFromSessionStorage("phoneNumber")
            )
          ) {
            this.paCCValidation.phoneNumber =
              this._cacheSvc.getFromSessionStorage("phoneNumber");
          }
          this.paCCValidation.payment.paymentArrangements =
            tempPaymentArrangement;
          this.paCCValidation.payment.paTermsAgreementIndicator = true;
          this.paCCValidation.payment.paTermsAgreementTime =
            this.datePipe.transform(
              runDate,
              MakePaymentConstant.dateFormatYYYY
            );
        }
        // end of code added for payment arrangement object
        this.sPValidation = this.paCCValidation;
      } else {
        this.sPCCValidation.salesContext.emailAddress =
          this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)
            ? JSON.parse(
              this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.EUI_TOKEN_DATA
              )
            ).logonId
            : MakePaymentConstant.cardSchemailId;
        this.sPCCValidation.salesChannelReference.salesChannelId =
          MakePaymentConstant.cardSchsalesChannelId;
        this.sPCCValidation.payment.paymentMethodReference.paymentMethodCode =
          this.paymentMethodCode;

        this.sPCCValidation.payment.businessUnit =
          MakePaymentConstant.businessUnit;
        this.sPCCValidation.payment.programCode =
          MakePaymentConstant.programCode;
        this.sPCCValidation.payment.billerCode = MakePaymentConstant.billerCode;
        this.sPCCValidation.payment.businessSegment =
          MakePaymentConstant.businessSegment;
        this.sPCCValidation.payment.orderTypes =
          MakePaymentConstant.schAchOrderType;
        this.sPCCValidation.payment.productGroup =
          MakePaymentConstant.productGroup;
        const runDate = new Date();
        this.sPCCValidation.payment.scheduledPayment.schPmtRunDate =
          this.datePipe.transform(runDate, MakePaymentConstant.dateFormatYYYY); // actual
        this.sPCCValidation.payment.scheduledPayment.schPmtDueDate =
          this.datePipe.transform(
            this.paymentDate,
            MakePaymentConstant.dateFormatYYYY
          ); // actual
        this.thankuDataObject.schPmtDueDate =
          this.sPCCValidation.payment.scheduledPayment.schPmtDueDate;
        // check for the payment Amount has comma
        if (this.paymentAmount) {
          this.sPCCValidation.payment.scheduledPayment.schPmtDueAmt = Number(
            this.paymentAmount.toString().replace(",", "")
          );
        }
        this.sPCCValidation.payment.scheduledpayTermsAgreementIndicator = true;
        const currDateTime = new Date();
        const agreementTime = currDateTime.toISOString();
        this.sPCCValidation.payment.scheduledpayTermsAgreementTime =
          agreementTime;
        this.thankuDataObject.paymentAmount = this.paymentAmount;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cardHolderName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderName;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cardHolderFirstName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderFirstName;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cardHolderLastName =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderLastName;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cvv =
          this.encryptedCvv;
        // tslint:disable-next-line:max-line-length
        // this.sPCCValidation.payment.paymentInstrument.paymentCard.typeCode = HttpCacheService.GetCache(CREDIT_CARD_CONSTANTS.CARD_TYPE);
        this.sPCCValidation.payment.paymentInstrument.paymentCard.typeCode =
          this.cardTypeCode;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.expirationMonthYear =
          this.ccSavedData.payment.paymentInstrument.paymentCard.expirationMonthYear;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.paymentCardAlias =
          "";
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cardNumber =
          encrypedNumber;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2 =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.purposes =
          MakePaymentConstant.purposes;
        // tslint:disable-next-line:max-line-length
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.countryCode =
          MakePaymentConstant.countryCode;
        this.sPCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode =
          this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode;
        this.sPCCValidation.payment.productGroup =
          MakePaymentConstant.productGroup;
        this.sPCCValidation.payment.billerCode = MakePaymentConstant.billerCode;
        this.sPCCValidation.customerReference.customerId = this.banNumber;
        this.sPValidation = this.sPCCValidation;
      }
    }
    this.dataConsentCheck = ContentService.GetCache(
      COMMON_CONSTANTS.TERMS_CONSENT
    );
    if (isNullOrUndefined(this.dataConsentCheck)) {
      if (this.autopayTC ) {
        this.termsAndConditionsSvc.recordUserConsent().subscribe(
          (data) => {
            if (
              data.postedDocumentDetails[0].status !==
              COMMON_CONSTANTS.STATUS_FAILURE ||
              data.postedDocumentDetails[1].status !==
              COMMON_CONSTANTS.STATUS_FAILURE
            ) {
              this.dataConsent = data;
              ContentService.SetCache(
                COMMON_CONSTANTS.TERMS_CONSENT,
                this.dataConsent
              );
              this.schedulePayment();
            } else {
              this.schedulePayment();
            }
          },
          (error) => {
            this.schedulePayment();
          }
        );
      } else {
        this.schedulePayment();
      }
    } else {
      this.schedulePayment();
    }
  }

  onSubmit() {
    console.log('Submit Payment Function Called');
    // analytics click tagging
    try{
      this.generateClickDetailsForMakePayment(
        "click",
        "button",
        ClickEventType.ACTION,
        "Submit",
        ""
      );
    } catch(error){
      console.log('generateClickDetailsForMakePayment: catch block');
    }
    if (!this.recaptchaToken) {
      console.log('Recaptcha Step not enabled flow');
      return;
    }
    console.log('Calling putIn Session Storage Step');
    this._cacheSvc.putInSessionStoarage(
      PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL,
      window.location.host + window.location.pathname
    );
    if (this.paymementInfo === MakePaymentConstant.bank) {
      console.log('BP: Bank Payment flow');
      this.billPaySvc.fetchPublicKey().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
        (data: any) => {
          console.log('BP:Fetch Public Key');
          this.isAchEncryptComplete = false;
          this.key1.kty = data.payment.encryption.jwkKey.kty;
          this.key1.e = data.payment.encryption.jwkKey.e;
          this.key1.n = data.payment.encryption.jwkKey.n;
          const account = this;
          encrypt(
            data.payment.encryption.jwkKey,
            HttpCacheService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER_VAL),
            (encryptedData) => {
              console.log('BP: Encrypt Account Number');
              encryptedData = JSON.stringify(encryptedData, undefined, 2);
              account.encrypedNumber = encryptedData.substring(
                1,
                encryptedData.length - 1
              );
              if (
                !isNullOrUndefined(this.encrypedNumber) &&
                !this.isAchEncryptComplete
              ) {
                console.log('BP: Encrypt Account Number completed');
                this.isAchEncryptComplete = true;
                // encryption method Ends
                this.achSavedData.payment.paymentMethodReference.paymentMethodCode =
                  ACH_CONSTANTS.pay_payMethodId;
                this.achSavedData.payment.paymentMethodReference.storePaymentMethodIndicator =
                  false;
                this.achSavedData.payment.clientId = ACH_CONSTANTS.CLIENT_ID;
                this.achSavedData.payment.termsAgreementIndicator = true;
                const currDateTime = new Date();
                const agreementTime = currDateTime.toISOString();
                this.achSavedData.payment.termsAgreementTime = agreementTime;
                this.achSavedData.payment.isTokenRequired = true;
                this.achSavedData.payment.paymentInstrument.bankPayment.accountNumber =
                  this.encrypedNumber;
                this.achSavedData.payment.paymentInstrument.bankPayment.routingNumber =
                HttpCacheService.GetCache(COMMON_CONSTANTS.ROUTING_NUMBER);
                this.achSavedData.payment.customerType =
                  ACH_CONSTANTS.CUSTOMER_TYPE;
                this.achSavedData.payment.productGroup =
                  ACH_CONSTANTS.PRODUCT_GROUP;
                this.achSavedData.payment.channel = ACH_CONSTANTS.CHANNEL_B2B;
                this.achSavedData.payment.businessUnit =
                  ACH_CONSTANTS.BUSINESS_UNIT;
                this.achSavedData.payment.programCode =
                  ACH_CONSTANTS.PROGRAM_CODE;
                this.achSavedData.payment.billerCode =
                  ACH_CONSTANTS.BILLER_CODE;
                this.achSavedData.payment.businessSegment =
                  ACH_CONSTANTS.BUSINESS_SEGMENT;
                this.achSavedData.payment.orderTypes =
                  ACH_CONSTANTS.MNGORDER_TYPE;
                this.achSavedData.payment.storedPaymentIndicator = false;
                this.achSavedData.customerReference.customerId = this.banNumber;
                this.achBillPayAliasData.paymentType =
                  ACH_CONSTANTS.PAYMENT_TYPE;
                this.achBillPayAliasData.accountHolderName =
                  this.achGroup.value.accountName;
                this.achBillPayAliasData.bankAccountAlias = "";
                this.achBillPayAliasData.accountType =
                  this.achGroup.value.accountType;
                this.achBillPayAliasData.routingNumber =
                  this.achGroup.getRawValue().routingNumber;
                this.achBillPayAliasData.billingAccountNumber = this.banNumber;
                this.achBillPayAliasData.orgId = this.orgID;
                console.log('BP: Values related to bank and other info assigned');
                if (this.isInstallmentRadioSelected || this.isEditFlow) {
                  if (this.isEditFlow) {
                    console.log('BP: Edit Payment Arrangement Transaction Type');
                    this.achBillPayAliasData.transactionType =
                      MakePaymentConstant.PA_EDIT_PAYMENT_ARRANGEMENT;
                  } else {
                    console.log('BP: Create Payment Arrangement Transaction Type');
                    this.achBillPayAliasData.transactionType =
                      MakePaymentConstant.PA_CREATE_PAYMENT_ARRANGEMENT;
                  }
                } else {
                  this.achBillPayAliasData.transactionType = this
                    .isOtherPaymentDateSelected
                    ? ACH_CONSTANTS.SCH_TRANSACTION_TYPE
                    : ACH_CONSTANTS.TRANSACTION_TYPE;
                    console.log('BP: '+ this.achBillPayAliasData.transactionType +' Arrangement Transaction Type');
                }

                this.billPayAliasData = this.achBillPayAliasData;       
                console.log('BP: Calling Bill Pay Alias');        
                this.getBillPayAlias(this.encrypedNumber);
              }
            },
            (errorData) => {
              console.log('BP: In error Data loop Account number encryption'); 
              this.errorMessage = ContentService.GetCache("content");
              this.saveSuccess = true;
              this.errorAlert = {
                message: {
                  errorKey: errorData.status ? errorData.status : "",
                  errorMessage: this.errorMessage.error.genericErrorMpTxt,
                  errorType: "Server",
                },
                show: true,
              };
            }
          );
        },
        (err) => {
          console.log('BP: In error loop of Fetch Public key'); 
          this.errorMessage = ContentService.GetCache("content");
          this.saveSuccess = true;
          this.errorAlert = {
            message: {
              errorKey: err ? err.status : "",
              errorMessage: this.errorMessage.error.genericErrorMpTxt,
              errorType: "Server",
            },
            show: true,
          };
        }
      );
    } else {
      console.log('CP: Card Payment flow');
      if (
        this.totalDueNewCardForm.value.expirationMonth !== "" &&
        this.totalDueNewCardForm.value.expirationYear !== ""
      ) {
        console.log('CP: Setting Card Detail Expiration date');
        this.cardDetailObject.expirationDate =
          this.totalDueNewCardForm.value.expirationMonth +
          "/" +
          this.totalDueNewCardForm.value.expirationYear.toString().substr(2, 2);
      }
      this.publicKeySubscription = this.billPaySvc.fetchPublicKey().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
        (data: any) => {
          console.log('CP:Fetch Public Key');
          this._loaderSvc.stop(CREDIT_CARD_CONSTANTS.MICRO_TEXT);
          this.isCCEncryptComplete = false;
          this.key1.kty = data.payment.encryption.jwkKey.kty;
          this.key1.e = data.payment.encryption.jwkKey.e;
          this.key1.n = data.payment.encryption.jwkKey.n;
          const creditCardNumber = this.totalDueNewCardForm.value.actualcreditCardNumber;
          const cvv = this.totalDueNewCardForm.value.cvv
          const card = this;
          encrypt(data?.payment?.encryption?.jwkKey, cvv, 
            (encryptedData) => {
              console.log('CP: Encrypt cvv');
              encryptedData = JSON.stringify(encryptedData, undefined, 2);
              card.encryptedCvv = encryptedData.substring(1, encryptedData.length - 1);
              console.log('CP: Encrypt cvv completed');
              if (!isNullOrUndefined(this.encryptedCvv)) {
                encrypt(data.payment.encryption.jwkKey, creditCardNumber,
                  (encryptedData) => {
                    console.log('CP: Encrypt Credit Card number');
                    encryptedData = JSON.stringify(encryptedData, undefined, 2);
                    card.encrypedNumber = encryptedData.substring(1, encryptedData.length - 1);   
                    console.log('CP: Encrypt credit card completed');          
                    if (!isNullOrUndefined(this.encrypedNumber) && !this.isCCEncryptComplete) {
                      if (!this.isOtherPaymentDateSelected || this.flowType === COMMON_CONSTANTS.AUTOPAY_KEY) {
                        console.log('CP: Other Payment Dt Selected:' + this.isOtherPaymentDateSelected);   
                        console.log('CP: Flow Type:' + this.flowType);   
                        this.ccSavedData.payment.specifications.length = 0;
                        this.ccSpecifications.name =
                          CreditCardConstants.IS_AUTH_REQUIRED;
                        // tslint:disable-next-line:max-line-length
                        this.ccSpecifications.value =
                          CreditCardConstants.IS_AUTH_FALSE;
                        // Below condition commented for US416936 - End
                        this.ccSavedData.payment.specifications.push(
                          this.ccSpecifications
                        );
                      } else if (this.isOtherPaymentDateSelected) {
                        console.log('CP: Other Payment Dt Selected:' + this.isOtherPaymentDateSelected);   
                        this.ccSavedData.payment.specifications.length = 0;
                      }
                      this.ccSavedData.payment.paymentMethodReference.paymentMethodCode =
                        this.paymentMethodCode;
                      this.ccSavedData.payment.paymentMethodReference.storePaymentMethodIndicator =
                        false;
                      this.ccSavedData.payment.termsAgreementIndicator = true;
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cardNumber =
                        this.encrypedNumber;
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderName =
                        this.totalDueNewCardForm.value.nameOnCreditCard;
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderFirstName =
                        this.getFormattedName(
                          this.totalDueNewCardForm.value.nameOnCreditCard,
                          CREDIT_CARD_CONSTANTS.FIRST
                        );
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderLastName =
                        this.getFormattedName(
                          this.totalDueNewCardForm.value.nameOnCreditCard,
                          CREDIT_CARD_CONSTANTS.LAST
                        );
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cvv =
                        this.encryptedCvv;
                      this.ccSavedData.payment.paymentInstrument.paymentCard.typeCode =
                        this.cardTypeCode;
                      this.ccSavedData.payment.paymentInstrument.paymentCard.expirationMonthYear =
                        // tslint:disable-next-line:max-line-length
                        this.totalDueNewCardForm.value.expirationMonth +
                        this.totalDueNewCardForm.value.expirationYear
                          .toString()
                          .substr(2, 2);
                      this.ccSavedData.payment.paymentInstrument.paymentCard.paymentCardAlias =
                        "";
                      // tslint:disable-next-line:max-line-length
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.purposes =
                        MakePaymentConstant.purposes;
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 =
                        this.totalDueNewCardForm.value.ccAddress1;
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2 =
                        this.totalDueNewCardForm.value.ccAddress2;
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city =
                        this.totalDueNewCardForm.value.ccCity;
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state =
                        this.totalDueNewCardForm.value.ccState;
                      // tslint:disable-next-line:max-line-length
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode =
                        this.totalDueNewCardForm.value.ccZip;
                      // tslint:disable-next-line:max-line-length
                      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.countryCode =
                        MakePaymentConstant.countryCode;
                      // tslint:disable-next-line:max-line-length
                      this.ccSavedData.payment.customerType =
                        CreditCardConstants.CUSTOMER_TYPE;
                      this.ccSavedData.payment.productGroup =
                        ACH_CONSTANTS.PRODUCT_GROUP;
                      this.ccSavedData.payment.businessUnit =
                        MakePaymentConstant.businessUnit;
                      this.ccSavedData.payment.programCode =
                        MakePaymentConstant.programCode;
                      this.ccSavedData.payment.billerCode =
                        MakePaymentConstant.billerCode;
                      this.ccSavedData.payment.businessSegment =
                        MakePaymentConstant.businessSegment;
                      this.ccSavedData.payment.orderTypes =
                        ACH_CONSTANTS.MNGORDER_TYPE;
                      this.ccSavedData.payment.storedPaymentIndicator = false;
                      this.ccSavedData.customerReference.customerId = this.banNumber;
                      // tslint:disable-next-line:max-line-length
                      this.ccBillPayAliasData.paymentType =
                        CREDIT_CARD_CONSTANTS.PAYMENT_TYPE;
                      this.ccBillPayAliasData.cardholderName =
                        this.totalDueNewCardForm.value.nameOnCreditCard;
                      // tslint:disable-next-line:max-line-length
                      this.ccBillPayAliasData.cardNumberAlias = "";
                      this.ccBillPayAliasData.expireMonth =
                        this.totalDueNewCardForm.value.expirationMonth;
                      this.ccBillPayAliasData.expireYear =
                        this.totalDueNewCardForm.value.expirationYear;
                      this.ccBillPayAliasData.cardBrand = this.cardTypeCode;
                      this.ccBillPayAliasData.billingAccountNumber = this.banNumber;
                      this.ccBillPayAliasData.orgId = this.orgID;
                      console.log('CP: Values related to Card and other info assigned');
                      if (this.isInstallmentRadioSelected || this.isEditFlow) {
                        if (this.isEditFlow) {
                          this.ccBillPayAliasData.transactionType =
                            MakePaymentConstant.PA_EDIT_PAYMENT_ARRANGEMENT;
                            console.log('CP: Transaction Type:' + this.ccBillPayAliasData.transactionType);
                        } else {
                          this.ccBillPayAliasData.transactionType =
                            MakePaymentConstant.PA_CREATE_PAYMENT_ARRANGEMENT;
                            console.log('CP: Transaction Type:' + this.ccBillPayAliasData.transactionType);
                        }
                      } else {
                        this.ccBillPayAliasData.transactionType = this
                          .isOtherPaymentDateSelected
                          ? ACH_CONSTANTS.SCH_TRANSACTION_TYPE
                          : ACH_CONSTANTS.TRANSACTION_TYPE;
                          console.log('CP: Transaction Type:' + this.ccBillPayAliasData.transactionType);
                      }
                      this.ccBillPayAliasData.billingAddress.address1 =
                        this.totalDueNewCardForm.value.ccAddress1;
                      this.ccBillPayAliasData.billingAddress.city =
                        this.totalDueNewCardForm.value.ccCity;
                      this.ccBillPayAliasData.billingAddress.state =
                        this.totalDueNewCardForm.value.ccState;
                      this.ccBillPayAliasData.billingAddress.zipCode =
                        this.totalDueNewCardForm.value.ccZip;
                      this.billPayAliasData = this.ccBillPayAliasData;
                      console.log('CP: Assign address and alias');
                      if (this.isHardGoodsEnable) {
                        console.log('CP: Hard Goods Enable:'+ this.isHardGoodsEnable);
                        this.submitSchedulePayment(this.encrypedNumber);
                      } else {
                        console.log('CP: Calling Bill Pay Alias');    
                        this.getBillPayAlias(this.encrypedNumber);
                      }
                    }
                  },
                  (errorData) => {
                    console.log('CP: In error Data loop credit number encryption'); 
                    this.errorMessage = ContentService.GetCache("content");
                    this.saveSuccess = true;
                    this.errorAlert = {
                      message: {
                        errorKey: errorData.status ? errorData.status : "",
                        errorMessage: this.errorMessage.error.genericErrorMpTxt,
                        errorType: "Server",
                      },
                      show: true,
                    };
                  }
                );
              }
            },
            (errorData) => {
              console.log('CP: In error Data loop cvv number encryption'); 
              this.errorMessage = ContentService.GetCache("content");
              this.saveSuccess = true;
              this.errorAlert = {
                message: {
                  errorKey: errorData.status ? errorData.status : "",
                  errorMessage: this.errorMessage.error.genericErrorMpTxt,
                  errorType: "Server",
                },
                show: true,
              };
            }
          );
        },
        (err) => {
          console.log('CP: In error loop of Fetch Public key'); 
          this.errorMessage = ContentService.GetCache("content");
          this.saveSuccess = true;
          this.errorAlert = {
            message: {
              errorKey: err ? err.status : "",
              errorMessage: this.errorMessage.error.genericErrorMpTxt,
              errorType: "Server",
            },
            show: true,
          };
          console.log(err);
        }
      );
    }
  }

  getBillPayAliasData() {
    if (
      (this.isInstallmentRadioSelected || this.isEditFlow) &&
      !this.paymentArrangementDisable
    ) {
      let data = Object.assign({}, this.billPayAliasData);
      var installmentList = [];
      //If 1st installment is past date only 2nd installment will be passed
      if (
        this.isEditFlow &&
        this.installmentInfo[COMMON_CONSTANTS.ZERO].date <=
        this.validateTodayDate(new Date())
      ) {
        installmentList = [
          {
            plannedPaymentId: "2",
            plannedPaymentAmount: Number(
              this.installmentInfo[COMMON_CONSTANTS.ONE].amount
                .toString()
                .replace(",", "")
            ),
            plannedPaymentDate: this.datePipe.transform(
              this.installmentInfo[COMMON_CONSTANTS.ONE].date,
              MakePaymentConstant.dateFormatpaymentArrangement
            ),
          },
        ];
      } else {
        installmentList = [
          {
            plannedPaymentId: "1",
            plannedPaymentAmount: Number(
              this.installmentInfo[COMMON_CONSTANTS.ZERO].amount
                .toString()
                .replace(",", "")
            ),
            plannedPaymentDate: this.datePipe.transform(
              this.installmentInfo[COMMON_CONSTANTS.ZERO].date,
              MakePaymentConstant.dateFormatpaymentArrangement
            ),
          },
          {
            plannedPaymentId: "2",
            plannedPaymentAmount: Number(
              this.installmentInfo[COMMON_CONSTANTS.ONE].amount
                .toString()
                .replace(",", "")
            ),
            plannedPaymentDate: this.datePipe.transform(
              this.installmentInfo[COMMON_CONSTANTS.ONE].date,
              MakePaymentConstant.dateFormatpaymentArrangement
            ),
          },
        ];
      }
      data.plannedPayments = installmentList;
      return data;
    } else {
      return this.billPayAliasData;
    }
  }

  getBillPayAlias(encrypedNumber) {
    console.log('GBPA:In the method to get bill pay alias');
    if (!this.isHardGoodsEnable) {
      this.billPaySubscription = this.billPaySvc
        .billPayAliasInfo(this.getBillPayAliasData(), this.orgID)
        .subscribe(
          (billPayRes: any) => {
            // POST call for account-orders
            this._cacheSvc.putInSessionStoarage(
              COMMON_CONSTANTS.TRANS_REF_NUM,
              billPayRes.transRefNum
            );
            this._cacheSvc.putInSessionStoarage(
              COMMON_CONSTANTS.TRANSACTION_ID,
              billPayRes.transactionId
            );
            this.transactionId = billPayRes.transactionId;
            if (this.paymementInfo === MakePaymentConstant.bank) {
              console.log('GBPA:Bank Acct');

              ContentService.SetCache(
                COMMON_CONSTANTS.ACCOUNT_NUMBER,
                HttpCacheService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER_VAL)
              );
              // tslint:disable-next-line:max-line-length
              this.maskedAccount =
                this.maskedChars +
                this.utils.getLastFourDigits(
                  ContentService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER)
                );
            } else {
              console.log('GBPA:Card Pay');
              const card = HttpCacheService.GetCache(
                COMMON_CONSTANTS.CREDITCARD_NUMBER
              ).replace(/ /g, "");
              this.maskedCcNumber =
                this.maskedChars +
                this.utils.getLastFourDigits(
                  HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER)
                );
              ContentService.SetCache(COMMON_CONSTANTS.CC_NUMBER, card);
              this.cardDetailObject.cardNumber = card;
              ContentService.SetCache(
                COMMON_CONSTANTS.CARD_DETAIL_OBJECT,
                this.cardDetailObject
              );
            }
            if (
              this.datePipe.transform(
                this.paymentDate,
                MakePaymentConstant.dateFormat
              ) ===
              this.datePipe.transform(
                this.todayDate,
                MakePaymentConstant.dateFormat
              ) &&
              !this.isInstallmentSelected &&
              !this.isEditFlow
            ) {
              console.log('GBPA:Call Submit Pay');
              this.submitPayment(encrypedNumber);
            } else if (
              this.paymentDate > this.todayDate &&
              !this.isInstallmentSelected &&
              !this.isEditFlow
            ) {
              console.log('GBPA:1 Call Schedule Pay');
              this.submitSchedulePayment(encrypedNumber);
            } else if (this.isInstallmentSelected || this.isEditFlow) {
              // this.datePipe.transform(this.todayDate, MakePaymentConstant.dateFormat)) {
              this._cacheSvc.putInSessionStoarage(
                MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
                MakePaymentConstant.FLOW_TYPE.ONETIME_PAYMENT
              );
              console.log('GBPA:2 Call Schedule Pay');
              this.submitSchedulePayment(encrypedNumber);
            }

          },
          (err) => {
            this.router.navigate([MakePaymentConstant.processError]);
            console.log(err);
          }
        );
    }
  }

  updateCROrder() {
    if (!this.isHardGoodsEnable) {
      const pPayments = this.getBillPayAliasData()?.plannedPayments;
      if (pPayments) {
        this.updateCrOrderObj['plannedPayments'] = pPayments;
      }
      this._paymentSvc.updateCROrder(this.updateCrOrderObj).subscribe(
        (crData) => {
          // PUT call for account-orders
        },
        (error) => { 
          // This is intentional
        }
      );
    }
  }

  getFormattedName(name: string, type: string) {
    const nameArr = name.toString().split(" ");
    if (type === CREDIT_CARD_CONSTANTS.FIRST) {
      if (nameArr.length > CREDIT_CARD_CONSTANTS.TWO) {
        return nameArr[0] + " " + nameArr[1];
      } else {
        return nameArr[0];
      }
    } else {
      if (nameArr.length > CREDIT_CARD_CONSTANTS.ONE) {
        return nameArr[nameArr.length - 1];
      } else {
        return "";
      }
    }
  }

  settingCardBrandMapping(cardBrand) {
    switch (cardBrand) {
      case CardBrands.VISA:
        return CreditCardConstants.VISA_CARD;
      case CardBrands.MASTERCARD:
        return CreditCardConstants.MASTER_CARD;
      case CardBrands.AMERICANEXPRESS:
        return CreditCardConstants.AMERICAN_EXPRESS_CARD;
      default:
        return CreditCardConstants.DISCOVER_CARD;
    }
  }

  showError(err) {
    this.retryRequest(err);
  }

  retryRequest(err) {
    if (err.error && err.error.errorKey) {
      if (err.error.errorKey === ACH_CONSTANTS.ERR_1001) {
        // dispalyed error message in case of BAN block or card block
        this.errorAlert = this._errMsgService.getErrorMessage();
      }
    } else {
      this.retry++;
      this.errorMessage = ContentService.GetCache(
        COMMON_CONSTANTS.CONTENT_CACHE_KEY
      );
      if (this.retry > CREDIT_CARD_CONSTANTS.TWO) {
        this.errorAlert = {
          message: {
            errorKey: err.status,
            errorMessage: this.errorMessage.error.retryCardBankErrorTxt1,
            errorType: ACH_CONSTANTS.ERR_SERVER,
          },
          show: true,
        };
        this.enableRetry = false;
      } else if (this.retry <= CREDIT_CARD_CONSTANTS.TWO) {
        this.errorAlert = {
          message: {
            errorKey: err.status,
            errorMessage: this.errorMessage.error.retryBankErrorTxt,
            errorType: ACH_CONSTANTS.ERR_SERVER,
          },
          show: true,
        };
      }
    }
  }

  onCancel() {
    // analytics click tagging
    this.generateClickDetailsForMakePayment(
      "click",
      "button",
      ClickEventType.NAVIGATE,
      "Cancel",
      ""
    ); //button at bottom
    if (this.isBillingPartnerFlow) {
      this.utils.redirectToPartnerBillingApp();
    } else {
      setTimeout(() => {
        this.utils.return(this.returnURI, true);
      }, 800);
    }
  }

  getUpdatedBalence(obj, schObj) { 
    let schAmnt: any;
    let scheduledDate: any;
    // BILLING INFO
    const autoPay =
      obj.statusInfo.autoPayStatus.toLowerCase() ===
        MakePaymentConstant.optIn ||
        obj.statusInfo.autoPayStatus.toLowerCase() === true
        ? MakePaymentConstant.txtTrue
        : MakePaymentConstant.txtFalse;
    const billInfo = obj.billInfo;
    const billDuedate = new Date(billInfo.dueDate);
    this.updatedBalence.icon = false;
    // SCHEDULED Obj  INFO
    if (
      schObj &&
      !isNullOrUndefined(
        schObj.scheduledPaymentAmount && schObj.scheduledPaymentDate
      )
    ) {
      schAmnt = schObj.scheduledPaymentAmount;
      scheduledDate = new Date(schObj.scheduledPaymentDate);
    }

    if (autoPay === MakePaymentConstant.txtTrue) {
      this.listsAll.push({ text: "Autopay", id: 1, className: "autopay-date" });
    }
    //  Auto Msg Manipulations
    if (autoPay === MakePaymentConstant.txtFalse && !schObj) {
      // case 1
      this.updatedBalence.text = "";
    } else if (autoPay === MakePaymentConstant.txtFalse && schObj) {
      // case 2
      // tslint:disable-next-line:max-line-length
      this.updatedBalence.text =
        this.authoringContent.paymentAmount.scheduledApOffTxt.replace(
          MakePaymentConstant.amountPlaceholder,
          this.currencyPipe.transform(schAmnt, MakePaymentConstant.usd)
        ) +
        this.datePipe.transform(
          scheduledDate,
          MakePaymentConstant.dateFormatMMMM
        ) +
        ".";
    } else if (
      autoPay === MakePaymentConstant.txtTrue &&
      obj.balanceinfo.arBalance &&
      obj.balanceinfo.arBalance > 0 &&
      !schObj
    ) {
      // CASE 3
      // tslint:disable-next-line:max-line-length
      this.updatedBalence.text =
        this.authoringContent.paymentAmount.noScheduleApOnTxt.replace(
          MakePaymentConstant.amountPlaceholder,
          this.currencyPipe.transform(
            obj.balanceinfo.arBalance,
            MakePaymentConstant.usd
          )
        );
    } else if (
      autoPay === MakePaymentConstant.txtTrue &&
      obj.balanceinfo.arBalance &&
      obj.balanceinfo.arBalance > 0 &&
      schObj
    ) {
      // tslint:disable-next-line:max-line-length
      this.updatedBalence.text =
        this.authoringContent.paymentAmount.scheduledApOnTxt1.replace(
          MakePaymentConstant.amountPlaceholder,
          this.currencyPipe.transform(schAmnt, MakePaymentConstant.usd)
        ) +
        this.authoringContent.paymentAmount.scheduledApOnTxt2.replace(
          "[SCHEDULEDUEDATE]",
          this.datePipe.transform(
            scheduledDate,
            MakePaymentConstant.dateFormatMMMM
          )
        );
    } else if (
      autoPay === "true" &&
      obj.balanceinfo.arBalance &&
      obj.balanceinfo.arBalance <= 0
    ) {
      this.updatedBalence.text =
        this.authoringContent.paymentAmount.noBalApOnTxt;
    }

    // Payment Amount manipulations - US474841
    // case 1 (arBalance < 0 )
    if (
      obj.balanceinfo.arBalance &&
      obj.balanceinfo.arBalance < "0.0"
    ) {
      this.enableBalanceRadioButton(false, true, false);
      this.payAmntDefRadiomsg =
        this.authoringContent.paymentAmount.accountCreditTxt.replace(
          MakePaymentConstant.amountPlaceholder,
          this.currencyPipe.transform(
            obj.balanceinfo.arBalance,
            MakePaymentConstant.usd
          )
        );
    } else if (parseFloat(obj.balanceinfo.arBalance) === 0) {
      // case 2 (arBalance == 0)
      this.enableBalanceRadioButton(false, true, false);
      this.payAmntDefRadiomsg =
        this.authoringContent.paymentAmount.noPaymentDueTxt;
    } else if (
      parseFloat(obj.balanceinfo.arBalance) > 0.0 &&
      parseFloat(obj.balanceinfo.arBalance) <
      MakePaymentConstant.smallBalance
    ) {
      // case 3: small
      this.isAmount = true;
      this.enableBalanceRadioButton(false, true, true);
      this.payAmntDefRadio =
        this.authoringContent.paymentAmount.defPayRadioTxt.replace(
          MakePaymentConstant.amountPlaceholder,
          this.currencyPipe.transform(
            obj.balanceinfo.arBalance,
            MakePaymentConstant.usd
          )
        );
      this.payAmntDefRadio =
        this.payAmntDefRadio +
        " " +
        this.authoringContent.paymentAmount.dueDateTxt.replace(
          MakePaymentConstant.dueDate,
          this.datePipe.transform(
            billDuedate,
            MakePaymentConstant.dateFormatMMMM
          )
        );
      this.payAmntDefRadiomsg =
        this.authoringContent.paymentAmount.smallAmountMessage;
      this.isOtherRadioSelected = true;
      this.isOtherSelected = true;
      this.isOtherAmount = true;
      this.paymentAmount = "2.00";
    } else if (
      parseFloat(obj.balanceinfo.arBalance) >
      MakePaymentConstant.smallBalance
    ) {
      if (
        parseFloat(obj.balanceinfo.totalPastDueAmount) === 0 ||
        obj.balanceinfo.totalPastDueAmount < "0.0"
      ) {
        // case 4 : Total due
        this.enableBalanceRadioButton(false, true, false);
        // tslint:disable-next-line:max-line-length
        this.totalDueRadio =
          this.authoringContent.paymentAmount.defPayRadioTxt.replace(
            MakePaymentConstant.amountPlaceholder,
            this.currencyPipe.transform(
              obj.balanceinfo.arBalance,
              "USD"
            )
          );
        this.totalDueText = this.authoringContent.paymentAmount.totalDue;
        this.isTotalSelected = true;
        this.totalDue = true;
        this.isOtherRadioSelected = false;
      } else {
        if (
          parseFloat(obj.balanceinfo.totalDueAmount) === 0 ||
          parseFloat(obj.balanceinfo.totalDueAmount) < 0.0
        ) {
          // late payment
          this.enableBalanceRadioButton(true, true, false);
          // tslint:disable-next-line:max-line-length
          this.payAmntDefRadio =
            this.authoringContent.paymentAmount.defPayRadioTxt.replace(
              MakePaymentConstant.amountPlaceholder,
              this.currencyPipe.transform(
                obj.balanceinfo.arBalance,
                "USD"
              )
            );
          this.dueNowText = this.authoringContent.paymentAmount.dueNowTxt;
          this.payAmntDefRadiomsg =
            this.authoringContent.paymentAmount.payImmediatelyTxt;
          this.isInformation = false;
          this.isLatePayment = true;
          this.isDefaultSelected = true;
        } else {
          // late + due
          this.isDefaultSelected = false;
          this.isTotalSelected = true;
          if (this.hasTotalDueMsg) {
            this.isOtherRadioSelected = true
          }
          // tslint:disable-next-line:max-line-length
          this.totalDueRadio =
            this.authoringContent.paymentAmount.totalDueRadioTxt.replace(
              MakePaymentConstant.amountPlaceholder,
              this.currencyPipe.transform(
                obj.balanceinfo.arBalance,
                "USD"
              )
            );
          this.totalDue = true;
          this.totalDueText = this.authoringContent.paymentAmount.totalDue;
          this.enableBalanceRadioButton(true, true, false);
          this.payAmntDefRadio =
            this.authoringContent.paymentAmount.defPayRadioTxt.replace(
              MakePaymentConstant.amountPlaceholder,
              this.currencyPipe.transform(
                obj.balanceinfo.totalPastDueAmount,
                MakePaymentConstant.usd
              )
            );
          this.dueNowText = this.authoringContent.paymentAmount.dueNowTxt;
          this.payAmntDefRadiomsg =
            this.authoringContent.paymentAmount.payImmediatelyTxt;
          this.isInformation = false;
        }
      }
    }
  }

  enableBalanceRadioButton(
    isDefault: boolean,
    isOther: boolean,
    isSmallBalance
  ) {
    this.isDefaultBalance = isDefault;
    this.isOtherAmount = isOther;
    this.isSmallBalance = isSmallBalance;
  }

  onSuccessCredit(evt, type) {
    this.saveSuccess = evt.response;
    if (evt.response && type === COMMON_CONSTANTS.TYPE_ACH) {
      this.isAchSaved = true;
    }
    if (evt.response && type === COMMON_CONSTANTS.TYPE_CREDIT) {
      const len = evt.cardNumber.length - 4;
      this.maskedAccount = evt.cardNumber.slice(len);
    }
    this.enableSubmitButton();
  }

  onApplySelectngx(evt) {
    this.dateSelect(evt);
    this.ngxdp.hide();
    this.nameField.nativeElement.focus();
  }
  addEvent(type: string, evt: MatDatepickerInputEvent<Date>) {
    this.dateSelect(evt.value);
    this.nameField.nativeElement.focus();
  }
  processPayment() {
    console.log('PP: In method Process Payment');
    this._paymentSvc.makePayment(this.mPValidation).subscribe(
      (response) => { 
        // POST call of processpayments
        console.log('PP: Response received make Payment');
        if (
          environment.ENABLE_PARTNER &&
          this.isHardGoodsEnable &&
          this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.IS_PARTNER_BOARD
          ) === "true"
        ) {
          if (
            !isNullOrUndefined(response) &&
            !isNullOrUndefined(response.payment) &&
            !isNullOrUndefined(response.payment.messages) &&
            !isNullOrUndefined(response.payment.messages[0].redirectUrl)
          ) {
            this._cacheSvc.putInSessionStoarage(
              COMMON_CONSTANTS.RETURN_URL_SSP,
              response.payment.messages[0].redirectUrl
            );
            console.log('PP: Put redirect URL in Session');
          }
        }

        if (
          this.retry > 0 &&
          !isNullOrUndefined(response["status"]) &&
          !isNullOrUndefined(response["status"].statusCode) &&
          response["status"].statusCode ===
          MakePaymentConstant.APPROVED_STATUS &&
          response["reason"].reasonCode === "8033" &&
          this._errMsgService.getErrorMessage().show
        ) {
          console.log('PP: Unsetting Error Msg');
          this._errMsgService.unSettingErrorMsg();
        }

        // Process Payment API Error Handling - 1st and 2nd Attempts
        // if (this.errorCounter < 2) {
        //   if (response.payment.messages[0].uiShortCode === this.authoringContent.error.processStatusCode) {
        //     // tslint:disable-next-line: max-line-length
        //     if (response.reason.reasonCode === this.authoringContent.error.processCvvReasonCodeTxt || response.reason.reasonCode === this.authoringContent.error.processAddressReasonCodeTxt) {
        //       this.errorCounter++;
        //       this._paymentSvc.processPaymentErrorCounter.next(this.errorCounter);
        //       this.PaymentErrorModel.openModal();
        //     }
        //   }
        // }

        if (this._errMsgService.getErrorMessage().show) {
          console.log('PP: Get Error Msg Show');
          this.retry++;
          this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
          this.updateCrOrderStatusDesc = response["payment"]
            ? response["payment"].messages[0].uiAction
            : this.errorMessage.error.genericErrorMpTxt;
          this.updateCROrderId(response);
          this.updateCROrder();
          this.saveSuccess = false;
          const negativeBanReasonCodeList = _.split(
            this.authoringContent.error.processNegativeBanReasonCodeTxt,
            ","
          );
          if (
            !isNullOrUndefined(
              this.errorAlert &&
              this.errorAlert.message &&
              this.errorAlert.message.errorKey
            ) &&
            negativeBanReasonCodeList.includes(this.errorAlert.message.errorKey)
          ) {
            this.enableRetry = false;
            this._paymentSvc.makePaymenttoAch(true);
            this._paymentSvc.makePaymenttoCC(true);
            console.log('PP:1 Navigate Process Error');
            this.router.navigate([MakePaymentConstant.processError]);
          } else {
            if (this.retry > CREDIT_CARD_CONSTANTS.TWO) {
              this.enableRetry = false;
              console.log('PP:2 Navigate Process Error');
              this.router.navigate([MakePaymentConstant.processError]);
            } else {
              if (
                negativeBanReasonCodeList.includes(
                  response["reason"].reasonCode
                )
              ) {
                this.enableRetry = false;
                console.log('PP:3 Navigate Process Error');
                this.router.navigate([MakePaymentConstant.processError]);
              } else {
                const errorListCode = "8183,8184,8330";
                if (errorListCode.includes(response["reason"].reasonCode)) {
                  this.enableRetry = true;
                  this._paymentSvc.processPaymentErrorCounter.next(this.retry);
                  console.log('PP:4 Open Payment Error Modal');
                  this.PaymentErrorModel.openModal();
                } else {
                  this.enableRetry = false;
                  console.log('PP:5 Navigate Process Error');
                  this.router.navigate([MakePaymentConstant.processError]);
                }
              }
            }
          }
          return false;
        } else if (
          !isNullOrUndefined(response["status"]) &&
          !isNullOrUndefined(response["status"].statusCode) &&
          response["status"].statusCode ===
          MakePaymentConstant.APPROVED_STATUS &&
          response["reason"].reasonCode === "8033"
        ) {
          this.thankuDataObject.paymentResponse = response;
          this.thankuDataObject.fromWhichPayment =
            MakePaymentConstant.paymentTxt;
          ContentService.SetCache(
            MakePaymentConstant.makepaymentResponse,
            this.thankuDataObject
          );
          this.updateCROrderId();
          this.updateCROrder();
          console.log('PP:6 Navigate Process Error');
          this.router.navigate([MakePaymentConstant.thankuUrl]);
        }
      },
      (err) => {
        this.errorMessage = ContentService.GetCache(
          COMMON_CONSTANTS.CONTENT_CACHE_KEY
        );
        this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
        if (!isNullOrUndefined(err.error.errors)) {
          this.updateCrOrderStatusDesc = err.error.errors[0].systemMessage
            ? err.error.errors[0].systemMessage
            : err.error.errors[0].userMessage;
            console.log('PP:1 Error loop updateCROrder');
        } else {
          if (!isNullOrUndefined(err.error.systemMessage)) {
            this.updateCrOrderStatusDesc = err.error.systemMessage;
            console.log('PP:2 Error loop updateCROrder');
          } else {
            this.updateCrOrderStatusDesc = err.error.error
            ? err.error.error
            : this.errorMessage.error.genericErrorMpTxt;
            console.log('PP:3 Error loop updateCROrder');
          }
        }
        this.updateCROrderId();
        this.updateCROrder();
        this.saveSuccess = false;
        console.log('PP:7 Navigate Process Error');
        this.router.navigate([MakePaymentConstant.processError]);
      }
    );
  }
  getFlowType() {
    if(this.isInstallmentSelected && !this.isEditFlow){
      return FLOW_TYPE.CREATE_PA;
    } else if(this.isInstallmentSelected && this.isEditFlow){
      return FLOW_TYPE.EDIT_PA;
    } else if(this.isHardGoodsEnable){
      return FLOW_TYPE.HARD_GOODS_AUTH;
    } else if(this.paymentDate > this.todayDate){
      return FLOW_TYPE.SCHEDULE_PAYMENT;
    }
  }
  schedulePayment() {
    const async=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_ASYNC)==='true';
    this._schPaySvc.managePayment(this.sPValidation, this.recaptchaToken, this.getFlowType()).subscribe(
      (response) => {
        if (
          environment.ENABLE_PARTNER &&
          this.isHardGoodsEnable &&
          this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.IS_PARTNER_BOARD
          ) === "true"
        ) {
          if (
            !isNullOrUndefined(response) &&
            !isNullOrUndefined(response.payment) &&
            !isNullOrUndefined(response.payment.messages) &&
            !isNullOrUndefined(response.payment.messages[0].redirectUrl) &&
            !async
          ) {
            this._cacheSvc.putInSessionStoarage(
              COMMON_CONSTANTS.RETURN_URL_SSP,
              response.payment.messages[0].redirectUrl
            );
          }
        }
        let errorCode = "";
        if ((response?.responseCodeList?.length >0 && response?.responseCodeList[0]?.errors?.responseErrors[0]?.code)) {
          errorCode = response?.responseCodeList[0]?.errors?.responseErrors[0]?.code;
        }
        if (
          this.retry > 0 &&
          !isNullOrUndefined(
            response && response["reason"] && response.reason.reasonCode
          ) &&
          response.reason.reasonCode ===
          MakePaymentConstant.APPROVED_MPI_CODE &&
          !response["responseCodeList"] &&
          this._errMsgService.getErrorMessage().show
        ) {
          this._errMsgService.unSettingErrorMsg();
        }

        if (this._errMsgService.getErrorMessage().show) {
          this.retry++;
          this.saveSuccess = false;

          // Actual User Message from API to the PUT account Order API
          this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
          this.updateCrOrderStatusDesc =
            response?.responseCodeList?.[0].errors?.responseErrors?.[0].userMessage || "";

          this.updateCROrderId(response);
          this.updateCROrder();
          const negativeBanReasonCodeList = _.split(
            this.authoringContent?.error?.negativeFileCheckcommonErrorCode,
            ","
          );
          if (
            !isNullOrUndefined(
              this.errorAlert &&
              this.errorAlert.message &&
              this.errorAlert.message.errorKey
            ) &&
            negativeBanReasonCodeList.includes(this.errorAlert.message.errorKey)
          ) {
            this.enableRetry = false;
            this._paymentSvc.makePaymenttoAch(true);
            this._paymentSvc.makePaymenttoCC(true);
            if (this.isIOTBanType ) {
              const param = { b2bRequestId: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.B2B_REQUESTID),
                            cartId: this._cacheSvc.getFromSessionStorage(MakePaymentConstant.ORDERNUMBER),
                            code: response?.responseCodeList[0]?.errors?.responseErrors[0]?.code,
                            userMessage: response?.responseCodeList[0]?.errors?.responseErrors[0]?.userMessage};
               this.redirectService.redirectToPartner(param, response.payment.messages[0].redirectUrl);
             } else if (this.isHardGoodsEnable && !async && !this.isIOTBanType ) {
              window.location.href = response.payment.messages[0].redirectUrl;
            } else {
              this.router.navigate([MakePaymentConstant.processError]);
            }
          } else {
            if (this.retry > CREDIT_CARD_CONSTANTS.TWO) {
              this.enableRetry = false;
              if (this.isIOTBanType ) {
                const param = { b2bRequestId: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.B2B_REQUESTID),
                            cartId: this._cacheSvc.getFromSessionStorage(MakePaymentConstant.ORDERNUMBER),
                            code: response?.responseCodeList[0]?.errors?.responseErrors[0]?.code,
                            userMessage: response?.responseCodeList[0]?.errors?.responseErrors[0]?.userMessage };
                this.redirectService.redirectToPartner(param, response.payment.messages[0].redirectUrl);
               } else if (this.isHardGoodsEnable && !async && !this.isIOTBanType) {
                window.location.href = response.payment.messages[0].redirectUrl;
              } else {
                this.router.navigate([MakePaymentConstant.processError]);
              }
            } else {
              if (
                this.isHardGoodsEnable &&
                !isNullOrUndefined(
                  response && response["reason"] && response.reason.reasonCode
                ) &&
                response.reason.reasonCode ===
                MakePaymentConstant.INSEEGO_NEGATIVE_FILE_ERROR &&
                negativeBanReasonCodeList.includes(errorCode)
              ) {
                if (this.isIOTBanType ) {
                  const param = {b2bRequestId: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.B2B_REQUESTID),
                              cartId: this._cacheSvc.getFromSessionStorage(MakePaymentConstant.ORDERNUMBER),
                              code: response?.responseCodeList[0]?.errors?.responseErrors[0]?.code,
                              userMessage: response?.responseCodeList[0]?.errors?.responseErrors[0]?.userMessage };
                  this.redirectService.redirectToPartner(param, response.payment.messages[0].redirectUrl);
                    } else {
                    window.location.href = response.payment.messages[0].redirectUrl;
                   }
              } else {
                if (negativeBanReasonCodeList.includes(errorCode)) {
                  this.router.navigate([MakePaymentConstant.processError]);
                } else {
                  let errorListCode = "8183,8184,8330";
                  if (errorListCode.includes(errorCode)) {
                    this.enableRetry = true;
                    this._paymentSvc.processPaymentErrorCounter.next(
                      this.retry
                    );
                    this.PaymentErrorModel.openModal();
                  } else if (errorCode === "2185") { // error code for sheduled payment pending
                    this.enableRetry = false;
                    this.router.navigate([MakePaymentConstant.processError], {
                      queryParams: { status: "pending" },
                    });
                  } else {
                    if (this.isIOTBanType ) {
                      const param = {b2bRequestId: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.B2B_REQUESTID),
                           cartId: this._cacheSvc.getFromSessionStorage(MakePaymentConstant.ORDERNUMBER),
                           code: response?.responseCodeList[0]?.errors?.responseErrors[0]?.code,
                           userMessage: response?.responseCodeList[0]?.errors?.responseErrors[0]?.userMessage };
                       this.redirectService.redirectToPartner(param, response.payment.messages[0].redirectUrl);
                    } else if (this.isHardGoodsEnable && !async && !this.isIOTBanType) {
                      window.location.href =
                        response.payment.messages[0].redirectUrl;
                    } else {
                      this.enableRetry = false;
                      this.router.navigate([MakePaymentConstant.processError]);
                    }
                  }
                }
              }
            }
          }
          if (this.retry > CREDIT_CARD_CONSTANTS.TWO) {
            this.enableRetry = false;
          }

          return false;
        } else if (
          !isNullOrUndefined(
            response && response["reason"] && response.reason.reasonCode
          ) &&
          response.reason.reasonCode ===
          MakePaymentConstant.APPROVED_MPI_CODE &&
          !response["responseCodeList"]
        ) {
          this.thankuDataObject.paymentResponse = response;
          if (this.isInstallmentRadioSelected || this.isEditFlow) {
            this.thankuDataObject.paymentInfo = this.paymementInfo;
            this.thankuDataObject.payInInstallment = true;
            this.thankuDataObject.paymentInstallmentInfo = this.installmentInfo;
          }
          this.thankuDataObject.fromWhichPayment =
            MakePaymentConstant.schedulePayment;
          ContentService.SetCache(
            MakePaymentConstant.makepaymentResponse,
            this.thankuDataObject
          );
          this.updateCROrderId();
          this.updateCROrder();
          if (this.isIOTBanType ) {
            const param = {b2bRequestId: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.B2B_REQUESTID),
                          cartId: this._cacheSvc.getFromSessionStorage(MakePaymentConstant.ORDERNUMBER),
                          paymentId: response.payment.paymentId,
                          paymentCardAlias: response.payment.paymentInstrument.paymentCard.paymentCardAlias };
            this.redirectService.redirectToPartner(param, response.payment.messages[0].redirectUrl);
         } else if (this.isHardGoodsEnable  && !async && !this.isIOTBanType) {
            window.location.href = response.payment.messages[0].redirectUrl;
          } else {
            this.router.navigate([MakePaymentConstant.thankuUrl]);
          }
        } else {
          const errorListCode = "8183,8184,8330";
          if (
            this.retry < 2 &&
            !isNullOrUndefined(response["status"]) &&
            !isNullOrUndefined(response["status"].statusCode) &&
            response["status"].statusCode === "DECLINED" &&
            response["reason"].reasonCode === "2492" &&
            errorListCode.includes(errorCode)
          ) {
            this.enableRetry = true;
            this._paymentSvc.processPaymentErrorCounter.next(this.retry);
            this.PaymentErrorModel.openModal();
          } else {
            if (this.isIOTBanType) {
              const param = {b2bRequestId: this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.B2B_REQUESTID),
                           cartId: this._cacheSvc.getFromSessionStorage(MakePaymentConstant.ORDERNUMBER),
                           code: response?.responseCodeList[0]?.errors?.responseErrors[0]?.code,
                           userMessage: response?.responseCodeList[0]?.errors?.responseErrors[0]?.userMessage };
              this.redirectService.redirectToPartner(param, response.payment.messages[0].redirectUrl);
            } else if (this.isHardGoodsEnable && !async && !this.isIOTBanType) {
               window.location.href = response.payment.messages[0].redirectUrl;
            } else {
              this.enableRetry = false;
              this.router.navigate([MakePaymentConstant.processError]);
            }
          }
        }
      },
      (err) => {
        this.errorMessage = ContentService.GetCache(
          COMMON_CONSTANTS.CONTENT_CACHE_KEY
        );
        this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
        if (
          !isNullOrUndefined(err.error.errors) &&
          isNullOrUndefined(err.error.errors.error)
        ) {
          this.updateCrOrderStatusDesc = err.error.errors[0].systemMessage
            ? err.error.errors[0].systemMessage
            : err.error.errors[0].userMessage;
        } else if (
          !isNullOrUndefined(err.error.errors) &&
          !isNullOrUndefined(err.error.errors.error)
        ) {
          this.updateCrOrderStatusDesc = err.error.errors.error[0].systemMessage
            ? err.error.errors.error[0].systemMessage
            : err.error.errors.error[0].userMessage;
        } else {
          if (!isNullOrUndefined(err.error.systemMessage)) {
            this.updateCrOrderStatusDesc = err.error.systemMessage;
          } else {
            this.updateCrOrderStatusDesc = err.error.error
            ? err.error.error
            : this.errorMessage.error.genericErrorMpTxt;
          }
        }
        this.saveSuccess = true;
        this.updateCROrderId();
        this.updateCROrder();
        if (this.isHardGoodsEnable && !async) {
          window.location.href = this.returnURI;
        } else {
          this.router.navigate([MakePaymentConstant.processError]);
        }
      }
    );

    this.enableSubmitButton();
  }

  updateCROrderId(response?: any) { 
    if (
      this.datePipe.transform(
        this.paymentDate,
        MakePaymentConstant.dateFormat
      ) ===
      this.datePipe.transform(this.todayDate, MakePaymentConstant.dateFormat)
    ) {
      // UPdate CR Order Object starts
      if (this.paymementInfo === MakePaymentConstant.bank) {
        if (
          !isNullOrUndefined(
            this.thankuDataObject &&
            this.thankuDataObject.paymentResponse &&
            this.thankuDataObject.paymentResponse.payment.paymentInstrument
          )
        ) {
          this.mPUpdateCrOrder.bankAccountAlias = !isNullOrUndefined(
            this.thankuDataObject.paymentResponse.payment.paymentInstrument
          )
            ? this.thankuDataObject.paymentResponse.payment.paymentInstrument
              .bankPayment.bankAccountAlias
            : "";
        } else if (
          !isNullOrUndefined(
            response && response.payment && response.payment.paymentInstrument
          )
        ) {
          this.mPUpdateCrOrder.bankAccountAlias = !isNullOrUndefined(
            response.payment.paymentInstrument
          )
            ? response.payment.paymentInstrument.bankPayment.bankAccountAlias
            : "";
        }
      } else {
        if (
          !isNullOrUndefined(
            this.thankuDataObject &&
            this.thankuDataObject.paymentResponse &&
            this.thankuDataObject.paymentResponse.payment.paymentInstrument
          )
        ) {
          // tslint:disable-next-line:max-line-length
          this.mPUpdateCrOrder.cardNumberAlias = !isNullOrUndefined(
            this.thankuDataObject.paymentResponse.payment.paymentInstrument
          )
            ? this.thankuDataObject.paymentResponse.payment.paymentInstrument
              .paymentCard.paymentCardAlias
            : "";
        } else if (
          !isNullOrUndefined(
            response && response.payment && response.payment.paymentInstrument
          )
        ) {
          this.mPUpdateCrOrder.cardNumberAlias = !isNullOrUndefined(
            response.payment.paymentInstrument
          )
            ? response.payment.paymentInstrument.paymentCard.cardNumberAlias
            : "";
        }
      }
      this.mPUpdateCrOrder.billingAccount = this.banNumber;
      const euiTokenData=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA);
      this.mPUpdateCrOrder.logonId = euiTokenData ? JSON.parse(euiTokenData).logonId : '';
      this.mPUpdateCrOrder.emailNotification.firstName=euiTokenData ? JSON.parse(euiTokenData)?.firstName : '';
      this.mPUpdateCrOrder.emailNotification.emailId=this.mPUpdateCrOrder.logonId;
      // tslint:disable-next-line:max-line-length
      this.mPUpdateCrOrder.status = this.thankuDataObject.paymentResponse
        ? "S"
        : this.updateCrOrderStatus;
      this.mPUpdateCrOrder.transactionDesc =
        // tslint:disable-next-line:max-line-length
        this.thankuDataObject.paymentResponse
          ? this.thankuDataObject.paymentResponse.paymentTransactionResponse?.statusMessage
          : this.updateCrOrderStatusDesc;
      // check for the payment Amount has comma
      if (!this.isHardGoodsEnable) {
        this.mPUpdateCrOrder.chargeAmount = Number(
          this.paymentAmount.toString().replace(",", "")
        );
      }

      if (
        !isNullOrUndefined(this.dataConsent) &&
        !isNullOrUndefined(this.dataConsent.postedDocumentDetails)
      ) {
        for(let dataConsentPostedDocumentDetails of this.dataConsent.postedDocumentDetails) {
          const element = {};
          element["docId"] = !isNullOrUndefined(
            dataConsentPostedDocumentDetails.ingestDocumentId
          )
            ? dataConsentPostedDocumentDetails.ingestDocumentId
            : "";
          element["docType"] = !isNullOrUndefined(
            dataConsentPostedDocumentDetails.ingestDocumentId
          )
            ? dataConsentPostedDocumentDetails.documentType
            : "";
          element['acceptedDateTime'] = this.termsAndConditionsSvc.findAndConvertAcceptedTimeStampByDocumentId(dataConsentPostedDocumentDetails.documentId);
          this.consentArray.push(element);
        }
        this.mPUpdateCrOrder.legalDocuments = this.consentArray;
      } else if (!this.dataConsent) {
        this.mPUpdateCrOrder.legalDocuments.push(this.legalDocument);
      }

      this.updateCrOrderObj = this.mPUpdateCrOrder;
      // UPdate CR Order Object Ends
    } else if (this.paymentDate > this.todayDate) {
      // UPdate CR Order Object starts
      if (this.paymementInfo === MakePaymentConstant.bank) {
        if (
          !isNullOrUndefined(
            this.thankuDataObject &&
            this.thankuDataObject.paymentResponse &&
            this.thankuDataObject.paymentResponse.payment.paymentInstrument
          )
        ) {
          this.sPUpdateCrOrder.bankAccountAlias = !isNullOrUndefined(
            this.thankuDataObject.paymentResponse.payment.paymentInstrument
          )
            ? this.thankuDataObject.paymentResponse.payment.paymentInstrument
              .bankPayment.bankAccountAlias
            : "";
        } else if (
          !isNullOrUndefined(
            response && response.payment && response.payment.paymentInstrument
          )
        ) {
          this.sPUpdateCrOrder.bankAccountAlias = !isNullOrUndefined(
            response.payment.paymentInstrument
          )
            ? response.payment.paymentInstrument.bankPayment.bankAccountAlias
            : "";
        }
      } else {
        if (
          !isNullOrUndefined(
            this.thankuDataObject &&
            this.thankuDataObject.paymentResponse &&
            this.thankuDataObject.paymentResponse.payment.paymentInstrument
          )
        ) {
          this.sPUpdateCrOrder.cardNumberAlias = !isNullOrUndefined(
            this.thankuDataObject.paymentResponse.payment.paymentInstrument
          )
            ? this.thankuDataObject.paymentResponse.payment.paymentInstrument
              .paymentCard.paymentCardAlias
            : "";
        } else if (
          !isNullOrUndefined(
            response && response.payment && response.payment.paymentInstrument
          )
        ) {
          this.sPUpdateCrOrder.cardNumberAlias = !isNullOrUndefined(
            response.payment.paymentInstrument
          )
            ? response.payment.paymentInstrument.paymentCard.cardNumberAlias
            : "";
        }
      }
      this.sPUpdateCrOrder.billingAccount = this.banNumber;

      const euiTokenData=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA);
      this.sPUpdateCrOrder.logonId = euiTokenData ? JSON.parse(euiTokenData).logonId : '';
      this.sPUpdateCrOrder.emailNotification.firstName=euiTokenData ? JSON.parse(euiTokenData)?.firstName : '';
      this.sPUpdateCrOrder.emailNotification.emailId=this.sPUpdateCrOrder.logonId;
      // tslint:disable-next-line:max-line-length
      this.sPUpdateCrOrder.status = this.thankuDataObject.paymentResponse
        ? "S"
        : this.updateCrOrderStatus;
      this.sPUpdateCrOrder.transactionDesc =
        // tslint:disable-next-line:max-line-length
        this.thankuDataObject.paymentResponse
          ? this.thankuDataObject.paymentResponse.paymentTransactionResponse?.statusMessage
          : this.updateCrOrderStatusDesc;
      if (!isNullOrUndefined(this.thankuDataObject.paymentResponse)) {
        this.sPUpdateCrOrder.scheduledPayment.scheduledPaymentId = this.thankuDataObject
          .paymentResponse
          ? this.thankuDataObject.paymentResponse.payment.paymentId
          : "";
      } else if (!isNullOrUndefined(response)) {
        this.sPUpdateCrOrder.scheduledPayment.scheduledPaymentId = response.payment
          ? response.payment.paymentId
          : "";
      }

      this.sPUpdateCrOrder.scheduledPayment.scheduledPaymentDate = this.datePipe.transform(
        this.paymentDate,
        MakePaymentConstant.dateFormatYYYY
      );
      // check for the payment Amount has comma
      this.sPUpdateCrOrder.chargeAmount = Number(
        this.paymentAmount.toString().replace(",", "")
      );
      if (
        !isNullOrUndefined(this.dataConsent) &&
        !isNullOrUndefined(this.dataConsent.postedDocumentDetails)
      ) {
        for (let dataConsentPostedDocumentDetails of this.dataConsent.postedDocumentDetails) {
          const element = {};
          element["docId"] = !isNullOrUndefined(
            dataConsentPostedDocumentDetails.ingestDocumentId
          )
            ? dataConsentPostedDocumentDetails.ingestDocumentId
            : "";
          element["docType"] = !isNullOrUndefined(
            dataConsentPostedDocumentDetails.ingestDocumentId
          )
            ? dataConsentPostedDocumentDetails.documentType
            : "";
          element['acceptedDateTime'] = this.termsAndConditionsSvc.findAndConvertAcceptedTimeStampByDocumentId(dataConsentPostedDocumentDetails.documentId);
          this.consentArray.push(element);
        }
        this.sPUpdateCrOrder.legalDocuments = this.consentArray;
      } else if (!this.dataConsent) {
        this.sPUpdateCrOrder.legalDocuments.push(this.legalDocument);
      }
      this.updateCrOrderObj = this.sPUpdateCrOrder;
      // UPdate CR Order Object Ends
    }
  }

  onEntrust() {
    this.entrustUrl = this.authoringContent.landingInfo.enTrustLogoUrl.replace(
      "[host]",
      location.host
    );
    window.open(
      this.entrustUrl,
      "",
      "width=537,height=457,left=19%,top=50,sceenX=19%,screenY=50"
    );
  }

  onEntrustPrivacy() {
    window.location.href = this.authoringContent.landingInfo.enTrustPrivacyUrl;
  }

  secondInstallment() {
    this.secondInstalment = !this.secondInstalment;
    this.firstInstallmentflag = false;
    const dateObject = {
      secondInstalment: this.secondInstalment,
      installmentDates: this.installmentDates,
      isRangeDatePicker: true,
      choosePayDateFirstInstTxt: this.choosePayDateFirstInstTxt,
      choosePayDateSecondInstTxt: this.choosePayDateSecondInstTxt,
    };
    this._paymentSvc.isSecondCaledarOpen(dateObject);
    this._paymentSvc.sendSelectedDate(this.installmentDates);
  }

  firstInstallment() {
    this.firstInstallmentflag = !this.firstInstallmentflag;
    this.secondInstalment = false;
    const dateObject = {
      secondInstalment: this.secondInstalment,
      installmentDates: this.installmentDates,
      isRangeDatePicker: true,
      choosePayDateFirstInstTxt: this.choosePayDateFirstInstTxt,
      choosePayDateSecondInstTxt: this.choosePayDateSecondInstTxt,
    };
    this._paymentSvc.isSecondCaledarOpen(dateObject);
    this._paymentSvc.sendSelectedDate([this.installmentDates[0]]);
  }

  firstInstallmentApply() {
    this.firstInstallmentflag = false;
  }

  secondInstallmentApply() {
    this.secondInstalment = false;
  }

  enableSubmitButton() {
    if (this.isInstallmentSelected === true) {
      let installmentInfoCheck = true;
      this.installmentInfo.forEach((installment) => {
        if (installment.amount < CREDIT_CARD_CONSTANTS.ONE) {
          installmentInfoCheck = false;
        }
      });

      if (installmentInfoCheck) {
        this.enableSubmitByInstallment = false;
        this.paymentAmount =
          Number(this.installmentInfo[COMMON_CONSTANTS.ZERO].amount) +
          Number(this.installmentInfo[COMMON_CONSTANTS.ONE].amount);
      }
    }
  }

  installmentDateChanges(event) {
    if (
      !isNullOrUndefined(this.installmentDates[0] && event.value) &&
      this.firstInstallmentDateModal !== event.value
    ) {
      this.firstInstallmentDateModal = event.value;
      this.displayFirstinstallmentDate = this.validateTodayDate(
        this.firstInstallmentDateModal
      );
      this.installmentInfo[COMMON_CONSTANTS.ZERO].date = this.validateTodayDate(
        this.firstInstallmentDateModal
      );
      this.secondInstallmentDate = new Date(event.value);
      this.secondInstallmentDate = new Date(
        this.secondInstallmentDate.setDate(
          this.secondInstallmentDate.getDate() +
          Number(this.maxDaysBetweenInstallments)
        )
      );
      this.secondInstallmentDateModal = this.secondInstallmentDate;
      this.displaySecondinstallmentDate = this.validateTodayDate(
        this.secondInstallmentDateModal
      );

      this.installmentInfo[COMMON_CONSTANTS.ONE].date = this.validateTodayDate(
        this.secondInstallmentDateModal
      );
      this.secondInsMinDate = new Date(this.firstInstallmentDateModal);
      this.secondInsMinDate = new Date(
        this.secondInsMinDate.setDate(
          this.firstInstallmentDateModal.getDate() + 1
          )
          );
       this.secondInsMaxDate = new Date(this.firstInstallmentDateModal);
        this.secondInsMaxDate = new Date(
        this.secondInsMaxDate.setDate(
          this.firstInstallmentDateModal.getDate() +
          Number(this.maxDaysBetweenInstallments)
          )
         );
      this.installmentDates = [];
      this.installmentDates.push(event.value);
      this.installmentDates.push(this.secondInstallmentDateModal);
    }

  }

  secondInstallmentSelectionDate(secondInstallMentDate) {
    if (
      !isNullOrUndefined(secondInstallMentDate.value) &&
      this.secondInstallmentDateModal !== secondInstallMentDate.value
    ) {
      this.secondInstallmentDateModal = secondInstallMentDate.value;
      this.displaySecondinstallmentDate = this.validateTodayDate(
        this.secondInstallmentDateModal
      );
      this.installmentInfo[COMMON_CONSTANTS.ONE].date = this.validateTodayDate(
        this.secondInstallmentDateModal
      );
      this.installmentDates = [];
      this.installmentDates.push(this.firstInstallmentDateModal);
      this.installmentDates.push(secondInstallMentDate.value);
    }
    this.isSecondCalendarDblClick = false;
    if (this.firstInstallmentflag) {
      this.secondInsMinDate = new Date(this.firstInstallmentDateModal);
      this.secondInsMinDate = new Date(
        this.secondInsMinDate.setDate(
          this.firstInstallmentDateModal.getDate() + 1
        )
      );
      this.secondInsMaxDate = new Date(this.firstInstallmentDateModal);
      this.secondInsMaxDate = new Date(
        this.secondInsMaxDate.setDate(
          this.firstInstallmentDateModal.getDate() +
          Number(this.maxDaysBetweenInstallments)
        )
      );
      this._paymentSvc.sendSelectedDate([this.installmentDates[0]]);
      if (
        !isNullOrUndefined(
          this.secondInstallmentDateModal &&
          this.secondInsMinDate &&
          this.secondInsMaxDate
        ) &&
        (this.firstInstallmentDateModal >= this.secondInstallmentDateModal ||
          this.secondInstallmentDateModal > this.secondInsMaxDate)
      ) {
        this.displaySecondinstallmentDate = null;
        this.secondInstallmentDateModal = null;
      }
    } else {
      this._paymentSvc.sendSelectedDate(this.installmentDates);
    }
  }
  changeInstallmentValue(amount) {
    let tempAmt: any = amount.value;
    if (tempAmt.includes(",")) {
      tempAmt = tempAmt.replace(/,/g, "");
    }
    this.installmentInfo[COMMON_CONSTANTS.ZERO].amount = +tempAmt;
    this.firstInstallmentAmount = +tempAmt;
    this.showMessageSecondInstallmet = false;
    const elegiblefirstInstallmentAmountVal =
      (this.paymentArrangementsResponse *
        this.paymentEligibilityResponse["minPercentageForFirstInstallment"]) /
      COMMON_CONSTANTS.HUNDRED;
    this.elegiblefirstInstallmentAmount = Number(
      this.decimalPipe.transform(elegiblefirstInstallmentAmountVal, "1.0-2")
    );

    if (
      this.firstInstallmentAmount < this.elegiblefirstInstallmentAmount ||
      this.firstInstallmentAmount == null
    ) {
      this.errorFirstInstallment = true;
      this.secondInstallmentAmount =
        this.paymentArrangementsResponse - this.firstInstallmentAmount;
      this.selectingSecondAmountAccordingFirst();
    } else if (
      this.firstInstallmentAmount >= this.elegiblefirstInstallmentAmount &&
      this.firstInstallmentAmount <= this.paymentArrangementsResponse
    ) {
      this.secondInstallmentAmount =
        this.paymentArrangementsResponse - this.firstInstallmentAmount;
      this.errorFirstInstallment = false;
      this.selectingSecondAmountAccordingFirst();
    } else if (this.firstInstallmentAmount > this.paymentArrangementsResponse) {
      this.secondInstallmentAmount = COMMON_CONSTANTS.FORMATED_ZERO;
      this.errorFirstInstallment = true;
      this.selectingSecondAmountAccordingFirst();
    }
    // analytics click tagging
    this.generateClickDetailsForMakePayment(
      "click",
      "input",
      ClickEventType.ACTION,
      "First Payment Arrangement Installment Entry",
      ""
    );
  }

  formattingFirstfieldAndSecondFieldValue() {
    if (
      !isNullOrUndefined(this.secondInstallmentAmount) &&
      this.secondInstallmentAmount !== 0
    ) {
      this.secondInstallmentAmount = this.currencyPipe
        .transform(this.secondInstallmentAmount, MakePaymentConstant.usd)
        .replace("$", "");
      this.installmentInfo[COMMON_CONSTANTS.ONE].amount =
        this.secondInstallmentAmount.replace(",", "");
    }
    if (
      !isNullOrUndefined(this.firstInstallmentAmount) &&
      this.firstInstallmentAmount !== 0
    ) {
      this.firstInstallmentAmount = this.currencyPipe
        .transform(this.firstInstallmentAmount, MakePaymentConstant.usd)
        .replace("$", "");
      this.installmentInfo[COMMON_CONSTANTS.ZERO].amount =
        this.firstInstallmentAmount.replace(",", "");
    }
    if (this.secondInstallmentAmount === 0) {
      this.secondInstallmentAmount = COMMON_CONSTANTS.FORMATED_ZERO;
    }
    if (this.firstInstallmentAmount === 0) {
      this.firstInstallmentAmount = COMMON_CONSTANTS.FORMATED_ZERO;
    }
  }

  selectingSecondAmountAccordingFirst() {
    const totalAmountEnterd = Number(
      this.decimalPipe
        .transform(
          this.firstInstallmentAmount + this.secondInstallmentAmount,
          "1.0-2"
        )
        .replace(",", "")
    );

    if (totalAmountEnterd > this.paymentArrangementsResponse) {
      this.showMessageSecondInstallmet = true;
      this.messageSecondInstallment =
        this.authoringContent.paymentAmount.messageSecondInstallment.replace(
          "[pastDue]",
          this.currencyPipe.transform(
            this.paymentArrangementsResponse,
            MakePaymentConstant.usd
          )
        );
    } else {
      this.showMessageSecondInstallmet = false;
    }
    if (
      this.secondInstallmentAmount <
      this.paymentArrangementsResponse - this.firstInstallmentAmount
    ) {
      if (
        !isNullOrUndefined(
          this.authoringContent && this.authoringContent.paymentDate
        )
      ) {
        this.secondPaymentFieldMsg =
          this.authoringContent.paymentDate.secondPaymentFieldMsg.replace(
            "[remElegiblePastDue]",
            this.currencyPipe.transform(
              this.paymentArrangementsResponse - this.firstInstallmentAmount,
              MakePaymentConstant.usd
            )
          );
      }
      this.errorSecondInstallment = true;
    } else {
      this.errorSecondInstallment = false;
    }
    this.formattingFirstfieldAndSecondFieldValue();
  }

  changeInstallmentValueSecond(amount) {
    let tempAmt: any = amount.value;
    if (tempAmt.includes(",")) {
      tempAmt = tempAmt.replace(/,/g, "");
    }
    if (this.firstInstallmentAmount.toString().includes(",")) {
      this.firstInstallmentAmount = this.firstInstallmentAmount.replace(
        /,/g,
        ""
      );
    }
    this.installmentInfo[COMMON_CONSTANTS.ONE].amount = +tempAmt;
    this.secondInstallmentAmount = +tempAmt;
    this.firstInstallmentAmount = +this.firstInstallmentAmount;
    if (
      this.secondInstallmentAmount <
      this.paymentArrangementsResponse - this.firstInstallmentAmount
    ) {
      if (
        !isNullOrUndefined(
          this.authoringContent && this.authoringContent.paymentDate
        )
      ) {
        this.secondPaymentFieldMsg =
          this.authoringContent.paymentDate.secondPaymentFieldMsg.replace(
            "[remElegiblePastDue]",
            this.currencyPipe.transform(
              this.paymentArrangementsResponse - this.firstInstallmentAmount,
              MakePaymentConstant.usd
            )
          );
      }
      this.errorSecondInstallment = true;
    } else {
      this.errorSecondInstallment = false;
    }
    this.selectingSecondAmountAccordingFirst();
    // analytics click tagging
    this.generateClickDetailsForMakePayment(
      "click",
      "input",
      ClickEventType.ACTION,
      "Second Payment Arrangement Installment Entry",
      ""
    );
  }

  calculatingMinAmmount() {
    if (!this.isEditFlow && isNullOrUndefined(this.paymentArrangementsResponse) && !isNullOrUndefined(this.paymentAmount)) {
      this.paymentArrangementsResponse = this.paymentAmount;
    }
    if (
      !isNullOrUndefined(
        this.paymentEligibilityResponse && this.paymentArrangementsResponse
      ) &&
      !this.isMiAmountCalculated
    ) {
      this.isMiAmountCalculated = true;
      this.firstInstallmentAmount =
        (this.paymentArrangementsResponse *
          this.paymentEligibilityResponse["minPercentageForFirstInstallment"]) /
        COMMON_CONSTANTS.HUNDRED;
      if (isNullOrUndefined(this.firstInstFieldMsgPercent)) {
        this.firstInstFieldMsgPercent =
          this.authoringContent.paymentAmount.firstPaymentFieldMsgPercent.replace(
            "[minPercent]",
            this.paymentEligibilityResponse
              .minPercentageForFirstInstallment
          );
      }
      this.firstInstFieldMsgPercent = this.firstInstFieldMsgPercent.replace(
        "[minAmount]",
        this.currencyPipe.transform(
          this.firstInstallmentAmount,
          MakePaymentConstant.usd
        )
      );
      this.secondInstallmentAmount =
        this.paymentArrangementsResponse - this.firstInstallmentAmount;
    }
    this.formattingFirstfieldAndSecondFieldValue();
  }

  closeDiv(divReference) {
    this.showBanner = false;
  }

  setToken(recaptchaToken: string): void {
    this.recaptchaToken = recaptchaToken;
  }

 disableHelp(){
   console.log(
       !this.MPIphoneNumberCheck + "\n" +
       !this.cardTypeVal  + "\n" +
       !this.luhnCheck  + "\n" +
       !this.enableRetry  + "\n" +
       !this.formValid  + "\n" +
       this.saveSuccess  + "\n" +
       this.amountError  + "\n" +
       !this.paymentDue  + "\n" +
       this.careAuthType + "\n" +
       (this.paymentAmount < 2 ) + "\n" +
       (this.paymentAmount > 25000  )+ "\n" +
       !this.cardTypeVal + "\n" +
       !this.luhnCheck  + "\n" +
       !this.enableRetry  + "\n" +
       !this.formValid + "\n" +
       this.isPaymentArrangementReadOnly )
 }
  disableRecaptcha(): boolean {
    let isDisabled: boolean;
    switch (this.submissionType) {
      case MakePaymentSubmissionFlow.DEFAULT:
        isDisabled =
          !this.MPIphoneNumberCheck ||
          !this.cardTypeVal ||
          !this.luhnCheck ||
          !this.enableRetry ||
          !this.formValid ||
          this.saveSuccess ||
          this.amountError ||
          !this.paymentDue ||
          this.careAuthType ||
          this.paymentAmount < 2 ||
          this.paymentAmount > 25000 ||
          this.isPaymentArrangementReadOnly;
        break;
      case MakePaymentSubmissionFlow.HARDGOODS:
        isDisabled =
          !this.cardTypeVal ||
          !this.luhnCheck ||
          !this.enableRetry ||
          !this.formValid;
        break;
      case MakePaymentSubmissionFlow.INSTALLMENT_EDIT:
        isDisabled =
          !this.MPIphoneNumberCheck ||
          this.showMessageSecondInstallmet ||
          this.errorSecondInstallment ||
          this.errorFirstInstallment ||
          !this.cardTypeVal ||
          !this.luhnCheck ||
          !this.enableRetry ||
          !this.formValid ||
          this.saveSuccess ||
          this.amountError ||
          !this.paymentDue ||
          this.careAuthType ||
          this.paymentAmount < 2 ||
          this.paymentAmount > 25000 ||
          this.isPaymentArrangementReadOnly;
        break;
      case MakePaymentSubmissionFlow.INSTALLMENT_NONEDIT:
        isDisabled =
          !this.MPIphoneNumberCheck ||
          !this.formValid ||
          this.amountError ||
          this.careAuthType ||
          this.isPaymentArrangementReadOnly;
        break;
      default:
        isDisabled = true;
    }
    return isDisabled;
  }

  private setPaymentSubmissionType() { // extract nested ternary operation into independent statement
    if (!this.isEditFlow) {
      this.submissionType = this.isHardGoodsEnable
        ? MakePaymentSubmissionFlow.HARDGOODS
        : this.isInstallmentSelected
          ? MakePaymentSubmissionFlow.INSTALLMENT_NONEDIT
          : MakePaymentSubmissionFlow.DEFAULT;
    } else {
      this.submissionType = MakePaymentSubmissionFlow.INSTALLMENT_EDIT;
    }
  }

  generateClickDetailsForMakePayment(
    actionInput: string,
    assetTypeInput: string,
    typeInput: ClickEventType,
    ctaNameInput: string,
    modalNameInput: string
  ) {
    try{
      console.log('GCDFMP: In the function generateClickDetailsForMakePayment');
      let clickDetail = {
        action: actionInput,
        assetType: assetTypeInput,
        type: typeInput,
        ctaName: ctaNameInput,
        modalName: modalNameInput,
      };
      console.log('Before calling generateClickTag');
      this.pdlService.generateClickTag(clickDetail);
    }
    catch(error){
      console.log('GCDFMP: generateClickDetailsForMakePayment: catch block');
    }
  }
  onInstallmentOpen(picker){
    picker?.id === "mat-datepicker-0"?this._cacheSvc.putInSessionStoarage(
      "firstInstallment",
      true
    ) :this._cacheSvc.putInSessionStoarage(
      "secondInstallment",
      true
    );
    this.appendInstallmenFooter();
  }
  dateClass = (d: any) => {
    if(this.firstInstallmentDateModal.getFullYear() === d.getFullYear() &&
      this.firstInstallmentDateModal.getDate() === d.getDate() &&
      this.firstInstallmentDateModal.getMonth() === d.getMonth()){
       return 'custom-date-class-first'
    }
    if(this.secondInstallmentDateModal.getFullYear() === d.getFullYear() &&
      this.secondInstallmentDateModal.getDate() === d.getDate() &&
      this.secondInstallmentDateModal.getMonth() === d.getMonth()){
      return  'custom-date-class-second'
    }
    const fromDate = Date.parse(this.firstInstallmentDateModal);
    const toDate = Date.parse(this.secondInstallmentDateModal);
    const betweenDate = Date.parse(d);
    if(betweenDate<toDate && betweenDate>fromDate){
      return 'custom-date-class-between'
    }
  }
  private appendInstallmenFooter(){
    const matCalendar = document.getElementsByClassName(
      "mat-datepicker-content"
    )[0] as HTMLElement;
    matCalendar.appendChild(this.datepickerInstallmentFooter.nativeElement);
  }
  
  onApply() {
    this.bsValue = new Date();
    this.datepicker.close();
  }
}
@Component({
    selector: "custom-payment-header",
    styleUrls: ["./make-payment.component.scss"],
    template: `
  <div class="header" *ngIf ="showDates">
  <span >Choose Payment Dates</span>
  <button class ="close"
  (click)="close()">x</button>
  </div>
  <div class="msg" *ngIf ="isFirstInstallment">
  {{firstInstallmentMsg}}
  </div>
  <div class="msg" *ngIf ="isSecondInstallment">
  {{secondInstallmentMsg}}
  </div>
    <div class="custom-payment-header">
      <button
      class ="icon"
        (click)="previousClicked('month')"
      >
        <
      </button>
      <span class="custom-payment-header-label">{{ periodLabel }}</span>
      <button
      class ="icon"
        (click)="nextClicked('month')"
      >
        >
      </button>
    </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf],
})
export class CustomPaymentHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  firstInstallmentMsg:string;
  secondInstallmentMsg:string;
  firstInstallment :boolean;
  secondInstallment:boolean;

  constructor(
    private _cacheSvc: HttpCacheService,
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    private _datePicker:MatDatepicker<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
      this.firstInstallment =this._cacheSvc.getFromSessionStorage('firstInstallment')?this._cacheSvc.getFromSessionStorage('firstInstallment'):false;
      this.secondInstallment = this._cacheSvc.getFromSessionStorage('secondInstallment')?this._cacheSvc.getFromSessionStorage('secondInstallment'):false;

  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
    this._cacheSvc.deleteFromSessionStoarage('firstInstallment');
    this._cacheSvc.deleteFromSessionStoarage('secondInstallment');
  }
  get showDates(){   
    return this.firstInstallment || this.secondInstallment; 
  }
  get isFirstInstallment(){
    const data = JSON.parse(this._cacheSvc.getFromSessionStorage('contentmain'))
    this.firstInstallmentMsg=data.par.common_payment.paymentAmount.choosePayDateFirstInstTxt.replace(
      "[maxDaysBeforeFirstInstallment]",this._cacheSvc?.getFromSessionStorage('maxDaysBeforeFirstInstallment')
    );
    return this.firstInstallment;
  }
  get isSecondInstallment(){
    const data = JSON.parse(this._cacheSvc.getFromSessionStorage('contentmain'))
    this.secondInstallmentMsg=data.par.common_payment.paymentAmount.choosePayDateSecondInstTxt.replace(
      "[maxDaysBetweenInstallment]",this._cacheSvc?.getFromSessionStorage('maxDaysBetweenInstallments')
    );
    return this.secondInstallment;
  }

  get periodLabel() {
    return this._dateAdapter.format(
      this._calendar.activeDate,
      this._dateFormats.display.monthYearA11yLabel
    );
  }

  previousClicked(mode: "month" | "year") {
    
    this._calendar.activeDate =
      mode === "month"
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: "month" | "year") {
    this._calendar.activeDate =
      mode === "month"
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
  close(){
    this._datePicker.close();
    this._calendar.ngOnDestroy();
  }
}
