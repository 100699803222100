import { DatePipe, CurrencyPipe, DecimalPipe, NgIf, NgClass, NgStyle } from "@angular/common";
import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { PnpClientService } from "../../pnpframework/public-api";
import {
  ACH_CONSTANTS,
  COMMON_CONSTANTS,
  CreditCardConstants,
  CREDIT_CARD_CONSTANTS,
  EipPaymentConstants,
  MakePaymentConstant,
  PDL_CONSTANTS,
} from "../common/core/common.constants";
import { ClickEventType } from "../common/core/common.interface";
import {
  CCBillPayAliasObj,
  CCEipPaymentModal,
  EipDeviceDetails,
  EncryptionKey,
  MakePaymentSubmissionFlow,
  PaymentValidation,
  UpdateCrOrderPaymentModel,
} from "../common/models/data.model";
import { PaymentErrorPopupComponent } from "../common/payment-error-popup/payment-error-popup.component";
import { AutoPaymentService } from "../services/auto-payment.service";
import { BillPayService } from "../services/bill-pay.service";
import { ContentService } from "../services/content.service";
import { EipPaymentService } from "../services/eip-payment.service";
import { EnvironmentDetailsService } from "../services/environment-details.service";
import {
  ErrorMessageService,
  ServiceMessage,
} from "../services/error-message.service";
import { HttpCacheService } from "../services/http-cache.service";
import { LoaderService } from "../services/loader.service";
import { MakePaymentService } from "../services/make-payment.service";
import { PDLService } from "../services/pdl.service";
import { RedirectService } from "../services/redirect.service";
import { SetupRouteService } from "../services/setup-route.service";
import { UtilsService } from "../services/utils.service";
import { SplunkUtilityService } from "../utilities/splunk-utility.service";
import { PipedOperatorsService } from "../common/pipes/customOperators/pipedOperatorsService.service";
import { PhonePipe } from "../common/pipes/phone.pipe";
import { FooterComponent } from "../footer/footer.component";
import { RecaptchaV2Component } from "../common/recaptcha-v2/recaptcha-v2.component";
import { CreditCardComponent } from "../credit-card/credit-card.component";
import { NumbersOnlyDirective } from "../directive/numbers-only.directive";
import { FormsModule } from "@angular/forms";
import { HeaderComponent } from "../header/header.component";
import {isNullOrUndefined} from "../utilities/helper-utils";

declare const encrypt;

@Component({
    selector: "app-eip-standalone-payment",
    templateUrl: "./eip-standalone-payment.component.html",
    styleUrls: ["./eip-standalone-payment.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        HeaderComponent,
        NgClass,
        NgStyle,
        FormsModule,
        NumbersOnlyDirective,
        CreditCardComponent,
        RecaptchaV2Component,
        PaymentErrorPopupComponent,
        FooterComponent,
        CurrencyPipe,
        DatePipe,
        PhonePipe,
    ],
})
export class EipStandalonePaymentComponent implements OnInit, OnDestroy {
  _cacheSvc: HttpCacheService;
  _envSvc: EnvironmentDetailsService;
  _errMsgService: ErrorMessageService;
  _paymentSvc: MakePaymentService;
  _loaderSvc: LoaderService;
  _routeService: SetupRouteService;
  contentService: ContentService;
  _eipPaymentSvc: EipPaymentService;
  _autoPaymentSvc: AutoPaymentService;
  router: Router;
  datePipe: DatePipe;
  billPaySvc: BillPayService;
  sanitizer: DomSanitizer;
  currencyPipe: CurrencyPipe;
  decimalPipe: DecimalPipe;
  utils: UtilsService;
  splunkSvc: SplunkUtilityService;
  pdlService: PDLService;
  redirectService: RedirectService;
  pnpClientService: PnpClientService;
  @ViewChild(PaymentErrorPopupComponent)
  PaymentErrorModel: PaymentErrorPopupComponent | any;

  deviceDetails: EipDeviceDetails = new EipDeviceDetails();
  paymentDate: Date;
  additionalAmount: any = "";
  showPartnerError = false;
  show: any;
  errorType: string;
  errorMessage: any;
  isAccessTokenStored: boolean;
  isRedirect: boolean;
  isPartnerFlow: boolean = false;
  isBillingPartnerFlow: boolean = false;
  cardTypeCode: any;
  cardTypeVal: boolean = true;
  paymentMethodCode: any;
  luhnCheck: boolean = true;
  formValid: boolean;
  totalDueNewCardForm: any;
  maskedAccount = "";
  maskedChars: string;
  cardDetailObject: any;
  walkMeIn_userID: any;
  careAuthType: any;
  orgID: any;
  banNumber: any;
  thankuDataObject: any;
  titleLabel: any;
  authoringContent: any;
  banDetails: any;
  returnURI: string;
  submissionType = MakePaymentSubmissionFlow.DEFAULT;
  isAdditionalAmount: boolean = true;
  balanceAmount: any;
  paymentAmount: any;
  isSymbol: boolean = false;
  amountError: any;
  showSubHeadertxt: boolean;
  transactionId: any;
  maskedCcNumber: string;
  retry = CREDIT_CARD_CONSTANTS.ZERO;
  isRDC = false;
  enablePartner = false;

  ccSavedData: PaymentValidation = new PaymentValidation();
  ccBillPayAliasData: CCBillPayAliasObj = new CCBillPayAliasObj();
  mPUpdateCrOrder: UpdateCrOrderPaymentModel = new UpdateCrOrderPaymentModel();
  eipCCValidation: CCEipPaymentModal = new CCEipPaymentModal();
  errorAlert: ServiceMessage = { message: null, show: false };
  entrustUrl: any;
  recaptchaToken: string;
  enableRetry = true;
  saveSuccess = false;
  isCCEncryptComplete: boolean;
  key1: EncryptionKey = new EncryptionKey();
  encryptedNumber: any;
  encryptedCvv: any;
  updateCrOrderStatus: any;
  updateCrOrderStatusDesc: any;
  enableEip = false;
  makeEIPPermission : boolean = false;
  content: any;

  private _getDeviceDetails$: Subscription;
  private _getBanDetails$: Subscription;
  private _publicKeySubscription$: Subscription;
  private _billPaySubscription$: Subscription;
  private _updateTransactionSubscription$: Subscription;
  private _eipPaymentSubscription$: Subscription;

  constructor(private injector: Injector, private pipedOperatorsService: PipedOperatorsService) {
    this.isCCEncryptComplete = false;
    this.maskedChars = COMMON_CONSTANTS.MASKING_CHARACTERS;
    this._cacheSvc = injector.get<HttpCacheService>(HttpCacheService);
    this._envSvc = injector.get<EnvironmentDetailsService>(
      EnvironmentDetailsService
    );
    this._errMsgService =
      injector.get<ErrorMessageService>(ErrorMessageService);
    this._paymentSvc = injector.get<MakePaymentService>(MakePaymentService);
    this._eipPaymentSvc = injector.get<EipPaymentService>(EipPaymentService);
    this._cacheSvc = injector.get<HttpCacheService>(HttpCacheService);
    this._loaderSvc = injector.get<LoaderService>(LoaderService);
    this._routeService = injector.get<SetupRouteService>(SetupRouteService);
    this.contentService = injector.get<ContentService>(ContentService);
    this._autoPaymentSvc = injector.get<AutoPaymentService>(AutoPaymentService);
    this.router = injector.get<Router>(Router);
    this.datePipe = injector.get<DatePipe>(DatePipe);
    this.billPaySvc = injector.get<BillPayService>(BillPayService);
    this.sanitizer = injector.get<DomSanitizer>(DomSanitizer);

    this.currencyPipe = injector.get<CurrencyPipe>(CurrencyPipe);
    this.decimalPipe = injector.get<DecimalPipe>(DecimalPipe);
    this.utils = injector.get<UtilsService>(UtilsService);
    this.splunkSvc = injector.get<SplunkUtilityService>(SplunkUtilityService);
    this.pdlService = injector.get<PDLService>(PDLService);
    this.redirectService = injector.get<RedirectService>(RedirectService);
    this.pnpClientService = injector.get<PnpClientService>(PnpClientService);
    this.content = environment.StaticContent;
    sessionStorage.setItem(
      EipPaymentConstants.CONTENT,
      JSON.stringify(this.content)
    );
  }

  ngOnInit(): void {
    this.enableEip = environment.ENABLE_EIP_STANDALONE_PAYMENT;
    const isPartner = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.IS_PARTNER_BOARD
    );
    this.enablePartner = (isPartner === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE)
      ? environment.ENABLE_EIP_STANDALONE_PAYMENT_PARTNER
      : true;
    if (this.enableEip && this.enablePartner) {
      this.deviceDetails =
        JSON.parse(
          this._cacheSvc.getFromSessionStorage(EipPaymentConstants.EIP_DEVICE)
        ) ?? null;
      this.setDefault();
      this._loaderSvc.stop("mega");

      this._paymentSvc.cardDataTomakepayment.subscribe((ccDataForm) => {
        if (ccDataForm) {
          this.totalDueNewCardForm = ccDataForm;
          this.formValid = ccDataForm.valid;
          this.luhnCheck = this.totalDueNewCardForm.value.LuhnCheck;
          this.paymentMethodCode =
            this.totalDueNewCardForm.value.paymentMethodCode;
          this.cardTypeCode = this.totalDueNewCardForm.value.cardTypeCode;
          this.cardTypeVal = this.totalDueNewCardForm.value.cardTypeVal;
        }
      });

      this.maskedAccount =
        this.maskedChars +
        (ContentService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER)
          ? this.utils.getLastFourDigits(
              ContentService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER)
            )
          : "");
      this.cardDetailObject = {};

      const idpType = this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.AUTH_TYPE
      );
      if (
        environment.ENABLE_TAGGING &&
        (idpType == COMMON_CONSTANTS.IAM_KEY ||
          isPartner == COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE)
      ) {
        this.generatePageViewDetails(idpType, isPartner);
      }
      this.walkMeIn_userID = this._cacheSvc.getFromSessionStorage(
        COMMON_CONSTANTS.EUI_TOKEN_DATA
      )
        ? JSON.parse(
            this._cacheSvc.getFromSessionStorage(
              COMMON_CONSTANTS.EUI_TOKEN_DATA
            )
          ).logonId
        : MakePaymentConstant.cardSchemailId;
    }
    window.scrollTo(0, 0);
  }

  get isRunningInGlobalNav(): boolean {
    return this.pnpClientService.isRunningInGlobalNav();
  }

  setDefault() {
    this._errMsgService.serviceData.subscribe((genricMessage: any) => {
      if (genricMessage) {
        if (genricMessage.errorType === EipPaymentConstants.PARTNER_ERROR) {
          this.showPartnerError = true;
          this._loaderSvc.stop("mega");
        }
        this.errorMessage = genricMessage.error;
        this.show = genricMessage.show;
        this.errorType = genricMessage.errorType;
      }
    });
    this._routeService.isEuiRedirect.pipe(first()).subscribe((isRedirect) => {
      this.isRedirect = isRedirect;
      this.isAccessTokenStored = Boolean(
        !(
          this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.ACCESS_TOKEN_KEY
          ) == null ||
          this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.ACCESS_TOKEN_KEY
          ) == undefined
        )
      );
      this._cacheSvc.putInSessionStoarage(
        MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT,
        EipPaymentConstants.EIP_PAYMENT
      );
      // tslint:disable-next-line:max-line-length
      if (
        environment.ENABLE_PARTNER &&
        this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.IS_PARTNER_BOARD
        ) === EipPaymentConstants.TRUE
      ) {
        this.setPartnerDefault();
      } else {
        if (
          this._cacheSvc.getFromSessionStorage(
            EipPaymentConstants.HANDSHAKE_COMPLETE
          ) &&
          this._cacheSvc.getFromSessionStorage(
            EipPaymentConstants.HANDSHAKE_COMPLETE
          ) === EipPaymentConstants.TRUE
        ) {
          this.checkPermission();
        } else {
          this._routeService.partnerHandShake.subscribe((data) => {
            if (data) {
              this.checkPermission();
            }
          });
        }
      }
    });
  }

  setPartnerDefault() {
    this._routeService.partnerHandShake.subscribe((data) => {
      if (data) {
        this.isPartnerFlow = true;
        if (
          this._cacheSvc.getFromSessionStorage(
            COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW
          ) === EipPaymentConstants.TRUE
        ) {
          this.isBillingPartnerFlow = true;
        }
        this.checkPermission();
      }
    });
  }

  getDeviceDetails() {
    let euiTokenData = JSON.parse(
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)
    );
    const msisdn = euiTokenData?.msisdn;
    const loanId = euiTokenData?.loanId;
    const ban = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.BAN_NUMBER_KEY
    );

    this.deviceDetails = new EipDeviceDetails();
    if (loanId && msisdn) {
      let payload = {
        billingAccountNumber: ban,
        equipmentFinanceId: [loanId],
      };
      this._getDeviceDetails$ = this._eipPaymentSvc
        .getDeviceDetails(payload)
        .subscribe(
          (response) => {
            const agreement = response?.financeAccount?.financeAgreements[0];
            const device =
              agreement?.equipments && _.isArray(agreement.equipments)
                ? agreement.equipments.find(
                    (equipment) => equipment?.equipmentFinanceId == loanId
                  )
                : "";
            if (device) {
              this.setEipDevice(device, msisdn, loanId, agreement);
            } else {
              this.deviceDetails = new EipDeviceDetails();
              this.balanceAmount = EipPaymentConstants.ZERO;
            }
          },
          (err) => {
            sessionStorage.removeItem(EipPaymentConstants.EIP_DEVICE);
            this.balanceAmount = EipPaymentConstants.ZERO;
          }
        );
    }
  }

  setEipDevice(device, msisdn, loanId, agreement) {
    const ban = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.BAN_NUMBER_KEY
    );
    this.isRDC = device.isPromotionAvailable;
    this.deviceDetails.mobileNumber = msisdn;
    this.deviceDetails.loanId = loanId;
    this.deviceDetails.orderId = device.purchasedEquipment?.orderLineId;
    this.deviceDetails.billingAccountNumber = ban;
    this.deviceDetails.agreementNumber = agreement.agreementNumber;
    this.deviceDetails.effectiveStartDate = device.createdDate;
    this.deviceDetails.totalDownPaymentAmount = device.downPaymentAmount;
    this.deviceDetails.remainingNumberOfInstallments =
      device.remainingNumberOfInstallments;
    this.deviceDetails.lastInstallmentAmount = device.lastInstallmentAmount;
    this.deviceDetails.recurringInstallmentAmount =
      device.recurringInstallmentAmount;
    this.deviceDetails.totalAmountFinanced = device.totalFinancedAmount;
    this.deviceDetails.balance = device.remainingFinancedAmount;
    this.deviceDetails.purchasedEquipment.description =
      device.purchasedEquipment?.skuDescription;
    this.deviceDetails.purchasedEquipment.imei =
      device.purchasedEquipment?.imei;
    this.deviceDetails.purchasedEquipment.salePrice =
      device.purchasedEquipment?.totalPrice;
    sessionStorage.setItem(
      EipPaymentConstants.EIP_DEVICE,
      JSON.stringify(this.deviceDetails)
    );
    this.balanceAmount =
      this.deviceDetails?.balance || EipPaymentConstants.ZERO;
  }

  generatePageViewDetails(idpTypeArg: any, isPartnerArg: any) {
    let pageDetail = {
      primaryCategory: PDL_CONSTANTS.PRIMARY_CATEGORY,
      subCategory: PDL_CONSTANTS.PLP_SUB_CATEGORY,
      pageType: PDL_CONSTANTS.PLP_PAGE_TYPE,
      pageName: PDL_CONSTANTS.PLP_PAGE_NAME,
      previousPageURL: this._cacheSvc.getFromSessionStorage(
        PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL
      ),
      idpType: idpTypeArg,
      isPartner: isPartnerArg,
    };

    this.pdlService.generatePageViewTag(pageDetail);
  }
  
  checkPermission() {
    if(!this.isPartnerFlow && !this.utils.isCareAuth()) {
      const permissionsObject = JSON.parse(this._cacheSvc.getFromSessionStorage("pnp-persisted-context") || "{}");
      if(!isNullOrUndefined(permissionsObject) && !isNullOrUndefined(permissionsObject.activities)){
        if (permissionsObject.activities.BAN?.MAKE_PAYMENTS?.isActiveFlag == 1) {
          this.makeEIPPermission = true;
          this.initializeObjects();
        }
        if(!this.makeEIPPermission){
          this.router.navigate([MakePaymentConstant.processError]);
        }
      }
    }
    else if(this.isPartnerFlow || this.utils.isCareAuth()){
      this.makeEIPPermission = true;
      this.initializeObjects()
    }
  }

  initializeObjects(){
    if (
        (!this.isRedirect &&
            this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE
            ) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE &&
            this.isAccessTokenStored === true) ||
        (environment.SSO_ON === false &&
            this.isAccessTokenStored === true &&
            this._cacheSvc.getFromSessionStorage(
                COMMON_CONSTANTS.IS_HANDSHAKE_COMPLETE
            ) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE) && this.makeEIPPermission
    ) {
      this.paymentDate = new Date();
      this.thankuDataObject = {};
      this.banNumber = this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.BAN_NUMBER_KEY
      );
      this.orgID = this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.ORG_ID_KEY
      );
      this.careAuthType = this.utils.isCareAuth()

      if (
          !Boolean(this.deviceDetails) &&
          environment.ENABLE_PARTNER &&
          this._cacheSvc.getFromSessionStorage(
              COMMON_CONSTANTS.IS_PARTNER_BOARD
          ) === EipPaymentConstants.TRUE
      ) {
        this.getDeviceDetails();
      } else if (!Boolean(this.deviceDetails)) {
        this.deviceDetails = new EipDeviceDetails();
        this.balanceAmount = EipPaymentConstants.ZERO;
      } else {
        this.balanceAmount =
            this.deviceDetails.balance || EipPaymentConstants.ZERO;
      }
      if(this.makeEIPPermission){
        this.makePaymentIntialLoad();
      }
    }
  }

  makePaymentIntialLoad() {
    this.authoringContent = this.content.par.common_payment;
    document.title = this.authoringContent.landingInfo.PageTitle;
    ContentService.SetCache(
      COMMON_CONSTANTS.CONTENT_CACHE_KEY,
      this.authoringContent
    );
    // tslint:disable-next-line:max-line-length
    this.returnURI = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.RETURN_URL_SSP
    );
    if (
      environment.hideBANFriendlyName ||
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) ==
        EipPaymentConstants.TRUE
    ) {
      this.titleLabel = `${this.authoringContent.paymentAmount.titlelblTxt1.replace(
        MakePaymentConstant.ban,
        this.banNumber
      )}`;
    } else {
      if (
        this._cacheSvc.getFromSessionStorage(
          COMMON_CONSTANTS.IS_PARTNER_BOARD
        ) !== EipPaymentConstants.TRUE
      ) {
        this.setTitle();
      }
    }
  }

  setTitle() {
    let accountDetailsRequestData = {
      billingAccount: this.banNumber,
    };
    this._getBanDetails$ = this._autoPaymentSvc
      .getAccountDetails(accountDetailsRequestData)
      .pipe(this.pipedOperatorsService.subReattempt())
      .subscribe(
        (data) => {
          this.titleLabel = `${this.authoringContent.paymentAmount.titlelblTxt1.replace(
            MakePaymentConstant.ban,
            this.banNumber
          )}`;
          if (data) {
            this.banDetails = data;
            if (this.banDetails.banFriendlyName) {
              this.titleLabel += `${this.authoringContent.paymentAmount.titlelblTxt2.replace(
                MakePaymentConstant.banName,
                this.banDetails.banFriendlyName
              )}`;
            }
          }
        },
        (error) => {
          this.titleLabel = `${this.authoringContent.paymentAmount.titlelblTxt1.replace(
            MakePaymentConstant.ban,
            this.banNumber
          )}`;
        }
      );
  }
  onPaymentAmountChange(evt: any) {
    this.isAdditionalAmount = true;
    if (evt === EipPaymentConstants.BALANCE) {
      this.isAdditionalAmount = false;
      this.paymentAmount = this.balanceAmount;
      this.additionalAmount = "";
      this.isSymbol = false;
      this.amountError = "";
    } else if (evt === EipPaymentConstants.ADDITIONAL) {
      this.isAdditionalAmount = true;
      this.paymentAmount = this.additionalAmount;
    }
  }

  collapseOther(amt?) {
    if (amt.value !== "" && this.isAdditionalAmount === true) {
      this.isSymbol = true;
      if (
        amt.value >= COMMON_CONSTANTS.TWO &&
        amt.value <= this.balanceAmount
      ) {
        this.paymentAmount = amt.value;
        this.additionalAmount = amt.value;
        this.amountError = "";
      } else if (amt.value < COMMON_CONSTANTS.TWO) {
        this.amountError =
          this.authoringContent.paymentAmount.chooseAmountErrorTxt;
      } else if (amt.value > this.balanceAmount) {
        this.amountError =
          this.authoringContent.paymentAmount.amountErrorTxt.replace(
            "$25,000",
            "$" + this.balanceAmount
          );
      }
    } else {
      this.isSymbol = false;
    }
  }

  toggleSymbol(amt?: any) {
    if (amt?.value) {
      this.isSymbol = false;
    } else {
      this.isSymbol = !this.isSymbol;
    }
  }

  getPercentage({ totalAmountFinanced, balance, ...rest }): number {
    if (totalAmountFinanced === 0) return 0;
    return (
      Math.round(
        ((totalAmountFinanced - balance) / totalAmountFinanced) * 100 * 100
      ) / 100
    );
  }

  onCcBillPaySuccess(ccData: PaymentValidation) {
    this.ccSavedData = ccData;
  }

  onSuccessCredit(evt) {
    this.saveSuccess = evt.response;
    const len = evt.cardNumber.length - 4;
    this.maskedAccount = evt.cardNumber.slice(len);
  }

  deleteCardInfo(val: boolean) {
    this.errorAlert = { message: null, show: false };
  }

  disableRecaptcha(): boolean {
    let isDisabled: boolean = false;
    isDisabled =
      !this.cardTypeVal ||
      !this.luhnCheck ||
      !this.enableRetry ||
      !this.formValid ||
      this.saveSuccess ||
      this.amountError ||
      this.careAuthType ||
      !this.paymentAmount ||
      this.paymentAmount < 2 ||
      this.paymentAmount > this.balanceAmount ||
      !Boolean(this.deviceDetails) ||
      !Boolean(this.deviceDetails?.agreementNumber) ||
      !Boolean(this.deviceDetails?.balance) ||
      !Boolean(this.deviceDetails.mobileNumber)
    return isDisabled;
  }

  setToken(recaptchaToken: string): void {
    this.recaptchaToken = recaptchaToken;
  }

  onEntrust() {
    this.entrustUrl = this.authoringContent.landingInfo.enTrustLogoUrl.replace(
      "[host]",
      location.host
    );
    window.open(
      this.entrustUrl,
      "",
      "width=537,height=457,left=19%,top=50,sceenX=19%,screenY=50"
    );
  }

  onSubmit() {
    this.generateClickDetailsForMakePayment(
      "click",
      "button",
      ClickEventType.ACTION,
      "Submit",
      ""
    );

    if (!this.recaptchaToken) {
      return;
    }

    this._cacheSvc.putInSessionStoarage(
      PDL_CONSTANTS.PLP_PAGE_PREVIOUS_URL,
      window.location.host + window.location.pathname
    );

    if (
      this.totalDueNewCardForm.value.expirationMonth !== "" &&
      this.totalDueNewCardForm.value.expirationYear !== ""
    ) {
      this.cardDetailObject.expirationDate =
        this.totalDueNewCardForm.value.expirationMonth +
        "/" +
        this.totalDueNewCardForm.value.expirationYear.toString().substr(2, 2);
    }
    this._publicKeySubscription$ = this.billPaySvc.fetchPublicKey().pipe(this.pipedOperatorsService.subReattempt()).subscribe(
      (data: any) => {
        this._loaderSvc.stop(CREDIT_CARD_CONSTANTS.MICRO_TEXT);
        this.isCCEncryptComplete = false;
        this.key1.kty = data.payment.encryption.jwkKey.kty;
        this.key1.e = data.payment.encryption.jwkKey.e;
        this.key1.n = data.payment.encryption.jwkKey.n;
        const toEncrypt = {
          creditCardNumber:
            this.totalDueNewCardForm.value.actualcreditCardNumber,
          cvv: HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_CVV_NUMBER),
        };
        const card = this;
        encrypt(
          data.payment.encryption.jwkKey,
          toEncrypt.creditCardNumber,
          (encryptedCCNumber) => {
            encryptedCCNumber = JSON.stringify(encryptedCCNumber, undefined, 2);
            card.encryptedNumber = encryptedCCNumber.substring(
              1,
              encryptedCCNumber.length - 1
            );
            if (this.encryptedNumber) {
              encrypt(
                data.payment.encryption.jwkKey,
                toEncrypt.cvv,
                (encryptedCvv) => {
                  encryptedCvv = JSON.stringify(encryptedCvv, undefined, 2);
                  card.encryptedCvv = encryptedCvv.substring(
                    1,
                    encryptedCvv.length - 1
                  );
                  if (
                    this.encryptedNumber &&
                    this.encryptedCvv &&
                    !this.isCCEncryptComplete
                  ) {
                    this.ccSavedData.payment.paymentMethodReference.paymentMethodCode =
                      this.paymentMethodCode;
                    this.ccSavedData.payment.paymentMethodReference.storePaymentMethodIndicator =
                      false;
                    this.ccSavedData.payment.termsAgreementIndicator = true;
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cardNumber =
                      this.encryptedNumber;
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderFirstName =
                      this.getFormattedName(
                        this.totalDueNewCardForm.value.nameOnCreditCard,
                        CREDIT_CARD_CONSTANTS.FIRST
                      );
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderLastName =
                      this.getFormattedName(
                        this.totalDueNewCardForm.value.nameOnCreditCard,
                        CREDIT_CARD_CONSTANTS.LAST
                      );
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderName =
                      this.totalDueNewCardForm.value.nameOnCreditCard;
                    this.ccSavedData.payment.paymentInstrument.paymentCard.typeCode =
                      this.cardTypeCode;
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cvv =
                      this.encryptedCvv;
                    this.ccSavedData.payment.paymentInstrument.paymentCard.expirationMonthYear =
                      // tslint:disable-next-line:max-line-length
                      this.totalDueNewCardForm.value.expirationMonth +
                      this.totalDueNewCardForm.value.expirationYear
                        .toString()
                        .substr(2, 2);
                    // tslint:disable-next-line:max-line-length
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.purposes =
                      MakePaymentConstant.purposes;
                    this.ccSavedData.payment.paymentInstrument.paymentCard.paymentCardAlias =
                      "";
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2 =
                      this.totalDueNewCardForm.value.ccAddress2;
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 =
                      this.totalDueNewCardForm.value.ccAddress1;
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state =
                      this.totalDueNewCardForm.value.ccState;
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city =
                      this.totalDueNewCardForm.value.ccCity;
                    // tslint:disable-next-line:max-line-length
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.countryCode =
                      MakePaymentConstant.countryCode;
                    // tslint:disable-next-line:max-line-length
                    this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode =
                      this.totalDueNewCardForm.value.ccZip;
                    // tslint:disable-next-line:max-line-length
                    this.ccSavedData.payment.productGroup =
                      ACH_CONSTANTS.PRODUCT_GROUP;
                    this.ccSavedData.payment.customerType =
                      CreditCardConstants.CUSTOMER_TYPE;
                    this.ccSavedData.payment.programCode =
                      MakePaymentConstant.programCode;
                    this.ccSavedData.payment.businessUnit =
                      MakePaymentConstant.businessUnit;
                    this.ccSavedData.payment.billerCode =
                      MakePaymentConstant.billerCode;
                    this.ccSavedData.payment.businessSegment =
                      MakePaymentConstant.businessSegment;
                    this.ccSavedData.payment.storedPaymentIndicator = false;
                    this.ccSavedData.payment.orderTypes =
                      ACH_CONSTANTS.MNGORDER_TYPE;
                    this.ccSavedData.customerReference.customerId =
                      this.banNumber;
                    // tslint:disable-next-line:max-line-length
                    this.ccBillPayAliasData.paymentType =
                      CREDIT_CARD_CONSTANTS.PAYMENT_TYPE;
                    this.ccBillPayAliasData.cardholderName =
                      this.totalDueNewCardForm.value.nameOnCreditCard;
                    //tslint:disable-next-line:max-line-length
                    this.ccBillPayAliasData.cardNumberAlias = "";
                    this.ccBillPayAliasData.expireMonth =
                      this.totalDueNewCardForm.value.expirationMonth;
                    this.ccBillPayAliasData.expireYear =
                      this.totalDueNewCardForm.value.expirationYear;
                    this.ccBillPayAliasData.cardBrand = this.cardTypeCode;
                    this.ccBillPayAliasData.billingAccountNumber =
                      this.banNumber;
                    this.ccBillPayAliasData.orgId = this.orgID;
                    this.ccBillPayAliasData.transactionType =
                      EipPaymentConstants.TRANSACTION_TYPE;
                    this.ccBillPayAliasData.billingAddress.address1 =
                      this.totalDueNewCardForm.value.ccAddress1;
                    this.ccBillPayAliasData.billingAddress.city =
                      this.totalDueNewCardForm.value.ccCity;
                    this.ccBillPayAliasData.billingAddress.state =
                      this.totalDueNewCardForm.value.ccState;
                    this.ccBillPayAliasData.billingAddress.zipCode =
                      this.totalDueNewCardForm.value.ccZip;
                    this.getBillPayAlias(
                      this.encryptedNumber,
                      this.encryptedCvv
                    );
                  }
                },
                (errorData) => {
                  this.setError(errorData)
                }
              );
            }
          },
          (errorData) => {
            this.setError(errorData)
          }
        );
      },
      (err) => {
        this.setError(err);
      }
    );
  }
setError(err){
  this.errorMessage = ContentService.GetCache(
    COMMON_CONSTANTS.CONTENT_CACHE_KEY
  );
  this.saveSuccess = true;
  this.errorAlert = {
    message: {
      errorKey: err ? err.status : "",
      errorMessage: this.errorMessage.error.genericErrorMpTxt,
      errorType: "Server",
    },
    show: true,
  };
  console.log(err);
}
  getBillPayAlias(encryptedNumber, encryptedCvv) {
    this._billPaySubscription$ = this.billPaySvc
      .billPayAliasInfo(this.ccBillPayAliasData, this.orgID)
      .subscribe(
        (billPayRes: any) => {
          // POST call for account-orders
          this._cacheSvc.putInSessionStoarage(
            COMMON_CONSTANTS.TRANS_REF_NUM,
            billPayRes.transRefNum
          );
          this._cacheSvc.putInSessionStoarage(
            COMMON_CONSTANTS.TRANSACTION_ID,
            billPayRes.transactionId
          );
          this.transactionId = billPayRes.transactionId;
          const card = HttpCacheService.GetCache(
            COMMON_CONSTANTS.CREDITCARD_NUMBER
          ).replace(/ /g, "");
          this.maskedCcNumber =
            this.maskedChars +
            this.utils.getLastFourDigits(
              HttpCacheService.GetCache(COMMON_CONSTANTS.CREDITCARD_NUMBER)
            );
          ContentService.SetCache(COMMON_CONSTANTS.CC_NUMBER, card);
          this.cardDetailObject.cardNumber = card;
          ContentService.SetCache(
            COMMON_CONSTANTS.CARD_DETAIL_OBJECT,
            this.cardDetailObject
          );
          this.submitPayment(encryptedNumber, encryptedCvv);
        },
        (err) => {
          this.router.navigate([MakePaymentConstant.processError]);
          console.log(err);
        }
      );
  }

  getFormattedName(name: string, type: string) {
    const nameArr = name.toString().split(" ");
    if (type === CREDIT_CARD_CONSTANTS.FIRST) {
      if (nameArr.length > CREDIT_CARD_CONSTANTS.TWO) {
        return nameArr[0] + " " + nameArr[1];
      } else {
        return nameArr[0];
      }
    } else {
      if (nameArr.length > CREDIT_CARD_CONSTANTS.ONE) {
        return nameArr[nameArr.length - 1];
      } else {
        return "";
      }
    }
  }

  submitPayment(encryptedNumber: any, encryptedCvv: any) {
    // analytics click tagging
    this.generateClickDetailsForMakePayment(
      "click",
      "button",
      ClickEventType.ACTION,
      "Submit",
      ""
    );
    const euiTokenData = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.EUI_TOKEN_DATA
    );
    const currDateTime = new Date();
    const agreementTime = currDateTime.toISOString();

    this.eipCCValidation.billingAccount =
      this.ccSavedData.customerReference.customerId;

    this.eipCCValidation.salesInfo.createdBy = euiTokenData
      ? JSON.parse(euiTokenData)?.firstName +
        " " +
        JSON.parse(euiTokenData)?.lastName
      : "";

    this.eipCCValidation.postingDetails.postingIdentifier = this
      .deviceDetails
      ? this.deviceDetails.agreementNumber
      : "";
    this.eipCCValidation.postingDetails.postingAmount = Number(
      this.paymentAmount.toString().replace(",", "")
    );
    this.eipCCValidation.postingDetails.postingSubIdentifier = this
      .deviceDetails
      ? this.deviceDetails.loanId
      : "";
    this.eipCCValidation.postingDetails.mobileNumber =
      this.deviceDetails ? this.deviceDetails.mobileNumber : "";

    this.eipCCValidation.payment.orderNumber =
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TRANS_REF_NUM);
    this.eipCCValidation.payment.termsAgreementTime = agreementTime;
    this.eipCCValidation.payment.termsAgreementIndicator = true;
    this.eipCCValidation.payment.creationTime = agreementTime;
    this.eipCCValidation.payment.chargeAmount = Number(
      this.paymentAmount.toString().replace(",", "")
    );

    this.eipCCValidation.payment.paymentInstrument.paymentCard.cvv =
      encryptedCvv;
    // // tslint:disable-next-line:max-line-length
    this.eipCCValidation.payment.paymentInstrument.paymentCard.typeCode =
      this.cardTypeCode;
    this.eipCCValidation.payment.paymentInstrument.paymentCard.expirationMonthYear =
      this.ccSavedData.payment.paymentInstrument.paymentCard.expirationMonthYear;
    this.eipCCValidation.payment.paymentInstrument.paymentCard.cardNumber =
      encryptedNumber;
    this.eipCCValidation.payment.paymentInstrument.paymentCard.paymentCardAlias =
      encryptedNumber;
    this.eipCCValidation.payment.paymentInstrument.paymentCard.cardHolderName =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderName;
    this.eipCCValidation.payment.paymentInstrument.paymentCard.cardHolderFirstName =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderFirstName;
    this.eipCCValidation.payment.paymentInstrument.paymentCard.cardHolderLastName =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderLastName;
    this.eipCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1 =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine1;
    this.eipCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2 =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.addressLine2;
    this.eipCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.city;
    this.eipCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.state;
    // tslint:disable-next-line:max-line-length
    this.eipCCValidation.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode =
      this.ccSavedData.payment.paymentInstrument.paymentCard.cardHolderAddress.addressCore.zipCode;

    this.eipCCValidation.recaptchaResponse = this.recaptchaToken;
    this.processPayment();
  }

  processPayment() {
    this._eipPaymentSubscription$ = this._eipPaymentSvc
      .makeEipPayment(this.eipCCValidation)
      .subscribe(
        (response) => {
          if (
            response["status"] &&
            response["status"].statusCode &&
            response["status"].statusCode ===
              MakePaymentConstant.APPROVED_STATUS &&
            (response["reason"].reasonCode === "8033" ||
              response["reason"].reasonCode === "8219") &&
            this._errMsgService.getErrorMessage().show
          ) {
            this._errMsgService.unSettingErrorMsg();
          }

          if (this._errMsgService.getErrorMessage().show) {
            this.handleErrorResponse(response);
            return false;
          } else if (
            response["status"] &&
            response["status"].statusCode &&
            response["status"].statusCode ===
              MakePaymentConstant.APPROVED_STATUS &&
            (response["reason"].reasonCode === "8033" ||
              response["reason"].reasonCode === "8219")
          ) {
            this.thankuDataObject.paymentResponse = response;
            this.thankuDataObject.fromWhichPayment =
              EipPaymentConstants.ORDER_TYPE;
            this.thankuDataObject.paymentInfo =
              EipPaymentConstants.PAYMENT_OBJECT;
            ContentService.SetCache(
              MakePaymentConstant.makepaymentResponse,
              this.thankuDataObject
            );
            this.updateCROrderId();
            this.updateCROrder(MakePaymentConstant.thankuUrl);
          }
        },
        (err) => {
          this.errorMessage = ContentService.GetCache(
            COMMON_CONSTANTS.CONTENT_CACHE_KEY
          );
          this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
          if (err?.error?.errors) {
            this.updateCrOrderStatusDesc = err.error.errors[0].systemMessage
              ? err.error.errors[0].systemMessage
              : err.error.errors[0].userMessage;
          } else {
            if (err?.error?.systemMessage) {
              this.updateCrOrderStatusDesc = err.error.systemMessage;
            } else {
              this.updateCrOrderStatusDesc = err?.error?.error
                ? err.error.error
                : this.errorMessage.error.genericErrorMpTxt;
            }
          }
          this.updateCROrderId();
          this.updateCROrder(MakePaymentConstant.processError);
          this.saveSuccess = false;
        }
      );
  }

  handleErrorResponse(response){
    this.retry++;
    this.updateCrOrderStatus = MakePaymentConstant.failedStatus;
    this.updateCrOrderStatusDesc = response["payment"]
      ? response["payment"].messages[0].uiAction
      : this.errorMessage.error.genericErrorMpTxt;
    this.updateCROrderId(response);
    this.updateCROrder();
    this.saveSuccess = false;

    const negativeBanReasonCodeList = _.split(
      this.authoringContent.error.processNegativeBanReasonCodeTxt,
      ","
    );
    if (
      this.errorAlert &&
      this.errorAlert.message &&
      this.errorAlert.message.errorKey &&
      negativeBanReasonCodeList.includes(
        this.errorAlert.message.errorKey
      )
    ) {
      this.enableRetry = false;
      this._paymentSvc.makePaymenttoCC(true);
      this.router.navigate([MakePaymentConstant.processError]);
    } else {
      if (this.retry > CREDIT_CARD_CONSTANTS.TWO) {
        this.enableRetry = false;
        this.router.navigate([MakePaymentConstant.processError]);
      } else {
        if (
          negativeBanReasonCodeList.includes(
            response["reason"].reasonCode
          )
        ) {
          this.enableRetry = false;
          this.router.navigate([MakePaymentConstant.processError]);
        } else {
          this.handleRetry(response);
        }
      }
    }
  }

  handleRetry(response){
    const errorListCode = EipPaymentConstants.ERROR_LIST_CODE;
    if (errorListCode.includes(response["reason"].reasonCode)) {
      this.enableRetry = true;
      this._paymentSvc.processPaymentErrorCounter.next(this.retry);
      this.PaymentErrorModel.openModal();
    } else {
      this.enableRetry = false;
      this.router.navigate([MakePaymentConstant.processError]);
    }
  }

  updateCROrder(path?) {
    this._updateTransactionSubscription$ = this._paymentSvc
      .updateCROrder(this.mPUpdateCrOrder)
      .subscribe(
        (crData) => {
          if (path) this.router.navigate([path]);
        },
        (error) => {
          if (path) this.router.navigate([path]);
        }
      );
  }

  updateCROrderId(response?: any) {
    // UPdate CR Order Object starts
    if (
      this.thankuDataObject &&
      this.thankuDataObject.paymentResponse &&
      this.thankuDataObject.paymentResponse.payment.paymentInstrument
    ) {
      // tslint:disable-next-line:max-line-length
      this.mPUpdateCrOrder.cardNumberAlias = this.thankuDataObject
        .paymentResponse.payment.paymentInstrument
        ? this.thankuDataObject.paymentResponse.payment.paymentInstrument
            .paymentCard.paymentCardAlias
        : "";
    } else if (
      response &&
      response.payment &&
      response.payment.paymentInstrument
    ) {
      this.mPUpdateCrOrder.cardNumberAlias = response.payment.paymentInstrument
        ? response.payment.paymentInstrument.paymentCard.cardNumberAlias
        : "";
    }

    this.mPUpdateCrOrder.billingAccount = this.banNumber;
    const euiTokenData = this._cacheSvc.getFromSessionStorage(
      COMMON_CONSTANTS.EUI_TOKEN_DATA
    );
    this.mPUpdateCrOrder.logonId = euiTokenData
      ? JSON.parse(euiTokenData).logonId
      : "";
    this.mPUpdateCrOrder.emailNotification.firstName = euiTokenData
      ? JSON.parse(euiTokenData)?.firstName
      : "";
    this.mPUpdateCrOrder.emailNotification.emailId =
      this.mPUpdateCrOrder.logonId;
    // tslint:disable-next-line:max-line-length
    this.mPUpdateCrOrder.status = this.thankuDataObject.paymentResponse
      ? "S"
      : this.updateCrOrderStatus;
    this.mPUpdateCrOrder.transactionDesc =
      // tslint:disable-next-line:max-line-length
      this.thankuDataObject.paymentResponse
        ? this.thankuDataObject.paymentResponse.paymentTransactionResponse
            .statusMessage
        : this.updateCrOrderStatusDesc;
    this.mPUpdateCrOrder.chargeAmount = Number(
      this.paymentAmount.toString().replace(",", "")
    );
  }

  generateClickDetailsForMakePayment(
    actionInput: string,
    assetTypeInput: string,
    typeInput: ClickEventType,
    ctaNameInput: string,
    modalNameInput: string
  ) {
    let clickDetail = {
      action: actionInput,
      assetType: assetTypeInput,
      type: typeInput,
      ctaName: ctaNameInput,
      modalName: modalNameInput,
    };

    this.pdlService.generateClickTag(clickDetail);
  }

  onCancel() {
    // analytics click tagging
    this.generateClickDetailsForMakePayment(
      "click",
      "button",
      ClickEventType.NAVIGATE,
      "Cancel",
      ""
    ); //button at bottom
    if (this.isBillingPartnerFlow) {
      this.utils.redirectToPartnerBillingApp();
    } else {
      setTimeout(() => {
        this.utils.return(this.returnURI, true);
      }, 800);
    }
  }

  ngOnDestroy(): void {
    this._eipPaymentSubscription$?.unsubscribe();
    this._updateTransactionSubscription$?.unsubscribe();
    this._billPaySubscription$?.unsubscribe();
    this._publicKeySubscription$?.unsubscribe();
    this._getBanDetails$?.unsubscribe();
    this._getDeviceDetails$?.unsubscribe();
  }

  checkIfGoogleLoaded() {
    return window && (window as any).google && (window as any).google.maps;
  }
}
