import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ContentService } from './content.service';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import * as _ from 'lodash';
import { UtilsService } from './utils.service';
import { COMMON_CONSTANTS, MakePaymentConstant } from '../common/core/common.constants';

export class ServiceMessage {
  public show: boolean;
  public message: {
    errorKey: any,
    errorMessage: any,
    errorType: any
  };
}

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  private dataSource = new BehaviorSubject(false);
  serviceData = this.dataSource.asObservable();
  itemArray: any = [];
  errorAlert: ServiceMessage = { message: null, show: false, };
  errorCodesJSON: any;
  genericErrorMessage: any;
  serverErrorMessage: any;
  errorCodesJSONText: any;
  errorKey: any;
  subscription: Subscription;
  ssoMessage = { error: '', show: false };
  constructor(public utilsSvc: UtilsService) { }

  serviceError(errorRes: any, showError: boolean) { // issue
    const error: ServiceMessage = {
      message: {
        errorKey: errorRes.errorCode,
        errorMessage: errorRes.errorMessage,
        errorType: errorRes.errorType
      },
      show: showError
    };
    this.constructErrorMessage(error);
  }

  mpiServiceNewErrorCode(succResponse) { 
    if (!isNullOrUndefined(succResponse && succResponse.body)) {
      const authoringContentData = ContentService.GetCache(COMMON_CONSTANTS.CONTENT_CACHE_KEY);
      const mpiResponse = succResponse.body;
      // null check validation of MPI response
      if ((mpiResponse?.reason && mpiResponse?.reason?.reasonCode && mpiResponse?.responseCodeList?.length>0
        && mpiResponse?.responseCodeList[0]?.errors?.responseErrors?.length > 0
        && mpiResponse?.responseCodeList[0]?.errors?.responseErrors[0]?.code
        && authoringContentData?.error)) {
        // && authoringContentData.error.validationFailure && authoringContentData.error.negativeFileCheck
        // response constant created
        const responseReasonCode = mpiResponse.reason.reasonCode;
        const responseErrorCode = mpiResponse?.responseCodeList[0]?.errors?.responseErrors[0]?.code;
        // AEM content authoring connstant created
        const genericReasonCodeList = _.split(authoringContentData.error.genericReasonCode, ','); // generic reason code
        const genericServerErrorTxt = authoringContentData.error.genericErrorApTxt; // generic server error
        // validation failure constant
        const addressCheckErrorCodeList = _.split(authoringContentData.error.validationFailureaddressCheckErrorCode, ',');
        const cvvCheckErrorCodeList = _.split(authoringContentData.error.validationFailurecvvCheckErrorCode, ',');
        // negative file check constant
        const negativeFileCommonErrorCodeList = _.split(authoringContentData.error.negativeFileCheckcommonErrorCode, ',');
        // for generic reason code validation
        if (!isNullOrUndefined(genericReasonCodeList) && genericReasonCodeList.includes(responseReasonCode)) {
           // for common reason code check
          let errorMessageText = '';
          // for validation failure error check
          if (!isNullOrUndefined(addressCheckErrorCodeList) && addressCheckErrorCodeList.includes(responseErrorCode) // for address check
            || !isNullOrUndefined(cvvCheckErrorCodeList) && cvvCheckErrorCodeList.includes(responseErrorCode)) { // for CVV check
            errorMessageText = authoringContentData.error.genericErrorApTxt;
          } else if (!isNullOrUndefined(negativeFileCommonErrorCodeList) && negativeFileCommonErrorCodeList.includes(responseErrorCode)) {
              // for negative file error code check
            // for negative file error check
            const fraudCheckKey = authoringContentData.error.negativeFileCheckfraudCheckKey;
            // variable declarations start
            let responseDenialType = '';
            const responseCodeKey = mpiResponse.responseCodeList[0].key;
            if (!isNullOrUndefined(mpiResponse.payment)) {
              if (!isNullOrUndefined(mpiResponse.payment.denialTypes)) {
                responseDenialType = mpiResponse.payment.denialTypes;
              }
              if (!isNullOrUndefined(mpiResponse.payment.specifications) &&
               !isNullOrUndefined(mpiResponse.payment.specifications[0].value)) {
                 // This is intentional
              }
            }
            // variable declarations end
            if (!isNullOrUndefined(fraudCheckKey) && fraudCheckKey === responseCodeKey) { // for fraud check
              errorMessageText = authoringContentData.error.cardBankBlockedErrorTxt;
            } else {
              errorMessageText = authoringContentData.error.genericErrorApTxt;
            }

          } else {
            errorMessageText = authoringContentData.error.genericErrorApTxt;
          }
          this.settingErrorMsg(responseErrorCode, errorMessageText, genericServerErrorTxt);
        }
      }
    }
  }


  processPaymentServiceNewErrorCode(succResponse) { 
    if (!isNullOrUndefined(succResponse && succResponse.body)) {
      const authoringContentData = ContentService.GetCache(COMMON_CONSTANTS.CONTENT_CACHE_KEY);
      const processResponse = succResponse.body;
      if (!isNullOrUndefined(processResponse?.reason && processResponse?.reason?.reasonCode && authoringContentData?.error &&
        processResponse?.status && processResponse?.status.statusCode) &&
        processResponse?.status?.statusCode !== MakePaymentConstant?.APPROVED_STATUS
        && processResponse?.reason?.reasonCode !== '8033') {

        const responseReasonCode = processResponse?.reason?.reasonCode;
        const genericServerErrorTxt = authoringContentData?.error?.genericErrorMpTxt; // generic server error

        const errorMessageText = authoringContentData?.error?.genericErrorMpTxt;
        this.settingErrorMsg(responseReasonCode, errorMessageText, genericServerErrorTxt);

      } else if (!isNullOrUndefined(processResponse?.reason && processResponse?.reason?.reasonCode && authoringContentData?.error &&
        processResponse?.status && processResponse?.status?.statusCode) &&
        processResponse?.status?.statusCode === MakePaymentConstant?.APPROVED_STATUS
        && processResponse?.reason?.reasonCode !== '8033') {
          const responseReasonCode = processResponse?.reason?.reasonCode;
          const errorMessageText = authoringContentData?.error?.genericErrorApTxt;
          this.settingErrorMsg(responseReasonCode, errorMessageText, errorMessageText);
      }
    }
  }

  unSettingErrorMsg(responseCode?: any, errorMessageText?: any, genericServerErrorTxt?: any) {
    this.errorAlert = {
      message: {
        errorKey: responseCode ? responseCode : '',
        errorMessage: errorMessageText ? errorMessageText : '',
        errorType: genericServerErrorTxt ? genericServerErrorTxt : ''
      },
      show: false
    };
  }

  settingErrorMsg(responseCode, errorMessageText, genericServerErrorTxt) {
    this.errorAlert = {
      message: {
        errorKey: responseCode,
        errorMessage: errorMessageText,
        errorType: genericServerErrorTxt
      },
      show: true
    };
  }


  constructErrorMessage(response: any) { 

    if ((response.message) && (response.show === true)) {

      this.errorKey = response.message.errorKey;
      this.genericErrorMessage = response.message.errorMessage;


      if (this.errorKey || this.genericErrorMessage) {

        this.errorCodesJSON = ContentService.GetCache('content');

        if (this.errorCodesJSON) {

          this.errorCodesJSONText = this.errorCodesJSON.errorconfigs;

          // this for loop logic needed because this.errorCodesJSONText could be non-iterable
          for (let index = 0; index < this.errorCodesJSONText.length; index++) {
            const element = JSON.parse(this.errorCodesJSONText[index]);
            this.itemArray.push(element);
          }

          this.itemArray.forEach(element => {
            if (this.errorKey === element.errorkey) {
              this.serverErrorMessage = element.errorvalue;
            }
          });

        }

        if (this.errorKey || this.genericErrorMessage) {
          if (this.serverErrorMessage) {
            this.errorAlert = {
              message: {
                errorKey: response.message.errorKey,
                errorMessage: this.serverErrorMessage,
                errorType: response.message.errorType
              }, show: response.show,
            };

          } else if (this.genericErrorMessage) {
            this.errorAlert = {
              message: {
                errorKey: response.message.errorKey,
                errorMessage: this.genericErrorMessage,
                errorType: response.message.errorType
              }, show: response.show,
            };
          } else {
            this.errorAlert = { message: this.errorKey, show: response.show, };
          }
        }
      }
    }

  }

  getErrorMessage(): ServiceMessage {
    return this.errorAlert;
  }

  getServerErrorMessage() {
    return this.serverErrorMessage;
  }

  serverErrorMessgae(errorMessage: any, showError: boolean) {
    this.serverErrorMessage = {
      message: {
        errorKey: errorMessage.errorCode,
        errorMessage: errorMessage.errorMessage,
        errorType: errorMessage.errorType
      },
      show: showError
    };
  }

  RedFlagErrorMessgae(errorMessage: any, showError: boolean) {
    const errorOBJ = {
       code: errorMessage.errorCode,
       error : errorMessage.errorMessage,
       errorType: errorMessage.errorType,
       show: showError, };
    this.sendErrorMessgae(errorOBJ);
  }


  sendErrorMessgae(genricMessage: any) {
    this.dataSource.next(genricMessage);
  }

  setErrorMessgae(genricMessage: any, flage: boolean) {
    this.ssoMessage.error = genricMessage;
    this.ssoMessage.show = flage;
  }

  getSSOMessage() {
    return this.ssoMessage;
  }

}
