import { Injectable } from '@angular/core';
import { HttpRequest, HttpHeaders } from '@angular/common/http';
import { NgxLoggerLevel, NGXLogger } from 'ngx-logger';
import { COMMON_CONSTANTS } from '../common/core/common.constants';
import { HttpCacheService } from '../services/http-cache.service';
import * as _ from 'lodash';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { UtilsService } from '../services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SplunkUtilityService {

  requestDate: any;
  b2bRequestid: any;

  constructor(private logger: NGXLogger, private _cacheSvc: HttpCacheService, private utils: UtilsService ) { }

  logInSplunk(logLevel: NgxLoggerLevel, req: any, message: any,  errorType?: any) {
    const urlProps = this._prepareUrlProperties(req);
    let headerVals={};
      if(_.isArray(req.headers) || _.isObject(req.headers)){
        const keys = req.headers.keys();
        keys.map(key =>{ headerVals[key]= req.headers.get(key)});
      }

      headerVals['Host']= window.document.location.host;
      headerVals['Origin']=  window.document.location.origin;
      headerVals['Referer']= window.document.location.href;
      headerVals['User-Agent']= navigator.userAgent;
      headerVals['Cookie']= window.document.cookie;

    if (isNullOrUndefined(errorType)) {
        if (_.isArray(message.headers.getAll('date'))) {
          this.requestDate = message.headers.getAll('date')[0];
        } else {
          this.requestDate = 'datedate';
        }

        if (_.isArray(message.headers.getAll(COMMON_CONSTANTS.B2B_REQUESTID))) {
          this.b2bRequestid = message.headers.getAll(COMMON_CONSTANTS.B2B_REQUESTID)[0];
        }else if (_.isArray(message.headers.getAll(COMMON_CONSTANTS.B2B_REQUEST_ID))) {
          this.b2bRequestid = message.headers.getAll(COMMON_CONSTANTS.B2B_REQUEST_ID)[0];
        }else {
          this.b2bRequestid = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
        }
        
        let respheaderVals = {
          date: this.requestDate,
          B2brequestid: this.b2bRequestid,
        }

        this.logger.setCustomHttpHeaders(new HttpHeaders({
        'b2bRequestid': this.b2bRequestid ,
        'requestDate': this.requestDate}));
        message = {
          ...message, 'requestBody': req.body, 'responseHeader': Object.assign({}, respheaderVals) , api: urlProps['apiName'] || '',
          submitCall : urlProps['finalCall'] || false, redflag : urlProps['REDFLAG'] || false, reqheader : Object.assign({}, headerVals)
        };

    } else if (!isNullOrUndefined(errorType)) {    
      message = {...message, 'requestBody': message.stack ? message.stack : message, errorType,
       redflag : urlProps['REDFLAG'] || false, reqheader : Object.assign({}, headerVals) };
    }

    if (_.includes(message.url , '/billing/v1/payment-profile/payment-arrangements/eligibility'))  {
        const serverDate = message.headers.getAll('date')[0];
        this._cacheSvc.putInSessionStoarage('serverDate', serverDate);
      }

    if (environment.HTTP_SPLUNK_EVENT) {
    switch (logLevel) {
      case NgxLoggerLevel.ERROR:
        this.logger.error(message);
        break;
      case NgxLoggerLevel.LOG:
        this.logger.log(message);
        break;
      default:
        this.logger.error(message);
      }
    }
  }
  private _prepareUrlProperties(req: HttpRequest<any>) {
    let urlProperties = {};

    if (req.params) {
      urlProperties = req.params.get('metaData');
      req.params.delete('metaData');
    }
    if (req.headers) {
      const metaData = JSON.parse(req.headers.get('metadata') || '{}');
      urlProperties = { ...urlProperties, ...metaData };
    }
    return urlProperties;
  }
}
