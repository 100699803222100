export class IPageViewTag {
  primaryCategory: string;
  pageName: string;
  previousPageURL: string;
  subCategory?: string;
  pageType?: string;
  product?: IProduct;
  idpType?: string;
  isPartner?: string;
}

export class IProduct {
  id: string;
  name: string;
  price: IPrice;
  type: ProductType;
  subType: ProductSubType;
}

export class IPrice {
  fullRetailPrice: number;
}

export enum ProductType {
  HARDGOOD = 'hardgood',
  SOFTGOOD = 'softgood'
}

export enum ProductSubType {
  PHONE = 'phone',
  TABLET = 'tablet',
  WEARABLE = 'wearable',
  SIMKIT = 'simkit',
  ACCESSORIES = 'accessories',
  PLAN = 'plan',
  SERVICE = 'service'
}

export interface IClickTag {
  action: string;
  type: ClickEventType;
  assetType: string;
  pageName?: string;
  modalName?: string;
  sortName?: string;
  filterName?: string;
  ctaName?: string;
  targetUrl?: string;
}

export enum ClickEventType {
  CLICK = 'click',
  NAVIGATE = 'clickToNavigate',
  ACTION = 'clickToAction',
  CALL = 'clickToCall'
}
