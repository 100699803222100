<app-header></app-header>
<div class="container">
  <div class="error-screen-wrapper">
    <div class="row">
      <div class="col-12 error-screen-title">
        <img
          src="../../../assets/tfb-payment/images/icon_error.svg"
          alt="We are unable to process your transaction."
        />
        <h1>{{ authoringContent?.error?.errorScreenTitle }}</h1>
      </div>
      <div
        class="col-12 error-screen-msg"
        *ngIf="!pendingRequest; else pendingRequestErr"
      >
        <p>{{ authoringContent?.error?.genericErrorMpTxt }}</p>
      </div>
      <ng-template #pendingRequestErr>
        <div class="col-12 error-screen-msg">
          <p>{{ authoringContent?.error?.pendingRequestErrorTxt }}</p>
        </div>
      </ng-template>
      <div *ngIf="enableBack" class="col-12 error-screen-cta">
        <a
          href="javascript:void(0)"
          *ngIf="!isPartnerFlow"
          class="primary_btn"
          (click)="onBackToShop()"
          >{{ authoringContent?.error?.errorScreenCTA }}</a
        >
        <button
          *ngIf="isBillingPartnerFlow"
          (click)="onCancel()"
          class="primary_btn"
        >
          {{ authoringContent?.error?.errorScreenCTA }}
        </button>
        <button
          *ngIf="isPartnerFlow && !isPartnerFlow && !isBillingPartnerFlow"
          (click)="onClose()"
          class="primary_btn"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<app-footer *ngIf="!isRunningInGlobalNav"></app-footer>
