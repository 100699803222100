import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HttpCacheService } from './http-cache.service';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CACHE_IGNORE_LIST } from '../common/core/common.constants';

@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor {

    constructor(private _cacheService: HttpCacheService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (req.method !== 'GET' || this._filterServices(req)) {
            return next.handle(req);
        }

        const cachedResponse = this._cacheService[req.urlWithParams] || null;
        if (cachedResponse) {
            return of(cachedResponse);
        }

        return (next.handle(req).pipe(tap(
            succ => {
                if (succ instanceof HttpResponse) {
                    this._cacheService[req.urlWithParams] = succ;
                }
            }
        )));
    }

    private _filterServices(req: HttpRequest<any>) {
        for (const routeItem of CACHE_IGNORE_LIST) {
            if (req.urlWithParams.indexOf(routeItem.route) !== -1) {
                return true;
            }
        }
        return false;
    }
}
