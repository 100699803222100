<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3>{{autopayDiscountTitle}}</h3>
        </div>
        <div class="modal-body">
          <p class="mb-md-5">
            <span class="cancel-icon-text text-color cancelConfirmationText">{{autopayDiscountDescription}}</span>
          </p>
        </div>
        <div class="modal-footer">
          <div class="col-12 col-sm-12 btn-wrapper">
            <button class="black_btn mrg-rt-10 col-12 col-sm-5 ml-sm-5" (click)="onCloseHandled('cancel')">{{autopayDiscountCancel}}</button>
            <button class="primary_btn col-12 col-sm-5" (click)="submitConfirm('confirm')">{{autopayDiscountConfirnmation}}</button>          
          </div>
        </div>
      </div>
    </div>
</div>