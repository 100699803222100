import { Injectable ,   Injector
} from '@angular/core';
import { HttpHeaders} from '@angular/common/http';
import { EnvironmentDetailsService } from './environment-details.service';
import { AuthHttpService } from './http-wrapper.service';
import { environment } from '../../environments/environment';
import { COMMON_CONSTANTS } from '../common/core/common.constants';
import { HttpCacheService } from './http-cache.service';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { PnpClientService } from 'src/pnpframework/lib/services/pnp-client.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root"
})
export class UtilsService {
  _cacheSvc: HttpCacheService;
  body: any;
  constructor(  private injector: Injector, private _loggerHttpClient: AuthHttpService, 
  private _envSvc: EnvironmentDetailsService,
  private pnpClientService: PnpClientService,
  private router: Router,
  ) { 
    this._cacheSvc = injector.get<HttpCacheService>(HttpCacheService)

  }

  public getCookie(name: string) {
    const value = ';' + document.cookie;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
  }

  /* Template reference of input field
     should be passed to toggleText function
     and it will toggle the input type
     between 'text' and 'password'*/

  public toggleText(ref) {
    if (ref.type === 'password') {
      ref.type = 'text';
    } else {
      ref.type = 'password';
    }
  }

  public return(returnURI, clearSessionStorage) {
    if (this.pnpClientService.isRunningInGlobalNav()) {
      const url = new URL(returnURI, location.origin);
      this.router.navigateByUrl(url.pathname + url.search);
    }else{
      if(clearSessionStorage){
        this._cacheSvc.clearSessionStorage();
      }
      window.location.href = returnURI;
    }
  }

  public getLastFourDigits(txt) {
    return txt?.substring(txt?.length - 4);
  }

  // this method will make a post call to logg requested
  // component name, method name, service name, request object,
  // response object and any other message.
  public setLogger(componetName: any, methodName: any, serviceName: any,
                   requestObj: any, responseObj: any, endPoint: any,  otherMSG: any) {

    if (environment.LOGGING_ENABLED) {
      const loggerHttpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Accept': 'application/json'

          }
        )
      };

      this.body = {
        loggingApiDetails: {
          componetName: componetName,
          serviceName: serviceName,
          requestObj: requestObj === null ? '' : requestObj,
          responseObj: responseObj === null ? '' : responseObj,
          methodName: methodName,
          otherMSG: otherMSG,
          // endPoint: endPoint,
          endPoint: endPoint.replace(/=/g, 'a@b').replace(/&/g, 'b@a'),
          loggingFileApp: 'paymentAppTrace'
        }
      };


      // logging start

      this._envSvc.APIGEE_TOKEN_URLS.LOGGING_API.componetName = componetName;
      this._envSvc.APIGEE_TOKEN_URLS.LOGGING_API.methodName = methodName;
      this._envSvc.APIGEE_TOKEN_URLS.LOGGING_API.serviceName = serviceName;
      this._envSvc.APIGEE_TOKEN_URLS.LOGGING_API.requestObj = requestObj;
      this._envSvc.APIGEE_TOKEN_URLS.LOGGING_API.responseObj = responseObj;
      this._envSvc.APIGEE_TOKEN_URLS.LOGGING_API.otherMSG = otherMSG;
      this._envSvc.APIGEE_TOKEN_URLS.LOGGING_API.endPoint = endPoint;


      this._loggerHttpClient.post(this._envSvc.APIGEE_TOKEN_URLS.LOGGING_API,
      this.body, loggerHttpOptions).toPromise();

      // logging end
    }

  }

  private getStylings(): string {

    let cssStyle: string = '';

    const documentLength = document.getElementsByTagName('style').length;
    for (let j = 0; j < documentLength; j++) {

      cssStyle += document.getElementsByTagName('style')[j].innerHTML;
    }
    return cssStyle;
  }

  private getPrintBody(idSelector: string): string {
    const htmlSelector = document.getElementById(idSelector).innerHTML;
    const body: string = '<html><head><style>'
      .concat('@media print {').concat(this.getStylings())
      .concat('* {-webkit-print-color-adjust: exact !important;color-adjust: exact !important;}')
      .concat('* {position: relative !important;}')
      .concat('*.printable {display: block !important;}')
      .concat('.page-break {page-break-before: always; display: block;}')
      .concat('}')
      .concat('</style></head><body class="ui-dialog">')
      .concat(htmlSelector)
      .concat('</body></html>');
    return body;
  }

  public print(idSelector: string): void {
    let iframe;
    let contentWin;
    let ifrm;
    iframe = document.createElement('iframe');
    iframe.style.height = '0';
    iframe.style.width = '0';
    document.body.appendChild(iframe);
    contentWin = iframe.contentWindow || iframe.window;
    ifrm = (iframe.contentWindow) ?
      iframe.contentWindow : (iframe.contentDocument.document) ?
        iframe.contentDocument.document : iframe.contentDocument;
    ifrm.document.open();

    ifrm.document.onreadystatechange = () => {

      ifrm.document.write(this.getPrintBody(idSelector));
      contentWin.focus();
      const result = contentWin.document.execCommand('print', false, null);
      if (!result) {
        contentWin.print();
      }
    };
    ifrm.document.close();
    iframe.style.display = 'none';
    iframe.parentNode.removeChild(iframe);

  }


  changeTimezone(date) {
    var invdate = new Date(date.toLocaleString('en', {
      timeZone: COMMON_CONSTANTS.PST_TIME_ZONE
    }));
    var diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() - diff);
  }
 public redirectToPartnerBillingApp(){
 this.getPaymentToBillingRedirectUrl().subscribe(
          data => {
             if (!isNullOrUndefined(data) && !isNullOrUndefined(data.redirectUrl)) {
              window.location.href = data.redirectUrl;
            }
         },
       error =>{
         // This is intentional
       });
 }
public getPaymentToBillingRedirectUrl(){

    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
      === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE ? true : false;
      if (isPartnerOnboard) {
    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
      COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken;
    headerSettings['channelid'] = COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_WEB_KEY;
    headerSettings['applicationid'] = COMMON_CONSTANTS.HEADER_PROPERTIES.PARTNER_APPLICATION_ID_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
    headerSettings['activity-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    const httpOptions = {
      headers: new HttpHeaders(headerSettings)
    };
     const requestPayload =  {
        "ban": this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY),
        "orgId": this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY),
        "firstName":JSON.parse(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)).firstName,
        "lastName": JSON.parse(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)).lastName,
        "email": JSON.parse(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)).logonId,
        "redirectUrl": this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.RETURN_URL_SSP),
      };

      if (environment.mocked_all || environment.mocked_endpoints) {
        return this._loggerHttpClient.get(this._envSvc.CURR_SESSION_ENDPOINTS.PARTNER_REDIRECT_TO_BILLING, requestPayload);
      } else {
        return this._loggerHttpClient.post(this._envSvc.CURR_SESSION_ENDPOINTS.PARTNER_REDIRECT_TO_BILLING, requestPayload, httpOptions);
      }

   }
}

    public isCareAuth() {
        return (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) === "okta" || this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) === "azure")
    }
}
