 <!-- model code start here -->
 <div class="BG-modal" [ngStyle]="{'display':display}"></div>
 <div class="modal backToShop" tabIndex="-1" role="dialog" [ngStyle]="{'display':display}" [ngClass]="(display == 'block') ? 'BG-modal' : ''">
 <div *ngIf = "isLoader"><app-loader></app-loader></div>
   <div class="modal-dialog modal-lg" role="document">
     <div class="modal-content" [ngClass]="(display == 'block') ? 'zoomIn' : 'zoomOut'">
       <div class="modal-header">
          <span class="warning-ico"></span>
          <h4 *ngIf="!isHardGoodsEnableMsg">{{authoringContent?.error?.processPaymentError}}</h4> 
          <h4 *ngIf="isHardGoodsEnableMsg">{{authoringContent?.error?.HardGoodsErrorTitleTxt}}</h4> 
         <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
           <span aria-hidden="true">&times;</span>
         </button> 
       </div>  
       <div class="modal-body">
        <div *ngIf="!isHardGoodsEnableMsg">{{authoringContent?.error?.genericErrorApTxt}}</div>
        <div *ngIf="isHardGoodsEnableMsg">{{authoringContent?.error?.HardGoodsErrorTxt}}</div>
         <div class="btn-wrapper text-right">
           <button class="btn primary_btn" (click)="closeModal()">{{authoringContent?.landingInfo?.tryAgainButtonTxt}}</button>
         </div>
       </div>
     </div>
   </div>
   <!-- model code end here -->