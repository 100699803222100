import { ReplaySubject } from 'rxjs';
import { AppProps } from 'single-spa';

import {PnpMessageBus} from '../pnpframework/lib/interfaces/pnp-message-bus';

export const singleSpaPropsSubject = new ReplaySubject<SingleSpaProps>(1);

export type SingleSpaProps = AppProps & {
  messageBus: PnpMessageBus;
};
