import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { EnvironmentDetailsService } from 'src/app/services/environment-details.service';
import { HttpCacheService } from '../services/http-cache.service';
import { COMMON_CONSTANTS } from '../common/core/common.constants';
import { DataSharingService } from '../services/data-sharing.service';
import { UtilsService } from '../services/utils.service';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { PDLService } from '../services/pdl.service';
import { ClickEventType } from 'src/app/common/core/common.interface';
import { NgIf } from '@angular/common';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    standalone: true,
    imports: [NgIf],
})
export class HeaderComponent implements OnInit {
  returnURI: string;
  authoringContent: any;
  enableCancel = true;
  enablePartnerCancel=false;
  enableAhubCancel=false;
  isPartnerFlow: boolean = false;
  constructor(private contentService: ContentService,
    private _envSvc: EnvironmentDetailsService,
    private _cacheSvc: HttpCacheService,
    private dataSharingSvc: DataSharingService,
    private utils: UtilsService,
    private pdlService: PDLService) { }

  ngOnInit() { 
     if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('paymentTypeIndicator'))
          && ((this._cacheSvc.getFromSessionStorage('paymentTypeIndicator') === 'oneTimeLineActivationDeposit')
          || this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW) === 'false')) {
          this.enableCancel = false;
        }
    if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) == 'true'
        && this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_BILLING_PARTNER_FLOW) === 'true') {
         this.enablePartnerCancel = true;
     }
    if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === 'false'){
         this.enableAhubCancel = true;
     }
     if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) == 'true'){
           this.isPartnerFlow = true;
       }
    if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('contentmain'))) {
      const data = JSON.parse(this._cacheSvc.getFromSessionStorage('contentmain'));
      this.authoringContent = data.par.common_payment;
      if (this.authoringContent.error !== undefined && this.authoringContent.error.handshakeFailureErrorTxt !== undefined) {
        this.dataSharingSvc.sendData(this.authoringContent.error.handshakeFailureErrorTxt);
      } else {
        this.dataSharingSvc.sendData(COMMON_CONSTANTS.HANDSHAKE_ERROR_MSG);
      }
    } else {
      this.contentService.GetContentData().subscribe(data => {
        this.authoringContent = data.par.common_payment;
        if (this.authoringContent.error !== undefined && this.authoringContent.error.handshakeFailureErrorTxt !== undefined) {
          this.dataSharingSvc.sendData(this.authoringContent.error.handshakeFailureErrorTxt);
        } else {
          this.dataSharingSvc.sendData(COMMON_CONSTANTS.HANDSHAKE_ERROR_MSG);
        }

      });
    }
    this.returnURI =  this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.RETURN_URL_SSP);
  }

  onHeaderCancel() {
      // analytics click tagging
      this.generateClickDetailsForMakePayment("click", "link", ClickEventType.NAVIGATE, "Cancel", "")
      this.utils.return(this.returnURI, true);
  }

  clickCancel() {
   this.utils.redirectToPartnerBillingApp();
  }

  onTmobileLogo() {
    this._cacheSvc.clearSessionStorage();
  }

  generateClickDetailsForMakePayment(actionInput: string, assetTypeInput: string, typeInput: ClickEventType, ctaNameInput: string, modalNameInput: string) {
    let clickDetail = {
      action: actionInput,
      assetType: assetTypeInput,
      type: typeInput,
      ctaName: ctaNameInput,
      modalName: modalNameInput
    };
    
    this.pdlService.generateClickTag(clickDetail);
  }

}
