import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
  ElementRef,
  ViewChild,
  Injector
} from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule, FormGroup, FormControl} from '@angular/forms';
import { environment } from '../../environments/environment';
import {
  COMMON_CONSTANTS,
  CREDIT_CARD_CONSTANTS,
  ACH_CONSTANTS} from '../common/core/common.constants';
import { BankAutoPayObj, BankBillPayAliasObj, BankPaymentValidation, EncryptionKey, accountDetailsRequestData } from '../common/models/data.model';
import { ACHService } from '../services/ach.service';
import { ContentService } from '../services/content.service';
import { EnvironmentDetailsService } from '../services/environment-details.service';
import { ServiceMessage } from '../services/error-message.service';
import { HttpCacheService } from '../services/http-cache.service';
import { UtilsService } from '../services/utils.service';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from "src/app/utilities/helper-utils";
import { MakePaymentService } from '../services/make-payment.service';
import {AutoPaymentService} from "../services/auto-payment.service";
import { LoaderComponent } from '../loader/loader.component';
import { PhoneMaskDirective } from '../directive/phone-mask.directive';
import { CharactersOnlyDirective } from '../directive/characters-only.directive';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownButtonItem, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { OptionalFloatingLabelDirective } from '../directive/floating-label.directive';
import { MaskDirective } from '../directive/mask-directive';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { PipedOperatorsService } from '../common/pipes/customOperators/pipedOperatorsService.service';
import {CreditCardInputDirective} from "../directive/credit-card-input.directive";
import {CvvAchInputDirective} from "../directive/cvv-ach-input.directive";
declare const encrypt;


@Component({
    selector: 'app-ach',
    templateUrl: './ach.component.html',
    styleUrls: ['./ach.component.scss'],
    standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, MaskDirective, NgClass, OptionalFloatingLabelDirective, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgFor, NgbDropdownButtonItem, NgbDropdownItem, CharactersOnlyDirective, PhoneMaskDirective, LoaderComponent, CreditCardInputDirective, CvvAchInputDirective]
})
export class AchComponent implements OnInit, OnDestroy {
  bankName: string;
  selectedAccntType: string;
  achGroup: FormGroup;
  content: any;
  errorAlert: ServiceMessage = { message: null, show: false };
  key1: EncryptionKey = new EncryptionKey();
  achBillPayAliasData: BankBillPayAliasObj = new BankBillPayAliasObj();
  achValData: BankPaymentValidation = new BankPaymentValidation();
  autoPayRequestDataObj: BankAutoPayObj = new BankAutoPayObj();
  showAcountDetails: boolean = false;
  showRoutingNumber: boolean = false;

  inputType: string = "text";

  maskedAccount: string;
  accountDetailsRequestData : accountDetailsRequestData = new accountDetailsRequestData();
  _autoPaymentSvc: AutoPaymentService;
  chgreqOrderid: any;
  saveSuccess = false;
  savePaymentRes: any;
  isRoutingNumberInvalid: boolean;
  encrypedNumber: any;
  mobileDetails: any[]=[];

  public accountTypes: any = [
    { key: ACH_CONSTANTS.CHECKING_KEY, type: COMMON_CONSTANTS.CHECKING_ACCOUNT },
    { key: ACH_CONSTANTS.SAVING_KEY, type: ACH_CONSTANTS.SAVINGS_ACCOUNT }
  ];

  @Input() billingAccNo: any;
  @Input() orderAccId: any;
  @Input() orgAccId: any;
  @Input() flowType: any;
  @Input() banNumber: number;
  @Input() orgId: number;
  @Input() isFuturePayment: boolean;
  @Input() isCurrentPayment: any;
  @Input() isInstallmentRadioSelected: boolean = false;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Output() achBillPayAliasSuccess: EventEmitter<BankPaymentValidation> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onBankInfoRemove: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('routingNumber') routingNumberRef: ElementRef;
  isValid = true;
  retry = CREDIT_CARD_CONSTANTS.ZERO;
  errorMessage: any;
  isAchEncryptComplete: boolean;
  bankNameFloatingCss: any;
  routingNumberLengthErr: boolean;
  maskedChars: string;
  billpayFlag = false;
  entrustUrl: any;
  contentSubscription: Subscription;
  bankNameSubscription: Subscription;
  publicKeySubscription: Subscription;
  cnpSalesSubscription: Subscription;
  billPaySubscription: Subscription;
  submitSubscription: Subscription;
  encriptedSubscription: Subscription;
  phoneNumberError = {status: false, message: ''};
  phoneNumberSuccess = {status: false, message: ''};
  maskingRoutingVal:boolean = true;
  maskingAccountNum:boolean = true;
  isRoutingValid:boolean = false;

  constructor(
    public achService: ACHService,
    private _cacheSvc: HttpCacheService,
    private contentService: ContentService,
    private _envSvc: EnvironmentDetailsService,
    private _paymentSvc: MakePaymentService,
    public utils: UtilsService,
    private injector: Injector,
    private pipedOperatorsService: PipedOperatorsService) {

    this.achGroup = new FormGroup({
      routingNumber: new FormControl('', [Validators.required, Validators.minLength(9)]),
      accountNumber: new FormControl('', [Validators.required, Validators.pattern("^(?!0{6})[0-9A-Za-z]{6,20}$")]),
      accountName: new FormControl('', [Validators.required]),
      accountType: new FormControl('', [Validators.required]),
      bankNameInput: new FormControl('', [Validators.required]),
      bankPhone: new FormControl('', this.isInstallmentRadioSelected ? [Validators.required] : null)
    });
    this.isFuturePayment = false;
    this.bankName = '';
    this.isAchEncryptComplete = false;
    this._autoPaymentSvc = injector.get<AutoPaymentService>(AutoPaymentService);
    this.maskedChars = COMMON_CONSTANTS.MASKING_CHARACTERS;
    this.achGroup.get('bankNameInput').disable();
    }

  ngOnInit() {
    this._toggleInputFields();
    this.achGroup.setValidators([
      (form) => Validators.required(form.get('accountNumber')),
      (form) => Validators.required(form.get('accountName')),
      (form) => Validators.required(form.get('accountType')),
      (form) => Validators.required(form.get('bankNameInput'))
    ])
    this.bankNameFloatingCss = '';
    HttpCacheService.SetCache(COMMON_CONSTANTS.ROUTING_NUMBER,'');
    HttpCacheService.SetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER_VAL,'');

    if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('contentmain'))) {
      const data = JSON.parse(this._cacheSvc.getFromSessionStorage('contentmain'));
      this.content = data.par.common_payment;
      ContentService.SetCache(COMMON_CONSTANTS.CONTENT_CACHE_KEY, this.content);
    } else {
      this.contentService.GetContentData().subscribe(data => {
        this.content = data.par.common_payment;
        ContentService.SetCache(COMMON_CONSTANTS.CONTENT_CACHE_KEY, this.content);
      });
    }

    // ban and OrgID from session storage query Params
    this.banNumber = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY);
    this.orgId = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY);

    this.achService.isBankInfoSaved = Boolean(ContentService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER));
    this.maskedAccount = this.maskedChars +
      (ContentService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER) ?
        this.utils.getLastFourDigits(ContentService.GetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER)) : '');
    if (!this.achService.isBankInfoSaved) {
      this.onSuccess.emit({ response: false, accountNumber: '' });
    } else {
      this.onSuccess.emit({ response: true, accountNumber: this.achGroup.value.accountNumber });
    }

    if (this.flowType === COMMON_CONSTANTS.BILL_PAY) {
      this.billpayFlag = true;
    }
    if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) === COMMON_CONSTANTS.OKTA_KEY ||
        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE) === COMMON_CONSTANTS.AZURE_KEY) {
      this.achGroup.disable();
    }

    this._paymentSvc.makePaymentToAch.subscribe((data) => {
        this.achGroup.patchValue({ accountType: '' });
        this.achGroup.reset();
    });
    this._paymentSvc.processPaymentErrorCounter.subscribe(data => {
      if (data > 0 && data <= 2) {
        this.selectedAccntType = undefined;
        this.routingNumberRef.nativeElement.focus();
      }
    });
    this.achGroup.get('routingNumber').markAsTouched();
  }

  public removeBankInfo() {
    this.achService.isBankInfoSaved = false;
    this.bankNameFloatingCss = '';
    ContentService.ClearCacheKey(COMMON_CONSTANTS.ACCOUNT_NUMBER);
    this.onBankInfoRemove.emit(this.achService.isBankInfoSaved);
    this.onSuccess.emit({
      response: false,
      accountNumber: this.achGroup.value.accountNumber
    });
  }

  public selectAcntType(accountType: any) {
    this.selectedAccntType = accountType.type;
    ContentService.SetCache(COMMON_CONSTANTS.SELECTED_ACCOUNT_TYPE, this.selectedAccntType);
    this.achGroup.patchValue({ accountType: accountType.key });
    this._paymentSvc.achDataTopayment(this.achGroup);
  }

  validateAcntName() {
    this._paymentSvc.achDataTopayment(this.achGroup);
  }

  validateAcntNumbr(value) {
    if(/^\d+$/.test(value)){
      this.achGroup.get('accountNumber').markAsTouched();
      this.achGroup.get("accountNumber")?.updateValueAndValidity();

      HttpCacheService.SetCache(COMMON_CONSTANTS.ACCOUNT_NUMBER_VAL, value);

      this._paymentSvc.achDataTopayment(this.achGroup);
    }
    else{ // edge case discovered during testing showing incorrect error message
      this.achGroup.patchValue({ accountNumber: value.replace(/[^0-9]*/g, '') });
    }
  }

  validateRoutingNumber(value) {
    this.achGroup.get('routingNumber').markAsTouched();
    this.achGroup.get("routingNumber")?.updateValueAndValidity();
    const routingInput = value.replace(/[^0-9]*/g, '');
    if(routingInput.length == COMMON_CONSTANTS.NINE){
      this.achService.getACHBankName(routingInput).subscribe(data => {
        this.bankName = data.bankName;
        ContentService.SetCache(COMMON_CONSTANTS.BANKNAME_TXT, this.bankName);
        if (this.bankName && this.bankName.length > COMMON_CONSTANTS.ZERO) {
          this.bankNameFloatingCss = 'bank-floating-label-up';
        }

        this.achGroup.patchValue({ bankNameInput: this.bankName });
        this.isRoutingNumberInvalid = false;
        this._toggleInputFields(true);
        this.routingNumberLengthErr = true;
        this.achGroup.markAllAsTouched();
        this._paymentSvc.achDataTopayment(this.achGroup);
      }, (err) => {
        this.isRoutingNumberInvalid = true;
        this._toggleInputFields();
      });
    }
    else{ // Field likely deleted; reset bank name
        this.routingNumberLengthErr = false;
        this.isRoutingNumberInvalid = false;
        this.achGroup.patchValue({ bankNameInput: '' });
        this.bankName = '';

    }

    HttpCacheService.SetCache(COMMON_CONSTANTS.ROUTING_NUMBER, this.achGroup.get('routingNumber')?.value);

    this._paymentSvc.achDataTopayment(this.achGroup);

  }

  validateBankName() {
    this._paymentSvc.achDataTopayment(this.achGroup);
  }

  public getHostName() {
    const url = window.location.href;
    const arr = url.split('/');
    return arr[0] + '//' + arr[2]; //host
  }

  onEntrust() {

    this.entrustUrl =  this.content.landingInfo.enTrustLogoUrl.replace('[host]', location.host);

    window.open(this.entrustUrl, '', 'width=537,height=457,left=19%,top=50,sceenX=19%,screenY=50');

  }

  onEntrustPrivacy() {

    window.location.href = this.content.enTrustPrivacyUrl;

  }

  ngOnDestroy() {

    if (this.billPaySubscription) {
      this.billPaySubscription.unsubscribe();
    }

    if (this.bankNameSubscription) {
      this.bankNameSubscription.unsubscribe();
    }

    if (this.submitSubscription) {
      this.submitSubscription.unsubscribe();
    }

    if (this.achBillPayAliasSuccess) {
      this.achBillPayAliasSuccess.unsubscribe();
    }

    if (this.cnpSalesSubscription) {
      this.cnpSalesSubscription.unsubscribe();
    }

    if (this.encriptedSubscription) {
      this.encriptedSubscription.unsubscribe();
    }

  }

  public checkPhoneNumber(phoneNumber: string) {
    phoneNumber = phoneNumber.replace(/\D/g,'');
    if (phoneNumber.length == 10) {
        this.accountDetailsRequestData = new accountDetailsRequestData();
        this.accountDetailsRequestData.billingAccount = this.banNumber.toString();
        this.accountDetailsRequestData.msisdn = phoneNumber;
        this._autoPaymentSvc.getAccountDetails(this.accountDetailsRequestData)
          .pipe(this.pipedOperatorsService.subReattempt())
          .subscribe({
            next: () => {
              // 200 response, which means phoneNumber is associated to ban
              this._cacheSvc.putInSessionStoarage('phoneNumber', phoneNumber);
              this.phoneNumberError.status = false;
              this.phoneNumberError.message = '';
              this.phoneNumberSuccess.status = true;
              this.phoneNumberSuccess.message = 'Account associated number';
            },
            error: () => {
              // 400 code if phone number is not part of ban
              this.phoneNumberError.status = true;
              this.phoneNumberError.message = 'Number not associated with account';
              this._cacheSvc.deleteFromSessionStoarage('phoneNumber');
              this.phoneNumberSuccess.status = false;
              this.phoneNumberSuccess.message = '';
            },
            complete: () => {
              this._paymentSvc.achDataTopayment(this.achGroup);
            }
          });
    }
    else {
      this._cacheSvc.deleteFromSessionStoarage('phoneNumber');
      this._resetPhoneNumberStatus(phoneNumber.length);
      this._paymentSvc.achDataTopayment(this.achGroup);
    }
  }
  toggleAcountDetails(){
    this.showAcountDetails = !this.showAcountDetails;
  }
  toggleRoutingNumber() {
    this.showRoutingNumber = !this.showRoutingNumber;
  }
  private _toggleInputFields(enable?: boolean) {
    if (enable) {
      this.achGroup.get('accountNumber').enable();
      this.achGroup.get('accountName').enable();
      this.achGroup.get('accountType').enable();
      this.achGroup.get('bankPhone')?.enable();
    } else {
      this.achGroup.get('accountNumber').disable();
      this.achGroup.get('accountName').disable();
      this.achGroup.get('accountType').disable();
      this.achGroup.get('bankPhone')?.disable();
    }
  }

  private _resetPhoneNumberStatus(phoneNumberLength: number) {
    this.phoneNumberError.message = '';
    this.phoneNumberSuccess.status = false;
    this.phoneNumberSuccess.message = '';
    this.phoneNumberError.status = phoneNumberLength == 0 ? false : true;
  }
}
