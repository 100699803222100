import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { COMMON_CONSTANTS, EipPaymentConstants } from '../common/core/common.constants';
import { CCEipPaymentModal, DeviceLoanDetailsRequestBody } from '../common/models/data.model';
import { EnvironmentDetailsService } from './environment-details.service';
import { HttpCacheService } from './http-cache.service';
import { AuthHttpService } from './http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class EipPaymentService {

  orgID: any;

  constructor(private httpClient: AuthHttpService, private _envSvc: EnvironmentDetailsService,
    private _cacheSvc: HttpCacheService) { }

  makeEipPayment(payload: CCEipPaymentModal) {
      const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
        === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE ? true : false;
      const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
  
      const headerSettings: { [name: string]: string | string[]; } = {};
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
        COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken;
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
      headerSettings['channel-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_WEB_KEY;
      if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
        headerSettings['eid'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID);
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
        headerSettings[COMMON_CONSTANTS.QUERY_PARAM_DS] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS);
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.X_AUTH_ORIGINATOR_KEY] = accessToken;
      }
      if (isPartnerOnboard) {
        headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.PARTNER_APPLICATION_ID_KEY;
        }else{
        headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY;
        }
      headerSettings['activity-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
      headerSettings['interaction-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
      headerSettings['origin-application-id'] = EipPaymentConstants.ORIGIN_APPLICATION_ID;
      headerSettings['workflow-id'] = COMMON_CONSTANTS.WORKFLOW_ID_LINE_DEPOSIT;
      headerSettings['session-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
      headerSettings['recaptcha-auth'] = payload?.recaptchaResponse;
      
      if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID)){
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_USER_ID]=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID);
      }
      const httpOptions = {
        headers: new HttpHeaders(headerSettings)
      };

        if (environment.mocked_all || environment.mocked_endpoints) {
          return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.EIP_STANDALONE_PAYMENT, payload);
        } else {
          return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.EIP_STANDALONE_PAYMENT, payload, httpOptions);
        }
  }

  
  getDeviceDetails(payload: DeviceLoanDetailsRequestBody) {
    
    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
      === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE ? true : false;

    if(isPartnerOnboard){
      const headerSettings: { [name: string]: string | string[]; } = {}
      headerSettings['activity-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
      headerSettings['interaction-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
      headerSettings['origin-application-id'] = EipPaymentConstants.ORIGIN_APPLICATION_ID;
      headerSettings['workflow-id'] = COMMON_CONSTANTS.WORKFLOW_ID_LINE_DEPOSIT;
      headerSettings['session-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
        COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken;
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.X_AUTH_ORIGINATOR_KEY] = accessToken;
      headerSettings['channel-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_WEB_KEY;
      headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.PARTNER_APPLICATION_ID_KEY;
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
      headerSettings['eid'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID);
      headerSettings[COMMON_CONSTANTS.QUERY_PARAM_DS] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS);
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
      if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID)){
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_USER_ID]=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID);
      }
      const httpOptions = {
        headers: new HttpHeaders(headerSettings)
      };
      
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.FINANCE_AGREEMENTS, payload);
      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.FINANCE_AGREEMENTS, payload, httpOptions);
      }
    }
  }
}
