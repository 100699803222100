import { Component, NgZone, OnInit } from "@angular/core";
import { LoaderService } from "../services/loader.service";
import { NgIf } from "@angular/common";

@Component({
    selector: "app-loader",
    templateUrl: "./loader.component.html",
    standalone: true,
    imports: [NgIf],
})
export class LoaderComponent implements OnInit {
  constructor(private loaderSvc: LoaderService, private zone: NgZone) {}

  isLoaded = false;
  loaderType: string;

  ngOnInit() {
    this.loaderSvc.loader.subscribe((data) => {
      this.zone.run(() => {
        this.loaderType = data.type;
        this.isLoaded = Boolean(data.isDsiplay);
      });
    });
  }
}