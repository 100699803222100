import { Directive, HostListener } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[creditCardInput]'
})
export class CreditCardInputDirective {
    @HostListener('input', ['$event'])
    onInputChange(event: Event): void {
        const input = event.target as HTMLInputElement;
        const initialValue = input.value;

        // Allow only numbers and spaces
        input.value = initialValue.replace(/[^0-9\s]/g, '');

        // If the value has changed, trigger an input event to update the model
        if (initialValue !== input.value) {
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                HTMLInputElement.prototype, 'value')!.set;
            nativeInputValueSetter!.call(input, input.value);

            const event = new Event('input', { bubbles: true });
            input.dispatchEvent(event);
        }
    }
}
