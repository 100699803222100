import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

export function formatPhone(phoneValue: number | string, country: string): string {
  try {
    const phoneNumber = parsePhoneNumber(
      phoneValue + "",
      country as CountryCode
    );
    return phoneNumber?.formatNational();
  } catch (error) {
    return phoneValue?.toString();
  }
}

@Pipe({
    name: 'phone',
    standalone: true
})
export class PhonePipe implements PipeTransform {

  transform(phoneValue: number | string, country: string): string {
    return formatPhone(phoneValue, country);
  }

}
