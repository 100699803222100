import { Component, OnInit, EventEmitter,Output,Input } from '@angular/core';
import { COMMON_CONSTANTS, MakePaymentConstant, ACH_CONSTANTS, CREDIT_CARD_CONSTANTS } from '../../common/core/common.constants';
import { staticContent } from "../../../environments/environment.content";
import { NgStyle } from '@angular/common';
@Component({
    selector: 'app-enroll-credit-card',
    templateUrl: './enroll-credit-card.component.html',
    styleUrls: ['./enroll-credit-card.component.scss'],
    standalone: true,
    imports: [NgStyle]
})
export class EnrollCreditCardComponent implements OnInit {
  @Input() authoringContent: any;
  display = COMMON_CONSTANTS.BLOCK;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();

  autopayDiscountTitle = staticContent.components.overlayMessage.autopayDiscountTitle;
  autopayDiscountDescription = staticContent.components.overlayMessage.autopayDiscountDescription;
  autopayDiscountConfirnmation = staticContent.components.overlayMessage.yes
  autopayDiscountCancel = staticContent.components.overlayMessage.no

  constructor() {
    this.display='block'
   }

  ngOnInit(): void {
    this.display='block'
  }

  onCloseHandled(evt){
    this.display = COMMON_CONSTANTS.NONE;
    this.onSuccess.emit({
      enrollCredit: 'cancel'
    });
  }

  submitConfirm(evt){
    this.display = COMMON_CONSTANTS.NONE;
    this.onSuccess.emit({
      enrollCredit: 'confirm'
    });
  }

}
