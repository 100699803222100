import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { v4 as uuid } from 'uuid';
import { environment } from '../../environments/environment';
import { PnpClientService } from '../../pnpframework/lib/services/pnp-client.service';
import { COMMON_CONSTANTS, MakePaymentConstant, SPECIAL_HEADER_HANDLE_LIST, TOKEN_DEFAULTS, API_PARAMS_CONST } from '../common/core/common.constants';
import { EnvironmentDetailsService } from './environment-details.service';
import { HttpCacheService } from './http-cache.service';
import { AuthHttpService } from './http-wrapper.service';
@Injectable({
  providedIn: 'root'
})
export class ApigeeTokenService {
  public accessToken: any = '';
  private _isTokenRefrshed = false;
  caller: number;

  constructor(private _http: AuthHttpService, private _cacheSvc: HttpCacheService,
    private _envSvc: EnvironmentDetailsService, private _httpclient: HttpClient,
    private _pnpClient: PnpClientService) { }

  _accessTokenSubscriber: Subscription;

  //  convert this to async serial
  public getTokens(): any { 
  if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY))
                && this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY) < new Date().getTime()) {
                 sessionStorage.removeItem(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
                 sessionStorage.removeItem(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY);
               }
    if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)  === 'true' || environment.DISABLE_ACCESS_TOKEN_CALL=== true){
             return this.getSecureAccessToken().then((apiToken) => {
               if (apiToken) {
                 this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY, apiToken.at);
                 this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY, apiToken.ts);
                 //  new Date(new Date().getTime()).setSeconds(apiToken.ts));
                 this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_AT, apiToken.at);
                 this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_EPID, decodeURIComponent(apiToken.epid));
                 this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_DS, apiToken.ds);
                 this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.QUERY_PARAM_TS, apiToken.ts);
                 const duration = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY) - new Date().getTime();
                 this._retryTokenCalls(duration - TOKEN_DEFAULTS.RETRY_INTERVAL);
               } else {
                 const duration = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY) - new Date().getTime();
                 if (duration > TOKEN_DEFAULTS.RETRY_INTERVAL) {
                   this._retryTokenCalls(duration - TOKEN_DEFAULTS.RETRY_INTERVAL);
                 } else {
                   this._isTokenRefrshed = true;
                   this.getTokens();
                 }
               }
              }, (error) => {
               this._handleAccessTokenError(error);
             });

        } else {
      return this.getAccessToken().then((apiToken) => {
      if (apiToken) {
                 this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY, apiToken.at);
                 const duration = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY) - new Date().getTime();
                 this._retryTokenCalls(duration - TOKEN_DEFAULTS.RETRY_INTERVAL);
      } else {
        const duration = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY) - new Date().getTime();
        if (duration > TOKEN_DEFAULTS.RETRY_INTERVAL) {
          this._retryTokenCalls(duration - TOKEN_DEFAULTS.RETRY_INTERVAL);
        } else {
          this._isTokenRefrshed = true;
          this.getTokens();
        }
      }
     }, (error) => {
      this._handleAccessTokenError(error);
    });
    }
  }

  private getAccessToken(): any {
    if (typeof (Storage) !== COMMON_CONSTANTS.UNDEFINED_KEY) {

      if (!this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY) ||
        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY) < new Date().getTime() ||
        this._isTokenRefrshed) {

        const httpOptions = {
          headers: new HttpHeaders(
            {
              'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
              'Authorization': COMMON_CONSTANTS.HEADER_PROPERTIES.BASIC_KEY + environment.APIGEE_KEY,
              'B2b-org': sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY)
            }
          )
        };
        return this._http.post(this._http.regeneratePath(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_REFRESH_TOKEN,
          this._envSvc.APIGEE_TOKEN_URLS.APIGEE_REFRESH_TOKEN.path +
          this._envSvc.APIGEE_TOKEN_URLS.APIGEE_REFRESH_TOKEN.queryparam + Date.now()), httpOptions).toPromise();

      }
      return Promise.resolve();
     }

  }

 private getSecureAccessToken(): any {
    if (typeof (Storage) !== COMMON_CONSTANTS.UNDEFINED_KEY) {

        if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY))
              && this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY) < new Date().getTime() ||
              this._isTokenRefrshed) {
              const idpType = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.AUTH_TYPE);
              const isPartner = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE ? true : false;
              const httpOptions = {
                headers: new HttpHeaders(
                  {
                    'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
                    'activity-id' : uuid(),
                    'b2b-user-id' : (idpType == COMMON_CONSTANTS.OKTA_KEY || idpType == COMMON_CONSTANTS.IAM_KEY || idpType == COMMON_CONSTANTS.AZURE_KEY) ? this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID) : '',
                    'B2b-client': (isPartner) ? this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY) : API_PARAMS_CONST.B2B_CLIENT,
                    'B2b-org': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY),
                    'Authorization': COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_AT),
                    'eid': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID),
                    'ds': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS)
                  }
                )
              };
              if (environment.mocked_all || environment.mocked_endpoints) {
                return this._http.get(this._envSvc.APIGEE_TOKEN_URLS.SECURE_TOKEN_REFRESH).toPromise();
              } else {
                  return this._http.get(this._http.regeneratePath(this._envSvc.APIGEE_TOKEN_URLS.SECURE_TOKEN_REFRESH,
                    this._envSvc.APIGEE_TOKEN_URLS.SECURE_TOKEN_REFRESH.path +
                    this._envSvc.APIGEE_TOKEN_URLS.SECURE_TOKEN_REFRESH.queryparam + Date.now()), httpOptions).toPromise();
              }
            }

       if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_AT))
                    && !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_TS))
                    && this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_AT).toString().length > 0
                    && this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_TS).toString().length > 0) {
                    this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY,
                      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_AT));
                    this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY,
                      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_TS));
                  } else {
                    const accessTokenError = { errorCode: 'Error from Parent App' };
                    this._handleAccessTokenError(accessTokenError);
                  }

      return Promise.resolve();
     }

  }

  async setRequestHeaders(req: HttpRequest<any>): Promise<HttpHeaders> {
    const headerSettings: { [name: string]: string | string[]; } = {};
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;

    for (const key of req.headers.keys()) {
      headerSettings[key] = req.headers.getAll(key);
    }
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY +
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    if(!isPartnerOnboard){
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.X_AUTH_ORIGINATOR_KEY] = (await this._pnpClient.getIdToken()).toString();
    }
    else {
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.X_AUTH_ORIGINATOR_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    }
    
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] =
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] ?
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] : sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.EID] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID);
      headerSettings[COMMON_CONSTANTS.QUERY_PARAM_DS] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS);
      headerSettings['activity-id'] = uuid();
      headerSettings['interaction-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
      headerSettings['workflow-id'] = this._cacheSvc.getFromSessionStorage(MakePaymentConstant.FLOW_TYPE.FLOW_TYPE_TEXT);
      headerSettings['session-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
      if(this._needSpeicalHeaderHandle(req)){
        if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID)){
          headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_USER_ID]=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID);
          headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_USERID]=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID);
        }
      }
    }
    return new HttpHeaders(headerSettings);
  }

  private _needSpeicalHeaderHandle(req: HttpRequest<any>) {
    for (const routeItem of SPECIAL_HEADER_HANDLE_LIST) {
      if (req.urlWithParams.search(routeItem.route) !== -1) {
        return true;
      }
    }
    return false;
  }

  private _handleAccessTokenError(error) {
    if (this._accessTokenSubscriber) {
      this._accessTokenSubscriber.unsubscribe();
    }
  }

  private _retryTokenCalls(timeDuration) {
    if (this._cacheSvc.getFromSessionStorage("isPartner") === "false") {
      setTimeout(() => {
        this._isTokenRefrshed = true;
        this.getTokens();
      }, timeDuration);
    }

  }

  public getTokenAnonymous(): any {
    if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY))
                  && this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY) < new Date().getTime()) {
                   sessionStorage.removeItem(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
                   sessionStorage.removeItem(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY);
                 }
        if (this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY) !== '')  {
          return this.getAccessToken().then((apiToken) => {
            if (apiToken) {
                      this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY, apiToken.expires_in);
                      this._cacheSvc.putInSessionStoarage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY, apiToken.access_token);
                      this._cacheSvc.putInSessionStoarage('id_token', apiToken.id_token);
                      const duration = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY) - new Date().getTime();
                                       this._retryTokenCalls(duration - TOKEN_DEFAULTS.RETRY_INTERVAL);
            } else {
              const duration = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_EXPIRY_KEY) - new Date().getTime();
              if (duration > TOKEN_DEFAULTS.RETRY_INTERVAL) {
                this._retryTokenCalls(duration - TOKEN_DEFAULTS.RETRY_INTERVAL);
              } else {
                this._isTokenRefrshed = true;
                this.getTokenAnonymous();
              }
            }
           }, (error) => {
            this._handleAccessTokenError(error);
          });
        }
        
      }
}
