import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentDetailsService {

  constructor() { 
    // This is intentional
  }

  contentDirectory = '';

  CURR_SESSION_ENDPOINTS;
  CURR_CONTENT_URLS;
  APIGEE_TOKEN_URLS;

  getContentEnvt() {
    return (environment.mocked_all || environment.mocked_content) ?
    import('../common/core/urls.mocks.constants') : import('../common/core/urls.constants');
  }

  getSessionEndpointsEnv() {
    return (environment.mocked_all || environment.mocked_endpoints) ?
    import('../common/core/urls.mocks.constants') : import('../common/core/urls.constants');
  }


}
