import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { from, Observable, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import {
  COMMON_CONSTANTS,
  CLONE_URL_IGNORE_LIST,
  ACHCC_URL_IGNORE_LIST,
  LOGGING_URL_IGNORE_LIST,
  REDFLAG_URL,
} from "../common/core/common.constants";
import { ApigeeTokenService } from "./apigee-token.service";
import { ErrorMessageService } from "./error-message.service";
import { LoaderService } from "./loader.service";
import {
  APIGEE_TOKEN_URL,
  CONTENT_PAGES_URL,
  WCS_ENPOINT_CONST,
} from "../common/core/urls.constants";
import * as _ from "lodash";
import { UtilsService } from "./utils.service";
import { NgxLoggerLevel } from "ngx-logger";
import { SplunkUtilityService } from "../utilities/splunk-utility.service";
import { HttpCacheService } from "./http-cache.service";
import { isNullOrUndefined } from "src/app/utilities/helper-utils";
import { PnpClientService } from "src/pnpframework/public-api";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private _cacheSvc: HttpCacheService,
    private _apigeeSvc: ApigeeTokenService,
    private _errorService: ErrorMessageService,
    private _loaderSvc: LoaderService,
    private utils: UtilsService,
    private splunkSvc: SplunkUtilityService,
    private pnpClientService: PnpClientService
  ) {}
  counter = 0;
  resObject: any = { errorCode: "", errorMessage: "", errorType: "" };
  requests: Map<string, Observable<HttpEvent<any>>> = new Map<string, Observable<HttpEvent<any>>>();
  isLoggingSvcCall = false;

  intercept(req: HttpRequest<any>, next: HttpHandler) {  
    let urlProperties = {};
    const normalizedUrl = this.getNormalizedUrl(req);

    if (req.params) {
      urlProperties = req.params.get("metaData");
      req.params.delete("metaData");
    }
    if (
      normalizedUrl !== APIGEE_TOKEN_URL.LOGGING_API.path &&
      normalizedUrl !== APIGEE_TOKEN_URL.SPLUNK_LOG.path
    ) {
      this.counter++;
    }
    if (this.counter === 1 && !_.get(urlProperties, "hideLoaderIcon")) {
      this._loaderSvc.start();
    }
    req = req.clone({ params: req.params.delete("metaData") });

    const promise = !this._filterTokenUrl(req)
      ? this._apigeeSvc.setRequestHeaders(req)
      : Promise.resolve(null);
    return from(promise).pipe(
      switchMap((hds) => {
        if (hds) {
          req = req.clone({
            headers: hds
          });
        }
        this.requests.set(req.urlWithParams, next.handle(req));
        return this.requests.get(req.urlWithParams).pipe(
          tap(
            (succ) => {
              if (succ instanceof HttpResponse) {
                // code added for the b2b-requestid

                const requestDate = succ.headers.getAll("date");
                if (!(_.isNull(requestDate) || _.isUndefined(requestDate))) {
                  this._cacheSvc.putInSessionStoarage(
                    COMMON_CONSTANTS.REQUEST_DATE,
                    requestDate
                  );
                }

                const b2bRequestid = succ.headers.getAll(
                  COMMON_CONSTANTS.B2B_REQUESTID
                );
                if (!(_.isNull(b2bRequestid) || _.isUndefined(b2bRequestid))) {
                  this._cacheSvc.putInSessionStoarage(
                    COMMON_CONSTANTS.B2B_REQUESTID,
                    b2bRequestid
                  );
                }

                if (!this._filterLoggingUrl(req)) {
                  this.splunkSvc.logInSplunk(NgxLoggerLevel.LOG, req, succ);
                }
                // Logging for all responses start
                if (
                  succ instanceof HttpResponse &&
                  succ["body"] &&
                  !succ["body"].errorKey &&
                  succ["body"].hasOwnProperty("par") === false
                ) {
                  // tslint:disable-next-line: max-line-length
                  if (
                    normalizedUrl !==
                      CONTENT_PAGES_URL.CONTENT_ASOP_CHECKOUT_PAGE.path &&
                    normalizedUrl !== APIGEE_TOKEN_URL.LOGGING_API.path
                  ) {
                    this.utils.setLogger(
                      "",
                      "",
                      "",
                      req.body,
                      succ.body,
                      req.url,
                      ""
                    );
                  }
                }
                // './assets/tfb-payment/content-and-assets/rest-apis/errorCodeAddress.json'

                if (
                  normalizedUrl === APIGEE_TOKEN_URL.CREATE_PA_PAYMENT_PARTNER.path ||
                  normalizedUrl === APIGEE_TOKEN_URL.EDIT_PA_PAYMENT_PARTNER.path ||
                  normalizedUrl === APIGEE_TOKEN_URL.SCHEDULE_PAYMENT_PARTNER.path ||
                  normalizedUrl === APIGEE_TOKEN_URL.HARD_GOODS_AUTH_PAYMENT_PARTNER.path ||
                  normalizedUrl === WCS_ENPOINT_CONST.ENROLL_AUTO_PAY_SERVICE_URL_PARTNER.path ||
                  normalizedUrl ===
                    "./assets/tfb-payment/content-and-assets/rest-apis/autopay-enroll-response.json"
                ) {
                  this._errorService.mpiServiceNewErrorCode(succ);
                }
                // normalizedUrl === './assets/tfb-payment/content-and-assets/rest-apis/processErrorCodeNegativeBanBlock.json'
                if (
                  normalizedUrl === APIGEE_TOKEN_URL.MAKE_PAYMENT.path ||
                  normalizedUrl ===
                    "./assets/tfb-payment/content-and-assets/rest-apis/processErrorCodeNegativeBankBlock.json" ||
                    normalizedUrl === APIGEE_TOKEN_URL.MAKE_PAYMENT_PARTNER.path ||
                  normalizedUrl === APIGEE_TOKEN_URL.SIMONHANDS_PAYMENT.path ||
                  normalizedUrl === APIGEE_TOKEN_URL.EIP_STANDALONE_PAYMENT.path
                ) {
                  this._errorService.processPaymentServiceNewErrorCode(succ);
                }

                // Logging for all responses end

                if (
                  (succ["body"] && succ["body"].error) ||
                  succ["errorKey"] ||
                  succ["errorMessage"]
                ) {
                  const errorKey = succ["body"].error.errorKey;
                  const genericErrorMessage = succ["body"].error.errorMessage;
                  this.resObject.errorCode = errorKey;
                  this.resObject.errorMessage = genericErrorMessage;
                  this.resObject.errorType = "SucessError";
                  if (errorKey || genericErrorMessage) {
                    if (
                      normalizedUrl !==
                        CONTENT_PAGES_URL.CONTENT_ASOP_CHECKOUT_PAGE.path &&
                      normalizedUrl !== APIGEE_TOKEN_URL.LOGGING_API.path
                    ) {
                      this.utils.setLogger(
                        "",
                        "",
                        "",
                        req.body,
                        this.resObject,
                        req.url,
                        "Error in Service"
                      );
                    }
                    this._errorService.serviceError(this.resObject, true);
                    this.splunkSvc.logInSplunk(
                      NgxLoggerLevel.ERROR,
                      req,
                      this.resObject
                    );
                  }
                }

                if (
                  normalizedUrl === APIGEE_TOKEN_URL.LOGGING_API.path ||
                  normalizedUrl === APIGEE_TOKEN_URL.SPLUNK_LOG.path
                ) {
                  this.isLoggingSvcCall = true;
                }
                this.updateLoader();
                return succ;
              }
            },
            (error) => {

              if (
                normalizedUrl === APIGEE_TOKEN_URL.LOGGING_API.path ||
                normalizedUrl === APIGEE_TOKEN_URL.SPLUNK_LOG.path
              ) {
                this.isLoggingSvcCall = true;
              }
              this.updateLoader(true);
              this.handleError(error, req, next);
            }
          )
        );
      }),
      catchError((error) => {
        return throwError(error);
      })
    );

  }
  private _filterTokenUrl(req: HttpRequest<any>) {
    for (const routeItem of CLONE_URL_IGNORE_LIST) {
      if (
        req.urlWithParams.indexOf(routeItem.route) !== -1 &&
        req.urlWithParams.indexOf("handshake/payment/refresh") < 0
      ) {
        return true;
      }
    }
    return false;
  }

  private _filterAchCCUrl(req: HttpRequest<any>) {
    for (const routeItem of ACHCC_URL_IGNORE_LIST) {
      if (req.urlWithParams.indexOf(routeItem.route) !== -1) {
        return true;
      }
    }
    return false;
  }

  private _filterLoggingUrl(req: HttpRequest<any>) {
    for (const routeItem of LOGGING_URL_IGNORE_LIST) {
      if (req.urlWithParams.indexOf(routeItem.route) !== -1) {
        return true;
      }
    }
    return false;
  }
  private handleError( 
    error: any,
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    const normalizedUrl = this.getNormalizedUrl(req);

    // Check Pretty print
    const errorWithReqBody = Object.assign(error, { requestBody: req.body });

    if (!this._filterLoggingUrl(req)) {
      this.splunkSvc.logInSplunk(NgxLoggerLevel.ERROR, req, errorWithReqBody);
    }

    if (error instanceof HttpErrorResponse) {
      if (error.message && error.status !== 404) {
        this.resObject.errorCode = error.status;
        this.resObject.errorType = "Server";
        this.resObject.errorMessage =
          "Unable to proceed for making a payment. Please call Business Care.";
        this.resObject.show = true;

        if (!this._filterAchCCUrl(req)) {
          if (REDFLAG_URL.indexOf(req.url) > -1) {
            this.resObject.errorMessage =
              "System is down, Please check back later";
            this._errorService.RedFlagErrorMessgae(this.resObject, true);
          } else {
            this._errorService.serverErrorMessgae(this.resObject, true);
          }

          this._errorService.serverErrorMessgae(this.resObject, true);
        }
      }
      if (
        normalizedUrl !== CONTENT_PAGES_URL.CONTENT_ASOP_CHECKOUT_PAGE.path &&
        normalizedUrl !== APIGEE_TOKEN_URL.LOGGING_API.path
      ) {
        this.utils.setLogger(
          "",
          "",
          "",
          req.body,
          this.resObject,
          req.url,
          "Error in Service"
        );
      }
      // Retry utility will be written to refreh tokens withh number of tries
      if ([401, 400, 500].includes(error.status)) {
        if (error.url.indexOf("/b2b-experience/v1/billing-accounts") !== -1) {
          return throwError("Temporary Error");
        }
        if (error.url.indexOf(APIGEE_TOKEN_URL.APIGEE_REFRESH_TOKEN) === -1) {
          // This is intentional
        }
      }
    }

    this.asyncError(error instanceof Response)
    return throwError(error || "Getting Error From Server");
  }

  private async asyncError(error) {
    if (error instanceof Response) {
      let errorMessage: Promise<any>;
      try {
        errorMessage = await error.json();
      } catch (err) {
        errorMessage = await err.statusText;
      }
      return throwError(errorMessage);
    }
  }

  private updateLoader(isErroredOut?) {
    if (!this.isLoggingSvcCall) {
      this.counter--;
    }
    if (
      (isErroredOut && !this.isLoggingSvcCall) ||
      this.counter === 0 ||
      isNullOrUndefined(this.counter)
    ) {
      this._loaderSvc.stop();
    }
    this.isLoggingSvcCall = false;
  }

  private getNormalizedUrl(req: HttpRequest<any>): string {
    return this.pnpClientService.isRunningInGlobalNav()
      ? (req.url || "").replace(environment.PNP_CONFIG.xhrPrefix, "")
      : req.url;
  }
}
