import { Injectable } from "@angular/core";
import { AuthHttpService } from "./http-wrapper.service";
import { EnvironmentDetailsService } from "./environment-details.service";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { COMMON_CONSTANTS } from "../common/core/common.constants";
import { throwError, BehaviorSubject, Subject } from "rxjs";
import { isNullOrUndefined } from "src/app/utilities/helper-utils";
import { HttpCacheService } from "./http-cache.service";
import { environment } from "../../environments/environment";
import { UtilsService } from "./utils.service";
import { catchError, map } from "rxjs/operators";
import { v4 as uuid } from "uuid";

@Injectable({
  providedIn: 'root'
})
export class MakePaymentService {
  requestPayload: any;
  public euiCodeData: any;
  orgID: any;
  isDecryptCallLoaded = new BehaviorSubject(null);
  achData = new BehaviorSubject(null);
  achDataTomakepayment = this.achData.asObservable();

  ccData = new BehaviorSubject(null);
  cardDataTomakepayment = this.ccData.asObservable();

  makePaymentReset = new BehaviorSubject(null);
  makePaymentToAch = this.makePaymentReset.asObservable();
  makePaymentToCC = this.makePaymentReset.asObservable();

  notifyDoubleClick = new Subject();
  notifyCalenderDates = new BehaviorSubject(null);
  notifyCalenderDateInstallment = this.notifyCalenderDates.asObservable();
  datesFromCalender = new BehaviorSubject(null);
  installmentDateFromClander = this.datesFromCalender.asObservable();
  isSecondCalenderSelected = new BehaviorSubject(null);
  isSecondCalendarOpen = this.isSecondCalenderSelected.asObservable();
  autCloseDateRangePicker = new BehaviorSubject(null);
  autoCloseDateRngPicker = this.autCloseDateRangePicker.asObservable();
  processPaymentErrorCounter = new Subject<number>();
  handShakeData: any;
  constructor(private httpClient: AuthHttpService, private _envSvc: EnvironmentDetailsService,
    private _cacheSvc: HttpCacheService, private _httpClient: HttpClient, private utils: UtilsService) {
    this.orgID = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY)) ?
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY) : '';
  }

   setHeaders() {
      const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);

      const headerSettings: { [name: string]: string | string[]; } = {};
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
      headerSettings['applicationid'] = COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY;
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
        COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken;
      headerSettings['channelid'] = COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_WEB_KEY;
      headerSettings['activityid'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TRANSACTIONID);
      headerSettings['authorization-idp'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TRANSACTIONID);
      headerSettings['sender-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TRANSACTIONID);
      const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
            ? true : false;
      if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
          headerSettings['eid'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID);
          headerSettings[COMMON_CONSTANTS.QUERY_PARAM_DS] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS);
          headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
      }
      return {headers: new HttpHeaders(headerSettings)};
    }

  getHeader(orgid) {
    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;

    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.ACCEPT_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings['B2b-Org'] = orgid;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
      COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken;
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
        headerSettings['eid'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID);
        headerSettings[COMMON_CONSTANTS.QUERY_PARAM_DS] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS);
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
    }

    return new HttpHeaders(headerSettings)
  }
  getBalanceApi() {
    let responseObj: any;
    const requestPayload = {
      'billingAccounts': [
        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY)
      ]
    };
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
      === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE ? true : false;
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_BALANCE_PARTNER);
      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_BALANCE_PARTNER, requestPayload, this.getHeader(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY)));
      }
    } else {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_BALANCE);
      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.APIGEE_BALANCE, requestPayload, this.getHeader(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY)));
      }
    }
  }

  getUpdatedBalance() {

    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);

    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
          'Authorization': COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken,
          'Accept': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
          'activityid': COMMON_CONSTANTS.ACTIVITY_ID,

        }
      )
    };

    const requestPayload = [
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.BAN_NUMBER_KEY)
    ];

    return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.CALCULATE_UPDATED_BALANCE, requestPayload, httpOptions);
  }

  makePayment(requestPayload: any) { 

    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
      === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE ? true : false;

    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
      COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken;
    headerSettings['channel-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_WEB_KEY;
    if (isPartnerOnboard) {
    headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.PARTNER_APPLICATION_ID_KEY;
    }else{
    headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY;
    }
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
      headerSettings['eid'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID);
      headerSettings[COMMON_CONSTANTS.QUERY_PARAM_DS] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS);
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
    }
    /// START - Sent below headers in all scenarios - FBEPTD-78312
    headerSettings['activity-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    headerSettings['interaction-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    headerSettings['workflow-id'] = COMMON_CONSTANTS.WORKFLOW_ID_LINE_DEPOSIT;
    headerSettings['session-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    headerSettings['recaptcha-auth'] = requestPayload?.recaptchaResponse;
    
    if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID)){
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_USER_ID]=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID);
    }
    /// END - Sent below headers in all scenarios - FBEPTD-78312
    const httpOptions = {
      headers: new HttpHeaders(headerSettings)
    };

    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.MAKE_PAYMENT_PARTNER, requestPayload);
      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.MAKE_PAYMENT_PARTNER, requestPayload, httpOptions);
      }
    } else {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.MAKE_PAYMENT, requestPayload);
      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.MAKE_PAYMENT, requestPayload, httpOptions);
      }
    }
  }

  public decryptPartnerToken(eid: any, ds: any): any {
    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    let httpOptions: any;

    httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
          'Authorization': COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken,
          'B2b-org': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY),
          'Activity-id': uuid(),
          'B2b-client': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY),
          'eid': eid,
          'ds': ds,
          'x-auth-originator': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY)
        }
      )
    };
    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(this._envSvc.CURR_SESSION_ENDPOINTS.DECRYPT_PARTNER_TOKEN);
    } else {
      return this.httpClient.get(this._envSvc.CURR_SESSION_ENDPOINTS.DECRYPT_PARTNER_TOKEN, httpOptions);
    }
  }

  public decryptPartnerAnonymousToken(eid: any, ds: any): any {
    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    let httpOptions: any;
    httpOptions = {
      headers: new HttpHeaders(
        {
          'Accept': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
          'Content-Type': COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY,
          'Authorization': COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken,
          'B2b-org': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY),
          'B2b-client': this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY),
          'eid': eid,
          'ds': ds,
          'activity-id': this._cacheSvc.getFromSessionStorage('transactionID'),
          'interaction-id': this._cacheSvc.getFromSessionStorage('transactionID'),
          'workflow-id': COMMON_CONSTANTS.WORKFLOW_ID_LINE_DEPOSIT,
          'session-id': this._cacheSvc.getFromSessionStorage('transactionID'),
          'X-Auth-Originator': this._cacheSvc.getFromSessionStorage('id_token')
        }
      )
    };
    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(this._envSvc.CURR_SESSION_ENDPOINTS.DECRYPT_PARTNER_SOH_TOKEN);
    } else {
      return this.httpClient.get(this._envSvc.CURR_SESSION_ENDPOINTS.DECRYPT_PARTNER_SOH_TOKEN, httpOptions);
    }
  }
  updateCROrder(requestPayload: any) { 
    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD) === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE
      ? true : false;
    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      requestPayload.transRefNum=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TRANS_REF_NUM);
      if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('paymentTypeIndicator'))
        && (this._cacheSvc.getFromSessionStorage('paymentTypeIndicator') === 'oneTimeLineActivationDeposit')) {
        const headerSettings: { [name: string]: string | string[]; } = {};
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
        // comment out for nodejs
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
          COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken;
        headerSettings['applicationid'] = COMMON_CONSTANTS.HEADER_PROPERTIES.TFB_APPLICATION_ID_KEY;
        headerSettings['channelid'] = COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_WEB_KEY;

        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
        headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
        headerSettings['activity-id'] = this._cacheSvc.getFromSessionStorage('transactionID');
        headerSettings['interaction-id'] = this._cacheSvc.getFromSessionStorage('transactionID');
        headerSettings['workflow-id'] = COMMON_CONSTANTS.WORKFLOW_ID_LINE_DEPOSIT;
        headerSettings['session-id'] = this._cacheSvc.getFromSessionStorage('transactionID');
        headerSettings["service-transaction-id"] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);


        const httpOptions = {
          headers: new HttpHeaders(headerSettings)
        };
        
        if (environment.mocked_all || environment.mocked_endpoints) {
          return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.UPDATE_CRORDERID_PARTNER);
        } else {
          const url=this._generateURL(this._envSvc.APIGEE_TOKEN_URLS.UPDATE_CRORDERID_PARTNER, 
            COMMON_CONSTANTS.TRANSACTION_PLACEHOLDER, this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TRANSACTION_ID));
          return this.httpClient.put(url, requestPayload, httpOptions)
            .pipe(map(() => {
              // This is intentional
            }),
              catchError(this.handleError)
            );
        }
      } else {
        if (environment.mocked_all || environment.mocked_endpoints) {
          return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.UPDATE_CRORDERID_PARTNER);
        } else {
          const url=this._generateURL(this._envSvc.APIGEE_TOKEN_URLS.UPDATE_CRORDERID_PARTNER,
             COMMON_CONSTANTS.TRANSACTION_PLACEHOLDER, this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.TRANSACTION_ID));
          return this.httpClient.put(url, requestPayload)
            .pipe(map(() => {
              // This is intentional
            }),
              catchError(this.handleError)
            );
        }
      }
    } else {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.UPDATE_CRORDERID);
      } else {
        return this._httpClient.put(this._envSvc.APIGEE_TOKEN_URLS.UPDATE_CRORDERID.path.replace('{orgId}', this.orgID), requestPayload)
          .pipe(map(() => {
            // This is intentional
          }),
            catchError(this.handleError)
          );
      }
    }
  }

  private _generateURL(url, placeholder, value){
    let path = url.path.replace(placeholder, value);
    return this.httpClient.regeneratePath(url, path); 
  }

  private handleError(error: Response) { return throwError(error); }

  achDataTopayment(achData) {
    this.achData.next(achData);
  }

  cardDataTopayment(ccData) {
    this.ccData.next(ccData);
  }

  paymentEligibilityCheck(banNumber) {

    const payLoadObject = {
      billingAccount: banNumber,
      paymentArrangementType: 'REGULAR'
    };
    const httpOptions = this.setHeaders();

    if (environment.ENABLE_PA_V2API === true) {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.INSTALLMENT_ELIGIBILITY_V2, payLoadObject);

      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.INSTALLMENT_ELIGIBILITY_V2, payLoadObject, httpOptions);
      }
    } else {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.INSTALLMENT_ELIGIBILITY, payLoadObject);

      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.INSTALLMENT_ELIGIBILITY, payLoadObject, httpOptions);
      }
    }
  }

  paymentArrangementsQuery(banNumber) {

    const payLoadObject = {
      billingAccount: banNumber,
      paymentArrangementType: 'REGULAR'
    };
    const httpOptions = this.setHeaders();

    if (environment.ENABLE_PA_V2API === true) {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_QUERY_V2);
      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_QUERY_V2, payLoadObject, httpOptions);
      }
    } else {
      if (environment.mocked_all || environment.mocked_endpoints) {
        return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_QUERY);
      } else {
        return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_QUERY, payLoadObject, httpOptions);
      }
    }
  }

  fetchRoles() {
    let apiUrl: any;

    this.handShakeData = !isNullOrUndefined(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA)) ?
      this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.EUI_TOKEN_DATA) : '';
    this.handShakeData = this.handShakeData !== '' ? JSON.parse(this.handShakeData) : '';

    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_FETCH_ACTIVITY_ID_IAM);
    } else {
      apiUrl = this.httpClient.regeneratePath(this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_FETCH_ACTIVITY_ID_IAM,
        this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_FETCH_ACTIVITY_ID_IAM.path.replace('{{uuid}}', this.handShakeData.uuid)
        + this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_FETCH_ACTIVITY_ID_IAM.queryparam.org +
        this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY));
      return this.httpClient.get(apiUrl, this.getHeader(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY)));
    }
  }

  paymentArrangementsFetchRoles_OKTA() {
   
    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_FETCH_USER_ROLE_ACTIVITIES);
    } else {
      return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_FETCH_USER_ROLE_ACTIVITIES);
    }  
  }

  paymentArrangementsFetchActivityID_ByRoles_OKTA(userID, roleID) {
    let apiUrl: any;
    apiUrl = this.httpClient.regeneratePath(this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_FETCH_ACTIVITIESID_BYROLES_OKTA,
      this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_FETCH_ACTIVITIESID_BYROLES_OKTA.path.replace('{{userId}}',
        userID).replace('{{roleId}}', roleID));

    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.PAYMENT_ARRANGEMENTS_FETCH_ACTIVITIESID_BYROLES_OKTA);
    } else {
      return this.httpClient.get(apiUrl,
        this.getHeader(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ORG_ID_KEY)));
    }
  }

  sendSelectedDate(changeDates) {
    this.notifyCalenderDates.next(changeDates);
  }
  sendSelectedDateFromCalender(changeDates) {
    this.datesFromCalender.next(changeDates);
  }

  isSecondCaledarOpen(isSecondCalendar) {
    this.isSecondCalenderSelected.next(isSecondCalendar);
    this.isSecondCalendarOpen.subscribe(data => {
      // This is intentional
    });
  }

  autoCloseDateRangePicker(autoCloseValue) {
    this.autCloseDateRangePicker.next(autoCloseValue);
  }

  makePaymenttoAch(makePaymentReset) {
    this.makePaymentReset.next(makePaymentReset);
  }

  makePaymenttoCC(makePaymentReset) {
    this.makePaymentReset.next(makePaymentReset);
  }

  SimOnHandsPayment(recapchaAuth: any, requestPayload: any) {

    const accessToken = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.ACCESS_TOKEN_KEY);
    const isPartnerOnboard = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.IS_PARTNER_BOARD)
      === COMMON_CONSTANTS.BOOLEAN_PROPERTIES.TRUE ? true : false;

    const headerSettings: { [name: string]: string | string[]; } = {};
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.CONTENT_TYPE_KEY] = COMMON_CONSTANTS.HEADER_PROPERTIES.APPLICATION_JSON_KEY;
    // comment out for nodejs
    headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.AUTHORIZATION_KEY] =
      COMMON_CONSTANTS.HEADER_PROPERTIES.BEARER_KEY + accessToken;
    headerSettings['application-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.PARTNER_APPLICATION_ID_KEY;
    headerSettings['channel-id'] = COMMON_CONSTANTS.HEADER_PROPERTIES.AP_CHANNEL_ID;
    headerSettings['recaptcha-auth'] = recapchaAuth;

    /// START - Sent below headers in all scenarios - FBEPTD-78312
    headerSettings['activity-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    headerSettings['interaction-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    headerSettings['workflow-id'] = COMMON_CONSTANTS.WORKFLOW_ID_LINE_DEPOSIT;
    headerSettings['session-id'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.UNIQUEID_FOR_APIS);
    if(this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID)){
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_USER_ID]=this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.USER_ID);
    }
    /// END - Sent below headers in all scenarios - FBEPTD-78312

    if (isPartnerOnboard || environment.DISABLE_ACCESS_TOKEN_CALL === true) {
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
      headerSettings['eid'] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_EPID);
      headerSettings[COMMON_CONSTANTS.QUERY_PARAM_DS] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.QUERY_PARAM_DS);
      headerSettings[COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_ORG_KEY] = sessionStorage.getItem(COMMON_CONSTANTS.ORG_ID_KEY);
      headerSettings["service-transaction-id"] = this._cacheSvc.getFromSessionStorage(COMMON_CONSTANTS.HEADER_PROPERTIES.B2B_CLIENT_KEY);
    }

    const httpOptions = {
      headers: new HttpHeaders(headerSettings)
    };

    if (environment.mocked_all || environment.mocked_endpoints) {
      return this.httpClient.get(this._envSvc.APIGEE_TOKEN_URLS.SIMONHANDS_PAYMENT, requestPayload);
    } else {
      return this.httpClient.post(this._envSvc.APIGEE_TOKEN_URLS.SIMONHANDS_PAYMENT, requestPayload, httpOptions);
    }
  }

}
