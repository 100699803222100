
// WCS URLS
export const WCS_ENPOINT_CONST: any = {

    GET_SERVICE_TEXT_URL: {
        path: '/b2b-experience/v1/documents/service-agreement',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    GET_SERVICE_TEXT_URL_PARTNER: {
        path: '/b2b-experience/v1/documents/service-agreement',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    GET_GENERAL_TERMS_URL: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/general-agreement-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    GET_SERVICE_CONSENT_URL: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/document-consent-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    GET_SERVICE_CONSENT_URL_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/document-consent-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    EUI_GET_CODE_REDIRECT_URL: {
        path: 'oauth2/v1/auth?client_id=TfBPayment&scope=TMO_ID_profile openid role extended_lines'
            + '&redirect_uri={{host}}&access_type=online&response_type=code',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    DECRYPT_PARTNER_TOKEN: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/decryptpartnertoken.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: { pid: '?pid=' },
        apiName: 'DECRYPT_PARTNER_TOKEN'
    },
    GET_AUTO_PAY_SERVICE_URL: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/easy-payagreement-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    GET_AUTO_PAY_SERVICE_URL_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/easy-payagreement-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    ENROLL_AUTO_PAY_SERVICE_URL_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/autopay-enroll-responses.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    MANAGE_AUTO_PAY_SERVICE_URL_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/autopay-manage-responsse.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    DELETE_AUTO_PAY_SERVICE_URL_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/autopay-cancel-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/enroll-eligibility-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    GET_AUTO_PAY_ELIGIBILITY_SERVICE_URL_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/enroll-eligibility-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    AUTO_PAY_PAYMENT_PROFILE_SERVICE_URL: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/autopay-search-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    APIGEE_CARDTYPE_VALIDATION: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/credit-type-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    }
};


// APIGEE_URLS
export const APIGEE_TOKEN_URL: any = {
    APIGEE_REFRESH_TOKEN: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/access-token.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: '?noCache='
    },
    SECURE_TOKEN_REFRESH: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/access-token-refresh.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: '&noCache=',
        apiName: 'SECURE_TOKEN_REFRESH'
    },
    APIGEE_PUBLIC_KEY: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/public-key-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    PUBLIC_KEY: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/public-key-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    APIGEE_CARDTYPE_VALIDATION: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/credit-type-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    APIGEE_SAVE_AUTO_PAY: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/billpayaliasv3-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    APIGEE_SAVE_AUTO_PAY_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/billpayaliasv3-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    APIGEE_GET_BANK_NAME: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/ach-v3-validation-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: '?programCode='
    },
    MAKE_PAYMENT: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/processErrorCodeNegativeBankBlock.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    MAKE_PAYMENT_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/processErrorCodeNegativeBankBlock.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    BILLPAY_ALIAS: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/billpayaliasv3-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        queryparam: '/account-orders'
    },
    BILLPAY_ALIAS_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/billpayaliasv3-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    CALCULATE_UPDATED_BALANCE: {
        path: '/billing-experience/v1/bills/calculate-updated-balance', // updated with actual API url
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    APIGEE_BALANCE: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/balance-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    APIGEE_BALANCE_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/balance-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    GET_SCH_ELIGIBILITY: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/sch-eligibility.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'test',
        queryparam: '?org='
    },
    GET_SCH_ELIGIBILITY_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/sch-eligibility.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },

    GET_SCH_DATES: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/scheduled-payment-info.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'test',
        queryparam: '?org='
    },
    MNG_PAYMENT_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/schedulepayment-response.json',
        // path: './assets/tfb-payment/content-and-assets/rest-apis/errorCodeAddress.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    CANCEL_SCHEDULE_PAYMENT: {
        path: '/b2b-experience/v1/billing-accounts/{billing-account}/scheduled-payment',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    UPDATE_CRORDERID: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/updateCrOrder-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    UPDATE_CRORDERID_PARTNER: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/updateCrOrder-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    OKTA_TOKEN: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/oktaToken.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    OKTA_USERINFO: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/oktaUserProfile.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    LOGGING_API: {
        path: '/tmobile/cq/loggingapi',
        componetName: '',
        methodName: '',
        requestObj: '',
        responseObj: '',
        serviceName: '',
        loggingFileApp: 'paymentAppTrace',
        endPoint: '',
        otherMSG: ''
    },
    SPLUNK_LOG: {
        path: '/splunk-logs/services/collector/event',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    OKTA_ISSUER: {
        path: '/oauth2/{{okta_issuercode}}/v1/authorize?client_id={{okta_clientid}}&scope=openid'
            + '&redirect_uri={{okta_redirecturi}}&response_type=code&state=state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb602',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
        apiName: 'OKTA_ISSUER'
    },
    INSTALLMENT_ELIGIBILITY: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/payment-eligibility.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    PAYMENT_ARRANGEMENTS_QUERY: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/payment-arrangements.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    INSTALLMENT_ELIGIBILITY_V2: {
            path: './assets/tfb-payment/content-and-assets/rest-apis/payment-eligibility.json',
            canCache: false,
            isMocked: false,
            b2bClient: 'tfb-payment'
        },
    PAYMENT_ARRANGEMENTS_QUERY_V2: {
            path: './assets/tfb-payment/content-and-assets/rest-apis/payment-arrangements.json',
            canCache: false,
            isMocked: false,
            b2bClient: 'tfb-payment'
        },
    PAYMENT_ARRANGEMENTS_FETCH_ACTIVITY_ID_IAM: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/arrangements-fetchActivityID-iam.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    PAYMENT_ARRANGEMENTS_FETCH_ROLES_ID_OKTA: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/arrangements-fetchRoles-Okta.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    PAYMENT_ARRANGEMENTS_FETCH_ACTIVITIESID_BYROLES_OKTA: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/arrangements-fetchActivityID-Okta.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    PAYMENT_ARRANGEMENTS_FETCH_USER_ROLE_ACTIVITIES: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/arrangements-fetchUserRoleActivities.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    SIMONHANDS_PAYMENT: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/processErrorCodeCvv.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    },
    
    EIP_STANDALONE_PAYMENT: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/eip-standalone-payment.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
    },

    FINANCE_AGREEMENTS: {
        path: './assets/tfb-payment/content-and-assets/rest-apis/finance-histories-response.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment',
    },
};

export const CONTENT_PAGES_URL: any = {
    CONTENT_ASOP_CHECKOUT_PAGE: {
        // path: '/b2b-experience/v1/content?service=/tmobile/cq/authorvalue/content/coe/en/commonpayment.json',
        path: './assets/tfb-payment/content-and-assets/rest-apis/commonpayment.json',
        canCache: false,
        isMocked: false,
        b2bClient: 'tfb-payment'
    }
};

