// In single-spa, the assets need to be loaded from a dynamic location,
// instead of hard coded to `/assets`. We use webpack public path for this.
// See https://webpack.js.org/guides/public-path/#root

declare var __webpack_public_path__: string;

export function assetUrl(url: string): string {
    const publicPath = __webpack_public_path__;
    const publicPathSuffix = publicPath.endsWith('/') ? '' : '/';
    const urlPrefix = url.startsWith('/') ? '' : '/';

    return `${publicPath}${publicPathSuffix}assets${urlPrefix}${url}`;
}

export function endpointUrl(url: string) {
    let publicPath: string = __webpack_public_path__;
    if (publicPath.endsWith('/')) {
        publicPath = publicPath.replace(/\/$/, '');
    }
    const urlPrefix = url.startsWith('/') ? '' : '/';

    return `${publicPath}${urlPrefix}${url}`;
}
