export const staticContent = {
  components: {
    autoPayment: {
      reactiveAwarenessMsg:"As early as May, customers on AutoPay eligible plans will be required to use a debit card or bank account as the payment method to receive the monthly AutoPay discount.",
      autopayBannerMessage: "With autopay you can save $5 per line upto $40 if you use a debit card or bank account for payment. Please be advised that you can enroll with a credit card but not be eligible for AutoPay discount.",
      creditCardAlertMsg:"Please be advised that credit cards are not eligible for the AutoPay discount.",
    },
    cardMessage:{
      creditCardInfo:"Credit Card Information",
      debitCardInfo:"Debit Card Information",
      invalidCreditCard:"Valid credit card required",
      wrongCreditCard:"This is a debit card, enter valid credit card number",
      invalidDebitcard:"Valid debit card required",
      wrongDebitCard:"This is a credit card, enter valid debit card number",
      creditCardNoMsg:"Credit card number",
      debitCardNoMsg:"Debit card number"
    },
    overlayMessage:{
      autopayDiscountTitle:"Continue without AutoPay discount?",
      autopayDiscountDescription:"You can save $5 per line upto $40 if you use a debit card or bank account for payment. If you choose to continue with a credit card, you will not be eligible to receive this discount.",
      yes:"Yes",
      no:"No"
    }
  },
  par: {
    common_payment: {
      validationFailure: {
        addressCheckErrorCode: "8184",
        cvvCheckErrorCode: "8183",
      },
      negativeFilecheck: {
        commonErrorCode: "2494",
        denialTxtForPaymentBlock: "DEBIT_CARD,CREDIT_CARD",
        denialTxtForBanBlock: "ALL",
        denialTxtForBankBlock: "CHECK",
        fraudCheckKey: "FRAUDCHECK",
        specificationsValue: "CARD",
      },
      landingInfo: {
        PageTitle: "T-Mobile for Business | Digital Payment",
        paymentHeadingTxt: "Make a Payment",
        cancelOrderButtonText: "Cancel",
        cancelOrderLinkText: "Cancel",
        submitOrderButtonText: "Submit",
        tmobileLogoUrl: "https://www.t-mobile.com/",
        billPaymentTxt: "BILL_PAYMENT",
        enTrustTxt:
          "This website has a verified certificate and uses SSL to encrypt data going between your Web browser and the website. For information about how we collect, use, disclose, and protect your information, see the T-Mobile",
        enTrustTxt1: "Privacy Policy",
        sspUrl:
          "https://tfb.t-mobile.com/skavastream/studio/reader/tmobilesspprod/companydashboard",
        enTrustLogoUrl:
          "https://www.entrust.net/customer/profile.cfm?domain=[host]&lang=en",
        enTrustPrivacyUrl:
          "https://www.t-mobile.com/website/privacypolicy.aspx",
        submitDisclaimerTxt:
          '<p>By clicking "Submit" you authorize a one time payment of {{amount}} and acknowledge that you\'ve read and agree to T-Mobile\'s <a href="https://www.t-mobile.com/Templates/Popup.aspx?PAsset=Ftr_Ftr_TermsAndConditions&amp;print=true" _rte_href="https://www.t-mobile.com/Templates/Popup.aspx?PAsset=Ftr_Ftr_TermsAndConditions&amp;print=true" target="_blank">Terms and Conditions</a> of use.</p>',
        tryAgainButtonTxt: "Try again",
        ContinueOrderButtonText: "Continue",
      },
      paymentAmount: {
        payImmediatelyTxt:
          "Please pay immediately to avoid service interruption",
        amountErrorTxt: "Amount cannot be more than $25,000",
        titlelblTxt1: "Account #[BAN] ",
        dueDateTxt: " (Due [DUEDATE])",
        billDueDateTxt: "Please pay by [DUEDATE] to avoid service interruption",
        defPayRadioTxt: "[AMOUNT]",
        totalDue: "Total due",
        scheduledApOnTxt1: "Your payment of [AMOUNT] will occur on ",
        scheduledApOnTxt2:
          "[SCHEDULEDUEDATE]. AutoPay will process any of the remaining balance.",
        noBalApOnTxt:
          "The account balance has been paid in full. AutoPay will no longer process for this billing cycle.",
        headingTxt: "Payment amount",
        noPaymentDueTxt: "No payment is due at this time",
        scheduledPaymentInfoTooltipTxt: "Date may vary due to bill cycle date",
        changeTxt: "Change",
        accountCreditTxt: "This account has a credit of [AMOUNT].",
        paymentAmountInfoTooltipTxt:
          "This total does not include your unbilled usage charges, which can be found in the Usage section of your account's Billing page. These charges will be reflected in your next billing statement.",
        chooseAmountErrorTxt: "Amount has to be more than $2",
        dueNowTxt: "Due now",
        chooseAmountPlaceHolderTxt: "Choose an Amount",
        smallAmountMessage:
          "$2.00 (minimum allowed payment, additional payment will be credited to your account)<br>If you'd like to pay the exact amount due, please contact a Business Expert.",
        scheduledApOffTxt: "A payment of [AMOUNT] will occur on ",
        otherTxt: "Other",
        noScheduleApOnTxt:
          "A payment of [AMOUNT] is scheduled for an auto payment. If you make a one-time payment now, AutoPay will process any of the remaining balance.",
        totalDueRadioTxt: "[AMOUNT]",
        autoPayRemainingBalanceTxt:
          "AutoPay will process any remaining balance on",
        titlelblTxt2: "([BANNAME])",
        alertPAnotification:
          "You are eligible to setup a payment arrangement to pay your balance in two future installments.",
        choosePayDateFirstInstTxt:
          "First installment date must be within [maxDaysBeforeFirstInstallment] days",
        choosePayDateSecondInstTxt:
          "Second installment date must be within [maxDaysBetweenInstallment] days of your first installment",
        firstInstallmentTxt: "First payment",
        firstPaymentFieldMsgPastDue: "([pastDue]).",
        firstPaymentFieldMsgPercent:
          "Must be at least [minPercent]% ([minAmount]) of total due.",
        installmentNoteTxt: "AutoPay will pause while you have an active payment arrangement and will resume once the payment arrangement is complete.",
        installmentText: "Pay in installments",
        installmentTooltipTxt:
          "Your AutoPay will resume once you successfully complete or cancel your payment arrangement. If your payment arrangement completes within 2 days of your due date, you will need to make a one-time payment and AutoPay will resume the following month. If your account is or becomes past due, you will not receive the AutoPay discount. If your payment arrangement fails, you will be un-enrolled from AutoPay.",
        messageSecondInstallment:
          "First and second payment must equal the total amount due ([pastDue])",
        notSameDateCheckNote:
          "Please note that updates to payment arrangements can only be made to the payment method on file (at least 1 day before the installment due date).",
        paymentDateNote: "Payments must equal the total amount due ",
        sameDateCheckNote:
          "Payment method cannot be updated on the payment due date. Please try again after your installment payment has finished processing.",
        secondInstallmentTxt: "Second payment",
        titleEditArrangement: "Edit Payment Arrangement",
        bannerTxt:
          "You are eligible to setup a payment arrangement to pay your balance in two future installments!",
        totalDueMessage:
          "Payment cannot exceed $25,000.Please use the Other amount option to make a payment of $25,000 or less.",
      },
      paymentDate: {
        todayTxt: "Today",
        notEligibleTxt: "Payment can only be made on the current date.",
        secondPaymentFieldMsg:
          "Minimum payment must equal balance due: [remElegiblePastDue]",
        changeTxt: "Change",
        headingTxt: "Payment date",
      },
      paymentInfo: {
        bankDepositTxt: "Bank transfer",
        addCreditCardTxt: "Add credit card",
        changeCreditCardTxt: "Change",
        changeBankInfoTxt: "Change",
        addBankInfoTxt: "Add bank info",
        creditCardTxt: "Credit/debit card",
        headingTxt: "Payment information",
      },
      ach: {
        bankAccountEndingTxt: "Bank account ending",
        routingNumberLbl: "9-digit transit/routing number",
        accountNameError: "Enter name as it appears on the account",
        headingTxt: "Add new bank account",
        bankAccountInfoTxt: "Bank account information",
        bankNameLbl: "Bank name",
        accountNameLbl: "Name on account",
        accountNumberError: "Please enter a valid account number",
        bankAccountSaveSuccessMsg:
          "Bank account details saved successfully! Order ID is -",
        invalidRoutingNumberError: "Invalid routing number",
        accountNumberLbl: "Account number",
        submitBtnTxt: "Verify bank information",
        bankNameError: "Please enter a valid bank name",
        changeTxt: "Change",
        accountTypeLbl: "Account type",
        routingNumberError: "Please enter a valid transit/routing number",
        phone: "Phone number",
        phoneErrorMessage: "Phone number is required",
        phoneHelpMessage: "Number must be associated with account",
        phoneNotificationMessage:
          "Please enter a phone number associated with this account to receive payment arrangement notifications",
        phoneNotificationTitle: "Payment installment notifications",
        phoneSuccessMessage: "Account associated number",
      },
      creditCard: {
        remainingbalance: "Remaining balance",
        linedepositcharges: "Line deposit",
        addressLine2Lbl: "Address line 2",
        invalidCardHolderNameError: "Please enter Name on card",
        ccName: "Name on card",
        esignaturelabel: "E-Signature",
        selectMonthLbl: "Exp month",
        masterCreditTypeText: "MASTERCARD",
        paymenttitle: "Payment",
        lowerMonthlyPayTxt:
          "Lower your monthly payments for all your financial devices in your order by paying more upfront",
        creditCardInfoTxt: "Credit/Debit Card Information",
        state: "State",
        invalidCreditCardNumberError: "Valid credit/debit card required",
        changeTxt: "Change",
        autoPaySignUpTxt: "Sign up for autoPay",
        zip: "ZIP code",
        dueMonthlyTxt: "Due monthly",
        selectPayDueNowTxt: "Select payment for total due now",
        summarytext: "Summary",
        dueNowTxt: "Due now",
        creditCardNumberText: "Credit/debit card number",
        paymentoptions: "Payment options",
        totalremainingbalance: "Total remaining balance",
        corporateAccount: "Charge to billing account",
        monthlyrecurring: "Monthly",
        cityPatternErrorMessage: "Please enter a valid city name",
        selectPayMonthlyTxt: "Select payment for monthly charges",
        discoverCreditTypeText: "DISCOVER",
        billingSameAsContactTxt: "Same as billing address",
        additionalDownPayment: "Add additional downpayment amount",
        poNumber: "PO Number",
        autoPayLabel: "AutoPay",
        city: "City",
        cvvHelpTooltipTxt:
          "CVV stands for Credit Card Verification Value. The CVV is a 3 or 4 digit code embossed or imprinted on the signature panel on the reverse side of Visa, MasterCard and Discover cards and on the front of American Express cards. This code is used as an extra security measure to ensure that you have access and/or physical possession of the credit card itself. A CVV cannot be all 0\'s",
        CCSpecifyDiffAddress: "Specify a different credit card billing address",
        blankAddressLine1Error: "Please enter address line 1",
        selectYearLbl: "Exp year",
        totalmonthlybal: "Total monthly",
        enrollAutoPayTxt:
          "Enrolling your billing account in autoPay automatically deducts funds from your chosen payment method (credit card) approximately two days before the bill due date and applies them to the account as a payment.",
        invalidCityErrorMessage: "Please enter valid city",
        paymentType: "Payment type",
        invalidCVVNumberError: "Please enter a valid CVV number",
        visaCreditTypeText: "VISA",
        totallinedepositcharges: "Total line deposit",
        creditCardBillingInfoTxt: "Credit card billing information",
        expirationError: "Please enter valid expiration date",
        billingaccount: "Billing Account",
        invalidCreditCardError: "Invalid credit card",
        blankExpMonth: "Please select exp month",
				blankExpYear: "Please select exp year",
        submitBtnTxt: "Verify card information",
        creditCardSaveSuccessMsg:
          "Credit card details saved successfully! Order ID is -",
        blankAddressLine2Error: "Please enter address line 2",
        creditCardTitleTxt: "Add a credit card",
        addressLine1Lbl: "Address line 1",
        billingAddTxt: "Billing Address",
        blankCVVNumberError: "Please enter a CVV number",
        zeroCVVNumberError: "Please enter a CVV number other than 000",
        cvvHelpLabelTxt: "What's this?",
        accountNumber: "Account number",
        creditcard: "Credit Card",
        blankCityErrorMessage: "Valid city required",
        invalidZipError: "Please enter a valid ZIP",
        transactionType: "Transaction type",
        editbuttontext: "Edit payment details",
        expirationDateCC: "Expiration date",
        americanCreditTypeText: "AMERICANEXPRESS",
        billingSameAsShippingTxt: "Same as shipping details",
        securityCodeLabel: "CVV",
        blankCreditCardNumberError: "Please enter a credit card number",
        blankZipError: "Valid ZIP code required",
        transactionIdText: "Transaction ID",
        phone: "Phone number",
        phoneErrorMessage: "Phone number is required",
        phoneHelpMessage: "Number must be associated with account",
        phoneNotificationMessage:
          "Please enter a phone number associated with this account to receive payment arrangement notifications",
        phoneNotificationTitle: "Payment installment notifications",
        phoneSuccessMessage: "Account associated number",
      },
      thankYou: {
        partnerHighSecurity: " Due to enhanced security measures designed to protect your personal information, receipts will no longer be sent through emails.",
        partnerHighSecurityBilling: " You can now easily download your receipt <a href=\"[REDIRECT_URL]\"><b>HERE</b></a>.",
        schPmtText: "will be charged ",
        onePaymentTxt4: " bank account ending in [BANKACNT]. ",
        onePaymentTxt: "Payment #[PAYMENTID] for ",
        onePaymentTxt5:
          ". Your payment will appear in your payment history approximately two hours after processing.",
        onePaymentTxt6:
          "Your payment will appear in your payment history approximately two hours after processing.",
        backTxt: "Back",
        onePaymentTxt2: "on [PAYMENTDATE], using a ",
        onePaymentTxt3: "[CARDTYPE] card ending in ",
        onePaymentTxt1: " was successfully applied to account #[BAN] ",
        remainingBalanceTxt: "Remaining balance, if any,",
        scheduledAutopayTxt: "will be processed by AutoPay",
        onTxt: "on",
        schPaymentTxt3: "was successfully scheduled for [PAYMENTDATE],",
        schPaymentTxt2: "for account #[BAN] ",
        schPaymentTxt5: " card ending in [CARDNUMBER]. ",
        schPaymentTxt4: " using a [CARDTYPE]",
        schPmtIDText: "Your transaction ID is ",
        autoPayMsgText: ", will be processed by AutoPay",
        visaCardTxt1: "ending in",
        visaCardTxt2: "was charged ",
        visaCardTxt3: "towards Account#",
        visaCardTxt4: "An email receipt has been sent to ",
        visaCardTxt5:
          "Your payment may not appear on payment history right away due to processing time.",
        paymentThanksTxt: "Thank you for your payment!",
        backToBilling: "Back to Billing",
        yourPaymentTxt: "Your Payment #:",
        schPaymentTxt1: "A payment in the amount of [PAYMENTAMOUNT] ",
        note: "Your account will return to its regular payment schedule after your final payment.",
        payInInstallmenttxt:
          "The following installment payments will be charged to your bank account ending in ",
        payInInstallmenttxtCC:
          "The following installment payments will be charged to your [CARDTYPE] ending in ",
        paymentArrangementComplete: "Payment arrangement complete.",
        eipSuccessTxt: " was successfully applied to Loan ID [LOANID] ",
        eipShowDetailsTxt: "Show details",
        eipHideDetailsTxt: "Hide details",
        eipOrderNumberTxt: "Order number: ",
        eipAuthCodeTxt: "Authorization code: ",
        eipTransactionTypeTxt: "Transaction type: ",
        eipBANTxt: "T-Mobile account number: ",
        eipLoanIdTxt: "EIP Loan ID number: ",
        eipImeiTxt: "IMEI: "
      },
      footer: {
        sitemapUrl: "https://www.t-mobile.com/customers/accessibility-policy",
        sitemapTxt: "Sitemap",
        twitterUrl: "https://twitter.com/TMobileBusiness",
        supportUrl:
          "https://tfb.t-mobile.com/skavastream/studio/reader/tmobilesspprod/support",
        supportTxt: "Support",
        consumerUrl: "https://www.t-mobile.com/responsibility/consumer-info",
        publicSafetyTxt: "Public Safety/911",
        publicSafetyUrl:
          "https://www.t-mobile.com/company/company-info/safety/wireless-emergency-alerts.html",
        termsOfUseTxt: "Terms of Use",
        consumerInfoTxt: "Consumer Information",
        termsOfUseUrl:
          "https://www.t-mobile.com/Templates/Popup.aspx?PAsset=Ftr_Ftr_TermsOfUse&print=true",
        facebookUrl: "https://www.facebook.com/Tmobileforbusiness",
        openInternetTxt: "Open Internet",
        tmobileIncTxt: "2002-2021 T-Mobile USA, INC.",
        openInternetUrl:
          "https://www.t-mobile.com/company/company-info/consumer/internet-services.html",
        helpDeskUrl:
          "https://businesscenter.t-mobile.com/content/tmobile/en/help.html",
        helpDeskTxt: "Help Desk",
        termsConditionTxt: "Terms & Conditions",
        faqUrl: "https://support.t-mobile.com",
        faqTxt: "FAQ",
        linkedInUrl: "https://www.linkedin.com/company/t-mobile-business",
        returnPolicyUrl:
          "https://businesscenter.t-mobile.com/content/tmobile/en/Businessreturnpolicy.html",
        returnPolicyTxt: "Return Policy",
        termsAndConditionUrl:
          "https://www.t-mobile.com/Templates/Popup.aspx?PAsset=Ftr_Ftr_TermsAndConditions&print=true",
        tmobileBusinessTxt: "T-Mobile for business is a part of T-Mobile ",
        privacyPolicyUrl:
          "https://www.t-mobile.com/company/website/privacypolicy.aspx",
        privacyPolicyTxt: "Privacy Policy",
        accessibilityUrl:
          "https://www.t-mobile.com/customers/accessibility-policy",
        coverageUrl:
          "https://business.t-mobile.com/coverage/coverage-map?icid=WMM_TM_Q317B2BNET_WJLXYRX2V29607",
        accessibilityTxt: "Accessibility",
        contactUrl:
          "https://businesscenter.t-mobile.com/content/tmobile/en/contact-us-bc.html",
        coverageTxt: "Coverage",
        doNotSellMyPersonalInfoUrl:
          "https://tos-flex-dev.dd-stg.kube.t-mobile.com/dns",
        doNotSellMyPersonalInfoTxt: "Do Not Sell My Personal Information",
        privacyCenterUrl: "https://www.t-mobile.com/privacy-center",
        privacyCenterTxt: "Privacy Center",
        domainURL: "https://t-mobile.com",
      },
      errorconfigs: {
        "MessageKey-1001":
          "Account is not eligible for this payment method.  Please select an alternative payment method.",
        "MessageKey-1002":
          "Unable to proceed as account is not eligible for making a payment.  Please call Business Care.",
      },
      error: {
        banBlockedErrorTxt:
          "Payment option not available for this account. Please call Business Care for assistance.",
        cardBlockedErrorTxt:
          "Account is not eligible for this payment method. Please select an alternative payment method.",
        specificCardErrorTxt:
          "Invalid credit card. Please select another method of payment or contact Business Care for assistance.",
        retryCardBankErrorTxt1:
          "Unable to proceed with this request. Please call our Business Team of Experts at 1-800-375-1126 for assistance.",
        genericErrorMpTxt:
          "Unable to proceed with this request. Please call our Business Team of Experts at 1-800-375-1126 for assistance.",
        cardBankBlockedErrorTxt:
          "Invalid payment method. Please select another method of payment or contact Business Care for assistance.",
        retryBankErrorTxt: "Bank validation error. Please try again.",
        genericErrorApTxt:
          "Your request could not be processed at this time. Please review the information and try again. If you are unable to proceed, please call our Business Team of Experts at 1-800-375-1126 for assistance",
        genericErrorTxt:
          "Unable to proceed with this request. Please call our Business Team of Experts at 1-800-375-1126 for assistance.",
        retryCardErrorTxt: "Card validation error. Please try again.",
        specificBankErrorTxt:
          "Invalid bank account. Please select another method of payment or contact Business Care for assistance.",
        handshakeFailureErrorTxt: "System is down, Please check back later",
        serverError: "Server Error",
        genericReasonCode: "2492",
        processAddressReasonCodeTxt: "8184",
        processCvvReasonCodeTxt: "8183",
        processNegativeCardReasonCodeTxt: "8186",
        processNegativeBankReasonCodeTxt: "8185",
        processNegativeBanReasonCodeTxt: "8186,8185",
        processNegativeSpecificBankReasonCodeTxt: "8186,8185",
        processNegativeSpecificCardReasonCodeTxt: "8185,8186",
        processFraudCheckFailureReasonCodeTxt: "8116",
        processStatusCode: "D",
        validationFailureaddressCheckErrorCode: "8184",
        validationFailurecvvCheckErrorCode: "8183",
        negativeFileCheckcommonErrorCode: "2494",
        negativeFileCheckdenialTxtForPaymentBlock: "DEBIT_CARD,CREDIT_CARD",
        negativeFileCheckdenialTxtForBanBlock: "ALL",
        negativeFileCheckdenialTxtForBankBlock: "CHECK",
        negativeFileCheckfraudCheckKey: "FRAUDCHECK",
        negativeFileCheckspecificationsValue: "CARD",
        errorScreenTitle: "We are unable to process your transaction.",
        errorScreenCTA: "Back to Billing",
        processPaymentError: "Payment Error",
        HardGoodsErrorTxt:
          "Your request could not be processed at this time. Please review the information and try again. If you are unable to proceed, please call our Business Team of Experts at 1-800-375-1126 for assistance.",
        HardGoodsErrorTitleTxt: "Payment Error",
        pendingRequestErrorTxt:
          "Unable to proceed with this request. A payment has already been scheduled for this account.",
      },
      termsAndCondition: {
        termAndConditionTxt: "Terms and Conditions",
        termTxt2: "of use.",
        termTxt: 'By clicking "Submit" you authorize a one-time payment of',
        termTxt1: " and acknowledge that you've read and agree to T-Mobile's",
        paymentTermsTxt:
          " By submitting a payment, you authorize T-Mobile to debit your bank account/debit card or charge your credit card for the total amount and date shown",
        makePaymentTermsTxt1: "Payment",
        paymentTermsHead: "Payment Terms",
        makePaymentTermsTxt:
          "By clicking <strong>Submit</strong>, you acknowledge that you've read and agree to the ",
        makePaymentTermsTxt2: "T-Mobile",
        makePaymentTermsTxt3:
          " Terms and Conditions and you authorize T-Mobile to charge your card/debit your account.",
        makePaymentTermsTxt4: "and ",
        eipTermsSubmitTxt: "By submitting you agree to ",
        eipTermsPayBalanceTxt: "All required monthly payments towards Equipment Installment Plans are included in your bill. An additional payment on this page is optional and can be used to pay your balance in full.",
        eipTermsDueTxt: "The installment plan payoff amount does not include any pending installments due on your next bill."
      },
      autoPay: {
        enrollButtonText: "Enroll",
        tcLink: "T-Mobile",
        cancelHeadingTxt: "Cancel AutoPay",
        currentlyEnrolledText: "Currently enrolled in AutoPay",
        bankDepositTxt: "Bank transfer",
        serverErrorText: "Server Error Occured",
        turnOffAutoPayText: "Turn Off AutoPay",
        saveChangesBtnText: "Save Changes",
        termAndConditionTxt2: "and T-Mobile",
        manageAutoPaymentHeadingTxt: "Manage AutoPay",
        autopayTitlelblTxt1: "Account #[BAN] ",
        termAndConditionTxt1:
          "By clicking Enroll, you acknowledge that you've read and agree to the",
        cancelDescription2:
          "Cancellation of AutoPay within 2 days before the due date may result in funds still being withdrawn for your upcoming bill.",
        bankAccountEndingTxt: "Bank account ending",
        cancelDescription3:
          "Cancellation of AutoPay within 2 days before the due date may result in funds still being withdrawn for your upcoming bill.",
        continueButtonText: "Continue",
        termAndConditionEndTxt:
          "Term and Conditions and you authorize T-Mobile to charge your card/debit your account on a monthly basis up to 2 days prior to your bill due date in the amount of your T-Mobile bill.",
        successDescriptionCard:
          "This account has successfully been set up for AutoPay using the credit/debit card provided. A confirmation email has been sent to [EMAILID].",
          successDescriptionCardPartner:
          "This account has successfully been set up for AutoPay using the credit/debit card provided.",
        successDescription:
          "PaymentType ending in paymentTypeDetails has been successfully set up for AutoPay. An email has been sent to emailId to confirm this change.",
        creditCardTxt: "Credit/debit card",
        headingTxt: "Payment Information",
        cancelAutoPayBody: "You have successfully canceled AutoPay.",
        cancelDescription1:
          "Cancellation of AutoPay will occur during this billing cycle. Please make a manual payment for your upcoming bill.",
        termAndConditionMiddleTxt: "and",
        successHeadingTxt: "Success!",
        successDescriptionForManage:
          "You have successfully updated your payment method for AutoPay. A confirmation email has been sent to [EMAILID].",
        partnerSuccessDescriptionForManage:
          "You have successfully updated your payment method for AutoPay.",
        autopayLink: "Autopay",
        successDateDetailsForManage1:
          "The next AutoPay payment will be processed within 2 days of the next bill due date.",
        changeTxt: "Change",
        autoPaymentHeadingTxt: "Enroll in AutoPay",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        termAndConditionStartTxt:
          "By clicking <strong>Enroll</strong>, you acknowledge that you've read and agree to the ",
        successDateDetails: "AutoPay will begin on autoDate.",
        successButtonText: "Ok",
        creditCardEndingTxt: "Credit card ending",
        goBackButtonText: "Go back",
        successDescriptionBank:
          "This account has successfully been set up for AutoPay using the bank account information provided. A confirmation email has been sent to [EMAILID].",
        successDescriptionBankPartner: "This account has successfully been set up for AutoPay using the bank account information provided.",
        autopayPartnerRedirectMsg: " You can view your enrollment status <a href=\"[REDIRECT_URL]\"><b>HERE</b></a>.",
        autopaytitlelblTxt2: "([BANNAME])",
        validThruText: "Valid thru",
        successDateDetailsForManage:
          "The next AutoPay payment will be processed within 2 days of the current bill due date.",
        successDateDetails1:
          "The first AutoPay payment will be processed on autoDate.",
        successDateDetails2:
          "The first AutoPay payment will be processed within 2 days of the current bill due date.",
        cancelConfirmationText:
          "If you cancel AutoPay you will lose your per-line discount. Would you like to continue?",
        successDateDetails3:
          "The first AutoPay payment will be processed within 2 days of the next bill due date.",
        termsConditionTitle: "Terms & Conditions",
        paymentTermsTitle: " Auto Payment Terms",
        printTxt: "Print",
        editTxt: "Edit",
        cardTxt: "card",
      },
      cancelSchedulePayment: {
        successMsgTitle: "Your scheduled payment has been cancelled.",
        cancelPaymentText:
          "Your scheduled payment of $ paymentAmount on paymentDate will not be processed if you continue. Would you like to continue?",
        cancelPayment: "Cancel Payment",
        successMsgContent:
          "PaymentType ending in paymentTypeDetails will not be charged paymentAmount on paymentDate. An email has been sent to emailId.",
        close: "Close",
      },
      eipAdditionalPayment: {
        plan: "Plan #",
        imei: "IMEI #",
        financed: "Financed: ",
        originalPrice: "Original price: ",
        downPayment: "Down payment: ",
        finalPayment: "Final payment: ",
        remaining: "remaining",
        paymentAmount: "Payment Amount",
        extraDevicePaymentTxt: "Extra device payment",
        enterAmountTxt: "Enter $ amount",
        payInFullTxt: "Pay installment plan in full",
        slash: "/",
        dollar: "$",
        paymentDate: "Payment date",
        today: "Today",
        paymentInformation: "Payment information"
      },
    },
  },
};
