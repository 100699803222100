import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-okta-callback',
    template: '',
    styleUrls: ['./okta-callback.component.scss'],
    standalone: true
})
export class OktaCallbackComponent implements OnInit {

  constructor() { 
    // This is intentional
  }

  ngOnInit() {
    // This is intentional
  }

}
