import { Component, OnInit } from '@angular/core';
import { EnvironmentDetailsService } from '../services/environment-details.service';
import { ContentService } from '../services/content.service';
import { environment } from 'src/environments/environment';
import { COMMON_CONSTANTS } from 'src/app/common/core/common.constants';
import { isNullOrUndefined } from 'src/app/utilities/helper-utils';
import { HttpCacheService } from '../services/http-cache.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class FooterComponent implements OnInit {
  content: any;
  contentData: any;
  isCCPAFlagEnable: boolean = false;

  constructor(private contentService: ContentService, private _envSvc: EnvironmentDetailsService,
    private _cacheSvc: HttpCacheService) { }

  ngOnInit() {

    this.contentData = ContentService.GetCache('content');
    this.isCCPAFlagEnable = environment.CCPADormancyFlag;

    if (this.contentData) {
      this.content = this.contentData.footer;
    } else {
      if (!isNullOrUndefined(this._cacheSvc.getFromSessionStorage('contentmain'))) {
        const data = JSON.parse(this._cacheSvc.getFromSessionStorage('contentmain'));
        this.content = data.par.common_payment.footer;
        ContentService.SetCache('content', data.par.common_payment);
      } else {
        if (this._envSvc.CURR_CONTENT_URLS) {
          this.contentService.GetContentData().subscribe(data => {
            this.content = data.par.common_payment.footer;
            ContentService.SetCache('content', data.par.common_payment);
          });
        } else {
          this._envSvc.getContentEnvt().then(x => {
            this._envSvc.CURR_CONTENT_URLS = x.CONTENT_PAGES_URL;
            if (this._envSvc.CURR_CONTENT_URLS) { // this._envSvc.CURR_CONTENT_URLS getting undefined
              this.contentService
                .GetContentData()
                .subscribe(data => {  this.content = data.par.common_payment.footer;
                  ContentService.SetCache('content', data.par.common_payment);

                });
            }
          });
        }
      }
    }
  }
  get supportPageURL() {
    return `${environment.domain.iamHome}/support`;
  }
  onDNSNavigate() {
    const mapForm = document.createElement('form');
    mapForm.target = COMMON_CONSTANTS.DNS_WINDOW;
    mapForm.method = COMMON_CONSTANTS.POST;
    // tslint:disable-next-line:max-line-length
    mapForm.action = !isNullOrUndefined(this.content && this.content.doNotSellMyPersonalInfoUrl) ? this.content.doNotSellMyPersonalInfoUrl : '';
    const mapInput = document.createElement('input');​
    mapInput.type = COMMON_CONSTANTS.TEXT;​
    mapInput.name = COMMON_CONSTANTS.BRAND_TEXT;​
    mapInput.value = COMMON_CONSTANTS.TFB;
    mapForm.appendChild(mapInput);​
    const mapInput1 = document.createElement('input');​
    mapInput1.type = COMMON_CONSTANTS.TEXT;​
    mapInput1.name = COMMON_CONSTANTS.SITE_TEXT;​
    mapInput1.value = COMMON_CONSTANTS.NO_SELL_WEB;
    mapForm.appendChild(mapInput1);​
    const mapInput2 = document.createElement('input');​
    mapInput2.type = COMMON_CONSTANTS.TEXT;​
    mapInput2.name = COMMON_CONSTANTS.ORIGINURL_TEXT;​
    mapInput2.value = COMMON_CONSTANTS.ACCOUNT_HUB;
    mapForm.appendChild(mapInput2);​
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

}
